const tokens = {
  "aspectRatios.square": {
    "value": "1 / 1",
    "variable": "var(--aspect-ratios-square)"
  },
  "aspectRatios.landscape": {
    "value": "4 / 3",
    "variable": "var(--aspect-ratios-landscape)"
  },
  "aspectRatios.portrait": {
    "value": "3 / 4",
    "variable": "var(--aspect-ratios-portrait)"
  },
  "aspectRatios.wide": {
    "value": "16 / 9",
    "variable": "var(--aspect-ratios-wide)"
  },
  "aspectRatios.ultrawide": {
    "value": "18 / 5",
    "variable": "var(--aspect-ratios-ultrawide)"
  },
  "aspectRatios.golden": {
    "value": "1.618 / 1",
    "variable": "var(--aspect-ratios-golden)"
  },
  "borders.none": {
    "value": "none",
    "variable": "var(--borders-none)"
  },
  "durations.fastest": {
    "value": "50ms",
    "variable": "var(--durations-fastest)"
  },
  "durations.faster": {
    "value": "100ms",
    "variable": "var(--durations-faster)"
  },
  "durations.fast": {
    "value": "150ms",
    "variable": "var(--durations-fast)"
  },
  "durations.normal": {
    "value": "200ms",
    "variable": "var(--durations-normal)"
  },
  "durations.slow": {
    "value": "300ms",
    "variable": "var(--durations-slow)"
  },
  "durations.slower": {
    "value": "400ms",
    "variable": "var(--durations-slower)"
  },
  "durations.slowest": {
    "value": "500ms",
    "variable": "var(--durations-slowest)"
  },
  "radii.xs": {
    "value": "0.125rem",
    "variable": "var(--radii-xs)"
  },
  "radii.sm": {
    "value": "0.25rem",
    "variable": "var(--radii-sm)"
  },
  "radii.md": {
    "value": "0.375rem",
    "variable": "var(--radii-md)"
  },
  "radii.lg": {
    "value": "0.5rem",
    "variable": "var(--radii-lg)"
  },
  "radii.xl": {
    "value": "0.75rem",
    "variable": "var(--radii-xl)"
  },
  "radii.2xl": {
    "value": "1rem",
    "variable": "var(--radii-2xl)"
  },
  "radii.3xl": {
    "value": "1.5rem",
    "variable": "var(--radii-3xl)"
  },
  "radii.4xl": {
    "value": "2rem",
    "variable": "var(--radii-4xl)"
  },
  "radii.full": {
    "value": "9999px",
    "variable": "var(--radii-full)"
  },
  "fontWeights.thin": {
    "value": "100",
    "variable": "var(--font-weights-thin)"
  },
  "fontWeights.extralight": {
    "value": "200",
    "variable": "var(--font-weights-extralight)"
  },
  "fontWeights.light": {
    "value": "300",
    "variable": "var(--font-weights-light)"
  },
  "fontWeights.normal": {
    "value": "400",
    "variable": "var(--font-weights-normal)"
  },
  "fontWeights.medium": {
    "value": "500",
    "variable": "var(--font-weights-medium)"
  },
  "fontWeights.semibold": {
    "value": "600",
    "variable": "var(--font-weights-semibold)"
  },
  "fontWeights.bold": {
    "value": "700",
    "variable": "var(--font-weights-bold)"
  },
  "fontWeights.extrabold": {
    "value": "800",
    "variable": "var(--font-weights-extrabold)"
  },
  "fontWeights.black": {
    "value": "900",
    "variable": "var(--font-weights-black)"
  },
  "lineHeights.none": {
    "value": "1",
    "variable": "var(--line-heights-none)"
  },
  "lineHeights.tight": {
    "value": "1.25",
    "variable": "var(--line-heights-tight)"
  },
  "lineHeights.snug": {
    "value": "1.375",
    "variable": "var(--line-heights-snug)"
  },
  "lineHeights.normal": {
    "value": "1.5",
    "variable": "var(--line-heights-normal)"
  },
  "lineHeights.relaxed": {
    "value": "1.625",
    "variable": "var(--line-heights-relaxed)"
  },
  "lineHeights.loose": {
    "value": "2",
    "variable": "var(--line-heights-loose)"
  },
  "letterSpacings.tighter": {
    "value": "-0.05em",
    "variable": "var(--letter-spacings-tighter)"
  },
  "letterSpacings.tight": {
    "value": "-0.025em",
    "variable": "var(--letter-spacings-tight)"
  },
  "letterSpacings.normal": {
    "value": "0em",
    "variable": "var(--letter-spacings-normal)"
  },
  "letterSpacings.wide": {
    "value": "0.025em",
    "variable": "var(--letter-spacings-wide)"
  },
  "letterSpacings.wider": {
    "value": "0.05em",
    "variable": "var(--letter-spacings-wider)"
  },
  "letterSpacings.widest": {
    "value": "0.1em",
    "variable": "var(--letter-spacings-widest)"
  },
  "fontSizes.2xs": {
    "value": "0.5rem",
    "variable": "var(--font-sizes-2xs)"
  },
  "fontSizes.xs": {
    "value": "0.75rem",
    "variable": "var(--font-sizes-xs)"
  },
  "fontSizes.sm": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-sm)"
  },
  "fontSizes.md": {
    "value": "1rem",
    "variable": "var(--font-sizes-md)"
  },
  "fontSizes.lg": {
    "value": "1.125rem",
    "variable": "var(--font-sizes-lg)"
  },
  "fontSizes.xl": {
    "value": "1.25rem",
    "variable": "var(--font-sizes-xl)"
  },
  "fontSizes.2xl": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-2xl)"
  },
  "fontSizes.3xl": {
    "value": "1.875rem",
    "variable": "var(--font-sizes-3xl)"
  },
  "fontSizes.4xl": {
    "value": "2.25rem",
    "variable": "var(--font-sizes-4xl)"
  },
  "fontSizes.5xl": {
    "value": "3rem",
    "variable": "var(--font-sizes-5xl)"
  },
  "fontSizes.6xl": {
    "value": "3.75rem",
    "variable": "var(--font-sizes-6xl)"
  },
  "fontSizes.7xl": {
    "value": "4.5rem",
    "variable": "var(--font-sizes-7xl)"
  },
  "fontSizes.8xl": {
    "value": "6rem",
    "variable": "var(--font-sizes-8xl)"
  },
  "fontSizes.9xl": {
    "value": "8rem",
    "variable": "var(--font-sizes-9xl)"
  },
  "colors.current": {
    "value": "currentColor",
    "variable": "var(--colors-current)"
  },
  "colors.black": {
    "value": "#000",
    "variable": "var(--colors-black)"
  },
  "colors.white": {
    "value": "#fff",
    "variable": "var(--colors-white)"
  },
  "colors.transparent": {
    "value": "rgb(0 0 0 / 0)",
    "variable": "var(--colors-transparent)"
  },
  "colors.rose.50": {
    "value": "#fff1f2",
    "variable": "var(--colors-rose-50)"
  },
  "colors.rose.100": {
    "value": "#ffe4e6",
    "variable": "var(--colors-rose-100)"
  },
  "colors.rose.200": {
    "value": "#fecdd3",
    "variable": "var(--colors-rose-200)"
  },
  "colors.rose.300": {
    "value": "#fda4af",
    "variable": "var(--colors-rose-300)"
  },
  "colors.rose.400": {
    "value": "#fb7185",
    "variable": "var(--colors-rose-400)"
  },
  "colors.rose.500": {
    "value": "#f43f5e",
    "variable": "var(--colors-rose-500)"
  },
  "colors.rose.600": {
    "value": "#e11d48",
    "variable": "var(--colors-rose-600)"
  },
  "colors.rose.700": {
    "value": "#be123c",
    "variable": "var(--colors-rose-700)"
  },
  "colors.rose.800": {
    "value": "#9f1239",
    "variable": "var(--colors-rose-800)"
  },
  "colors.rose.900": {
    "value": "#881337",
    "variable": "var(--colors-rose-900)"
  },
  "colors.rose.950": {
    "value": "#4c0519",
    "variable": "var(--colors-rose-950)"
  },
  "colors.pink.50": {
    "value": "#fdf2f8",
    "variable": "var(--colors-pink-50)"
  },
  "colors.pink.100": {
    "value": "#fce7f3",
    "variable": "var(--colors-pink-100)"
  },
  "colors.pink.200": {
    "value": "#fbcfe8",
    "variable": "var(--colors-pink-200)"
  },
  "colors.pink.300": {
    "value": "#f9a8d4",
    "variable": "var(--colors-pink-300)"
  },
  "colors.pink.400": {
    "value": "#f472b6",
    "variable": "var(--colors-pink-400)"
  },
  "colors.pink.500": {
    "value": "#ec4899",
    "variable": "var(--colors-pink-500)"
  },
  "colors.pink.600": {
    "value": "#db2777",
    "variable": "var(--colors-pink-600)"
  },
  "colors.pink.700": {
    "value": "#be185d",
    "variable": "var(--colors-pink-700)"
  },
  "colors.pink.800": {
    "value": "#9d174d",
    "variable": "var(--colors-pink-800)"
  },
  "colors.pink.900": {
    "value": "#831843",
    "variable": "var(--colors-pink-900)"
  },
  "colors.pink.950": {
    "value": "#500724",
    "variable": "var(--colors-pink-950)"
  },
  "colors.fuchsia.50": {
    "value": "#fdf4ff",
    "variable": "var(--colors-fuchsia-50)"
  },
  "colors.fuchsia.100": {
    "value": "#fae8ff",
    "variable": "var(--colors-fuchsia-100)"
  },
  "colors.fuchsia.200": {
    "value": "#f5d0fe",
    "variable": "var(--colors-fuchsia-200)"
  },
  "colors.fuchsia.300": {
    "value": "#f0abfc",
    "variable": "var(--colors-fuchsia-300)"
  },
  "colors.fuchsia.400": {
    "value": "#e879f9",
    "variable": "var(--colors-fuchsia-400)"
  },
  "colors.fuchsia.500": {
    "value": "#d946ef",
    "variable": "var(--colors-fuchsia-500)"
  },
  "colors.fuchsia.600": {
    "value": "#c026d3",
    "variable": "var(--colors-fuchsia-600)"
  },
  "colors.fuchsia.700": {
    "value": "#a21caf",
    "variable": "var(--colors-fuchsia-700)"
  },
  "colors.fuchsia.800": {
    "value": "#86198f",
    "variable": "var(--colors-fuchsia-800)"
  },
  "colors.fuchsia.900": {
    "value": "#701a75",
    "variable": "var(--colors-fuchsia-900)"
  },
  "colors.fuchsia.950": {
    "value": "#4a044e",
    "variable": "var(--colors-fuchsia-950)"
  },
  "colors.purple.50": {
    "value": "#faf5ff",
    "variable": "var(--colors-purple-50)"
  },
  "colors.purple.100": {
    "value": "#f3e8ff",
    "variable": "var(--colors-purple-100)"
  },
  "colors.purple.200": {
    "value": "#e9d5ff",
    "variable": "var(--colors-purple-200)"
  },
  "colors.purple.300": {
    "value": "#d8b4fe",
    "variable": "var(--colors-purple-300)"
  },
  "colors.purple.400": {
    "value": "#c084fc",
    "variable": "var(--colors-purple-400)"
  },
  "colors.purple.500": {
    "value": "#a855f7",
    "variable": "var(--colors-purple-500)"
  },
  "colors.purple.600": {
    "value": "#9333ea",
    "variable": "var(--colors-purple-600)"
  },
  "colors.purple.700": {
    "value": "#7e22ce",
    "variable": "var(--colors-purple-700)"
  },
  "colors.purple.800": {
    "value": "#6b21a8",
    "variable": "var(--colors-purple-800)"
  },
  "colors.purple.900": {
    "value": "#581c87",
    "variable": "var(--colors-purple-900)"
  },
  "colors.purple.950": {
    "value": "#3b0764",
    "variable": "var(--colors-purple-950)"
  },
  "colors.violet.50": {
    "value": "#f5f3ff",
    "variable": "var(--colors-violet-50)"
  },
  "colors.violet.100": {
    "value": "#ede9fe",
    "variable": "var(--colors-violet-100)"
  },
  "colors.violet.200": {
    "value": "#ddd6fe",
    "variable": "var(--colors-violet-200)"
  },
  "colors.violet.300": {
    "value": "#c4b5fd",
    "variable": "var(--colors-violet-300)"
  },
  "colors.violet.400": {
    "value": "#a78bfa",
    "variable": "var(--colors-violet-400)"
  },
  "colors.violet.500": {
    "value": "#8b5cf6",
    "variable": "var(--colors-violet-500)"
  },
  "colors.violet.600": {
    "value": "#7c3aed",
    "variable": "var(--colors-violet-600)"
  },
  "colors.violet.700": {
    "value": "#6d28d9",
    "variable": "var(--colors-violet-700)"
  },
  "colors.violet.800": {
    "value": "#5b21b6",
    "variable": "var(--colors-violet-800)"
  },
  "colors.violet.900": {
    "value": "#4c1d95",
    "variable": "var(--colors-violet-900)"
  },
  "colors.violet.950": {
    "value": "#2e1065",
    "variable": "var(--colors-violet-950)"
  },
  "colors.indigo.50": {
    "value": "#eef2ff",
    "variable": "var(--colors-indigo-50)"
  },
  "colors.indigo.100": {
    "value": "#e0e7ff",
    "variable": "var(--colors-indigo-100)"
  },
  "colors.indigo.200": {
    "value": "#c7d2fe",
    "variable": "var(--colors-indigo-200)"
  },
  "colors.indigo.300": {
    "value": "#a5b4fc",
    "variable": "var(--colors-indigo-300)"
  },
  "colors.indigo.400": {
    "value": "#818cf8",
    "variable": "var(--colors-indigo-400)"
  },
  "colors.indigo.500": {
    "value": "#6366f1",
    "variable": "var(--colors-indigo-500)"
  },
  "colors.indigo.600": {
    "value": "#4f46e5",
    "variable": "var(--colors-indigo-600)"
  },
  "colors.indigo.700": {
    "value": "#4338ca",
    "variable": "var(--colors-indigo-700)"
  },
  "colors.indigo.800": {
    "value": "#3730a3",
    "variable": "var(--colors-indigo-800)"
  },
  "colors.indigo.900": {
    "value": "#312e81",
    "variable": "var(--colors-indigo-900)"
  },
  "colors.indigo.950": {
    "value": "#1e1b4b",
    "variable": "var(--colors-indigo-950)"
  },
  "colors.blue.50": {
    "value": "#eff6ff",
    "variable": "var(--colors-blue-50)"
  },
  "colors.blue.100": {
    "value": "#dbeafe",
    "variable": "var(--colors-blue-100)"
  },
  "colors.blue.200": {
    "value": "#bfdbfe",
    "variable": "var(--colors-blue-200)"
  },
  "colors.blue.300": {
    "value": "#93c5fd",
    "variable": "var(--colors-blue-300)"
  },
  "colors.blue.400": {
    "value": "#60a5fa",
    "variable": "var(--colors-blue-400)"
  },
  "colors.blue.500": {
    "value": "#3b82f6",
    "variable": "var(--colors-blue-500)"
  },
  "colors.blue.600": {
    "value": "#2563eb",
    "variable": "var(--colors-blue-600)"
  },
  "colors.blue.700": {
    "value": "#1d4ed8",
    "variable": "var(--colors-blue-700)"
  },
  "colors.blue.800": {
    "value": "#1e40af",
    "variable": "var(--colors-blue-800)"
  },
  "colors.blue.900": {
    "value": "#1e3a8a",
    "variable": "var(--colors-blue-900)"
  },
  "colors.blue.950": {
    "value": "#172554",
    "variable": "var(--colors-blue-950)"
  },
  "colors.sky.50": {
    "value": "#f0f9ff",
    "variable": "var(--colors-sky-50)"
  },
  "colors.sky.100": {
    "value": "#e0f2fe",
    "variable": "var(--colors-sky-100)"
  },
  "colors.sky.200": {
    "value": "#bae6fd",
    "variable": "var(--colors-sky-200)"
  },
  "colors.sky.300": {
    "value": "#7dd3fc",
    "variable": "var(--colors-sky-300)"
  },
  "colors.sky.400": {
    "value": "#38bdf8",
    "variable": "var(--colors-sky-400)"
  },
  "colors.sky.500": {
    "value": "#0ea5e9",
    "variable": "var(--colors-sky-500)"
  },
  "colors.sky.600": {
    "value": "#0284c7",
    "variable": "var(--colors-sky-600)"
  },
  "colors.sky.700": {
    "value": "#0369a1",
    "variable": "var(--colors-sky-700)"
  },
  "colors.sky.800": {
    "value": "#075985",
    "variable": "var(--colors-sky-800)"
  },
  "colors.sky.900": {
    "value": "#0c4a6e",
    "variable": "var(--colors-sky-900)"
  },
  "colors.sky.950": {
    "value": "#082f49",
    "variable": "var(--colors-sky-950)"
  },
  "colors.cyan.50": {
    "value": "#ecfeff",
    "variable": "var(--colors-cyan-50)"
  },
  "colors.cyan.100": {
    "value": "#cffafe",
    "variable": "var(--colors-cyan-100)"
  },
  "colors.cyan.200": {
    "value": "#a5f3fc",
    "variable": "var(--colors-cyan-200)"
  },
  "colors.cyan.300": {
    "value": "#67e8f9",
    "variable": "var(--colors-cyan-300)"
  },
  "colors.cyan.400": {
    "value": "#22d3ee",
    "variable": "var(--colors-cyan-400)"
  },
  "colors.cyan.500": {
    "value": "#06b6d4",
    "variable": "var(--colors-cyan-500)"
  },
  "colors.cyan.600": {
    "value": "#0891b2",
    "variable": "var(--colors-cyan-600)"
  },
  "colors.cyan.700": {
    "value": "#0e7490",
    "variable": "var(--colors-cyan-700)"
  },
  "colors.cyan.800": {
    "value": "#155e75",
    "variable": "var(--colors-cyan-800)"
  },
  "colors.cyan.900": {
    "value": "#164e63",
    "variable": "var(--colors-cyan-900)"
  },
  "colors.cyan.950": {
    "value": "#083344",
    "variable": "var(--colors-cyan-950)"
  },
  "colors.teal.50": {
    "value": "#f0fdfa",
    "variable": "var(--colors-teal-50)"
  },
  "colors.teal.100": {
    "value": "#ccfbf1",
    "variable": "var(--colors-teal-100)"
  },
  "colors.teal.200": {
    "value": "#99f6e4",
    "variable": "var(--colors-teal-200)"
  },
  "colors.teal.300": {
    "value": "#5eead4",
    "variable": "var(--colors-teal-300)"
  },
  "colors.teal.400": {
    "value": "#2dd4bf",
    "variable": "var(--colors-teal-400)"
  },
  "colors.teal.500": {
    "value": "#14b8a6",
    "variable": "var(--colors-teal-500)"
  },
  "colors.teal.600": {
    "value": "#0d9488",
    "variable": "var(--colors-teal-600)"
  },
  "colors.teal.700": {
    "value": "#0f766e",
    "variable": "var(--colors-teal-700)"
  },
  "colors.teal.800": {
    "value": "#115e59",
    "variable": "var(--colors-teal-800)"
  },
  "colors.teal.900": {
    "value": "#134e4a",
    "variable": "var(--colors-teal-900)"
  },
  "colors.teal.950": {
    "value": "#042f2e",
    "variable": "var(--colors-teal-950)"
  },
  "colors.emerald.50": {
    "value": "#ecfdf5",
    "variable": "var(--colors-emerald-50)"
  },
  "colors.emerald.100": {
    "value": "#d1fae5",
    "variable": "var(--colors-emerald-100)"
  },
  "colors.emerald.200": {
    "value": "#a7f3d0",
    "variable": "var(--colors-emerald-200)"
  },
  "colors.emerald.300": {
    "value": "#6ee7b7",
    "variable": "var(--colors-emerald-300)"
  },
  "colors.emerald.400": {
    "value": "#34d399",
    "variable": "var(--colors-emerald-400)"
  },
  "colors.emerald.500": {
    "value": "#10b981",
    "variable": "var(--colors-emerald-500)"
  },
  "colors.emerald.600": {
    "value": "#059669",
    "variable": "var(--colors-emerald-600)"
  },
  "colors.emerald.700": {
    "value": "#047857",
    "variable": "var(--colors-emerald-700)"
  },
  "colors.emerald.800": {
    "value": "#065f46",
    "variable": "var(--colors-emerald-800)"
  },
  "colors.emerald.900": {
    "value": "#064e3b",
    "variable": "var(--colors-emerald-900)"
  },
  "colors.emerald.950": {
    "value": "#022c22",
    "variable": "var(--colors-emerald-950)"
  },
  "colors.green.50": {
    "value": "#f0fdf4",
    "variable": "var(--colors-green-50)"
  },
  "colors.green.100": {
    "value": "#dcfce7",
    "variable": "var(--colors-green-100)"
  },
  "colors.green.200": {
    "value": "#bbf7d0",
    "variable": "var(--colors-green-200)"
  },
  "colors.green.300": {
    "value": "#86efac",
    "variable": "var(--colors-green-300)"
  },
  "colors.green.400": {
    "value": "#4ade80",
    "variable": "var(--colors-green-400)"
  },
  "colors.green.500": {
    "value": "#22c55e",
    "variable": "var(--colors-green-500)"
  },
  "colors.green.600": {
    "value": "#16a34a",
    "variable": "var(--colors-green-600)"
  },
  "colors.green.700": {
    "value": "#15803d",
    "variable": "var(--colors-green-700)"
  },
  "colors.green.800": {
    "value": "#166534",
    "variable": "var(--colors-green-800)"
  },
  "colors.green.900": {
    "value": "#14532d",
    "variable": "var(--colors-green-900)"
  },
  "colors.green.950": {
    "value": "#052e16",
    "variable": "var(--colors-green-950)"
  },
  "colors.lime.50": {
    "value": "#f7fee7",
    "variable": "var(--colors-lime-50)"
  },
  "colors.lime.100": {
    "value": "#ecfccb",
    "variable": "var(--colors-lime-100)"
  },
  "colors.lime.200": {
    "value": "#d9f99d",
    "variable": "var(--colors-lime-200)"
  },
  "colors.lime.300": {
    "value": "#bef264",
    "variable": "var(--colors-lime-300)"
  },
  "colors.lime.400": {
    "value": "#a3e635",
    "variable": "var(--colors-lime-400)"
  },
  "colors.lime.500": {
    "value": "#84cc16",
    "variable": "var(--colors-lime-500)"
  },
  "colors.lime.600": {
    "value": "#65a30d",
    "variable": "var(--colors-lime-600)"
  },
  "colors.lime.700": {
    "value": "#4d7c0f",
    "variable": "var(--colors-lime-700)"
  },
  "colors.lime.800": {
    "value": "#3f6212",
    "variable": "var(--colors-lime-800)"
  },
  "colors.lime.900": {
    "value": "#365314",
    "variable": "var(--colors-lime-900)"
  },
  "colors.lime.950": {
    "value": "#1a2e05",
    "variable": "var(--colors-lime-950)"
  },
  "colors.yellow.50": {
    "value": "#fefce8",
    "variable": "var(--colors-yellow-50)"
  },
  "colors.yellow.100": {
    "value": "#fef9c3",
    "variable": "var(--colors-yellow-100)"
  },
  "colors.yellow.200": {
    "value": "#fef08a",
    "variable": "var(--colors-yellow-200)"
  },
  "colors.yellow.300": {
    "value": "#fde047",
    "variable": "var(--colors-yellow-300)"
  },
  "colors.yellow.400": {
    "value": "#facc15",
    "variable": "var(--colors-yellow-400)"
  },
  "colors.yellow.500": {
    "value": "#eab308",
    "variable": "var(--colors-yellow-500)"
  },
  "colors.yellow.600": {
    "value": "#ca8a04",
    "variable": "var(--colors-yellow-600)"
  },
  "colors.yellow.700": {
    "value": "#a16207",
    "variable": "var(--colors-yellow-700)"
  },
  "colors.yellow.800": {
    "value": "#854d0e",
    "variable": "var(--colors-yellow-800)"
  },
  "colors.yellow.900": {
    "value": "#713f12",
    "variable": "var(--colors-yellow-900)"
  },
  "colors.yellow.950": {
    "value": "#422006",
    "variable": "var(--colors-yellow-950)"
  },
  "colors.amber.50": {
    "value": "#fffbeb",
    "variable": "var(--colors-amber-50)"
  },
  "colors.amber.100": {
    "value": "#fef3c7",
    "variable": "var(--colors-amber-100)"
  },
  "colors.amber.200": {
    "value": "#fde68a",
    "variable": "var(--colors-amber-200)"
  },
  "colors.amber.300": {
    "value": "#fcd34d",
    "variable": "var(--colors-amber-300)"
  },
  "colors.amber.400": {
    "value": "#fbbf24",
    "variable": "var(--colors-amber-400)"
  },
  "colors.amber.500": {
    "value": "#f59e0b",
    "variable": "var(--colors-amber-500)"
  },
  "colors.amber.600": {
    "value": "#d97706",
    "variable": "var(--colors-amber-600)"
  },
  "colors.amber.700": {
    "value": "#b45309",
    "variable": "var(--colors-amber-700)"
  },
  "colors.amber.800": {
    "value": "#92400e",
    "variable": "var(--colors-amber-800)"
  },
  "colors.amber.900": {
    "value": "#78350f",
    "variable": "var(--colors-amber-900)"
  },
  "colors.amber.950": {
    "value": "#451a03",
    "variable": "var(--colors-amber-950)"
  },
  "colors.orange.50": {
    "value": "#fff7ed",
    "variable": "var(--colors-orange-50)"
  },
  "colors.orange.100": {
    "value": "#ffedd5",
    "variable": "var(--colors-orange-100)"
  },
  "colors.orange.200": {
    "value": "#fed7aa",
    "variable": "var(--colors-orange-200)"
  },
  "colors.orange.300": {
    "value": "#fdba74",
    "variable": "var(--colors-orange-300)"
  },
  "colors.orange.400": {
    "value": "#fb923c",
    "variable": "var(--colors-orange-400)"
  },
  "colors.orange.500": {
    "value": "#f97316",
    "variable": "var(--colors-orange-500)"
  },
  "colors.orange.600": {
    "value": "#ea580c",
    "variable": "var(--colors-orange-600)"
  },
  "colors.orange.700": {
    "value": "#c2410c",
    "variable": "var(--colors-orange-700)"
  },
  "colors.orange.800": {
    "value": "#9a3412",
    "variable": "var(--colors-orange-800)"
  },
  "colors.orange.900": {
    "value": "#7c2d12",
    "variable": "var(--colors-orange-900)"
  },
  "colors.orange.950": {
    "value": "#431407",
    "variable": "var(--colors-orange-950)"
  },
  "colors.red.50": {
    "value": "#fef2f2",
    "variable": "var(--colors-red-50)"
  },
  "colors.red.100": {
    "value": "#fee2e2",
    "variable": "var(--colors-red-100)"
  },
  "colors.red.200": {
    "value": "#fecaca",
    "variable": "var(--colors-red-200)"
  },
  "colors.red.300": {
    "value": "#fca5a5",
    "variable": "var(--colors-red-300)"
  },
  "colors.red.400": {
    "value": "#f87171",
    "variable": "var(--colors-red-400)"
  },
  "colors.red.500": {
    "value": "#ef4444",
    "variable": "var(--colors-red-500)"
  },
  "colors.red.600": {
    "value": "#dc2626",
    "variable": "var(--colors-red-600)"
  },
  "colors.red.700": {
    "value": "#b91c1c",
    "variable": "var(--colors-red-700)"
  },
  "colors.red.800": {
    "value": "#991b1b",
    "variable": "var(--colors-red-800)"
  },
  "colors.red.900": {
    "value": "#7f1d1d",
    "variable": "var(--colors-red-900)"
  },
  "colors.red.950": {
    "value": "#450a0a",
    "variable": "var(--colors-red-950)"
  },
  "colors.neutral.50": {
    "value": "#fafafa",
    "variable": "var(--colors-neutral-50)"
  },
  "colors.neutral.100": {
    "value": "#f5f5f5",
    "variable": "var(--colors-neutral-100)"
  },
  "colors.neutral.200": {
    "value": "#e5e5e5",
    "variable": "var(--colors-neutral-200)"
  },
  "colors.neutral.300": {
    "value": "#d4d4d4",
    "variable": "var(--colors-neutral-300)"
  },
  "colors.neutral.400": {
    "value": "#a3a3a3",
    "variable": "var(--colors-neutral-400)"
  },
  "colors.neutral.500": {
    "value": "#737373",
    "variable": "var(--colors-neutral-500)"
  },
  "colors.neutral.600": {
    "value": "#525252",
    "variable": "var(--colors-neutral-600)"
  },
  "colors.neutral.700": {
    "value": "#404040",
    "variable": "var(--colors-neutral-700)"
  },
  "colors.neutral.800": {
    "value": "#262626",
    "variable": "var(--colors-neutral-800)"
  },
  "colors.neutral.900": {
    "value": "#171717",
    "variable": "var(--colors-neutral-900)"
  },
  "colors.neutral.950": {
    "value": "#0a0a0a",
    "variable": "var(--colors-neutral-950)"
  },
  "colors.stone.50": {
    "value": "#fafaf9",
    "variable": "var(--colors-stone-50)"
  },
  "colors.stone.100": {
    "value": "#f5f5f4",
    "variable": "var(--colors-stone-100)"
  },
  "colors.stone.200": {
    "value": "#e7e5e4",
    "variable": "var(--colors-stone-200)"
  },
  "colors.stone.300": {
    "value": "#d6d3d1",
    "variable": "var(--colors-stone-300)"
  },
  "colors.stone.400": {
    "value": "#a8a29e",
    "variable": "var(--colors-stone-400)"
  },
  "colors.stone.500": {
    "value": "#78716c",
    "variable": "var(--colors-stone-500)"
  },
  "colors.stone.600": {
    "value": "#57534e",
    "variable": "var(--colors-stone-600)"
  },
  "colors.stone.700": {
    "value": "#44403c",
    "variable": "var(--colors-stone-700)"
  },
  "colors.stone.800": {
    "value": "#292524",
    "variable": "var(--colors-stone-800)"
  },
  "colors.stone.900": {
    "value": "#1c1917",
    "variable": "var(--colors-stone-900)"
  },
  "colors.stone.950": {
    "value": "#0c0a09",
    "variable": "var(--colors-stone-950)"
  },
  "colors.zinc.50": {
    "value": "#fafafa",
    "variable": "var(--colors-zinc-50)"
  },
  "colors.zinc.100": {
    "value": "#f4f4f5",
    "variable": "var(--colors-zinc-100)"
  },
  "colors.zinc.200": {
    "value": "#e4e4e7",
    "variable": "var(--colors-zinc-200)"
  },
  "colors.zinc.300": {
    "value": "#d4d4d8",
    "variable": "var(--colors-zinc-300)"
  },
  "colors.zinc.400": {
    "value": "#a1a1aa",
    "variable": "var(--colors-zinc-400)"
  },
  "colors.zinc.500": {
    "value": "#71717a",
    "variable": "var(--colors-zinc-500)"
  },
  "colors.zinc.600": {
    "value": "#52525b",
    "variable": "var(--colors-zinc-600)"
  },
  "colors.zinc.700": {
    "value": "#3f3f46",
    "variable": "var(--colors-zinc-700)"
  },
  "colors.zinc.800": {
    "value": "#27272a",
    "variable": "var(--colors-zinc-800)"
  },
  "colors.zinc.900": {
    "value": "#18181b",
    "variable": "var(--colors-zinc-900)"
  },
  "colors.zinc.950": {
    "value": "#09090b",
    "variable": "var(--colors-zinc-950)"
  },
  "colors.gray.50": {
    "value": "#f9fafb",
    "variable": "var(--colors-gray-50)"
  },
  "colors.gray.100": {
    "value": "#f3f4f6",
    "variable": "var(--colors-gray-100)"
  },
  "colors.gray.200": {
    "value": "#e5e7eb",
    "variable": "var(--colors-gray-200)"
  },
  "colors.gray.300": {
    "value": "#d1d5db",
    "variable": "var(--colors-gray-300)"
  },
  "colors.gray.400": {
    "value": "#9ca3af",
    "variable": "var(--colors-gray-400)"
  },
  "colors.gray.500": {
    "value": "#6b7280",
    "variable": "var(--colors-gray-500)"
  },
  "colors.gray.600": {
    "value": "#4b5563",
    "variable": "var(--colors-gray-600)"
  },
  "colors.gray.700": {
    "value": "#374151",
    "variable": "var(--colors-gray-700)"
  },
  "colors.gray.800": {
    "value": "#1f2937",
    "variable": "var(--colors-gray-800)"
  },
  "colors.gray.900": {
    "value": "#111827",
    "variable": "var(--colors-gray-900)"
  },
  "colors.gray.950": {
    "value": "#030712",
    "variable": "var(--colors-gray-950)"
  },
  "colors.slate.50": {
    "value": "#f8fafc",
    "variable": "var(--colors-slate-50)"
  },
  "colors.slate.100": {
    "value": "#f1f5f9",
    "variable": "var(--colors-slate-100)"
  },
  "colors.slate.200": {
    "value": "#e2e8f0",
    "variable": "var(--colors-slate-200)"
  },
  "colors.slate.300": {
    "value": "#cbd5e1",
    "variable": "var(--colors-slate-300)"
  },
  "colors.slate.400": {
    "value": "#94a3b8",
    "variable": "var(--colors-slate-400)"
  },
  "colors.slate.500": {
    "value": "#64748b",
    "variable": "var(--colors-slate-500)"
  },
  "colors.slate.600": {
    "value": "#475569",
    "variable": "var(--colors-slate-600)"
  },
  "colors.slate.700": {
    "value": "#334155",
    "variable": "var(--colors-slate-700)"
  },
  "colors.slate.800": {
    "value": "#1e293b",
    "variable": "var(--colors-slate-800)"
  },
  "colors.slate.900": {
    "value": "#0f172a",
    "variable": "var(--colors-slate-900)"
  },
  "colors.slate.950": {
    "value": "#020617",
    "variable": "var(--colors-slate-950)"
  },
  "blurs.sm": {
    "value": "4px",
    "variable": "var(--blurs-sm)"
  },
  "blurs.base": {
    "value": "8px",
    "variable": "var(--blurs-base)"
  },
  "blurs.md": {
    "value": "12px",
    "variable": "var(--blurs-md)"
  },
  "blurs.lg": {
    "value": "16px",
    "variable": "var(--blurs-lg)"
  },
  "blurs.xl": {
    "value": "24px",
    "variable": "var(--blurs-xl)"
  },
  "blurs.2xl": {
    "value": "40px",
    "variable": "var(--blurs-2xl)"
  },
  "blurs.3xl": {
    "value": "64px",
    "variable": "var(--blurs-3xl)"
  },
  "spacing.0": {
    "value": "0rem",
    "variable": "var(--spacing-0)"
  },
  "spacing.1": {
    "value": "0.25rem",
    "variable": "var(--spacing-1)"
  },
  "spacing.2": {
    "value": "0.5rem",
    "variable": "var(--spacing-2)"
  },
  "spacing.3": {
    "value": "0.75rem",
    "variable": "var(--spacing-3)"
  },
  "spacing.4": {
    "value": "1rem",
    "variable": "var(--spacing-4)"
  },
  "spacing.5": {
    "value": "1.25rem",
    "variable": "var(--spacing-5)"
  },
  "spacing.6": {
    "value": "1.5rem",
    "variable": "var(--spacing-6)"
  },
  "spacing.7": {
    "value": "1.75rem",
    "variable": "var(--spacing-7)"
  },
  "spacing.8": {
    "value": "2rem",
    "variable": "var(--spacing-8)"
  },
  "spacing.9": {
    "value": "2.25rem",
    "variable": "var(--spacing-9)"
  },
  "spacing.10": {
    "value": "2.5rem",
    "variable": "var(--spacing-10)"
  },
  "spacing.11": {
    "value": "2.75rem",
    "variable": "var(--spacing-11)"
  },
  "spacing.12": {
    "value": "3rem",
    "variable": "var(--spacing-12)"
  },
  "spacing.14": {
    "value": "3.5rem",
    "variable": "var(--spacing-14)"
  },
  "spacing.16": {
    "value": "4rem",
    "variable": "var(--spacing-16)"
  },
  "spacing.20": {
    "value": "5rem",
    "variable": "var(--spacing-20)"
  },
  "spacing.24": {
    "value": "6rem",
    "variable": "var(--spacing-24)"
  },
  "spacing.28": {
    "value": "7rem",
    "variable": "var(--spacing-28)"
  },
  "spacing.32": {
    "value": "8rem",
    "variable": "var(--spacing-32)"
  },
  "spacing.36": {
    "value": "9rem",
    "variable": "var(--spacing-36)"
  },
  "spacing.40": {
    "value": "10rem",
    "variable": "var(--spacing-40)"
  },
  "spacing.44": {
    "value": "11rem",
    "variable": "var(--spacing-44)"
  },
  "spacing.48": {
    "value": "12rem",
    "variable": "var(--spacing-48)"
  },
  "spacing.52": {
    "value": "13rem",
    "variable": "var(--spacing-52)"
  },
  "spacing.56": {
    "value": "14rem",
    "variable": "var(--spacing-56)"
  },
  "spacing.60": {
    "value": "15rem",
    "variable": "var(--spacing-60)"
  },
  "spacing.64": {
    "value": "16rem",
    "variable": "var(--spacing-64)"
  },
  "spacing.72": {
    "value": "18rem",
    "variable": "var(--spacing-72)"
  },
  "spacing.80": {
    "value": "20rem",
    "variable": "var(--spacing-80)"
  },
  "spacing.96": {
    "value": "24rem",
    "variable": "var(--spacing-96)"
  },
  "spacing.0.5": {
    "value": "0.125rem",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.1.5": {
    "value": "0.375rem",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.2.5": {
    "value": "0.625rem",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.3.5": {
    "value": "0.875rem",
    "variable": "var(--spacing-3\\.5)"
  },
  "sizes.0": {
    "value": "0rem",
    "variable": "var(--sizes-0)"
  },
  "sizes.1": {
    "value": "0.25rem",
    "variable": "var(--sizes-1)"
  },
  "sizes.2": {
    "value": "0.5rem",
    "variable": "var(--sizes-2)"
  },
  "sizes.3": {
    "value": "0.75rem",
    "variable": "var(--sizes-3)"
  },
  "sizes.4": {
    "value": "1rem",
    "variable": "var(--sizes-4)"
  },
  "sizes.5": {
    "value": "1.25rem",
    "variable": "var(--sizes-5)"
  },
  "sizes.6": {
    "value": "1.5rem",
    "variable": "var(--sizes-6)"
  },
  "sizes.7": {
    "value": "1.75rem",
    "variable": "var(--sizes-7)"
  },
  "sizes.8": {
    "value": "2rem",
    "variable": "var(--sizes-8)"
  },
  "sizes.9": {
    "value": "2.25rem",
    "variable": "var(--sizes-9)"
  },
  "sizes.10": {
    "value": "2.5rem",
    "variable": "var(--sizes-10)"
  },
  "sizes.11": {
    "value": "2.75rem",
    "variable": "var(--sizes-11)"
  },
  "sizes.12": {
    "value": "3rem",
    "variable": "var(--sizes-12)"
  },
  "sizes.14": {
    "value": "3.5rem",
    "variable": "var(--sizes-14)"
  },
  "sizes.16": {
    "value": "4rem",
    "variable": "var(--sizes-16)"
  },
  "sizes.20": {
    "value": "5rem",
    "variable": "var(--sizes-20)"
  },
  "sizes.24": {
    "value": "6rem",
    "variable": "var(--sizes-24)"
  },
  "sizes.28": {
    "value": "7rem",
    "variable": "var(--sizes-28)"
  },
  "sizes.32": {
    "value": "8rem",
    "variable": "var(--sizes-32)"
  },
  "sizes.36": {
    "value": "9rem",
    "variable": "var(--sizes-36)"
  },
  "sizes.40": {
    "value": "10rem",
    "variable": "var(--sizes-40)"
  },
  "sizes.44": {
    "value": "11rem",
    "variable": "var(--sizes-44)"
  },
  "sizes.48": {
    "value": "12rem",
    "variable": "var(--sizes-48)"
  },
  "sizes.52": {
    "value": "13rem",
    "variable": "var(--sizes-52)"
  },
  "sizes.56": {
    "value": "14rem",
    "variable": "var(--sizes-56)"
  },
  "sizes.60": {
    "value": "15rem",
    "variable": "var(--sizes-60)"
  },
  "sizes.64": {
    "value": "16rem",
    "variable": "var(--sizes-64)"
  },
  "sizes.72": {
    "value": "18rem",
    "variable": "var(--sizes-72)"
  },
  "sizes.80": {
    "value": "20rem",
    "variable": "var(--sizes-80)"
  },
  "sizes.96": {
    "value": "24rem",
    "variable": "var(--sizes-96)"
  },
  "sizes.0.5": {
    "value": "0.125rem",
    "variable": "var(--sizes-0\\.5)"
  },
  "sizes.1.5": {
    "value": "0.375rem",
    "variable": "var(--sizes-1\\.5)"
  },
  "sizes.2.5": {
    "value": "0.625rem",
    "variable": "var(--sizes-2\\.5)"
  },
  "sizes.3.5": {
    "value": "0.875rem",
    "variable": "var(--sizes-3\\.5)"
  },
  "sizes.xs": {
    "value": "20rem",
    "variable": "var(--sizes-xs)"
  },
  "sizes.sm": {
    "value": "24rem",
    "variable": "var(--sizes-sm)"
  },
  "sizes.md": {
    "value": "28rem",
    "variable": "var(--sizes-md)"
  },
  "sizes.lg": {
    "value": "32rem",
    "variable": "var(--sizes-lg)"
  },
  "sizes.xl": {
    "value": "36rem",
    "variable": "var(--sizes-xl)"
  },
  "sizes.2xl": {
    "value": "42rem",
    "variable": "var(--sizes-2xl)"
  },
  "sizes.3xl": {
    "value": "48rem",
    "variable": "var(--sizes-3xl)"
  },
  "sizes.4xl": {
    "value": "56rem",
    "variable": "var(--sizes-4xl)"
  },
  "sizes.5xl": {
    "value": "64rem",
    "variable": "var(--sizes-5xl)"
  },
  "sizes.6xl": {
    "value": "72rem",
    "variable": "var(--sizes-6xl)"
  },
  "sizes.7xl": {
    "value": "80rem",
    "variable": "var(--sizes-7xl)"
  },
  "sizes.8xl": {
    "value": "90rem",
    "variable": "var(--sizes-8xl)"
  },
  "sizes.prose": {
    "value": "65ch",
    "variable": "var(--sizes-prose)"
  },
  "sizes.full": {
    "value": "100%",
    "variable": "var(--sizes-full)"
  },
  "sizes.min": {
    "value": "min-content",
    "variable": "var(--sizes-min)"
  },
  "sizes.max": {
    "value": "max-content",
    "variable": "var(--sizes-max)"
  },
  "sizes.fit": {
    "value": "fit-content",
    "variable": "var(--sizes-fit)"
  },
  "sizes.breakpoint-sm": {
    "value": "640px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "1536px",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "animations.spin": {
    "value": "spin 1s linear infinite",
    "variable": "var(--animations-spin)"
  },
  "animations.ping": {
    "value": "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    "variable": "var(--animations-ping)"
  },
  "animations.pulse": {
    "value": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    "variable": "var(--animations-pulse)"
  },
  "animations.bounce": {
    "value": "bounce 1s infinite",
    "variable": "var(--animations-bounce)"
  },
  "easings.default": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-default)"
  },
  "easings.linear": {
    "value": "linear",
    "variable": "var(--easings-linear)"
  },
  "easings.in": {
    "value": "cubic-bezier(0.4, 0, 1, 1)",
    "variable": "var(--easings-in)"
  },
  "easings.out": {
    "value": "cubic-bezier(0, 0, 0.2, 1)",
    "variable": "var(--easings-out)"
  },
  "easings.in-out": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-in-out)"
  },
  "easings.sine-in": {
    "value": "cubic-bezier(0.12, 0, 0.39, 0)",
    "variable": "var(--easings-sine-in)"
  },
  "easings.sine-out": {
    "value": "cubic-bezier(0.61, 1, 0.88, 1)",
    "variable": "var(--easings-sine-out)"
  },
  "easings.sine-in-out": {
    "value": "cubic-bezier(0.37, 0, 0.63, 1)",
    "variable": "var(--easings-sine-in-out)"
  },
  "easings.quad-in": {
    "value": "cubic-bezier(0.11, 0, 0.5, 0)",
    "variable": "var(--easings-quad-in)"
  },
  "easings.quad-out": {
    "value": "cubic-bezier(0.5, 1, 0.89, 1)",
    "variable": "var(--easings-quad-out)"
  },
  "easings.quad-in-out": {
    "value": "cubic-bezier(0.45, 0, 0.55, 1)",
    "variable": "var(--easings-quad-in-out)"
  },
  "easings.cubic-in": {
    "value": "cubic-bezier(0.32, 0, 0.67, 0)",
    "variable": "var(--easings-cubic-in)"
  },
  "easings.cubic-out": {
    "value": "cubic-bezier(0.33, 1, 0.68, 1)",
    "variable": "var(--easings-cubic-out)"
  },
  "easings.cubic-in-out": {
    "value": "cubic-bezier(0.65, 0, 0.35, 1)",
    "variable": "var(--easings-cubic-in-out)"
  },
  "easings.quart-in": {
    "value": "cubic-bezier(0.5, 0, 0.75, 0)",
    "variable": "var(--easings-quart-in)"
  },
  "easings.quart-out": {
    "value": "cubic-bezier(0.25, 1, 0.5, 1)",
    "variable": "var(--easings-quart-out)"
  },
  "easings.quart-in-out": {
    "value": "cubic-bezier(0.76, 0, 0.24, 1)",
    "variable": "var(--easings-quart-in-out)"
  },
  "easings.quint-in": {
    "value": "cubic-bezier(0.64, 0, 0.78, 0)",
    "variable": "var(--easings-quint-in)"
  },
  "easings.quint-out": {
    "value": "cubic-bezier(0.22, 1, 0.36, 1)",
    "variable": "var(--easings-quint-out)"
  },
  "easings.quint-in-out": {
    "value": "cubic-bezier(0.83, 0, 0.17, 1)",
    "variable": "var(--easings-quint-in-out)"
  },
  "easings.expo-in": {
    "value": "cubic-bezier(0.7, 0, 0.84, 0)",
    "variable": "var(--easings-expo-in)"
  },
  "easings.expo-out": {
    "value": "cubic-bezier(0.16, 1, 0.3, 1)",
    "variable": "var(--easings-expo-out)"
  },
  "easings.expo-in-out": {
    "value": "cubic-bezier(0.87, 0, 0.13, 1)",
    "variable": "var(--easings-expo-in-out)"
  },
  "easings.circ-in": {
    "value": "cubic-bezier(0.55, 0, 1, 0.45)",
    "variable": "var(--easings-circ-in)"
  },
  "easings.circ-out": {
    "value": "cubic-bezier(0, 0.55, 0.45, 1)",
    "variable": "var(--easings-circ-out)"
  },
  "easings.circ-in-out": {
    "value": "cubic-bezier(0.85, 0, 0.15, 1)",
    "variable": "var(--easings-circ-in-out)"
  },
  "easings.back-in": {
    "value": "cubic-bezier(0.36, 0, 0.66, -0.56)",
    "variable": "var(--easings-back-in)"
  },
  "easings.back-out": {
    "value": "cubic-bezier(0.34, 1.56, 0.64, 1)",
    "variable": "var(--easings-back-out)"
  },
  "easings.back-in-out": {
    "value": "cubic-bezier(0.68, -0.6, 0.32, 1.6)",
    "variable": "var(--easings-back-in-out)"
  },
  "shadows.xs": {
    "value": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-xs)"
  },
  "shadows.sm": {
    "value": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-sm)"
  },
  "shadows.md": {
    "value": "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-md)"
  },
  "shadows.lg": {
    "value": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-lg)"
  },
  "shadows.xl": {
    "value": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-xl)"
  },
  "shadows.2xl": {
    "value": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
    "variable": "var(--shadows-2xl)"
  },
  "shadows.inner": {
    "value": "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-inner)"
  },
  "shadows.popover": {
    "value": "0 0 0 1px rgba(0,0,0,0.08),0 3.3px 2.7px rgba(0,0,0,.03),0 8.3px 6.9px rgba(0,0,0,.04),0 17px 14.2px rgba(0,0,0,.05),0 35px 29.2px rgba(0,0,0,.06),0 96px 80px rgba(0,0,0,.07)",
    "variable": "var(--shadows-popover)"
  },
  "shadows.dialog": {
    "value": "0 0 0 1px rgba(0,0,0,0.08),0 3.3px 2.7px rgba(0,0,0,.03),0 8.3px 6.9px rgba(0,0,0,.04),0 17px 14.2px rgba(0,0,0,.05),0 35px 29.2px rgba(0,0,0,.06),0 96px 80px rgba(0,0,0,.07)",
    "variable": "var(--shadows-dialog)"
  },
  "shadows.toggle": {
    "value": "0 0 0 1px rgba(0,0,0,0.04), 0px 0px 0px rgba(3, 7, 18, 0.05),0px 1px 1px rgba(3, 7, 18, 0.08),0px 1px 1px rgba(3, 7, 18, 0.03)",
    "variable": "var(--shadows-toggle)"
  },
  "fonts.sans": {
    "value": "ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
    "variable": "var(--fonts-sans)"
  },
  "fonts.serif": {
    "value": "ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif",
    "variable": "var(--fonts-serif)"
  },
  "fonts.mono": {
    "value": "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace",
    "variable": "var(--fonts-mono)"
  },
  "fonts.inter": {
    "value": "InterVariable",
    "variable": "var(--fonts-inter)"
  },
  "fonts.instrumentSerif": {
    "value": "Instrument Serif",
    "variable": "var(--fonts-instrument-serif)"
  },
  "fonts.ibmPlexMono": {
    "value": "IBM Plex Mono",
    "variable": "var(--fonts-ibm-plex-mono)"
  },
  "fonts.editorialNew": {
    "value": "Editorial New",
    "variable": "var(--fonts-editorial-new)"
  },
  "assets.icons.unavailable": {
    "value": "/icons/remix-icon.svg#leaf-line",
    "variable": "var(--assets-icons-unavailable)"
  },
  "assets.icons.external": {
    "value": "/icons/remix-icon.svg#external-link-line",
    "variable": "var(--assets-icons-external)"
  },
  "assets.icons.account": {
    "value": "/icons/remix-icon.svg#account-circle-line",
    "variable": "var(--assets-icons-account)"
  },
  "assets.icons.search": {
    "value": "/icons/remix-icon.svg#search-line",
    "variable": "var(--assets-icons-search)"
  },
  "assets.icons.settings": {
    "value": "/icons/remix-icon.svg#settings-2-line",
    "variable": "var(--assets-icons-settings)"
  },
  "assets.icons.check": {
    "value": "/icons/remix-icon.svg#check-line",
    "variable": "var(--assets-icons-check)"
  },
  "assets.icons.close": {
    "value": "/icons/remix-icon.svg#close-line",
    "variable": "var(--assets-icons-close)"
  },
  "assets.icons.heart": {
    "value": "/icons/remix-icon.svg#heart-line",
    "variable": "var(--assets-icons-heart)"
  },
  "assets.icons.heart-fill": {
    "value": "/icons/remix-icon.svg#heart-fill",
    "variable": "var(--assets-icons-heart-fill)"
  },
  "assets.icons.bookmark": {
    "value": "/icons/remix-icon.svg#bookmark-2-line",
    "variable": "var(--assets-icons-bookmark)"
  },
  "assets.icons.chevron-right": {
    "value": "/icons/remix-icon.svg#arrow-right-s-line",
    "variable": "var(--assets-icons-chevron-right)"
  },
  "assets.icons.chevron-left": {
    "value": "/icons/remix-icon.svg#arrow-left-s-line",
    "variable": "var(--assets-icons-chevron-left)"
  },
  "assets.icons.alert": {
    "value": "/icons/remix-icon.svg#alert-fill",
    "variable": "var(--assets-icons-alert)"
  },
  "assets.icons.warning": {
    "value": "/icons/remix-icon.svg#error-warning-fill",
    "variable": "var(--assets-icons-warning)"
  },
  "assets.icons.expand": {
    "value": "/icons/remix-icon.svg#expand-up-down-line",
    "variable": "var(--assets-icons-expand)"
  },
  "assets.icons.star": {
    "value": "/icons/remix-icon.svg#star-fill",
    "variable": "var(--assets-icons-star)"
  },
  "assets.icons.home": {
    "value": "/icons/remix-icon.svg#home-6-line",
    "variable": "var(--assets-icons-home)"
  },
  "assets.icons.loader": {
    "value": "/icons/remix-icon.svg#loader-4-line",
    "variable": "var(--assets-icons-loader)"
  },
  "assets.icons.delete": {
    "value": "/icons/remix-icon.svg#delete-bin-line",
    "variable": "var(--assets-icons-delete)"
  },
  "assets.icons.share": {
    "value": "/icons/remix-icon.svg#share-line",
    "variable": "var(--assets-icons-share)"
  },
  "assets.icons.filters": {
    "value": "/icons/remix-icon.svg#filter-3-line",
    "variable": "var(--assets-icons-filters)"
  },
  "assets.icons.twitter": {
    "value": "/icons/remix-icon.svg#twitter-line",
    "variable": "var(--assets-icons-twitter)"
  },
  "assets.icons.instagram": {
    "value": "/icons/remix-icon.svg#instagram-line",
    "variable": "var(--assets-icons-instagram)"
  },
  "assets.icons.external-link": {
    "value": "/icons/remix-icon.svg#external-link-line",
    "variable": "var(--assets-icons-external-link)"
  },
  "assets.icons.notification": {
    "value": "/icons/remix-icon.svg#notification-line",
    "variable": "var(--assets-icons-notification)"
  },
  "assets.icons.arrow-left": {
    "value": "/icons/remix-icon.svg#arrow-left-line",
    "variable": "var(--assets-icons-arrow-left)"
  },
  "assets.icons.arrow-right": {
    "value": "/icons/remix-icon.svg#arrow-right-line",
    "variable": "var(--assets-icons-arrow-right)"
  },
  "assets.icons.24-hours-fill": {
    "value": "/icons/remix-icon.svg#24-hours-fill",
    "variable": "var(--assets-icons-24-hours-fill)"
  },
  "assets.icons.24-hours-line": {
    "value": "/icons/remix-icon.svg#24-hours-line",
    "variable": "var(--assets-icons-24-hours-line)"
  },
  "assets.icons.4k-fill": {
    "value": "/icons/remix-icon.svg#4k-fill",
    "variable": "var(--assets-icons-4k-fill)"
  },
  "assets.icons.4k-line": {
    "value": "/icons/remix-icon.svg#4k-line",
    "variable": "var(--assets-icons-4k-line)"
  },
  "assets.icons.a-b": {
    "value": "/icons/remix-icon.svg#a-b",
    "variable": "var(--assets-icons-a-b)"
  },
  "assets.icons.account-box-fill": {
    "value": "/icons/remix-icon.svg#account-box-fill",
    "variable": "var(--assets-icons-account-box-fill)"
  },
  "assets.icons.account-box-line": {
    "value": "/icons/remix-icon.svg#account-box-line",
    "variable": "var(--assets-icons-account-box-line)"
  },
  "assets.icons.account-circle-fill": {
    "value": "/icons/remix-icon.svg#account-circle-fill",
    "variable": "var(--assets-icons-account-circle-fill)"
  },
  "assets.icons.account-circle-line": {
    "value": "/icons/remix-icon.svg#account-circle-line",
    "variable": "var(--assets-icons-account-circle-line)"
  },
  "assets.icons.account-pin-box-fill": {
    "value": "/icons/remix-icon.svg#account-pin-box-fill",
    "variable": "var(--assets-icons-account-pin-box-fill)"
  },
  "assets.icons.account-pin-box-line": {
    "value": "/icons/remix-icon.svg#account-pin-box-line",
    "variable": "var(--assets-icons-account-pin-box-line)"
  },
  "assets.icons.account-pin-circle-fill": {
    "value": "/icons/remix-icon.svg#account-pin-circle-fill",
    "variable": "var(--assets-icons-account-pin-circle-fill)"
  },
  "assets.icons.account-pin-circle-line": {
    "value": "/icons/remix-icon.svg#account-pin-circle-line",
    "variable": "var(--assets-icons-account-pin-circle-line)"
  },
  "assets.icons.add-box-fill": {
    "value": "/icons/remix-icon.svg#add-box-fill",
    "variable": "var(--assets-icons-add-box-fill)"
  },
  "assets.icons.add-box-line": {
    "value": "/icons/remix-icon.svg#add-box-line",
    "variable": "var(--assets-icons-add-box-line)"
  },
  "assets.icons.add-circle-fill": {
    "value": "/icons/remix-icon.svg#add-circle-fill",
    "variable": "var(--assets-icons-add-circle-fill)"
  },
  "assets.icons.add-circle-line": {
    "value": "/icons/remix-icon.svg#add-circle-line",
    "variable": "var(--assets-icons-add-circle-line)"
  },
  "assets.icons.add-fill": {
    "value": "/icons/remix-icon.svg#add-fill",
    "variable": "var(--assets-icons-add-fill)"
  },
  "assets.icons.add-line": {
    "value": "/icons/remix-icon.svg#add-line",
    "variable": "var(--assets-icons-add-line)"
  },
  "assets.icons.admin-fill": {
    "value": "/icons/remix-icon.svg#admin-fill",
    "variable": "var(--assets-icons-admin-fill)"
  },
  "assets.icons.admin-line": {
    "value": "/icons/remix-icon.svg#admin-line",
    "variable": "var(--assets-icons-admin-line)"
  },
  "assets.icons.advertisement-fill": {
    "value": "/icons/remix-icon.svg#advertisement-fill",
    "variable": "var(--assets-icons-advertisement-fill)"
  },
  "assets.icons.advertisement-line": {
    "value": "/icons/remix-icon.svg#advertisement-line",
    "variable": "var(--assets-icons-advertisement-line)"
  },
  "assets.icons.airplay-fill": {
    "value": "/icons/remix-icon.svg#airplay-fill",
    "variable": "var(--assets-icons-airplay-fill)"
  },
  "assets.icons.airplay-line": {
    "value": "/icons/remix-icon.svg#airplay-line",
    "variable": "var(--assets-icons-airplay-line)"
  },
  "assets.icons.alarm-fill": {
    "value": "/icons/remix-icon.svg#alarm-fill",
    "variable": "var(--assets-icons-alarm-fill)"
  },
  "assets.icons.alarm-line": {
    "value": "/icons/remix-icon.svg#alarm-line",
    "variable": "var(--assets-icons-alarm-line)"
  },
  "assets.icons.alarm-warning-fill": {
    "value": "/icons/remix-icon.svg#alarm-warning-fill",
    "variable": "var(--assets-icons-alarm-warning-fill)"
  },
  "assets.icons.alarm-warning-line": {
    "value": "/icons/remix-icon.svg#alarm-warning-line",
    "variable": "var(--assets-icons-alarm-warning-line)"
  },
  "assets.icons.album-fill": {
    "value": "/icons/remix-icon.svg#album-fill",
    "variable": "var(--assets-icons-album-fill)"
  },
  "assets.icons.album-line": {
    "value": "/icons/remix-icon.svg#album-line",
    "variable": "var(--assets-icons-album-line)"
  },
  "assets.icons.alert-fill": {
    "value": "/icons/remix-icon.svg#alert-fill",
    "variable": "var(--assets-icons-alert-fill)"
  },
  "assets.icons.alert-line": {
    "value": "/icons/remix-icon.svg#alert-line",
    "variable": "var(--assets-icons-alert-line)"
  },
  "assets.icons.aliens-fill": {
    "value": "/icons/remix-icon.svg#aliens-fill",
    "variable": "var(--assets-icons-aliens-fill)"
  },
  "assets.icons.aliens-line": {
    "value": "/icons/remix-icon.svg#aliens-line",
    "variable": "var(--assets-icons-aliens-line)"
  },
  "assets.icons.align-bottom": {
    "value": "/icons/remix-icon.svg#align-bottom",
    "variable": "var(--assets-icons-align-bottom)"
  },
  "assets.icons.align-center": {
    "value": "/icons/remix-icon.svg#align-center",
    "variable": "var(--assets-icons-align-center)"
  },
  "assets.icons.align-justify": {
    "value": "/icons/remix-icon.svg#align-justify",
    "variable": "var(--assets-icons-align-justify)"
  },
  "assets.icons.align-left": {
    "value": "/icons/remix-icon.svg#align-left",
    "variable": "var(--assets-icons-align-left)"
  },
  "assets.icons.align-right": {
    "value": "/icons/remix-icon.svg#align-right",
    "variable": "var(--assets-icons-align-right)"
  },
  "assets.icons.align-top": {
    "value": "/icons/remix-icon.svg#align-top",
    "variable": "var(--assets-icons-align-top)"
  },
  "assets.icons.align-vertically": {
    "value": "/icons/remix-icon.svg#align-vertically",
    "variable": "var(--assets-icons-align-vertically)"
  },
  "assets.icons.alipay-fill": {
    "value": "/icons/remix-icon.svg#alipay-fill",
    "variable": "var(--assets-icons-alipay-fill)"
  },
  "assets.icons.alipay-line": {
    "value": "/icons/remix-icon.svg#alipay-line",
    "variable": "var(--assets-icons-alipay-line)"
  },
  "assets.icons.amazon-fill": {
    "value": "/icons/remix-icon.svg#amazon-fill",
    "variable": "var(--assets-icons-amazon-fill)"
  },
  "assets.icons.amazon-line": {
    "value": "/icons/remix-icon.svg#amazon-line",
    "variable": "var(--assets-icons-amazon-line)"
  },
  "assets.icons.anchor-fill": {
    "value": "/icons/remix-icon.svg#anchor-fill",
    "variable": "var(--assets-icons-anchor-fill)"
  },
  "assets.icons.anchor-line": {
    "value": "/icons/remix-icon.svg#anchor-line",
    "variable": "var(--assets-icons-anchor-line)"
  },
  "assets.icons.ancient-gate-fill": {
    "value": "/icons/remix-icon.svg#ancient-gate-fill",
    "variable": "var(--assets-icons-ancient-gate-fill)"
  },
  "assets.icons.ancient-gate-line": {
    "value": "/icons/remix-icon.svg#ancient-gate-line",
    "variable": "var(--assets-icons-ancient-gate-line)"
  },
  "assets.icons.ancient-pavilion-fill": {
    "value": "/icons/remix-icon.svg#ancient-pavilion-fill",
    "variable": "var(--assets-icons-ancient-pavilion-fill)"
  },
  "assets.icons.ancient-pavilion-line": {
    "value": "/icons/remix-icon.svg#ancient-pavilion-line",
    "variable": "var(--assets-icons-ancient-pavilion-line)"
  },
  "assets.icons.android-fill": {
    "value": "/icons/remix-icon.svg#android-fill",
    "variable": "var(--assets-icons-android-fill)"
  },
  "assets.icons.android-line": {
    "value": "/icons/remix-icon.svg#android-line",
    "variable": "var(--assets-icons-android-line)"
  },
  "assets.icons.angularjs-fill": {
    "value": "/icons/remix-icon.svg#angularjs-fill",
    "variable": "var(--assets-icons-angularjs-fill)"
  },
  "assets.icons.angularjs-line": {
    "value": "/icons/remix-icon.svg#angularjs-line",
    "variable": "var(--assets-icons-angularjs-line)"
  },
  "assets.icons.anticlockwise-2-fill": {
    "value": "/icons/remix-icon.svg#anticlockwise-2-fill",
    "variable": "var(--assets-icons-anticlockwise-2-fill)"
  },
  "assets.icons.anticlockwise-2-line": {
    "value": "/icons/remix-icon.svg#anticlockwise-2-line",
    "variable": "var(--assets-icons-anticlockwise-2-line)"
  },
  "assets.icons.anticlockwise-fill": {
    "value": "/icons/remix-icon.svg#anticlockwise-fill",
    "variable": "var(--assets-icons-anticlockwise-fill)"
  },
  "assets.icons.anticlockwise-line": {
    "value": "/icons/remix-icon.svg#anticlockwise-line",
    "variable": "var(--assets-icons-anticlockwise-line)"
  },
  "assets.icons.app-store-fill": {
    "value": "/icons/remix-icon.svg#app-store-fill",
    "variable": "var(--assets-icons-app-store-fill)"
  },
  "assets.icons.app-store-line": {
    "value": "/icons/remix-icon.svg#app-store-line",
    "variable": "var(--assets-icons-app-store-line)"
  },
  "assets.icons.apple-fill": {
    "value": "/icons/remix-icon.svg#apple-fill",
    "variable": "var(--assets-icons-apple-fill)"
  },
  "assets.icons.apple-line": {
    "value": "/icons/remix-icon.svg#apple-line",
    "variable": "var(--assets-icons-apple-line)"
  },
  "assets.icons.apps-2-fill": {
    "value": "/icons/remix-icon.svg#apps-2-fill",
    "variable": "var(--assets-icons-apps-2-fill)"
  },
  "assets.icons.apps-2-line": {
    "value": "/icons/remix-icon.svg#apps-2-line",
    "variable": "var(--assets-icons-apps-2-line)"
  },
  "assets.icons.apps-fill": {
    "value": "/icons/remix-icon.svg#apps-fill",
    "variable": "var(--assets-icons-apps-fill)"
  },
  "assets.icons.apps-line": {
    "value": "/icons/remix-icon.svg#apps-line",
    "variable": "var(--assets-icons-apps-line)"
  },
  "assets.icons.archive-drawer-fill": {
    "value": "/icons/remix-icon.svg#archive-drawer-fill",
    "variable": "var(--assets-icons-archive-drawer-fill)"
  },
  "assets.icons.archive-drawer-line": {
    "value": "/icons/remix-icon.svg#archive-drawer-line",
    "variable": "var(--assets-icons-archive-drawer-line)"
  },
  "assets.icons.archive-fill": {
    "value": "/icons/remix-icon.svg#archive-fill",
    "variable": "var(--assets-icons-archive-fill)"
  },
  "assets.icons.archive-line": {
    "value": "/icons/remix-icon.svg#archive-line",
    "variable": "var(--assets-icons-archive-line)"
  },
  "assets.icons.arrow-down-circle-fill": {
    "value": "/icons/remix-icon.svg#arrow-down-circle-fill",
    "variable": "var(--assets-icons-arrow-down-circle-fill)"
  },
  "assets.icons.arrow-down-circle-line": {
    "value": "/icons/remix-icon.svg#arrow-down-circle-line",
    "variable": "var(--assets-icons-arrow-down-circle-line)"
  },
  "assets.icons.arrow-down-fill": {
    "value": "/icons/remix-icon.svg#arrow-down-fill",
    "variable": "var(--assets-icons-arrow-down-fill)"
  },
  "assets.icons.arrow-down-line": {
    "value": "/icons/remix-icon.svg#arrow-down-line",
    "variable": "var(--assets-icons-arrow-down-line)"
  },
  "assets.icons.arrow-down-s-fill": {
    "value": "/icons/remix-icon.svg#arrow-down-s-fill",
    "variable": "var(--assets-icons-arrow-down-s-fill)"
  },
  "assets.icons.arrow-down-s-line": {
    "value": "/icons/remix-icon.svg#arrow-down-s-line",
    "variable": "var(--assets-icons-arrow-down-s-line)"
  },
  "assets.icons.arrow-drop-down-fill": {
    "value": "/icons/remix-icon.svg#arrow-drop-down-fill",
    "variable": "var(--assets-icons-arrow-drop-down-fill)"
  },
  "assets.icons.arrow-drop-down-line": {
    "value": "/icons/remix-icon.svg#arrow-drop-down-line",
    "variable": "var(--assets-icons-arrow-drop-down-line)"
  },
  "assets.icons.arrow-drop-left-fill": {
    "value": "/icons/remix-icon.svg#arrow-drop-left-fill",
    "variable": "var(--assets-icons-arrow-drop-left-fill)"
  },
  "assets.icons.arrow-drop-left-line": {
    "value": "/icons/remix-icon.svg#arrow-drop-left-line",
    "variable": "var(--assets-icons-arrow-drop-left-line)"
  },
  "assets.icons.arrow-drop-right-fill": {
    "value": "/icons/remix-icon.svg#arrow-drop-right-fill",
    "variable": "var(--assets-icons-arrow-drop-right-fill)"
  },
  "assets.icons.arrow-drop-right-line": {
    "value": "/icons/remix-icon.svg#arrow-drop-right-line",
    "variable": "var(--assets-icons-arrow-drop-right-line)"
  },
  "assets.icons.arrow-drop-up-fill": {
    "value": "/icons/remix-icon.svg#arrow-drop-up-fill",
    "variable": "var(--assets-icons-arrow-drop-up-fill)"
  },
  "assets.icons.arrow-drop-up-line": {
    "value": "/icons/remix-icon.svg#arrow-drop-up-line",
    "variable": "var(--assets-icons-arrow-drop-up-line)"
  },
  "assets.icons.arrow-go-back-fill": {
    "value": "/icons/remix-icon.svg#arrow-go-back-fill",
    "variable": "var(--assets-icons-arrow-go-back-fill)"
  },
  "assets.icons.arrow-go-back-line": {
    "value": "/icons/remix-icon.svg#arrow-go-back-line",
    "variable": "var(--assets-icons-arrow-go-back-line)"
  },
  "assets.icons.arrow-go-forward-fill": {
    "value": "/icons/remix-icon.svg#arrow-go-forward-fill",
    "variable": "var(--assets-icons-arrow-go-forward-fill)"
  },
  "assets.icons.arrow-go-forward-line": {
    "value": "/icons/remix-icon.svg#arrow-go-forward-line",
    "variable": "var(--assets-icons-arrow-go-forward-line)"
  },
  "assets.icons.arrow-left-circle-fill": {
    "value": "/icons/remix-icon.svg#arrow-left-circle-fill",
    "variable": "var(--assets-icons-arrow-left-circle-fill)"
  },
  "assets.icons.arrow-left-circle-line": {
    "value": "/icons/remix-icon.svg#arrow-left-circle-line",
    "variable": "var(--assets-icons-arrow-left-circle-line)"
  },
  "assets.icons.arrow-left-down-fill": {
    "value": "/icons/remix-icon.svg#arrow-left-down-fill",
    "variable": "var(--assets-icons-arrow-left-down-fill)"
  },
  "assets.icons.arrow-left-down-line": {
    "value": "/icons/remix-icon.svg#arrow-left-down-line",
    "variable": "var(--assets-icons-arrow-left-down-line)"
  },
  "assets.icons.arrow-left-fill": {
    "value": "/icons/remix-icon.svg#arrow-left-fill",
    "variable": "var(--assets-icons-arrow-left-fill)"
  },
  "assets.icons.arrow-left-line": {
    "value": "/icons/remix-icon.svg#arrow-left-line",
    "variable": "var(--assets-icons-arrow-left-line)"
  },
  "assets.icons.arrow-left-right-fill": {
    "value": "/icons/remix-icon.svg#arrow-left-right-fill",
    "variable": "var(--assets-icons-arrow-left-right-fill)"
  },
  "assets.icons.arrow-left-right-line": {
    "value": "/icons/remix-icon.svg#arrow-left-right-line",
    "variable": "var(--assets-icons-arrow-left-right-line)"
  },
  "assets.icons.arrow-left-s-fill": {
    "value": "/icons/remix-icon.svg#arrow-left-s-fill",
    "variable": "var(--assets-icons-arrow-left-s-fill)"
  },
  "assets.icons.arrow-left-s-line": {
    "value": "/icons/remix-icon.svg#arrow-left-s-line",
    "variable": "var(--assets-icons-arrow-left-s-line)"
  },
  "assets.icons.arrow-left-up-fill": {
    "value": "/icons/remix-icon.svg#arrow-left-up-fill",
    "variable": "var(--assets-icons-arrow-left-up-fill)"
  },
  "assets.icons.arrow-left-up-line": {
    "value": "/icons/remix-icon.svg#arrow-left-up-line",
    "variable": "var(--assets-icons-arrow-left-up-line)"
  },
  "assets.icons.arrow-right-circle-fill": {
    "value": "/icons/remix-icon.svg#arrow-right-circle-fill",
    "variable": "var(--assets-icons-arrow-right-circle-fill)"
  },
  "assets.icons.arrow-right-circle-line": {
    "value": "/icons/remix-icon.svg#arrow-right-circle-line",
    "variable": "var(--assets-icons-arrow-right-circle-line)"
  },
  "assets.icons.arrow-right-down-fill": {
    "value": "/icons/remix-icon.svg#arrow-right-down-fill",
    "variable": "var(--assets-icons-arrow-right-down-fill)"
  },
  "assets.icons.arrow-right-down-line": {
    "value": "/icons/remix-icon.svg#arrow-right-down-line",
    "variable": "var(--assets-icons-arrow-right-down-line)"
  },
  "assets.icons.arrow-right-fill": {
    "value": "/icons/remix-icon.svg#arrow-right-fill",
    "variable": "var(--assets-icons-arrow-right-fill)"
  },
  "assets.icons.arrow-right-line": {
    "value": "/icons/remix-icon.svg#arrow-right-line",
    "variable": "var(--assets-icons-arrow-right-line)"
  },
  "assets.icons.arrow-right-s-fill": {
    "value": "/icons/remix-icon.svg#arrow-right-s-fill",
    "variable": "var(--assets-icons-arrow-right-s-fill)"
  },
  "assets.icons.arrow-right-s-line": {
    "value": "/icons/remix-icon.svg#arrow-right-s-line",
    "variable": "var(--assets-icons-arrow-right-s-line)"
  },
  "assets.icons.arrow-right-up-fill": {
    "value": "/icons/remix-icon.svg#arrow-right-up-fill",
    "variable": "var(--assets-icons-arrow-right-up-fill)"
  },
  "assets.icons.arrow-right-up-line": {
    "value": "/icons/remix-icon.svg#arrow-right-up-line",
    "variable": "var(--assets-icons-arrow-right-up-line)"
  },
  "assets.icons.arrow-up-circle-fill": {
    "value": "/icons/remix-icon.svg#arrow-up-circle-fill",
    "variable": "var(--assets-icons-arrow-up-circle-fill)"
  },
  "assets.icons.arrow-up-circle-line": {
    "value": "/icons/remix-icon.svg#arrow-up-circle-line",
    "variable": "var(--assets-icons-arrow-up-circle-line)"
  },
  "assets.icons.arrow-up-down-fill": {
    "value": "/icons/remix-icon.svg#arrow-up-down-fill",
    "variable": "var(--assets-icons-arrow-up-down-fill)"
  },
  "assets.icons.arrow-up-down-line": {
    "value": "/icons/remix-icon.svg#arrow-up-down-line",
    "variable": "var(--assets-icons-arrow-up-down-line)"
  },
  "assets.icons.arrow-up-fill": {
    "value": "/icons/remix-icon.svg#arrow-up-fill",
    "variable": "var(--assets-icons-arrow-up-fill)"
  },
  "assets.icons.arrow-up-line": {
    "value": "/icons/remix-icon.svg#arrow-up-line",
    "variable": "var(--assets-icons-arrow-up-line)"
  },
  "assets.icons.arrow-up-s-fill": {
    "value": "/icons/remix-icon.svg#arrow-up-s-fill",
    "variable": "var(--assets-icons-arrow-up-s-fill)"
  },
  "assets.icons.arrow-up-s-line": {
    "value": "/icons/remix-icon.svg#arrow-up-s-line",
    "variable": "var(--assets-icons-arrow-up-s-line)"
  },
  "assets.icons.artboard-2-fill": {
    "value": "/icons/remix-icon.svg#artboard-2-fill",
    "variable": "var(--assets-icons-artboard-2-fill)"
  },
  "assets.icons.artboard-2-line": {
    "value": "/icons/remix-icon.svg#artboard-2-line",
    "variable": "var(--assets-icons-artboard-2-line)"
  },
  "assets.icons.artboard-fill": {
    "value": "/icons/remix-icon.svg#artboard-fill",
    "variable": "var(--assets-icons-artboard-fill)"
  },
  "assets.icons.artboard-line": {
    "value": "/icons/remix-icon.svg#artboard-line",
    "variable": "var(--assets-icons-artboard-line)"
  },
  "assets.icons.article-fill": {
    "value": "/icons/remix-icon.svg#article-fill",
    "variable": "var(--assets-icons-article-fill)"
  },
  "assets.icons.article-line": {
    "value": "/icons/remix-icon.svg#article-line",
    "variable": "var(--assets-icons-article-line)"
  },
  "assets.icons.aspect-ratio-fill": {
    "value": "/icons/remix-icon.svg#aspect-ratio-fill",
    "variable": "var(--assets-icons-aspect-ratio-fill)"
  },
  "assets.icons.aspect-ratio-line": {
    "value": "/icons/remix-icon.svg#aspect-ratio-line",
    "variable": "var(--assets-icons-aspect-ratio-line)"
  },
  "assets.icons.asterisk": {
    "value": "/icons/remix-icon.svg#asterisk",
    "variable": "var(--assets-icons-asterisk)"
  },
  "assets.icons.at-fill": {
    "value": "/icons/remix-icon.svg#at-fill",
    "variable": "var(--assets-icons-at-fill)"
  },
  "assets.icons.at-line": {
    "value": "/icons/remix-icon.svg#at-line",
    "variable": "var(--assets-icons-at-line)"
  },
  "assets.icons.attachment-2": {
    "value": "/icons/remix-icon.svg#attachment-2",
    "variable": "var(--assets-icons-attachment-2)"
  },
  "assets.icons.attachment-fill": {
    "value": "/icons/remix-icon.svg#attachment-fill",
    "variable": "var(--assets-icons-attachment-fill)"
  },
  "assets.icons.attachment-line": {
    "value": "/icons/remix-icon.svg#attachment-line",
    "variable": "var(--assets-icons-attachment-line)"
  },
  "assets.icons.auction-fill": {
    "value": "/icons/remix-icon.svg#auction-fill",
    "variable": "var(--assets-icons-auction-fill)"
  },
  "assets.icons.auction-line": {
    "value": "/icons/remix-icon.svg#auction-line",
    "variable": "var(--assets-icons-auction-line)"
  },
  "assets.icons.award-fill": {
    "value": "/icons/remix-icon.svg#award-fill",
    "variable": "var(--assets-icons-award-fill)"
  },
  "assets.icons.award-line": {
    "value": "/icons/remix-icon.svg#award-line",
    "variable": "var(--assets-icons-award-line)"
  },
  "assets.icons.baidu-fill": {
    "value": "/icons/remix-icon.svg#baidu-fill",
    "variable": "var(--assets-icons-baidu-fill)"
  },
  "assets.icons.baidu-line": {
    "value": "/icons/remix-icon.svg#baidu-line",
    "variable": "var(--assets-icons-baidu-line)"
  },
  "assets.icons.ball-pen-fill": {
    "value": "/icons/remix-icon.svg#ball-pen-fill",
    "variable": "var(--assets-icons-ball-pen-fill)"
  },
  "assets.icons.ball-pen-line": {
    "value": "/icons/remix-icon.svg#ball-pen-line",
    "variable": "var(--assets-icons-ball-pen-line)"
  },
  "assets.icons.bank-card-2-fill": {
    "value": "/icons/remix-icon.svg#bank-card-2-fill",
    "variable": "var(--assets-icons-bank-card-2-fill)"
  },
  "assets.icons.bank-card-2-line": {
    "value": "/icons/remix-icon.svg#bank-card-2-line",
    "variable": "var(--assets-icons-bank-card-2-line)"
  },
  "assets.icons.bank-card-fill": {
    "value": "/icons/remix-icon.svg#bank-card-fill",
    "variable": "var(--assets-icons-bank-card-fill)"
  },
  "assets.icons.bank-card-line": {
    "value": "/icons/remix-icon.svg#bank-card-line",
    "variable": "var(--assets-icons-bank-card-line)"
  },
  "assets.icons.bank-fill": {
    "value": "/icons/remix-icon.svg#bank-fill",
    "variable": "var(--assets-icons-bank-fill)"
  },
  "assets.icons.bank-line": {
    "value": "/icons/remix-icon.svg#bank-line",
    "variable": "var(--assets-icons-bank-line)"
  },
  "assets.icons.bar-chart-2-fill": {
    "value": "/icons/remix-icon.svg#bar-chart-2-fill",
    "variable": "var(--assets-icons-bar-chart-2-fill)"
  },
  "assets.icons.bar-chart-2-line": {
    "value": "/icons/remix-icon.svg#bar-chart-2-line",
    "variable": "var(--assets-icons-bar-chart-2-line)"
  },
  "assets.icons.bar-chart-box-fill": {
    "value": "/icons/remix-icon.svg#bar-chart-box-fill",
    "variable": "var(--assets-icons-bar-chart-box-fill)"
  },
  "assets.icons.bar-chart-box-line": {
    "value": "/icons/remix-icon.svg#bar-chart-box-line",
    "variable": "var(--assets-icons-bar-chart-box-line)"
  },
  "assets.icons.bar-chart-fill": {
    "value": "/icons/remix-icon.svg#bar-chart-fill",
    "variable": "var(--assets-icons-bar-chart-fill)"
  },
  "assets.icons.bar-chart-grouped-fill": {
    "value": "/icons/remix-icon.svg#bar-chart-grouped-fill",
    "variable": "var(--assets-icons-bar-chart-grouped-fill)"
  },
  "assets.icons.bar-chart-grouped-line": {
    "value": "/icons/remix-icon.svg#bar-chart-grouped-line",
    "variable": "var(--assets-icons-bar-chart-grouped-line)"
  },
  "assets.icons.bar-chart-horizontal-fill": {
    "value": "/icons/remix-icon.svg#bar-chart-horizontal-fill",
    "variable": "var(--assets-icons-bar-chart-horizontal-fill)"
  },
  "assets.icons.bar-chart-horizontal-line": {
    "value": "/icons/remix-icon.svg#bar-chart-horizontal-line",
    "variable": "var(--assets-icons-bar-chart-horizontal-line)"
  },
  "assets.icons.bar-chart-line": {
    "value": "/icons/remix-icon.svg#bar-chart-line",
    "variable": "var(--assets-icons-bar-chart-line)"
  },
  "assets.icons.barcode-box-fill": {
    "value": "/icons/remix-icon.svg#barcode-box-fill",
    "variable": "var(--assets-icons-barcode-box-fill)"
  },
  "assets.icons.barcode-box-line": {
    "value": "/icons/remix-icon.svg#barcode-box-line",
    "variable": "var(--assets-icons-barcode-box-line)"
  },
  "assets.icons.barcode-fill": {
    "value": "/icons/remix-icon.svg#barcode-fill",
    "variable": "var(--assets-icons-barcode-fill)"
  },
  "assets.icons.barcode-line": {
    "value": "/icons/remix-icon.svg#barcode-line",
    "variable": "var(--assets-icons-barcode-line)"
  },
  "assets.icons.barricade-fill": {
    "value": "/icons/remix-icon.svg#barricade-fill",
    "variable": "var(--assets-icons-barricade-fill)"
  },
  "assets.icons.barricade-line": {
    "value": "/icons/remix-icon.svg#barricade-line",
    "variable": "var(--assets-icons-barricade-line)"
  },
  "assets.icons.base-station-fill": {
    "value": "/icons/remix-icon.svg#base-station-fill",
    "variable": "var(--assets-icons-base-station-fill)"
  },
  "assets.icons.base-station-line": {
    "value": "/icons/remix-icon.svg#base-station-line",
    "variable": "var(--assets-icons-base-station-line)"
  },
  "assets.icons.basketball-fill": {
    "value": "/icons/remix-icon.svg#basketball-fill",
    "variable": "var(--assets-icons-basketball-fill)"
  },
  "assets.icons.basketball-line": {
    "value": "/icons/remix-icon.svg#basketball-line",
    "variable": "var(--assets-icons-basketball-line)"
  },
  "assets.icons.battery-2-charge-fill": {
    "value": "/icons/remix-icon.svg#battery-2-charge-fill",
    "variable": "var(--assets-icons-battery-2-charge-fill)"
  },
  "assets.icons.battery-2-charge-line": {
    "value": "/icons/remix-icon.svg#battery-2-charge-line",
    "variable": "var(--assets-icons-battery-2-charge-line)"
  },
  "assets.icons.battery-2-fill": {
    "value": "/icons/remix-icon.svg#battery-2-fill",
    "variable": "var(--assets-icons-battery-2-fill)"
  },
  "assets.icons.battery-2-line": {
    "value": "/icons/remix-icon.svg#battery-2-line",
    "variable": "var(--assets-icons-battery-2-line)"
  },
  "assets.icons.battery-charge-fill": {
    "value": "/icons/remix-icon.svg#battery-charge-fill",
    "variable": "var(--assets-icons-battery-charge-fill)"
  },
  "assets.icons.battery-charge-line": {
    "value": "/icons/remix-icon.svg#battery-charge-line",
    "variable": "var(--assets-icons-battery-charge-line)"
  },
  "assets.icons.battery-fill": {
    "value": "/icons/remix-icon.svg#battery-fill",
    "variable": "var(--assets-icons-battery-fill)"
  },
  "assets.icons.battery-line": {
    "value": "/icons/remix-icon.svg#battery-line",
    "variable": "var(--assets-icons-battery-line)"
  },
  "assets.icons.battery-low-fill": {
    "value": "/icons/remix-icon.svg#battery-low-fill",
    "variable": "var(--assets-icons-battery-low-fill)"
  },
  "assets.icons.battery-low-line": {
    "value": "/icons/remix-icon.svg#battery-low-line",
    "variable": "var(--assets-icons-battery-low-line)"
  },
  "assets.icons.battery-saver-fill": {
    "value": "/icons/remix-icon.svg#battery-saver-fill",
    "variable": "var(--assets-icons-battery-saver-fill)"
  },
  "assets.icons.battery-saver-line": {
    "value": "/icons/remix-icon.svg#battery-saver-line",
    "variable": "var(--assets-icons-battery-saver-line)"
  },
  "assets.icons.battery-share-fill": {
    "value": "/icons/remix-icon.svg#battery-share-fill",
    "variable": "var(--assets-icons-battery-share-fill)"
  },
  "assets.icons.battery-share-line": {
    "value": "/icons/remix-icon.svg#battery-share-line",
    "variable": "var(--assets-icons-battery-share-line)"
  },
  "assets.icons.bear-smile-fill": {
    "value": "/icons/remix-icon.svg#bear-smile-fill",
    "variable": "var(--assets-icons-bear-smile-fill)"
  },
  "assets.icons.bear-smile-line": {
    "value": "/icons/remix-icon.svg#bear-smile-line",
    "variable": "var(--assets-icons-bear-smile-line)"
  },
  "assets.icons.behance-fill": {
    "value": "/icons/remix-icon.svg#behance-fill",
    "variable": "var(--assets-icons-behance-fill)"
  },
  "assets.icons.behance-line": {
    "value": "/icons/remix-icon.svg#behance-line",
    "variable": "var(--assets-icons-behance-line)"
  },
  "assets.icons.bell-fill": {
    "value": "/icons/remix-icon.svg#bell-fill",
    "variable": "var(--assets-icons-bell-fill)"
  },
  "assets.icons.bell-line": {
    "value": "/icons/remix-icon.svg#bell-line",
    "variable": "var(--assets-icons-bell-line)"
  },
  "assets.icons.bike-fill": {
    "value": "/icons/remix-icon.svg#bike-fill",
    "variable": "var(--assets-icons-bike-fill)"
  },
  "assets.icons.bike-line": {
    "value": "/icons/remix-icon.svg#bike-line",
    "variable": "var(--assets-icons-bike-line)"
  },
  "assets.icons.bilibili-fill": {
    "value": "/icons/remix-icon.svg#bilibili-fill",
    "variable": "var(--assets-icons-bilibili-fill)"
  },
  "assets.icons.bilibili-line": {
    "value": "/icons/remix-icon.svg#bilibili-line",
    "variable": "var(--assets-icons-bilibili-line)"
  },
  "assets.icons.bill-fill": {
    "value": "/icons/remix-icon.svg#bill-fill",
    "variable": "var(--assets-icons-bill-fill)"
  },
  "assets.icons.bill-line": {
    "value": "/icons/remix-icon.svg#bill-line",
    "variable": "var(--assets-icons-bill-line)"
  },
  "assets.icons.billiards-fill": {
    "value": "/icons/remix-icon.svg#billiards-fill",
    "variable": "var(--assets-icons-billiards-fill)"
  },
  "assets.icons.billiards-line": {
    "value": "/icons/remix-icon.svg#billiards-line",
    "variable": "var(--assets-icons-billiards-line)"
  },
  "assets.icons.bit-coin-fill": {
    "value": "/icons/remix-icon.svg#bit-coin-fill",
    "variable": "var(--assets-icons-bit-coin-fill)"
  },
  "assets.icons.bit-coin-line": {
    "value": "/icons/remix-icon.svg#bit-coin-line",
    "variable": "var(--assets-icons-bit-coin-line)"
  },
  "assets.icons.blaze-fill": {
    "value": "/icons/remix-icon.svg#blaze-fill",
    "variable": "var(--assets-icons-blaze-fill)"
  },
  "assets.icons.blaze-line": {
    "value": "/icons/remix-icon.svg#blaze-line",
    "variable": "var(--assets-icons-blaze-line)"
  },
  "assets.icons.bluetooth-connect-fill": {
    "value": "/icons/remix-icon.svg#bluetooth-connect-fill",
    "variable": "var(--assets-icons-bluetooth-connect-fill)"
  },
  "assets.icons.bluetooth-connect-line": {
    "value": "/icons/remix-icon.svg#bluetooth-connect-line",
    "variable": "var(--assets-icons-bluetooth-connect-line)"
  },
  "assets.icons.bluetooth-fill": {
    "value": "/icons/remix-icon.svg#bluetooth-fill",
    "variable": "var(--assets-icons-bluetooth-fill)"
  },
  "assets.icons.bluetooth-line": {
    "value": "/icons/remix-icon.svg#bluetooth-line",
    "variable": "var(--assets-icons-bluetooth-line)"
  },
  "assets.icons.blur-off-fill": {
    "value": "/icons/remix-icon.svg#blur-off-fill",
    "variable": "var(--assets-icons-blur-off-fill)"
  },
  "assets.icons.blur-off-line": {
    "value": "/icons/remix-icon.svg#blur-off-line",
    "variable": "var(--assets-icons-blur-off-line)"
  },
  "assets.icons.body-scan-fill": {
    "value": "/icons/remix-icon.svg#body-scan-fill",
    "variable": "var(--assets-icons-body-scan-fill)"
  },
  "assets.icons.body-scan-line": {
    "value": "/icons/remix-icon.svg#body-scan-line",
    "variable": "var(--assets-icons-body-scan-line)"
  },
  "assets.icons.bold": {
    "value": "/icons/remix-icon.svg#bold",
    "variable": "var(--assets-icons-bold)"
  },
  "assets.icons.book-2-fill": {
    "value": "/icons/remix-icon.svg#book-2-fill",
    "variable": "var(--assets-icons-book-2-fill)"
  },
  "assets.icons.book-2-line": {
    "value": "/icons/remix-icon.svg#book-2-line",
    "variable": "var(--assets-icons-book-2-line)"
  },
  "assets.icons.book-3-fill": {
    "value": "/icons/remix-icon.svg#book-3-fill",
    "variable": "var(--assets-icons-book-3-fill)"
  },
  "assets.icons.book-3-line": {
    "value": "/icons/remix-icon.svg#book-3-line",
    "variable": "var(--assets-icons-book-3-line)"
  },
  "assets.icons.book-fill": {
    "value": "/icons/remix-icon.svg#book-fill",
    "variable": "var(--assets-icons-book-fill)"
  },
  "assets.icons.book-line": {
    "value": "/icons/remix-icon.svg#book-line",
    "variable": "var(--assets-icons-book-line)"
  },
  "assets.icons.book-mark-fill": {
    "value": "/icons/remix-icon.svg#book-mark-fill",
    "variable": "var(--assets-icons-book-mark-fill)"
  },
  "assets.icons.book-mark-line": {
    "value": "/icons/remix-icon.svg#book-mark-line",
    "variable": "var(--assets-icons-book-mark-line)"
  },
  "assets.icons.book-open-fill": {
    "value": "/icons/remix-icon.svg#book-open-fill",
    "variable": "var(--assets-icons-book-open-fill)"
  },
  "assets.icons.book-open-line": {
    "value": "/icons/remix-icon.svg#book-open-line",
    "variable": "var(--assets-icons-book-open-line)"
  },
  "assets.icons.book-read-fill": {
    "value": "/icons/remix-icon.svg#book-read-fill",
    "variable": "var(--assets-icons-book-read-fill)"
  },
  "assets.icons.book-read-line": {
    "value": "/icons/remix-icon.svg#book-read-line",
    "variable": "var(--assets-icons-book-read-line)"
  },
  "assets.icons.booklet-fill": {
    "value": "/icons/remix-icon.svg#booklet-fill",
    "variable": "var(--assets-icons-booklet-fill)"
  },
  "assets.icons.booklet-line": {
    "value": "/icons/remix-icon.svg#booklet-line",
    "variable": "var(--assets-icons-booklet-line)"
  },
  "assets.icons.bookmark-2-fill": {
    "value": "/icons/remix-icon.svg#bookmark-2-fill",
    "variable": "var(--assets-icons-bookmark-2-fill)"
  },
  "assets.icons.bookmark-2-line": {
    "value": "/icons/remix-icon.svg#bookmark-2-line",
    "variable": "var(--assets-icons-bookmark-2-line)"
  },
  "assets.icons.bookmark-3-fill": {
    "value": "/icons/remix-icon.svg#bookmark-3-fill",
    "variable": "var(--assets-icons-bookmark-3-fill)"
  },
  "assets.icons.bookmark-3-line": {
    "value": "/icons/remix-icon.svg#bookmark-3-line",
    "variable": "var(--assets-icons-bookmark-3-line)"
  },
  "assets.icons.bookmark-fill": {
    "value": "/icons/remix-icon.svg#bookmark-fill",
    "variable": "var(--assets-icons-bookmark-fill)"
  },
  "assets.icons.bookmark-line": {
    "value": "/icons/remix-icon.svg#bookmark-line",
    "variable": "var(--assets-icons-bookmark-line)"
  },
  "assets.icons.boxing-fill": {
    "value": "/icons/remix-icon.svg#boxing-fill",
    "variable": "var(--assets-icons-boxing-fill)"
  },
  "assets.icons.boxing-line": {
    "value": "/icons/remix-icon.svg#boxing-line",
    "variable": "var(--assets-icons-boxing-line)"
  },
  "assets.icons.braces-fill": {
    "value": "/icons/remix-icon.svg#braces-fill",
    "variable": "var(--assets-icons-braces-fill)"
  },
  "assets.icons.braces-line": {
    "value": "/icons/remix-icon.svg#braces-line",
    "variable": "var(--assets-icons-braces-line)"
  },
  "assets.icons.brackets-fill": {
    "value": "/icons/remix-icon.svg#brackets-fill",
    "variable": "var(--assets-icons-brackets-fill)"
  },
  "assets.icons.brackets-line": {
    "value": "/icons/remix-icon.svg#brackets-line",
    "variable": "var(--assets-icons-brackets-line)"
  },
  "assets.icons.briefcase-2-fill": {
    "value": "/icons/remix-icon.svg#briefcase-2-fill",
    "variable": "var(--assets-icons-briefcase-2-fill)"
  },
  "assets.icons.briefcase-2-line": {
    "value": "/icons/remix-icon.svg#briefcase-2-line",
    "variable": "var(--assets-icons-briefcase-2-line)"
  },
  "assets.icons.briefcase-3-fill": {
    "value": "/icons/remix-icon.svg#briefcase-3-fill",
    "variable": "var(--assets-icons-briefcase-3-fill)"
  },
  "assets.icons.briefcase-3-line": {
    "value": "/icons/remix-icon.svg#briefcase-3-line",
    "variable": "var(--assets-icons-briefcase-3-line)"
  },
  "assets.icons.briefcase-4-fill": {
    "value": "/icons/remix-icon.svg#briefcase-4-fill",
    "variable": "var(--assets-icons-briefcase-4-fill)"
  },
  "assets.icons.briefcase-4-line": {
    "value": "/icons/remix-icon.svg#briefcase-4-line",
    "variable": "var(--assets-icons-briefcase-4-line)"
  },
  "assets.icons.briefcase-5-fill": {
    "value": "/icons/remix-icon.svg#briefcase-5-fill",
    "variable": "var(--assets-icons-briefcase-5-fill)"
  },
  "assets.icons.briefcase-5-line": {
    "value": "/icons/remix-icon.svg#briefcase-5-line",
    "variable": "var(--assets-icons-briefcase-5-line)"
  },
  "assets.icons.briefcase-fill": {
    "value": "/icons/remix-icon.svg#briefcase-fill",
    "variable": "var(--assets-icons-briefcase-fill)"
  },
  "assets.icons.briefcase-line": {
    "value": "/icons/remix-icon.svg#briefcase-line",
    "variable": "var(--assets-icons-briefcase-line)"
  },
  "assets.icons.bring-forward": {
    "value": "/icons/remix-icon.svg#bring-forward",
    "variable": "var(--assets-icons-bring-forward)"
  },
  "assets.icons.bring-to-front": {
    "value": "/icons/remix-icon.svg#bring-to-front",
    "variable": "var(--assets-icons-bring-to-front)"
  },
  "assets.icons.broadcast-fill": {
    "value": "/icons/remix-icon.svg#broadcast-fill",
    "variable": "var(--assets-icons-broadcast-fill)"
  },
  "assets.icons.broadcast-line": {
    "value": "/icons/remix-icon.svg#broadcast-line",
    "variable": "var(--assets-icons-broadcast-line)"
  },
  "assets.icons.brush-2-fill": {
    "value": "/icons/remix-icon.svg#brush-2-fill",
    "variable": "var(--assets-icons-brush-2-fill)"
  },
  "assets.icons.brush-2-line": {
    "value": "/icons/remix-icon.svg#brush-2-line",
    "variable": "var(--assets-icons-brush-2-line)"
  },
  "assets.icons.brush-3-fill": {
    "value": "/icons/remix-icon.svg#brush-3-fill",
    "variable": "var(--assets-icons-brush-3-fill)"
  },
  "assets.icons.brush-3-line": {
    "value": "/icons/remix-icon.svg#brush-3-line",
    "variable": "var(--assets-icons-brush-3-line)"
  },
  "assets.icons.brush-4-fill": {
    "value": "/icons/remix-icon.svg#brush-4-fill",
    "variable": "var(--assets-icons-brush-4-fill)"
  },
  "assets.icons.brush-4-line": {
    "value": "/icons/remix-icon.svg#brush-4-line",
    "variable": "var(--assets-icons-brush-4-line)"
  },
  "assets.icons.brush-fill": {
    "value": "/icons/remix-icon.svg#brush-fill",
    "variable": "var(--assets-icons-brush-fill)"
  },
  "assets.icons.brush-line": {
    "value": "/icons/remix-icon.svg#brush-line",
    "variable": "var(--assets-icons-brush-line)"
  },
  "assets.icons.bubble-chart-fill": {
    "value": "/icons/remix-icon.svg#bubble-chart-fill",
    "variable": "var(--assets-icons-bubble-chart-fill)"
  },
  "assets.icons.bubble-chart-line": {
    "value": "/icons/remix-icon.svg#bubble-chart-line",
    "variable": "var(--assets-icons-bubble-chart-line)"
  },
  "assets.icons.bug-2-fill": {
    "value": "/icons/remix-icon.svg#bug-2-fill",
    "variable": "var(--assets-icons-bug-2-fill)"
  },
  "assets.icons.bug-2-line": {
    "value": "/icons/remix-icon.svg#bug-2-line",
    "variable": "var(--assets-icons-bug-2-line)"
  },
  "assets.icons.bug-fill": {
    "value": "/icons/remix-icon.svg#bug-fill",
    "variable": "var(--assets-icons-bug-fill)"
  },
  "assets.icons.bug-line": {
    "value": "/icons/remix-icon.svg#bug-line",
    "variable": "var(--assets-icons-bug-line)"
  },
  "assets.icons.building-2-fill": {
    "value": "/icons/remix-icon.svg#building-2-fill",
    "variable": "var(--assets-icons-building-2-fill)"
  },
  "assets.icons.building-2-line": {
    "value": "/icons/remix-icon.svg#building-2-line",
    "variable": "var(--assets-icons-building-2-line)"
  },
  "assets.icons.building-3-fill": {
    "value": "/icons/remix-icon.svg#building-3-fill",
    "variable": "var(--assets-icons-building-3-fill)"
  },
  "assets.icons.building-3-line": {
    "value": "/icons/remix-icon.svg#building-3-line",
    "variable": "var(--assets-icons-building-3-line)"
  },
  "assets.icons.building-4-fill": {
    "value": "/icons/remix-icon.svg#building-4-fill",
    "variable": "var(--assets-icons-building-4-fill)"
  },
  "assets.icons.building-4-line": {
    "value": "/icons/remix-icon.svg#building-4-line",
    "variable": "var(--assets-icons-building-4-line)"
  },
  "assets.icons.building-fill": {
    "value": "/icons/remix-icon.svg#building-fill",
    "variable": "var(--assets-icons-building-fill)"
  },
  "assets.icons.building-line": {
    "value": "/icons/remix-icon.svg#building-line",
    "variable": "var(--assets-icons-building-line)"
  },
  "assets.icons.bus-2-fill": {
    "value": "/icons/remix-icon.svg#bus-2-fill",
    "variable": "var(--assets-icons-bus-2-fill)"
  },
  "assets.icons.bus-2-line": {
    "value": "/icons/remix-icon.svg#bus-2-line",
    "variable": "var(--assets-icons-bus-2-line)"
  },
  "assets.icons.bus-fill": {
    "value": "/icons/remix-icon.svg#bus-fill",
    "variable": "var(--assets-icons-bus-fill)"
  },
  "assets.icons.bus-line": {
    "value": "/icons/remix-icon.svg#bus-line",
    "variable": "var(--assets-icons-bus-line)"
  },
  "assets.icons.bus-wifi-fill": {
    "value": "/icons/remix-icon.svg#bus-wifi-fill",
    "variable": "var(--assets-icons-bus-wifi-fill)"
  },
  "assets.icons.bus-wifi-line": {
    "value": "/icons/remix-icon.svg#bus-wifi-line",
    "variable": "var(--assets-icons-bus-wifi-line)"
  },
  "assets.icons.cactus-fill": {
    "value": "/icons/remix-icon.svg#cactus-fill",
    "variable": "var(--assets-icons-cactus-fill)"
  },
  "assets.icons.cactus-line": {
    "value": "/icons/remix-icon.svg#cactus-line",
    "variable": "var(--assets-icons-cactus-line)"
  },
  "assets.icons.cake-2-fill": {
    "value": "/icons/remix-icon.svg#cake-2-fill",
    "variable": "var(--assets-icons-cake-2-fill)"
  },
  "assets.icons.cake-2-line": {
    "value": "/icons/remix-icon.svg#cake-2-line",
    "variable": "var(--assets-icons-cake-2-line)"
  },
  "assets.icons.cake-3-fill": {
    "value": "/icons/remix-icon.svg#cake-3-fill",
    "variable": "var(--assets-icons-cake-3-fill)"
  },
  "assets.icons.cake-3-line": {
    "value": "/icons/remix-icon.svg#cake-3-line",
    "variable": "var(--assets-icons-cake-3-line)"
  },
  "assets.icons.cake-fill": {
    "value": "/icons/remix-icon.svg#cake-fill",
    "variable": "var(--assets-icons-cake-fill)"
  },
  "assets.icons.cake-line": {
    "value": "/icons/remix-icon.svg#cake-line",
    "variable": "var(--assets-icons-cake-line)"
  },
  "assets.icons.calculator-fill": {
    "value": "/icons/remix-icon.svg#calculator-fill",
    "variable": "var(--assets-icons-calculator-fill)"
  },
  "assets.icons.calculator-line": {
    "value": "/icons/remix-icon.svg#calculator-line",
    "variable": "var(--assets-icons-calculator-line)"
  },
  "assets.icons.calendar-2-fill": {
    "value": "/icons/remix-icon.svg#calendar-2-fill",
    "variable": "var(--assets-icons-calendar-2-fill)"
  },
  "assets.icons.calendar-2-line": {
    "value": "/icons/remix-icon.svg#calendar-2-line",
    "variable": "var(--assets-icons-calendar-2-line)"
  },
  "assets.icons.calendar-check-fill": {
    "value": "/icons/remix-icon.svg#calendar-check-fill",
    "variable": "var(--assets-icons-calendar-check-fill)"
  },
  "assets.icons.calendar-check-line": {
    "value": "/icons/remix-icon.svg#calendar-check-line",
    "variable": "var(--assets-icons-calendar-check-line)"
  },
  "assets.icons.calendar-event-fill": {
    "value": "/icons/remix-icon.svg#calendar-event-fill",
    "variable": "var(--assets-icons-calendar-event-fill)"
  },
  "assets.icons.calendar-event-line": {
    "value": "/icons/remix-icon.svg#calendar-event-line",
    "variable": "var(--assets-icons-calendar-event-line)"
  },
  "assets.icons.calendar-fill": {
    "value": "/icons/remix-icon.svg#calendar-fill",
    "variable": "var(--assets-icons-calendar-fill)"
  },
  "assets.icons.calendar-line": {
    "value": "/icons/remix-icon.svg#calendar-line",
    "variable": "var(--assets-icons-calendar-line)"
  },
  "assets.icons.calendar-todo-fill": {
    "value": "/icons/remix-icon.svg#calendar-todo-fill",
    "variable": "var(--assets-icons-calendar-todo-fill)"
  },
  "assets.icons.calendar-todo-line": {
    "value": "/icons/remix-icon.svg#calendar-todo-line",
    "variable": "var(--assets-icons-calendar-todo-line)"
  },
  "assets.icons.camera-2-fill": {
    "value": "/icons/remix-icon.svg#camera-2-fill",
    "variable": "var(--assets-icons-camera-2-fill)"
  },
  "assets.icons.camera-2-line": {
    "value": "/icons/remix-icon.svg#camera-2-line",
    "variable": "var(--assets-icons-camera-2-line)"
  },
  "assets.icons.camera-3-fill": {
    "value": "/icons/remix-icon.svg#camera-3-fill",
    "variable": "var(--assets-icons-camera-3-fill)"
  },
  "assets.icons.camera-3-line": {
    "value": "/icons/remix-icon.svg#camera-3-line",
    "variable": "var(--assets-icons-camera-3-line)"
  },
  "assets.icons.camera-fill": {
    "value": "/icons/remix-icon.svg#camera-fill",
    "variable": "var(--assets-icons-camera-fill)"
  },
  "assets.icons.camera-lens-fill": {
    "value": "/icons/remix-icon.svg#camera-lens-fill",
    "variable": "var(--assets-icons-camera-lens-fill)"
  },
  "assets.icons.camera-lens-line": {
    "value": "/icons/remix-icon.svg#camera-lens-line",
    "variable": "var(--assets-icons-camera-lens-line)"
  },
  "assets.icons.camera-line": {
    "value": "/icons/remix-icon.svg#camera-line",
    "variable": "var(--assets-icons-camera-line)"
  },
  "assets.icons.camera-off-fill": {
    "value": "/icons/remix-icon.svg#camera-off-fill",
    "variable": "var(--assets-icons-camera-off-fill)"
  },
  "assets.icons.camera-off-line": {
    "value": "/icons/remix-icon.svg#camera-off-line",
    "variable": "var(--assets-icons-camera-off-line)"
  },
  "assets.icons.camera-switch-fill": {
    "value": "/icons/remix-icon.svg#camera-switch-fill",
    "variable": "var(--assets-icons-camera-switch-fill)"
  },
  "assets.icons.camera-switch-line": {
    "value": "/icons/remix-icon.svg#camera-switch-line",
    "variable": "var(--assets-icons-camera-switch-line)"
  },
  "assets.icons.capsule-fill": {
    "value": "/icons/remix-icon.svg#capsule-fill",
    "variable": "var(--assets-icons-capsule-fill)"
  },
  "assets.icons.capsule-line": {
    "value": "/icons/remix-icon.svg#capsule-line",
    "variable": "var(--assets-icons-capsule-line)"
  },
  "assets.icons.car-fill": {
    "value": "/icons/remix-icon.svg#car-fill",
    "variable": "var(--assets-icons-car-fill)"
  },
  "assets.icons.car-line": {
    "value": "/icons/remix-icon.svg#car-line",
    "variable": "var(--assets-icons-car-line)"
  },
  "assets.icons.car-washing-fill": {
    "value": "/icons/remix-icon.svg#car-washing-fill",
    "variable": "var(--assets-icons-car-washing-fill)"
  },
  "assets.icons.car-washing-line": {
    "value": "/icons/remix-icon.svg#car-washing-line",
    "variable": "var(--assets-icons-car-washing-line)"
  },
  "assets.icons.caravan-fill": {
    "value": "/icons/remix-icon.svg#caravan-fill",
    "variable": "var(--assets-icons-caravan-fill)"
  },
  "assets.icons.caravan-line": {
    "value": "/icons/remix-icon.svg#caravan-line",
    "variable": "var(--assets-icons-caravan-line)"
  },
  "assets.icons.cast-fill": {
    "value": "/icons/remix-icon.svg#cast-fill",
    "variable": "var(--assets-icons-cast-fill)"
  },
  "assets.icons.cast-line": {
    "value": "/icons/remix-icon.svg#cast-line",
    "variable": "var(--assets-icons-cast-line)"
  },
  "assets.icons.cellphone-fill": {
    "value": "/icons/remix-icon.svg#cellphone-fill",
    "variable": "var(--assets-icons-cellphone-fill)"
  },
  "assets.icons.cellphone-line": {
    "value": "/icons/remix-icon.svg#cellphone-line",
    "variable": "var(--assets-icons-cellphone-line)"
  },
  "assets.icons.celsius-fill": {
    "value": "/icons/remix-icon.svg#celsius-fill",
    "variable": "var(--assets-icons-celsius-fill)"
  },
  "assets.icons.celsius-line": {
    "value": "/icons/remix-icon.svg#celsius-line",
    "variable": "var(--assets-icons-celsius-line)"
  },
  "assets.icons.centos-fill": {
    "value": "/icons/remix-icon.svg#centos-fill",
    "variable": "var(--assets-icons-centos-fill)"
  },
  "assets.icons.centos-line": {
    "value": "/icons/remix-icon.svg#centos-line",
    "variable": "var(--assets-icons-centos-line)"
  },
  "assets.icons.character-recognition-fill": {
    "value": "/icons/remix-icon.svg#character-recognition-fill",
    "variable": "var(--assets-icons-character-recognition-fill)"
  },
  "assets.icons.character-recognition-line": {
    "value": "/icons/remix-icon.svg#character-recognition-line",
    "variable": "var(--assets-icons-character-recognition-line)"
  },
  "assets.icons.charging-pile-2-fill": {
    "value": "/icons/remix-icon.svg#charging-pile-2-fill",
    "variable": "var(--assets-icons-charging-pile-2-fill)"
  },
  "assets.icons.charging-pile-2-line": {
    "value": "/icons/remix-icon.svg#charging-pile-2-line",
    "variable": "var(--assets-icons-charging-pile-2-line)"
  },
  "assets.icons.charging-pile-fill": {
    "value": "/icons/remix-icon.svg#charging-pile-fill",
    "variable": "var(--assets-icons-charging-pile-fill)"
  },
  "assets.icons.charging-pile-line": {
    "value": "/icons/remix-icon.svg#charging-pile-line",
    "variable": "var(--assets-icons-charging-pile-line)"
  },
  "assets.icons.chat-1-fill": {
    "value": "/icons/remix-icon.svg#chat-1-fill",
    "variable": "var(--assets-icons-chat-1-fill)"
  },
  "assets.icons.chat-1-line": {
    "value": "/icons/remix-icon.svg#chat-1-line",
    "variable": "var(--assets-icons-chat-1-line)"
  },
  "assets.icons.chat-2-fill": {
    "value": "/icons/remix-icon.svg#chat-2-fill",
    "variable": "var(--assets-icons-chat-2-fill)"
  },
  "assets.icons.chat-2-line": {
    "value": "/icons/remix-icon.svg#chat-2-line",
    "variable": "var(--assets-icons-chat-2-line)"
  },
  "assets.icons.chat-3-fill": {
    "value": "/icons/remix-icon.svg#chat-3-fill",
    "variable": "var(--assets-icons-chat-3-fill)"
  },
  "assets.icons.chat-3-line": {
    "value": "/icons/remix-icon.svg#chat-3-line",
    "variable": "var(--assets-icons-chat-3-line)"
  },
  "assets.icons.chat-4-fill": {
    "value": "/icons/remix-icon.svg#chat-4-fill",
    "variable": "var(--assets-icons-chat-4-fill)"
  },
  "assets.icons.chat-4-line": {
    "value": "/icons/remix-icon.svg#chat-4-line",
    "variable": "var(--assets-icons-chat-4-line)"
  },
  "assets.icons.chat-check-fill": {
    "value": "/icons/remix-icon.svg#chat-check-fill",
    "variable": "var(--assets-icons-chat-check-fill)"
  },
  "assets.icons.chat-check-line": {
    "value": "/icons/remix-icon.svg#chat-check-line",
    "variable": "var(--assets-icons-chat-check-line)"
  },
  "assets.icons.chat-delete-fill": {
    "value": "/icons/remix-icon.svg#chat-delete-fill",
    "variable": "var(--assets-icons-chat-delete-fill)"
  },
  "assets.icons.chat-delete-line": {
    "value": "/icons/remix-icon.svg#chat-delete-line",
    "variable": "var(--assets-icons-chat-delete-line)"
  },
  "assets.icons.chat-download-fill": {
    "value": "/icons/remix-icon.svg#chat-download-fill",
    "variable": "var(--assets-icons-chat-download-fill)"
  },
  "assets.icons.chat-download-line": {
    "value": "/icons/remix-icon.svg#chat-download-line",
    "variable": "var(--assets-icons-chat-download-line)"
  },
  "assets.icons.chat-follow-up-fill": {
    "value": "/icons/remix-icon.svg#chat-follow-up-fill",
    "variable": "var(--assets-icons-chat-follow-up-fill)"
  },
  "assets.icons.chat-follow-up-line": {
    "value": "/icons/remix-icon.svg#chat-follow-up-line",
    "variable": "var(--assets-icons-chat-follow-up-line)"
  },
  "assets.icons.chat-forward-fill": {
    "value": "/icons/remix-icon.svg#chat-forward-fill",
    "variable": "var(--assets-icons-chat-forward-fill)"
  },
  "assets.icons.chat-forward-line": {
    "value": "/icons/remix-icon.svg#chat-forward-line",
    "variable": "var(--assets-icons-chat-forward-line)"
  },
  "assets.icons.chat-heart-fill": {
    "value": "/icons/remix-icon.svg#chat-heart-fill",
    "variable": "var(--assets-icons-chat-heart-fill)"
  },
  "assets.icons.chat-heart-line": {
    "value": "/icons/remix-icon.svg#chat-heart-line",
    "variable": "var(--assets-icons-chat-heart-line)"
  },
  "assets.icons.chat-history-fill": {
    "value": "/icons/remix-icon.svg#chat-history-fill",
    "variable": "var(--assets-icons-chat-history-fill)"
  },
  "assets.icons.chat-history-line": {
    "value": "/icons/remix-icon.svg#chat-history-line",
    "variable": "var(--assets-icons-chat-history-line)"
  },
  "assets.icons.chat-new-fill": {
    "value": "/icons/remix-icon.svg#chat-new-fill",
    "variable": "var(--assets-icons-chat-new-fill)"
  },
  "assets.icons.chat-new-line": {
    "value": "/icons/remix-icon.svg#chat-new-line",
    "variable": "var(--assets-icons-chat-new-line)"
  },
  "assets.icons.chat-off-fill": {
    "value": "/icons/remix-icon.svg#chat-off-fill",
    "variable": "var(--assets-icons-chat-off-fill)"
  },
  "assets.icons.chat-off-line": {
    "value": "/icons/remix-icon.svg#chat-off-line",
    "variable": "var(--assets-icons-chat-off-line)"
  },
  "assets.icons.chat-poll-fill": {
    "value": "/icons/remix-icon.svg#chat-poll-fill",
    "variable": "var(--assets-icons-chat-poll-fill)"
  },
  "assets.icons.chat-poll-line": {
    "value": "/icons/remix-icon.svg#chat-poll-line",
    "variable": "var(--assets-icons-chat-poll-line)"
  },
  "assets.icons.chat-private-fill": {
    "value": "/icons/remix-icon.svg#chat-private-fill",
    "variable": "var(--assets-icons-chat-private-fill)"
  },
  "assets.icons.chat-private-line": {
    "value": "/icons/remix-icon.svg#chat-private-line",
    "variable": "var(--assets-icons-chat-private-line)"
  },
  "assets.icons.chat-quote-fill": {
    "value": "/icons/remix-icon.svg#chat-quote-fill",
    "variable": "var(--assets-icons-chat-quote-fill)"
  },
  "assets.icons.chat-quote-line": {
    "value": "/icons/remix-icon.svg#chat-quote-line",
    "variable": "var(--assets-icons-chat-quote-line)"
  },
  "assets.icons.chat-settings-fill": {
    "value": "/icons/remix-icon.svg#chat-settings-fill",
    "variable": "var(--assets-icons-chat-settings-fill)"
  },
  "assets.icons.chat-settings-line": {
    "value": "/icons/remix-icon.svg#chat-settings-line",
    "variable": "var(--assets-icons-chat-settings-line)"
  },
  "assets.icons.chat-smile-2-fill": {
    "value": "/icons/remix-icon.svg#chat-smile-2-fill",
    "variable": "var(--assets-icons-chat-smile-2-fill)"
  },
  "assets.icons.chat-smile-2-line": {
    "value": "/icons/remix-icon.svg#chat-smile-2-line",
    "variable": "var(--assets-icons-chat-smile-2-line)"
  },
  "assets.icons.chat-smile-3-fill": {
    "value": "/icons/remix-icon.svg#chat-smile-3-fill",
    "variable": "var(--assets-icons-chat-smile-3-fill)"
  },
  "assets.icons.chat-smile-3-line": {
    "value": "/icons/remix-icon.svg#chat-smile-3-line",
    "variable": "var(--assets-icons-chat-smile-3-line)"
  },
  "assets.icons.chat-smile-fill": {
    "value": "/icons/remix-icon.svg#chat-smile-fill",
    "variable": "var(--assets-icons-chat-smile-fill)"
  },
  "assets.icons.chat-smile-line": {
    "value": "/icons/remix-icon.svg#chat-smile-line",
    "variable": "var(--assets-icons-chat-smile-line)"
  },
  "assets.icons.chat-upload-fill": {
    "value": "/icons/remix-icon.svg#chat-upload-fill",
    "variable": "var(--assets-icons-chat-upload-fill)"
  },
  "assets.icons.chat-upload-line": {
    "value": "/icons/remix-icon.svg#chat-upload-line",
    "variable": "var(--assets-icons-chat-upload-line)"
  },
  "assets.icons.chat-voice-fill": {
    "value": "/icons/remix-icon.svg#chat-voice-fill",
    "variable": "var(--assets-icons-chat-voice-fill)"
  },
  "assets.icons.chat-voice-line": {
    "value": "/icons/remix-icon.svg#chat-voice-line",
    "variable": "var(--assets-icons-chat-voice-line)"
  },
  "assets.icons.check-double-fill": {
    "value": "/icons/remix-icon.svg#check-double-fill",
    "variable": "var(--assets-icons-check-double-fill)"
  },
  "assets.icons.check-double-line": {
    "value": "/icons/remix-icon.svg#check-double-line",
    "variable": "var(--assets-icons-check-double-line)"
  },
  "assets.icons.check-fill": {
    "value": "/icons/remix-icon.svg#check-fill",
    "variable": "var(--assets-icons-check-fill)"
  },
  "assets.icons.check-line": {
    "value": "/icons/remix-icon.svg#check-line",
    "variable": "var(--assets-icons-check-line)"
  },
  "assets.icons.checkbox-blank-circle-fill": {
    "value": "/icons/remix-icon.svg#checkbox-blank-circle-fill",
    "variable": "var(--assets-icons-checkbox-blank-circle-fill)"
  },
  "assets.icons.checkbox-blank-circle-line": {
    "value": "/icons/remix-icon.svg#checkbox-blank-circle-line",
    "variable": "var(--assets-icons-checkbox-blank-circle-line)"
  },
  "assets.icons.checkbox-blank-fill": {
    "value": "/icons/remix-icon.svg#checkbox-blank-fill",
    "variable": "var(--assets-icons-checkbox-blank-fill)"
  },
  "assets.icons.checkbox-blank-line": {
    "value": "/icons/remix-icon.svg#checkbox-blank-line",
    "variable": "var(--assets-icons-checkbox-blank-line)"
  },
  "assets.icons.checkbox-circle-fill": {
    "value": "/icons/remix-icon.svg#checkbox-circle-fill",
    "variable": "var(--assets-icons-checkbox-circle-fill)"
  },
  "assets.icons.checkbox-circle-line": {
    "value": "/icons/remix-icon.svg#checkbox-circle-line",
    "variable": "var(--assets-icons-checkbox-circle-line)"
  },
  "assets.icons.checkbox-fill": {
    "value": "/icons/remix-icon.svg#checkbox-fill",
    "variable": "var(--assets-icons-checkbox-fill)"
  },
  "assets.icons.checkbox-indeterminate-fill": {
    "value": "/icons/remix-icon.svg#checkbox-indeterminate-fill",
    "variable": "var(--assets-icons-checkbox-indeterminate-fill)"
  },
  "assets.icons.checkbox-indeterminate-line": {
    "value": "/icons/remix-icon.svg#checkbox-indeterminate-line",
    "variable": "var(--assets-icons-checkbox-indeterminate-line)"
  },
  "assets.icons.checkbox-line": {
    "value": "/icons/remix-icon.svg#checkbox-line",
    "variable": "var(--assets-icons-checkbox-line)"
  },
  "assets.icons.checkbox-multiple-blank-fill": {
    "value": "/icons/remix-icon.svg#checkbox-multiple-blank-fill",
    "variable": "var(--assets-icons-checkbox-multiple-blank-fill)"
  },
  "assets.icons.checkbox-multiple-blank-line": {
    "value": "/icons/remix-icon.svg#checkbox-multiple-blank-line",
    "variable": "var(--assets-icons-checkbox-multiple-blank-line)"
  },
  "assets.icons.checkbox-multiple-fill": {
    "value": "/icons/remix-icon.svg#checkbox-multiple-fill",
    "variable": "var(--assets-icons-checkbox-multiple-fill)"
  },
  "assets.icons.checkbox-multiple-line": {
    "value": "/icons/remix-icon.svg#checkbox-multiple-line",
    "variable": "var(--assets-icons-checkbox-multiple-line)"
  },
  "assets.icons.china-railway-fill": {
    "value": "/icons/remix-icon.svg#china-railway-fill",
    "variable": "var(--assets-icons-china-railway-fill)"
  },
  "assets.icons.china-railway-line": {
    "value": "/icons/remix-icon.svg#china-railway-line",
    "variable": "var(--assets-icons-china-railway-line)"
  },
  "assets.icons.chrome-fill": {
    "value": "/icons/remix-icon.svg#chrome-fill",
    "variable": "var(--assets-icons-chrome-fill)"
  },
  "assets.icons.chrome-line": {
    "value": "/icons/remix-icon.svg#chrome-line",
    "variable": "var(--assets-icons-chrome-line)"
  },
  "assets.icons.clapperboard-fill": {
    "value": "/icons/remix-icon.svg#clapperboard-fill",
    "variable": "var(--assets-icons-clapperboard-fill)"
  },
  "assets.icons.clapperboard-line": {
    "value": "/icons/remix-icon.svg#clapperboard-line",
    "variable": "var(--assets-icons-clapperboard-line)"
  },
  "assets.icons.clipboard-fill": {
    "value": "/icons/remix-icon.svg#clipboard-fill",
    "variable": "var(--assets-icons-clipboard-fill)"
  },
  "assets.icons.clipboard-line": {
    "value": "/icons/remix-icon.svg#clipboard-line",
    "variable": "var(--assets-icons-clipboard-line)"
  },
  "assets.icons.clockwise-2-fill": {
    "value": "/icons/remix-icon.svg#clockwise-2-fill",
    "variable": "var(--assets-icons-clockwise-2-fill)"
  },
  "assets.icons.clockwise-2-line": {
    "value": "/icons/remix-icon.svg#clockwise-2-line",
    "variable": "var(--assets-icons-clockwise-2-line)"
  },
  "assets.icons.clockwise-fill": {
    "value": "/icons/remix-icon.svg#clockwise-fill",
    "variable": "var(--assets-icons-clockwise-fill)"
  },
  "assets.icons.clockwise-line": {
    "value": "/icons/remix-icon.svg#clockwise-line",
    "variable": "var(--assets-icons-clockwise-line)"
  },
  "assets.icons.close-circle-fill": {
    "value": "/icons/remix-icon.svg#close-circle-fill",
    "variable": "var(--assets-icons-close-circle-fill)"
  },
  "assets.icons.close-circle-line": {
    "value": "/icons/remix-icon.svg#close-circle-line",
    "variable": "var(--assets-icons-close-circle-line)"
  },
  "assets.icons.close-fill": {
    "value": "/icons/remix-icon.svg#close-fill",
    "variable": "var(--assets-icons-close-fill)"
  },
  "assets.icons.close-line": {
    "value": "/icons/remix-icon.svg#close-line",
    "variable": "var(--assets-icons-close-line)"
  },
  "assets.icons.closed-captioning-fill": {
    "value": "/icons/remix-icon.svg#closed-captioning-fill",
    "variable": "var(--assets-icons-closed-captioning-fill)"
  },
  "assets.icons.closed-captioning-line": {
    "value": "/icons/remix-icon.svg#closed-captioning-line",
    "variable": "var(--assets-icons-closed-captioning-line)"
  },
  "assets.icons.cloud-fill": {
    "value": "/icons/remix-icon.svg#cloud-fill",
    "variable": "var(--assets-icons-cloud-fill)"
  },
  "assets.icons.cloud-line": {
    "value": "/icons/remix-icon.svg#cloud-line",
    "variable": "var(--assets-icons-cloud-line)"
  },
  "assets.icons.cloud-off-fill": {
    "value": "/icons/remix-icon.svg#cloud-off-fill",
    "variable": "var(--assets-icons-cloud-off-fill)"
  },
  "assets.icons.cloud-off-line": {
    "value": "/icons/remix-icon.svg#cloud-off-line",
    "variable": "var(--assets-icons-cloud-off-line)"
  },
  "assets.icons.cloud-windy-fill": {
    "value": "/icons/remix-icon.svg#cloud-windy-fill",
    "variable": "var(--assets-icons-cloud-windy-fill)"
  },
  "assets.icons.cloud-windy-line": {
    "value": "/icons/remix-icon.svg#cloud-windy-line",
    "variable": "var(--assets-icons-cloud-windy-line)"
  },
  "assets.icons.cloudy-2-fill": {
    "value": "/icons/remix-icon.svg#cloudy-2-fill",
    "variable": "var(--assets-icons-cloudy-2-fill)"
  },
  "assets.icons.cloudy-2-line": {
    "value": "/icons/remix-icon.svg#cloudy-2-line",
    "variable": "var(--assets-icons-cloudy-2-line)"
  },
  "assets.icons.cloudy-fill": {
    "value": "/icons/remix-icon.svg#cloudy-fill",
    "variable": "var(--assets-icons-cloudy-fill)"
  },
  "assets.icons.cloudy-line": {
    "value": "/icons/remix-icon.svg#cloudy-line",
    "variable": "var(--assets-icons-cloudy-line)"
  },
  "assets.icons.code-box-fill": {
    "value": "/icons/remix-icon.svg#code-box-fill",
    "variable": "var(--assets-icons-code-box-fill)"
  },
  "assets.icons.code-box-line": {
    "value": "/icons/remix-icon.svg#code-box-line",
    "variable": "var(--assets-icons-code-box-line)"
  },
  "assets.icons.code-fill": {
    "value": "/icons/remix-icon.svg#code-fill",
    "variable": "var(--assets-icons-code-fill)"
  },
  "assets.icons.code-line": {
    "value": "/icons/remix-icon.svg#code-line",
    "variable": "var(--assets-icons-code-line)"
  },
  "assets.icons.code-s-fill": {
    "value": "/icons/remix-icon.svg#code-s-fill",
    "variable": "var(--assets-icons-code-s-fill)"
  },
  "assets.icons.code-s-line": {
    "value": "/icons/remix-icon.svg#code-s-line",
    "variable": "var(--assets-icons-code-s-line)"
  },
  "assets.icons.code-s-slash-fill": {
    "value": "/icons/remix-icon.svg#code-s-slash-fill",
    "variable": "var(--assets-icons-code-s-slash-fill)"
  },
  "assets.icons.code-s-slash-line": {
    "value": "/icons/remix-icon.svg#code-s-slash-line",
    "variable": "var(--assets-icons-code-s-slash-line)"
  },
  "assets.icons.code-view": {
    "value": "/icons/remix-icon.svg#code-view",
    "variable": "var(--assets-icons-code-view)"
  },
  "assets.icons.codepen-fill": {
    "value": "/icons/remix-icon.svg#codepen-fill",
    "variable": "var(--assets-icons-codepen-fill)"
  },
  "assets.icons.codepen-line": {
    "value": "/icons/remix-icon.svg#codepen-line",
    "variable": "var(--assets-icons-codepen-line)"
  },
  "assets.icons.coin-fill": {
    "value": "/icons/remix-icon.svg#coin-fill",
    "variable": "var(--assets-icons-coin-fill)"
  },
  "assets.icons.coin-line": {
    "value": "/icons/remix-icon.svg#coin-line",
    "variable": "var(--assets-icons-coin-line)"
  },
  "assets.icons.coins-fill": {
    "value": "/icons/remix-icon.svg#coins-fill",
    "variable": "var(--assets-icons-coins-fill)"
  },
  "assets.icons.coins-line": {
    "value": "/icons/remix-icon.svg#coins-line",
    "variable": "var(--assets-icons-coins-line)"
  },
  "assets.icons.collage-fill": {
    "value": "/icons/remix-icon.svg#collage-fill",
    "variable": "var(--assets-icons-collage-fill)"
  },
  "assets.icons.collage-line": {
    "value": "/icons/remix-icon.svg#collage-line",
    "variable": "var(--assets-icons-collage-line)"
  },
  "assets.icons.command-fill": {
    "value": "/icons/remix-icon.svg#command-fill",
    "variable": "var(--assets-icons-command-fill)"
  },
  "assets.icons.command-line": {
    "value": "/icons/remix-icon.svg#command-line",
    "variable": "var(--assets-icons-command-line)"
  },
  "assets.icons.community-fill": {
    "value": "/icons/remix-icon.svg#community-fill",
    "variable": "var(--assets-icons-community-fill)"
  },
  "assets.icons.community-line": {
    "value": "/icons/remix-icon.svg#community-line",
    "variable": "var(--assets-icons-community-line)"
  },
  "assets.icons.compass-2-fill": {
    "value": "/icons/remix-icon.svg#compass-2-fill",
    "variable": "var(--assets-icons-compass-2-fill)"
  },
  "assets.icons.compass-2-line": {
    "value": "/icons/remix-icon.svg#compass-2-line",
    "variable": "var(--assets-icons-compass-2-line)"
  },
  "assets.icons.compass-3-fill": {
    "value": "/icons/remix-icon.svg#compass-3-fill",
    "variable": "var(--assets-icons-compass-3-fill)"
  },
  "assets.icons.compass-3-line": {
    "value": "/icons/remix-icon.svg#compass-3-line",
    "variable": "var(--assets-icons-compass-3-line)"
  },
  "assets.icons.compass-4-fill": {
    "value": "/icons/remix-icon.svg#compass-4-fill",
    "variable": "var(--assets-icons-compass-4-fill)"
  },
  "assets.icons.compass-4-line": {
    "value": "/icons/remix-icon.svg#compass-4-line",
    "variable": "var(--assets-icons-compass-4-line)"
  },
  "assets.icons.compass-discover-fill": {
    "value": "/icons/remix-icon.svg#compass-discover-fill",
    "variable": "var(--assets-icons-compass-discover-fill)"
  },
  "assets.icons.compass-discover-line": {
    "value": "/icons/remix-icon.svg#compass-discover-line",
    "variable": "var(--assets-icons-compass-discover-line)"
  },
  "assets.icons.compass-fill": {
    "value": "/icons/remix-icon.svg#compass-fill",
    "variable": "var(--assets-icons-compass-fill)"
  },
  "assets.icons.compass-line": {
    "value": "/icons/remix-icon.svg#compass-line",
    "variable": "var(--assets-icons-compass-line)"
  },
  "assets.icons.compasses-2-fill": {
    "value": "/icons/remix-icon.svg#compasses-2-fill",
    "variable": "var(--assets-icons-compasses-2-fill)"
  },
  "assets.icons.compasses-2-line": {
    "value": "/icons/remix-icon.svg#compasses-2-line",
    "variable": "var(--assets-icons-compasses-2-line)"
  },
  "assets.icons.compasses-fill": {
    "value": "/icons/remix-icon.svg#compasses-fill",
    "variable": "var(--assets-icons-compasses-fill)"
  },
  "assets.icons.compasses-line": {
    "value": "/icons/remix-icon.svg#compasses-line",
    "variable": "var(--assets-icons-compasses-line)"
  },
  "assets.icons.computer-fill": {
    "value": "/icons/remix-icon.svg#computer-fill",
    "variable": "var(--assets-icons-computer-fill)"
  },
  "assets.icons.computer-line": {
    "value": "/icons/remix-icon.svg#computer-line",
    "variable": "var(--assets-icons-computer-line)"
  },
  "assets.icons.contacts-book-2-fill": {
    "value": "/icons/remix-icon.svg#contacts-book-2-fill",
    "variable": "var(--assets-icons-contacts-book-2-fill)"
  },
  "assets.icons.contacts-book-2-line": {
    "value": "/icons/remix-icon.svg#contacts-book-2-line",
    "variable": "var(--assets-icons-contacts-book-2-line)"
  },
  "assets.icons.contacts-book-fill": {
    "value": "/icons/remix-icon.svg#contacts-book-fill",
    "variable": "var(--assets-icons-contacts-book-fill)"
  },
  "assets.icons.contacts-book-line": {
    "value": "/icons/remix-icon.svg#contacts-book-line",
    "variable": "var(--assets-icons-contacts-book-line)"
  },
  "assets.icons.contacts-book-upload-fill": {
    "value": "/icons/remix-icon.svg#contacts-book-upload-fill",
    "variable": "var(--assets-icons-contacts-book-upload-fill)"
  },
  "assets.icons.contacts-book-upload-line": {
    "value": "/icons/remix-icon.svg#contacts-book-upload-line",
    "variable": "var(--assets-icons-contacts-book-upload-line)"
  },
  "assets.icons.contacts-fill": {
    "value": "/icons/remix-icon.svg#contacts-fill",
    "variable": "var(--assets-icons-contacts-fill)"
  },
  "assets.icons.contacts-line": {
    "value": "/icons/remix-icon.svg#contacts-line",
    "variable": "var(--assets-icons-contacts-line)"
  },
  "assets.icons.contrast-2-fill": {
    "value": "/icons/remix-icon.svg#contrast-2-fill",
    "variable": "var(--assets-icons-contrast-2-fill)"
  },
  "assets.icons.contrast-2-line": {
    "value": "/icons/remix-icon.svg#contrast-2-line",
    "variable": "var(--assets-icons-contrast-2-line)"
  },
  "assets.icons.contrast-drop-2-fill": {
    "value": "/icons/remix-icon.svg#contrast-drop-2-fill",
    "variable": "var(--assets-icons-contrast-drop-2-fill)"
  },
  "assets.icons.contrast-drop-2-line": {
    "value": "/icons/remix-icon.svg#contrast-drop-2-line",
    "variable": "var(--assets-icons-contrast-drop-2-line)"
  },
  "assets.icons.contrast-drop-fill": {
    "value": "/icons/remix-icon.svg#contrast-drop-fill",
    "variable": "var(--assets-icons-contrast-drop-fill)"
  },
  "assets.icons.contrast-drop-line": {
    "value": "/icons/remix-icon.svg#contrast-drop-line",
    "variable": "var(--assets-icons-contrast-drop-line)"
  },
  "assets.icons.contrast-fill": {
    "value": "/icons/remix-icon.svg#contrast-fill",
    "variable": "var(--assets-icons-contrast-fill)"
  },
  "assets.icons.contrast-line": {
    "value": "/icons/remix-icon.svg#contrast-line",
    "variable": "var(--assets-icons-contrast-line)"
  },
  "assets.icons.copper-coin-fill": {
    "value": "/icons/remix-icon.svg#copper-coin-fill",
    "variable": "var(--assets-icons-copper-coin-fill)"
  },
  "assets.icons.copper-coin-line": {
    "value": "/icons/remix-icon.svg#copper-coin-line",
    "variable": "var(--assets-icons-copper-coin-line)"
  },
  "assets.icons.copper-diamond-fill": {
    "value": "/icons/remix-icon.svg#copper-diamond-fill",
    "variable": "var(--assets-icons-copper-diamond-fill)"
  },
  "assets.icons.copper-diamond-line": {
    "value": "/icons/remix-icon.svg#copper-diamond-line",
    "variable": "var(--assets-icons-copper-diamond-line)"
  },
  "assets.icons.copyleft-fill": {
    "value": "/icons/remix-icon.svg#copyleft-fill",
    "variable": "var(--assets-icons-copyleft-fill)"
  },
  "assets.icons.copyleft-line": {
    "value": "/icons/remix-icon.svg#copyleft-line",
    "variable": "var(--assets-icons-copyleft-line)"
  },
  "assets.icons.copyright-fill": {
    "value": "/icons/remix-icon.svg#copyright-fill",
    "variable": "var(--assets-icons-copyright-fill)"
  },
  "assets.icons.copyright-line": {
    "value": "/icons/remix-icon.svg#copyright-line",
    "variable": "var(--assets-icons-copyright-line)"
  },
  "assets.icons.coreos-fill": {
    "value": "/icons/remix-icon.svg#coreos-fill",
    "variable": "var(--assets-icons-coreos-fill)"
  },
  "assets.icons.coreos-line": {
    "value": "/icons/remix-icon.svg#coreos-line",
    "variable": "var(--assets-icons-coreos-line)"
  },
  "assets.icons.coupon-2-fill": {
    "value": "/icons/remix-icon.svg#coupon-2-fill",
    "variable": "var(--assets-icons-coupon-2-fill)"
  },
  "assets.icons.coupon-2-line": {
    "value": "/icons/remix-icon.svg#coupon-2-line",
    "variable": "var(--assets-icons-coupon-2-line)"
  },
  "assets.icons.coupon-3-fill": {
    "value": "/icons/remix-icon.svg#coupon-3-fill",
    "variable": "var(--assets-icons-coupon-3-fill)"
  },
  "assets.icons.coupon-3-line": {
    "value": "/icons/remix-icon.svg#coupon-3-line",
    "variable": "var(--assets-icons-coupon-3-line)"
  },
  "assets.icons.coupon-4-fill": {
    "value": "/icons/remix-icon.svg#coupon-4-fill",
    "variable": "var(--assets-icons-coupon-4-fill)"
  },
  "assets.icons.coupon-4-line": {
    "value": "/icons/remix-icon.svg#coupon-4-line",
    "variable": "var(--assets-icons-coupon-4-line)"
  },
  "assets.icons.coupon-5-fill": {
    "value": "/icons/remix-icon.svg#coupon-5-fill",
    "variable": "var(--assets-icons-coupon-5-fill)"
  },
  "assets.icons.coupon-5-line": {
    "value": "/icons/remix-icon.svg#coupon-5-line",
    "variable": "var(--assets-icons-coupon-5-line)"
  },
  "assets.icons.coupon-fill": {
    "value": "/icons/remix-icon.svg#coupon-fill",
    "variable": "var(--assets-icons-coupon-fill)"
  },
  "assets.icons.coupon-line": {
    "value": "/icons/remix-icon.svg#coupon-line",
    "variable": "var(--assets-icons-coupon-line)"
  },
  "assets.icons.cpu-fill": {
    "value": "/icons/remix-icon.svg#cpu-fill",
    "variable": "var(--assets-icons-cpu-fill)"
  },
  "assets.icons.cpu-line": {
    "value": "/icons/remix-icon.svg#cpu-line",
    "variable": "var(--assets-icons-cpu-line)"
  },
  "assets.icons.creative-commons-by-fill": {
    "value": "/icons/remix-icon.svg#creative-commons-by-fill",
    "variable": "var(--assets-icons-creative-commons-by-fill)"
  },
  "assets.icons.creative-commons-by-line": {
    "value": "/icons/remix-icon.svg#creative-commons-by-line",
    "variable": "var(--assets-icons-creative-commons-by-line)"
  },
  "assets.icons.creative-commons-fill": {
    "value": "/icons/remix-icon.svg#creative-commons-fill",
    "variable": "var(--assets-icons-creative-commons-fill)"
  },
  "assets.icons.creative-commons-line": {
    "value": "/icons/remix-icon.svg#creative-commons-line",
    "variable": "var(--assets-icons-creative-commons-line)"
  },
  "assets.icons.creative-commons-nc-fill": {
    "value": "/icons/remix-icon.svg#creative-commons-nc-fill",
    "variable": "var(--assets-icons-creative-commons-nc-fill)"
  },
  "assets.icons.creative-commons-nc-line": {
    "value": "/icons/remix-icon.svg#creative-commons-nc-line",
    "variable": "var(--assets-icons-creative-commons-nc-line)"
  },
  "assets.icons.creative-commons-nd-fill": {
    "value": "/icons/remix-icon.svg#creative-commons-nd-fill",
    "variable": "var(--assets-icons-creative-commons-nd-fill)"
  },
  "assets.icons.creative-commons-nd-line": {
    "value": "/icons/remix-icon.svg#creative-commons-nd-line",
    "variable": "var(--assets-icons-creative-commons-nd-line)"
  },
  "assets.icons.creative-commons-sa-fill": {
    "value": "/icons/remix-icon.svg#creative-commons-sa-fill",
    "variable": "var(--assets-icons-creative-commons-sa-fill)"
  },
  "assets.icons.creative-commons-sa-line": {
    "value": "/icons/remix-icon.svg#creative-commons-sa-line",
    "variable": "var(--assets-icons-creative-commons-sa-line)"
  },
  "assets.icons.creative-commons-zero-fill": {
    "value": "/icons/remix-icon.svg#creative-commons-zero-fill",
    "variable": "var(--assets-icons-creative-commons-zero-fill)"
  },
  "assets.icons.creative-commons-zero-line": {
    "value": "/icons/remix-icon.svg#creative-commons-zero-line",
    "variable": "var(--assets-icons-creative-commons-zero-line)"
  },
  "assets.icons.criminal-fill": {
    "value": "/icons/remix-icon.svg#criminal-fill",
    "variable": "var(--assets-icons-criminal-fill)"
  },
  "assets.icons.criminal-line": {
    "value": "/icons/remix-icon.svg#criminal-line",
    "variable": "var(--assets-icons-criminal-line)"
  },
  "assets.icons.crop-2-fill": {
    "value": "/icons/remix-icon.svg#crop-2-fill",
    "variable": "var(--assets-icons-crop-2-fill)"
  },
  "assets.icons.crop-2-line": {
    "value": "/icons/remix-icon.svg#crop-2-line",
    "variable": "var(--assets-icons-crop-2-line)"
  },
  "assets.icons.crop-fill": {
    "value": "/icons/remix-icon.svg#crop-fill",
    "variable": "var(--assets-icons-crop-fill)"
  },
  "assets.icons.crop-line": {
    "value": "/icons/remix-icon.svg#crop-line",
    "variable": "var(--assets-icons-crop-line)"
  },
  "assets.icons.css3-fill": {
    "value": "/icons/remix-icon.svg#css3-fill",
    "variable": "var(--assets-icons-css3-fill)"
  },
  "assets.icons.css3-line": {
    "value": "/icons/remix-icon.svg#css3-line",
    "variable": "var(--assets-icons-css3-line)"
  },
  "assets.icons.cup-fill": {
    "value": "/icons/remix-icon.svg#cup-fill",
    "variable": "var(--assets-icons-cup-fill)"
  },
  "assets.icons.cup-line": {
    "value": "/icons/remix-icon.svg#cup-line",
    "variable": "var(--assets-icons-cup-line)"
  },
  "assets.icons.currency-fill": {
    "value": "/icons/remix-icon.svg#currency-fill",
    "variable": "var(--assets-icons-currency-fill)"
  },
  "assets.icons.currency-line": {
    "value": "/icons/remix-icon.svg#currency-line",
    "variable": "var(--assets-icons-currency-line)"
  },
  "assets.icons.cursor-fill": {
    "value": "/icons/remix-icon.svg#cursor-fill",
    "variable": "var(--assets-icons-cursor-fill)"
  },
  "assets.icons.cursor-line": {
    "value": "/icons/remix-icon.svg#cursor-line",
    "variable": "var(--assets-icons-cursor-line)"
  },
  "assets.icons.customer-service-2-fill": {
    "value": "/icons/remix-icon.svg#customer-service-2-fill",
    "variable": "var(--assets-icons-customer-service-2-fill)"
  },
  "assets.icons.customer-service-2-line": {
    "value": "/icons/remix-icon.svg#customer-service-2-line",
    "variable": "var(--assets-icons-customer-service-2-line)"
  },
  "assets.icons.customer-service-fill": {
    "value": "/icons/remix-icon.svg#customer-service-fill",
    "variable": "var(--assets-icons-customer-service-fill)"
  },
  "assets.icons.customer-service-line": {
    "value": "/icons/remix-icon.svg#customer-service-line",
    "variable": "var(--assets-icons-customer-service-line)"
  },
  "assets.icons.dashboard-2-fill": {
    "value": "/icons/remix-icon.svg#dashboard-2-fill",
    "variable": "var(--assets-icons-dashboard-2-fill)"
  },
  "assets.icons.dashboard-2-line": {
    "value": "/icons/remix-icon.svg#dashboard-2-line",
    "variable": "var(--assets-icons-dashboard-2-line)"
  },
  "assets.icons.dashboard-3-fill": {
    "value": "/icons/remix-icon.svg#dashboard-3-fill",
    "variable": "var(--assets-icons-dashboard-3-fill)"
  },
  "assets.icons.dashboard-3-line": {
    "value": "/icons/remix-icon.svg#dashboard-3-line",
    "variable": "var(--assets-icons-dashboard-3-line)"
  },
  "assets.icons.dashboard-fill": {
    "value": "/icons/remix-icon.svg#dashboard-fill",
    "variable": "var(--assets-icons-dashboard-fill)"
  },
  "assets.icons.dashboard-line": {
    "value": "/icons/remix-icon.svg#dashboard-line",
    "variable": "var(--assets-icons-dashboard-line)"
  },
  "assets.icons.database-2-fill": {
    "value": "/icons/remix-icon.svg#database-2-fill",
    "variable": "var(--assets-icons-database-2-fill)"
  },
  "assets.icons.database-2-line": {
    "value": "/icons/remix-icon.svg#database-2-line",
    "variable": "var(--assets-icons-database-2-line)"
  },
  "assets.icons.database-fill": {
    "value": "/icons/remix-icon.svg#database-fill",
    "variable": "var(--assets-icons-database-fill)"
  },
  "assets.icons.database-line": {
    "value": "/icons/remix-icon.svg#database-line",
    "variable": "var(--assets-icons-database-line)"
  },
  "assets.icons.delete-back-2-fill": {
    "value": "/icons/remix-icon.svg#delete-back-2-fill",
    "variable": "var(--assets-icons-delete-back-2-fill)"
  },
  "assets.icons.delete-back-2-line": {
    "value": "/icons/remix-icon.svg#delete-back-2-line",
    "variable": "var(--assets-icons-delete-back-2-line)"
  },
  "assets.icons.delete-back-fill": {
    "value": "/icons/remix-icon.svg#delete-back-fill",
    "variable": "var(--assets-icons-delete-back-fill)"
  },
  "assets.icons.delete-back-line": {
    "value": "/icons/remix-icon.svg#delete-back-line",
    "variable": "var(--assets-icons-delete-back-line)"
  },
  "assets.icons.delete-bin-2-fill": {
    "value": "/icons/remix-icon.svg#delete-bin-2-fill",
    "variable": "var(--assets-icons-delete-bin-2-fill)"
  },
  "assets.icons.delete-bin-2-line": {
    "value": "/icons/remix-icon.svg#delete-bin-2-line",
    "variable": "var(--assets-icons-delete-bin-2-line)"
  },
  "assets.icons.delete-bin-3-fill": {
    "value": "/icons/remix-icon.svg#delete-bin-3-fill",
    "variable": "var(--assets-icons-delete-bin-3-fill)"
  },
  "assets.icons.delete-bin-3-line": {
    "value": "/icons/remix-icon.svg#delete-bin-3-line",
    "variable": "var(--assets-icons-delete-bin-3-line)"
  },
  "assets.icons.delete-bin-4-fill": {
    "value": "/icons/remix-icon.svg#delete-bin-4-fill",
    "variable": "var(--assets-icons-delete-bin-4-fill)"
  },
  "assets.icons.delete-bin-4-line": {
    "value": "/icons/remix-icon.svg#delete-bin-4-line",
    "variable": "var(--assets-icons-delete-bin-4-line)"
  },
  "assets.icons.delete-bin-5-fill": {
    "value": "/icons/remix-icon.svg#delete-bin-5-fill",
    "variable": "var(--assets-icons-delete-bin-5-fill)"
  },
  "assets.icons.delete-bin-5-line": {
    "value": "/icons/remix-icon.svg#delete-bin-5-line",
    "variable": "var(--assets-icons-delete-bin-5-line)"
  },
  "assets.icons.delete-bin-6-fill": {
    "value": "/icons/remix-icon.svg#delete-bin-6-fill",
    "variable": "var(--assets-icons-delete-bin-6-fill)"
  },
  "assets.icons.delete-bin-6-line": {
    "value": "/icons/remix-icon.svg#delete-bin-6-line",
    "variable": "var(--assets-icons-delete-bin-6-line)"
  },
  "assets.icons.delete-bin-7-fill": {
    "value": "/icons/remix-icon.svg#delete-bin-7-fill",
    "variable": "var(--assets-icons-delete-bin-7-fill)"
  },
  "assets.icons.delete-bin-7-line": {
    "value": "/icons/remix-icon.svg#delete-bin-7-line",
    "variable": "var(--assets-icons-delete-bin-7-line)"
  },
  "assets.icons.delete-bin-fill": {
    "value": "/icons/remix-icon.svg#delete-bin-fill",
    "variable": "var(--assets-icons-delete-bin-fill)"
  },
  "assets.icons.delete-bin-line": {
    "value": "/icons/remix-icon.svg#delete-bin-line",
    "variable": "var(--assets-icons-delete-bin-line)"
  },
  "assets.icons.delete-column": {
    "value": "/icons/remix-icon.svg#delete-column",
    "variable": "var(--assets-icons-delete-column)"
  },
  "assets.icons.delete-row": {
    "value": "/icons/remix-icon.svg#delete-row",
    "variable": "var(--assets-icons-delete-row)"
  },
  "assets.icons.device-fill": {
    "value": "/icons/remix-icon.svg#device-fill",
    "variable": "var(--assets-icons-device-fill)"
  },
  "assets.icons.device-line": {
    "value": "/icons/remix-icon.svg#device-line",
    "variable": "var(--assets-icons-device-line)"
  },
  "assets.icons.device-recover-fill": {
    "value": "/icons/remix-icon.svg#device-recover-fill",
    "variable": "var(--assets-icons-device-recover-fill)"
  },
  "assets.icons.device-recover-line": {
    "value": "/icons/remix-icon.svg#device-recover-line",
    "variable": "var(--assets-icons-device-recover-line)"
  },
  "assets.icons.dingding-fill": {
    "value": "/icons/remix-icon.svg#dingding-fill",
    "variable": "var(--assets-icons-dingding-fill)"
  },
  "assets.icons.dingding-line": {
    "value": "/icons/remix-icon.svg#dingding-line",
    "variable": "var(--assets-icons-dingding-line)"
  },
  "assets.icons.direction-fill": {
    "value": "/icons/remix-icon.svg#direction-fill",
    "variable": "var(--assets-icons-direction-fill)"
  },
  "assets.icons.direction-line": {
    "value": "/icons/remix-icon.svg#direction-line",
    "variable": "var(--assets-icons-direction-line)"
  },
  "assets.icons.disc-fill": {
    "value": "/icons/remix-icon.svg#disc-fill",
    "variable": "var(--assets-icons-disc-fill)"
  },
  "assets.icons.disc-line": {
    "value": "/icons/remix-icon.svg#disc-line",
    "variable": "var(--assets-icons-disc-line)"
  },
  "assets.icons.discord-fill": {
    "value": "/icons/remix-icon.svg#discord-fill",
    "variable": "var(--assets-icons-discord-fill)"
  },
  "assets.icons.discord-line": {
    "value": "/icons/remix-icon.svg#discord-line",
    "variable": "var(--assets-icons-discord-line)"
  },
  "assets.icons.discuss-fill": {
    "value": "/icons/remix-icon.svg#discuss-fill",
    "variable": "var(--assets-icons-discuss-fill)"
  },
  "assets.icons.discuss-line": {
    "value": "/icons/remix-icon.svg#discuss-line",
    "variable": "var(--assets-icons-discuss-line)"
  },
  "assets.icons.dislike-fill": {
    "value": "/icons/remix-icon.svg#dislike-fill",
    "variable": "var(--assets-icons-dislike-fill)"
  },
  "assets.icons.dislike-line": {
    "value": "/icons/remix-icon.svg#dislike-line",
    "variable": "var(--assets-icons-dislike-line)"
  },
  "assets.icons.disqus-fill": {
    "value": "/icons/remix-icon.svg#disqus-fill",
    "variable": "var(--assets-icons-disqus-fill)"
  },
  "assets.icons.disqus-line": {
    "value": "/icons/remix-icon.svg#disqus-line",
    "variable": "var(--assets-icons-disqus-line)"
  },
  "assets.icons.divide-fill": {
    "value": "/icons/remix-icon.svg#divide-fill",
    "variable": "var(--assets-icons-divide-fill)"
  },
  "assets.icons.divide-line": {
    "value": "/icons/remix-icon.svg#divide-line",
    "variable": "var(--assets-icons-divide-line)"
  },
  "assets.icons.donut-chart-fill": {
    "value": "/icons/remix-icon.svg#donut-chart-fill",
    "variable": "var(--assets-icons-donut-chart-fill)"
  },
  "assets.icons.donut-chart-line": {
    "value": "/icons/remix-icon.svg#donut-chart-line",
    "variable": "var(--assets-icons-donut-chart-line)"
  },
  "assets.icons.door-closed-fill": {
    "value": "/icons/remix-icon.svg#door-closed-fill",
    "variable": "var(--assets-icons-door-closed-fill)"
  },
  "assets.icons.door-closed-line": {
    "value": "/icons/remix-icon.svg#door-closed-line",
    "variable": "var(--assets-icons-door-closed-line)"
  },
  "assets.icons.door-fill": {
    "value": "/icons/remix-icon.svg#door-fill",
    "variable": "var(--assets-icons-door-fill)"
  },
  "assets.icons.door-line": {
    "value": "/icons/remix-icon.svg#door-line",
    "variable": "var(--assets-icons-door-line)"
  },
  "assets.icons.door-lock-box-fill": {
    "value": "/icons/remix-icon.svg#door-lock-box-fill",
    "variable": "var(--assets-icons-door-lock-box-fill)"
  },
  "assets.icons.door-lock-box-line": {
    "value": "/icons/remix-icon.svg#door-lock-box-line",
    "variable": "var(--assets-icons-door-lock-box-line)"
  },
  "assets.icons.door-lock-fill": {
    "value": "/icons/remix-icon.svg#door-lock-fill",
    "variable": "var(--assets-icons-door-lock-fill)"
  },
  "assets.icons.door-lock-line": {
    "value": "/icons/remix-icon.svg#door-lock-line",
    "variable": "var(--assets-icons-door-lock-line)"
  },
  "assets.icons.door-open-fill": {
    "value": "/icons/remix-icon.svg#door-open-fill",
    "variable": "var(--assets-icons-door-open-fill)"
  },
  "assets.icons.door-open-line": {
    "value": "/icons/remix-icon.svg#door-open-line",
    "variable": "var(--assets-icons-door-open-line)"
  },
  "assets.icons.dossier-fill": {
    "value": "/icons/remix-icon.svg#dossier-fill",
    "variable": "var(--assets-icons-dossier-fill)"
  },
  "assets.icons.dossier-line": {
    "value": "/icons/remix-icon.svg#dossier-line",
    "variable": "var(--assets-icons-dossier-line)"
  },
  "assets.icons.douban-fill": {
    "value": "/icons/remix-icon.svg#douban-fill",
    "variable": "var(--assets-icons-douban-fill)"
  },
  "assets.icons.douban-line": {
    "value": "/icons/remix-icon.svg#douban-line",
    "variable": "var(--assets-icons-douban-line)"
  },
  "assets.icons.double-quotes-l": {
    "value": "/icons/remix-icon.svg#double-quotes-l",
    "variable": "var(--assets-icons-double-quotes-l)"
  },
  "assets.icons.double-quotes-r": {
    "value": "/icons/remix-icon.svg#double-quotes-r",
    "variable": "var(--assets-icons-double-quotes-r)"
  },
  "assets.icons.download-2-fill": {
    "value": "/icons/remix-icon.svg#download-2-fill",
    "variable": "var(--assets-icons-download-2-fill)"
  },
  "assets.icons.download-2-line": {
    "value": "/icons/remix-icon.svg#download-2-line",
    "variable": "var(--assets-icons-download-2-line)"
  },
  "assets.icons.download-cloud-2-fill": {
    "value": "/icons/remix-icon.svg#download-cloud-2-fill",
    "variable": "var(--assets-icons-download-cloud-2-fill)"
  },
  "assets.icons.download-cloud-2-line": {
    "value": "/icons/remix-icon.svg#download-cloud-2-line",
    "variable": "var(--assets-icons-download-cloud-2-line)"
  },
  "assets.icons.download-cloud-fill": {
    "value": "/icons/remix-icon.svg#download-cloud-fill",
    "variable": "var(--assets-icons-download-cloud-fill)"
  },
  "assets.icons.download-cloud-line": {
    "value": "/icons/remix-icon.svg#download-cloud-line",
    "variable": "var(--assets-icons-download-cloud-line)"
  },
  "assets.icons.download-fill": {
    "value": "/icons/remix-icon.svg#download-fill",
    "variable": "var(--assets-icons-download-fill)"
  },
  "assets.icons.download-line": {
    "value": "/icons/remix-icon.svg#download-line",
    "variable": "var(--assets-icons-download-line)"
  },
  "assets.icons.draft-fill": {
    "value": "/icons/remix-icon.svg#draft-fill",
    "variable": "var(--assets-icons-draft-fill)"
  },
  "assets.icons.draft-line": {
    "value": "/icons/remix-icon.svg#draft-line",
    "variable": "var(--assets-icons-draft-line)"
  },
  "assets.icons.drag-drop-fill": {
    "value": "/icons/remix-icon.svg#drag-drop-fill",
    "variable": "var(--assets-icons-drag-drop-fill)"
  },
  "assets.icons.drag-drop-line": {
    "value": "/icons/remix-icon.svg#drag-drop-line",
    "variable": "var(--assets-icons-drag-drop-line)"
  },
  "assets.icons.drag-move-2-fill": {
    "value": "/icons/remix-icon.svg#drag-move-2-fill",
    "variable": "var(--assets-icons-drag-move-2-fill)"
  },
  "assets.icons.drag-move-2-line": {
    "value": "/icons/remix-icon.svg#drag-move-2-line",
    "variable": "var(--assets-icons-drag-move-2-line)"
  },
  "assets.icons.drag-move-fill": {
    "value": "/icons/remix-icon.svg#drag-move-fill",
    "variable": "var(--assets-icons-drag-move-fill)"
  },
  "assets.icons.drag-move-line": {
    "value": "/icons/remix-icon.svg#drag-move-line",
    "variable": "var(--assets-icons-drag-move-line)"
  },
  "assets.icons.dribbble-fill": {
    "value": "/icons/remix-icon.svg#dribbble-fill",
    "variable": "var(--assets-icons-dribbble-fill)"
  },
  "assets.icons.dribbble-line": {
    "value": "/icons/remix-icon.svg#dribbble-line",
    "variable": "var(--assets-icons-dribbble-line)"
  },
  "assets.icons.drive-fill": {
    "value": "/icons/remix-icon.svg#drive-fill",
    "variable": "var(--assets-icons-drive-fill)"
  },
  "assets.icons.drive-line": {
    "value": "/icons/remix-icon.svg#drive-line",
    "variable": "var(--assets-icons-drive-line)"
  },
  "assets.icons.drizzle-fill": {
    "value": "/icons/remix-icon.svg#drizzle-fill",
    "variable": "var(--assets-icons-drizzle-fill)"
  },
  "assets.icons.drizzle-line": {
    "value": "/icons/remix-icon.svg#drizzle-line",
    "variable": "var(--assets-icons-drizzle-line)"
  },
  "assets.icons.drop-fill": {
    "value": "/icons/remix-icon.svg#drop-fill",
    "variable": "var(--assets-icons-drop-fill)"
  },
  "assets.icons.drop-line": {
    "value": "/icons/remix-icon.svg#drop-line",
    "variable": "var(--assets-icons-drop-line)"
  },
  "assets.icons.dropbox-fill": {
    "value": "/icons/remix-icon.svg#dropbox-fill",
    "variable": "var(--assets-icons-dropbox-fill)"
  },
  "assets.icons.dropbox-line": {
    "value": "/icons/remix-icon.svg#dropbox-line",
    "variable": "var(--assets-icons-dropbox-line)"
  },
  "assets.icons.dual-sim-1-fill": {
    "value": "/icons/remix-icon.svg#dual-sim-1-fill",
    "variable": "var(--assets-icons-dual-sim-1-fill)"
  },
  "assets.icons.dual-sim-1-line": {
    "value": "/icons/remix-icon.svg#dual-sim-1-line",
    "variable": "var(--assets-icons-dual-sim-1-line)"
  },
  "assets.icons.dual-sim-2-fill": {
    "value": "/icons/remix-icon.svg#dual-sim-2-fill",
    "variable": "var(--assets-icons-dual-sim-2-fill)"
  },
  "assets.icons.dual-sim-2-line": {
    "value": "/icons/remix-icon.svg#dual-sim-2-line",
    "variable": "var(--assets-icons-dual-sim-2-line)"
  },
  "assets.icons.dv-fill": {
    "value": "/icons/remix-icon.svg#dv-fill",
    "variable": "var(--assets-icons-dv-fill)"
  },
  "assets.icons.dv-line": {
    "value": "/icons/remix-icon.svg#dv-line",
    "variable": "var(--assets-icons-dv-line)"
  },
  "assets.icons.dvd-fill": {
    "value": "/icons/remix-icon.svg#dvd-fill",
    "variable": "var(--assets-icons-dvd-fill)"
  },
  "assets.icons.dvd-line": {
    "value": "/icons/remix-icon.svg#dvd-line",
    "variable": "var(--assets-icons-dvd-line)"
  },
  "assets.icons.e-bike-2-fill": {
    "value": "/icons/remix-icon.svg#e-bike-2-fill",
    "variable": "var(--assets-icons-e-bike-2-fill)"
  },
  "assets.icons.e-bike-2-line": {
    "value": "/icons/remix-icon.svg#e-bike-2-line",
    "variable": "var(--assets-icons-e-bike-2-line)"
  },
  "assets.icons.e-bike-fill": {
    "value": "/icons/remix-icon.svg#e-bike-fill",
    "variable": "var(--assets-icons-e-bike-fill)"
  },
  "assets.icons.e-bike-line": {
    "value": "/icons/remix-icon.svg#e-bike-line",
    "variable": "var(--assets-icons-e-bike-line)"
  },
  "assets.icons.earth-fill": {
    "value": "/icons/remix-icon.svg#earth-fill",
    "variable": "var(--assets-icons-earth-fill)"
  },
  "assets.icons.earth-line": {
    "value": "/icons/remix-icon.svg#earth-line",
    "variable": "var(--assets-icons-earth-line)"
  },
  "assets.icons.earthquake-fill": {
    "value": "/icons/remix-icon.svg#earthquake-fill",
    "variable": "var(--assets-icons-earthquake-fill)"
  },
  "assets.icons.earthquake-line": {
    "value": "/icons/remix-icon.svg#earthquake-line",
    "variable": "var(--assets-icons-earthquake-line)"
  },
  "assets.icons.edge-fill": {
    "value": "/icons/remix-icon.svg#edge-fill",
    "variable": "var(--assets-icons-edge-fill)"
  },
  "assets.icons.edge-line": {
    "value": "/icons/remix-icon.svg#edge-line",
    "variable": "var(--assets-icons-edge-line)"
  },
  "assets.icons.edit-2-fill": {
    "value": "/icons/remix-icon.svg#edit-2-fill",
    "variable": "var(--assets-icons-edit-2-fill)"
  },
  "assets.icons.edit-2-line": {
    "value": "/icons/remix-icon.svg#edit-2-line",
    "variable": "var(--assets-icons-edit-2-line)"
  },
  "assets.icons.edit-box-fill": {
    "value": "/icons/remix-icon.svg#edit-box-fill",
    "variable": "var(--assets-icons-edit-box-fill)"
  },
  "assets.icons.edit-box-line": {
    "value": "/icons/remix-icon.svg#edit-box-line",
    "variable": "var(--assets-icons-edit-box-line)"
  },
  "assets.icons.edit-circle-fill": {
    "value": "/icons/remix-icon.svg#edit-circle-fill",
    "variable": "var(--assets-icons-edit-circle-fill)"
  },
  "assets.icons.edit-circle-line": {
    "value": "/icons/remix-icon.svg#edit-circle-line",
    "variable": "var(--assets-icons-edit-circle-line)"
  },
  "assets.icons.edit-fill": {
    "value": "/icons/remix-icon.svg#edit-fill",
    "variable": "var(--assets-icons-edit-fill)"
  },
  "assets.icons.edit-line": {
    "value": "/icons/remix-icon.svg#edit-line",
    "variable": "var(--assets-icons-edit-line)"
  },
  "assets.icons.eject-fill": {
    "value": "/icons/remix-icon.svg#eject-fill",
    "variable": "var(--assets-icons-eject-fill)"
  },
  "assets.icons.eject-line": {
    "value": "/icons/remix-icon.svg#eject-line",
    "variable": "var(--assets-icons-eject-line)"
  },
  "assets.icons.emotion-2-fill": {
    "value": "/icons/remix-icon.svg#emotion-2-fill",
    "variable": "var(--assets-icons-emotion-2-fill)"
  },
  "assets.icons.emotion-2-line": {
    "value": "/icons/remix-icon.svg#emotion-2-line",
    "variable": "var(--assets-icons-emotion-2-line)"
  },
  "assets.icons.emotion-fill": {
    "value": "/icons/remix-icon.svg#emotion-fill",
    "variable": "var(--assets-icons-emotion-fill)"
  },
  "assets.icons.emotion-happy-fill": {
    "value": "/icons/remix-icon.svg#emotion-happy-fill",
    "variable": "var(--assets-icons-emotion-happy-fill)"
  },
  "assets.icons.emotion-happy-line": {
    "value": "/icons/remix-icon.svg#emotion-happy-line",
    "variable": "var(--assets-icons-emotion-happy-line)"
  },
  "assets.icons.emotion-laugh-fill": {
    "value": "/icons/remix-icon.svg#emotion-laugh-fill",
    "variable": "var(--assets-icons-emotion-laugh-fill)"
  },
  "assets.icons.emotion-laugh-line": {
    "value": "/icons/remix-icon.svg#emotion-laugh-line",
    "variable": "var(--assets-icons-emotion-laugh-line)"
  },
  "assets.icons.emotion-line": {
    "value": "/icons/remix-icon.svg#emotion-line",
    "variable": "var(--assets-icons-emotion-line)"
  },
  "assets.icons.emotion-normal-fill": {
    "value": "/icons/remix-icon.svg#emotion-normal-fill",
    "variable": "var(--assets-icons-emotion-normal-fill)"
  },
  "assets.icons.emotion-normal-line": {
    "value": "/icons/remix-icon.svg#emotion-normal-line",
    "variable": "var(--assets-icons-emotion-normal-line)"
  },
  "assets.icons.emotion-sad-fill": {
    "value": "/icons/remix-icon.svg#emotion-sad-fill",
    "variable": "var(--assets-icons-emotion-sad-fill)"
  },
  "assets.icons.emotion-sad-line": {
    "value": "/icons/remix-icon.svg#emotion-sad-line",
    "variable": "var(--assets-icons-emotion-sad-line)"
  },
  "assets.icons.emotion-unhappy-fill": {
    "value": "/icons/remix-icon.svg#emotion-unhappy-fill",
    "variable": "var(--assets-icons-emotion-unhappy-fill)"
  },
  "assets.icons.emotion-unhappy-line": {
    "value": "/icons/remix-icon.svg#emotion-unhappy-line",
    "variable": "var(--assets-icons-emotion-unhappy-line)"
  },
  "assets.icons.empathize-fill": {
    "value": "/icons/remix-icon.svg#empathize-fill",
    "variable": "var(--assets-icons-empathize-fill)"
  },
  "assets.icons.empathize-line": {
    "value": "/icons/remix-icon.svg#empathize-line",
    "variable": "var(--assets-icons-empathize-line)"
  },
  "assets.icons.emphasis-cn": {
    "value": "/icons/remix-icon.svg#emphasis-cn",
    "variable": "var(--assets-icons-emphasis-cn)"
  },
  "assets.icons.emphasis": {
    "value": "/icons/remix-icon.svg#emphasis",
    "variable": "var(--assets-icons-emphasis)"
  },
  "assets.icons.english-input": {
    "value": "/icons/remix-icon.svg#english-input",
    "variable": "var(--assets-icons-english-input)"
  },
  "assets.icons.equalizer-fill": {
    "value": "/icons/remix-icon.svg#equalizer-fill",
    "variable": "var(--assets-icons-equalizer-fill)"
  },
  "assets.icons.equalizer-line": {
    "value": "/icons/remix-icon.svg#equalizer-line",
    "variable": "var(--assets-icons-equalizer-line)"
  },
  "assets.icons.eraser-fill": {
    "value": "/icons/remix-icon.svg#eraser-fill",
    "variable": "var(--assets-icons-eraser-fill)"
  },
  "assets.icons.eraser-line": {
    "value": "/icons/remix-icon.svg#eraser-line",
    "variable": "var(--assets-icons-eraser-line)"
  },
  "assets.icons.error-warning-fill": {
    "value": "/icons/remix-icon.svg#error-warning-fill",
    "variable": "var(--assets-icons-error-warning-fill)"
  },
  "assets.icons.error-warning-line": {
    "value": "/icons/remix-icon.svg#error-warning-line",
    "variable": "var(--assets-icons-error-warning-line)"
  },
  "assets.icons.evernote-fill": {
    "value": "/icons/remix-icon.svg#evernote-fill",
    "variable": "var(--assets-icons-evernote-fill)"
  },
  "assets.icons.evernote-line": {
    "value": "/icons/remix-icon.svg#evernote-line",
    "variable": "var(--assets-icons-evernote-line)"
  },
  "assets.icons.exchange-box-fill": {
    "value": "/icons/remix-icon.svg#exchange-box-fill",
    "variable": "var(--assets-icons-exchange-box-fill)"
  },
  "assets.icons.exchange-box-line": {
    "value": "/icons/remix-icon.svg#exchange-box-line",
    "variable": "var(--assets-icons-exchange-box-line)"
  },
  "assets.icons.exchange-cny-fill": {
    "value": "/icons/remix-icon.svg#exchange-cny-fill",
    "variable": "var(--assets-icons-exchange-cny-fill)"
  },
  "assets.icons.exchange-cny-line": {
    "value": "/icons/remix-icon.svg#exchange-cny-line",
    "variable": "var(--assets-icons-exchange-cny-line)"
  },
  "assets.icons.exchange-dollar-fill": {
    "value": "/icons/remix-icon.svg#exchange-dollar-fill",
    "variable": "var(--assets-icons-exchange-dollar-fill)"
  },
  "assets.icons.exchange-dollar-line": {
    "value": "/icons/remix-icon.svg#exchange-dollar-line",
    "variable": "var(--assets-icons-exchange-dollar-line)"
  },
  "assets.icons.exchange-fill": {
    "value": "/icons/remix-icon.svg#exchange-fill",
    "variable": "var(--assets-icons-exchange-fill)"
  },
  "assets.icons.exchange-funds-fill": {
    "value": "/icons/remix-icon.svg#exchange-funds-fill",
    "variable": "var(--assets-icons-exchange-funds-fill)"
  },
  "assets.icons.exchange-funds-line": {
    "value": "/icons/remix-icon.svg#exchange-funds-line",
    "variable": "var(--assets-icons-exchange-funds-line)"
  },
  "assets.icons.exchange-line": {
    "value": "/icons/remix-icon.svg#exchange-line",
    "variable": "var(--assets-icons-exchange-line)"
  },
  "assets.icons.external-link-fill": {
    "value": "/icons/remix-icon.svg#external-link-fill",
    "variable": "var(--assets-icons-external-link-fill)"
  },
  "assets.icons.external-link-line": {
    "value": "/icons/remix-icon.svg#external-link-line",
    "variable": "var(--assets-icons-external-link-line)"
  },
  "assets.icons.eye-2-fill": {
    "value": "/icons/remix-icon.svg#eye-2-fill",
    "variable": "var(--assets-icons-eye-2-fill)"
  },
  "assets.icons.eye-2-line": {
    "value": "/icons/remix-icon.svg#eye-2-line",
    "variable": "var(--assets-icons-eye-2-line)"
  },
  "assets.icons.eye-close-fill": {
    "value": "/icons/remix-icon.svg#eye-close-fill",
    "variable": "var(--assets-icons-eye-close-fill)"
  },
  "assets.icons.eye-close-line": {
    "value": "/icons/remix-icon.svg#eye-close-line",
    "variable": "var(--assets-icons-eye-close-line)"
  },
  "assets.icons.eye-fill": {
    "value": "/icons/remix-icon.svg#eye-fill",
    "variable": "var(--assets-icons-eye-fill)"
  },
  "assets.icons.eye-line": {
    "value": "/icons/remix-icon.svg#eye-line",
    "variable": "var(--assets-icons-eye-line)"
  },
  "assets.icons.eye-off-fill": {
    "value": "/icons/remix-icon.svg#eye-off-fill",
    "variable": "var(--assets-icons-eye-off-fill)"
  },
  "assets.icons.eye-off-line": {
    "value": "/icons/remix-icon.svg#eye-off-line",
    "variable": "var(--assets-icons-eye-off-line)"
  },
  "assets.icons.facebook-box-fill": {
    "value": "/icons/remix-icon.svg#facebook-box-fill",
    "variable": "var(--assets-icons-facebook-box-fill)"
  },
  "assets.icons.facebook-box-line": {
    "value": "/icons/remix-icon.svg#facebook-box-line",
    "variable": "var(--assets-icons-facebook-box-line)"
  },
  "assets.icons.facebook-circle-fill": {
    "value": "/icons/remix-icon.svg#facebook-circle-fill",
    "variable": "var(--assets-icons-facebook-circle-fill)"
  },
  "assets.icons.facebook-circle-line": {
    "value": "/icons/remix-icon.svg#facebook-circle-line",
    "variable": "var(--assets-icons-facebook-circle-line)"
  },
  "assets.icons.facebook-fill": {
    "value": "/icons/remix-icon.svg#facebook-fill",
    "variable": "var(--assets-icons-facebook-fill)"
  },
  "assets.icons.facebook-line": {
    "value": "/icons/remix-icon.svg#facebook-line",
    "variable": "var(--assets-icons-facebook-line)"
  },
  "assets.icons.fahrenheit-fill": {
    "value": "/icons/remix-icon.svg#fahrenheit-fill",
    "variable": "var(--assets-icons-fahrenheit-fill)"
  },
  "assets.icons.fahrenheit-line": {
    "value": "/icons/remix-icon.svg#fahrenheit-line",
    "variable": "var(--assets-icons-fahrenheit-line)"
  },
  "assets.icons.feedback-fill": {
    "value": "/icons/remix-icon.svg#feedback-fill",
    "variable": "var(--assets-icons-feedback-fill)"
  },
  "assets.icons.feedback-line": {
    "value": "/icons/remix-icon.svg#feedback-line",
    "variable": "var(--assets-icons-feedback-line)"
  },
  "assets.icons.file-2-fill": {
    "value": "/icons/remix-icon.svg#file-2-fill",
    "variable": "var(--assets-icons-file-2-fill)"
  },
  "assets.icons.file-2-line": {
    "value": "/icons/remix-icon.svg#file-2-line",
    "variable": "var(--assets-icons-file-2-line)"
  },
  "assets.icons.file-3-fill": {
    "value": "/icons/remix-icon.svg#file-3-fill",
    "variable": "var(--assets-icons-file-3-fill)"
  },
  "assets.icons.file-3-line": {
    "value": "/icons/remix-icon.svg#file-3-line",
    "variable": "var(--assets-icons-file-3-line)"
  },
  "assets.icons.file-4-fill": {
    "value": "/icons/remix-icon.svg#file-4-fill",
    "variable": "var(--assets-icons-file-4-fill)"
  },
  "assets.icons.file-4-line": {
    "value": "/icons/remix-icon.svg#file-4-line",
    "variable": "var(--assets-icons-file-4-line)"
  },
  "assets.icons.file-add-fill": {
    "value": "/icons/remix-icon.svg#file-add-fill",
    "variable": "var(--assets-icons-file-add-fill)"
  },
  "assets.icons.file-add-line": {
    "value": "/icons/remix-icon.svg#file-add-line",
    "variable": "var(--assets-icons-file-add-line)"
  },
  "assets.icons.file-chart-2-fill": {
    "value": "/icons/remix-icon.svg#file-chart-2-fill",
    "variable": "var(--assets-icons-file-chart-2-fill)"
  },
  "assets.icons.file-chart-2-line": {
    "value": "/icons/remix-icon.svg#file-chart-2-line",
    "variable": "var(--assets-icons-file-chart-2-line)"
  },
  "assets.icons.file-chart-fill": {
    "value": "/icons/remix-icon.svg#file-chart-fill",
    "variable": "var(--assets-icons-file-chart-fill)"
  },
  "assets.icons.file-chart-line": {
    "value": "/icons/remix-icon.svg#file-chart-line",
    "variable": "var(--assets-icons-file-chart-line)"
  },
  "assets.icons.file-cloud-fill": {
    "value": "/icons/remix-icon.svg#file-cloud-fill",
    "variable": "var(--assets-icons-file-cloud-fill)"
  },
  "assets.icons.file-cloud-line": {
    "value": "/icons/remix-icon.svg#file-cloud-line",
    "variable": "var(--assets-icons-file-cloud-line)"
  },
  "assets.icons.file-code-fill": {
    "value": "/icons/remix-icon.svg#file-code-fill",
    "variable": "var(--assets-icons-file-code-fill)"
  },
  "assets.icons.file-code-line": {
    "value": "/icons/remix-icon.svg#file-code-line",
    "variable": "var(--assets-icons-file-code-line)"
  },
  "assets.icons.file-copy-2-fill": {
    "value": "/icons/remix-icon.svg#file-copy-2-fill",
    "variable": "var(--assets-icons-file-copy-2-fill)"
  },
  "assets.icons.file-copy-2-line": {
    "value": "/icons/remix-icon.svg#file-copy-2-line",
    "variable": "var(--assets-icons-file-copy-2-line)"
  },
  "assets.icons.file-copy-fill": {
    "value": "/icons/remix-icon.svg#file-copy-fill",
    "variable": "var(--assets-icons-file-copy-fill)"
  },
  "assets.icons.file-copy-line": {
    "value": "/icons/remix-icon.svg#file-copy-line",
    "variable": "var(--assets-icons-file-copy-line)"
  },
  "assets.icons.file-damage-fill": {
    "value": "/icons/remix-icon.svg#file-damage-fill",
    "variable": "var(--assets-icons-file-damage-fill)"
  },
  "assets.icons.file-damage-line": {
    "value": "/icons/remix-icon.svg#file-damage-line",
    "variable": "var(--assets-icons-file-damage-line)"
  },
  "assets.icons.file-download-fill": {
    "value": "/icons/remix-icon.svg#file-download-fill",
    "variable": "var(--assets-icons-file-download-fill)"
  },
  "assets.icons.file-download-line": {
    "value": "/icons/remix-icon.svg#file-download-line",
    "variable": "var(--assets-icons-file-download-line)"
  },
  "assets.icons.file-edit-fill": {
    "value": "/icons/remix-icon.svg#file-edit-fill",
    "variable": "var(--assets-icons-file-edit-fill)"
  },
  "assets.icons.file-edit-line": {
    "value": "/icons/remix-icon.svg#file-edit-line",
    "variable": "var(--assets-icons-file-edit-line)"
  },
  "assets.icons.file-excel-2-fill": {
    "value": "/icons/remix-icon.svg#file-excel-2-fill",
    "variable": "var(--assets-icons-file-excel-2-fill)"
  },
  "assets.icons.file-excel-2-line": {
    "value": "/icons/remix-icon.svg#file-excel-2-line",
    "variable": "var(--assets-icons-file-excel-2-line)"
  },
  "assets.icons.file-excel-fill": {
    "value": "/icons/remix-icon.svg#file-excel-fill",
    "variable": "var(--assets-icons-file-excel-fill)"
  },
  "assets.icons.file-excel-line": {
    "value": "/icons/remix-icon.svg#file-excel-line",
    "variable": "var(--assets-icons-file-excel-line)"
  },
  "assets.icons.file-fill": {
    "value": "/icons/remix-icon.svg#file-fill",
    "variable": "var(--assets-icons-file-fill)"
  },
  "assets.icons.file-forbid-fill": {
    "value": "/icons/remix-icon.svg#file-forbid-fill",
    "variable": "var(--assets-icons-file-forbid-fill)"
  },
  "assets.icons.file-forbid-line": {
    "value": "/icons/remix-icon.svg#file-forbid-line",
    "variable": "var(--assets-icons-file-forbid-line)"
  },
  "assets.icons.file-gif-fill": {
    "value": "/icons/remix-icon.svg#file-gif-fill",
    "variable": "var(--assets-icons-file-gif-fill)"
  },
  "assets.icons.file-gif-line": {
    "value": "/icons/remix-icon.svg#file-gif-line",
    "variable": "var(--assets-icons-file-gif-line)"
  },
  "assets.icons.file-history-fill": {
    "value": "/icons/remix-icon.svg#file-history-fill",
    "variable": "var(--assets-icons-file-history-fill)"
  },
  "assets.icons.file-history-line": {
    "value": "/icons/remix-icon.svg#file-history-line",
    "variable": "var(--assets-icons-file-history-line)"
  },
  "assets.icons.file-hwp-fill": {
    "value": "/icons/remix-icon.svg#file-hwp-fill",
    "variable": "var(--assets-icons-file-hwp-fill)"
  },
  "assets.icons.file-hwp-line": {
    "value": "/icons/remix-icon.svg#file-hwp-line",
    "variable": "var(--assets-icons-file-hwp-line)"
  },
  "assets.icons.file-info-fill": {
    "value": "/icons/remix-icon.svg#file-info-fill",
    "variable": "var(--assets-icons-file-info-fill)"
  },
  "assets.icons.file-info-line": {
    "value": "/icons/remix-icon.svg#file-info-line",
    "variable": "var(--assets-icons-file-info-line)"
  },
  "assets.icons.file-line": {
    "value": "/icons/remix-icon.svg#file-line",
    "variable": "var(--assets-icons-file-line)"
  },
  "assets.icons.file-list-2-fill": {
    "value": "/icons/remix-icon.svg#file-list-2-fill",
    "variable": "var(--assets-icons-file-list-2-fill)"
  },
  "assets.icons.file-list-2-line": {
    "value": "/icons/remix-icon.svg#file-list-2-line",
    "variable": "var(--assets-icons-file-list-2-line)"
  },
  "assets.icons.file-list-3-fill": {
    "value": "/icons/remix-icon.svg#file-list-3-fill",
    "variable": "var(--assets-icons-file-list-3-fill)"
  },
  "assets.icons.file-list-3-line": {
    "value": "/icons/remix-icon.svg#file-list-3-line",
    "variable": "var(--assets-icons-file-list-3-line)"
  },
  "assets.icons.file-list-fill": {
    "value": "/icons/remix-icon.svg#file-list-fill",
    "variable": "var(--assets-icons-file-list-fill)"
  },
  "assets.icons.file-list-line": {
    "value": "/icons/remix-icon.svg#file-list-line",
    "variable": "var(--assets-icons-file-list-line)"
  },
  "assets.icons.file-lock-fill": {
    "value": "/icons/remix-icon.svg#file-lock-fill",
    "variable": "var(--assets-icons-file-lock-fill)"
  },
  "assets.icons.file-lock-line": {
    "value": "/icons/remix-icon.svg#file-lock-line",
    "variable": "var(--assets-icons-file-lock-line)"
  },
  "assets.icons.file-mark-fill": {
    "value": "/icons/remix-icon.svg#file-mark-fill",
    "variable": "var(--assets-icons-file-mark-fill)"
  },
  "assets.icons.file-mark-line": {
    "value": "/icons/remix-icon.svg#file-mark-line",
    "variable": "var(--assets-icons-file-mark-line)"
  },
  "assets.icons.file-music-fill": {
    "value": "/icons/remix-icon.svg#file-music-fill",
    "variable": "var(--assets-icons-file-music-fill)"
  },
  "assets.icons.file-music-line": {
    "value": "/icons/remix-icon.svg#file-music-line",
    "variable": "var(--assets-icons-file-music-line)"
  },
  "assets.icons.file-paper-2-fill": {
    "value": "/icons/remix-icon.svg#file-paper-2-fill",
    "variable": "var(--assets-icons-file-paper-2-fill)"
  },
  "assets.icons.file-paper-2-line": {
    "value": "/icons/remix-icon.svg#file-paper-2-line",
    "variable": "var(--assets-icons-file-paper-2-line)"
  },
  "assets.icons.file-paper-fill": {
    "value": "/icons/remix-icon.svg#file-paper-fill",
    "variable": "var(--assets-icons-file-paper-fill)"
  },
  "assets.icons.file-paper-line": {
    "value": "/icons/remix-icon.svg#file-paper-line",
    "variable": "var(--assets-icons-file-paper-line)"
  },
  "assets.icons.file-pdf-fill": {
    "value": "/icons/remix-icon.svg#file-pdf-fill",
    "variable": "var(--assets-icons-file-pdf-fill)"
  },
  "assets.icons.file-pdf-line": {
    "value": "/icons/remix-icon.svg#file-pdf-line",
    "variable": "var(--assets-icons-file-pdf-line)"
  },
  "assets.icons.file-ppt-2-fill": {
    "value": "/icons/remix-icon.svg#file-ppt-2-fill",
    "variable": "var(--assets-icons-file-ppt-2-fill)"
  },
  "assets.icons.file-ppt-2-line": {
    "value": "/icons/remix-icon.svg#file-ppt-2-line",
    "variable": "var(--assets-icons-file-ppt-2-line)"
  },
  "assets.icons.file-ppt-fill": {
    "value": "/icons/remix-icon.svg#file-ppt-fill",
    "variable": "var(--assets-icons-file-ppt-fill)"
  },
  "assets.icons.file-ppt-line": {
    "value": "/icons/remix-icon.svg#file-ppt-line",
    "variable": "var(--assets-icons-file-ppt-line)"
  },
  "assets.icons.file-reduce-fill": {
    "value": "/icons/remix-icon.svg#file-reduce-fill",
    "variable": "var(--assets-icons-file-reduce-fill)"
  },
  "assets.icons.file-reduce-line": {
    "value": "/icons/remix-icon.svg#file-reduce-line",
    "variable": "var(--assets-icons-file-reduce-line)"
  },
  "assets.icons.file-search-fill": {
    "value": "/icons/remix-icon.svg#file-search-fill",
    "variable": "var(--assets-icons-file-search-fill)"
  },
  "assets.icons.file-search-line": {
    "value": "/icons/remix-icon.svg#file-search-line",
    "variable": "var(--assets-icons-file-search-line)"
  },
  "assets.icons.file-settings-fill": {
    "value": "/icons/remix-icon.svg#file-settings-fill",
    "variable": "var(--assets-icons-file-settings-fill)"
  },
  "assets.icons.file-settings-line": {
    "value": "/icons/remix-icon.svg#file-settings-line",
    "variable": "var(--assets-icons-file-settings-line)"
  },
  "assets.icons.file-shield-2-fill": {
    "value": "/icons/remix-icon.svg#file-shield-2-fill",
    "variable": "var(--assets-icons-file-shield-2-fill)"
  },
  "assets.icons.file-shield-2-line": {
    "value": "/icons/remix-icon.svg#file-shield-2-line",
    "variable": "var(--assets-icons-file-shield-2-line)"
  },
  "assets.icons.file-shield-fill": {
    "value": "/icons/remix-icon.svg#file-shield-fill",
    "variable": "var(--assets-icons-file-shield-fill)"
  },
  "assets.icons.file-shield-line": {
    "value": "/icons/remix-icon.svg#file-shield-line",
    "variable": "var(--assets-icons-file-shield-line)"
  },
  "assets.icons.file-shred-fill": {
    "value": "/icons/remix-icon.svg#file-shred-fill",
    "variable": "var(--assets-icons-file-shred-fill)"
  },
  "assets.icons.file-shred-line": {
    "value": "/icons/remix-icon.svg#file-shred-line",
    "variable": "var(--assets-icons-file-shred-line)"
  },
  "assets.icons.file-text-fill": {
    "value": "/icons/remix-icon.svg#file-text-fill",
    "variable": "var(--assets-icons-file-text-fill)"
  },
  "assets.icons.file-text-line": {
    "value": "/icons/remix-icon.svg#file-text-line",
    "variable": "var(--assets-icons-file-text-line)"
  },
  "assets.icons.file-transfer-fill": {
    "value": "/icons/remix-icon.svg#file-transfer-fill",
    "variable": "var(--assets-icons-file-transfer-fill)"
  },
  "assets.icons.file-transfer-line": {
    "value": "/icons/remix-icon.svg#file-transfer-line",
    "variable": "var(--assets-icons-file-transfer-line)"
  },
  "assets.icons.file-unknow-fill": {
    "value": "/icons/remix-icon.svg#file-unknow-fill",
    "variable": "var(--assets-icons-file-unknow-fill)"
  },
  "assets.icons.file-unknow-line": {
    "value": "/icons/remix-icon.svg#file-unknow-line",
    "variable": "var(--assets-icons-file-unknow-line)"
  },
  "assets.icons.file-upload-fill": {
    "value": "/icons/remix-icon.svg#file-upload-fill",
    "variable": "var(--assets-icons-file-upload-fill)"
  },
  "assets.icons.file-upload-line": {
    "value": "/icons/remix-icon.svg#file-upload-line",
    "variable": "var(--assets-icons-file-upload-line)"
  },
  "assets.icons.file-user-fill": {
    "value": "/icons/remix-icon.svg#file-user-fill",
    "variable": "var(--assets-icons-file-user-fill)"
  },
  "assets.icons.file-user-line": {
    "value": "/icons/remix-icon.svg#file-user-line",
    "variable": "var(--assets-icons-file-user-line)"
  },
  "assets.icons.file-warning-fill": {
    "value": "/icons/remix-icon.svg#file-warning-fill",
    "variable": "var(--assets-icons-file-warning-fill)"
  },
  "assets.icons.file-warning-line": {
    "value": "/icons/remix-icon.svg#file-warning-line",
    "variable": "var(--assets-icons-file-warning-line)"
  },
  "assets.icons.file-word-2-fill": {
    "value": "/icons/remix-icon.svg#file-word-2-fill",
    "variable": "var(--assets-icons-file-word-2-fill)"
  },
  "assets.icons.file-word-2-line": {
    "value": "/icons/remix-icon.svg#file-word-2-line",
    "variable": "var(--assets-icons-file-word-2-line)"
  },
  "assets.icons.file-word-fill": {
    "value": "/icons/remix-icon.svg#file-word-fill",
    "variable": "var(--assets-icons-file-word-fill)"
  },
  "assets.icons.file-word-line": {
    "value": "/icons/remix-icon.svg#file-word-line",
    "variable": "var(--assets-icons-file-word-line)"
  },
  "assets.icons.file-zip-fill": {
    "value": "/icons/remix-icon.svg#file-zip-fill",
    "variable": "var(--assets-icons-file-zip-fill)"
  },
  "assets.icons.file-zip-line": {
    "value": "/icons/remix-icon.svg#file-zip-line",
    "variable": "var(--assets-icons-file-zip-line)"
  },
  "assets.icons.film-fill": {
    "value": "/icons/remix-icon.svg#film-fill",
    "variable": "var(--assets-icons-film-fill)"
  },
  "assets.icons.film-line": {
    "value": "/icons/remix-icon.svg#film-line",
    "variable": "var(--assets-icons-film-line)"
  },
  "assets.icons.filter-2-fill": {
    "value": "/icons/remix-icon.svg#filter-2-fill",
    "variable": "var(--assets-icons-filter-2-fill)"
  },
  "assets.icons.filter-2-line": {
    "value": "/icons/remix-icon.svg#filter-2-line",
    "variable": "var(--assets-icons-filter-2-line)"
  },
  "assets.icons.filter-3-fill": {
    "value": "/icons/remix-icon.svg#filter-3-fill",
    "variable": "var(--assets-icons-filter-3-fill)"
  },
  "assets.icons.filter-3-line": {
    "value": "/icons/remix-icon.svg#filter-3-line",
    "variable": "var(--assets-icons-filter-3-line)"
  },
  "assets.icons.filter-fill": {
    "value": "/icons/remix-icon.svg#filter-fill",
    "variable": "var(--assets-icons-filter-fill)"
  },
  "assets.icons.filter-line": {
    "value": "/icons/remix-icon.svg#filter-line",
    "variable": "var(--assets-icons-filter-line)"
  },
  "assets.icons.filter-off-fill": {
    "value": "/icons/remix-icon.svg#filter-off-fill",
    "variable": "var(--assets-icons-filter-off-fill)"
  },
  "assets.icons.filter-off-line": {
    "value": "/icons/remix-icon.svg#filter-off-line",
    "variable": "var(--assets-icons-filter-off-line)"
  },
  "assets.icons.find-replace-fill": {
    "value": "/icons/remix-icon.svg#find-replace-fill",
    "variable": "var(--assets-icons-find-replace-fill)"
  },
  "assets.icons.find-replace-line": {
    "value": "/icons/remix-icon.svg#find-replace-line",
    "variable": "var(--assets-icons-find-replace-line)"
  },
  "assets.icons.finder-fill": {
    "value": "/icons/remix-icon.svg#finder-fill",
    "variable": "var(--assets-icons-finder-fill)"
  },
  "assets.icons.finder-line": {
    "value": "/icons/remix-icon.svg#finder-line",
    "variable": "var(--assets-icons-finder-line)"
  },
  "assets.icons.fingerprint-2-fill": {
    "value": "/icons/remix-icon.svg#fingerprint-2-fill",
    "variable": "var(--assets-icons-fingerprint-2-fill)"
  },
  "assets.icons.fingerprint-2-line": {
    "value": "/icons/remix-icon.svg#fingerprint-2-line",
    "variable": "var(--assets-icons-fingerprint-2-line)"
  },
  "assets.icons.fingerprint-fill": {
    "value": "/icons/remix-icon.svg#fingerprint-fill",
    "variable": "var(--assets-icons-fingerprint-fill)"
  },
  "assets.icons.fingerprint-line": {
    "value": "/icons/remix-icon.svg#fingerprint-line",
    "variable": "var(--assets-icons-fingerprint-line)"
  },
  "assets.icons.fire-fill": {
    "value": "/icons/remix-icon.svg#fire-fill",
    "variable": "var(--assets-icons-fire-fill)"
  },
  "assets.icons.fire-line": {
    "value": "/icons/remix-icon.svg#fire-line",
    "variable": "var(--assets-icons-fire-line)"
  },
  "assets.icons.firefox-fill": {
    "value": "/icons/remix-icon.svg#firefox-fill",
    "variable": "var(--assets-icons-firefox-fill)"
  },
  "assets.icons.firefox-line": {
    "value": "/icons/remix-icon.svg#firefox-line",
    "variable": "var(--assets-icons-firefox-line)"
  },
  "assets.icons.first-aid-kit-fill": {
    "value": "/icons/remix-icon.svg#first-aid-kit-fill",
    "variable": "var(--assets-icons-first-aid-kit-fill)"
  },
  "assets.icons.first-aid-kit-line": {
    "value": "/icons/remix-icon.svg#first-aid-kit-line",
    "variable": "var(--assets-icons-first-aid-kit-line)"
  },
  "assets.icons.flag-2-fill": {
    "value": "/icons/remix-icon.svg#flag-2-fill",
    "variable": "var(--assets-icons-flag-2-fill)"
  },
  "assets.icons.flag-2-line": {
    "value": "/icons/remix-icon.svg#flag-2-line",
    "variable": "var(--assets-icons-flag-2-line)"
  },
  "assets.icons.flag-fill": {
    "value": "/icons/remix-icon.svg#flag-fill",
    "variable": "var(--assets-icons-flag-fill)"
  },
  "assets.icons.flag-line": {
    "value": "/icons/remix-icon.svg#flag-line",
    "variable": "var(--assets-icons-flag-line)"
  },
  "assets.icons.flashlight-fill": {
    "value": "/icons/remix-icon.svg#flashlight-fill",
    "variable": "var(--assets-icons-flashlight-fill)"
  },
  "assets.icons.flashlight-line": {
    "value": "/icons/remix-icon.svg#flashlight-line",
    "variable": "var(--assets-icons-flashlight-line)"
  },
  "assets.icons.flask-fill": {
    "value": "/icons/remix-icon.svg#flask-fill",
    "variable": "var(--assets-icons-flask-fill)"
  },
  "assets.icons.flask-line": {
    "value": "/icons/remix-icon.svg#flask-line",
    "variable": "var(--assets-icons-flask-line)"
  },
  "assets.icons.flight-land-fill": {
    "value": "/icons/remix-icon.svg#flight-land-fill",
    "variable": "var(--assets-icons-flight-land-fill)"
  },
  "assets.icons.flight-land-line": {
    "value": "/icons/remix-icon.svg#flight-land-line",
    "variable": "var(--assets-icons-flight-land-line)"
  },
  "assets.icons.flight-takeoff-fill": {
    "value": "/icons/remix-icon.svg#flight-takeoff-fill",
    "variable": "var(--assets-icons-flight-takeoff-fill)"
  },
  "assets.icons.flight-takeoff-line": {
    "value": "/icons/remix-icon.svg#flight-takeoff-line",
    "variable": "var(--assets-icons-flight-takeoff-line)"
  },
  "assets.icons.flood-fill": {
    "value": "/icons/remix-icon.svg#flood-fill",
    "variable": "var(--assets-icons-flood-fill)"
  },
  "assets.icons.flood-line": {
    "value": "/icons/remix-icon.svg#flood-line",
    "variable": "var(--assets-icons-flood-line)"
  },
  "assets.icons.flow-chart": {
    "value": "/icons/remix-icon.svg#flow-chart",
    "variable": "var(--assets-icons-flow-chart)"
  },
  "assets.icons.flutter-fill": {
    "value": "/icons/remix-icon.svg#flutter-fill",
    "variable": "var(--assets-icons-flutter-fill)"
  },
  "assets.icons.flutter-line": {
    "value": "/icons/remix-icon.svg#flutter-line",
    "variable": "var(--assets-icons-flutter-line)"
  },
  "assets.icons.focus-2-fill": {
    "value": "/icons/remix-icon.svg#focus-2-fill",
    "variable": "var(--assets-icons-focus-2-fill)"
  },
  "assets.icons.focus-2-line": {
    "value": "/icons/remix-icon.svg#focus-2-line",
    "variable": "var(--assets-icons-focus-2-line)"
  },
  "assets.icons.focus-3-fill": {
    "value": "/icons/remix-icon.svg#focus-3-fill",
    "variable": "var(--assets-icons-focus-3-fill)"
  },
  "assets.icons.focus-3-line": {
    "value": "/icons/remix-icon.svg#focus-3-line",
    "variable": "var(--assets-icons-focus-3-line)"
  },
  "assets.icons.focus-fill": {
    "value": "/icons/remix-icon.svg#focus-fill",
    "variable": "var(--assets-icons-focus-fill)"
  },
  "assets.icons.focus-line": {
    "value": "/icons/remix-icon.svg#focus-line",
    "variable": "var(--assets-icons-focus-line)"
  },
  "assets.icons.foggy-fill": {
    "value": "/icons/remix-icon.svg#foggy-fill",
    "variable": "var(--assets-icons-foggy-fill)"
  },
  "assets.icons.foggy-line": {
    "value": "/icons/remix-icon.svg#foggy-line",
    "variable": "var(--assets-icons-foggy-line)"
  },
  "assets.icons.folder-2-fill": {
    "value": "/icons/remix-icon.svg#folder-2-fill",
    "variable": "var(--assets-icons-folder-2-fill)"
  },
  "assets.icons.folder-2-line": {
    "value": "/icons/remix-icon.svg#folder-2-line",
    "variable": "var(--assets-icons-folder-2-line)"
  },
  "assets.icons.folder-3-fill": {
    "value": "/icons/remix-icon.svg#folder-3-fill",
    "variable": "var(--assets-icons-folder-3-fill)"
  },
  "assets.icons.folder-3-line": {
    "value": "/icons/remix-icon.svg#folder-3-line",
    "variable": "var(--assets-icons-folder-3-line)"
  },
  "assets.icons.folder-4-fill": {
    "value": "/icons/remix-icon.svg#folder-4-fill",
    "variable": "var(--assets-icons-folder-4-fill)"
  },
  "assets.icons.folder-4-line": {
    "value": "/icons/remix-icon.svg#folder-4-line",
    "variable": "var(--assets-icons-folder-4-line)"
  },
  "assets.icons.folder-5-fill": {
    "value": "/icons/remix-icon.svg#folder-5-fill",
    "variable": "var(--assets-icons-folder-5-fill)"
  },
  "assets.icons.folder-5-line": {
    "value": "/icons/remix-icon.svg#folder-5-line",
    "variable": "var(--assets-icons-folder-5-line)"
  },
  "assets.icons.folder-add-fill": {
    "value": "/icons/remix-icon.svg#folder-add-fill",
    "variable": "var(--assets-icons-folder-add-fill)"
  },
  "assets.icons.folder-add-line": {
    "value": "/icons/remix-icon.svg#folder-add-line",
    "variable": "var(--assets-icons-folder-add-line)"
  },
  "assets.icons.folder-chart-2-fill": {
    "value": "/icons/remix-icon.svg#folder-chart-2-fill",
    "variable": "var(--assets-icons-folder-chart-2-fill)"
  },
  "assets.icons.folder-chart-2-line": {
    "value": "/icons/remix-icon.svg#folder-chart-2-line",
    "variable": "var(--assets-icons-folder-chart-2-line)"
  },
  "assets.icons.folder-chart-fill": {
    "value": "/icons/remix-icon.svg#folder-chart-fill",
    "variable": "var(--assets-icons-folder-chart-fill)"
  },
  "assets.icons.folder-chart-line": {
    "value": "/icons/remix-icon.svg#folder-chart-line",
    "variable": "var(--assets-icons-folder-chart-line)"
  },
  "assets.icons.folder-download-fill": {
    "value": "/icons/remix-icon.svg#folder-download-fill",
    "variable": "var(--assets-icons-folder-download-fill)"
  },
  "assets.icons.folder-download-line": {
    "value": "/icons/remix-icon.svg#folder-download-line",
    "variable": "var(--assets-icons-folder-download-line)"
  },
  "assets.icons.folder-fill": {
    "value": "/icons/remix-icon.svg#folder-fill",
    "variable": "var(--assets-icons-folder-fill)"
  },
  "assets.icons.folder-forbid-fill": {
    "value": "/icons/remix-icon.svg#folder-forbid-fill",
    "variable": "var(--assets-icons-folder-forbid-fill)"
  },
  "assets.icons.folder-forbid-line": {
    "value": "/icons/remix-icon.svg#folder-forbid-line",
    "variable": "var(--assets-icons-folder-forbid-line)"
  },
  "assets.icons.folder-history-fill": {
    "value": "/icons/remix-icon.svg#folder-history-fill",
    "variable": "var(--assets-icons-folder-history-fill)"
  },
  "assets.icons.folder-history-line": {
    "value": "/icons/remix-icon.svg#folder-history-line",
    "variable": "var(--assets-icons-folder-history-line)"
  },
  "assets.icons.folder-info-fill": {
    "value": "/icons/remix-icon.svg#folder-info-fill",
    "variable": "var(--assets-icons-folder-info-fill)"
  },
  "assets.icons.folder-info-line": {
    "value": "/icons/remix-icon.svg#folder-info-line",
    "variable": "var(--assets-icons-folder-info-line)"
  },
  "assets.icons.folder-keyhole-fill": {
    "value": "/icons/remix-icon.svg#folder-keyhole-fill",
    "variable": "var(--assets-icons-folder-keyhole-fill)"
  },
  "assets.icons.folder-keyhole-line": {
    "value": "/icons/remix-icon.svg#folder-keyhole-line",
    "variable": "var(--assets-icons-folder-keyhole-line)"
  },
  "assets.icons.folder-line": {
    "value": "/icons/remix-icon.svg#folder-line",
    "variable": "var(--assets-icons-folder-line)"
  },
  "assets.icons.folder-lock-fill": {
    "value": "/icons/remix-icon.svg#folder-lock-fill",
    "variable": "var(--assets-icons-folder-lock-fill)"
  },
  "assets.icons.folder-lock-line": {
    "value": "/icons/remix-icon.svg#folder-lock-line",
    "variable": "var(--assets-icons-folder-lock-line)"
  },
  "assets.icons.folder-music-fill": {
    "value": "/icons/remix-icon.svg#folder-music-fill",
    "variable": "var(--assets-icons-folder-music-fill)"
  },
  "assets.icons.folder-music-line": {
    "value": "/icons/remix-icon.svg#folder-music-line",
    "variable": "var(--assets-icons-folder-music-line)"
  },
  "assets.icons.folder-open-fill": {
    "value": "/icons/remix-icon.svg#folder-open-fill",
    "variable": "var(--assets-icons-folder-open-fill)"
  },
  "assets.icons.folder-open-line": {
    "value": "/icons/remix-icon.svg#folder-open-line",
    "variable": "var(--assets-icons-folder-open-line)"
  },
  "assets.icons.folder-received-fill": {
    "value": "/icons/remix-icon.svg#folder-received-fill",
    "variable": "var(--assets-icons-folder-received-fill)"
  },
  "assets.icons.folder-received-line": {
    "value": "/icons/remix-icon.svg#folder-received-line",
    "variable": "var(--assets-icons-folder-received-line)"
  },
  "assets.icons.folder-reduce-fill": {
    "value": "/icons/remix-icon.svg#folder-reduce-fill",
    "variable": "var(--assets-icons-folder-reduce-fill)"
  },
  "assets.icons.folder-reduce-line": {
    "value": "/icons/remix-icon.svg#folder-reduce-line",
    "variable": "var(--assets-icons-folder-reduce-line)"
  },
  "assets.icons.folder-settings-fill": {
    "value": "/icons/remix-icon.svg#folder-settings-fill",
    "variable": "var(--assets-icons-folder-settings-fill)"
  },
  "assets.icons.folder-settings-line": {
    "value": "/icons/remix-icon.svg#folder-settings-line",
    "variable": "var(--assets-icons-folder-settings-line)"
  },
  "assets.icons.folder-shared-fill": {
    "value": "/icons/remix-icon.svg#folder-shared-fill",
    "variable": "var(--assets-icons-folder-shared-fill)"
  },
  "assets.icons.folder-shared-line": {
    "value": "/icons/remix-icon.svg#folder-shared-line",
    "variable": "var(--assets-icons-folder-shared-line)"
  },
  "assets.icons.folder-shield-2-fill": {
    "value": "/icons/remix-icon.svg#folder-shield-2-fill",
    "variable": "var(--assets-icons-folder-shield-2-fill)"
  },
  "assets.icons.folder-shield-2-line": {
    "value": "/icons/remix-icon.svg#folder-shield-2-line",
    "variable": "var(--assets-icons-folder-shield-2-line)"
  },
  "assets.icons.folder-shield-fill": {
    "value": "/icons/remix-icon.svg#folder-shield-fill",
    "variable": "var(--assets-icons-folder-shield-fill)"
  },
  "assets.icons.folder-shield-line": {
    "value": "/icons/remix-icon.svg#folder-shield-line",
    "variable": "var(--assets-icons-folder-shield-line)"
  },
  "assets.icons.folder-transfer-fill": {
    "value": "/icons/remix-icon.svg#folder-transfer-fill",
    "variable": "var(--assets-icons-folder-transfer-fill)"
  },
  "assets.icons.folder-transfer-line": {
    "value": "/icons/remix-icon.svg#folder-transfer-line",
    "variable": "var(--assets-icons-folder-transfer-line)"
  },
  "assets.icons.folder-unknow-fill": {
    "value": "/icons/remix-icon.svg#folder-unknow-fill",
    "variable": "var(--assets-icons-folder-unknow-fill)"
  },
  "assets.icons.folder-unknow-line": {
    "value": "/icons/remix-icon.svg#folder-unknow-line",
    "variable": "var(--assets-icons-folder-unknow-line)"
  },
  "assets.icons.folder-upload-fill": {
    "value": "/icons/remix-icon.svg#folder-upload-fill",
    "variable": "var(--assets-icons-folder-upload-fill)"
  },
  "assets.icons.folder-upload-line": {
    "value": "/icons/remix-icon.svg#folder-upload-line",
    "variable": "var(--assets-icons-folder-upload-line)"
  },
  "assets.icons.folder-user-fill": {
    "value": "/icons/remix-icon.svg#folder-user-fill",
    "variable": "var(--assets-icons-folder-user-fill)"
  },
  "assets.icons.folder-user-line": {
    "value": "/icons/remix-icon.svg#folder-user-line",
    "variable": "var(--assets-icons-folder-user-line)"
  },
  "assets.icons.folder-warning-fill": {
    "value": "/icons/remix-icon.svg#folder-warning-fill",
    "variable": "var(--assets-icons-folder-warning-fill)"
  },
  "assets.icons.folder-warning-line": {
    "value": "/icons/remix-icon.svg#folder-warning-line",
    "variable": "var(--assets-icons-folder-warning-line)"
  },
  "assets.icons.folder-zip-fill": {
    "value": "/icons/remix-icon.svg#folder-zip-fill",
    "variable": "var(--assets-icons-folder-zip-fill)"
  },
  "assets.icons.folder-zip-line": {
    "value": "/icons/remix-icon.svg#folder-zip-line",
    "variable": "var(--assets-icons-folder-zip-line)"
  },
  "assets.icons.folders-fill": {
    "value": "/icons/remix-icon.svg#folders-fill",
    "variable": "var(--assets-icons-folders-fill)"
  },
  "assets.icons.folders-line": {
    "value": "/icons/remix-icon.svg#folders-line",
    "variable": "var(--assets-icons-folders-line)"
  },
  "assets.icons.font-color": {
    "value": "/icons/remix-icon.svg#font-color",
    "variable": "var(--assets-icons-font-color)"
  },
  "assets.icons.font-size-2": {
    "value": "/icons/remix-icon.svg#font-size-2",
    "variable": "var(--assets-icons-font-size-2)"
  },
  "assets.icons.font-size": {
    "value": "/icons/remix-icon.svg#font-size",
    "variable": "var(--assets-icons-font-size)"
  },
  "assets.icons.football-fill": {
    "value": "/icons/remix-icon.svg#football-fill",
    "variable": "var(--assets-icons-football-fill)"
  },
  "assets.icons.football-line": {
    "value": "/icons/remix-icon.svg#football-line",
    "variable": "var(--assets-icons-football-line)"
  },
  "assets.icons.footprint-fill": {
    "value": "/icons/remix-icon.svg#footprint-fill",
    "variable": "var(--assets-icons-footprint-fill)"
  },
  "assets.icons.footprint-line": {
    "value": "/icons/remix-icon.svg#footprint-line",
    "variable": "var(--assets-icons-footprint-line)"
  },
  "assets.icons.forbid-2-fill": {
    "value": "/icons/remix-icon.svg#forbid-2-fill",
    "variable": "var(--assets-icons-forbid-2-fill)"
  },
  "assets.icons.forbid-2-line": {
    "value": "/icons/remix-icon.svg#forbid-2-line",
    "variable": "var(--assets-icons-forbid-2-line)"
  },
  "assets.icons.forbid-fill": {
    "value": "/icons/remix-icon.svg#forbid-fill",
    "variable": "var(--assets-icons-forbid-fill)"
  },
  "assets.icons.forbid-line": {
    "value": "/icons/remix-icon.svg#forbid-line",
    "variable": "var(--assets-icons-forbid-line)"
  },
  "assets.icons.format-clear": {
    "value": "/icons/remix-icon.svg#format-clear",
    "variable": "var(--assets-icons-format-clear)"
  },
  "assets.icons.fridge-fill": {
    "value": "/icons/remix-icon.svg#fridge-fill",
    "variable": "var(--assets-icons-fridge-fill)"
  },
  "assets.icons.fridge-line": {
    "value": "/icons/remix-icon.svg#fridge-line",
    "variable": "var(--assets-icons-fridge-line)"
  },
  "assets.icons.fullscreen-exit-fill": {
    "value": "/icons/remix-icon.svg#fullscreen-exit-fill",
    "variable": "var(--assets-icons-fullscreen-exit-fill)"
  },
  "assets.icons.fullscreen-exit-line": {
    "value": "/icons/remix-icon.svg#fullscreen-exit-line",
    "variable": "var(--assets-icons-fullscreen-exit-line)"
  },
  "assets.icons.fullscreen-fill": {
    "value": "/icons/remix-icon.svg#fullscreen-fill",
    "variable": "var(--assets-icons-fullscreen-fill)"
  },
  "assets.icons.fullscreen-line": {
    "value": "/icons/remix-icon.svg#fullscreen-line",
    "variable": "var(--assets-icons-fullscreen-line)"
  },
  "assets.icons.function-fill": {
    "value": "/icons/remix-icon.svg#function-fill",
    "variable": "var(--assets-icons-function-fill)"
  },
  "assets.icons.function-line": {
    "value": "/icons/remix-icon.svg#function-line",
    "variable": "var(--assets-icons-function-line)"
  },
  "assets.icons.functions": {
    "value": "/icons/remix-icon.svg#functions",
    "variable": "var(--assets-icons-functions)"
  },
  "assets.icons.funds-box-fill": {
    "value": "/icons/remix-icon.svg#funds-box-fill",
    "variable": "var(--assets-icons-funds-box-fill)"
  },
  "assets.icons.funds-box-line": {
    "value": "/icons/remix-icon.svg#funds-box-line",
    "variable": "var(--assets-icons-funds-box-line)"
  },
  "assets.icons.funds-fill": {
    "value": "/icons/remix-icon.svg#funds-fill",
    "variable": "var(--assets-icons-funds-fill)"
  },
  "assets.icons.funds-line": {
    "value": "/icons/remix-icon.svg#funds-line",
    "variable": "var(--assets-icons-funds-line)"
  },
  "assets.icons.gallery-fill": {
    "value": "/icons/remix-icon.svg#gallery-fill",
    "variable": "var(--assets-icons-gallery-fill)"
  },
  "assets.icons.gallery-line": {
    "value": "/icons/remix-icon.svg#gallery-line",
    "variable": "var(--assets-icons-gallery-line)"
  },
  "assets.icons.gallery-upload-fill": {
    "value": "/icons/remix-icon.svg#gallery-upload-fill",
    "variable": "var(--assets-icons-gallery-upload-fill)"
  },
  "assets.icons.gallery-upload-line": {
    "value": "/icons/remix-icon.svg#gallery-upload-line",
    "variable": "var(--assets-icons-gallery-upload-line)"
  },
  "assets.icons.game-fill": {
    "value": "/icons/remix-icon.svg#game-fill",
    "variable": "var(--assets-icons-game-fill)"
  },
  "assets.icons.game-line": {
    "value": "/icons/remix-icon.svg#game-line",
    "variable": "var(--assets-icons-game-line)"
  },
  "assets.icons.gamepad-fill": {
    "value": "/icons/remix-icon.svg#gamepad-fill",
    "variable": "var(--assets-icons-gamepad-fill)"
  },
  "assets.icons.gamepad-line": {
    "value": "/icons/remix-icon.svg#gamepad-line",
    "variable": "var(--assets-icons-gamepad-line)"
  },
  "assets.icons.gas-station-fill": {
    "value": "/icons/remix-icon.svg#gas-station-fill",
    "variable": "var(--assets-icons-gas-station-fill)"
  },
  "assets.icons.gas-station-line": {
    "value": "/icons/remix-icon.svg#gas-station-line",
    "variable": "var(--assets-icons-gas-station-line)"
  },
  "assets.icons.gatsby-fill": {
    "value": "/icons/remix-icon.svg#gatsby-fill",
    "variable": "var(--assets-icons-gatsby-fill)"
  },
  "assets.icons.gatsby-line": {
    "value": "/icons/remix-icon.svg#gatsby-line",
    "variable": "var(--assets-icons-gatsby-line)"
  },
  "assets.icons.genderless-fill": {
    "value": "/icons/remix-icon.svg#genderless-fill",
    "variable": "var(--assets-icons-genderless-fill)"
  },
  "assets.icons.genderless-line": {
    "value": "/icons/remix-icon.svg#genderless-line",
    "variable": "var(--assets-icons-genderless-line)"
  },
  "assets.icons.ghost-2-fill": {
    "value": "/icons/remix-icon.svg#ghost-2-fill",
    "variable": "var(--assets-icons-ghost-2-fill)"
  },
  "assets.icons.ghost-2-line": {
    "value": "/icons/remix-icon.svg#ghost-2-line",
    "variable": "var(--assets-icons-ghost-2-line)"
  },
  "assets.icons.ghost-fill": {
    "value": "/icons/remix-icon.svg#ghost-fill",
    "variable": "var(--assets-icons-ghost-fill)"
  },
  "assets.icons.ghost-line": {
    "value": "/icons/remix-icon.svg#ghost-line",
    "variable": "var(--assets-icons-ghost-line)"
  },
  "assets.icons.ghost-smile-fill": {
    "value": "/icons/remix-icon.svg#ghost-smile-fill",
    "variable": "var(--assets-icons-ghost-smile-fill)"
  },
  "assets.icons.ghost-smile-line": {
    "value": "/icons/remix-icon.svg#ghost-smile-line",
    "variable": "var(--assets-icons-ghost-smile-line)"
  },
  "assets.icons.gift-2-fill": {
    "value": "/icons/remix-icon.svg#gift-2-fill",
    "variable": "var(--assets-icons-gift-2-fill)"
  },
  "assets.icons.gift-2-line": {
    "value": "/icons/remix-icon.svg#gift-2-line",
    "variable": "var(--assets-icons-gift-2-line)"
  },
  "assets.icons.gift-fill": {
    "value": "/icons/remix-icon.svg#gift-fill",
    "variable": "var(--assets-icons-gift-fill)"
  },
  "assets.icons.gift-line": {
    "value": "/icons/remix-icon.svg#gift-line",
    "variable": "var(--assets-icons-gift-line)"
  },
  "assets.icons.git-branch-fill": {
    "value": "/icons/remix-icon.svg#git-branch-fill",
    "variable": "var(--assets-icons-git-branch-fill)"
  },
  "assets.icons.git-branch-line": {
    "value": "/icons/remix-icon.svg#git-branch-line",
    "variable": "var(--assets-icons-git-branch-line)"
  },
  "assets.icons.git-commit-fill": {
    "value": "/icons/remix-icon.svg#git-commit-fill",
    "variable": "var(--assets-icons-git-commit-fill)"
  },
  "assets.icons.git-commit-line": {
    "value": "/icons/remix-icon.svg#git-commit-line",
    "variable": "var(--assets-icons-git-commit-line)"
  },
  "assets.icons.git-merge-fill": {
    "value": "/icons/remix-icon.svg#git-merge-fill",
    "variable": "var(--assets-icons-git-merge-fill)"
  },
  "assets.icons.git-merge-line": {
    "value": "/icons/remix-icon.svg#git-merge-line",
    "variable": "var(--assets-icons-git-merge-line)"
  },
  "assets.icons.git-pull-request-fill": {
    "value": "/icons/remix-icon.svg#git-pull-request-fill",
    "variable": "var(--assets-icons-git-pull-request-fill)"
  },
  "assets.icons.git-pull-request-line": {
    "value": "/icons/remix-icon.svg#git-pull-request-line",
    "variable": "var(--assets-icons-git-pull-request-line)"
  },
  "assets.icons.git-repository-commits-fill": {
    "value": "/icons/remix-icon.svg#git-repository-commits-fill",
    "variable": "var(--assets-icons-git-repository-commits-fill)"
  },
  "assets.icons.git-repository-commits-line": {
    "value": "/icons/remix-icon.svg#git-repository-commits-line",
    "variable": "var(--assets-icons-git-repository-commits-line)"
  },
  "assets.icons.git-repository-fill": {
    "value": "/icons/remix-icon.svg#git-repository-fill",
    "variable": "var(--assets-icons-git-repository-fill)"
  },
  "assets.icons.git-repository-line": {
    "value": "/icons/remix-icon.svg#git-repository-line",
    "variable": "var(--assets-icons-git-repository-line)"
  },
  "assets.icons.git-repository-private-fill": {
    "value": "/icons/remix-icon.svg#git-repository-private-fill",
    "variable": "var(--assets-icons-git-repository-private-fill)"
  },
  "assets.icons.git-repository-private-line": {
    "value": "/icons/remix-icon.svg#git-repository-private-line",
    "variable": "var(--assets-icons-git-repository-private-line)"
  },
  "assets.icons.github-fill": {
    "value": "/icons/remix-icon.svg#github-fill",
    "variable": "var(--assets-icons-github-fill)"
  },
  "assets.icons.github-line": {
    "value": "/icons/remix-icon.svg#github-line",
    "variable": "var(--assets-icons-github-line)"
  },
  "assets.icons.gitlab-fill": {
    "value": "/icons/remix-icon.svg#gitlab-fill",
    "variable": "var(--assets-icons-gitlab-fill)"
  },
  "assets.icons.gitlab-line": {
    "value": "/icons/remix-icon.svg#gitlab-line",
    "variable": "var(--assets-icons-gitlab-line)"
  },
  "assets.icons.global-fill": {
    "value": "/icons/remix-icon.svg#global-fill",
    "variable": "var(--assets-icons-global-fill)"
  },
  "assets.icons.global-line": {
    "value": "/icons/remix-icon.svg#global-line",
    "variable": "var(--assets-icons-global-line)"
  },
  "assets.icons.globe-fill": {
    "value": "/icons/remix-icon.svg#globe-fill",
    "variable": "var(--assets-icons-globe-fill)"
  },
  "assets.icons.globe-line": {
    "value": "/icons/remix-icon.svg#globe-line",
    "variable": "var(--assets-icons-globe-line)"
  },
  "assets.icons.goblet-fill": {
    "value": "/icons/remix-icon.svg#goblet-fill",
    "variable": "var(--assets-icons-goblet-fill)"
  },
  "assets.icons.goblet-line": {
    "value": "/icons/remix-icon.svg#goblet-line",
    "variable": "var(--assets-icons-goblet-line)"
  },
  "assets.icons.google-fill": {
    "value": "/icons/remix-icon.svg#google-fill",
    "variable": "var(--assets-icons-google-fill)"
  },
  "assets.icons.google-line": {
    "value": "/icons/remix-icon.svg#google-line",
    "variable": "var(--assets-icons-google-line)"
  },
  "assets.icons.google-play-fill": {
    "value": "/icons/remix-icon.svg#google-play-fill",
    "variable": "var(--assets-icons-google-play-fill)"
  },
  "assets.icons.google-play-line": {
    "value": "/icons/remix-icon.svg#google-play-line",
    "variable": "var(--assets-icons-google-play-line)"
  },
  "assets.icons.government-fill": {
    "value": "/icons/remix-icon.svg#government-fill",
    "variable": "var(--assets-icons-government-fill)"
  },
  "assets.icons.government-line": {
    "value": "/icons/remix-icon.svg#government-line",
    "variable": "var(--assets-icons-government-line)"
  },
  "assets.icons.gps-fill": {
    "value": "/icons/remix-icon.svg#gps-fill",
    "variable": "var(--assets-icons-gps-fill)"
  },
  "assets.icons.gps-line": {
    "value": "/icons/remix-icon.svg#gps-line",
    "variable": "var(--assets-icons-gps-line)"
  },
  "assets.icons.gradienter-fill": {
    "value": "/icons/remix-icon.svg#gradienter-fill",
    "variable": "var(--assets-icons-gradienter-fill)"
  },
  "assets.icons.gradienter-line": {
    "value": "/icons/remix-icon.svg#gradienter-line",
    "variable": "var(--assets-icons-gradienter-line)"
  },
  "assets.icons.grid-fill": {
    "value": "/icons/remix-icon.svg#grid-fill",
    "variable": "var(--assets-icons-grid-fill)"
  },
  "assets.icons.grid-line": {
    "value": "/icons/remix-icon.svg#grid-line",
    "variable": "var(--assets-icons-grid-line)"
  },
  "assets.icons.group-2-fill": {
    "value": "/icons/remix-icon.svg#group-2-fill",
    "variable": "var(--assets-icons-group-2-fill)"
  },
  "assets.icons.group-2-line": {
    "value": "/icons/remix-icon.svg#group-2-line",
    "variable": "var(--assets-icons-group-2-line)"
  },
  "assets.icons.group-fill": {
    "value": "/icons/remix-icon.svg#group-fill",
    "variable": "var(--assets-icons-group-fill)"
  },
  "assets.icons.group-line": {
    "value": "/icons/remix-icon.svg#group-line",
    "variable": "var(--assets-icons-group-line)"
  },
  "assets.icons.guide-fill": {
    "value": "/icons/remix-icon.svg#guide-fill",
    "variable": "var(--assets-icons-guide-fill)"
  },
  "assets.icons.guide-line": {
    "value": "/icons/remix-icon.svg#guide-line",
    "variable": "var(--assets-icons-guide-line)"
  },
  "assets.icons.h-1": {
    "value": "/icons/remix-icon.svg#h-1",
    "variable": "var(--assets-icons-h-1)"
  },
  "assets.icons.h-2": {
    "value": "/icons/remix-icon.svg#h-2",
    "variable": "var(--assets-icons-h-2)"
  },
  "assets.icons.h-3": {
    "value": "/icons/remix-icon.svg#h-3",
    "variable": "var(--assets-icons-h-3)"
  },
  "assets.icons.h-4": {
    "value": "/icons/remix-icon.svg#h-4",
    "variable": "var(--assets-icons-h-4)"
  },
  "assets.icons.h-5": {
    "value": "/icons/remix-icon.svg#h-5",
    "variable": "var(--assets-icons-h-5)"
  },
  "assets.icons.h-6": {
    "value": "/icons/remix-icon.svg#h-6",
    "variable": "var(--assets-icons-h-6)"
  },
  "assets.icons.hail-fill": {
    "value": "/icons/remix-icon.svg#hail-fill",
    "variable": "var(--assets-icons-hail-fill)"
  },
  "assets.icons.hail-line": {
    "value": "/icons/remix-icon.svg#hail-line",
    "variable": "var(--assets-icons-hail-line)"
  },
  "assets.icons.hammer-fill": {
    "value": "/icons/remix-icon.svg#hammer-fill",
    "variable": "var(--assets-icons-hammer-fill)"
  },
  "assets.icons.hammer-line": {
    "value": "/icons/remix-icon.svg#hammer-line",
    "variable": "var(--assets-icons-hammer-line)"
  },
  "assets.icons.hand-coin-fill": {
    "value": "/icons/remix-icon.svg#hand-coin-fill",
    "variable": "var(--assets-icons-hand-coin-fill)"
  },
  "assets.icons.hand-coin-line": {
    "value": "/icons/remix-icon.svg#hand-coin-line",
    "variable": "var(--assets-icons-hand-coin-line)"
  },
  "assets.icons.hand-heart-fill": {
    "value": "/icons/remix-icon.svg#hand-heart-fill",
    "variable": "var(--assets-icons-hand-heart-fill)"
  },
  "assets.icons.hand-heart-line": {
    "value": "/icons/remix-icon.svg#hand-heart-line",
    "variable": "var(--assets-icons-hand-heart-line)"
  },
  "assets.icons.hand-sanitizer-fill": {
    "value": "/icons/remix-icon.svg#hand-sanitizer-fill",
    "variable": "var(--assets-icons-hand-sanitizer-fill)"
  },
  "assets.icons.hand-sanitizer-line": {
    "value": "/icons/remix-icon.svg#hand-sanitizer-line",
    "variable": "var(--assets-icons-hand-sanitizer-line)"
  },
  "assets.icons.handbag-fill": {
    "value": "/icons/remix-icon.svg#handbag-fill",
    "variable": "var(--assets-icons-handbag-fill)"
  },
  "assets.icons.handbag-line": {
    "value": "/icons/remix-icon.svg#handbag-line",
    "variable": "var(--assets-icons-handbag-line)"
  },
  "assets.icons.hard-drive-2-fill": {
    "value": "/icons/remix-icon.svg#hard-drive-2-fill",
    "variable": "var(--assets-icons-hard-drive-2-fill)"
  },
  "assets.icons.hard-drive-2-line": {
    "value": "/icons/remix-icon.svg#hard-drive-2-line",
    "variable": "var(--assets-icons-hard-drive-2-line)"
  },
  "assets.icons.hard-drive-fill": {
    "value": "/icons/remix-icon.svg#hard-drive-fill",
    "variable": "var(--assets-icons-hard-drive-fill)"
  },
  "assets.icons.hard-drive-line": {
    "value": "/icons/remix-icon.svg#hard-drive-line",
    "variable": "var(--assets-icons-hard-drive-line)"
  },
  "assets.icons.hashtag": {
    "value": "/icons/remix-icon.svg#hashtag",
    "variable": "var(--assets-icons-hashtag)"
  },
  "assets.icons.haze-2-fill": {
    "value": "/icons/remix-icon.svg#haze-2-fill",
    "variable": "var(--assets-icons-haze-2-fill)"
  },
  "assets.icons.haze-2-line": {
    "value": "/icons/remix-icon.svg#haze-2-line",
    "variable": "var(--assets-icons-haze-2-line)"
  },
  "assets.icons.haze-fill": {
    "value": "/icons/remix-icon.svg#haze-fill",
    "variable": "var(--assets-icons-haze-fill)"
  },
  "assets.icons.haze-line": {
    "value": "/icons/remix-icon.svg#haze-line",
    "variable": "var(--assets-icons-haze-line)"
  },
  "assets.icons.hd-fill": {
    "value": "/icons/remix-icon.svg#hd-fill",
    "variable": "var(--assets-icons-hd-fill)"
  },
  "assets.icons.hd-line": {
    "value": "/icons/remix-icon.svg#hd-line",
    "variable": "var(--assets-icons-hd-line)"
  },
  "assets.icons.heading": {
    "value": "/icons/remix-icon.svg#heading",
    "variable": "var(--assets-icons-heading)"
  },
  "assets.icons.headphone-fill": {
    "value": "/icons/remix-icon.svg#headphone-fill",
    "variable": "var(--assets-icons-headphone-fill)"
  },
  "assets.icons.headphone-line": {
    "value": "/icons/remix-icon.svg#headphone-line",
    "variable": "var(--assets-icons-headphone-line)"
  },
  "assets.icons.health-book-fill": {
    "value": "/icons/remix-icon.svg#health-book-fill",
    "variable": "var(--assets-icons-health-book-fill)"
  },
  "assets.icons.health-book-line": {
    "value": "/icons/remix-icon.svg#health-book-line",
    "variable": "var(--assets-icons-health-book-line)"
  },
  "assets.icons.heart-2-fill": {
    "value": "/icons/remix-icon.svg#heart-2-fill",
    "variable": "var(--assets-icons-heart-2-fill)"
  },
  "assets.icons.heart-2-line": {
    "value": "/icons/remix-icon.svg#heart-2-line",
    "variable": "var(--assets-icons-heart-2-line)"
  },
  "assets.icons.heart-3-fill": {
    "value": "/icons/remix-icon.svg#heart-3-fill",
    "variable": "var(--assets-icons-heart-3-fill)"
  },
  "assets.icons.heart-3-line": {
    "value": "/icons/remix-icon.svg#heart-3-line",
    "variable": "var(--assets-icons-heart-3-line)"
  },
  "assets.icons.heart-add-fill": {
    "value": "/icons/remix-icon.svg#heart-add-fill",
    "variable": "var(--assets-icons-heart-add-fill)"
  },
  "assets.icons.heart-add-line": {
    "value": "/icons/remix-icon.svg#heart-add-line",
    "variable": "var(--assets-icons-heart-add-line)"
  },
  "assets.icons.heart-line": {
    "value": "/icons/remix-icon.svg#heart-line",
    "variable": "var(--assets-icons-heart-line)"
  },
  "assets.icons.heart-pulse-fill": {
    "value": "/icons/remix-icon.svg#heart-pulse-fill",
    "variable": "var(--assets-icons-heart-pulse-fill)"
  },
  "assets.icons.heart-pulse-line": {
    "value": "/icons/remix-icon.svg#heart-pulse-line",
    "variable": "var(--assets-icons-heart-pulse-line)"
  },
  "assets.icons.hearts-fill": {
    "value": "/icons/remix-icon.svg#hearts-fill",
    "variable": "var(--assets-icons-hearts-fill)"
  },
  "assets.icons.hearts-line": {
    "value": "/icons/remix-icon.svg#hearts-line",
    "variable": "var(--assets-icons-hearts-line)"
  },
  "assets.icons.heavy-showers-fill": {
    "value": "/icons/remix-icon.svg#heavy-showers-fill",
    "variable": "var(--assets-icons-heavy-showers-fill)"
  },
  "assets.icons.heavy-showers-line": {
    "value": "/icons/remix-icon.svg#heavy-showers-line",
    "variable": "var(--assets-icons-heavy-showers-line)"
  },
  "assets.icons.history-fill": {
    "value": "/icons/remix-icon.svg#history-fill",
    "variable": "var(--assets-icons-history-fill)"
  },
  "assets.icons.history-line": {
    "value": "/icons/remix-icon.svg#history-line",
    "variable": "var(--assets-icons-history-line)"
  },
  "assets.icons.home-2-fill": {
    "value": "/icons/remix-icon.svg#home-2-fill",
    "variable": "var(--assets-icons-home-2-fill)"
  },
  "assets.icons.home-2-line": {
    "value": "/icons/remix-icon.svg#home-2-line",
    "variable": "var(--assets-icons-home-2-line)"
  },
  "assets.icons.home-3-fill": {
    "value": "/icons/remix-icon.svg#home-3-fill",
    "variable": "var(--assets-icons-home-3-fill)"
  },
  "assets.icons.home-3-line": {
    "value": "/icons/remix-icon.svg#home-3-line",
    "variable": "var(--assets-icons-home-3-line)"
  },
  "assets.icons.home-4-fill": {
    "value": "/icons/remix-icon.svg#home-4-fill",
    "variable": "var(--assets-icons-home-4-fill)"
  },
  "assets.icons.home-4-line": {
    "value": "/icons/remix-icon.svg#home-4-line",
    "variable": "var(--assets-icons-home-4-line)"
  },
  "assets.icons.home-5-fill": {
    "value": "/icons/remix-icon.svg#home-5-fill",
    "variable": "var(--assets-icons-home-5-fill)"
  },
  "assets.icons.home-5-line": {
    "value": "/icons/remix-icon.svg#home-5-line",
    "variable": "var(--assets-icons-home-5-line)"
  },
  "assets.icons.home-6-fill": {
    "value": "/icons/remix-icon.svg#home-6-fill",
    "variable": "var(--assets-icons-home-6-fill)"
  },
  "assets.icons.home-6-line": {
    "value": "/icons/remix-icon.svg#home-6-line",
    "variable": "var(--assets-icons-home-6-line)"
  },
  "assets.icons.home-7-fill": {
    "value": "/icons/remix-icon.svg#home-7-fill",
    "variable": "var(--assets-icons-home-7-fill)"
  },
  "assets.icons.home-7-line": {
    "value": "/icons/remix-icon.svg#home-7-line",
    "variable": "var(--assets-icons-home-7-line)"
  },
  "assets.icons.home-8-fill": {
    "value": "/icons/remix-icon.svg#home-8-fill",
    "variable": "var(--assets-icons-home-8-fill)"
  },
  "assets.icons.home-8-line": {
    "value": "/icons/remix-icon.svg#home-8-line",
    "variable": "var(--assets-icons-home-8-line)"
  },
  "assets.icons.home-fill": {
    "value": "/icons/remix-icon.svg#home-fill",
    "variable": "var(--assets-icons-home-fill)"
  },
  "assets.icons.home-gear-fill": {
    "value": "/icons/remix-icon.svg#home-gear-fill",
    "variable": "var(--assets-icons-home-gear-fill)"
  },
  "assets.icons.home-gear-line": {
    "value": "/icons/remix-icon.svg#home-gear-line",
    "variable": "var(--assets-icons-home-gear-line)"
  },
  "assets.icons.home-heart-fill": {
    "value": "/icons/remix-icon.svg#home-heart-fill",
    "variable": "var(--assets-icons-home-heart-fill)"
  },
  "assets.icons.home-heart-line": {
    "value": "/icons/remix-icon.svg#home-heart-line",
    "variable": "var(--assets-icons-home-heart-line)"
  },
  "assets.icons.home-line": {
    "value": "/icons/remix-icon.svg#home-line",
    "variable": "var(--assets-icons-home-line)"
  },
  "assets.icons.home-smile-2-fill": {
    "value": "/icons/remix-icon.svg#home-smile-2-fill",
    "variable": "var(--assets-icons-home-smile-2-fill)"
  },
  "assets.icons.home-smile-2-line": {
    "value": "/icons/remix-icon.svg#home-smile-2-line",
    "variable": "var(--assets-icons-home-smile-2-line)"
  },
  "assets.icons.home-smile-fill": {
    "value": "/icons/remix-icon.svg#home-smile-fill",
    "variable": "var(--assets-icons-home-smile-fill)"
  },
  "assets.icons.home-smile-line": {
    "value": "/icons/remix-icon.svg#home-smile-line",
    "variable": "var(--assets-icons-home-smile-line)"
  },
  "assets.icons.home-wifi-fill": {
    "value": "/icons/remix-icon.svg#home-wifi-fill",
    "variable": "var(--assets-icons-home-wifi-fill)"
  },
  "assets.icons.home-wifi-line": {
    "value": "/icons/remix-icon.svg#home-wifi-line",
    "variable": "var(--assets-icons-home-wifi-line)"
  },
  "assets.icons.honor-of-kings-fill": {
    "value": "/icons/remix-icon.svg#honor-of-kings-fill",
    "variable": "var(--assets-icons-honor-of-kings-fill)"
  },
  "assets.icons.honor-of-kings-line": {
    "value": "/icons/remix-icon.svg#honor-of-kings-line",
    "variable": "var(--assets-icons-honor-of-kings-line)"
  },
  "assets.icons.honour-fill": {
    "value": "/icons/remix-icon.svg#honour-fill",
    "variable": "var(--assets-icons-honour-fill)"
  },
  "assets.icons.honour-line": {
    "value": "/icons/remix-icon.svg#honour-line",
    "variable": "var(--assets-icons-honour-line)"
  },
  "assets.icons.hospital-fill": {
    "value": "/icons/remix-icon.svg#hospital-fill",
    "variable": "var(--assets-icons-hospital-fill)"
  },
  "assets.icons.hospital-line": {
    "value": "/icons/remix-icon.svg#hospital-line",
    "variable": "var(--assets-icons-hospital-line)"
  },
  "assets.icons.hotel-bed-fill": {
    "value": "/icons/remix-icon.svg#hotel-bed-fill",
    "variable": "var(--assets-icons-hotel-bed-fill)"
  },
  "assets.icons.hotel-bed-line": {
    "value": "/icons/remix-icon.svg#hotel-bed-line",
    "variable": "var(--assets-icons-hotel-bed-line)"
  },
  "assets.icons.hotel-fill": {
    "value": "/icons/remix-icon.svg#hotel-fill",
    "variable": "var(--assets-icons-hotel-fill)"
  },
  "assets.icons.hotel-line": {
    "value": "/icons/remix-icon.svg#hotel-line",
    "variable": "var(--assets-icons-hotel-line)"
  },
  "assets.icons.hotspot-fill": {
    "value": "/icons/remix-icon.svg#hotspot-fill",
    "variable": "var(--assets-icons-hotspot-fill)"
  },
  "assets.icons.hotspot-line": {
    "value": "/icons/remix-icon.svg#hotspot-line",
    "variable": "var(--assets-icons-hotspot-line)"
  },
  "assets.icons.hq-fill": {
    "value": "/icons/remix-icon.svg#hq-fill",
    "variable": "var(--assets-icons-hq-fill)"
  },
  "assets.icons.hq-line": {
    "value": "/icons/remix-icon.svg#hq-line",
    "variable": "var(--assets-icons-hq-line)"
  },
  "assets.icons.html5-fill": {
    "value": "/icons/remix-icon.svg#html5-fill",
    "variable": "var(--assets-icons-html5-fill)"
  },
  "assets.icons.html5-line": {
    "value": "/icons/remix-icon.svg#html5-line",
    "variable": "var(--assets-icons-html5-line)"
  },
  "assets.icons.ie-fill": {
    "value": "/icons/remix-icon.svg#ie-fill",
    "variable": "var(--assets-icons-ie-fill)"
  },
  "assets.icons.ie-line": {
    "value": "/icons/remix-icon.svg#ie-line",
    "variable": "var(--assets-icons-ie-line)"
  },
  "assets.icons.image-2-fill": {
    "value": "/icons/remix-icon.svg#image-2-fill",
    "variable": "var(--assets-icons-image-2-fill)"
  },
  "assets.icons.image-2-line": {
    "value": "/icons/remix-icon.svg#image-2-line",
    "variable": "var(--assets-icons-image-2-line)"
  },
  "assets.icons.image-add-fill": {
    "value": "/icons/remix-icon.svg#image-add-fill",
    "variable": "var(--assets-icons-image-add-fill)"
  },
  "assets.icons.image-add-line": {
    "value": "/icons/remix-icon.svg#image-add-line",
    "variable": "var(--assets-icons-image-add-line)"
  },
  "assets.icons.image-edit-fill": {
    "value": "/icons/remix-icon.svg#image-edit-fill",
    "variable": "var(--assets-icons-image-edit-fill)"
  },
  "assets.icons.image-edit-line": {
    "value": "/icons/remix-icon.svg#image-edit-line",
    "variable": "var(--assets-icons-image-edit-line)"
  },
  "assets.icons.image-fill": {
    "value": "/icons/remix-icon.svg#image-fill",
    "variable": "var(--assets-icons-image-fill)"
  },
  "assets.icons.image-line": {
    "value": "/icons/remix-icon.svg#image-line",
    "variable": "var(--assets-icons-image-line)"
  },
  "assets.icons.inbox-archive-fill": {
    "value": "/icons/remix-icon.svg#inbox-archive-fill",
    "variable": "var(--assets-icons-inbox-archive-fill)"
  },
  "assets.icons.inbox-archive-line": {
    "value": "/icons/remix-icon.svg#inbox-archive-line",
    "variable": "var(--assets-icons-inbox-archive-line)"
  },
  "assets.icons.inbox-fill": {
    "value": "/icons/remix-icon.svg#inbox-fill",
    "variable": "var(--assets-icons-inbox-fill)"
  },
  "assets.icons.inbox-line": {
    "value": "/icons/remix-icon.svg#inbox-line",
    "variable": "var(--assets-icons-inbox-line)"
  },
  "assets.icons.inbox-unarchive-fill": {
    "value": "/icons/remix-icon.svg#inbox-unarchive-fill",
    "variable": "var(--assets-icons-inbox-unarchive-fill)"
  },
  "assets.icons.inbox-unarchive-line": {
    "value": "/icons/remix-icon.svg#inbox-unarchive-line",
    "variable": "var(--assets-icons-inbox-unarchive-line)"
  },
  "assets.icons.increase-decrease-fill": {
    "value": "/icons/remix-icon.svg#increase-decrease-fill",
    "variable": "var(--assets-icons-increase-decrease-fill)"
  },
  "assets.icons.increase-decrease-line": {
    "value": "/icons/remix-icon.svg#increase-decrease-line",
    "variable": "var(--assets-icons-increase-decrease-line)"
  },
  "assets.icons.indent-decrease": {
    "value": "/icons/remix-icon.svg#indent-decrease",
    "variable": "var(--assets-icons-indent-decrease)"
  },
  "assets.icons.indent-increase": {
    "value": "/icons/remix-icon.svg#indent-increase",
    "variable": "var(--assets-icons-indent-increase)"
  },
  "assets.icons.indeterminate-circle-fill": {
    "value": "/icons/remix-icon.svg#indeterminate-circle-fill",
    "variable": "var(--assets-icons-indeterminate-circle-fill)"
  },
  "assets.icons.indeterminate-circle-line": {
    "value": "/icons/remix-icon.svg#indeterminate-circle-line",
    "variable": "var(--assets-icons-indeterminate-circle-line)"
  },
  "assets.icons.information-fill": {
    "value": "/icons/remix-icon.svg#information-fill",
    "variable": "var(--assets-icons-information-fill)"
  },
  "assets.icons.information-line": {
    "value": "/icons/remix-icon.svg#information-line",
    "variable": "var(--assets-icons-information-line)"
  },
  "assets.icons.infrared-thermometer-fill": {
    "value": "/icons/remix-icon.svg#infrared-thermometer-fill",
    "variable": "var(--assets-icons-infrared-thermometer-fill)"
  },
  "assets.icons.infrared-thermometer-line": {
    "value": "/icons/remix-icon.svg#infrared-thermometer-line",
    "variable": "var(--assets-icons-infrared-thermometer-line)"
  },
  "assets.icons.ink-bottle-fill": {
    "value": "/icons/remix-icon.svg#ink-bottle-fill",
    "variable": "var(--assets-icons-ink-bottle-fill)"
  },
  "assets.icons.ink-bottle-line": {
    "value": "/icons/remix-icon.svg#ink-bottle-line",
    "variable": "var(--assets-icons-ink-bottle-line)"
  },
  "assets.icons.input-cursor-move": {
    "value": "/icons/remix-icon.svg#input-cursor-move",
    "variable": "var(--assets-icons-input-cursor-move)"
  },
  "assets.icons.input-method-fill": {
    "value": "/icons/remix-icon.svg#input-method-fill",
    "variable": "var(--assets-icons-input-method-fill)"
  },
  "assets.icons.input-method-line": {
    "value": "/icons/remix-icon.svg#input-method-line",
    "variable": "var(--assets-icons-input-method-line)"
  },
  "assets.icons.insert-column-left": {
    "value": "/icons/remix-icon.svg#insert-column-left",
    "variable": "var(--assets-icons-insert-column-left)"
  },
  "assets.icons.insert-column-right": {
    "value": "/icons/remix-icon.svg#insert-column-right",
    "variable": "var(--assets-icons-insert-column-right)"
  },
  "assets.icons.insert-row-bottom": {
    "value": "/icons/remix-icon.svg#insert-row-bottom",
    "variable": "var(--assets-icons-insert-row-bottom)"
  },
  "assets.icons.insert-row-top": {
    "value": "/icons/remix-icon.svg#insert-row-top",
    "variable": "var(--assets-icons-insert-row-top)"
  },
  "assets.icons.instagram-fill": {
    "value": "/icons/remix-icon.svg#instagram-fill",
    "variable": "var(--assets-icons-instagram-fill)"
  },
  "assets.icons.instagram-line": {
    "value": "/icons/remix-icon.svg#instagram-line",
    "variable": "var(--assets-icons-instagram-line)"
  },
  "assets.icons.install-fill": {
    "value": "/icons/remix-icon.svg#install-fill",
    "variable": "var(--assets-icons-install-fill)"
  },
  "assets.icons.install-line": {
    "value": "/icons/remix-icon.svg#install-line",
    "variable": "var(--assets-icons-install-line)"
  },
  "assets.icons.invision-fill": {
    "value": "/icons/remix-icon.svg#invision-fill",
    "variable": "var(--assets-icons-invision-fill)"
  },
  "assets.icons.invision-line": {
    "value": "/icons/remix-icon.svg#invision-line",
    "variable": "var(--assets-icons-invision-line)"
  },
  "assets.icons.italic": {
    "value": "/icons/remix-icon.svg#italic",
    "variable": "var(--assets-icons-italic)"
  },
  "assets.icons.kakao-talk-fill": {
    "value": "/icons/remix-icon.svg#kakao-talk-fill",
    "variable": "var(--assets-icons-kakao-talk-fill)"
  },
  "assets.icons.kakao-talk-line": {
    "value": "/icons/remix-icon.svg#kakao-talk-line",
    "variable": "var(--assets-icons-kakao-talk-line)"
  },
  "assets.icons.key-2-fill": {
    "value": "/icons/remix-icon.svg#key-2-fill",
    "variable": "var(--assets-icons-key-2-fill)"
  },
  "assets.icons.key-2-line": {
    "value": "/icons/remix-icon.svg#key-2-line",
    "variable": "var(--assets-icons-key-2-line)"
  },
  "assets.icons.key-fill": {
    "value": "/icons/remix-icon.svg#key-fill",
    "variable": "var(--assets-icons-key-fill)"
  },
  "assets.icons.key-line": {
    "value": "/icons/remix-icon.svg#key-line",
    "variable": "var(--assets-icons-key-line)"
  },
  "assets.icons.keyboard-box-fill": {
    "value": "/icons/remix-icon.svg#keyboard-box-fill",
    "variable": "var(--assets-icons-keyboard-box-fill)"
  },
  "assets.icons.keyboard-box-line": {
    "value": "/icons/remix-icon.svg#keyboard-box-line",
    "variable": "var(--assets-icons-keyboard-box-line)"
  },
  "assets.icons.keyboard-fill": {
    "value": "/icons/remix-icon.svg#keyboard-fill",
    "variable": "var(--assets-icons-keyboard-fill)"
  },
  "assets.icons.keyboard-line": {
    "value": "/icons/remix-icon.svg#keyboard-line",
    "variable": "var(--assets-icons-keyboard-line)"
  },
  "assets.icons.keynote-fill": {
    "value": "/icons/remix-icon.svg#keynote-fill",
    "variable": "var(--assets-icons-keynote-fill)"
  },
  "assets.icons.keynote-line": {
    "value": "/icons/remix-icon.svg#keynote-line",
    "variable": "var(--assets-icons-keynote-line)"
  },
  "assets.icons.knife-blood-fill": {
    "value": "/icons/remix-icon.svg#knife-blood-fill",
    "variable": "var(--assets-icons-knife-blood-fill)"
  },
  "assets.icons.knife-blood-line": {
    "value": "/icons/remix-icon.svg#knife-blood-line",
    "variable": "var(--assets-icons-knife-blood-line)"
  },
  "assets.icons.knife-fill": {
    "value": "/icons/remix-icon.svg#knife-fill",
    "variable": "var(--assets-icons-knife-fill)"
  },
  "assets.icons.knife-line": {
    "value": "/icons/remix-icon.svg#knife-line",
    "variable": "var(--assets-icons-knife-line)"
  },
  "assets.icons.landscape-fill": {
    "value": "/icons/remix-icon.svg#landscape-fill",
    "variable": "var(--assets-icons-landscape-fill)"
  },
  "assets.icons.landscape-line": {
    "value": "/icons/remix-icon.svg#landscape-line",
    "variable": "var(--assets-icons-landscape-line)"
  },
  "assets.icons.layout-2-fill": {
    "value": "/icons/remix-icon.svg#layout-2-fill",
    "variable": "var(--assets-icons-layout-2-fill)"
  },
  "assets.icons.layout-2-line": {
    "value": "/icons/remix-icon.svg#layout-2-line",
    "variable": "var(--assets-icons-layout-2-line)"
  },
  "assets.icons.layout-3-fill": {
    "value": "/icons/remix-icon.svg#layout-3-fill",
    "variable": "var(--assets-icons-layout-3-fill)"
  },
  "assets.icons.layout-3-line": {
    "value": "/icons/remix-icon.svg#layout-3-line",
    "variable": "var(--assets-icons-layout-3-line)"
  },
  "assets.icons.layout-4-fill": {
    "value": "/icons/remix-icon.svg#layout-4-fill",
    "variable": "var(--assets-icons-layout-4-fill)"
  },
  "assets.icons.layout-4-line": {
    "value": "/icons/remix-icon.svg#layout-4-line",
    "variable": "var(--assets-icons-layout-4-line)"
  },
  "assets.icons.layout-5-fill": {
    "value": "/icons/remix-icon.svg#layout-5-fill",
    "variable": "var(--assets-icons-layout-5-fill)"
  },
  "assets.icons.layout-5-line": {
    "value": "/icons/remix-icon.svg#layout-5-line",
    "variable": "var(--assets-icons-layout-5-line)"
  },
  "assets.icons.layout-6-fill": {
    "value": "/icons/remix-icon.svg#layout-6-fill",
    "variable": "var(--assets-icons-layout-6-fill)"
  },
  "assets.icons.layout-6-line": {
    "value": "/icons/remix-icon.svg#layout-6-line",
    "variable": "var(--assets-icons-layout-6-line)"
  },
  "assets.icons.layout-bottom-2-fill": {
    "value": "/icons/remix-icon.svg#layout-bottom-2-fill",
    "variable": "var(--assets-icons-layout-bottom-2-fill)"
  },
  "assets.icons.layout-bottom-2-line": {
    "value": "/icons/remix-icon.svg#layout-bottom-2-line",
    "variable": "var(--assets-icons-layout-bottom-2-line)"
  },
  "assets.icons.layout-bottom-fill": {
    "value": "/icons/remix-icon.svg#layout-bottom-fill",
    "variable": "var(--assets-icons-layout-bottom-fill)"
  },
  "assets.icons.layout-bottom-line": {
    "value": "/icons/remix-icon.svg#layout-bottom-line",
    "variable": "var(--assets-icons-layout-bottom-line)"
  },
  "assets.icons.layout-column-fill": {
    "value": "/icons/remix-icon.svg#layout-column-fill",
    "variable": "var(--assets-icons-layout-column-fill)"
  },
  "assets.icons.layout-column-line": {
    "value": "/icons/remix-icon.svg#layout-column-line",
    "variable": "var(--assets-icons-layout-column-line)"
  },
  "assets.icons.layout-fill": {
    "value": "/icons/remix-icon.svg#layout-fill",
    "variable": "var(--assets-icons-layout-fill)"
  },
  "assets.icons.layout-grid-fill": {
    "value": "/icons/remix-icon.svg#layout-grid-fill",
    "variable": "var(--assets-icons-layout-grid-fill)"
  },
  "assets.icons.layout-grid-line": {
    "value": "/icons/remix-icon.svg#layout-grid-line",
    "variable": "var(--assets-icons-layout-grid-line)"
  },
  "assets.icons.layout-left-2-fill": {
    "value": "/icons/remix-icon.svg#layout-left-2-fill",
    "variable": "var(--assets-icons-layout-left-2-fill)"
  },
  "assets.icons.layout-left-2-line": {
    "value": "/icons/remix-icon.svg#layout-left-2-line",
    "variable": "var(--assets-icons-layout-left-2-line)"
  },
  "assets.icons.layout-left-fill": {
    "value": "/icons/remix-icon.svg#layout-left-fill",
    "variable": "var(--assets-icons-layout-left-fill)"
  },
  "assets.icons.layout-left-line": {
    "value": "/icons/remix-icon.svg#layout-left-line",
    "variable": "var(--assets-icons-layout-left-line)"
  },
  "assets.icons.layout-line": {
    "value": "/icons/remix-icon.svg#layout-line",
    "variable": "var(--assets-icons-layout-line)"
  },
  "assets.icons.layout-masonry-fill": {
    "value": "/icons/remix-icon.svg#layout-masonry-fill",
    "variable": "var(--assets-icons-layout-masonry-fill)"
  },
  "assets.icons.layout-masonry-line": {
    "value": "/icons/remix-icon.svg#layout-masonry-line",
    "variable": "var(--assets-icons-layout-masonry-line)"
  },
  "assets.icons.layout-right-2-fill": {
    "value": "/icons/remix-icon.svg#layout-right-2-fill",
    "variable": "var(--assets-icons-layout-right-2-fill)"
  },
  "assets.icons.layout-right-2-line": {
    "value": "/icons/remix-icon.svg#layout-right-2-line",
    "variable": "var(--assets-icons-layout-right-2-line)"
  },
  "assets.icons.layout-right-fill": {
    "value": "/icons/remix-icon.svg#layout-right-fill",
    "variable": "var(--assets-icons-layout-right-fill)"
  },
  "assets.icons.layout-right-line": {
    "value": "/icons/remix-icon.svg#layout-right-line",
    "variable": "var(--assets-icons-layout-right-line)"
  },
  "assets.icons.layout-row-fill": {
    "value": "/icons/remix-icon.svg#layout-row-fill",
    "variable": "var(--assets-icons-layout-row-fill)"
  },
  "assets.icons.layout-row-line": {
    "value": "/icons/remix-icon.svg#layout-row-line",
    "variable": "var(--assets-icons-layout-row-line)"
  },
  "assets.icons.layout-top-2-fill": {
    "value": "/icons/remix-icon.svg#layout-top-2-fill",
    "variable": "var(--assets-icons-layout-top-2-fill)"
  },
  "assets.icons.layout-top-2-line": {
    "value": "/icons/remix-icon.svg#layout-top-2-line",
    "variable": "var(--assets-icons-layout-top-2-line)"
  },
  "assets.icons.layout-top-fill": {
    "value": "/icons/remix-icon.svg#layout-top-fill",
    "variable": "var(--assets-icons-layout-top-fill)"
  },
  "assets.icons.layout-top-line": {
    "value": "/icons/remix-icon.svg#layout-top-line",
    "variable": "var(--assets-icons-layout-top-line)"
  },
  "assets.icons.leaf-fill": {
    "value": "/icons/remix-icon.svg#leaf-fill",
    "variable": "var(--assets-icons-leaf-fill)"
  },
  "assets.icons.leaf-line": {
    "value": "/icons/remix-icon.svg#leaf-line",
    "variable": "var(--assets-icons-leaf-line)"
  },
  "assets.icons.lifebuoy-fill": {
    "value": "/icons/remix-icon.svg#lifebuoy-fill",
    "variable": "var(--assets-icons-lifebuoy-fill)"
  },
  "assets.icons.lifebuoy-line": {
    "value": "/icons/remix-icon.svg#lifebuoy-line",
    "variable": "var(--assets-icons-lifebuoy-line)"
  },
  "assets.icons.lightbulb-fill": {
    "value": "/icons/remix-icon.svg#lightbulb-fill",
    "variable": "var(--assets-icons-lightbulb-fill)"
  },
  "assets.icons.lightbulb-flash-fill": {
    "value": "/icons/remix-icon.svg#lightbulb-flash-fill",
    "variable": "var(--assets-icons-lightbulb-flash-fill)"
  },
  "assets.icons.lightbulb-flash-line": {
    "value": "/icons/remix-icon.svg#lightbulb-flash-line",
    "variable": "var(--assets-icons-lightbulb-flash-line)"
  },
  "assets.icons.lightbulb-line": {
    "value": "/icons/remix-icon.svg#lightbulb-line",
    "variable": "var(--assets-icons-lightbulb-line)"
  },
  "assets.icons.line-chart-fill": {
    "value": "/icons/remix-icon.svg#line-chart-fill",
    "variable": "var(--assets-icons-line-chart-fill)"
  },
  "assets.icons.line-chart-line": {
    "value": "/icons/remix-icon.svg#line-chart-line",
    "variable": "var(--assets-icons-line-chart-line)"
  },
  "assets.icons.line-fill": {
    "value": "/icons/remix-icon.svg#line-fill",
    "variable": "var(--assets-icons-line-fill)"
  },
  "assets.icons.line-height": {
    "value": "/icons/remix-icon.svg#line-height",
    "variable": "var(--assets-icons-line-height)"
  },
  "assets.icons.line-line": {
    "value": "/icons/remix-icon.svg#line-line",
    "variable": "var(--assets-icons-line-line)"
  },
  "assets.icons.link-m": {
    "value": "/icons/remix-icon.svg#link-m",
    "variable": "var(--assets-icons-link-m)"
  },
  "assets.icons.link-unlink-m": {
    "value": "/icons/remix-icon.svg#link-unlink-m",
    "variable": "var(--assets-icons-link-unlink-m)"
  },
  "assets.icons.link-unlink": {
    "value": "/icons/remix-icon.svg#link-unlink",
    "variable": "var(--assets-icons-link-unlink)"
  },
  "assets.icons.link": {
    "value": "/icons/remix-icon.svg#link",
    "variable": "var(--assets-icons-link)"
  },
  "assets.icons.linkedin-box-fill": {
    "value": "/icons/remix-icon.svg#linkedin-box-fill",
    "variable": "var(--assets-icons-linkedin-box-fill)"
  },
  "assets.icons.linkedin-box-line": {
    "value": "/icons/remix-icon.svg#linkedin-box-line",
    "variable": "var(--assets-icons-linkedin-box-line)"
  },
  "assets.icons.linkedin-fill": {
    "value": "/icons/remix-icon.svg#linkedin-fill",
    "variable": "var(--assets-icons-linkedin-fill)"
  },
  "assets.icons.linkedin-line": {
    "value": "/icons/remix-icon.svg#linkedin-line",
    "variable": "var(--assets-icons-linkedin-line)"
  },
  "assets.icons.links-fill": {
    "value": "/icons/remix-icon.svg#links-fill",
    "variable": "var(--assets-icons-links-fill)"
  },
  "assets.icons.links-line": {
    "value": "/icons/remix-icon.svg#links-line",
    "variable": "var(--assets-icons-links-line)"
  },
  "assets.icons.list-check-2": {
    "value": "/icons/remix-icon.svg#list-check-2",
    "variable": "var(--assets-icons-list-check-2)"
  },
  "assets.icons.list-check": {
    "value": "/icons/remix-icon.svg#list-check",
    "variable": "var(--assets-icons-list-check)"
  },
  "assets.icons.list-ordered": {
    "value": "/icons/remix-icon.svg#list-ordered",
    "variable": "var(--assets-icons-list-ordered)"
  },
  "assets.icons.list-settings-fill": {
    "value": "/icons/remix-icon.svg#list-settings-fill",
    "variable": "var(--assets-icons-list-settings-fill)"
  },
  "assets.icons.list-settings-line": {
    "value": "/icons/remix-icon.svg#list-settings-line",
    "variable": "var(--assets-icons-list-settings-line)"
  },
  "assets.icons.list-unordered": {
    "value": "/icons/remix-icon.svg#list-unordered",
    "variable": "var(--assets-icons-list-unordered)"
  },
  "assets.icons.live-fill": {
    "value": "/icons/remix-icon.svg#live-fill",
    "variable": "var(--assets-icons-live-fill)"
  },
  "assets.icons.live-line": {
    "value": "/icons/remix-icon.svg#live-line",
    "variable": "var(--assets-icons-live-line)"
  },
  "assets.icons.loader-2-fill": {
    "value": "/icons/remix-icon.svg#loader-2-fill",
    "variable": "var(--assets-icons-loader-2-fill)"
  },
  "assets.icons.loader-2-line": {
    "value": "/icons/remix-icon.svg#loader-2-line",
    "variable": "var(--assets-icons-loader-2-line)"
  },
  "assets.icons.loader-3-fill": {
    "value": "/icons/remix-icon.svg#loader-3-fill",
    "variable": "var(--assets-icons-loader-3-fill)"
  },
  "assets.icons.loader-3-line": {
    "value": "/icons/remix-icon.svg#loader-3-line",
    "variable": "var(--assets-icons-loader-3-line)"
  },
  "assets.icons.loader-4-fill": {
    "value": "/icons/remix-icon.svg#loader-4-fill",
    "variable": "var(--assets-icons-loader-4-fill)"
  },
  "assets.icons.loader-4-line": {
    "value": "/icons/remix-icon.svg#loader-4-line",
    "variable": "var(--assets-icons-loader-4-line)"
  },
  "assets.icons.loader-5-fill": {
    "value": "/icons/remix-icon.svg#loader-5-fill",
    "variable": "var(--assets-icons-loader-5-fill)"
  },
  "assets.icons.loader-5-line": {
    "value": "/icons/remix-icon.svg#loader-5-line",
    "variable": "var(--assets-icons-loader-5-line)"
  },
  "assets.icons.loader-fill": {
    "value": "/icons/remix-icon.svg#loader-fill",
    "variable": "var(--assets-icons-loader-fill)"
  },
  "assets.icons.loader-line": {
    "value": "/icons/remix-icon.svg#loader-line",
    "variable": "var(--assets-icons-loader-line)"
  },
  "assets.icons.lock-2-fill": {
    "value": "/icons/remix-icon.svg#lock-2-fill",
    "variable": "var(--assets-icons-lock-2-fill)"
  },
  "assets.icons.lock-2-line": {
    "value": "/icons/remix-icon.svg#lock-2-line",
    "variable": "var(--assets-icons-lock-2-line)"
  },
  "assets.icons.lock-fill": {
    "value": "/icons/remix-icon.svg#lock-fill",
    "variable": "var(--assets-icons-lock-fill)"
  },
  "assets.icons.lock-line": {
    "value": "/icons/remix-icon.svg#lock-line",
    "variable": "var(--assets-icons-lock-line)"
  },
  "assets.icons.lock-password-fill": {
    "value": "/icons/remix-icon.svg#lock-password-fill",
    "variable": "var(--assets-icons-lock-password-fill)"
  },
  "assets.icons.lock-password-line": {
    "value": "/icons/remix-icon.svg#lock-password-line",
    "variable": "var(--assets-icons-lock-password-line)"
  },
  "assets.icons.lock-unlock-fill": {
    "value": "/icons/remix-icon.svg#lock-unlock-fill",
    "variable": "var(--assets-icons-lock-unlock-fill)"
  },
  "assets.icons.lock-unlock-line": {
    "value": "/icons/remix-icon.svg#lock-unlock-line",
    "variable": "var(--assets-icons-lock-unlock-line)"
  },
  "assets.icons.login-box-fill": {
    "value": "/icons/remix-icon.svg#login-box-fill",
    "variable": "var(--assets-icons-login-box-fill)"
  },
  "assets.icons.login-box-line": {
    "value": "/icons/remix-icon.svg#login-box-line",
    "variable": "var(--assets-icons-login-box-line)"
  },
  "assets.icons.login-circle-fill": {
    "value": "/icons/remix-icon.svg#login-circle-fill",
    "variable": "var(--assets-icons-login-circle-fill)"
  },
  "assets.icons.login-circle-line": {
    "value": "/icons/remix-icon.svg#login-circle-line",
    "variable": "var(--assets-icons-login-circle-line)"
  },
  "assets.icons.logout-box-fill": {
    "value": "/icons/remix-icon.svg#logout-box-fill",
    "variable": "var(--assets-icons-logout-box-fill)"
  },
  "assets.icons.logout-box-line": {
    "value": "/icons/remix-icon.svg#logout-box-line",
    "variable": "var(--assets-icons-logout-box-line)"
  },
  "assets.icons.logout-box-r-fill": {
    "value": "/icons/remix-icon.svg#logout-box-r-fill",
    "variable": "var(--assets-icons-logout-box-r-fill)"
  },
  "assets.icons.logout-box-r-line": {
    "value": "/icons/remix-icon.svg#logout-box-r-line",
    "variable": "var(--assets-icons-logout-box-r-line)"
  },
  "assets.icons.logout-circle-fill": {
    "value": "/icons/remix-icon.svg#logout-circle-fill",
    "variable": "var(--assets-icons-logout-circle-fill)"
  },
  "assets.icons.logout-circle-line": {
    "value": "/icons/remix-icon.svg#logout-circle-line",
    "variable": "var(--assets-icons-logout-circle-line)"
  },
  "assets.icons.logout-circle-r-fill": {
    "value": "/icons/remix-icon.svg#logout-circle-r-fill",
    "variable": "var(--assets-icons-logout-circle-r-fill)"
  },
  "assets.icons.logout-circle-r-line": {
    "value": "/icons/remix-icon.svg#logout-circle-r-line",
    "variable": "var(--assets-icons-logout-circle-r-line)"
  },
  "assets.icons.luggage-cart-fill": {
    "value": "/icons/remix-icon.svg#luggage-cart-fill",
    "variable": "var(--assets-icons-luggage-cart-fill)"
  },
  "assets.icons.luggage-cart-line": {
    "value": "/icons/remix-icon.svg#luggage-cart-line",
    "variable": "var(--assets-icons-luggage-cart-line)"
  },
  "assets.icons.luggage-deposit-fill": {
    "value": "/icons/remix-icon.svg#luggage-deposit-fill",
    "variable": "var(--assets-icons-luggage-deposit-fill)"
  },
  "assets.icons.luggage-deposit-line": {
    "value": "/icons/remix-icon.svg#luggage-deposit-line",
    "variable": "var(--assets-icons-luggage-deposit-line)"
  },
  "assets.icons.lungs-fill": {
    "value": "/icons/remix-icon.svg#lungs-fill",
    "variable": "var(--assets-icons-lungs-fill)"
  },
  "assets.icons.lungs-line": {
    "value": "/icons/remix-icon.svg#lungs-line",
    "variable": "var(--assets-icons-lungs-line)"
  },
  "assets.icons.mac-fill": {
    "value": "/icons/remix-icon.svg#mac-fill",
    "variable": "var(--assets-icons-mac-fill)"
  },
  "assets.icons.mac-line": {
    "value": "/icons/remix-icon.svg#mac-line",
    "variable": "var(--assets-icons-mac-line)"
  },
  "assets.icons.macbook-fill": {
    "value": "/icons/remix-icon.svg#macbook-fill",
    "variable": "var(--assets-icons-macbook-fill)"
  },
  "assets.icons.macbook-line": {
    "value": "/icons/remix-icon.svg#macbook-line",
    "variable": "var(--assets-icons-macbook-line)"
  },
  "assets.icons.magic-fill": {
    "value": "/icons/remix-icon.svg#magic-fill",
    "variable": "var(--assets-icons-magic-fill)"
  },
  "assets.icons.magic-line": {
    "value": "/icons/remix-icon.svg#magic-line",
    "variable": "var(--assets-icons-magic-line)"
  },
  "assets.icons.mail-add-fill": {
    "value": "/icons/remix-icon.svg#mail-add-fill",
    "variable": "var(--assets-icons-mail-add-fill)"
  },
  "assets.icons.mail-add-line": {
    "value": "/icons/remix-icon.svg#mail-add-line",
    "variable": "var(--assets-icons-mail-add-line)"
  },
  "assets.icons.mail-check-fill": {
    "value": "/icons/remix-icon.svg#mail-check-fill",
    "variable": "var(--assets-icons-mail-check-fill)"
  },
  "assets.icons.mail-check-line": {
    "value": "/icons/remix-icon.svg#mail-check-line",
    "variable": "var(--assets-icons-mail-check-line)"
  },
  "assets.icons.mail-close-fill": {
    "value": "/icons/remix-icon.svg#mail-close-fill",
    "variable": "var(--assets-icons-mail-close-fill)"
  },
  "assets.icons.mail-close-line": {
    "value": "/icons/remix-icon.svg#mail-close-line",
    "variable": "var(--assets-icons-mail-close-line)"
  },
  "assets.icons.mail-download-fill": {
    "value": "/icons/remix-icon.svg#mail-download-fill",
    "variable": "var(--assets-icons-mail-download-fill)"
  },
  "assets.icons.mail-download-line": {
    "value": "/icons/remix-icon.svg#mail-download-line",
    "variable": "var(--assets-icons-mail-download-line)"
  },
  "assets.icons.mail-fill": {
    "value": "/icons/remix-icon.svg#mail-fill",
    "variable": "var(--assets-icons-mail-fill)"
  },
  "assets.icons.mail-forbid-fill": {
    "value": "/icons/remix-icon.svg#mail-forbid-fill",
    "variable": "var(--assets-icons-mail-forbid-fill)"
  },
  "assets.icons.mail-forbid-line": {
    "value": "/icons/remix-icon.svg#mail-forbid-line",
    "variable": "var(--assets-icons-mail-forbid-line)"
  },
  "assets.icons.mail-line": {
    "value": "/icons/remix-icon.svg#mail-line",
    "variable": "var(--assets-icons-mail-line)"
  },
  "assets.icons.mail-lock-fill": {
    "value": "/icons/remix-icon.svg#mail-lock-fill",
    "variable": "var(--assets-icons-mail-lock-fill)"
  },
  "assets.icons.mail-lock-line": {
    "value": "/icons/remix-icon.svg#mail-lock-line",
    "variable": "var(--assets-icons-mail-lock-line)"
  },
  "assets.icons.mail-open-fill": {
    "value": "/icons/remix-icon.svg#mail-open-fill",
    "variable": "var(--assets-icons-mail-open-fill)"
  },
  "assets.icons.mail-open-line": {
    "value": "/icons/remix-icon.svg#mail-open-line",
    "variable": "var(--assets-icons-mail-open-line)"
  },
  "assets.icons.mail-send-fill": {
    "value": "/icons/remix-icon.svg#mail-send-fill",
    "variable": "var(--assets-icons-mail-send-fill)"
  },
  "assets.icons.mail-send-line": {
    "value": "/icons/remix-icon.svg#mail-send-line",
    "variable": "var(--assets-icons-mail-send-line)"
  },
  "assets.icons.mail-settings-fill": {
    "value": "/icons/remix-icon.svg#mail-settings-fill",
    "variable": "var(--assets-icons-mail-settings-fill)"
  },
  "assets.icons.mail-settings-line": {
    "value": "/icons/remix-icon.svg#mail-settings-line",
    "variable": "var(--assets-icons-mail-settings-line)"
  },
  "assets.icons.mail-star-fill": {
    "value": "/icons/remix-icon.svg#mail-star-fill",
    "variable": "var(--assets-icons-mail-star-fill)"
  },
  "assets.icons.mail-star-line": {
    "value": "/icons/remix-icon.svg#mail-star-line",
    "variable": "var(--assets-icons-mail-star-line)"
  },
  "assets.icons.mail-unread-fill": {
    "value": "/icons/remix-icon.svg#mail-unread-fill",
    "variable": "var(--assets-icons-mail-unread-fill)"
  },
  "assets.icons.mail-unread-line": {
    "value": "/icons/remix-icon.svg#mail-unread-line",
    "variable": "var(--assets-icons-mail-unread-line)"
  },
  "assets.icons.mail-volume-fill": {
    "value": "/icons/remix-icon.svg#mail-volume-fill",
    "variable": "var(--assets-icons-mail-volume-fill)"
  },
  "assets.icons.mail-volume-line": {
    "value": "/icons/remix-icon.svg#mail-volume-line",
    "variable": "var(--assets-icons-mail-volume-line)"
  },
  "assets.icons.map-2-fill": {
    "value": "/icons/remix-icon.svg#map-2-fill",
    "variable": "var(--assets-icons-map-2-fill)"
  },
  "assets.icons.map-2-line": {
    "value": "/icons/remix-icon.svg#map-2-line",
    "variable": "var(--assets-icons-map-2-line)"
  },
  "assets.icons.map-fill": {
    "value": "/icons/remix-icon.svg#map-fill",
    "variable": "var(--assets-icons-map-fill)"
  },
  "assets.icons.map-line": {
    "value": "/icons/remix-icon.svg#map-line",
    "variable": "var(--assets-icons-map-line)"
  },
  "assets.icons.map-pin-2-fill": {
    "value": "/icons/remix-icon.svg#map-pin-2-fill",
    "variable": "var(--assets-icons-map-pin-2-fill)"
  },
  "assets.icons.map-pin-2-line": {
    "value": "/icons/remix-icon.svg#map-pin-2-line",
    "variable": "var(--assets-icons-map-pin-2-line)"
  },
  "assets.icons.map-pin-3-fill": {
    "value": "/icons/remix-icon.svg#map-pin-3-fill",
    "variable": "var(--assets-icons-map-pin-3-fill)"
  },
  "assets.icons.map-pin-3-line": {
    "value": "/icons/remix-icon.svg#map-pin-3-line",
    "variable": "var(--assets-icons-map-pin-3-line)"
  },
  "assets.icons.map-pin-4-fill": {
    "value": "/icons/remix-icon.svg#map-pin-4-fill",
    "variable": "var(--assets-icons-map-pin-4-fill)"
  },
  "assets.icons.map-pin-4-line": {
    "value": "/icons/remix-icon.svg#map-pin-4-line",
    "variable": "var(--assets-icons-map-pin-4-line)"
  },
  "assets.icons.map-pin-5-fill": {
    "value": "/icons/remix-icon.svg#map-pin-5-fill",
    "variable": "var(--assets-icons-map-pin-5-fill)"
  },
  "assets.icons.map-pin-5-line": {
    "value": "/icons/remix-icon.svg#map-pin-5-line",
    "variable": "var(--assets-icons-map-pin-5-line)"
  },
  "assets.icons.map-pin-add-fill": {
    "value": "/icons/remix-icon.svg#map-pin-add-fill",
    "variable": "var(--assets-icons-map-pin-add-fill)"
  },
  "assets.icons.map-pin-add-line": {
    "value": "/icons/remix-icon.svg#map-pin-add-line",
    "variable": "var(--assets-icons-map-pin-add-line)"
  },
  "assets.icons.map-pin-fill": {
    "value": "/icons/remix-icon.svg#map-pin-fill",
    "variable": "var(--assets-icons-map-pin-fill)"
  },
  "assets.icons.map-pin-line": {
    "value": "/icons/remix-icon.svg#map-pin-line",
    "variable": "var(--assets-icons-map-pin-line)"
  },
  "assets.icons.map-pin-range-fill": {
    "value": "/icons/remix-icon.svg#map-pin-range-fill",
    "variable": "var(--assets-icons-map-pin-range-fill)"
  },
  "assets.icons.map-pin-range-line": {
    "value": "/icons/remix-icon.svg#map-pin-range-line",
    "variable": "var(--assets-icons-map-pin-range-line)"
  },
  "assets.icons.map-pin-time-fill": {
    "value": "/icons/remix-icon.svg#map-pin-time-fill",
    "variable": "var(--assets-icons-map-pin-time-fill)"
  },
  "assets.icons.map-pin-time-line": {
    "value": "/icons/remix-icon.svg#map-pin-time-line",
    "variable": "var(--assets-icons-map-pin-time-line)"
  },
  "assets.icons.map-pin-user-fill": {
    "value": "/icons/remix-icon.svg#map-pin-user-fill",
    "variable": "var(--assets-icons-map-pin-user-fill)"
  },
  "assets.icons.map-pin-user-line": {
    "value": "/icons/remix-icon.svg#map-pin-user-line",
    "variable": "var(--assets-icons-map-pin-user-line)"
  },
  "assets.icons.mark-pen-fill": {
    "value": "/icons/remix-icon.svg#mark-pen-fill",
    "variable": "var(--assets-icons-mark-pen-fill)"
  },
  "assets.icons.mark-pen-line": {
    "value": "/icons/remix-icon.svg#mark-pen-line",
    "variable": "var(--assets-icons-mark-pen-line)"
  },
  "assets.icons.markdown-fill": {
    "value": "/icons/remix-icon.svg#markdown-fill",
    "variable": "var(--assets-icons-markdown-fill)"
  },
  "assets.icons.markdown-line": {
    "value": "/icons/remix-icon.svg#markdown-line",
    "variable": "var(--assets-icons-markdown-line)"
  },
  "assets.icons.markup-fill": {
    "value": "/icons/remix-icon.svg#markup-fill",
    "variable": "var(--assets-icons-markup-fill)"
  },
  "assets.icons.markup-line": {
    "value": "/icons/remix-icon.svg#markup-line",
    "variable": "var(--assets-icons-markup-line)"
  },
  "assets.icons.mastercard-fill": {
    "value": "/icons/remix-icon.svg#mastercard-fill",
    "variable": "var(--assets-icons-mastercard-fill)"
  },
  "assets.icons.mastercard-line": {
    "value": "/icons/remix-icon.svg#mastercard-line",
    "variable": "var(--assets-icons-mastercard-line)"
  },
  "assets.icons.mastodon-fill": {
    "value": "/icons/remix-icon.svg#mastodon-fill",
    "variable": "var(--assets-icons-mastodon-fill)"
  },
  "assets.icons.mastodon-line": {
    "value": "/icons/remix-icon.svg#mastodon-line",
    "variable": "var(--assets-icons-mastodon-line)"
  },
  "assets.icons.medal-2-fill": {
    "value": "/icons/remix-icon.svg#medal-2-fill",
    "variable": "var(--assets-icons-medal-2-fill)"
  },
  "assets.icons.medal-2-line": {
    "value": "/icons/remix-icon.svg#medal-2-line",
    "variable": "var(--assets-icons-medal-2-line)"
  },
  "assets.icons.medal-fill": {
    "value": "/icons/remix-icon.svg#medal-fill",
    "variable": "var(--assets-icons-medal-fill)"
  },
  "assets.icons.medal-line": {
    "value": "/icons/remix-icon.svg#medal-line",
    "variable": "var(--assets-icons-medal-line)"
  },
  "assets.icons.medicine-bottle-fill": {
    "value": "/icons/remix-icon.svg#medicine-bottle-fill",
    "variable": "var(--assets-icons-medicine-bottle-fill)"
  },
  "assets.icons.medicine-bottle-line": {
    "value": "/icons/remix-icon.svg#medicine-bottle-line",
    "variable": "var(--assets-icons-medicine-bottle-line)"
  },
  "assets.icons.medium-fill": {
    "value": "/icons/remix-icon.svg#medium-fill",
    "variable": "var(--assets-icons-medium-fill)"
  },
  "assets.icons.medium-line": {
    "value": "/icons/remix-icon.svg#medium-line",
    "variable": "var(--assets-icons-medium-line)"
  },
  "assets.icons.men-fill": {
    "value": "/icons/remix-icon.svg#men-fill",
    "variable": "var(--assets-icons-men-fill)"
  },
  "assets.icons.men-line": {
    "value": "/icons/remix-icon.svg#men-line",
    "variable": "var(--assets-icons-men-line)"
  },
  "assets.icons.mental-health-fill": {
    "value": "/icons/remix-icon.svg#mental-health-fill",
    "variable": "var(--assets-icons-mental-health-fill)"
  },
  "assets.icons.mental-health-line": {
    "value": "/icons/remix-icon.svg#mental-health-line",
    "variable": "var(--assets-icons-mental-health-line)"
  },
  "assets.icons.menu-2-fill": {
    "value": "/icons/remix-icon.svg#menu-2-fill",
    "variable": "var(--assets-icons-menu-2-fill)"
  },
  "assets.icons.menu-2-line": {
    "value": "/icons/remix-icon.svg#menu-2-line",
    "variable": "var(--assets-icons-menu-2-line)"
  },
  "assets.icons.menu-3-fill": {
    "value": "/icons/remix-icon.svg#menu-3-fill",
    "variable": "var(--assets-icons-menu-3-fill)"
  },
  "assets.icons.menu-3-line": {
    "value": "/icons/remix-icon.svg#menu-3-line",
    "variable": "var(--assets-icons-menu-3-line)"
  },
  "assets.icons.menu-4-fill": {
    "value": "/icons/remix-icon.svg#menu-4-fill",
    "variable": "var(--assets-icons-menu-4-fill)"
  },
  "assets.icons.menu-4-line": {
    "value": "/icons/remix-icon.svg#menu-4-line",
    "variable": "var(--assets-icons-menu-4-line)"
  },
  "assets.icons.menu-5-fill": {
    "value": "/icons/remix-icon.svg#menu-5-fill",
    "variable": "var(--assets-icons-menu-5-fill)"
  },
  "assets.icons.menu-5-line": {
    "value": "/icons/remix-icon.svg#menu-5-line",
    "variable": "var(--assets-icons-menu-5-line)"
  },
  "assets.icons.menu-add-fill": {
    "value": "/icons/remix-icon.svg#menu-add-fill",
    "variable": "var(--assets-icons-menu-add-fill)"
  },
  "assets.icons.menu-add-line": {
    "value": "/icons/remix-icon.svg#menu-add-line",
    "variable": "var(--assets-icons-menu-add-line)"
  },
  "assets.icons.menu-fill": {
    "value": "/icons/remix-icon.svg#menu-fill",
    "variable": "var(--assets-icons-menu-fill)"
  },
  "assets.icons.menu-fold-fill": {
    "value": "/icons/remix-icon.svg#menu-fold-fill",
    "variable": "var(--assets-icons-menu-fold-fill)"
  },
  "assets.icons.menu-fold-line": {
    "value": "/icons/remix-icon.svg#menu-fold-line",
    "variable": "var(--assets-icons-menu-fold-line)"
  },
  "assets.icons.menu-line": {
    "value": "/icons/remix-icon.svg#menu-line",
    "variable": "var(--assets-icons-menu-line)"
  },
  "assets.icons.menu-unfold-fill": {
    "value": "/icons/remix-icon.svg#menu-unfold-fill",
    "variable": "var(--assets-icons-menu-unfold-fill)"
  },
  "assets.icons.menu-unfold-line": {
    "value": "/icons/remix-icon.svg#menu-unfold-line",
    "variable": "var(--assets-icons-menu-unfold-line)"
  },
  "assets.icons.merge-cells-horizontal": {
    "value": "/icons/remix-icon.svg#merge-cells-horizontal",
    "variable": "var(--assets-icons-merge-cells-horizontal)"
  },
  "assets.icons.merge-cells-vertical": {
    "value": "/icons/remix-icon.svg#merge-cells-vertical",
    "variable": "var(--assets-icons-merge-cells-vertical)"
  },
  "assets.icons.message-2-fill": {
    "value": "/icons/remix-icon.svg#message-2-fill",
    "variable": "var(--assets-icons-message-2-fill)"
  },
  "assets.icons.message-2-line": {
    "value": "/icons/remix-icon.svg#message-2-line",
    "variable": "var(--assets-icons-message-2-line)"
  },
  "assets.icons.message-3-fill": {
    "value": "/icons/remix-icon.svg#message-3-fill",
    "variable": "var(--assets-icons-message-3-fill)"
  },
  "assets.icons.message-3-line": {
    "value": "/icons/remix-icon.svg#message-3-line",
    "variable": "var(--assets-icons-message-3-line)"
  },
  "assets.icons.message-fill": {
    "value": "/icons/remix-icon.svg#message-fill",
    "variable": "var(--assets-icons-message-fill)"
  },
  "assets.icons.message-line": {
    "value": "/icons/remix-icon.svg#message-line",
    "variable": "var(--assets-icons-message-line)"
  },
  "assets.icons.messenger-fill": {
    "value": "/icons/remix-icon.svg#messenger-fill",
    "variable": "var(--assets-icons-messenger-fill)"
  },
  "assets.icons.messenger-line": {
    "value": "/icons/remix-icon.svg#messenger-line",
    "variable": "var(--assets-icons-messenger-line)"
  },
  "assets.icons.meteor-fill": {
    "value": "/icons/remix-icon.svg#meteor-fill",
    "variable": "var(--assets-icons-meteor-fill)"
  },
  "assets.icons.meteor-line": {
    "value": "/icons/remix-icon.svg#meteor-line",
    "variable": "var(--assets-icons-meteor-line)"
  },
  "assets.icons.mic-2-fill": {
    "value": "/icons/remix-icon.svg#mic-2-fill",
    "variable": "var(--assets-icons-mic-2-fill)"
  },
  "assets.icons.mic-2-line": {
    "value": "/icons/remix-icon.svg#mic-2-line",
    "variable": "var(--assets-icons-mic-2-line)"
  },
  "assets.icons.mic-fill": {
    "value": "/icons/remix-icon.svg#mic-fill",
    "variable": "var(--assets-icons-mic-fill)"
  },
  "assets.icons.mic-line": {
    "value": "/icons/remix-icon.svg#mic-line",
    "variable": "var(--assets-icons-mic-line)"
  },
  "assets.icons.mic-off-fill": {
    "value": "/icons/remix-icon.svg#mic-off-fill",
    "variable": "var(--assets-icons-mic-off-fill)"
  },
  "assets.icons.mic-off-line": {
    "value": "/icons/remix-icon.svg#mic-off-line",
    "variable": "var(--assets-icons-mic-off-line)"
  },
  "assets.icons.mickey-fill": {
    "value": "/icons/remix-icon.svg#mickey-fill",
    "variable": "var(--assets-icons-mickey-fill)"
  },
  "assets.icons.mickey-line": {
    "value": "/icons/remix-icon.svg#mickey-line",
    "variable": "var(--assets-icons-mickey-line)"
  },
  "assets.icons.microscope-fill": {
    "value": "/icons/remix-icon.svg#microscope-fill",
    "variable": "var(--assets-icons-microscope-fill)"
  },
  "assets.icons.microscope-line": {
    "value": "/icons/remix-icon.svg#microscope-line",
    "variable": "var(--assets-icons-microscope-line)"
  },
  "assets.icons.microsoft-fill": {
    "value": "/icons/remix-icon.svg#microsoft-fill",
    "variable": "var(--assets-icons-microsoft-fill)"
  },
  "assets.icons.microsoft-line": {
    "value": "/icons/remix-icon.svg#microsoft-line",
    "variable": "var(--assets-icons-microsoft-line)"
  },
  "assets.icons.mind-map": {
    "value": "/icons/remix-icon.svg#mind-map",
    "variable": "var(--assets-icons-mind-map)"
  },
  "assets.icons.mini-program-fill": {
    "value": "/icons/remix-icon.svg#mini-program-fill",
    "variable": "var(--assets-icons-mini-program-fill)"
  },
  "assets.icons.mini-program-line": {
    "value": "/icons/remix-icon.svg#mini-program-line",
    "variable": "var(--assets-icons-mini-program-line)"
  },
  "assets.icons.mist-fill": {
    "value": "/icons/remix-icon.svg#mist-fill",
    "variable": "var(--assets-icons-mist-fill)"
  },
  "assets.icons.mist-line": {
    "value": "/icons/remix-icon.svg#mist-line",
    "variable": "var(--assets-icons-mist-line)"
  },
  "assets.icons.money-cny-box-fill": {
    "value": "/icons/remix-icon.svg#money-cny-box-fill",
    "variable": "var(--assets-icons-money-cny-box-fill)"
  },
  "assets.icons.money-cny-box-line": {
    "value": "/icons/remix-icon.svg#money-cny-box-line",
    "variable": "var(--assets-icons-money-cny-box-line)"
  },
  "assets.icons.money-cny-circle-fill": {
    "value": "/icons/remix-icon.svg#money-cny-circle-fill",
    "variable": "var(--assets-icons-money-cny-circle-fill)"
  },
  "assets.icons.money-cny-circle-line": {
    "value": "/icons/remix-icon.svg#money-cny-circle-line",
    "variable": "var(--assets-icons-money-cny-circle-line)"
  },
  "assets.icons.money-dollar-box-fill": {
    "value": "/icons/remix-icon.svg#money-dollar-box-fill",
    "variable": "var(--assets-icons-money-dollar-box-fill)"
  },
  "assets.icons.money-dollar-box-line": {
    "value": "/icons/remix-icon.svg#money-dollar-box-line",
    "variable": "var(--assets-icons-money-dollar-box-line)"
  },
  "assets.icons.money-dollar-circle-fill": {
    "value": "/icons/remix-icon.svg#money-dollar-circle-fill",
    "variable": "var(--assets-icons-money-dollar-circle-fill)"
  },
  "assets.icons.money-dollar-circle-line": {
    "value": "/icons/remix-icon.svg#money-dollar-circle-line",
    "variable": "var(--assets-icons-money-dollar-circle-line)"
  },
  "assets.icons.money-euro-box-fill": {
    "value": "/icons/remix-icon.svg#money-euro-box-fill",
    "variable": "var(--assets-icons-money-euro-box-fill)"
  },
  "assets.icons.money-euro-box-line": {
    "value": "/icons/remix-icon.svg#money-euro-box-line",
    "variable": "var(--assets-icons-money-euro-box-line)"
  },
  "assets.icons.money-euro-circle-fill": {
    "value": "/icons/remix-icon.svg#money-euro-circle-fill",
    "variable": "var(--assets-icons-money-euro-circle-fill)"
  },
  "assets.icons.money-euro-circle-line": {
    "value": "/icons/remix-icon.svg#money-euro-circle-line",
    "variable": "var(--assets-icons-money-euro-circle-line)"
  },
  "assets.icons.money-pound-box-fill": {
    "value": "/icons/remix-icon.svg#money-pound-box-fill",
    "variable": "var(--assets-icons-money-pound-box-fill)"
  },
  "assets.icons.money-pound-box-line": {
    "value": "/icons/remix-icon.svg#money-pound-box-line",
    "variable": "var(--assets-icons-money-pound-box-line)"
  },
  "assets.icons.money-pound-circle-fill": {
    "value": "/icons/remix-icon.svg#money-pound-circle-fill",
    "variable": "var(--assets-icons-money-pound-circle-fill)"
  },
  "assets.icons.money-pound-circle-line": {
    "value": "/icons/remix-icon.svg#money-pound-circle-line",
    "variable": "var(--assets-icons-money-pound-circle-line)"
  },
  "assets.icons.moon-clear-fill": {
    "value": "/icons/remix-icon.svg#moon-clear-fill",
    "variable": "var(--assets-icons-moon-clear-fill)"
  },
  "assets.icons.moon-clear-line": {
    "value": "/icons/remix-icon.svg#moon-clear-line",
    "variable": "var(--assets-icons-moon-clear-line)"
  },
  "assets.icons.moon-cloudy-fill": {
    "value": "/icons/remix-icon.svg#moon-cloudy-fill",
    "variable": "var(--assets-icons-moon-cloudy-fill)"
  },
  "assets.icons.moon-cloudy-line": {
    "value": "/icons/remix-icon.svg#moon-cloudy-line",
    "variable": "var(--assets-icons-moon-cloudy-line)"
  },
  "assets.icons.moon-fill": {
    "value": "/icons/remix-icon.svg#moon-fill",
    "variable": "var(--assets-icons-moon-fill)"
  },
  "assets.icons.moon-foggy-fill": {
    "value": "/icons/remix-icon.svg#moon-foggy-fill",
    "variable": "var(--assets-icons-moon-foggy-fill)"
  },
  "assets.icons.moon-foggy-line": {
    "value": "/icons/remix-icon.svg#moon-foggy-line",
    "variable": "var(--assets-icons-moon-foggy-line)"
  },
  "assets.icons.moon-line": {
    "value": "/icons/remix-icon.svg#moon-line",
    "variable": "var(--assets-icons-moon-line)"
  },
  "assets.icons.more-2-fill": {
    "value": "/icons/remix-icon.svg#more-2-fill",
    "variable": "var(--assets-icons-more-2-fill)"
  },
  "assets.icons.more-2-line": {
    "value": "/icons/remix-icon.svg#more-2-line",
    "variable": "var(--assets-icons-more-2-line)"
  },
  "assets.icons.more-fill": {
    "value": "/icons/remix-icon.svg#more-fill",
    "variable": "var(--assets-icons-more-fill)"
  },
  "assets.icons.more-line": {
    "value": "/icons/remix-icon.svg#more-line",
    "variable": "var(--assets-icons-more-line)"
  },
  "assets.icons.motorbike-fill": {
    "value": "/icons/remix-icon.svg#motorbike-fill",
    "variable": "var(--assets-icons-motorbike-fill)"
  },
  "assets.icons.motorbike-line": {
    "value": "/icons/remix-icon.svg#motorbike-line",
    "variable": "var(--assets-icons-motorbike-line)"
  },
  "assets.icons.mouse-fill": {
    "value": "/icons/remix-icon.svg#mouse-fill",
    "variable": "var(--assets-icons-mouse-fill)"
  },
  "assets.icons.mouse-line": {
    "value": "/icons/remix-icon.svg#mouse-line",
    "variable": "var(--assets-icons-mouse-line)"
  },
  "assets.icons.movie-2-fill": {
    "value": "/icons/remix-icon.svg#movie-2-fill",
    "variable": "var(--assets-icons-movie-2-fill)"
  },
  "assets.icons.movie-2-line": {
    "value": "/icons/remix-icon.svg#movie-2-line",
    "variable": "var(--assets-icons-movie-2-line)"
  },
  "assets.icons.movie-fill": {
    "value": "/icons/remix-icon.svg#movie-fill",
    "variable": "var(--assets-icons-movie-fill)"
  },
  "assets.icons.movie-line": {
    "value": "/icons/remix-icon.svg#movie-line",
    "variable": "var(--assets-icons-movie-line)"
  },
  "assets.icons.music-2-fill": {
    "value": "/icons/remix-icon.svg#music-2-fill",
    "variable": "var(--assets-icons-music-2-fill)"
  },
  "assets.icons.music-2-line": {
    "value": "/icons/remix-icon.svg#music-2-line",
    "variable": "var(--assets-icons-music-2-line)"
  },
  "assets.icons.music-fill": {
    "value": "/icons/remix-icon.svg#music-fill",
    "variable": "var(--assets-icons-music-fill)"
  },
  "assets.icons.music-line": {
    "value": "/icons/remix-icon.svg#music-line",
    "variable": "var(--assets-icons-music-line)"
  },
  "assets.icons.mv-fill": {
    "value": "/icons/remix-icon.svg#mv-fill",
    "variable": "var(--assets-icons-mv-fill)"
  },
  "assets.icons.mv-line": {
    "value": "/icons/remix-icon.svg#mv-line",
    "variable": "var(--assets-icons-mv-line)"
  },
  "assets.icons.navigation-fill": {
    "value": "/icons/remix-icon.svg#navigation-fill",
    "variable": "var(--assets-icons-navigation-fill)"
  },
  "assets.icons.navigation-line": {
    "value": "/icons/remix-icon.svg#navigation-line",
    "variable": "var(--assets-icons-navigation-line)"
  },
  "assets.icons.netease-cloud-music-fill": {
    "value": "/icons/remix-icon.svg#netease-cloud-music-fill",
    "variable": "var(--assets-icons-netease-cloud-music-fill)"
  },
  "assets.icons.netease-cloud-music-line": {
    "value": "/icons/remix-icon.svg#netease-cloud-music-line",
    "variable": "var(--assets-icons-netease-cloud-music-line)"
  },
  "assets.icons.netflix-fill": {
    "value": "/icons/remix-icon.svg#netflix-fill",
    "variable": "var(--assets-icons-netflix-fill)"
  },
  "assets.icons.netflix-line": {
    "value": "/icons/remix-icon.svg#netflix-line",
    "variable": "var(--assets-icons-netflix-line)"
  },
  "assets.icons.newspaper-fill": {
    "value": "/icons/remix-icon.svg#newspaper-fill",
    "variable": "var(--assets-icons-newspaper-fill)"
  },
  "assets.icons.newspaper-line": {
    "value": "/icons/remix-icon.svg#newspaper-line",
    "variable": "var(--assets-icons-newspaper-line)"
  },
  "assets.icons.node-tree": {
    "value": "/icons/remix-icon.svg#node-tree",
    "variable": "var(--assets-icons-node-tree)"
  },
  "assets.icons.notification-2-fill": {
    "value": "/icons/remix-icon.svg#notification-2-fill",
    "variable": "var(--assets-icons-notification-2-fill)"
  },
  "assets.icons.notification-2-line": {
    "value": "/icons/remix-icon.svg#notification-2-line",
    "variable": "var(--assets-icons-notification-2-line)"
  },
  "assets.icons.notification-3-fill": {
    "value": "/icons/remix-icon.svg#notification-3-fill",
    "variable": "var(--assets-icons-notification-3-fill)"
  },
  "assets.icons.notification-3-line": {
    "value": "/icons/remix-icon.svg#notification-3-line",
    "variable": "var(--assets-icons-notification-3-line)"
  },
  "assets.icons.notification-4-fill": {
    "value": "/icons/remix-icon.svg#notification-4-fill",
    "variable": "var(--assets-icons-notification-4-fill)"
  },
  "assets.icons.notification-4-line": {
    "value": "/icons/remix-icon.svg#notification-4-line",
    "variable": "var(--assets-icons-notification-4-line)"
  },
  "assets.icons.notification-badge-fill": {
    "value": "/icons/remix-icon.svg#notification-badge-fill",
    "variable": "var(--assets-icons-notification-badge-fill)"
  },
  "assets.icons.notification-badge-line": {
    "value": "/icons/remix-icon.svg#notification-badge-line",
    "variable": "var(--assets-icons-notification-badge-line)"
  },
  "assets.icons.notification-fill": {
    "value": "/icons/remix-icon.svg#notification-fill",
    "variable": "var(--assets-icons-notification-fill)"
  },
  "assets.icons.notification-line": {
    "value": "/icons/remix-icon.svg#notification-line",
    "variable": "var(--assets-icons-notification-line)"
  },
  "assets.icons.notification-off-fill": {
    "value": "/icons/remix-icon.svg#notification-off-fill",
    "variable": "var(--assets-icons-notification-off-fill)"
  },
  "assets.icons.notification-off-line": {
    "value": "/icons/remix-icon.svg#notification-off-line",
    "variable": "var(--assets-icons-notification-off-line)"
  },
  "assets.icons.npmjs-fill": {
    "value": "/icons/remix-icon.svg#npmjs-fill",
    "variable": "var(--assets-icons-npmjs-fill)"
  },
  "assets.icons.npmjs-line": {
    "value": "/icons/remix-icon.svg#npmjs-line",
    "variable": "var(--assets-icons-npmjs-line)"
  },
  "assets.icons.number-0": {
    "value": "/icons/remix-icon.svg#number-0",
    "variable": "var(--assets-icons-number-0)"
  },
  "assets.icons.number-1": {
    "value": "/icons/remix-icon.svg#number-1",
    "variable": "var(--assets-icons-number-1)"
  },
  "assets.icons.number-2": {
    "value": "/icons/remix-icon.svg#number-2",
    "variable": "var(--assets-icons-number-2)"
  },
  "assets.icons.number-3": {
    "value": "/icons/remix-icon.svg#number-3",
    "variable": "var(--assets-icons-number-3)"
  },
  "assets.icons.number-4": {
    "value": "/icons/remix-icon.svg#number-4",
    "variable": "var(--assets-icons-number-4)"
  },
  "assets.icons.number-5": {
    "value": "/icons/remix-icon.svg#number-5",
    "variable": "var(--assets-icons-number-5)"
  },
  "assets.icons.number-6": {
    "value": "/icons/remix-icon.svg#number-6",
    "variable": "var(--assets-icons-number-6)"
  },
  "assets.icons.number-7": {
    "value": "/icons/remix-icon.svg#number-7",
    "variable": "var(--assets-icons-number-7)"
  },
  "assets.icons.number-8": {
    "value": "/icons/remix-icon.svg#number-8",
    "variable": "var(--assets-icons-number-8)"
  },
  "assets.icons.number-9": {
    "value": "/icons/remix-icon.svg#number-9",
    "variable": "var(--assets-icons-number-9)"
  },
  "assets.icons.numbers-fill": {
    "value": "/icons/remix-icon.svg#numbers-fill",
    "variable": "var(--assets-icons-numbers-fill)"
  },
  "assets.icons.numbers-line": {
    "value": "/icons/remix-icon.svg#numbers-line",
    "variable": "var(--assets-icons-numbers-line)"
  },
  "assets.icons.nurse-fill": {
    "value": "/icons/remix-icon.svg#nurse-fill",
    "variable": "var(--assets-icons-nurse-fill)"
  },
  "assets.icons.nurse-line": {
    "value": "/icons/remix-icon.svg#nurse-line",
    "variable": "var(--assets-icons-nurse-line)"
  },
  "assets.icons.oil-fill": {
    "value": "/icons/remix-icon.svg#oil-fill",
    "variable": "var(--assets-icons-oil-fill)"
  },
  "assets.icons.oil-line": {
    "value": "/icons/remix-icon.svg#oil-line",
    "variable": "var(--assets-icons-oil-line)"
  },
  "assets.icons.omega": {
    "value": "/icons/remix-icon.svg#omega",
    "variable": "var(--assets-icons-omega)"
  },
  "assets.icons.open-arm-fill": {
    "value": "/icons/remix-icon.svg#open-arm-fill",
    "variable": "var(--assets-icons-open-arm-fill)"
  },
  "assets.icons.open-arm-line": {
    "value": "/icons/remix-icon.svg#open-arm-line",
    "variable": "var(--assets-icons-open-arm-line)"
  },
  "assets.icons.open-source-fill": {
    "value": "/icons/remix-icon.svg#open-source-fill",
    "variable": "var(--assets-icons-open-source-fill)"
  },
  "assets.icons.open-source-line": {
    "value": "/icons/remix-icon.svg#open-source-line",
    "variable": "var(--assets-icons-open-source-line)"
  },
  "assets.icons.opera-fill": {
    "value": "/icons/remix-icon.svg#opera-fill",
    "variable": "var(--assets-icons-opera-fill)"
  },
  "assets.icons.opera-line": {
    "value": "/icons/remix-icon.svg#opera-line",
    "variable": "var(--assets-icons-opera-line)"
  },
  "assets.icons.order-play-fill": {
    "value": "/icons/remix-icon.svg#order-play-fill",
    "variable": "var(--assets-icons-order-play-fill)"
  },
  "assets.icons.order-play-line": {
    "value": "/icons/remix-icon.svg#order-play-line",
    "variable": "var(--assets-icons-order-play-line)"
  },
  "assets.icons.organization-chart": {
    "value": "/icons/remix-icon.svg#organization-chart",
    "variable": "var(--assets-icons-organization-chart)"
  },
  "assets.icons.outlet-2-fill": {
    "value": "/icons/remix-icon.svg#outlet-2-fill",
    "variable": "var(--assets-icons-outlet-2-fill)"
  },
  "assets.icons.outlet-2-line": {
    "value": "/icons/remix-icon.svg#outlet-2-line",
    "variable": "var(--assets-icons-outlet-2-line)"
  },
  "assets.icons.outlet-fill": {
    "value": "/icons/remix-icon.svg#outlet-fill",
    "variable": "var(--assets-icons-outlet-fill)"
  },
  "assets.icons.outlet-line": {
    "value": "/icons/remix-icon.svg#outlet-line",
    "variable": "var(--assets-icons-outlet-line)"
  },
  "assets.icons.page-separator": {
    "value": "/icons/remix-icon.svg#page-separator",
    "variable": "var(--assets-icons-page-separator)"
  },
  "assets.icons.pages-fill": {
    "value": "/icons/remix-icon.svg#pages-fill",
    "variable": "var(--assets-icons-pages-fill)"
  },
  "assets.icons.pages-line": {
    "value": "/icons/remix-icon.svg#pages-line",
    "variable": "var(--assets-icons-pages-line)"
  },
  "assets.icons.paint-brush-fill": {
    "value": "/icons/remix-icon.svg#paint-brush-fill",
    "variable": "var(--assets-icons-paint-brush-fill)"
  },
  "assets.icons.paint-brush-line": {
    "value": "/icons/remix-icon.svg#paint-brush-line",
    "variable": "var(--assets-icons-paint-brush-line)"
  },
  "assets.icons.paint-fill": {
    "value": "/icons/remix-icon.svg#paint-fill",
    "variable": "var(--assets-icons-paint-fill)"
  },
  "assets.icons.paint-line": {
    "value": "/icons/remix-icon.svg#paint-line",
    "variable": "var(--assets-icons-paint-line)"
  },
  "assets.icons.palette-fill": {
    "value": "/icons/remix-icon.svg#palette-fill",
    "variable": "var(--assets-icons-palette-fill)"
  },
  "assets.icons.palette-line": {
    "value": "/icons/remix-icon.svg#palette-line",
    "variable": "var(--assets-icons-palette-line)"
  },
  "assets.icons.pantone-fill": {
    "value": "/icons/remix-icon.svg#pantone-fill",
    "variable": "var(--assets-icons-pantone-fill)"
  },
  "assets.icons.pantone-line": {
    "value": "/icons/remix-icon.svg#pantone-line",
    "variable": "var(--assets-icons-pantone-line)"
  },
  "assets.icons.paragraph": {
    "value": "/icons/remix-icon.svg#paragraph",
    "variable": "var(--assets-icons-paragraph)"
  },
  "assets.icons.parent-fill": {
    "value": "/icons/remix-icon.svg#parent-fill",
    "variable": "var(--assets-icons-parent-fill)"
  },
  "assets.icons.parent-line": {
    "value": "/icons/remix-icon.svg#parent-line",
    "variable": "var(--assets-icons-parent-line)"
  },
  "assets.icons.parentheses-fill": {
    "value": "/icons/remix-icon.svg#parentheses-fill",
    "variable": "var(--assets-icons-parentheses-fill)"
  },
  "assets.icons.parentheses-line": {
    "value": "/icons/remix-icon.svg#parentheses-line",
    "variable": "var(--assets-icons-parentheses-line)"
  },
  "assets.icons.parking-box-fill": {
    "value": "/icons/remix-icon.svg#parking-box-fill",
    "variable": "var(--assets-icons-parking-box-fill)"
  },
  "assets.icons.parking-box-line": {
    "value": "/icons/remix-icon.svg#parking-box-line",
    "variable": "var(--assets-icons-parking-box-line)"
  },
  "assets.icons.parking-fill": {
    "value": "/icons/remix-icon.svg#parking-fill",
    "variable": "var(--assets-icons-parking-fill)"
  },
  "assets.icons.parking-line": {
    "value": "/icons/remix-icon.svg#parking-line",
    "variable": "var(--assets-icons-parking-line)"
  },
  "assets.icons.passport-fill": {
    "value": "/icons/remix-icon.svg#passport-fill",
    "variable": "var(--assets-icons-passport-fill)"
  },
  "assets.icons.passport-line": {
    "value": "/icons/remix-icon.svg#passport-line",
    "variable": "var(--assets-icons-passport-line)"
  },
  "assets.icons.patreon-fill": {
    "value": "/icons/remix-icon.svg#patreon-fill",
    "variable": "var(--assets-icons-patreon-fill)"
  },
  "assets.icons.patreon-line": {
    "value": "/icons/remix-icon.svg#patreon-line",
    "variable": "var(--assets-icons-patreon-line)"
  },
  "assets.icons.pause-circle-fill": {
    "value": "/icons/remix-icon.svg#pause-circle-fill",
    "variable": "var(--assets-icons-pause-circle-fill)"
  },
  "assets.icons.pause-circle-line": {
    "value": "/icons/remix-icon.svg#pause-circle-line",
    "variable": "var(--assets-icons-pause-circle-line)"
  },
  "assets.icons.pause-fill": {
    "value": "/icons/remix-icon.svg#pause-fill",
    "variable": "var(--assets-icons-pause-fill)"
  },
  "assets.icons.pause-line": {
    "value": "/icons/remix-icon.svg#pause-line",
    "variable": "var(--assets-icons-pause-line)"
  },
  "assets.icons.pause-mini-fill": {
    "value": "/icons/remix-icon.svg#pause-mini-fill",
    "variable": "var(--assets-icons-pause-mini-fill)"
  },
  "assets.icons.pause-mini-line": {
    "value": "/icons/remix-icon.svg#pause-mini-line",
    "variable": "var(--assets-icons-pause-mini-line)"
  },
  "assets.icons.paypal-fill": {
    "value": "/icons/remix-icon.svg#paypal-fill",
    "variable": "var(--assets-icons-paypal-fill)"
  },
  "assets.icons.paypal-line": {
    "value": "/icons/remix-icon.svg#paypal-line",
    "variable": "var(--assets-icons-paypal-line)"
  },
  "assets.icons.pen-nib-fill": {
    "value": "/icons/remix-icon.svg#pen-nib-fill",
    "variable": "var(--assets-icons-pen-nib-fill)"
  },
  "assets.icons.pen-nib-line": {
    "value": "/icons/remix-icon.svg#pen-nib-line",
    "variable": "var(--assets-icons-pen-nib-line)"
  },
  "assets.icons.pencil-fill": {
    "value": "/icons/remix-icon.svg#pencil-fill",
    "variable": "var(--assets-icons-pencil-fill)"
  },
  "assets.icons.pencil-line": {
    "value": "/icons/remix-icon.svg#pencil-line",
    "variable": "var(--assets-icons-pencil-line)"
  },
  "assets.icons.pencil-ruler-2-fill": {
    "value": "/icons/remix-icon.svg#pencil-ruler-2-fill",
    "variable": "var(--assets-icons-pencil-ruler-2-fill)"
  },
  "assets.icons.pencil-ruler-2-line": {
    "value": "/icons/remix-icon.svg#pencil-ruler-2-line",
    "variable": "var(--assets-icons-pencil-ruler-2-line)"
  },
  "assets.icons.pencil-ruler-fill": {
    "value": "/icons/remix-icon.svg#pencil-ruler-fill",
    "variable": "var(--assets-icons-pencil-ruler-fill)"
  },
  "assets.icons.pencil-ruler-line": {
    "value": "/icons/remix-icon.svg#pencil-ruler-line",
    "variable": "var(--assets-icons-pencil-ruler-line)"
  },
  "assets.icons.percent-fill": {
    "value": "/icons/remix-icon.svg#percent-fill",
    "variable": "var(--assets-icons-percent-fill)"
  },
  "assets.icons.percent-line": {
    "value": "/icons/remix-icon.svg#percent-line",
    "variable": "var(--assets-icons-percent-line)"
  },
  "assets.icons.phone-camera-fill": {
    "value": "/icons/remix-icon.svg#phone-camera-fill",
    "variable": "var(--assets-icons-phone-camera-fill)"
  },
  "assets.icons.phone-camera-line": {
    "value": "/icons/remix-icon.svg#phone-camera-line",
    "variable": "var(--assets-icons-phone-camera-line)"
  },
  "assets.icons.phone-fill": {
    "value": "/icons/remix-icon.svg#phone-fill",
    "variable": "var(--assets-icons-phone-fill)"
  },
  "assets.icons.phone-find-fill": {
    "value": "/icons/remix-icon.svg#phone-find-fill",
    "variable": "var(--assets-icons-phone-find-fill)"
  },
  "assets.icons.phone-find-line": {
    "value": "/icons/remix-icon.svg#phone-find-line",
    "variable": "var(--assets-icons-phone-find-line)"
  },
  "assets.icons.phone-line": {
    "value": "/icons/remix-icon.svg#phone-line",
    "variable": "var(--assets-icons-phone-line)"
  },
  "assets.icons.phone-lock-fill": {
    "value": "/icons/remix-icon.svg#phone-lock-fill",
    "variable": "var(--assets-icons-phone-lock-fill)"
  },
  "assets.icons.phone-lock-line": {
    "value": "/icons/remix-icon.svg#phone-lock-line",
    "variable": "var(--assets-icons-phone-lock-line)"
  },
  "assets.icons.picture-in-picture-2-fill": {
    "value": "/icons/remix-icon.svg#picture-in-picture-2-fill",
    "variable": "var(--assets-icons-picture-in-picture-2-fill)"
  },
  "assets.icons.picture-in-picture-2-line": {
    "value": "/icons/remix-icon.svg#picture-in-picture-2-line",
    "variable": "var(--assets-icons-picture-in-picture-2-line)"
  },
  "assets.icons.picture-in-picture-exit-fill": {
    "value": "/icons/remix-icon.svg#picture-in-picture-exit-fill",
    "variable": "var(--assets-icons-picture-in-picture-exit-fill)"
  },
  "assets.icons.picture-in-picture-exit-line": {
    "value": "/icons/remix-icon.svg#picture-in-picture-exit-line",
    "variable": "var(--assets-icons-picture-in-picture-exit-line)"
  },
  "assets.icons.picture-in-picture-fill": {
    "value": "/icons/remix-icon.svg#picture-in-picture-fill",
    "variable": "var(--assets-icons-picture-in-picture-fill)"
  },
  "assets.icons.picture-in-picture-line": {
    "value": "/icons/remix-icon.svg#picture-in-picture-line",
    "variable": "var(--assets-icons-picture-in-picture-line)"
  },
  "assets.icons.pie-chart-2-fill": {
    "value": "/icons/remix-icon.svg#pie-chart-2-fill",
    "variable": "var(--assets-icons-pie-chart-2-fill)"
  },
  "assets.icons.pie-chart-2-line": {
    "value": "/icons/remix-icon.svg#pie-chart-2-line",
    "variable": "var(--assets-icons-pie-chart-2-line)"
  },
  "assets.icons.pie-chart-box-fill": {
    "value": "/icons/remix-icon.svg#pie-chart-box-fill",
    "variable": "var(--assets-icons-pie-chart-box-fill)"
  },
  "assets.icons.pie-chart-box-line": {
    "value": "/icons/remix-icon.svg#pie-chart-box-line",
    "variable": "var(--assets-icons-pie-chart-box-line)"
  },
  "assets.icons.pie-chart-fill": {
    "value": "/icons/remix-icon.svg#pie-chart-fill",
    "variable": "var(--assets-icons-pie-chart-fill)"
  },
  "assets.icons.pie-chart-line": {
    "value": "/icons/remix-icon.svg#pie-chart-line",
    "variable": "var(--assets-icons-pie-chart-line)"
  },
  "assets.icons.pin-distance-fill": {
    "value": "/icons/remix-icon.svg#pin-distance-fill",
    "variable": "var(--assets-icons-pin-distance-fill)"
  },
  "assets.icons.pin-distance-line": {
    "value": "/icons/remix-icon.svg#pin-distance-line",
    "variable": "var(--assets-icons-pin-distance-line)"
  },
  "assets.icons.ping-pong-fill": {
    "value": "/icons/remix-icon.svg#ping-pong-fill",
    "variable": "var(--assets-icons-ping-pong-fill)"
  },
  "assets.icons.ping-pong-line": {
    "value": "/icons/remix-icon.svg#ping-pong-line",
    "variable": "var(--assets-icons-ping-pong-line)"
  },
  "assets.icons.pinterest-fill": {
    "value": "/icons/remix-icon.svg#pinterest-fill",
    "variable": "var(--assets-icons-pinterest-fill)"
  },
  "assets.icons.pinterest-line": {
    "value": "/icons/remix-icon.svg#pinterest-line",
    "variable": "var(--assets-icons-pinterest-line)"
  },
  "assets.icons.pinyin-input": {
    "value": "/icons/remix-icon.svg#pinyin-input",
    "variable": "var(--assets-icons-pinyin-input)"
  },
  "assets.icons.pixelfed-fill": {
    "value": "/icons/remix-icon.svg#pixelfed-fill",
    "variable": "var(--assets-icons-pixelfed-fill)"
  },
  "assets.icons.pixelfed-line": {
    "value": "/icons/remix-icon.svg#pixelfed-line",
    "variable": "var(--assets-icons-pixelfed-line)"
  },
  "assets.icons.plane-fill": {
    "value": "/icons/remix-icon.svg#plane-fill",
    "variable": "var(--assets-icons-plane-fill)"
  },
  "assets.icons.plane-line": {
    "value": "/icons/remix-icon.svg#plane-line",
    "variable": "var(--assets-icons-plane-line)"
  },
  "assets.icons.plant-fill": {
    "value": "/icons/remix-icon.svg#plant-fill",
    "variable": "var(--assets-icons-plant-fill)"
  },
  "assets.icons.plant-line": {
    "value": "/icons/remix-icon.svg#plant-line",
    "variable": "var(--assets-icons-plant-line)"
  },
  "assets.icons.play-circle-fill": {
    "value": "/icons/remix-icon.svg#play-circle-fill",
    "variable": "var(--assets-icons-play-circle-fill)"
  },
  "assets.icons.play-circle-line": {
    "value": "/icons/remix-icon.svg#play-circle-line",
    "variable": "var(--assets-icons-play-circle-line)"
  },
  "assets.icons.play-fill": {
    "value": "/icons/remix-icon.svg#play-fill",
    "variable": "var(--assets-icons-play-fill)"
  },
  "assets.icons.play-line": {
    "value": "/icons/remix-icon.svg#play-line",
    "variable": "var(--assets-icons-play-line)"
  },
  "assets.icons.play-list-2-fill": {
    "value": "/icons/remix-icon.svg#play-list-2-fill",
    "variable": "var(--assets-icons-play-list-2-fill)"
  },
  "assets.icons.play-list-2-line": {
    "value": "/icons/remix-icon.svg#play-list-2-line",
    "variable": "var(--assets-icons-play-list-2-line)"
  },
  "assets.icons.play-list-add-fill": {
    "value": "/icons/remix-icon.svg#play-list-add-fill",
    "variable": "var(--assets-icons-play-list-add-fill)"
  },
  "assets.icons.play-list-add-line": {
    "value": "/icons/remix-icon.svg#play-list-add-line",
    "variable": "var(--assets-icons-play-list-add-line)"
  },
  "assets.icons.play-list-fill": {
    "value": "/icons/remix-icon.svg#play-list-fill",
    "variable": "var(--assets-icons-play-list-fill)"
  },
  "assets.icons.play-list-line": {
    "value": "/icons/remix-icon.svg#play-list-line",
    "variable": "var(--assets-icons-play-list-line)"
  },
  "assets.icons.play-mini-fill": {
    "value": "/icons/remix-icon.svg#play-mini-fill",
    "variable": "var(--assets-icons-play-mini-fill)"
  },
  "assets.icons.play-mini-line": {
    "value": "/icons/remix-icon.svg#play-mini-line",
    "variable": "var(--assets-icons-play-mini-line)"
  },
  "assets.icons.playstation-fill": {
    "value": "/icons/remix-icon.svg#playstation-fill",
    "variable": "var(--assets-icons-playstation-fill)"
  },
  "assets.icons.playstation-line": {
    "value": "/icons/remix-icon.svg#playstation-line",
    "variable": "var(--assets-icons-playstation-line)"
  },
  "assets.icons.plug-2-fill": {
    "value": "/icons/remix-icon.svg#plug-2-fill",
    "variable": "var(--assets-icons-plug-2-fill)"
  },
  "assets.icons.plug-2-line": {
    "value": "/icons/remix-icon.svg#plug-2-line",
    "variable": "var(--assets-icons-plug-2-line)"
  },
  "assets.icons.plug-fill": {
    "value": "/icons/remix-icon.svg#plug-fill",
    "variable": "var(--assets-icons-plug-fill)"
  },
  "assets.icons.plug-line": {
    "value": "/icons/remix-icon.svg#plug-line",
    "variable": "var(--assets-icons-plug-line)"
  },
  "assets.icons.polaroid-2-fill": {
    "value": "/icons/remix-icon.svg#polaroid-2-fill",
    "variable": "var(--assets-icons-polaroid-2-fill)"
  },
  "assets.icons.polaroid-2-line": {
    "value": "/icons/remix-icon.svg#polaroid-2-line",
    "variable": "var(--assets-icons-polaroid-2-line)"
  },
  "assets.icons.polaroid-fill": {
    "value": "/icons/remix-icon.svg#polaroid-fill",
    "variable": "var(--assets-icons-polaroid-fill)"
  },
  "assets.icons.polaroid-line": {
    "value": "/icons/remix-icon.svg#polaroid-line",
    "variable": "var(--assets-icons-polaroid-line)"
  },
  "assets.icons.police-car-fill": {
    "value": "/icons/remix-icon.svg#police-car-fill",
    "variable": "var(--assets-icons-police-car-fill)"
  },
  "assets.icons.police-car-line": {
    "value": "/icons/remix-icon.svg#police-car-line",
    "variable": "var(--assets-icons-police-car-line)"
  },
  "assets.icons.price-tag-2-fill": {
    "value": "/icons/remix-icon.svg#price-tag-2-fill",
    "variable": "var(--assets-icons-price-tag-2-fill)"
  },
  "assets.icons.price-tag-2-line": {
    "value": "/icons/remix-icon.svg#price-tag-2-line",
    "variable": "var(--assets-icons-price-tag-2-line)"
  },
  "assets.icons.price-tag-3-fill": {
    "value": "/icons/remix-icon.svg#price-tag-3-fill",
    "variable": "var(--assets-icons-price-tag-3-fill)"
  },
  "assets.icons.price-tag-3-line": {
    "value": "/icons/remix-icon.svg#price-tag-3-line",
    "variable": "var(--assets-icons-price-tag-3-line)"
  },
  "assets.icons.price-tag-fill": {
    "value": "/icons/remix-icon.svg#price-tag-fill",
    "variable": "var(--assets-icons-price-tag-fill)"
  },
  "assets.icons.price-tag-line": {
    "value": "/icons/remix-icon.svg#price-tag-line",
    "variable": "var(--assets-icons-price-tag-line)"
  },
  "assets.icons.printer-cloud-fill": {
    "value": "/icons/remix-icon.svg#printer-cloud-fill",
    "variable": "var(--assets-icons-printer-cloud-fill)"
  },
  "assets.icons.printer-cloud-line": {
    "value": "/icons/remix-icon.svg#printer-cloud-line",
    "variable": "var(--assets-icons-printer-cloud-line)"
  },
  "assets.icons.printer-fill": {
    "value": "/icons/remix-icon.svg#printer-fill",
    "variable": "var(--assets-icons-printer-fill)"
  },
  "assets.icons.printer-line": {
    "value": "/icons/remix-icon.svg#printer-line",
    "variable": "var(--assets-icons-printer-line)"
  },
  "assets.icons.product-hunt-fill": {
    "value": "/icons/remix-icon.svg#product-hunt-fill",
    "variable": "var(--assets-icons-product-hunt-fill)"
  },
  "assets.icons.product-hunt-line": {
    "value": "/icons/remix-icon.svg#product-hunt-line",
    "variable": "var(--assets-icons-product-hunt-line)"
  },
  "assets.icons.profile-fill": {
    "value": "/icons/remix-icon.svg#profile-fill",
    "variable": "var(--assets-icons-profile-fill)"
  },
  "assets.icons.profile-line": {
    "value": "/icons/remix-icon.svg#profile-line",
    "variable": "var(--assets-icons-profile-line)"
  },
  "assets.icons.projector-2-fill": {
    "value": "/icons/remix-icon.svg#projector-2-fill",
    "variable": "var(--assets-icons-projector-2-fill)"
  },
  "assets.icons.projector-2-line": {
    "value": "/icons/remix-icon.svg#projector-2-line",
    "variable": "var(--assets-icons-projector-2-line)"
  },
  "assets.icons.projector-fill": {
    "value": "/icons/remix-icon.svg#projector-fill",
    "variable": "var(--assets-icons-projector-fill)"
  },
  "assets.icons.projector-line": {
    "value": "/icons/remix-icon.svg#projector-line",
    "variable": "var(--assets-icons-projector-line)"
  },
  "assets.icons.psychotherapy-fill": {
    "value": "/icons/remix-icon.svg#psychotherapy-fill",
    "variable": "var(--assets-icons-psychotherapy-fill)"
  },
  "assets.icons.psychotherapy-line": {
    "value": "/icons/remix-icon.svg#psychotherapy-line",
    "variable": "var(--assets-icons-psychotherapy-line)"
  },
  "assets.icons.pulse-fill": {
    "value": "/icons/remix-icon.svg#pulse-fill",
    "variable": "var(--assets-icons-pulse-fill)"
  },
  "assets.icons.pulse-line": {
    "value": "/icons/remix-icon.svg#pulse-line",
    "variable": "var(--assets-icons-pulse-line)"
  },
  "assets.icons.pushpin-2-fill": {
    "value": "/icons/remix-icon.svg#pushpin-2-fill",
    "variable": "var(--assets-icons-pushpin-2-fill)"
  },
  "assets.icons.pushpin-2-line": {
    "value": "/icons/remix-icon.svg#pushpin-2-line",
    "variable": "var(--assets-icons-pushpin-2-line)"
  },
  "assets.icons.pushpin-fill": {
    "value": "/icons/remix-icon.svg#pushpin-fill",
    "variable": "var(--assets-icons-pushpin-fill)"
  },
  "assets.icons.pushpin-line": {
    "value": "/icons/remix-icon.svg#pushpin-line",
    "variable": "var(--assets-icons-pushpin-line)"
  },
  "assets.icons.qq-fill": {
    "value": "/icons/remix-icon.svg#qq-fill",
    "variable": "var(--assets-icons-qq-fill)"
  },
  "assets.icons.qq-line": {
    "value": "/icons/remix-icon.svg#qq-line",
    "variable": "var(--assets-icons-qq-line)"
  },
  "assets.icons.qr-code-fill": {
    "value": "/icons/remix-icon.svg#qr-code-fill",
    "variable": "var(--assets-icons-qr-code-fill)"
  },
  "assets.icons.qr-code-line": {
    "value": "/icons/remix-icon.svg#qr-code-line",
    "variable": "var(--assets-icons-qr-code-line)"
  },
  "assets.icons.qr-scan-2-fill": {
    "value": "/icons/remix-icon.svg#qr-scan-2-fill",
    "variable": "var(--assets-icons-qr-scan-2-fill)"
  },
  "assets.icons.qr-scan-2-line": {
    "value": "/icons/remix-icon.svg#qr-scan-2-line",
    "variable": "var(--assets-icons-qr-scan-2-line)"
  },
  "assets.icons.qr-scan-fill": {
    "value": "/icons/remix-icon.svg#qr-scan-fill",
    "variable": "var(--assets-icons-qr-scan-fill)"
  },
  "assets.icons.qr-scan-line": {
    "value": "/icons/remix-icon.svg#qr-scan-line",
    "variable": "var(--assets-icons-qr-scan-line)"
  },
  "assets.icons.question-answer-fill": {
    "value": "/icons/remix-icon.svg#question-answer-fill",
    "variable": "var(--assets-icons-question-answer-fill)"
  },
  "assets.icons.question-answer-line": {
    "value": "/icons/remix-icon.svg#question-answer-line",
    "variable": "var(--assets-icons-question-answer-line)"
  },
  "assets.icons.question-fill": {
    "value": "/icons/remix-icon.svg#question-fill",
    "variable": "var(--assets-icons-question-fill)"
  },
  "assets.icons.question-line": {
    "value": "/icons/remix-icon.svg#question-line",
    "variable": "var(--assets-icons-question-line)"
  },
  "assets.icons.question-mark": {
    "value": "/icons/remix-icon.svg#question-mark",
    "variable": "var(--assets-icons-question-mark)"
  },
  "assets.icons.questionnaire-fill": {
    "value": "/icons/remix-icon.svg#questionnaire-fill",
    "variable": "var(--assets-icons-questionnaire-fill)"
  },
  "assets.icons.questionnaire-line": {
    "value": "/icons/remix-icon.svg#questionnaire-line",
    "variable": "var(--assets-icons-questionnaire-line)"
  },
  "assets.icons.quill-pen-fill": {
    "value": "/icons/remix-icon.svg#quill-pen-fill",
    "variable": "var(--assets-icons-quill-pen-fill)"
  },
  "assets.icons.quill-pen-line": {
    "value": "/icons/remix-icon.svg#quill-pen-line",
    "variable": "var(--assets-icons-quill-pen-line)"
  },
  "assets.icons.radar-fill": {
    "value": "/icons/remix-icon.svg#radar-fill",
    "variable": "var(--assets-icons-radar-fill)"
  },
  "assets.icons.radar-line": {
    "value": "/icons/remix-icon.svg#radar-line",
    "variable": "var(--assets-icons-radar-line)"
  },
  "assets.icons.radio-2-fill": {
    "value": "/icons/remix-icon.svg#radio-2-fill",
    "variable": "var(--assets-icons-radio-2-fill)"
  },
  "assets.icons.radio-2-line": {
    "value": "/icons/remix-icon.svg#radio-2-line",
    "variable": "var(--assets-icons-radio-2-line)"
  },
  "assets.icons.radio-button-fill": {
    "value": "/icons/remix-icon.svg#radio-button-fill",
    "variable": "var(--assets-icons-radio-button-fill)"
  },
  "assets.icons.radio-button-line": {
    "value": "/icons/remix-icon.svg#radio-button-line",
    "variable": "var(--assets-icons-radio-button-line)"
  },
  "assets.icons.radio-fill": {
    "value": "/icons/remix-icon.svg#radio-fill",
    "variable": "var(--assets-icons-radio-fill)"
  },
  "assets.icons.radio-line": {
    "value": "/icons/remix-icon.svg#radio-line",
    "variable": "var(--assets-icons-radio-line)"
  },
  "assets.icons.rainbow-fill": {
    "value": "/icons/remix-icon.svg#rainbow-fill",
    "variable": "var(--assets-icons-rainbow-fill)"
  },
  "assets.icons.rainbow-line": {
    "value": "/icons/remix-icon.svg#rainbow-line",
    "variable": "var(--assets-icons-rainbow-line)"
  },
  "assets.icons.rainy-fill": {
    "value": "/icons/remix-icon.svg#rainy-fill",
    "variable": "var(--assets-icons-rainy-fill)"
  },
  "assets.icons.rainy-line": {
    "value": "/icons/remix-icon.svg#rainy-line",
    "variable": "var(--assets-icons-rainy-line)"
  },
  "assets.icons.reactjs-fill": {
    "value": "/icons/remix-icon.svg#reactjs-fill",
    "variable": "var(--assets-icons-reactjs-fill)"
  },
  "assets.icons.reactjs-line": {
    "value": "/icons/remix-icon.svg#reactjs-line",
    "variable": "var(--assets-icons-reactjs-line)"
  },
  "assets.icons.record-circle-fill": {
    "value": "/icons/remix-icon.svg#record-circle-fill",
    "variable": "var(--assets-icons-record-circle-fill)"
  },
  "assets.icons.record-circle-line": {
    "value": "/icons/remix-icon.svg#record-circle-line",
    "variable": "var(--assets-icons-record-circle-line)"
  },
  "assets.icons.record-mail-fill": {
    "value": "/icons/remix-icon.svg#record-mail-fill",
    "variable": "var(--assets-icons-record-mail-fill)"
  },
  "assets.icons.record-mail-line": {
    "value": "/icons/remix-icon.svg#record-mail-line",
    "variable": "var(--assets-icons-record-mail-line)"
  },
  "assets.icons.recycle-fill": {
    "value": "/icons/remix-icon.svg#recycle-fill",
    "variable": "var(--assets-icons-recycle-fill)"
  },
  "assets.icons.recycle-line": {
    "value": "/icons/remix-icon.svg#recycle-line",
    "variable": "var(--assets-icons-recycle-line)"
  },
  "assets.icons.red-packet-fill": {
    "value": "/icons/remix-icon.svg#red-packet-fill",
    "variable": "var(--assets-icons-red-packet-fill)"
  },
  "assets.icons.red-packet-line": {
    "value": "/icons/remix-icon.svg#red-packet-line",
    "variable": "var(--assets-icons-red-packet-line)"
  },
  "assets.icons.reddit-fill": {
    "value": "/icons/remix-icon.svg#reddit-fill",
    "variable": "var(--assets-icons-reddit-fill)"
  },
  "assets.icons.reddit-line": {
    "value": "/icons/remix-icon.svg#reddit-line",
    "variable": "var(--assets-icons-reddit-line)"
  },
  "assets.icons.refresh-fill": {
    "value": "/icons/remix-icon.svg#refresh-fill",
    "variable": "var(--assets-icons-refresh-fill)"
  },
  "assets.icons.refresh-line": {
    "value": "/icons/remix-icon.svg#refresh-line",
    "variable": "var(--assets-icons-refresh-line)"
  },
  "assets.icons.refund-2-fill": {
    "value": "/icons/remix-icon.svg#refund-2-fill",
    "variable": "var(--assets-icons-refund-2-fill)"
  },
  "assets.icons.refund-2-line": {
    "value": "/icons/remix-icon.svg#refund-2-line",
    "variable": "var(--assets-icons-refund-2-line)"
  },
  "assets.icons.refund-fill": {
    "value": "/icons/remix-icon.svg#refund-fill",
    "variable": "var(--assets-icons-refund-fill)"
  },
  "assets.icons.refund-line": {
    "value": "/icons/remix-icon.svg#refund-line",
    "variable": "var(--assets-icons-refund-line)"
  },
  "assets.icons.registered-fill": {
    "value": "/icons/remix-icon.svg#registered-fill",
    "variable": "var(--assets-icons-registered-fill)"
  },
  "assets.icons.registered-line": {
    "value": "/icons/remix-icon.svg#registered-line",
    "variable": "var(--assets-icons-registered-line)"
  },
  "assets.icons.remixicon-fill": {
    "value": "/icons/remix-icon.svg#remixicon-fill",
    "variable": "var(--assets-icons-remixicon-fill)"
  },
  "assets.icons.remixicon-line": {
    "value": "/icons/remix-icon.svg#remixicon-line",
    "variable": "var(--assets-icons-remixicon-line)"
  },
  "assets.icons.remote-control-2-fill": {
    "value": "/icons/remix-icon.svg#remote-control-2-fill",
    "variable": "var(--assets-icons-remote-control-2-fill)"
  },
  "assets.icons.remote-control-2-line": {
    "value": "/icons/remix-icon.svg#remote-control-2-line",
    "variable": "var(--assets-icons-remote-control-2-line)"
  },
  "assets.icons.remote-control-fill": {
    "value": "/icons/remix-icon.svg#remote-control-fill",
    "variable": "var(--assets-icons-remote-control-fill)"
  },
  "assets.icons.remote-control-line": {
    "value": "/icons/remix-icon.svg#remote-control-line",
    "variable": "var(--assets-icons-remote-control-line)"
  },
  "assets.icons.repeat-2-fill": {
    "value": "/icons/remix-icon.svg#repeat-2-fill",
    "variable": "var(--assets-icons-repeat-2-fill)"
  },
  "assets.icons.repeat-2-line": {
    "value": "/icons/remix-icon.svg#repeat-2-line",
    "variable": "var(--assets-icons-repeat-2-line)"
  },
  "assets.icons.repeat-fill": {
    "value": "/icons/remix-icon.svg#repeat-fill",
    "variable": "var(--assets-icons-repeat-fill)"
  },
  "assets.icons.repeat-line": {
    "value": "/icons/remix-icon.svg#repeat-line",
    "variable": "var(--assets-icons-repeat-line)"
  },
  "assets.icons.repeat-one-fill": {
    "value": "/icons/remix-icon.svg#repeat-one-fill",
    "variable": "var(--assets-icons-repeat-one-fill)"
  },
  "assets.icons.repeat-one-line": {
    "value": "/icons/remix-icon.svg#repeat-one-line",
    "variable": "var(--assets-icons-repeat-one-line)"
  },
  "assets.icons.reply-all-fill": {
    "value": "/icons/remix-icon.svg#reply-all-fill",
    "variable": "var(--assets-icons-reply-all-fill)"
  },
  "assets.icons.reply-all-line": {
    "value": "/icons/remix-icon.svg#reply-all-line",
    "variable": "var(--assets-icons-reply-all-line)"
  },
  "assets.icons.reply-fill": {
    "value": "/icons/remix-icon.svg#reply-fill",
    "variable": "var(--assets-icons-reply-fill)"
  },
  "assets.icons.reply-line": {
    "value": "/icons/remix-icon.svg#reply-line",
    "variable": "var(--assets-icons-reply-line)"
  },
  "assets.icons.reserved-fill": {
    "value": "/icons/remix-icon.svg#reserved-fill",
    "variable": "var(--assets-icons-reserved-fill)"
  },
  "assets.icons.reserved-line": {
    "value": "/icons/remix-icon.svg#reserved-line",
    "variable": "var(--assets-icons-reserved-line)"
  },
  "assets.icons.rest-time-fill": {
    "value": "/icons/remix-icon.svg#rest-time-fill",
    "variable": "var(--assets-icons-rest-time-fill)"
  },
  "assets.icons.rest-time-line": {
    "value": "/icons/remix-icon.svg#rest-time-line",
    "variable": "var(--assets-icons-rest-time-line)"
  },
  "assets.icons.restart-fill": {
    "value": "/icons/remix-icon.svg#restart-fill",
    "variable": "var(--assets-icons-restart-fill)"
  },
  "assets.icons.restart-line": {
    "value": "/icons/remix-icon.svg#restart-line",
    "variable": "var(--assets-icons-restart-line)"
  },
  "assets.icons.restaurant-2-fill": {
    "value": "/icons/remix-icon.svg#restaurant-2-fill",
    "variable": "var(--assets-icons-restaurant-2-fill)"
  },
  "assets.icons.restaurant-2-line": {
    "value": "/icons/remix-icon.svg#restaurant-2-line",
    "variable": "var(--assets-icons-restaurant-2-line)"
  },
  "assets.icons.restaurant-fill": {
    "value": "/icons/remix-icon.svg#restaurant-fill",
    "variable": "var(--assets-icons-restaurant-fill)"
  },
  "assets.icons.restaurant-line": {
    "value": "/icons/remix-icon.svg#restaurant-line",
    "variable": "var(--assets-icons-restaurant-line)"
  },
  "assets.icons.rewind-fill": {
    "value": "/icons/remix-icon.svg#rewind-fill",
    "variable": "var(--assets-icons-rewind-fill)"
  },
  "assets.icons.rewind-line": {
    "value": "/icons/remix-icon.svg#rewind-line",
    "variable": "var(--assets-icons-rewind-line)"
  },
  "assets.icons.rewind-mini-fill": {
    "value": "/icons/remix-icon.svg#rewind-mini-fill",
    "variable": "var(--assets-icons-rewind-mini-fill)"
  },
  "assets.icons.rewind-mini-line": {
    "value": "/icons/remix-icon.svg#rewind-mini-line",
    "variable": "var(--assets-icons-rewind-mini-line)"
  },
  "assets.icons.rhythm-fill": {
    "value": "/icons/remix-icon.svg#rhythm-fill",
    "variable": "var(--assets-icons-rhythm-fill)"
  },
  "assets.icons.rhythm-line": {
    "value": "/icons/remix-icon.svg#rhythm-line",
    "variable": "var(--assets-icons-rhythm-line)"
  },
  "assets.icons.riding-fill": {
    "value": "/icons/remix-icon.svg#riding-fill",
    "variable": "var(--assets-icons-riding-fill)"
  },
  "assets.icons.riding-line": {
    "value": "/icons/remix-icon.svg#riding-line",
    "variable": "var(--assets-icons-riding-line)"
  },
  "assets.icons.road-map-fill": {
    "value": "/icons/remix-icon.svg#road-map-fill",
    "variable": "var(--assets-icons-road-map-fill)"
  },
  "assets.icons.road-map-line": {
    "value": "/icons/remix-icon.svg#road-map-line",
    "variable": "var(--assets-icons-road-map-line)"
  },
  "assets.icons.roadster-fill": {
    "value": "/icons/remix-icon.svg#roadster-fill",
    "variable": "var(--assets-icons-roadster-fill)"
  },
  "assets.icons.roadster-line": {
    "value": "/icons/remix-icon.svg#roadster-line",
    "variable": "var(--assets-icons-roadster-line)"
  },
  "assets.icons.robot-fill": {
    "value": "/icons/remix-icon.svg#robot-fill",
    "variable": "var(--assets-icons-robot-fill)"
  },
  "assets.icons.robot-line": {
    "value": "/icons/remix-icon.svg#robot-line",
    "variable": "var(--assets-icons-robot-line)"
  },
  "assets.icons.rocket-2-fill": {
    "value": "/icons/remix-icon.svg#rocket-2-fill",
    "variable": "var(--assets-icons-rocket-2-fill)"
  },
  "assets.icons.rocket-2-line": {
    "value": "/icons/remix-icon.svg#rocket-2-line",
    "variable": "var(--assets-icons-rocket-2-line)"
  },
  "assets.icons.rocket-fill": {
    "value": "/icons/remix-icon.svg#rocket-fill",
    "variable": "var(--assets-icons-rocket-fill)"
  },
  "assets.icons.rocket-line": {
    "value": "/icons/remix-icon.svg#rocket-line",
    "variable": "var(--assets-icons-rocket-line)"
  },
  "assets.icons.rotate-lock-fill": {
    "value": "/icons/remix-icon.svg#rotate-lock-fill",
    "variable": "var(--assets-icons-rotate-lock-fill)"
  },
  "assets.icons.rotate-lock-line": {
    "value": "/icons/remix-icon.svg#rotate-lock-line",
    "variable": "var(--assets-icons-rotate-lock-line)"
  },
  "assets.icons.rounded-corner": {
    "value": "/icons/remix-icon.svg#rounded-corner",
    "variable": "var(--assets-icons-rounded-corner)"
  },
  "assets.icons.route-fill": {
    "value": "/icons/remix-icon.svg#route-fill",
    "variable": "var(--assets-icons-route-fill)"
  },
  "assets.icons.route-line": {
    "value": "/icons/remix-icon.svg#route-line",
    "variable": "var(--assets-icons-route-line)"
  },
  "assets.icons.router-fill": {
    "value": "/icons/remix-icon.svg#router-fill",
    "variable": "var(--assets-icons-router-fill)"
  },
  "assets.icons.router-line": {
    "value": "/icons/remix-icon.svg#router-line",
    "variable": "var(--assets-icons-router-line)"
  },
  "assets.icons.rss-fill": {
    "value": "/icons/remix-icon.svg#rss-fill",
    "variable": "var(--assets-icons-rss-fill)"
  },
  "assets.icons.rss-line": {
    "value": "/icons/remix-icon.svg#rss-line",
    "variable": "var(--assets-icons-rss-line)"
  },
  "assets.icons.ruler-2-fill": {
    "value": "/icons/remix-icon.svg#ruler-2-fill",
    "variable": "var(--assets-icons-ruler-2-fill)"
  },
  "assets.icons.ruler-2-line": {
    "value": "/icons/remix-icon.svg#ruler-2-line",
    "variable": "var(--assets-icons-ruler-2-line)"
  },
  "assets.icons.ruler-fill": {
    "value": "/icons/remix-icon.svg#ruler-fill",
    "variable": "var(--assets-icons-ruler-fill)"
  },
  "assets.icons.ruler-line": {
    "value": "/icons/remix-icon.svg#ruler-line",
    "variable": "var(--assets-icons-ruler-line)"
  },
  "assets.icons.run-fill": {
    "value": "/icons/remix-icon.svg#run-fill",
    "variable": "var(--assets-icons-run-fill)"
  },
  "assets.icons.run-line": {
    "value": "/icons/remix-icon.svg#run-line",
    "variable": "var(--assets-icons-run-line)"
  },
  "assets.icons.safafill": {
    "value": "/icons/remix-icon.svg#safafill",
    "variable": "var(--assets-icons-safafill)"
  },
  "assets.icons.safaline": {
    "value": "/icons/remix-icon.svg#safaline",
    "variable": "var(--assets-icons-safaline)"
  },
  "assets.icons.safe-2-fill": {
    "value": "/icons/remix-icon.svg#safe-2-fill",
    "variable": "var(--assets-icons-safe-2-fill)"
  },
  "assets.icons.safe-2-line": {
    "value": "/icons/remix-icon.svg#safe-2-line",
    "variable": "var(--assets-icons-safe-2-line)"
  },
  "assets.icons.safe-fill": {
    "value": "/icons/remix-icon.svg#safe-fill",
    "variable": "var(--assets-icons-safe-fill)"
  },
  "assets.icons.safe-line": {
    "value": "/icons/remix-icon.svg#safe-line",
    "variable": "var(--assets-icons-safe-line)"
  },
  "assets.icons.sailboat-fill": {
    "value": "/icons/remix-icon.svg#sailboat-fill",
    "variable": "var(--assets-icons-sailboat-fill)"
  },
  "assets.icons.sailboat-line": {
    "value": "/icons/remix-icon.svg#sailboat-line",
    "variable": "var(--assets-icons-sailboat-line)"
  },
  "assets.icons.save-2-fill": {
    "value": "/icons/remix-icon.svg#save-2-fill",
    "variable": "var(--assets-icons-save-2-fill)"
  },
  "assets.icons.save-2-line": {
    "value": "/icons/remix-icon.svg#save-2-line",
    "variable": "var(--assets-icons-save-2-line)"
  },
  "assets.icons.save-3-fill": {
    "value": "/icons/remix-icon.svg#save-3-fill",
    "variable": "var(--assets-icons-save-3-fill)"
  },
  "assets.icons.save-3-line": {
    "value": "/icons/remix-icon.svg#save-3-line",
    "variable": "var(--assets-icons-save-3-line)"
  },
  "assets.icons.save-fill": {
    "value": "/icons/remix-icon.svg#save-fill",
    "variable": "var(--assets-icons-save-fill)"
  },
  "assets.icons.save-line": {
    "value": "/icons/remix-icon.svg#save-line",
    "variable": "var(--assets-icons-save-line)"
  },
  "assets.icons.scales-2-fill": {
    "value": "/icons/remix-icon.svg#scales-2-fill",
    "variable": "var(--assets-icons-scales-2-fill)"
  },
  "assets.icons.scales-2-line": {
    "value": "/icons/remix-icon.svg#scales-2-line",
    "variable": "var(--assets-icons-scales-2-line)"
  },
  "assets.icons.scales-3-fill": {
    "value": "/icons/remix-icon.svg#scales-3-fill",
    "variable": "var(--assets-icons-scales-3-fill)"
  },
  "assets.icons.scales-3-line": {
    "value": "/icons/remix-icon.svg#scales-3-line",
    "variable": "var(--assets-icons-scales-3-line)"
  },
  "assets.icons.scales-fill": {
    "value": "/icons/remix-icon.svg#scales-fill",
    "variable": "var(--assets-icons-scales-fill)"
  },
  "assets.icons.scales-line": {
    "value": "/icons/remix-icon.svg#scales-line",
    "variable": "var(--assets-icons-scales-line)"
  },
  "assets.icons.scan-2-fill": {
    "value": "/icons/remix-icon.svg#scan-2-fill",
    "variable": "var(--assets-icons-scan-2-fill)"
  },
  "assets.icons.scan-2-line": {
    "value": "/icons/remix-icon.svg#scan-2-line",
    "variable": "var(--assets-icons-scan-2-line)"
  },
  "assets.icons.scan-fill": {
    "value": "/icons/remix-icon.svg#scan-fill",
    "variable": "var(--assets-icons-scan-fill)"
  },
  "assets.icons.scan-line": {
    "value": "/icons/remix-icon.svg#scan-line",
    "variable": "var(--assets-icons-scan-line)"
  },
  "assets.icons.scissors-2-fill": {
    "value": "/icons/remix-icon.svg#scissors-2-fill",
    "variable": "var(--assets-icons-scissors-2-fill)"
  },
  "assets.icons.scissors-2-line": {
    "value": "/icons/remix-icon.svg#scissors-2-line",
    "variable": "var(--assets-icons-scissors-2-line)"
  },
  "assets.icons.scissors-cut-fill": {
    "value": "/icons/remix-icon.svg#scissors-cut-fill",
    "variable": "var(--assets-icons-scissors-cut-fill)"
  },
  "assets.icons.scissors-cut-line": {
    "value": "/icons/remix-icon.svg#scissors-cut-line",
    "variable": "var(--assets-icons-scissors-cut-line)"
  },
  "assets.icons.scissors-fill": {
    "value": "/icons/remix-icon.svg#scissors-fill",
    "variable": "var(--assets-icons-scissors-fill)"
  },
  "assets.icons.scissors-line": {
    "value": "/icons/remix-icon.svg#scissors-line",
    "variable": "var(--assets-icons-scissors-line)"
  },
  "assets.icons.screenshot-2-fill": {
    "value": "/icons/remix-icon.svg#screenshot-2-fill",
    "variable": "var(--assets-icons-screenshot-2-fill)"
  },
  "assets.icons.screenshot-2-line": {
    "value": "/icons/remix-icon.svg#screenshot-2-line",
    "variable": "var(--assets-icons-screenshot-2-line)"
  },
  "assets.icons.screenshot-fill": {
    "value": "/icons/remix-icon.svg#screenshot-fill",
    "variable": "var(--assets-icons-screenshot-fill)"
  },
  "assets.icons.screenshot-line": {
    "value": "/icons/remix-icon.svg#screenshot-line",
    "variable": "var(--assets-icons-screenshot-line)"
  },
  "assets.icons.sd-card-fill": {
    "value": "/icons/remix-icon.svg#sd-card-fill",
    "variable": "var(--assets-icons-sd-card-fill)"
  },
  "assets.icons.sd-card-line": {
    "value": "/icons/remix-icon.svg#sd-card-line",
    "variable": "var(--assets-icons-sd-card-line)"
  },
  "assets.icons.sd-card-mini-fill": {
    "value": "/icons/remix-icon.svg#sd-card-mini-fill",
    "variable": "var(--assets-icons-sd-card-mini-fill)"
  },
  "assets.icons.sd-card-mini-line": {
    "value": "/icons/remix-icon.svg#sd-card-mini-line",
    "variable": "var(--assets-icons-sd-card-mini-line)"
  },
  "assets.icons.search-2-fill": {
    "value": "/icons/remix-icon.svg#search-2-fill",
    "variable": "var(--assets-icons-search-2-fill)"
  },
  "assets.icons.search-2-line": {
    "value": "/icons/remix-icon.svg#search-2-line",
    "variable": "var(--assets-icons-search-2-line)"
  },
  "assets.icons.search-eye-fill": {
    "value": "/icons/remix-icon.svg#search-eye-fill",
    "variable": "var(--assets-icons-search-eye-fill)"
  },
  "assets.icons.search-eye-line": {
    "value": "/icons/remix-icon.svg#search-eye-line",
    "variable": "var(--assets-icons-search-eye-line)"
  },
  "assets.icons.search-fill": {
    "value": "/icons/remix-icon.svg#search-fill",
    "variable": "var(--assets-icons-search-fill)"
  },
  "assets.icons.search-line": {
    "value": "/icons/remix-icon.svg#search-line",
    "variable": "var(--assets-icons-search-line)"
  },
  "assets.icons.secure-payment-fill": {
    "value": "/icons/remix-icon.svg#secure-payment-fill",
    "variable": "var(--assets-icons-secure-payment-fill)"
  },
  "assets.icons.secure-payment-line": {
    "value": "/icons/remix-icon.svg#secure-payment-line",
    "variable": "var(--assets-icons-secure-payment-line)"
  },
  "assets.icons.seedling-fill": {
    "value": "/icons/remix-icon.svg#seedling-fill",
    "variable": "var(--assets-icons-seedling-fill)"
  },
  "assets.icons.seedling-line": {
    "value": "/icons/remix-icon.svg#seedling-line",
    "variable": "var(--assets-icons-seedling-line)"
  },
  "assets.icons.send-backward": {
    "value": "/icons/remix-icon.svg#send-backward",
    "variable": "var(--assets-icons-send-backward)"
  },
  "assets.icons.send-plane-2-fill": {
    "value": "/icons/remix-icon.svg#send-plane-2-fill",
    "variable": "var(--assets-icons-send-plane-2-fill)"
  },
  "assets.icons.send-plane-2-line": {
    "value": "/icons/remix-icon.svg#send-plane-2-line",
    "variable": "var(--assets-icons-send-plane-2-line)"
  },
  "assets.icons.send-plane-fill": {
    "value": "/icons/remix-icon.svg#send-plane-fill",
    "variable": "var(--assets-icons-send-plane-fill)"
  },
  "assets.icons.send-plane-line": {
    "value": "/icons/remix-icon.svg#send-plane-line",
    "variable": "var(--assets-icons-send-plane-line)"
  },
  "assets.icons.send-to-back": {
    "value": "/icons/remix-icon.svg#send-to-back",
    "variable": "var(--assets-icons-send-to-back)"
  },
  "assets.icons.sensor-fill": {
    "value": "/icons/remix-icon.svg#sensor-fill",
    "variable": "var(--assets-icons-sensor-fill)"
  },
  "assets.icons.sensor-line": {
    "value": "/icons/remix-icon.svg#sensor-line",
    "variable": "var(--assets-icons-sensor-line)"
  },
  "assets.icons.separator": {
    "value": "/icons/remix-icon.svg#separator",
    "variable": "var(--assets-icons-separator)"
  },
  "assets.icons.server-fill": {
    "value": "/icons/remix-icon.svg#server-fill",
    "variable": "var(--assets-icons-server-fill)"
  },
  "assets.icons.server-line": {
    "value": "/icons/remix-icon.svg#server-line",
    "variable": "var(--assets-icons-server-line)"
  },
  "assets.icons.service-fill": {
    "value": "/icons/remix-icon.svg#service-fill",
    "variable": "var(--assets-icons-service-fill)"
  },
  "assets.icons.service-line": {
    "value": "/icons/remix-icon.svg#service-line",
    "variable": "var(--assets-icons-service-line)"
  },
  "assets.icons.settings-2-fill": {
    "value": "/icons/remix-icon.svg#settings-2-fill",
    "variable": "var(--assets-icons-settings-2-fill)"
  },
  "assets.icons.settings-2-line": {
    "value": "/icons/remix-icon.svg#settings-2-line",
    "variable": "var(--assets-icons-settings-2-line)"
  },
  "assets.icons.settings-3-fill": {
    "value": "/icons/remix-icon.svg#settings-3-fill",
    "variable": "var(--assets-icons-settings-3-fill)"
  },
  "assets.icons.settings-3-line": {
    "value": "/icons/remix-icon.svg#settings-3-line",
    "variable": "var(--assets-icons-settings-3-line)"
  },
  "assets.icons.settings-4-fill": {
    "value": "/icons/remix-icon.svg#settings-4-fill",
    "variable": "var(--assets-icons-settings-4-fill)"
  },
  "assets.icons.settings-4-line": {
    "value": "/icons/remix-icon.svg#settings-4-line",
    "variable": "var(--assets-icons-settings-4-line)"
  },
  "assets.icons.settings-5-fill": {
    "value": "/icons/remix-icon.svg#settings-5-fill",
    "variable": "var(--assets-icons-settings-5-fill)"
  },
  "assets.icons.settings-5-line": {
    "value": "/icons/remix-icon.svg#settings-5-line",
    "variable": "var(--assets-icons-settings-5-line)"
  },
  "assets.icons.settings-6-fill": {
    "value": "/icons/remix-icon.svg#settings-6-fill",
    "variable": "var(--assets-icons-settings-6-fill)"
  },
  "assets.icons.settings-6-line": {
    "value": "/icons/remix-icon.svg#settings-6-line",
    "variable": "var(--assets-icons-settings-6-line)"
  },
  "assets.icons.settings-fill": {
    "value": "/icons/remix-icon.svg#settings-fill",
    "variable": "var(--assets-icons-settings-fill)"
  },
  "assets.icons.settings-line": {
    "value": "/icons/remix-icon.svg#settings-line",
    "variable": "var(--assets-icons-settings-line)"
  },
  "assets.icons.shape-2-fill": {
    "value": "/icons/remix-icon.svg#shape-2-fill",
    "variable": "var(--assets-icons-shape-2-fill)"
  },
  "assets.icons.shape-2-line": {
    "value": "/icons/remix-icon.svg#shape-2-line",
    "variable": "var(--assets-icons-shape-2-line)"
  },
  "assets.icons.shape-fill": {
    "value": "/icons/remix-icon.svg#shape-fill",
    "variable": "var(--assets-icons-shape-fill)"
  },
  "assets.icons.shape-line": {
    "value": "/icons/remix-icon.svg#shape-line",
    "variable": "var(--assets-icons-shape-line)"
  },
  "assets.icons.share-box-fill": {
    "value": "/icons/remix-icon.svg#share-box-fill",
    "variable": "var(--assets-icons-share-box-fill)"
  },
  "assets.icons.share-box-line": {
    "value": "/icons/remix-icon.svg#share-box-line",
    "variable": "var(--assets-icons-share-box-line)"
  },
  "assets.icons.share-circle-fill": {
    "value": "/icons/remix-icon.svg#share-circle-fill",
    "variable": "var(--assets-icons-share-circle-fill)"
  },
  "assets.icons.share-circle-line": {
    "value": "/icons/remix-icon.svg#share-circle-line",
    "variable": "var(--assets-icons-share-circle-line)"
  },
  "assets.icons.share-fill": {
    "value": "/icons/remix-icon.svg#share-fill",
    "variable": "var(--assets-icons-share-fill)"
  },
  "assets.icons.share-forward-2-fill": {
    "value": "/icons/remix-icon.svg#share-forward-2-fill",
    "variable": "var(--assets-icons-share-forward-2-fill)"
  },
  "assets.icons.share-forward-2-line": {
    "value": "/icons/remix-icon.svg#share-forward-2-line",
    "variable": "var(--assets-icons-share-forward-2-line)"
  },
  "assets.icons.share-forward-box-fill": {
    "value": "/icons/remix-icon.svg#share-forward-box-fill",
    "variable": "var(--assets-icons-share-forward-box-fill)"
  },
  "assets.icons.share-forward-box-line": {
    "value": "/icons/remix-icon.svg#share-forward-box-line",
    "variable": "var(--assets-icons-share-forward-box-line)"
  },
  "assets.icons.share-forward-fill": {
    "value": "/icons/remix-icon.svg#share-forward-fill",
    "variable": "var(--assets-icons-share-forward-fill)"
  },
  "assets.icons.share-forward-line": {
    "value": "/icons/remix-icon.svg#share-forward-line",
    "variable": "var(--assets-icons-share-forward-line)"
  },
  "assets.icons.share-line": {
    "value": "/icons/remix-icon.svg#share-line",
    "variable": "var(--assets-icons-share-line)"
  },
  "assets.icons.shield-check-fill": {
    "value": "/icons/remix-icon.svg#shield-check-fill",
    "variable": "var(--assets-icons-shield-check-fill)"
  },
  "assets.icons.shield-check-line": {
    "value": "/icons/remix-icon.svg#shield-check-line",
    "variable": "var(--assets-icons-shield-check-line)"
  },
  "assets.icons.shield-cross-fill": {
    "value": "/icons/remix-icon.svg#shield-cross-fill",
    "variable": "var(--assets-icons-shield-cross-fill)"
  },
  "assets.icons.shield-cross-line": {
    "value": "/icons/remix-icon.svg#shield-cross-line",
    "variable": "var(--assets-icons-shield-cross-line)"
  },
  "assets.icons.shield-fill": {
    "value": "/icons/remix-icon.svg#shield-fill",
    "variable": "var(--assets-icons-shield-fill)"
  },
  "assets.icons.shield-flash-fill": {
    "value": "/icons/remix-icon.svg#shield-flash-fill",
    "variable": "var(--assets-icons-shield-flash-fill)"
  },
  "assets.icons.shield-flash-line": {
    "value": "/icons/remix-icon.svg#shield-flash-line",
    "variable": "var(--assets-icons-shield-flash-line)"
  },
  "assets.icons.shield-keyhole-fill": {
    "value": "/icons/remix-icon.svg#shield-keyhole-fill",
    "variable": "var(--assets-icons-shield-keyhole-fill)"
  },
  "assets.icons.shield-keyhole-line": {
    "value": "/icons/remix-icon.svg#shield-keyhole-line",
    "variable": "var(--assets-icons-shield-keyhole-line)"
  },
  "assets.icons.shield-line": {
    "value": "/icons/remix-icon.svg#shield-line",
    "variable": "var(--assets-icons-shield-line)"
  },
  "assets.icons.shield-star-fill": {
    "value": "/icons/remix-icon.svg#shield-star-fill",
    "variable": "var(--assets-icons-shield-star-fill)"
  },
  "assets.icons.shield-star-line": {
    "value": "/icons/remix-icon.svg#shield-star-line",
    "variable": "var(--assets-icons-shield-star-line)"
  },
  "assets.icons.shield-user-fill": {
    "value": "/icons/remix-icon.svg#shield-user-fill",
    "variable": "var(--assets-icons-shield-user-fill)"
  },
  "assets.icons.shield-user-line": {
    "value": "/icons/remix-icon.svg#shield-user-line",
    "variable": "var(--assets-icons-shield-user-line)"
  },
  "assets.icons.ship-2-fill": {
    "value": "/icons/remix-icon.svg#ship-2-fill",
    "variable": "var(--assets-icons-ship-2-fill)"
  },
  "assets.icons.ship-2-line": {
    "value": "/icons/remix-icon.svg#ship-2-line",
    "variable": "var(--assets-icons-ship-2-line)"
  },
  "assets.icons.ship-fill": {
    "value": "/icons/remix-icon.svg#ship-fill",
    "variable": "var(--assets-icons-ship-fill)"
  },
  "assets.icons.ship-line": {
    "value": "/icons/remix-icon.svg#ship-line",
    "variable": "var(--assets-icons-ship-line)"
  },
  "assets.icons.shirt-fill": {
    "value": "/icons/remix-icon.svg#shirt-fill",
    "variable": "var(--assets-icons-shirt-fill)"
  },
  "assets.icons.shirt-line": {
    "value": "/icons/remix-icon.svg#shirt-line",
    "variable": "var(--assets-icons-shirt-line)"
  },
  "assets.icons.shopping-bag-2-fill": {
    "value": "/icons/remix-icon.svg#shopping-bag-2-fill",
    "variable": "var(--assets-icons-shopping-bag-2-fill)"
  },
  "assets.icons.shopping-bag-2-line": {
    "value": "/icons/remix-icon.svg#shopping-bag-2-line",
    "variable": "var(--assets-icons-shopping-bag-2-line)"
  },
  "assets.icons.shopping-bag-3-fill": {
    "value": "/icons/remix-icon.svg#shopping-bag-3-fill",
    "variable": "var(--assets-icons-shopping-bag-3-fill)"
  },
  "assets.icons.shopping-bag-3-line": {
    "value": "/icons/remix-icon.svg#shopping-bag-3-line",
    "variable": "var(--assets-icons-shopping-bag-3-line)"
  },
  "assets.icons.shopping-bag-fill": {
    "value": "/icons/remix-icon.svg#shopping-bag-fill",
    "variable": "var(--assets-icons-shopping-bag-fill)"
  },
  "assets.icons.shopping-bag-line": {
    "value": "/icons/remix-icon.svg#shopping-bag-line",
    "variable": "var(--assets-icons-shopping-bag-line)"
  },
  "assets.icons.shopping-basket-2-fill": {
    "value": "/icons/remix-icon.svg#shopping-basket-2-fill",
    "variable": "var(--assets-icons-shopping-basket-2-fill)"
  },
  "assets.icons.shopping-basket-2-line": {
    "value": "/icons/remix-icon.svg#shopping-basket-2-line",
    "variable": "var(--assets-icons-shopping-basket-2-line)"
  },
  "assets.icons.shopping-basket-fill": {
    "value": "/icons/remix-icon.svg#shopping-basket-fill",
    "variable": "var(--assets-icons-shopping-basket-fill)"
  },
  "assets.icons.shopping-basket-line": {
    "value": "/icons/remix-icon.svg#shopping-basket-line",
    "variable": "var(--assets-icons-shopping-basket-line)"
  },
  "assets.icons.shopping-cart-2-fill": {
    "value": "/icons/remix-icon.svg#shopping-cart-2-fill",
    "variable": "var(--assets-icons-shopping-cart-2-fill)"
  },
  "assets.icons.shopping-cart-2-line": {
    "value": "/icons/remix-icon.svg#shopping-cart-2-line",
    "variable": "var(--assets-icons-shopping-cart-2-line)"
  },
  "assets.icons.shopping-cart-fill": {
    "value": "/icons/remix-icon.svg#shopping-cart-fill",
    "variable": "var(--assets-icons-shopping-cart-fill)"
  },
  "assets.icons.shopping-cart-line": {
    "value": "/icons/remix-icon.svg#shopping-cart-line",
    "variable": "var(--assets-icons-shopping-cart-line)"
  },
  "assets.icons.showers-fill": {
    "value": "/icons/remix-icon.svg#showers-fill",
    "variable": "var(--assets-icons-showers-fill)"
  },
  "assets.icons.showers-line": {
    "value": "/icons/remix-icon.svg#showers-line",
    "variable": "var(--assets-icons-showers-line)"
  },
  "assets.icons.shuffle-fill": {
    "value": "/icons/remix-icon.svg#shuffle-fill",
    "variable": "var(--assets-icons-shuffle-fill)"
  },
  "assets.icons.shuffle-line": {
    "value": "/icons/remix-icon.svg#shuffle-line",
    "variable": "var(--assets-icons-shuffle-line)"
  },
  "assets.icons.shut-down-fill": {
    "value": "/icons/remix-icon.svg#shut-down-fill",
    "variable": "var(--assets-icons-shut-down-fill)"
  },
  "assets.icons.shut-down-line": {
    "value": "/icons/remix-icon.svg#shut-down-line",
    "variable": "var(--assets-icons-shut-down-line)"
  },
  "assets.icons.side-bar-fill": {
    "value": "/icons/remix-icon.svg#side-bar-fill",
    "variable": "var(--assets-icons-side-bar-fill)"
  },
  "assets.icons.side-bar-line": {
    "value": "/icons/remix-icon.svg#side-bar-line",
    "variable": "var(--assets-icons-side-bar-line)"
  },
  "assets.icons.signal-tower-fill": {
    "value": "/icons/remix-icon.svg#signal-tower-fill",
    "variable": "var(--assets-icons-signal-tower-fill)"
  },
  "assets.icons.signal-tower-line": {
    "value": "/icons/remix-icon.svg#signal-tower-line",
    "variable": "var(--assets-icons-signal-tower-line)"
  },
  "assets.icons.signal-wifi-1-fill": {
    "value": "/icons/remix-icon.svg#signal-wifi-1-fill",
    "variable": "var(--assets-icons-signal-wifi-1-fill)"
  },
  "assets.icons.signal-wifi-1-line": {
    "value": "/icons/remix-icon.svg#signal-wifi-1-line",
    "variable": "var(--assets-icons-signal-wifi-1-line)"
  },
  "assets.icons.signal-wifi-2-fill": {
    "value": "/icons/remix-icon.svg#signal-wifi-2-fill",
    "variable": "var(--assets-icons-signal-wifi-2-fill)"
  },
  "assets.icons.signal-wifi-2-line": {
    "value": "/icons/remix-icon.svg#signal-wifi-2-line",
    "variable": "var(--assets-icons-signal-wifi-2-line)"
  },
  "assets.icons.signal-wifi-3-fill": {
    "value": "/icons/remix-icon.svg#signal-wifi-3-fill",
    "variable": "var(--assets-icons-signal-wifi-3-fill)"
  },
  "assets.icons.signal-wifi-3-line": {
    "value": "/icons/remix-icon.svg#signal-wifi-3-line",
    "variable": "var(--assets-icons-signal-wifi-3-line)"
  },
  "assets.icons.signal-wifi-error-fill": {
    "value": "/icons/remix-icon.svg#signal-wifi-error-fill",
    "variable": "var(--assets-icons-signal-wifi-error-fill)"
  },
  "assets.icons.signal-wifi-error-line": {
    "value": "/icons/remix-icon.svg#signal-wifi-error-line",
    "variable": "var(--assets-icons-signal-wifi-error-line)"
  },
  "assets.icons.signal-wifi-fill": {
    "value": "/icons/remix-icon.svg#signal-wifi-fill",
    "variable": "var(--assets-icons-signal-wifi-fill)"
  },
  "assets.icons.signal-wifi-line": {
    "value": "/icons/remix-icon.svg#signal-wifi-line",
    "variable": "var(--assets-icons-signal-wifi-line)"
  },
  "assets.icons.signal-wifi-off-fill": {
    "value": "/icons/remix-icon.svg#signal-wifi-off-fill",
    "variable": "var(--assets-icons-signal-wifi-off-fill)"
  },
  "assets.icons.signal-wifi-off-line": {
    "value": "/icons/remix-icon.svg#signal-wifi-off-line",
    "variable": "var(--assets-icons-signal-wifi-off-line)"
  },
  "assets.icons.sim-card-2-fill": {
    "value": "/icons/remix-icon.svg#sim-card-2-fill",
    "variable": "var(--assets-icons-sim-card-2-fill)"
  },
  "assets.icons.sim-card-2-line": {
    "value": "/icons/remix-icon.svg#sim-card-2-line",
    "variable": "var(--assets-icons-sim-card-2-line)"
  },
  "assets.icons.sim-card-fill": {
    "value": "/icons/remix-icon.svg#sim-card-fill",
    "variable": "var(--assets-icons-sim-card-fill)"
  },
  "assets.icons.sim-card-line": {
    "value": "/icons/remix-icon.svg#sim-card-line",
    "variable": "var(--assets-icons-sim-card-line)"
  },
  "assets.icons.single-quotes-l": {
    "value": "/icons/remix-icon.svg#single-quotes-l",
    "variable": "var(--assets-icons-single-quotes-l)"
  },
  "assets.icons.single-quotes-r": {
    "value": "/icons/remix-icon.svg#single-quotes-r",
    "variable": "var(--assets-icons-single-quotes-r)"
  },
  "assets.icons.sip-fill": {
    "value": "/icons/remix-icon.svg#sip-fill",
    "variable": "var(--assets-icons-sip-fill)"
  },
  "assets.icons.sip-line": {
    "value": "/icons/remix-icon.svg#sip-line",
    "variable": "var(--assets-icons-sip-line)"
  },
  "assets.icons.skip-back-fill": {
    "value": "/icons/remix-icon.svg#skip-back-fill",
    "variable": "var(--assets-icons-skip-back-fill)"
  },
  "assets.icons.skip-back-line": {
    "value": "/icons/remix-icon.svg#skip-back-line",
    "variable": "var(--assets-icons-skip-back-line)"
  },
  "assets.icons.skip-back-mini-fill": {
    "value": "/icons/remix-icon.svg#skip-back-mini-fill",
    "variable": "var(--assets-icons-skip-back-mini-fill)"
  },
  "assets.icons.skip-back-mini-line": {
    "value": "/icons/remix-icon.svg#skip-back-mini-line",
    "variable": "var(--assets-icons-skip-back-mini-line)"
  },
  "assets.icons.skip-forward-fill": {
    "value": "/icons/remix-icon.svg#skip-forward-fill",
    "variable": "var(--assets-icons-skip-forward-fill)"
  },
  "assets.icons.skip-forward-line": {
    "value": "/icons/remix-icon.svg#skip-forward-line",
    "variable": "var(--assets-icons-skip-forward-line)"
  },
  "assets.icons.skip-forward-mini-fill": {
    "value": "/icons/remix-icon.svg#skip-forward-mini-fill",
    "variable": "var(--assets-icons-skip-forward-mini-fill)"
  },
  "assets.icons.skip-forward-mini-line": {
    "value": "/icons/remix-icon.svg#skip-forward-mini-line",
    "variable": "var(--assets-icons-skip-forward-mini-line)"
  },
  "assets.icons.skull-2-fill": {
    "value": "/icons/remix-icon.svg#skull-2-fill",
    "variable": "var(--assets-icons-skull-2-fill)"
  },
  "assets.icons.skull-2-line": {
    "value": "/icons/remix-icon.svg#skull-2-line",
    "variable": "var(--assets-icons-skull-2-line)"
  },
  "assets.icons.skull-fill": {
    "value": "/icons/remix-icon.svg#skull-fill",
    "variable": "var(--assets-icons-skull-fill)"
  },
  "assets.icons.skull-line": {
    "value": "/icons/remix-icon.svg#skull-line",
    "variable": "var(--assets-icons-skull-line)"
  },
  "assets.icons.skype-fill": {
    "value": "/icons/remix-icon.svg#skype-fill",
    "variable": "var(--assets-icons-skype-fill)"
  },
  "assets.icons.skype-line": {
    "value": "/icons/remix-icon.svg#skype-line",
    "variable": "var(--assets-icons-skype-line)"
  },
  "assets.icons.slack-fill": {
    "value": "/icons/remix-icon.svg#slack-fill",
    "variable": "var(--assets-icons-slack-fill)"
  },
  "assets.icons.slack-line": {
    "value": "/icons/remix-icon.svg#slack-line",
    "variable": "var(--assets-icons-slack-line)"
  },
  "assets.icons.slice-fill": {
    "value": "/icons/remix-icon.svg#slice-fill",
    "variable": "var(--assets-icons-slice-fill)"
  },
  "assets.icons.slice-line": {
    "value": "/icons/remix-icon.svg#slice-line",
    "variable": "var(--assets-icons-slice-line)"
  },
  "assets.icons.slideshow-2-fill": {
    "value": "/icons/remix-icon.svg#slideshow-2-fill",
    "variable": "var(--assets-icons-slideshow-2-fill)"
  },
  "assets.icons.slideshow-2-line": {
    "value": "/icons/remix-icon.svg#slideshow-2-line",
    "variable": "var(--assets-icons-slideshow-2-line)"
  },
  "assets.icons.slideshow-3-fill": {
    "value": "/icons/remix-icon.svg#slideshow-3-fill",
    "variable": "var(--assets-icons-slideshow-3-fill)"
  },
  "assets.icons.slideshow-3-line": {
    "value": "/icons/remix-icon.svg#slideshow-3-line",
    "variable": "var(--assets-icons-slideshow-3-line)"
  },
  "assets.icons.slideshow-4-fill": {
    "value": "/icons/remix-icon.svg#slideshow-4-fill",
    "variable": "var(--assets-icons-slideshow-4-fill)"
  },
  "assets.icons.slideshow-4-line": {
    "value": "/icons/remix-icon.svg#slideshow-4-line",
    "variable": "var(--assets-icons-slideshow-4-line)"
  },
  "assets.icons.slideshow-fill": {
    "value": "/icons/remix-icon.svg#slideshow-fill",
    "variable": "var(--assets-icons-slideshow-fill)"
  },
  "assets.icons.slideshow-line": {
    "value": "/icons/remix-icon.svg#slideshow-line",
    "variable": "var(--assets-icons-slideshow-line)"
  },
  "assets.icons.smartphone-fill": {
    "value": "/icons/remix-icon.svg#smartphone-fill",
    "variable": "var(--assets-icons-smartphone-fill)"
  },
  "assets.icons.smartphone-line": {
    "value": "/icons/remix-icon.svg#smartphone-line",
    "variable": "var(--assets-icons-smartphone-line)"
  },
  "assets.icons.snapchat-fill": {
    "value": "/icons/remix-icon.svg#snapchat-fill",
    "variable": "var(--assets-icons-snapchat-fill)"
  },
  "assets.icons.snapchat-line": {
    "value": "/icons/remix-icon.svg#snapchat-line",
    "variable": "var(--assets-icons-snapchat-line)"
  },
  "assets.icons.snowy-fill": {
    "value": "/icons/remix-icon.svg#snowy-fill",
    "variable": "var(--assets-icons-snowy-fill)"
  },
  "assets.icons.snowy-line": {
    "value": "/icons/remix-icon.svg#snowy-line",
    "variable": "var(--assets-icons-snowy-line)"
  },
  "assets.icons.sort-asc": {
    "value": "/icons/remix-icon.svg#sort-asc",
    "variable": "var(--assets-icons-sort-asc)"
  },
  "assets.icons.sort-desc": {
    "value": "/icons/remix-icon.svg#sort-desc",
    "variable": "var(--assets-icons-sort-desc)"
  },
  "assets.icons.sound-module-fill": {
    "value": "/icons/remix-icon.svg#sound-module-fill",
    "variable": "var(--assets-icons-sound-module-fill)"
  },
  "assets.icons.sound-module-line": {
    "value": "/icons/remix-icon.svg#sound-module-line",
    "variable": "var(--assets-icons-sound-module-line)"
  },
  "assets.icons.soundcloud-fill": {
    "value": "/icons/remix-icon.svg#soundcloud-fill",
    "variable": "var(--assets-icons-soundcloud-fill)"
  },
  "assets.icons.soundcloud-line": {
    "value": "/icons/remix-icon.svg#soundcloud-line",
    "variable": "var(--assets-icons-soundcloud-line)"
  },
  "assets.icons.space-ship-fill": {
    "value": "/icons/remix-icon.svg#space-ship-fill",
    "variable": "var(--assets-icons-space-ship-fill)"
  },
  "assets.icons.space-ship-line": {
    "value": "/icons/remix-icon.svg#space-ship-line",
    "variable": "var(--assets-icons-space-ship-line)"
  },
  "assets.icons.space": {
    "value": "/icons/remix-icon.svg#space",
    "variable": "var(--assets-icons-space)"
  },
  "assets.icons.spam-2-fill": {
    "value": "/icons/remix-icon.svg#spam-2-fill",
    "variable": "var(--assets-icons-spam-2-fill)"
  },
  "assets.icons.spam-2-line": {
    "value": "/icons/remix-icon.svg#spam-2-line",
    "variable": "var(--assets-icons-spam-2-line)"
  },
  "assets.icons.spam-3-fill": {
    "value": "/icons/remix-icon.svg#spam-3-fill",
    "variable": "var(--assets-icons-spam-3-fill)"
  },
  "assets.icons.spam-3-line": {
    "value": "/icons/remix-icon.svg#spam-3-line",
    "variable": "var(--assets-icons-spam-3-line)"
  },
  "assets.icons.spam-fill": {
    "value": "/icons/remix-icon.svg#spam-fill",
    "variable": "var(--assets-icons-spam-fill)"
  },
  "assets.icons.spam-line": {
    "value": "/icons/remix-icon.svg#spam-line",
    "variable": "var(--assets-icons-spam-line)"
  },
  "assets.icons.speaker-2-fill": {
    "value": "/icons/remix-icon.svg#speaker-2-fill",
    "variable": "var(--assets-icons-speaker-2-fill)"
  },
  "assets.icons.speaker-2-line": {
    "value": "/icons/remix-icon.svg#speaker-2-line",
    "variable": "var(--assets-icons-speaker-2-line)"
  },
  "assets.icons.speaker-3-fill": {
    "value": "/icons/remix-icon.svg#speaker-3-fill",
    "variable": "var(--assets-icons-speaker-3-fill)"
  },
  "assets.icons.speaker-3-line": {
    "value": "/icons/remix-icon.svg#speaker-3-line",
    "variable": "var(--assets-icons-speaker-3-line)"
  },
  "assets.icons.speaker-fill": {
    "value": "/icons/remix-icon.svg#speaker-fill",
    "variable": "var(--assets-icons-speaker-fill)"
  },
  "assets.icons.speaker-line": {
    "value": "/icons/remix-icon.svg#speaker-line",
    "variable": "var(--assets-icons-speaker-line)"
  },
  "assets.icons.spectrum-fill": {
    "value": "/icons/remix-icon.svg#spectrum-fill",
    "variable": "var(--assets-icons-spectrum-fill)"
  },
  "assets.icons.spectrum-line": {
    "value": "/icons/remix-icon.svg#spectrum-line",
    "variable": "var(--assets-icons-spectrum-line)"
  },
  "assets.icons.speed-fill": {
    "value": "/icons/remix-icon.svg#speed-fill",
    "variable": "var(--assets-icons-speed-fill)"
  },
  "assets.icons.speed-line": {
    "value": "/icons/remix-icon.svg#speed-line",
    "variable": "var(--assets-icons-speed-line)"
  },
  "assets.icons.speed-mini-fill": {
    "value": "/icons/remix-icon.svg#speed-mini-fill",
    "variable": "var(--assets-icons-speed-mini-fill)"
  },
  "assets.icons.speed-mini-line": {
    "value": "/icons/remix-icon.svg#speed-mini-line",
    "variable": "var(--assets-icons-speed-mini-line)"
  },
  "assets.icons.split-cells-horizontal": {
    "value": "/icons/remix-icon.svg#split-cells-horizontal",
    "variable": "var(--assets-icons-split-cells-horizontal)"
  },
  "assets.icons.split-cells-vertical": {
    "value": "/icons/remix-icon.svg#split-cells-vertical",
    "variable": "var(--assets-icons-split-cells-vertical)"
  },
  "assets.icons.spotify-fill": {
    "value": "/icons/remix-icon.svg#spotify-fill",
    "variable": "var(--assets-icons-spotify-fill)"
  },
  "assets.icons.spotify-line": {
    "value": "/icons/remix-icon.svg#spotify-line",
    "variable": "var(--assets-icons-spotify-line)"
  },
  "assets.icons.spy-fill": {
    "value": "/icons/remix-icon.svg#spy-fill",
    "variable": "var(--assets-icons-spy-fill)"
  },
  "assets.icons.spy-line": {
    "value": "/icons/remix-icon.svg#spy-line",
    "variable": "var(--assets-icons-spy-line)"
  },
  "assets.icons.stack-fill": {
    "value": "/icons/remix-icon.svg#stack-fill",
    "variable": "var(--assets-icons-stack-fill)"
  },
  "assets.icons.stack-line": {
    "value": "/icons/remix-icon.svg#stack-line",
    "variable": "var(--assets-icons-stack-line)"
  },
  "assets.icons.stack-overflow-fill": {
    "value": "/icons/remix-icon.svg#stack-overflow-fill",
    "variable": "var(--assets-icons-stack-overflow-fill)"
  },
  "assets.icons.stack-overflow-line": {
    "value": "/icons/remix-icon.svg#stack-overflow-line",
    "variable": "var(--assets-icons-stack-overflow-line)"
  },
  "assets.icons.stackshare-fill": {
    "value": "/icons/remix-icon.svg#stackshare-fill",
    "variable": "var(--assets-icons-stackshare-fill)"
  },
  "assets.icons.stackshare-line": {
    "value": "/icons/remix-icon.svg#stackshare-line",
    "variable": "var(--assets-icons-stackshare-line)"
  },
  "assets.icons.star-fill": {
    "value": "/icons/remix-icon.svg#star-fill",
    "variable": "var(--assets-icons-star-fill)"
  },
  "assets.icons.star-half-fill": {
    "value": "/icons/remix-icon.svg#star-half-fill",
    "variable": "var(--assets-icons-star-half-fill)"
  },
  "assets.icons.star-half-line": {
    "value": "/icons/remix-icon.svg#star-half-line",
    "variable": "var(--assets-icons-star-half-line)"
  },
  "assets.icons.star-half-s-fill": {
    "value": "/icons/remix-icon.svg#star-half-s-fill",
    "variable": "var(--assets-icons-star-half-s-fill)"
  },
  "assets.icons.star-half-s-line": {
    "value": "/icons/remix-icon.svg#star-half-s-line",
    "variable": "var(--assets-icons-star-half-s-line)"
  },
  "assets.icons.star-line": {
    "value": "/icons/remix-icon.svg#star-line",
    "variable": "var(--assets-icons-star-line)"
  },
  "assets.icons.star-s-fill": {
    "value": "/icons/remix-icon.svg#star-s-fill",
    "variable": "var(--assets-icons-star-s-fill)"
  },
  "assets.icons.star-s-line": {
    "value": "/icons/remix-icon.svg#star-s-line",
    "variable": "var(--assets-icons-star-s-line)"
  },
  "assets.icons.star-smile-fill": {
    "value": "/icons/remix-icon.svg#star-smile-fill",
    "variable": "var(--assets-icons-star-smile-fill)"
  },
  "assets.icons.star-smile-line": {
    "value": "/icons/remix-icon.svg#star-smile-line",
    "variable": "var(--assets-icons-star-smile-line)"
  },
  "assets.icons.steam-fill": {
    "value": "/icons/remix-icon.svg#steam-fill",
    "variable": "var(--assets-icons-steam-fill)"
  },
  "assets.icons.steam-line": {
    "value": "/icons/remix-icon.svg#steam-line",
    "variable": "var(--assets-icons-steam-line)"
  },
  "assets.icons.steering-2-fill": {
    "value": "/icons/remix-icon.svg#steering-2-fill",
    "variable": "var(--assets-icons-steering-2-fill)"
  },
  "assets.icons.steering-2-line": {
    "value": "/icons/remix-icon.svg#steering-2-line",
    "variable": "var(--assets-icons-steering-2-line)"
  },
  "assets.icons.steering-fill": {
    "value": "/icons/remix-icon.svg#steering-fill",
    "variable": "var(--assets-icons-steering-fill)"
  },
  "assets.icons.steering-line": {
    "value": "/icons/remix-icon.svg#steering-line",
    "variable": "var(--assets-icons-steering-line)"
  },
  "assets.icons.stethoscope-fill": {
    "value": "/icons/remix-icon.svg#stethoscope-fill",
    "variable": "var(--assets-icons-stethoscope-fill)"
  },
  "assets.icons.stethoscope-line": {
    "value": "/icons/remix-icon.svg#stethoscope-line",
    "variable": "var(--assets-icons-stethoscope-line)"
  },
  "assets.icons.sticky-note-2-fill": {
    "value": "/icons/remix-icon.svg#sticky-note-2-fill",
    "variable": "var(--assets-icons-sticky-note-2-fill)"
  },
  "assets.icons.sticky-note-2-line": {
    "value": "/icons/remix-icon.svg#sticky-note-2-line",
    "variable": "var(--assets-icons-sticky-note-2-line)"
  },
  "assets.icons.sticky-note-fill": {
    "value": "/icons/remix-icon.svg#sticky-note-fill",
    "variable": "var(--assets-icons-sticky-note-fill)"
  },
  "assets.icons.sticky-note-line": {
    "value": "/icons/remix-icon.svg#sticky-note-line",
    "variable": "var(--assets-icons-sticky-note-line)"
  },
  "assets.icons.stock-fill": {
    "value": "/icons/remix-icon.svg#stock-fill",
    "variable": "var(--assets-icons-stock-fill)"
  },
  "assets.icons.stock-line": {
    "value": "/icons/remix-icon.svg#stock-line",
    "variable": "var(--assets-icons-stock-line)"
  },
  "assets.icons.stop-circle-fill": {
    "value": "/icons/remix-icon.svg#stop-circle-fill",
    "variable": "var(--assets-icons-stop-circle-fill)"
  },
  "assets.icons.stop-circle-line": {
    "value": "/icons/remix-icon.svg#stop-circle-line",
    "variable": "var(--assets-icons-stop-circle-line)"
  },
  "assets.icons.stop-fill": {
    "value": "/icons/remix-icon.svg#stop-fill",
    "variable": "var(--assets-icons-stop-fill)"
  },
  "assets.icons.stop-line": {
    "value": "/icons/remix-icon.svg#stop-line",
    "variable": "var(--assets-icons-stop-line)"
  },
  "assets.icons.stop-mini-fill": {
    "value": "/icons/remix-icon.svg#stop-mini-fill",
    "variable": "var(--assets-icons-stop-mini-fill)"
  },
  "assets.icons.stop-mini-line": {
    "value": "/icons/remix-icon.svg#stop-mini-line",
    "variable": "var(--assets-icons-stop-mini-line)"
  },
  "assets.icons.store-2-fill": {
    "value": "/icons/remix-icon.svg#store-2-fill",
    "variable": "var(--assets-icons-store-2-fill)"
  },
  "assets.icons.store-2-line": {
    "value": "/icons/remix-icon.svg#store-2-line",
    "variable": "var(--assets-icons-store-2-line)"
  },
  "assets.icons.store-3-fill": {
    "value": "/icons/remix-icon.svg#store-3-fill",
    "variable": "var(--assets-icons-store-3-fill)"
  },
  "assets.icons.store-3-line": {
    "value": "/icons/remix-icon.svg#store-3-line",
    "variable": "var(--assets-icons-store-3-line)"
  },
  "assets.icons.store-fill": {
    "value": "/icons/remix-icon.svg#store-fill",
    "variable": "var(--assets-icons-store-fill)"
  },
  "assets.icons.store-line": {
    "value": "/icons/remix-icon.svg#store-line",
    "variable": "var(--assets-icons-store-line)"
  },
  "assets.icons.strikethrough-2": {
    "value": "/icons/remix-icon.svg#strikethrough-2",
    "variable": "var(--assets-icons-strikethrough-2)"
  },
  "assets.icons.strikethrough": {
    "value": "/icons/remix-icon.svg#strikethrough",
    "variable": "var(--assets-icons-strikethrough)"
  },
  "assets.icons.subscript-2": {
    "value": "/icons/remix-icon.svg#subscript-2",
    "variable": "var(--assets-icons-subscript-2)"
  },
  "assets.icons.subscript": {
    "value": "/icons/remix-icon.svg#subscript",
    "variable": "var(--assets-icons-subscript)"
  },
  "assets.icons.subtract-fill": {
    "value": "/icons/remix-icon.svg#subtract-fill",
    "variable": "var(--assets-icons-subtract-fill)"
  },
  "assets.icons.subtract-line": {
    "value": "/icons/remix-icon.svg#subtract-line",
    "variable": "var(--assets-icons-subtract-line)"
  },
  "assets.icons.subway-fill": {
    "value": "/icons/remix-icon.svg#subway-fill",
    "variable": "var(--assets-icons-subway-fill)"
  },
  "assets.icons.subway-line": {
    "value": "/icons/remix-icon.svg#subway-line",
    "variable": "var(--assets-icons-subway-line)"
  },
  "assets.icons.subway-wifi-fill": {
    "value": "/icons/remix-icon.svg#subway-wifi-fill",
    "variable": "var(--assets-icons-subway-wifi-fill)"
  },
  "assets.icons.subway-wifi-line": {
    "value": "/icons/remix-icon.svg#subway-wifi-line",
    "variable": "var(--assets-icons-subway-wifi-line)"
  },
  "assets.icons.suitcase-2-fill": {
    "value": "/icons/remix-icon.svg#suitcase-2-fill",
    "variable": "var(--assets-icons-suitcase-2-fill)"
  },
  "assets.icons.suitcase-2-line": {
    "value": "/icons/remix-icon.svg#suitcase-2-line",
    "variable": "var(--assets-icons-suitcase-2-line)"
  },
  "assets.icons.suitcase-3-fill": {
    "value": "/icons/remix-icon.svg#suitcase-3-fill",
    "variable": "var(--assets-icons-suitcase-3-fill)"
  },
  "assets.icons.suitcase-3-line": {
    "value": "/icons/remix-icon.svg#suitcase-3-line",
    "variable": "var(--assets-icons-suitcase-3-line)"
  },
  "assets.icons.suitcase-fill": {
    "value": "/icons/remix-icon.svg#suitcase-fill",
    "variable": "var(--assets-icons-suitcase-fill)"
  },
  "assets.icons.suitcase-line": {
    "value": "/icons/remix-icon.svg#suitcase-line",
    "variable": "var(--assets-icons-suitcase-line)"
  },
  "assets.icons.sun-cloudy-fill": {
    "value": "/icons/remix-icon.svg#sun-cloudy-fill",
    "variable": "var(--assets-icons-sun-cloudy-fill)"
  },
  "assets.icons.sun-cloudy-line": {
    "value": "/icons/remix-icon.svg#sun-cloudy-line",
    "variable": "var(--assets-icons-sun-cloudy-line)"
  },
  "assets.icons.sun-fill": {
    "value": "/icons/remix-icon.svg#sun-fill",
    "variable": "var(--assets-icons-sun-fill)"
  },
  "assets.icons.sun-foggy-fill": {
    "value": "/icons/remix-icon.svg#sun-foggy-fill",
    "variable": "var(--assets-icons-sun-foggy-fill)"
  },
  "assets.icons.sun-foggy-line": {
    "value": "/icons/remix-icon.svg#sun-foggy-line",
    "variable": "var(--assets-icons-sun-foggy-line)"
  },
  "assets.icons.sun-line": {
    "value": "/icons/remix-icon.svg#sun-line",
    "variable": "var(--assets-icons-sun-line)"
  },
  "assets.icons.superscript-2": {
    "value": "/icons/remix-icon.svg#superscript-2",
    "variable": "var(--assets-icons-superscript-2)"
  },
  "assets.icons.superscript": {
    "value": "/icons/remix-icon.svg#superscript",
    "variable": "var(--assets-icons-superscript)"
  },
  "assets.icons.surgical-mask-fill": {
    "value": "/icons/remix-icon.svg#surgical-mask-fill",
    "variable": "var(--assets-icons-surgical-mask-fill)"
  },
  "assets.icons.surgical-mask-line": {
    "value": "/icons/remix-icon.svg#surgical-mask-line",
    "variable": "var(--assets-icons-surgical-mask-line)"
  },
  "assets.icons.surround-sound-fill": {
    "value": "/icons/remix-icon.svg#surround-sound-fill",
    "variable": "var(--assets-icons-surround-sound-fill)"
  },
  "assets.icons.surround-sound-line": {
    "value": "/icons/remix-icon.svg#surround-sound-line",
    "variable": "var(--assets-icons-surround-sound-line)"
  },
  "assets.icons.survey-fill": {
    "value": "/icons/remix-icon.svg#survey-fill",
    "variable": "var(--assets-icons-survey-fill)"
  },
  "assets.icons.survey-line": {
    "value": "/icons/remix-icon.svg#survey-line",
    "variable": "var(--assets-icons-survey-line)"
  },
  "assets.icons.swap-box-fill": {
    "value": "/icons/remix-icon.svg#swap-box-fill",
    "variable": "var(--assets-icons-swap-box-fill)"
  },
  "assets.icons.swap-box-line": {
    "value": "/icons/remix-icon.svg#swap-box-line",
    "variable": "var(--assets-icons-swap-box-line)"
  },
  "assets.icons.swap-fill": {
    "value": "/icons/remix-icon.svg#swap-fill",
    "variable": "var(--assets-icons-swap-fill)"
  },
  "assets.icons.swap-line": {
    "value": "/icons/remix-icon.svg#swap-line",
    "variable": "var(--assets-icons-swap-line)"
  },
  "assets.icons.switch-fill": {
    "value": "/icons/remix-icon.svg#switch-fill",
    "variable": "var(--assets-icons-switch-fill)"
  },
  "assets.icons.switch-line": {
    "value": "/icons/remix-icon.svg#switch-line",
    "variable": "var(--assets-icons-switch-line)"
  },
  "assets.icons.sword-fill": {
    "value": "/icons/remix-icon.svg#sword-fill",
    "variable": "var(--assets-icons-sword-fill)"
  },
  "assets.icons.sword-line": {
    "value": "/icons/remix-icon.svg#sword-line",
    "variable": "var(--assets-icons-sword-line)"
  },
  "assets.icons.syringe-fill": {
    "value": "/icons/remix-icon.svg#syringe-fill",
    "variable": "var(--assets-icons-syringe-fill)"
  },
  "assets.icons.syringe-line": {
    "value": "/icons/remix-icon.svg#syringe-line",
    "variable": "var(--assets-icons-syringe-line)"
  },
  "assets.icons.t-box-fill": {
    "value": "/icons/remix-icon.svg#t-box-fill",
    "variable": "var(--assets-icons-t-box-fill)"
  },
  "assets.icons.t-box-line": {
    "value": "/icons/remix-icon.svg#t-box-line",
    "variable": "var(--assets-icons-t-box-line)"
  },
  "assets.icons.t-shirt-2-fill": {
    "value": "/icons/remix-icon.svg#t-shirt-2-fill",
    "variable": "var(--assets-icons-t-shirt-2-fill)"
  },
  "assets.icons.t-shirt-2-line": {
    "value": "/icons/remix-icon.svg#t-shirt-2-line",
    "variable": "var(--assets-icons-t-shirt-2-line)"
  },
  "assets.icons.t-shirt-air-fill": {
    "value": "/icons/remix-icon.svg#t-shirt-air-fill",
    "variable": "var(--assets-icons-t-shirt-air-fill)"
  },
  "assets.icons.t-shirt-air-line": {
    "value": "/icons/remix-icon.svg#t-shirt-air-line",
    "variable": "var(--assets-icons-t-shirt-air-line)"
  },
  "assets.icons.t-shirt-fill": {
    "value": "/icons/remix-icon.svg#t-shirt-fill",
    "variable": "var(--assets-icons-t-shirt-fill)"
  },
  "assets.icons.t-shirt-line": {
    "value": "/icons/remix-icon.svg#t-shirt-line",
    "variable": "var(--assets-icons-t-shirt-line)"
  },
  "assets.icons.table-2": {
    "value": "/icons/remix-icon.svg#table-2",
    "variable": "var(--assets-icons-table-2)"
  },
  "assets.icons.table-alt-fill": {
    "value": "/icons/remix-icon.svg#table-alt-fill",
    "variable": "var(--assets-icons-table-alt-fill)"
  },
  "assets.icons.table-alt-line": {
    "value": "/icons/remix-icon.svg#table-alt-line",
    "variable": "var(--assets-icons-table-alt-line)"
  },
  "assets.icons.table-fill": {
    "value": "/icons/remix-icon.svg#table-fill",
    "variable": "var(--assets-icons-table-fill)"
  },
  "assets.icons.table-line": {
    "value": "/icons/remix-icon.svg#table-line",
    "variable": "var(--assets-icons-table-line)"
  },
  "assets.icons.tablet-fill": {
    "value": "/icons/remix-icon.svg#tablet-fill",
    "variable": "var(--assets-icons-tablet-fill)"
  },
  "assets.icons.tablet-line": {
    "value": "/icons/remix-icon.svg#tablet-line",
    "variable": "var(--assets-icons-tablet-line)"
  },
  "assets.icons.takeaway-fill": {
    "value": "/icons/remix-icon.svg#takeaway-fill",
    "variable": "var(--assets-icons-takeaway-fill)"
  },
  "assets.icons.takeaway-line": {
    "value": "/icons/remix-icon.svg#takeaway-line",
    "variable": "var(--assets-icons-takeaway-line)"
  },
  "assets.icons.taobao-fill": {
    "value": "/icons/remix-icon.svg#taobao-fill",
    "variable": "var(--assets-icons-taobao-fill)"
  },
  "assets.icons.taobao-line": {
    "value": "/icons/remix-icon.svg#taobao-line",
    "variable": "var(--assets-icons-taobao-line)"
  },
  "assets.icons.tape-fill": {
    "value": "/icons/remix-icon.svg#tape-fill",
    "variable": "var(--assets-icons-tape-fill)"
  },
  "assets.icons.tape-line": {
    "value": "/icons/remix-icon.svg#tape-line",
    "variable": "var(--assets-icons-tape-line)"
  },
  "assets.icons.task-fill": {
    "value": "/icons/remix-icon.svg#task-fill",
    "variable": "var(--assets-icons-task-fill)"
  },
  "assets.icons.task-line": {
    "value": "/icons/remix-icon.svg#task-line",
    "variable": "var(--assets-icons-task-line)"
  },
  "assets.icons.taxi-fill": {
    "value": "/icons/remix-icon.svg#taxi-fill",
    "variable": "var(--assets-icons-taxi-fill)"
  },
  "assets.icons.taxi-line": {
    "value": "/icons/remix-icon.svg#taxi-line",
    "variable": "var(--assets-icons-taxi-line)"
  },
  "assets.icons.taxi-wifi-fill": {
    "value": "/icons/remix-icon.svg#taxi-wifi-fill",
    "variable": "var(--assets-icons-taxi-wifi-fill)"
  },
  "assets.icons.taxi-wifi-line": {
    "value": "/icons/remix-icon.svg#taxi-wifi-line",
    "variable": "var(--assets-icons-taxi-wifi-line)"
  },
  "assets.icons.team-fill": {
    "value": "/icons/remix-icon.svg#team-fill",
    "variable": "var(--assets-icons-team-fill)"
  },
  "assets.icons.team-line": {
    "value": "/icons/remix-icon.svg#team-line",
    "variable": "var(--assets-icons-team-line)"
  },
  "assets.icons.telegram-fill": {
    "value": "/icons/remix-icon.svg#telegram-fill",
    "variable": "var(--assets-icons-telegram-fill)"
  },
  "assets.icons.telegram-line": {
    "value": "/icons/remix-icon.svg#telegram-line",
    "variable": "var(--assets-icons-telegram-line)"
  },
  "assets.icons.temp-cold-fill": {
    "value": "/icons/remix-icon.svg#temp-cold-fill",
    "variable": "var(--assets-icons-temp-cold-fill)"
  },
  "assets.icons.temp-cold-line": {
    "value": "/icons/remix-icon.svg#temp-cold-line",
    "variable": "var(--assets-icons-temp-cold-line)"
  },
  "assets.icons.temp-hot-fill": {
    "value": "/icons/remix-icon.svg#temp-hot-fill",
    "variable": "var(--assets-icons-temp-hot-fill)"
  },
  "assets.icons.temp-hot-line": {
    "value": "/icons/remix-icon.svg#temp-hot-line",
    "variable": "var(--assets-icons-temp-hot-line)"
  },
  "assets.icons.terminal-box-fill": {
    "value": "/icons/remix-icon.svg#terminal-box-fill",
    "variable": "var(--assets-icons-terminal-box-fill)"
  },
  "assets.icons.terminal-box-line": {
    "value": "/icons/remix-icon.svg#terminal-box-line",
    "variable": "var(--assets-icons-terminal-box-line)"
  },
  "assets.icons.terminal-fill": {
    "value": "/icons/remix-icon.svg#terminal-fill",
    "variable": "var(--assets-icons-terminal-fill)"
  },
  "assets.icons.terminal-line": {
    "value": "/icons/remix-icon.svg#terminal-line",
    "variable": "var(--assets-icons-terminal-line)"
  },
  "assets.icons.terminal-window-fill": {
    "value": "/icons/remix-icon.svg#terminal-window-fill",
    "variable": "var(--assets-icons-terminal-window-fill)"
  },
  "assets.icons.terminal-window-line": {
    "value": "/icons/remix-icon.svg#terminal-window-line",
    "variable": "var(--assets-icons-terminal-window-line)"
  },
  "assets.icons.test-tube-fill": {
    "value": "/icons/remix-icon.svg#test-tube-fill",
    "variable": "var(--assets-icons-test-tube-fill)"
  },
  "assets.icons.test-tube-line": {
    "value": "/icons/remix-icon.svg#test-tube-line",
    "variable": "var(--assets-icons-test-tube-line)"
  },
  "assets.icons.text-direction-l": {
    "value": "/icons/remix-icon.svg#text-direction-l",
    "variable": "var(--assets-icons-text-direction-l)"
  },
  "assets.icons.text-direction-r": {
    "value": "/icons/remix-icon.svg#text-direction-r",
    "variable": "var(--assets-icons-text-direction-r)"
  },
  "assets.icons.text-spacing": {
    "value": "/icons/remix-icon.svg#text-spacing",
    "variable": "var(--assets-icons-text-spacing)"
  },
  "assets.icons.text-wrap": {
    "value": "/icons/remix-icon.svg#text-wrap",
    "variable": "var(--assets-icons-text-wrap)"
  },
  "assets.icons.text": {
    "value": "/icons/remix-icon.svg#text",
    "variable": "var(--assets-icons-text)"
  },
  "assets.icons.thermometer-fill": {
    "value": "/icons/remix-icon.svg#thermometer-fill",
    "variable": "var(--assets-icons-thermometer-fill)"
  },
  "assets.icons.thermometer-line": {
    "value": "/icons/remix-icon.svg#thermometer-line",
    "variable": "var(--assets-icons-thermometer-line)"
  },
  "assets.icons.thumb-down-fill": {
    "value": "/icons/remix-icon.svg#thumb-down-fill",
    "variable": "var(--assets-icons-thumb-down-fill)"
  },
  "assets.icons.thumb-down-line": {
    "value": "/icons/remix-icon.svg#thumb-down-line",
    "variable": "var(--assets-icons-thumb-down-line)"
  },
  "assets.icons.thumb-up-fill": {
    "value": "/icons/remix-icon.svg#thumb-up-fill",
    "variable": "var(--assets-icons-thumb-up-fill)"
  },
  "assets.icons.thumb-up-line": {
    "value": "/icons/remix-icon.svg#thumb-up-line",
    "variable": "var(--assets-icons-thumb-up-line)"
  },
  "assets.icons.thunderstorms-fill": {
    "value": "/icons/remix-icon.svg#thunderstorms-fill",
    "variable": "var(--assets-icons-thunderstorms-fill)"
  },
  "assets.icons.thunderstorms-line": {
    "value": "/icons/remix-icon.svg#thunderstorms-line",
    "variable": "var(--assets-icons-thunderstorms-line)"
  },
  "assets.icons.ticket-2-fill": {
    "value": "/icons/remix-icon.svg#ticket-2-fill",
    "variable": "var(--assets-icons-ticket-2-fill)"
  },
  "assets.icons.ticket-2-line": {
    "value": "/icons/remix-icon.svg#ticket-2-line",
    "variable": "var(--assets-icons-ticket-2-line)"
  },
  "assets.icons.ticket-fill": {
    "value": "/icons/remix-icon.svg#ticket-fill",
    "variable": "var(--assets-icons-ticket-fill)"
  },
  "assets.icons.ticket-line": {
    "value": "/icons/remix-icon.svg#ticket-line",
    "variable": "var(--assets-icons-ticket-line)"
  },
  "assets.icons.time-fill": {
    "value": "/icons/remix-icon.svg#time-fill",
    "variable": "var(--assets-icons-time-fill)"
  },
  "assets.icons.time-line": {
    "value": "/icons/remix-icon.svg#time-line",
    "variable": "var(--assets-icons-time-line)"
  },
  "assets.icons.timer-2-fill": {
    "value": "/icons/remix-icon.svg#timer-2-fill",
    "variable": "var(--assets-icons-timer-2-fill)"
  },
  "assets.icons.timer-2-line": {
    "value": "/icons/remix-icon.svg#timer-2-line",
    "variable": "var(--assets-icons-timer-2-line)"
  },
  "assets.icons.timer-fill": {
    "value": "/icons/remix-icon.svg#timer-fill",
    "variable": "var(--assets-icons-timer-fill)"
  },
  "assets.icons.timer-flash-fill": {
    "value": "/icons/remix-icon.svg#timer-flash-fill",
    "variable": "var(--assets-icons-timer-flash-fill)"
  },
  "assets.icons.timer-flash-line": {
    "value": "/icons/remix-icon.svg#timer-flash-line",
    "variable": "var(--assets-icons-timer-flash-line)"
  },
  "assets.icons.timer-line": {
    "value": "/icons/remix-icon.svg#timer-line",
    "variable": "var(--assets-icons-timer-line)"
  },
  "assets.icons.todo-fill": {
    "value": "/icons/remix-icon.svg#todo-fill",
    "variable": "var(--assets-icons-todo-fill)"
  },
  "assets.icons.todo-line": {
    "value": "/icons/remix-icon.svg#todo-line",
    "variable": "var(--assets-icons-todo-line)"
  },
  "assets.icons.toggle-fill": {
    "value": "/icons/remix-icon.svg#toggle-fill",
    "variable": "var(--assets-icons-toggle-fill)"
  },
  "assets.icons.toggle-line": {
    "value": "/icons/remix-icon.svg#toggle-line",
    "variable": "var(--assets-icons-toggle-line)"
  },
  "assets.icons.tools-fill": {
    "value": "/icons/remix-icon.svg#tools-fill",
    "variable": "var(--assets-icons-tools-fill)"
  },
  "assets.icons.tools-line": {
    "value": "/icons/remix-icon.svg#tools-line",
    "variable": "var(--assets-icons-tools-line)"
  },
  "assets.icons.tornado-fill": {
    "value": "/icons/remix-icon.svg#tornado-fill",
    "variable": "var(--assets-icons-tornado-fill)"
  },
  "assets.icons.tornado-line": {
    "value": "/icons/remix-icon.svg#tornado-line",
    "variable": "var(--assets-icons-tornado-line)"
  },
  "assets.icons.trademark-fill": {
    "value": "/icons/remix-icon.svg#trademark-fill",
    "variable": "var(--assets-icons-trademark-fill)"
  },
  "assets.icons.trademark-line": {
    "value": "/icons/remix-icon.svg#trademark-line",
    "variable": "var(--assets-icons-trademark-line)"
  },
  "assets.icons.traffic-light-fill": {
    "value": "/icons/remix-icon.svg#traffic-light-fill",
    "variable": "var(--assets-icons-traffic-light-fill)"
  },
  "assets.icons.traffic-light-line": {
    "value": "/icons/remix-icon.svg#traffic-light-line",
    "variable": "var(--assets-icons-traffic-light-line)"
  },
  "assets.icons.train-fill": {
    "value": "/icons/remix-icon.svg#train-fill",
    "variable": "var(--assets-icons-train-fill)"
  },
  "assets.icons.train-line": {
    "value": "/icons/remix-icon.svg#train-line",
    "variable": "var(--assets-icons-train-line)"
  },
  "assets.icons.train-wifi-fill": {
    "value": "/icons/remix-icon.svg#train-wifi-fill",
    "variable": "var(--assets-icons-train-wifi-fill)"
  },
  "assets.icons.train-wifi-line": {
    "value": "/icons/remix-icon.svg#train-wifi-line",
    "variable": "var(--assets-icons-train-wifi-line)"
  },
  "assets.icons.translate-2": {
    "value": "/icons/remix-icon.svg#translate-2",
    "variable": "var(--assets-icons-translate-2)"
  },
  "assets.icons.translate": {
    "value": "/icons/remix-icon.svg#translate",
    "variable": "var(--assets-icons-translate)"
  },
  "assets.icons.travesti-fill": {
    "value": "/icons/remix-icon.svg#travesti-fill",
    "variable": "var(--assets-icons-travesti-fill)"
  },
  "assets.icons.travesti-line": {
    "value": "/icons/remix-icon.svg#travesti-line",
    "variable": "var(--assets-icons-travesti-line)"
  },
  "assets.icons.treasure-map-fill": {
    "value": "/icons/remix-icon.svg#treasure-map-fill",
    "variable": "var(--assets-icons-treasure-map-fill)"
  },
  "assets.icons.treasure-map-line": {
    "value": "/icons/remix-icon.svg#treasure-map-line",
    "variable": "var(--assets-icons-treasure-map-line)"
  },
  "assets.icons.trello-fill": {
    "value": "/icons/remix-icon.svg#trello-fill",
    "variable": "var(--assets-icons-trello-fill)"
  },
  "assets.icons.trello-line": {
    "value": "/icons/remix-icon.svg#trello-line",
    "variable": "var(--assets-icons-trello-line)"
  },
  "assets.icons.trophy-fill": {
    "value": "/icons/remix-icon.svg#trophy-fill",
    "variable": "var(--assets-icons-trophy-fill)"
  },
  "assets.icons.trophy-line": {
    "value": "/icons/remix-icon.svg#trophy-line",
    "variable": "var(--assets-icons-trophy-line)"
  },
  "assets.icons.truck-fill": {
    "value": "/icons/remix-icon.svg#truck-fill",
    "variable": "var(--assets-icons-truck-fill)"
  },
  "assets.icons.truck-line": {
    "value": "/icons/remix-icon.svg#truck-line",
    "variable": "var(--assets-icons-truck-line)"
  },
  "assets.icons.tumblr-fill": {
    "value": "/icons/remix-icon.svg#tumblr-fill",
    "variable": "var(--assets-icons-tumblr-fill)"
  },
  "assets.icons.tumblr-line": {
    "value": "/icons/remix-icon.svg#tumblr-line",
    "variable": "var(--assets-icons-tumblr-line)"
  },
  "assets.icons.tv-2-fill": {
    "value": "/icons/remix-icon.svg#tv-2-fill",
    "variable": "var(--assets-icons-tv-2-fill)"
  },
  "assets.icons.tv-2-line": {
    "value": "/icons/remix-icon.svg#tv-2-line",
    "variable": "var(--assets-icons-tv-2-line)"
  },
  "assets.icons.tv-fill": {
    "value": "/icons/remix-icon.svg#tv-fill",
    "variable": "var(--assets-icons-tv-fill)"
  },
  "assets.icons.tv-line": {
    "value": "/icons/remix-icon.svg#tv-line",
    "variable": "var(--assets-icons-tv-line)"
  },
  "assets.icons.twitch-fill": {
    "value": "/icons/remix-icon.svg#twitch-fill",
    "variable": "var(--assets-icons-twitch-fill)"
  },
  "assets.icons.twitch-line": {
    "value": "/icons/remix-icon.svg#twitch-line",
    "variable": "var(--assets-icons-twitch-line)"
  },
  "assets.icons.twitter-fill": {
    "value": "/icons/remix-icon.svg#twitter-fill",
    "variable": "var(--assets-icons-twitter-fill)"
  },
  "assets.icons.twitter-line": {
    "value": "/icons/remix-icon.svg#twitter-line",
    "variable": "var(--assets-icons-twitter-line)"
  },
  "assets.icons.typhoon-fill": {
    "value": "/icons/remix-icon.svg#typhoon-fill",
    "variable": "var(--assets-icons-typhoon-fill)"
  },
  "assets.icons.typhoon-line": {
    "value": "/icons/remix-icon.svg#typhoon-line",
    "variable": "var(--assets-icons-typhoon-line)"
  },
  "assets.icons.u-disk-fill": {
    "value": "/icons/remix-icon.svg#u-disk-fill",
    "variable": "var(--assets-icons-u-disk-fill)"
  },
  "assets.icons.u-disk-line": {
    "value": "/icons/remix-icon.svg#u-disk-line",
    "variable": "var(--assets-icons-u-disk-line)"
  },
  "assets.icons.ubuntu-fill": {
    "value": "/icons/remix-icon.svg#ubuntu-fill",
    "variable": "var(--assets-icons-ubuntu-fill)"
  },
  "assets.icons.ubuntu-line": {
    "value": "/icons/remix-icon.svg#ubuntu-line",
    "variable": "var(--assets-icons-ubuntu-line)"
  },
  "assets.icons.umbrella-fill": {
    "value": "/icons/remix-icon.svg#umbrella-fill",
    "variable": "var(--assets-icons-umbrella-fill)"
  },
  "assets.icons.umbrella-line": {
    "value": "/icons/remix-icon.svg#umbrella-line",
    "variable": "var(--assets-icons-umbrella-line)"
  },
  "assets.icons.underline": {
    "value": "/icons/remix-icon.svg#underline",
    "variable": "var(--assets-icons-underline)"
  },
  "assets.icons.uninstall-fill": {
    "value": "/icons/remix-icon.svg#uninstall-fill",
    "variable": "var(--assets-icons-uninstall-fill)"
  },
  "assets.icons.uninstall-line": {
    "value": "/icons/remix-icon.svg#uninstall-line",
    "variable": "var(--assets-icons-uninstall-line)"
  },
  "assets.icons.unsplash-fill": {
    "value": "/icons/remix-icon.svg#unsplash-fill",
    "variable": "var(--assets-icons-unsplash-fill)"
  },
  "assets.icons.unsplash-line": {
    "value": "/icons/remix-icon.svg#unsplash-line",
    "variable": "var(--assets-icons-unsplash-line)"
  },
  "assets.icons.upload-2-fill": {
    "value": "/icons/remix-icon.svg#upload-2-fill",
    "variable": "var(--assets-icons-upload-2-fill)"
  },
  "assets.icons.upload-2-line": {
    "value": "/icons/remix-icon.svg#upload-2-line",
    "variable": "var(--assets-icons-upload-2-line)"
  },
  "assets.icons.upload-cloud-2-fill": {
    "value": "/icons/remix-icon.svg#upload-cloud-2-fill",
    "variable": "var(--assets-icons-upload-cloud-2-fill)"
  },
  "assets.icons.upload-cloud-2-line": {
    "value": "/icons/remix-icon.svg#upload-cloud-2-line",
    "variable": "var(--assets-icons-upload-cloud-2-line)"
  },
  "assets.icons.upload-cloud-fill": {
    "value": "/icons/remix-icon.svg#upload-cloud-fill",
    "variable": "var(--assets-icons-upload-cloud-fill)"
  },
  "assets.icons.upload-cloud-line": {
    "value": "/icons/remix-icon.svg#upload-cloud-line",
    "variable": "var(--assets-icons-upload-cloud-line)"
  },
  "assets.icons.upload-fill": {
    "value": "/icons/remix-icon.svg#upload-fill",
    "variable": "var(--assets-icons-upload-fill)"
  },
  "assets.icons.upload-line": {
    "value": "/icons/remix-icon.svg#upload-line",
    "variable": "var(--assets-icons-upload-line)"
  },
  "assets.icons.usb-fill": {
    "value": "/icons/remix-icon.svg#usb-fill",
    "variable": "var(--assets-icons-usb-fill)"
  },
  "assets.icons.usb-line": {
    "value": "/icons/remix-icon.svg#usb-line",
    "variable": "var(--assets-icons-usb-line)"
  },
  "assets.icons.user-2-fill": {
    "value": "/icons/remix-icon.svg#user-2-fill",
    "variable": "var(--assets-icons-user-2-fill)"
  },
  "assets.icons.user-2-line": {
    "value": "/icons/remix-icon.svg#user-2-line",
    "variable": "var(--assets-icons-user-2-line)"
  },
  "assets.icons.user-3-fill": {
    "value": "/icons/remix-icon.svg#user-3-fill",
    "variable": "var(--assets-icons-user-3-fill)"
  },
  "assets.icons.user-3-line": {
    "value": "/icons/remix-icon.svg#user-3-line",
    "variable": "var(--assets-icons-user-3-line)"
  },
  "assets.icons.user-4-fill": {
    "value": "/icons/remix-icon.svg#user-4-fill",
    "variable": "var(--assets-icons-user-4-fill)"
  },
  "assets.icons.user-4-line": {
    "value": "/icons/remix-icon.svg#user-4-line",
    "variable": "var(--assets-icons-user-4-line)"
  },
  "assets.icons.user-5-fill": {
    "value": "/icons/remix-icon.svg#user-5-fill",
    "variable": "var(--assets-icons-user-5-fill)"
  },
  "assets.icons.user-5-line": {
    "value": "/icons/remix-icon.svg#user-5-line",
    "variable": "var(--assets-icons-user-5-line)"
  },
  "assets.icons.user-6-fill": {
    "value": "/icons/remix-icon.svg#user-6-fill",
    "variable": "var(--assets-icons-user-6-fill)"
  },
  "assets.icons.user-6-line": {
    "value": "/icons/remix-icon.svg#user-6-line",
    "variable": "var(--assets-icons-user-6-line)"
  },
  "assets.icons.user-add-fill": {
    "value": "/icons/remix-icon.svg#user-add-fill",
    "variable": "var(--assets-icons-user-add-fill)"
  },
  "assets.icons.user-add-line": {
    "value": "/icons/remix-icon.svg#user-add-line",
    "variable": "var(--assets-icons-user-add-line)"
  },
  "assets.icons.user-fill": {
    "value": "/icons/remix-icon.svg#user-fill",
    "variable": "var(--assets-icons-user-fill)"
  },
  "assets.icons.user-follow-fill": {
    "value": "/icons/remix-icon.svg#user-follow-fill",
    "variable": "var(--assets-icons-user-follow-fill)"
  },
  "assets.icons.user-follow-line": {
    "value": "/icons/remix-icon.svg#user-follow-line",
    "variable": "var(--assets-icons-user-follow-line)"
  },
  "assets.icons.user-heart-fill": {
    "value": "/icons/remix-icon.svg#user-heart-fill",
    "variable": "var(--assets-icons-user-heart-fill)"
  },
  "assets.icons.user-heart-line": {
    "value": "/icons/remix-icon.svg#user-heart-line",
    "variable": "var(--assets-icons-user-heart-line)"
  },
  "assets.icons.user-line": {
    "value": "/icons/remix-icon.svg#user-line",
    "variable": "var(--assets-icons-user-line)"
  },
  "assets.icons.user-location-fill": {
    "value": "/icons/remix-icon.svg#user-location-fill",
    "variable": "var(--assets-icons-user-location-fill)"
  },
  "assets.icons.user-location-line": {
    "value": "/icons/remix-icon.svg#user-location-line",
    "variable": "var(--assets-icons-user-location-line)"
  },
  "assets.icons.user-received-2-fill": {
    "value": "/icons/remix-icon.svg#user-received-2-fill",
    "variable": "var(--assets-icons-user-received-2-fill)"
  },
  "assets.icons.user-received-2-line": {
    "value": "/icons/remix-icon.svg#user-received-2-line",
    "variable": "var(--assets-icons-user-received-2-line)"
  },
  "assets.icons.user-received-fill": {
    "value": "/icons/remix-icon.svg#user-received-fill",
    "variable": "var(--assets-icons-user-received-fill)"
  },
  "assets.icons.user-received-line": {
    "value": "/icons/remix-icon.svg#user-received-line",
    "variable": "var(--assets-icons-user-received-line)"
  },
  "assets.icons.user-search-fill": {
    "value": "/icons/remix-icon.svg#user-search-fill",
    "variable": "var(--assets-icons-user-search-fill)"
  },
  "assets.icons.user-search-line": {
    "value": "/icons/remix-icon.svg#user-search-line",
    "variable": "var(--assets-icons-user-search-line)"
  },
  "assets.icons.user-settings-fill": {
    "value": "/icons/remix-icon.svg#user-settings-fill",
    "variable": "var(--assets-icons-user-settings-fill)"
  },
  "assets.icons.user-settings-line": {
    "value": "/icons/remix-icon.svg#user-settings-line",
    "variable": "var(--assets-icons-user-settings-line)"
  },
  "assets.icons.user-shared-2-fill": {
    "value": "/icons/remix-icon.svg#user-shared-2-fill",
    "variable": "var(--assets-icons-user-shared-2-fill)"
  },
  "assets.icons.user-shared-2-line": {
    "value": "/icons/remix-icon.svg#user-shared-2-line",
    "variable": "var(--assets-icons-user-shared-2-line)"
  },
  "assets.icons.user-shared-fill": {
    "value": "/icons/remix-icon.svg#user-shared-fill",
    "variable": "var(--assets-icons-user-shared-fill)"
  },
  "assets.icons.user-shared-line": {
    "value": "/icons/remix-icon.svg#user-shared-line",
    "variable": "var(--assets-icons-user-shared-line)"
  },
  "assets.icons.user-smile-fill": {
    "value": "/icons/remix-icon.svg#user-smile-fill",
    "variable": "var(--assets-icons-user-smile-fill)"
  },
  "assets.icons.user-smile-line": {
    "value": "/icons/remix-icon.svg#user-smile-line",
    "variable": "var(--assets-icons-user-smile-line)"
  },
  "assets.icons.user-star-fill": {
    "value": "/icons/remix-icon.svg#user-star-fill",
    "variable": "var(--assets-icons-user-star-fill)"
  },
  "assets.icons.user-star-line": {
    "value": "/icons/remix-icon.svg#user-star-line",
    "variable": "var(--assets-icons-user-star-line)"
  },
  "assets.icons.user-unfollow-fill": {
    "value": "/icons/remix-icon.svg#user-unfollow-fill",
    "variable": "var(--assets-icons-user-unfollow-fill)"
  },
  "assets.icons.user-unfollow-line": {
    "value": "/icons/remix-icon.svg#user-unfollow-line",
    "variable": "var(--assets-icons-user-unfollow-line)"
  },
  "assets.icons.user-voice-fill": {
    "value": "/icons/remix-icon.svg#user-voice-fill",
    "variable": "var(--assets-icons-user-voice-fill)"
  },
  "assets.icons.user-voice-line": {
    "value": "/icons/remix-icon.svg#user-voice-line",
    "variable": "var(--assets-icons-user-voice-line)"
  },
  "assets.icons.video-add-fill": {
    "value": "/icons/remix-icon.svg#video-add-fill",
    "variable": "var(--assets-icons-video-add-fill)"
  },
  "assets.icons.video-add-line": {
    "value": "/icons/remix-icon.svg#video-add-line",
    "variable": "var(--assets-icons-video-add-line)"
  },
  "assets.icons.video-chat-fill": {
    "value": "/icons/remix-icon.svg#video-chat-fill",
    "variable": "var(--assets-icons-video-chat-fill)"
  },
  "assets.icons.video-chat-line": {
    "value": "/icons/remix-icon.svg#video-chat-line",
    "variable": "var(--assets-icons-video-chat-line)"
  },
  "assets.icons.video-download-fill": {
    "value": "/icons/remix-icon.svg#video-download-fill",
    "variable": "var(--assets-icons-video-download-fill)"
  },
  "assets.icons.video-download-line": {
    "value": "/icons/remix-icon.svg#video-download-line",
    "variable": "var(--assets-icons-video-download-line)"
  },
  "assets.icons.video-fill": {
    "value": "/icons/remix-icon.svg#video-fill",
    "variable": "var(--assets-icons-video-fill)"
  },
  "assets.icons.video-line": {
    "value": "/icons/remix-icon.svg#video-line",
    "variable": "var(--assets-icons-video-line)"
  },
  "assets.icons.video-upload-fill": {
    "value": "/icons/remix-icon.svg#video-upload-fill",
    "variable": "var(--assets-icons-video-upload-fill)"
  },
  "assets.icons.video-upload-line": {
    "value": "/icons/remix-icon.svg#video-upload-line",
    "variable": "var(--assets-icons-video-upload-line)"
  },
  "assets.icons.vidicon-2-fill": {
    "value": "/icons/remix-icon.svg#vidicon-2-fill",
    "variable": "var(--assets-icons-vidicon-2-fill)"
  },
  "assets.icons.vidicon-2-line": {
    "value": "/icons/remix-icon.svg#vidicon-2-line",
    "variable": "var(--assets-icons-vidicon-2-line)"
  },
  "assets.icons.vidicon-fill": {
    "value": "/icons/remix-icon.svg#vidicon-fill",
    "variable": "var(--assets-icons-vidicon-fill)"
  },
  "assets.icons.vidicon-line": {
    "value": "/icons/remix-icon.svg#vidicon-line",
    "variable": "var(--assets-icons-vidicon-line)"
  },
  "assets.icons.vimeo-fill": {
    "value": "/icons/remix-icon.svg#vimeo-fill",
    "variable": "var(--assets-icons-vimeo-fill)"
  },
  "assets.icons.vimeo-line": {
    "value": "/icons/remix-icon.svg#vimeo-line",
    "variable": "var(--assets-icons-vimeo-line)"
  },
  "assets.icons.vip-crown-2-fill": {
    "value": "/icons/remix-icon.svg#vip-crown-2-fill",
    "variable": "var(--assets-icons-vip-crown-2-fill)"
  },
  "assets.icons.vip-crown-2-line": {
    "value": "/icons/remix-icon.svg#vip-crown-2-line",
    "variable": "var(--assets-icons-vip-crown-2-line)"
  },
  "assets.icons.vip-crown-fill": {
    "value": "/icons/remix-icon.svg#vip-crown-fill",
    "variable": "var(--assets-icons-vip-crown-fill)"
  },
  "assets.icons.vip-crown-line": {
    "value": "/icons/remix-icon.svg#vip-crown-line",
    "variable": "var(--assets-icons-vip-crown-line)"
  },
  "assets.icons.vip-diamond-fill": {
    "value": "/icons/remix-icon.svg#vip-diamond-fill",
    "variable": "var(--assets-icons-vip-diamond-fill)"
  },
  "assets.icons.vip-diamond-line": {
    "value": "/icons/remix-icon.svg#vip-diamond-line",
    "variable": "var(--assets-icons-vip-diamond-line)"
  },
  "assets.icons.vip-fill": {
    "value": "/icons/remix-icon.svg#vip-fill",
    "variable": "var(--assets-icons-vip-fill)"
  },
  "assets.icons.vip-line": {
    "value": "/icons/remix-icon.svg#vip-line",
    "variable": "var(--assets-icons-vip-line)"
  },
  "assets.icons.virus-fill": {
    "value": "/icons/remix-icon.svg#virus-fill",
    "variable": "var(--assets-icons-virus-fill)"
  },
  "assets.icons.virus-line": {
    "value": "/icons/remix-icon.svg#virus-line",
    "variable": "var(--assets-icons-virus-line)"
  },
  "assets.icons.visa-fill": {
    "value": "/icons/remix-icon.svg#visa-fill",
    "variable": "var(--assets-icons-visa-fill)"
  },
  "assets.icons.visa-line": {
    "value": "/icons/remix-icon.svg#visa-line",
    "variable": "var(--assets-icons-visa-line)"
  },
  "assets.icons.voice-recognition-fill": {
    "value": "/icons/remix-icon.svg#voice-recognition-fill",
    "variable": "var(--assets-icons-voice-recognition-fill)"
  },
  "assets.icons.voice-recognition-line": {
    "value": "/icons/remix-icon.svg#voice-recognition-line",
    "variable": "var(--assets-icons-voice-recognition-line)"
  },
  "assets.icons.voiceprint-fill": {
    "value": "/icons/remix-icon.svg#voiceprint-fill",
    "variable": "var(--assets-icons-voiceprint-fill)"
  },
  "assets.icons.voiceprint-line": {
    "value": "/icons/remix-icon.svg#voiceprint-line",
    "variable": "var(--assets-icons-voiceprint-line)"
  },
  "assets.icons.volume-down-fill": {
    "value": "/icons/remix-icon.svg#volume-down-fill",
    "variable": "var(--assets-icons-volume-down-fill)"
  },
  "assets.icons.volume-down-line": {
    "value": "/icons/remix-icon.svg#volume-down-line",
    "variable": "var(--assets-icons-volume-down-line)"
  },
  "assets.icons.volume-mute-fill": {
    "value": "/icons/remix-icon.svg#volume-mute-fill",
    "variable": "var(--assets-icons-volume-mute-fill)"
  },
  "assets.icons.volume-mute-line": {
    "value": "/icons/remix-icon.svg#volume-mute-line",
    "variable": "var(--assets-icons-volume-mute-line)"
  },
  "assets.icons.volume-off-vibrate-fill": {
    "value": "/icons/remix-icon.svg#volume-off-vibrate-fill",
    "variable": "var(--assets-icons-volume-off-vibrate-fill)"
  },
  "assets.icons.volume-off-vibrate-line": {
    "value": "/icons/remix-icon.svg#volume-off-vibrate-line",
    "variable": "var(--assets-icons-volume-off-vibrate-line)"
  },
  "assets.icons.volume-up-fill": {
    "value": "/icons/remix-icon.svg#volume-up-fill",
    "variable": "var(--assets-icons-volume-up-fill)"
  },
  "assets.icons.volume-up-line": {
    "value": "/icons/remix-icon.svg#volume-up-line",
    "variable": "var(--assets-icons-volume-up-line)"
  },
  "assets.icons.volume-vibrate-fill": {
    "value": "/icons/remix-icon.svg#volume-vibrate-fill",
    "variable": "var(--assets-icons-volume-vibrate-fill)"
  },
  "assets.icons.volume-vibrate-line": {
    "value": "/icons/remix-icon.svg#volume-vibrate-line",
    "variable": "var(--assets-icons-volume-vibrate-line)"
  },
  "assets.icons.vuejs-fill": {
    "value": "/icons/remix-icon.svg#vuejs-fill",
    "variable": "var(--assets-icons-vuejs-fill)"
  },
  "assets.icons.vuejs-line": {
    "value": "/icons/remix-icon.svg#vuejs-line",
    "variable": "var(--assets-icons-vuejs-line)"
  },
  "assets.icons.walk-fill": {
    "value": "/icons/remix-icon.svg#walk-fill",
    "variable": "var(--assets-icons-walk-fill)"
  },
  "assets.icons.walk-line": {
    "value": "/icons/remix-icon.svg#walk-line",
    "variable": "var(--assets-icons-walk-line)"
  },
  "assets.icons.wallet-2-fill": {
    "value": "/icons/remix-icon.svg#wallet-2-fill",
    "variable": "var(--assets-icons-wallet-2-fill)"
  },
  "assets.icons.wallet-2-line": {
    "value": "/icons/remix-icon.svg#wallet-2-line",
    "variable": "var(--assets-icons-wallet-2-line)"
  },
  "assets.icons.wallet-3-fill": {
    "value": "/icons/remix-icon.svg#wallet-3-fill",
    "variable": "var(--assets-icons-wallet-3-fill)"
  },
  "assets.icons.wallet-3-line": {
    "value": "/icons/remix-icon.svg#wallet-3-line",
    "variable": "var(--assets-icons-wallet-3-line)"
  },
  "assets.icons.wallet-fill": {
    "value": "/icons/remix-icon.svg#wallet-fill",
    "variable": "var(--assets-icons-wallet-fill)"
  },
  "assets.icons.wallet-line": {
    "value": "/icons/remix-icon.svg#wallet-line",
    "variable": "var(--assets-icons-wallet-line)"
  },
  "assets.icons.water-flash-fill": {
    "value": "/icons/remix-icon.svg#water-flash-fill",
    "variable": "var(--assets-icons-water-flash-fill)"
  },
  "assets.icons.water-flash-line": {
    "value": "/icons/remix-icon.svg#water-flash-line",
    "variable": "var(--assets-icons-water-flash-line)"
  },
  "assets.icons.webcam-fill": {
    "value": "/icons/remix-icon.svg#webcam-fill",
    "variable": "var(--assets-icons-webcam-fill)"
  },
  "assets.icons.webcam-line": {
    "value": "/icons/remix-icon.svg#webcam-line",
    "variable": "var(--assets-icons-webcam-line)"
  },
  "assets.icons.wechat-2-fill": {
    "value": "/icons/remix-icon.svg#wechat-2-fill",
    "variable": "var(--assets-icons-wechat-2-fill)"
  },
  "assets.icons.wechat-2-line": {
    "value": "/icons/remix-icon.svg#wechat-2-line",
    "variable": "var(--assets-icons-wechat-2-line)"
  },
  "assets.icons.wechat-fill": {
    "value": "/icons/remix-icon.svg#wechat-fill",
    "variable": "var(--assets-icons-wechat-fill)"
  },
  "assets.icons.wechat-line": {
    "value": "/icons/remix-icon.svg#wechat-line",
    "variable": "var(--assets-icons-wechat-line)"
  },
  "assets.icons.wechat-pay-fill": {
    "value": "/icons/remix-icon.svg#wechat-pay-fill",
    "variable": "var(--assets-icons-wechat-pay-fill)"
  },
  "assets.icons.wechat-pay-line": {
    "value": "/icons/remix-icon.svg#wechat-pay-line",
    "variable": "var(--assets-icons-wechat-pay-line)"
  },
  "assets.icons.weibo-fill": {
    "value": "/icons/remix-icon.svg#weibo-fill",
    "variable": "var(--assets-icons-weibo-fill)"
  },
  "assets.icons.weibo-line": {
    "value": "/icons/remix-icon.svg#weibo-line",
    "variable": "var(--assets-icons-weibo-line)"
  },
  "assets.icons.whatsapp-fill": {
    "value": "/icons/remix-icon.svg#whatsapp-fill",
    "variable": "var(--assets-icons-whatsapp-fill)"
  },
  "assets.icons.whatsapp-line": {
    "value": "/icons/remix-icon.svg#whatsapp-line",
    "variable": "var(--assets-icons-whatsapp-line)"
  },
  "assets.icons.wheelchair-fill": {
    "value": "/icons/remix-icon.svg#wheelchair-fill",
    "variable": "var(--assets-icons-wheelchair-fill)"
  },
  "assets.icons.wheelchair-line": {
    "value": "/icons/remix-icon.svg#wheelchair-line",
    "variable": "var(--assets-icons-wheelchair-line)"
  },
  "assets.icons.wifi-fill": {
    "value": "/icons/remix-icon.svg#wifi-fill",
    "variable": "var(--assets-icons-wifi-fill)"
  },
  "assets.icons.wifi-line": {
    "value": "/icons/remix-icon.svg#wifi-line",
    "variable": "var(--assets-icons-wifi-line)"
  },
  "assets.icons.wifi-off-fill": {
    "value": "/icons/remix-icon.svg#wifi-off-fill",
    "variable": "var(--assets-icons-wifi-off-fill)"
  },
  "assets.icons.wifi-off-line": {
    "value": "/icons/remix-icon.svg#wifi-off-line",
    "variable": "var(--assets-icons-wifi-off-line)"
  },
  "assets.icons.window-2-fill": {
    "value": "/icons/remix-icon.svg#window-2-fill",
    "variable": "var(--assets-icons-window-2-fill)"
  },
  "assets.icons.window-2-line": {
    "value": "/icons/remix-icon.svg#window-2-line",
    "variable": "var(--assets-icons-window-2-line)"
  },
  "assets.icons.window-fill": {
    "value": "/icons/remix-icon.svg#window-fill",
    "variable": "var(--assets-icons-window-fill)"
  },
  "assets.icons.window-line": {
    "value": "/icons/remix-icon.svg#window-line",
    "variable": "var(--assets-icons-window-line)"
  },
  "assets.icons.windows-fill": {
    "value": "/icons/remix-icon.svg#windows-fill",
    "variable": "var(--assets-icons-windows-fill)"
  },
  "assets.icons.windows-line": {
    "value": "/icons/remix-icon.svg#windows-line",
    "variable": "var(--assets-icons-windows-line)"
  },
  "assets.icons.windy-fill": {
    "value": "/icons/remix-icon.svg#windy-fill",
    "variable": "var(--assets-icons-windy-fill)"
  },
  "assets.icons.windy-line": {
    "value": "/icons/remix-icon.svg#windy-line",
    "variable": "var(--assets-icons-windy-line)"
  },
  "assets.icons.wireless-charging-fill": {
    "value": "/icons/remix-icon.svg#wireless-charging-fill",
    "variable": "var(--assets-icons-wireless-charging-fill)"
  },
  "assets.icons.wireless-charging-line": {
    "value": "/icons/remix-icon.svg#wireless-charging-line",
    "variable": "var(--assets-icons-wireless-charging-line)"
  },
  "assets.icons.women-fill": {
    "value": "/icons/remix-icon.svg#women-fill",
    "variable": "var(--assets-icons-women-fill)"
  },
  "assets.icons.women-line": {
    "value": "/icons/remix-icon.svg#women-line",
    "variable": "var(--assets-icons-women-line)"
  },
  "assets.icons.wubi-input": {
    "value": "/icons/remix-icon.svg#wubi-input",
    "variable": "var(--assets-icons-wubi-input)"
  },
  "assets.icons.xbox-fill": {
    "value": "/icons/remix-icon.svg#xbox-fill",
    "variable": "var(--assets-icons-xbox-fill)"
  },
  "assets.icons.xbox-line": {
    "value": "/icons/remix-icon.svg#xbox-line",
    "variable": "var(--assets-icons-xbox-line)"
  },
  "assets.icons.xing-fill": {
    "value": "/icons/remix-icon.svg#xing-fill",
    "variable": "var(--assets-icons-xing-fill)"
  },
  "assets.icons.xing-line": {
    "value": "/icons/remix-icon.svg#xing-line",
    "variable": "var(--assets-icons-xing-line)"
  },
  "assets.icons.youtube-fill": {
    "value": "/icons/remix-icon.svg#youtube-fill",
    "variable": "var(--assets-icons-youtube-fill)"
  },
  "assets.icons.youtube-line": {
    "value": "/icons/remix-icon.svg#youtube-line",
    "variable": "var(--assets-icons-youtube-line)"
  },
  "assets.icons.zcool-fill": {
    "value": "/icons/remix-icon.svg#zcool-fill",
    "variable": "var(--assets-icons-zcool-fill)"
  },
  "assets.icons.zcool-line": {
    "value": "/icons/remix-icon.svg#zcool-line",
    "variable": "var(--assets-icons-zcool-line)"
  },
  "assets.icons.zhihu-fill": {
    "value": "/icons/remix-icon.svg#zhihu-fill",
    "variable": "var(--assets-icons-zhihu-fill)"
  },
  "assets.icons.zhihu-line": {
    "value": "/icons/remix-icon.svg#zhihu-line",
    "variable": "var(--assets-icons-zhihu-line)"
  },
  "assets.icons.zoom-in-fill": {
    "value": "/icons/remix-icon.svg#zoom-in-fill",
    "variable": "var(--assets-icons-zoom-in-fill)"
  },
  "assets.icons.zoom-in-line": {
    "value": "/icons/remix-icon.svg#zoom-in-line",
    "variable": "var(--assets-icons-zoom-in-line)"
  },
  "assets.icons.zoom-out-fill": {
    "value": "/icons/remix-icon.svg#zoom-out-fill",
    "variable": "var(--assets-icons-zoom-out-fill)"
  },
  "assets.icons.zoom-out-line": {
    "value": "/icons/remix-icon.svg#zoom-out-line",
    "variable": "var(--assets-icons-zoom-out-line)"
  },
  "assets.icons.zzz-fill": {
    "value": "/icons/remix-icon.svg#zzz-fill",
    "variable": "var(--assets-icons-zzz-fill)"
  },
  "assets.icons.zzz-line": {
    "value": "/icons/remix-icon.svg#zzz-line",
    "variable": "var(--assets-icons-zzz-line)"
  },
  "assets.icons.arrow-down-double-fill": {
    "value": "/icons/remix-icon.svg#arrow-down-double-fill",
    "variable": "var(--assets-icons-arrow-down-double-fill)"
  },
  "assets.icons.arrow-down-double-line": {
    "value": "/icons/remix-icon.svg#arrow-down-double-line",
    "variable": "var(--assets-icons-arrow-down-double-line)"
  },
  "assets.icons.arrow-left-double-fill": {
    "value": "/icons/remix-icon.svg#arrow-left-double-fill",
    "variable": "var(--assets-icons-arrow-left-double-fill)"
  },
  "assets.icons.arrow-left-double-line": {
    "value": "/icons/remix-icon.svg#arrow-left-double-line",
    "variable": "var(--assets-icons-arrow-left-double-line)"
  },
  "assets.icons.arrow-right-double-fill": {
    "value": "/icons/remix-icon.svg#arrow-right-double-fill",
    "variable": "var(--assets-icons-arrow-right-double-fill)"
  },
  "assets.icons.arrow-right-double-line": {
    "value": "/icons/remix-icon.svg#arrow-right-double-line",
    "variable": "var(--assets-icons-arrow-right-double-line)"
  },
  "assets.icons.arrow-turn-back-fill": {
    "value": "/icons/remix-icon.svg#arrow-turn-back-fill",
    "variable": "var(--assets-icons-arrow-turn-back-fill)"
  },
  "assets.icons.arrow-turn-back-line": {
    "value": "/icons/remix-icon.svg#arrow-turn-back-line",
    "variable": "var(--assets-icons-arrow-turn-back-line)"
  },
  "assets.icons.arrow-turn-forward-fill": {
    "value": "/icons/remix-icon.svg#arrow-turn-forward-fill",
    "variable": "var(--assets-icons-arrow-turn-forward-fill)"
  },
  "assets.icons.arrow-turn-forward-line": {
    "value": "/icons/remix-icon.svg#arrow-turn-forward-line",
    "variable": "var(--assets-icons-arrow-turn-forward-line)"
  },
  "assets.icons.arrow-up-double-fill": {
    "value": "/icons/remix-icon.svg#arrow-up-double-fill",
    "variable": "var(--assets-icons-arrow-up-double-fill)"
  },
  "assets.icons.arrow-up-double-line": {
    "value": "/icons/remix-icon.svg#arrow-up-double-line",
    "variable": "var(--assets-icons-arrow-up-double-line)"
  },
  "assets.icons.bard-fill": {
    "value": "/icons/remix-icon.svg#bard-fill",
    "variable": "var(--assets-icons-bard-fill)"
  },
  "assets.icons.bard-line": {
    "value": "/icons/remix-icon.svg#bard-line",
    "variable": "var(--assets-icons-bard-line)"
  },
  "assets.icons.bootstrap-fill": {
    "value": "/icons/remix-icon.svg#bootstrap-fill",
    "variable": "var(--assets-icons-bootstrap-fill)"
  },
  "assets.icons.bootstrap-line": {
    "value": "/icons/remix-icon.svg#bootstrap-line",
    "variable": "var(--assets-icons-bootstrap-line)"
  },
  "assets.icons.box-1-fill": {
    "value": "/icons/remix-icon.svg#box-1-fill",
    "variable": "var(--assets-icons-box-1-fill)"
  },
  "assets.icons.box-1-line": {
    "value": "/icons/remix-icon.svg#box-1-line",
    "variable": "var(--assets-icons-box-1-line)"
  },
  "assets.icons.box-2-fill": {
    "value": "/icons/remix-icon.svg#box-2-fill",
    "variable": "var(--assets-icons-box-2-fill)"
  },
  "assets.icons.box-2-line": {
    "value": "/icons/remix-icon.svg#box-2-line",
    "variable": "var(--assets-icons-box-2-line)"
  },
  "assets.icons.box-3-fill": {
    "value": "/icons/remix-icon.svg#box-3-fill",
    "variable": "var(--assets-icons-box-3-fill)"
  },
  "assets.icons.box-3-line": {
    "value": "/icons/remix-icon.svg#box-3-line",
    "variable": "var(--assets-icons-box-3-line)"
  },
  "assets.icons.brain-fill": {
    "value": "/icons/remix-icon.svg#brain-fill",
    "variable": "var(--assets-icons-brain-fill)"
  },
  "assets.icons.brain-line": {
    "value": "/icons/remix-icon.svg#brain-line",
    "variable": "var(--assets-icons-brain-line)"
  },
  "assets.icons.candle-fill": {
    "value": "/icons/remix-icon.svg#candle-fill",
    "variable": "var(--assets-icons-candle-fill)"
  },
  "assets.icons.candle-line": {
    "value": "/icons/remix-icon.svg#candle-line",
    "variable": "var(--assets-icons-candle-line)"
  },
  "assets.icons.cash-fill": {
    "value": "/icons/remix-icon.svg#cash-fill",
    "variable": "var(--assets-icons-cash-fill)"
  },
  "assets.icons.cash-line": {
    "value": "/icons/remix-icon.svg#cash-line",
    "variable": "var(--assets-icons-cash-line)"
  },
  "assets.icons.contract-left-fill": {
    "value": "/icons/remix-icon.svg#contract-left-fill",
    "variable": "var(--assets-icons-contract-left-fill)"
  },
  "assets.icons.contract-left-line": {
    "value": "/icons/remix-icon.svg#contract-left-line",
    "variable": "var(--assets-icons-contract-left-line)"
  },
  "assets.icons.contract-left-right-fill": {
    "value": "/icons/remix-icon.svg#contract-left-right-fill",
    "variable": "var(--assets-icons-contract-left-right-fill)"
  },
  "assets.icons.contract-left-right-line": {
    "value": "/icons/remix-icon.svg#contract-left-right-line",
    "variable": "var(--assets-icons-contract-left-right-line)"
  },
  "assets.icons.contract-right-fill": {
    "value": "/icons/remix-icon.svg#contract-right-fill",
    "variable": "var(--assets-icons-contract-right-fill)"
  },
  "assets.icons.contract-right-line": {
    "value": "/icons/remix-icon.svg#contract-right-line",
    "variable": "var(--assets-icons-contract-right-line)"
  },
  "assets.icons.contract-up-down-fill": {
    "value": "/icons/remix-icon.svg#contract-up-down-fill",
    "variable": "var(--assets-icons-contract-up-down-fill)"
  },
  "assets.icons.contract-up-down-line": {
    "value": "/icons/remix-icon.svg#contract-up-down-line",
    "variable": "var(--assets-icons-contract-up-down-line)"
  },
  "assets.icons.copilot-fill": {
    "value": "/icons/remix-icon.svg#copilot-fill",
    "variable": "var(--assets-icons-copilot-fill)"
  },
  "assets.icons.copilot-line": {
    "value": "/icons/remix-icon.svg#copilot-line",
    "variable": "var(--assets-icons-copilot-line)"
  },
  "assets.icons.corner-down-left-fill": {
    "value": "/icons/remix-icon.svg#corner-down-left-fill",
    "variable": "var(--assets-icons-corner-down-left-fill)"
  },
  "assets.icons.corner-down-left-line": {
    "value": "/icons/remix-icon.svg#corner-down-left-line",
    "variable": "var(--assets-icons-corner-down-left-line)"
  },
  "assets.icons.corner-down-right-fill": {
    "value": "/icons/remix-icon.svg#corner-down-right-fill",
    "variable": "var(--assets-icons-corner-down-right-fill)"
  },
  "assets.icons.corner-down-right-line": {
    "value": "/icons/remix-icon.svg#corner-down-right-line",
    "variable": "var(--assets-icons-corner-down-right-line)"
  },
  "assets.icons.corner-left-down-fill": {
    "value": "/icons/remix-icon.svg#corner-left-down-fill",
    "variable": "var(--assets-icons-corner-left-down-fill)"
  },
  "assets.icons.corner-left-down-line": {
    "value": "/icons/remix-icon.svg#corner-left-down-line",
    "variable": "var(--assets-icons-corner-left-down-line)"
  },
  "assets.icons.corner-left-up-fill": {
    "value": "/icons/remix-icon.svg#corner-left-up-fill",
    "variable": "var(--assets-icons-corner-left-up-fill)"
  },
  "assets.icons.corner-left-up-line": {
    "value": "/icons/remix-icon.svg#corner-left-up-line",
    "variable": "var(--assets-icons-corner-left-up-line)"
  },
  "assets.icons.corner-right-down-fill": {
    "value": "/icons/remix-icon.svg#corner-right-down-fill",
    "variable": "var(--assets-icons-corner-right-down-fill)"
  },
  "assets.icons.corner-right-down-line": {
    "value": "/icons/remix-icon.svg#corner-right-down-line",
    "variable": "var(--assets-icons-corner-right-down-line)"
  },
  "assets.icons.corner-right-up-fill": {
    "value": "/icons/remix-icon.svg#corner-right-up-fill",
    "variable": "var(--assets-icons-corner-right-up-fill)"
  },
  "assets.icons.corner-right-up-line": {
    "value": "/icons/remix-icon.svg#corner-right-up-line",
    "variable": "var(--assets-icons-corner-right-up-line)"
  },
  "assets.icons.corner-up-left-double-fill": {
    "value": "/icons/remix-icon.svg#corner-up-left-double-fill",
    "variable": "var(--assets-icons-corner-up-left-double-fill)"
  },
  "assets.icons.corner-up-left-double-line": {
    "value": "/icons/remix-icon.svg#corner-up-left-double-line",
    "variable": "var(--assets-icons-corner-up-left-double-line)"
  },
  "assets.icons.corner-up-left-fill": {
    "value": "/icons/remix-icon.svg#corner-up-left-fill",
    "variable": "var(--assets-icons-corner-up-left-fill)"
  },
  "assets.icons.corner-up-left-line": {
    "value": "/icons/remix-icon.svg#corner-up-left-line",
    "variable": "var(--assets-icons-corner-up-left-line)"
  },
  "assets.icons.corner-up-right-double-fill": {
    "value": "/icons/remix-icon.svg#corner-up-right-double-fill",
    "variable": "var(--assets-icons-corner-up-right-double-fill)"
  },
  "assets.icons.corner-up-right-double-line": {
    "value": "/icons/remix-icon.svg#corner-up-right-double-line",
    "variable": "var(--assets-icons-corner-up-right-double-line)"
  },
  "assets.icons.corner-up-right-fill": {
    "value": "/icons/remix-icon.svg#corner-up-right-fill",
    "variable": "var(--assets-icons-corner-up-right-fill)"
  },
  "assets.icons.corner-up-right-line": {
    "value": "/icons/remix-icon.svg#corner-up-right-line",
    "variable": "var(--assets-icons-corner-up-right-line)"
  },
  "assets.icons.cross-fill": {
    "value": "/icons/remix-icon.svg#cross-fill",
    "variable": "var(--assets-icons-cross-fill)"
  },
  "assets.icons.cross-line": {
    "value": "/icons/remix-icon.svg#cross-line",
    "variable": "var(--assets-icons-cross-line)"
  },
  "assets.icons.edge-new-fill": {
    "value": "/icons/remix-icon.svg#edge-new-fill",
    "variable": "var(--assets-icons-edge-new-fill)"
  },
  "assets.icons.edge-new-line": {
    "value": "/icons/remix-icon.svg#edge-new-line",
    "variable": "var(--assets-icons-edge-new-line)"
  },
  "assets.icons.equal-fill": {
    "value": "/icons/remix-icon.svg#equal-fill",
    "variable": "var(--assets-icons-equal-fill)"
  },
  "assets.icons.equal-line": {
    "value": "/icons/remix-icon.svg#equal-line",
    "variable": "var(--assets-icons-equal-line)"
  },
  "assets.icons.expand-left-fill": {
    "value": "/icons/remix-icon.svg#expand-left-fill",
    "variable": "var(--assets-icons-expand-left-fill)"
  },
  "assets.icons.expand-left-line": {
    "value": "/icons/remix-icon.svg#expand-left-line",
    "variable": "var(--assets-icons-expand-left-line)"
  },
  "assets.icons.expand-left-right-fill": {
    "value": "/icons/remix-icon.svg#expand-left-right-fill",
    "variable": "var(--assets-icons-expand-left-right-fill)"
  },
  "assets.icons.expand-left-right-line": {
    "value": "/icons/remix-icon.svg#expand-left-right-line",
    "variable": "var(--assets-icons-expand-left-right-line)"
  },
  "assets.icons.expand-right-fill": {
    "value": "/icons/remix-icon.svg#expand-right-fill",
    "variable": "var(--assets-icons-expand-right-fill)"
  },
  "assets.icons.expand-right-line": {
    "value": "/icons/remix-icon.svg#expand-right-line",
    "variable": "var(--assets-icons-expand-right-line)"
  },
  "assets.icons.expand-up-down-fill": {
    "value": "/icons/remix-icon.svg#expand-up-down-fill",
    "variable": "var(--assets-icons-expand-up-down-fill)"
  },
  "assets.icons.expand-up-down-line": {
    "value": "/icons/remix-icon.svg#expand-up-down-line",
    "variable": "var(--assets-icons-expand-up-down-line)"
  },
  "assets.icons.flickr-fill": {
    "value": "/icons/remix-icon.svg#flickr-fill",
    "variable": "var(--assets-icons-flickr-fill)"
  },
  "assets.icons.flickr-line": {
    "value": "/icons/remix-icon.svg#flickr-line",
    "variable": "var(--assets-icons-flickr-line)"
  },
  "assets.icons.forward-10-fill": {
    "value": "/icons/remix-icon.svg#forward-10-fill",
    "variable": "var(--assets-icons-forward-10-fill)"
  },
  "assets.icons.forward-10-line": {
    "value": "/icons/remix-icon.svg#forward-10-line",
    "variable": "var(--assets-icons-forward-10-line)"
  },
  "assets.icons.forward-15-fill": {
    "value": "/icons/remix-icon.svg#forward-15-fill",
    "variable": "var(--assets-icons-forward-15-fill)"
  },
  "assets.icons.forward-15-line": {
    "value": "/icons/remix-icon.svg#forward-15-line",
    "variable": "var(--assets-icons-forward-15-line)"
  },
  "assets.icons.forward-30-fill": {
    "value": "/icons/remix-icon.svg#forward-30-fill",
    "variable": "var(--assets-icons-forward-30-fill)"
  },
  "assets.icons.forward-30-line": {
    "value": "/icons/remix-icon.svg#forward-30-line",
    "variable": "var(--assets-icons-forward-30-line)"
  },
  "assets.icons.forward-5-fill": {
    "value": "/icons/remix-icon.svg#forward-5-fill",
    "variable": "var(--assets-icons-forward-5-fill)"
  },
  "assets.icons.forward-5-line": {
    "value": "/icons/remix-icon.svg#forward-5-line",
    "variable": "var(--assets-icons-forward-5-line)"
  },
  "assets.icons.graduation-cap-fill": {
    "value": "/icons/remix-icon.svg#graduation-cap-fill",
    "variable": "var(--assets-icons-graduation-cap-fill)"
  },
  "assets.icons.graduation-cap-line": {
    "value": "/icons/remix-icon.svg#graduation-cap-line",
    "variable": "var(--assets-icons-graduation-cap-line)"
  },
  "assets.icons.home-office-fill": {
    "value": "/icons/remix-icon.svg#home-office-fill",
    "variable": "var(--assets-icons-home-office-fill)"
  },
  "assets.icons.home-office-line": {
    "value": "/icons/remix-icon.svg#home-office-line",
    "variable": "var(--assets-icons-home-office-line)"
  },
  "assets.icons.hourglass-2-fill": {
    "value": "/icons/remix-icon.svg#hourglass-2-fill",
    "variable": "var(--assets-icons-hourglass-2-fill)"
  },
  "assets.icons.hourglass-2-line": {
    "value": "/icons/remix-icon.svg#hourglass-2-line",
    "variable": "var(--assets-icons-hourglass-2-line)"
  },
  "assets.icons.hourglass-fill": {
    "value": "/icons/remix-icon.svg#hourglass-fill",
    "variable": "var(--assets-icons-hourglass-fill)"
  },
  "assets.icons.hourglass-line": {
    "value": "/icons/remix-icon.svg#hourglass-line",
    "variable": "var(--assets-icons-hourglass-line)"
  },
  "assets.icons.javascript-fill": {
    "value": "/icons/remix-icon.svg#javascript-fill",
    "variable": "var(--assets-icons-javascript-fill)"
  },
  "assets.icons.javascript-line": {
    "value": "/icons/remix-icon.svg#javascript-line",
    "variable": "var(--assets-icons-javascript-line)"
  },
  "assets.icons.loop-left-fill": {
    "value": "/icons/remix-icon.svg#loop-left-fill",
    "variable": "var(--assets-icons-loop-left-fill)"
  },
  "assets.icons.loop-left-line": {
    "value": "/icons/remix-icon.svg#loop-left-line",
    "variable": "var(--assets-icons-loop-left-line)"
  },
  "assets.icons.loop-right-fill": {
    "value": "/icons/remix-icon.svg#loop-right-fill",
    "variable": "var(--assets-icons-loop-right-fill)"
  },
  "assets.icons.loop-right-line": {
    "value": "/icons/remix-icon.svg#loop-right-line",
    "variable": "var(--assets-icons-loop-right-line)"
  },
  "assets.icons.memories-fill": {
    "value": "/icons/remix-icon.svg#memories-fill",
    "variable": "var(--assets-icons-memories-fill)"
  },
  "assets.icons.memories-line": {
    "value": "/icons/remix-icon.svg#memories-line",
    "variable": "var(--assets-icons-memories-line)"
  },
  "assets.icons.meta-fill": {
    "value": "/icons/remix-icon.svg#meta-fill",
    "variable": "var(--assets-icons-meta-fill)"
  },
  "assets.icons.meta-line": {
    "value": "/icons/remix-icon.svg#meta-line",
    "variable": "var(--assets-icons-meta-line)"
  },
  "assets.icons.microsoft-loop-fill": {
    "value": "/icons/remix-icon.svg#microsoft-loop-fill",
    "variable": "var(--assets-icons-microsoft-loop-fill)"
  },
  "assets.icons.microsoft-loop-line": {
    "value": "/icons/remix-icon.svg#microsoft-loop-line",
    "variable": "var(--assets-icons-microsoft-loop-line)"
  },
  "assets.icons.nft-fill": {
    "value": "/icons/remix-icon.svg#nft-fill",
    "variable": "var(--assets-icons-nft-fill)"
  },
  "assets.icons.nft-line": {
    "value": "/icons/remix-icon.svg#nft-line",
    "variable": "var(--assets-icons-nft-line)"
  },
  "assets.icons.notion-fill": {
    "value": "/icons/remix-icon.svg#notion-fill",
    "variable": "var(--assets-icons-notion-fill)"
  },
  "assets.icons.notion-line": {
    "value": "/icons/remix-icon.svg#notion-line",
    "variable": "var(--assets-icons-notion-line)"
  },
  "assets.icons.openai-fill": {
    "value": "/icons/remix-icon.svg#openai-fill",
    "variable": "var(--assets-icons-openai-fill)"
  },
  "assets.icons.openai-line": {
    "value": "/icons/remix-icon.svg#openai-line",
    "variable": "var(--assets-icons-openai-line)"
  },
  "assets.icons.overline": {
    "value": "/icons/remix-icon.svg#overline",
    "variable": "var(--assets-icons-overline)"
  },
  "assets.icons.p2p-fill": {
    "value": "/icons/remix-icon.svg#p2p-fill",
    "variable": "var(--assets-icons-p2p-fill)"
  },
  "assets.icons.p2p-line": {
    "value": "/icons/remix-icon.svg#p2p-line",
    "variable": "var(--assets-icons-p2p-line)"
  },
  "assets.icons.presentation-fill": {
    "value": "/icons/remix-icon.svg#presentation-fill",
    "variable": "var(--assets-icons-presentation-fill)"
  },
  "assets.icons.presentation-line": {
    "value": "/icons/remix-icon.svg#presentation-line",
    "variable": "var(--assets-icons-presentation-line)"
  },
  "assets.icons.replay-10-fill": {
    "value": "/icons/remix-icon.svg#replay-10-fill",
    "variable": "var(--assets-icons-replay-10-fill)"
  },
  "assets.icons.replay-10-line": {
    "value": "/icons/remix-icon.svg#replay-10-line",
    "variable": "var(--assets-icons-replay-10-line)"
  },
  "assets.icons.replay-15-fill": {
    "value": "/icons/remix-icon.svg#replay-15-fill",
    "variable": "var(--assets-icons-replay-15-fill)"
  },
  "assets.icons.replay-15-line": {
    "value": "/icons/remix-icon.svg#replay-15-line",
    "variable": "var(--assets-icons-replay-15-line)"
  },
  "assets.icons.replay-30-fill": {
    "value": "/icons/remix-icon.svg#replay-30-fill",
    "variable": "var(--assets-icons-replay-30-fill)"
  },
  "assets.icons.replay-30-line": {
    "value": "/icons/remix-icon.svg#replay-30-line",
    "variable": "var(--assets-icons-replay-30-line)"
  },
  "assets.icons.replay-5-fill": {
    "value": "/icons/remix-icon.svg#replay-5-fill",
    "variable": "var(--assets-icons-replay-5-fill)"
  },
  "assets.icons.replay-5-line": {
    "value": "/icons/remix-icon.svg#replay-5-line",
    "variable": "var(--assets-icons-replay-5-line)"
  },
  "assets.icons.school-fill": {
    "value": "/icons/remix-icon.svg#school-fill",
    "variable": "var(--assets-icons-school-fill)"
  },
  "assets.icons.school-line": {
    "value": "/icons/remix-icon.svg#school-line",
    "variable": "var(--assets-icons-school-line)"
  },
  "assets.icons.shining-2-fill": {
    "value": "/icons/remix-icon.svg#shining-2-fill",
    "variable": "var(--assets-icons-shining-2-fill)"
  },
  "assets.icons.shining-2-line": {
    "value": "/icons/remix-icon.svg#shining-2-line",
    "variable": "var(--assets-icons-shining-2-line)"
  },
  "assets.icons.shining-fill": {
    "value": "/icons/remix-icon.svg#shining-fill",
    "variable": "var(--assets-icons-shining-fill)"
  },
  "assets.icons.shining-line": {
    "value": "/icons/remix-icon.svg#shining-line",
    "variable": "var(--assets-icons-shining-line)"
  },
  "assets.icons.sketching": {
    "value": "/icons/remix-icon.svg#sketching",
    "variable": "var(--assets-icons-sketching)"
  },
  "assets.icons.skip-down-fill": {
    "value": "/icons/remix-icon.svg#skip-down-fill",
    "variable": "var(--assets-icons-skip-down-fill)"
  },
  "assets.icons.skip-down-line": {
    "value": "/icons/remix-icon.svg#skip-down-line",
    "variable": "var(--assets-icons-skip-down-line)"
  },
  "assets.icons.skip-left-fill": {
    "value": "/icons/remix-icon.svg#skip-left-fill",
    "variable": "var(--assets-icons-skip-left-fill)"
  },
  "assets.icons.skip-left-line": {
    "value": "/icons/remix-icon.svg#skip-left-line",
    "variable": "var(--assets-icons-skip-left-line)"
  },
  "assets.icons.skip-right-fill": {
    "value": "/icons/remix-icon.svg#skip-right-fill",
    "variable": "var(--assets-icons-skip-right-fill)"
  },
  "assets.icons.skip-right-line": {
    "value": "/icons/remix-icon.svg#skip-right-line",
    "variable": "var(--assets-icons-skip-right-line)"
  },
  "assets.icons.skip-up-fill": {
    "value": "/icons/remix-icon.svg#skip-up-fill",
    "variable": "var(--assets-icons-skip-up-fill)"
  },
  "assets.icons.skip-up-line": {
    "value": "/icons/remix-icon.svg#skip-up-line",
    "variable": "var(--assets-icons-skip-up-line)"
  },
  "assets.icons.slow-down-fill": {
    "value": "/icons/remix-icon.svg#slow-down-fill",
    "variable": "var(--assets-icons-slow-down-fill)"
  },
  "assets.icons.slow-down-line": {
    "value": "/icons/remix-icon.svg#slow-down-line",
    "variable": "var(--assets-icons-slow-down-line)"
  },
  "assets.icons.sparkling-2-fill": {
    "value": "/icons/remix-icon.svg#sparkling-2-fill",
    "variable": "var(--assets-icons-sparkling-2-fill)"
  },
  "assets.icons.sparkling-2-line": {
    "value": "/icons/remix-icon.svg#sparkling-2-line",
    "variable": "var(--assets-icons-sparkling-2-line)"
  },
  "assets.icons.sparkling-fill": {
    "value": "/icons/remix-icon.svg#sparkling-fill",
    "variable": "var(--assets-icons-sparkling-fill)"
  },
  "assets.icons.sparkling-line": {
    "value": "/icons/remix-icon.svg#sparkling-line",
    "variable": "var(--assets-icons-sparkling-line)"
  },
  "assets.icons.speak-fill": {
    "value": "/icons/remix-icon.svg#speak-fill",
    "variable": "var(--assets-icons-speak-fill)"
  },
  "assets.icons.speak-line": {
    "value": "/icons/remix-icon.svg#speak-line",
    "variable": "var(--assets-icons-speak-line)"
  },
  "assets.icons.speed-up-fill": {
    "value": "/icons/remix-icon.svg#speed-up-fill",
    "variable": "var(--assets-icons-speed-up-fill)"
  },
  "assets.icons.speed-up-line": {
    "value": "/icons/remix-icon.svg#speed-up-line",
    "variable": "var(--assets-icons-speed-up-line)"
  },
  "assets.icons.tiktok-fill": {
    "value": "/icons/remix-icon.svg#tiktok-fill",
    "variable": "var(--assets-icons-tiktok-fill)"
  },
  "assets.icons.tiktok-line": {
    "value": "/icons/remix-icon.svg#tiktok-line",
    "variable": "var(--assets-icons-tiktok-line)"
  },
  "assets.icons.token-swap-fill": {
    "value": "/icons/remix-icon.svg#token-swap-fill",
    "variable": "var(--assets-icons-token-swap-fill)"
  },
  "assets.icons.token-swap-line": {
    "value": "/icons/remix-icon.svg#token-swap-line",
    "variable": "var(--assets-icons-token-swap-line)"
  },
  "assets.icons.unpin-fill": {
    "value": "/icons/remix-icon.svg#unpin-fill",
    "variable": "var(--assets-icons-unpin-fill)"
  },
  "assets.icons.unpin-line": {
    "value": "/icons/remix-icon.svg#unpin-line",
    "variable": "var(--assets-icons-unpin-line)"
  },
  "assets.icons.wechat-channels-fill": {
    "value": "/icons/remix-icon.svg#wechat-channels-fill",
    "variable": "var(--assets-icons-wechat-channels-fill)"
  },
  "assets.icons.wechat-channels-line": {
    "value": "/icons/remix-icon.svg#wechat-channels-line",
    "variable": "var(--assets-icons-wechat-channels-line)"
  },
  "assets.icons.wordpress-fill": {
    "value": "/icons/remix-icon.svg#wordpress-fill",
    "variable": "var(--assets-icons-wordpress-fill)"
  },
  "assets.icons.wordpress-line": {
    "value": "/icons/remix-icon.svg#wordpress-line",
    "variable": "var(--assets-icons-wordpress-line)"
  },
  "assets.icons.blender-fill": {
    "value": "/icons/remix-icon.svg#blender-fill",
    "variable": "var(--assets-icons-blender-fill)"
  },
  "assets.icons.blender-line": {
    "value": "/icons/remix-icon.svg#blender-line",
    "variable": "var(--assets-icons-blender-line)"
  },
  "assets.icons.emoji-sticker-fill": {
    "value": "/icons/remix-icon.svg#emoji-sticker-fill",
    "variable": "var(--assets-icons-emoji-sticker-fill)"
  },
  "assets.icons.emoji-sticker-line": {
    "value": "/icons/remix-icon.svg#emoji-sticker-line",
    "variable": "var(--assets-icons-emoji-sticker-line)"
  },
  "assets.icons.git-close-pull-request-fill": {
    "value": "/icons/remix-icon.svg#git-close-pull-request-fill",
    "variable": "var(--assets-icons-git-close-pull-request-fill)"
  },
  "assets.icons.git-close-pull-request-line": {
    "value": "/icons/remix-icon.svg#git-close-pull-request-line",
    "variable": "var(--assets-icons-git-close-pull-request-line)"
  },
  "assets.icons.instance-fill": {
    "value": "/icons/remix-icon.svg#instance-fill",
    "variable": "var(--assets-icons-instance-fill)"
  },
  "assets.icons.instance-line": {
    "value": "/icons/remix-icon.svg#instance-line",
    "variable": "var(--assets-icons-instance-line)"
  },
  "assets.icons.megaphone-fill": {
    "value": "/icons/remix-icon.svg#megaphone-fill",
    "variable": "var(--assets-icons-megaphone-fill)"
  },
  "assets.icons.megaphone-line": {
    "value": "/icons/remix-icon.svg#megaphone-line",
    "variable": "var(--assets-icons-megaphone-line)"
  },
  "assets.icons.pass-expired-fill": {
    "value": "/icons/remix-icon.svg#pass-expired-fill",
    "variable": "var(--assets-icons-pass-expired-fill)"
  },
  "assets.icons.pass-expired-line": {
    "value": "/icons/remix-icon.svg#pass-expired-line",
    "variable": "var(--assets-icons-pass-expired-line)"
  },
  "assets.icons.pass-pending-fill": {
    "value": "/icons/remix-icon.svg#pass-pending-fill",
    "variable": "var(--assets-icons-pass-pending-fill)"
  },
  "assets.icons.pass-pending-line": {
    "value": "/icons/remix-icon.svg#pass-pending-line",
    "variable": "var(--assets-icons-pass-pending-line)"
  },
  "assets.icons.pass-valid-fill": {
    "value": "/icons/remix-icon.svg#pass-valid-fill",
    "variable": "var(--assets-icons-pass-valid-fill)"
  },
  "assets.icons.pass-valid-line": {
    "value": "/icons/remix-icon.svg#pass-valid-line",
    "variable": "var(--assets-icons-pass-valid-line)"
  },
  "assets.icons.ai-generate": {
    "value": "/icons/remix-icon.svg#ai-generate",
    "variable": "var(--assets-icons-ai-generate)"
  },
  "assets.icons.calendar-close-fill": {
    "value": "/icons/remix-icon.svg#calendar-close-fill",
    "variable": "var(--assets-icons-calendar-close-fill)"
  },
  "assets.icons.calendar-close-line": {
    "value": "/icons/remix-icon.svg#calendar-close-line",
    "variable": "var(--assets-icons-calendar-close-line)"
  },
  "assets.icons.draggable": {
    "value": "/icons/remix-icon.svg#draggable",
    "variable": "var(--assets-icons-draggable)"
  },
  "assets.icons.font-family": {
    "value": "/icons/remix-icon.svg#font-family",
    "variable": "var(--assets-icons-font-family)"
  },
  "assets.icons.font-mono": {
    "value": "/icons/remix-icon.svg#font-mono",
    "variable": "var(--assets-icons-font-mono)"
  },
  "assets.icons.font-sans-serif": {
    "value": "/icons/remix-icon.svg#font-sans-serif",
    "variable": "var(--assets-icons-font-sans-serif)"
  },
  "assets.icons.font-sans": {
    "value": "/icons/remix-icon.svg#font-sans",
    "variable": "var(--assets-icons-font-sans)"
  },
  "assets.icons.hard-drive-3-fill": {
    "value": "/icons/remix-icon.svg#hard-drive-3-fill",
    "variable": "var(--assets-icons-hard-drive-3-fill)"
  },
  "assets.icons.hard-drive-3-line": {
    "value": "/icons/remix-icon.svg#hard-drive-3-line",
    "variable": "var(--assets-icons-hard-drive-3-line)"
  },
  "assets.icons.kick-fill": {
    "value": "/icons/remix-icon.svg#kick-fill",
    "variable": "var(--assets-icons-kick-fill)"
  },
  "assets.icons.kick-line": {
    "value": "/icons/remix-icon.svg#kick-line",
    "variable": "var(--assets-icons-kick-line)"
  },
  "assets.icons.list-check-3": {
    "value": "/icons/remix-icon.svg#list-check-3",
    "variable": "var(--assets-icons-list-check-3)"
  },
  "assets.icons.list-indefinite": {
    "value": "/icons/remix-icon.svg#list-indefinite",
    "variable": "var(--assets-icons-list-indefinite)"
  },
  "assets.icons.list-ordered-2": {
    "value": "/icons/remix-icon.svg#list-ordered-2",
    "variable": "var(--assets-icons-list-ordered-2)"
  },
  "assets.icons.list-radio": {
    "value": "/icons/remix-icon.svg#list-radio",
    "variable": "var(--assets-icons-list-radio)"
  },
  "assets.icons.openbase-fill": {
    "value": "/icons/remix-icon.svg#openbase-fill",
    "variable": "var(--assets-icons-openbase-fill)"
  },
  "assets.icons.openbase-line": {
    "value": "/icons/remix-icon.svg#openbase-line",
    "variable": "var(--assets-icons-openbase-line)"
  },
  "assets.icons.planet-fill": {
    "value": "/icons/remix-icon.svg#planet-fill",
    "variable": "var(--assets-icons-planet-fill)"
  },
  "assets.icons.planet-line": {
    "value": "/icons/remix-icon.svg#planet-line",
    "variable": "var(--assets-icons-planet-line)"
  },
  "assets.icons.prohibited-fill": {
    "value": "/icons/remix-icon.svg#prohibited-fill",
    "variable": "var(--assets-icons-prohibited-fill)"
  },
  "assets.icons.prohibited-line": {
    "value": "/icons/remix-icon.svg#prohibited-line",
    "variable": "var(--assets-icons-prohibited-line)"
  },
  "assets.icons.quote-text": {
    "value": "/icons/remix-icon.svg#quote-text",
    "variable": "var(--assets-icons-quote-text)"
  },
  "assets.icons.seo-fill": {
    "value": "/icons/remix-icon.svg#seo-fill",
    "variable": "var(--assets-icons-seo-fill)"
  },
  "assets.icons.seo-line": {
    "value": "/icons/remix-icon.svg#seo-line",
    "variable": "var(--assets-icons-seo-line)"
  },
  "assets.icons.slash-commands": {
    "value": "/icons/remix-icon.svg#slash-commands",
    "variable": "var(--assets-icons-slash-commands)"
  },
  "assets.icons.archive-2-fill": {
    "value": "/icons/remix-icon.svg#archive-2-fill",
    "variable": "var(--assets-icons-archive-2-fill)"
  },
  "assets.icons.archive-2-line": {
    "value": "/icons/remix-icon.svg#archive-2-line",
    "variable": "var(--assets-icons-archive-2-line)"
  },
  "assets.icons.inbox-2-fill": {
    "value": "/icons/remix-icon.svg#inbox-2-fill",
    "variable": "var(--assets-icons-inbox-2-fill)"
  },
  "assets.icons.inbox-2-line": {
    "value": "/icons/remix-icon.svg#inbox-2-line",
    "variable": "var(--assets-icons-inbox-2-line)"
  },
  "assets.icons.shake-hands-fill": {
    "value": "/icons/remix-icon.svg#shake-hands-fill",
    "variable": "var(--assets-icons-shake-hands-fill)"
  },
  "assets.icons.shake-hands-line": {
    "value": "/icons/remix-icon.svg#shake-hands-line",
    "variable": "var(--assets-icons-shake-hands-line)"
  },
  "assets.icons.supabase-fill": {
    "value": "/icons/remix-icon.svg#supabase-fill",
    "variable": "var(--assets-icons-supabase-fill)"
  },
  "assets.icons.supabase-line": {
    "value": "/icons/remix-icon.svg#supabase-line",
    "variable": "var(--assets-icons-supabase-line)"
  },
  "assets.icons.water-percent-fill": {
    "value": "/icons/remix-icon.svg#water-percent-fill",
    "variable": "var(--assets-icons-water-percent-fill)"
  },
  "assets.icons.water-percent-line": {
    "value": "/icons/remix-icon.svg#water-percent-line",
    "variable": "var(--assets-icons-water-percent-line)"
  },
  "assets.icons.yuque-fill": {
    "value": "/icons/remix-icon.svg#yuque-fill",
    "variable": "var(--assets-icons-yuque-fill)"
  },
  "assets.icons.yuque-line": {
    "value": "/icons/remix-icon.svg#yuque-line",
    "variable": "var(--assets-icons-yuque-line)"
  },
  "assets.icons.crosshair-2-fill": {
    "value": "/icons/remix-icon.svg#crosshair-2-fill",
    "variable": "var(--assets-icons-crosshair-2-fill)"
  },
  "assets.icons.crosshair-2-line": {
    "value": "/icons/remix-icon.svg#crosshair-2-line",
    "variable": "var(--assets-icons-crosshair-2-line)"
  },
  "assets.icons.crosshair-fill": {
    "value": "/icons/remix-icon.svg#crosshair-fill",
    "variable": "var(--assets-icons-crosshair-fill)"
  },
  "assets.icons.crosshair-line": {
    "value": "/icons/remix-icon.svg#crosshair-line",
    "variable": "var(--assets-icons-crosshair-line)"
  },
  "assets.icons.file-close-fill": {
    "value": "/icons/remix-icon.svg#file-close-fill",
    "variable": "var(--assets-icons-file-close-fill)"
  },
  "assets.icons.file-close-line": {
    "value": "/icons/remix-icon.svg#file-close-line",
    "variable": "var(--assets-icons-file-close-line)"
  },
  "assets.icons.infinity-fill": {
    "value": "/icons/remix-icon.svg#infinity-fill",
    "variable": "var(--assets-icons-infinity-fill)"
  },
  "assets.icons.infinity-line": {
    "value": "/icons/remix-icon.svg#infinity-line",
    "variable": "var(--assets-icons-infinity-line)"
  },
  "assets.icons.rfid-fill": {
    "value": "/icons/remix-icon.svg#rfid-fill",
    "variable": "var(--assets-icons-rfid-fill)"
  },
  "assets.icons.rfid-line": {
    "value": "/icons/remix-icon.svg#rfid-line",
    "variable": "var(--assets-icons-rfid-line)"
  },
  "assets.icons.slash-commands-2": {
    "value": "/icons/remix-icon.svg#slash-commands-2",
    "variable": "var(--assets-icons-slash-commands-2)"
  },
  "assets.icons.user-forbid-fill": {
    "value": "/icons/remix-icon.svg#user-forbid-fill",
    "variable": "var(--assets-icons-user-forbid-fill)"
  },
  "assets.icons.user-forbid-line": {
    "value": "/icons/remix-icon.svg#user-forbid-line",
    "variable": "var(--assets-icons-user-forbid-line)"
  },
  "assets.icons.beer-fill": {
    "value": "/icons/remix-icon.svg#beer-fill",
    "variable": "var(--assets-icons-beer-fill)"
  },
  "assets.icons.beer-line": {
    "value": "/icons/remix-icon.svg#beer-line",
    "variable": "var(--assets-icons-beer-line)"
  },
  "assets.icons.circle-fill": {
    "value": "/icons/remix-icon.svg#circle-fill",
    "variable": "var(--assets-icons-circle-fill)"
  },
  "assets.icons.circle-line": {
    "value": "/icons/remix-icon.svg#circle-line",
    "variable": "var(--assets-icons-circle-line)"
  },
  "assets.icons.dropdown-list": {
    "value": "/icons/remix-icon.svg#dropdown-list",
    "variable": "var(--assets-icons-dropdown-list)"
  },
  "assets.icons.file-image-fill": {
    "value": "/icons/remix-icon.svg#file-image-fill",
    "variable": "var(--assets-icons-file-image-fill)"
  },
  "assets.icons.file-image-line": {
    "value": "/icons/remix-icon.svg#file-image-line",
    "variable": "var(--assets-icons-file-image-line)"
  },
  "assets.icons.file-pdf-2-fill": {
    "value": "/icons/remix-icon.svg#file-pdf-2-fill",
    "variable": "var(--assets-icons-file-pdf-2-fill)"
  },
  "assets.icons.file-pdf-2-line": {
    "value": "/icons/remix-icon.svg#file-pdf-2-line",
    "variable": "var(--assets-icons-file-pdf-2-line)"
  },
  "assets.icons.file-video-fill": {
    "value": "/icons/remix-icon.svg#file-video-fill",
    "variable": "var(--assets-icons-file-video-fill)"
  },
  "assets.icons.file-video-line": {
    "value": "/icons/remix-icon.svg#file-video-line",
    "variable": "var(--assets-icons-file-video-line)"
  },
  "assets.icons.folder-image-fill": {
    "value": "/icons/remix-icon.svg#folder-image-fill",
    "variable": "var(--assets-icons-folder-image-fill)"
  },
  "assets.icons.folder-image-line": {
    "value": "/icons/remix-icon.svg#folder-image-line",
    "variable": "var(--assets-icons-folder-image-line)"
  },
  "assets.icons.folder-video-fill": {
    "value": "/icons/remix-icon.svg#folder-video-fill",
    "variable": "var(--assets-icons-folder-video-fill)"
  },
  "assets.icons.folder-video-line": {
    "value": "/icons/remix-icon.svg#folder-video-line",
    "variable": "var(--assets-icons-folder-video-line)"
  },
  "assets.icons.hexagon-fill": {
    "value": "/icons/remix-icon.svg#hexagon-fill",
    "variable": "var(--assets-icons-hexagon-fill)"
  },
  "assets.icons.hexagon-line": {
    "value": "/icons/remix-icon.svg#hexagon-line",
    "variable": "var(--assets-icons-hexagon-line)"
  },
  "assets.icons.menu-search-fill": {
    "value": "/icons/remix-icon.svg#menu-search-fill",
    "variable": "var(--assets-icons-menu-search-fill)"
  },
  "assets.icons.menu-search-line": {
    "value": "/icons/remix-icon.svg#menu-search-line",
    "variable": "var(--assets-icons-menu-search-line)"
  },
  "assets.icons.octagon-fill": {
    "value": "/icons/remix-icon.svg#octagon-fill",
    "variable": "var(--assets-icons-octagon-fill)"
  },
  "assets.icons.octagon-line": {
    "value": "/icons/remix-icon.svg#octagon-line",
    "variable": "var(--assets-icons-octagon-line)"
  },
  "assets.icons.pentagon-fill": {
    "value": "/icons/remix-icon.svg#pentagon-fill",
    "variable": "var(--assets-icons-pentagon-fill)"
  },
  "assets.icons.pentagon-line": {
    "value": "/icons/remix-icon.svg#pentagon-line",
    "variable": "var(--assets-icons-pentagon-line)"
  },
  "assets.icons.rectangle-fill": {
    "value": "/icons/remix-icon.svg#rectangle-fill",
    "variable": "var(--assets-icons-rectangle-fill)"
  },
  "assets.icons.rectangle-line": {
    "value": "/icons/remix-icon.svg#rectangle-line",
    "variable": "var(--assets-icons-rectangle-line)"
  },
  "assets.icons.robot-2-fill": {
    "value": "/icons/remix-icon.svg#robot-2-fill",
    "variable": "var(--assets-icons-robot-2-fill)"
  },
  "assets.icons.robot-2-line": {
    "value": "/icons/remix-icon.svg#robot-2-line",
    "variable": "var(--assets-icons-robot-2-line)"
  },
  "assets.icons.shapes-fill": {
    "value": "/icons/remix-icon.svg#shapes-fill",
    "variable": "var(--assets-icons-shapes-fill)"
  },
  "assets.icons.shapes-line": {
    "value": "/icons/remix-icon.svg#shapes-line",
    "variable": "var(--assets-icons-shapes-line)"
  },
  "assets.icons.square-fill": {
    "value": "/icons/remix-icon.svg#square-fill",
    "variable": "var(--assets-icons-square-fill)"
  },
  "assets.icons.square-line": {
    "value": "/icons/remix-icon.svg#square-line",
    "variable": "var(--assets-icons-square-line)"
  },
  "assets.icons.tent-fill": {
    "value": "/icons/remix-icon.svg#tent-fill",
    "variable": "var(--assets-icons-tent-fill)"
  },
  "assets.icons.tent-line": {
    "value": "/icons/remix-icon.svg#tent-line",
    "variable": "var(--assets-icons-tent-line)"
  },
  "assets.icons.threads-fill": {
    "value": "/icons/remix-icon.svg#threads-fill",
    "variable": "var(--assets-icons-threads-fill)"
  },
  "assets.icons.threads-line": {
    "value": "/icons/remix-icon.svg#threads-line",
    "variable": "var(--assets-icons-threads-line)"
  },
  "assets.icons.tree-fill": {
    "value": "/icons/remix-icon.svg#tree-fill",
    "variable": "var(--assets-icons-tree-fill)"
  },
  "assets.icons.tree-line": {
    "value": "/icons/remix-icon.svg#tree-line",
    "variable": "var(--assets-icons-tree-line)"
  },
  "assets.icons.triangle-fill": {
    "value": "/icons/remix-icon.svg#triangle-fill",
    "variable": "var(--assets-icons-triangle-fill)"
  },
  "assets.icons.triangle-line": {
    "value": "/icons/remix-icon.svg#triangle-line",
    "variable": "var(--assets-icons-triangle-line)"
  },
  "assets.icons.twitter-x-fill": {
    "value": "/icons/remix-icon.svg#twitter-x-fill",
    "variable": "var(--assets-icons-twitter-x-fill)"
  },
  "assets.icons.twitter-x-line": {
    "value": "/icons/remix-icon.svg#twitter-x-line",
    "variable": "var(--assets-icons-twitter-x-line)"
  },
  "assets.icons.verified-badge-fill": {
    "value": "/icons/remix-icon.svg#verified-badge-fill",
    "variable": "var(--assets-icons-verified-badge-fill)"
  },
  "assets.icons.verified-badge-line": {
    "value": "/icons/remix-icon.svg#verified-badge-line",
    "variable": "var(--assets-icons-verified-badge-line)"
  },
  "assets.icons.armchair-fill": {
    "value": "/icons/remix-icon.svg#armchair-fill",
    "variable": "var(--assets-icons-armchair-fill)"
  },
  "assets.icons.armchair-line": {
    "value": "/icons/remix-icon.svg#armchair-line",
    "variable": "var(--assets-icons-armchair-line)"
  },
  "assets.icons.bnb-fill": {
    "value": "/icons/remix-icon.svg#bnb-fill",
    "variable": "var(--assets-icons-bnb-fill)"
  },
  "assets.icons.bnb-line": {
    "value": "/icons/remix-icon.svg#bnb-line",
    "variable": "var(--assets-icons-bnb-line)"
  },
  "assets.icons.bread-fill": {
    "value": "/icons/remix-icon.svg#bread-fill",
    "variable": "var(--assets-icons-bread-fill)"
  },
  "assets.icons.bread-line": {
    "value": "/icons/remix-icon.svg#bread-line",
    "variable": "var(--assets-icons-bread-line)"
  },
  "assets.icons.btc-fill": {
    "value": "/icons/remix-icon.svg#btc-fill",
    "variable": "var(--assets-icons-btc-fill)"
  },
  "assets.icons.btc-line": {
    "value": "/icons/remix-icon.svg#btc-line",
    "variable": "var(--assets-icons-btc-line)"
  },
  "assets.icons.calendar-schedule-fill": {
    "value": "/icons/remix-icon.svg#calendar-schedule-fill",
    "variable": "var(--assets-icons-calendar-schedule-fill)"
  },
  "assets.icons.calendar-schedule-line": {
    "value": "/icons/remix-icon.svg#calendar-schedule-line",
    "variable": "var(--assets-icons-calendar-schedule-line)"
  },
  "assets.icons.dice-1-fill": {
    "value": "/icons/remix-icon.svg#dice-1-fill",
    "variable": "var(--assets-icons-dice-1-fill)"
  },
  "assets.icons.dice-1-line": {
    "value": "/icons/remix-icon.svg#dice-1-line",
    "variable": "var(--assets-icons-dice-1-line)"
  },
  "assets.icons.dice-2-fill": {
    "value": "/icons/remix-icon.svg#dice-2-fill",
    "variable": "var(--assets-icons-dice-2-fill)"
  },
  "assets.icons.dice-2-line": {
    "value": "/icons/remix-icon.svg#dice-2-line",
    "variable": "var(--assets-icons-dice-2-line)"
  },
  "assets.icons.dice-3-fill": {
    "value": "/icons/remix-icon.svg#dice-3-fill",
    "variable": "var(--assets-icons-dice-3-fill)"
  },
  "assets.icons.dice-3-line": {
    "value": "/icons/remix-icon.svg#dice-3-line",
    "variable": "var(--assets-icons-dice-3-line)"
  },
  "assets.icons.dice-4-fill": {
    "value": "/icons/remix-icon.svg#dice-4-fill",
    "variable": "var(--assets-icons-dice-4-fill)"
  },
  "assets.icons.dice-4-line": {
    "value": "/icons/remix-icon.svg#dice-4-line",
    "variable": "var(--assets-icons-dice-4-line)"
  },
  "assets.icons.dice-5-fill": {
    "value": "/icons/remix-icon.svg#dice-5-fill",
    "variable": "var(--assets-icons-dice-5-fill)"
  },
  "assets.icons.dice-5-line": {
    "value": "/icons/remix-icon.svg#dice-5-line",
    "variable": "var(--assets-icons-dice-5-line)"
  },
  "assets.icons.dice-6-fill": {
    "value": "/icons/remix-icon.svg#dice-6-fill",
    "variable": "var(--assets-icons-dice-6-fill)"
  },
  "assets.icons.dice-6-line": {
    "value": "/icons/remix-icon.svg#dice-6-line",
    "variable": "var(--assets-icons-dice-6-line)"
  },
  "assets.icons.dice-fill": {
    "value": "/icons/remix-icon.svg#dice-fill",
    "variable": "var(--assets-icons-dice-fill)"
  },
  "assets.icons.dice-line": {
    "value": "/icons/remix-icon.svg#dice-line",
    "variable": "var(--assets-icons-dice-line)"
  },
  "assets.icons.drinks-fill": {
    "value": "/icons/remix-icon.svg#drinks-fill",
    "variable": "var(--assets-icons-drinks-fill)"
  },
  "assets.icons.drinks-line": {
    "value": "/icons/remix-icon.svg#drinks-line",
    "variable": "var(--assets-icons-drinks-line)"
  },
  "assets.icons.equalizer-2-fill": {
    "value": "/icons/remix-icon.svg#equalizer-2-fill",
    "variable": "var(--assets-icons-equalizer-2-fill)"
  },
  "assets.icons.equalizer-2-line": {
    "value": "/icons/remix-icon.svg#equalizer-2-line",
    "variable": "var(--assets-icons-equalizer-2-line)"
  },
  "assets.icons.equalizer-3-fill": {
    "value": "/icons/remix-icon.svg#equalizer-3-fill",
    "variable": "var(--assets-icons-equalizer-3-fill)"
  },
  "assets.icons.equalizer-3-line": {
    "value": "/icons/remix-icon.svg#equalizer-3-line",
    "variable": "var(--assets-icons-equalizer-3-line)"
  },
  "assets.icons.eth-fill": {
    "value": "/icons/remix-icon.svg#eth-fill",
    "variable": "var(--assets-icons-eth-fill)"
  },
  "assets.icons.eth-line": {
    "value": "/icons/remix-icon.svg#eth-line",
    "variable": "var(--assets-icons-eth-line)"
  },
  "assets.icons.flower-fill": {
    "value": "/icons/remix-icon.svg#flower-fill",
    "variable": "var(--assets-icons-flower-fill)"
  },
  "assets.icons.flower-line": {
    "value": "/icons/remix-icon.svg#flower-line",
    "variable": "var(--assets-icons-flower-line)"
  },
  "assets.icons.glasses-2-fill": {
    "value": "/icons/remix-icon.svg#glasses-2-fill",
    "variable": "var(--assets-icons-glasses-2-fill)"
  },
  "assets.icons.glasses-2-line": {
    "value": "/icons/remix-icon.svg#glasses-2-line",
    "variable": "var(--assets-icons-glasses-2-line)"
  },
  "assets.icons.glasses-fill": {
    "value": "/icons/remix-icon.svg#glasses-fill",
    "variable": "var(--assets-icons-glasses-fill)"
  },
  "assets.icons.glasses-line": {
    "value": "/icons/remix-icon.svg#glasses-line",
    "variable": "var(--assets-icons-glasses-line)"
  },
  "assets.icons.goggles-fill": {
    "value": "/icons/remix-icon.svg#goggles-fill",
    "variable": "var(--assets-icons-goggles-fill)"
  },
  "assets.icons.goggles-line": {
    "value": "/icons/remix-icon.svg#goggles-line",
    "variable": "var(--assets-icons-goggles-line)"
  },
  "assets.icons.image-circle-fill": {
    "value": "/icons/remix-icon.svg#image-circle-fill",
    "variable": "var(--assets-icons-image-circle-fill)"
  },
  "assets.icons.image-circle-line": {
    "value": "/icons/remix-icon.svg#image-circle-line",
    "variable": "var(--assets-icons-image-circle-line)"
  },
  "assets.icons.info-i": {
    "value": "/icons/remix-icon.svg#info-i",
    "variable": "var(--assets-icons-info-i)"
  },
  "assets.icons.money-rupee-circle-fill": {
    "value": "/icons/remix-icon.svg#money-rupee-circle-fill",
    "variable": "var(--assets-icons-money-rupee-circle-fill)"
  },
  "assets.icons.money-rupee-circle-line": {
    "value": "/icons/remix-icon.svg#money-rupee-circle-line",
    "variable": "var(--assets-icons-money-rupee-circle-line)"
  },
  "assets.icons.news-fill": {
    "value": "/icons/remix-icon.svg#news-fill",
    "variable": "var(--assets-icons-news-fill)"
  },
  "assets.icons.news-line": {
    "value": "/icons/remix-icon.svg#news-line",
    "variable": "var(--assets-icons-news-line)"
  },
  "assets.icons.robot-3-fill": {
    "value": "/icons/remix-icon.svg#robot-3-fill",
    "variable": "var(--assets-icons-robot-3-fill)"
  },
  "assets.icons.robot-3-line": {
    "value": "/icons/remix-icon.svg#robot-3-line",
    "variable": "var(--assets-icons-robot-3-line)"
  },
  "assets.icons.share-2-fill": {
    "value": "/icons/remix-icon.svg#share-2-fill",
    "variable": "var(--assets-icons-share-2-fill)"
  },
  "assets.icons.share-2-line": {
    "value": "/icons/remix-icon.svg#share-2-line",
    "variable": "var(--assets-icons-share-2-line)"
  },
  "assets.icons.sofa-fill": {
    "value": "/icons/remix-icon.svg#sofa-fill",
    "variable": "var(--assets-icons-sofa-fill)"
  },
  "assets.icons.sofa-line": {
    "value": "/icons/remix-icon.svg#sofa-line",
    "variable": "var(--assets-icons-sofa-line)"
  },
  "assets.icons.svelte-fill": {
    "value": "/icons/remix-icon.svg#svelte-fill",
    "variable": "var(--assets-icons-svelte-fill)"
  },
  "assets.icons.svelte-line": {
    "value": "/icons/remix-icon.svg#svelte-line",
    "variable": "var(--assets-icons-svelte-line)"
  },
  "assets.icons.vk-fill": {
    "value": "/icons/remix-icon.svg#vk-fill",
    "variable": "var(--assets-icons-vk-fill)"
  },
  "assets.icons.vk-line": {
    "value": "/icons/remix-icon.svg#vk-line",
    "variable": "var(--assets-icons-vk-line)"
  },
  "assets.icons.xrp-fill": {
    "value": "/icons/remix-icon.svg#xrp-fill",
    "variable": "var(--assets-icons-xrp-fill)"
  },
  "assets.icons.xrp-line": {
    "value": "/icons/remix-icon.svg#xrp-line",
    "variable": "var(--assets-icons-xrp-line)"
  },
  "assets.icons.xtz-fill": {
    "value": "/icons/remix-icon.svg#xtz-fill",
    "variable": "var(--assets-icons-xtz-fill)"
  },
  "assets.icons.xtz-line": {
    "value": "/icons/remix-icon.svg#xtz-line",
    "variable": "var(--assets-icons-xtz-line)"
  },
  "assets.icons.archive-stack-fill": {
    "value": "/icons/remix-icon.svg#archive-stack-fill",
    "variable": "var(--assets-icons-archive-stack-fill)"
  },
  "assets.icons.archive-stack-line": {
    "value": "/icons/remix-icon.svg#archive-stack-line",
    "variable": "var(--assets-icons-archive-stack-line)"
  },
  "assets.icons.bowl-fill": {
    "value": "/icons/remix-icon.svg#bowl-fill",
    "variable": "var(--assets-icons-bowl-fill)"
  },
  "assets.icons.bowl-line": {
    "value": "/icons/remix-icon.svg#bowl-line",
    "variable": "var(--assets-icons-bowl-line)"
  },
  "assets.icons.calendar-view": {
    "value": "/icons/remix-icon.svg#calendar-view",
    "variable": "var(--assets-icons-calendar-view)"
  },
  "assets.icons.carousel-view": {
    "value": "/icons/remix-icon.svg#carousel-view",
    "variable": "var(--assets-icons-carousel-view)"
  },
  "assets.icons.code-block": {
    "value": "/icons/remix-icon.svg#code-block",
    "variable": "var(--assets-icons-code-block)"
  },
  "assets.icons.color-filter-fill": {
    "value": "/icons/remix-icon.svg#color-filter-fill",
    "variable": "var(--assets-icons-color-filter-fill)"
  },
  "assets.icons.color-filter-line": {
    "value": "/icons/remix-icon.svg#color-filter-line",
    "variable": "var(--assets-icons-color-filter-line)"
  },
  "assets.icons.contacts-book-3-fill": {
    "value": "/icons/remix-icon.svg#contacts-book-3-fill",
    "variable": "var(--assets-icons-contacts-book-3-fill)"
  },
  "assets.icons.contacts-book-3-line": {
    "value": "/icons/remix-icon.svg#contacts-book-3-line",
    "variable": "var(--assets-icons-contacts-book-3-line)"
  },
  "assets.icons.contract-fill": {
    "value": "/icons/remix-icon.svg#contract-fill",
    "variable": "var(--assets-icons-contract-fill)"
  },
  "assets.icons.contract-line": {
    "value": "/icons/remix-icon.svg#contract-line",
    "variable": "var(--assets-icons-contract-line)"
  },
  "assets.icons.drinks-2-fill": {
    "value": "/icons/remix-icon.svg#drinks-2-fill",
    "variable": "var(--assets-icons-drinks-2-fill)"
  },
  "assets.icons.drinks-2-line": {
    "value": "/icons/remix-icon.svg#drinks-2-line",
    "variable": "var(--assets-icons-drinks-2-line)"
  },
  "assets.icons.export-fill": {
    "value": "/icons/remix-icon.svg#export-fill",
    "variable": "var(--assets-icons-export-fill)"
  },
  "assets.icons.export-line": {
    "value": "/icons/remix-icon.svg#export-line",
    "variable": "var(--assets-icons-export-line)"
  },
  "assets.icons.file-check-fill": {
    "value": "/icons/remix-icon.svg#file-check-fill",
    "variable": "var(--assets-icons-file-check-fill)"
  },
  "assets.icons.file-check-line": {
    "value": "/icons/remix-icon.svg#file-check-line",
    "variable": "var(--assets-icons-file-check-line)"
  },
  "assets.icons.focus-mode": {
    "value": "/icons/remix-icon.svg#focus-mode",
    "variable": "var(--assets-icons-focus-mode)"
  },
  "assets.icons.folder-6-fill": {
    "value": "/icons/remix-icon.svg#folder-6-fill",
    "variable": "var(--assets-icons-folder-6-fill)"
  },
  "assets.icons.folder-6-line": {
    "value": "/icons/remix-icon.svg#folder-6-line",
    "variable": "var(--assets-icons-folder-6-line)"
  },
  "assets.icons.folder-check-fill": {
    "value": "/icons/remix-icon.svg#folder-check-fill",
    "variable": "var(--assets-icons-folder-check-fill)"
  },
  "assets.icons.folder-check-line": {
    "value": "/icons/remix-icon.svg#folder-check-line",
    "variable": "var(--assets-icons-folder-check-line)"
  },
  "assets.icons.folder-close-fill": {
    "value": "/icons/remix-icon.svg#folder-close-fill",
    "variable": "var(--assets-icons-folder-close-fill)"
  },
  "assets.icons.folder-close-line": {
    "value": "/icons/remix-icon.svg#folder-close-line",
    "variable": "var(--assets-icons-folder-close-line)"
  },
  "assets.icons.folder-cloud-fill": {
    "value": "/icons/remix-icon.svg#folder-cloud-fill",
    "variable": "var(--assets-icons-folder-cloud-fill)"
  },
  "assets.icons.folder-cloud-line": {
    "value": "/icons/remix-icon.svg#folder-cloud-line",
    "variable": "var(--assets-icons-folder-cloud-line)"
  },
  "assets.icons.gallery-view-2": {
    "value": "/icons/remix-icon.svg#gallery-view-2",
    "variable": "var(--assets-icons-gallery-view-2)"
  },
  "assets.icons.gallery-view": {
    "value": "/icons/remix-icon.svg#gallery-view",
    "variable": "var(--assets-icons-gallery-view)"
  },
  "assets.icons.hand": {
    "value": "/icons/remix-icon.svg#hand",
    "variable": "var(--assets-icons-hand)"
  },
  "assets.icons.import-fill": {
    "value": "/icons/remix-icon.svg#import-fill",
    "variable": "var(--assets-icons-import-fill)"
  },
  "assets.icons.import-line": {
    "value": "/icons/remix-icon.svg#import-line",
    "variable": "var(--assets-icons-import-line)"
  },
  "assets.icons.information-2-fill": {
    "value": "/icons/remix-icon.svg#information-2-fill",
    "variable": "var(--assets-icons-information-2-fill)"
  },
  "assets.icons.information-2-line": {
    "value": "/icons/remix-icon.svg#information-2-line",
    "variable": "var(--assets-icons-information-2-line)"
  },
  "assets.icons.kanban-view-2": {
    "value": "/icons/remix-icon.svg#kanban-view-2",
    "variable": "var(--assets-icons-kanban-view-2)"
  },
  "assets.icons.kanban-view": {
    "value": "/icons/remix-icon.svg#kanban-view",
    "variable": "var(--assets-icons-kanban-view)"
  },
  "assets.icons.list-view": {
    "value": "/icons/remix-icon.svg#list-view",
    "variable": "var(--assets-icons-list-view)"
  },
  "assets.icons.lock-star-fill": {
    "value": "/icons/remix-icon.svg#lock-star-fill",
    "variable": "var(--assets-icons-lock-star-fill)"
  },
  "assets.icons.lock-star-line": {
    "value": "/icons/remix-icon.svg#lock-star-line",
    "variable": "var(--assets-icons-lock-star-line)"
  },
  "assets.icons.puzzle-2-fill": {
    "value": "/icons/remix-icon.svg#puzzle-2-fill",
    "variable": "var(--assets-icons-puzzle-2-fill)"
  },
  "assets.icons.puzzle-2-line": {
    "value": "/icons/remix-icon.svg#puzzle-2-line",
    "variable": "var(--assets-icons-puzzle-2-line)"
  },
  "assets.icons.puzzle-fill": {
    "value": "/icons/remix-icon.svg#puzzle-fill",
    "variable": "var(--assets-icons-puzzle-fill)"
  },
  "assets.icons.puzzle-line": {
    "value": "/icons/remix-icon.svg#puzzle-line",
    "variable": "var(--assets-icons-puzzle-line)"
  },
  "assets.icons.ram-2-fill": {
    "value": "/icons/remix-icon.svg#ram-2-fill",
    "variable": "var(--assets-icons-ram-2-fill)"
  },
  "assets.icons.ram-2-line": {
    "value": "/icons/remix-icon.svg#ram-2-line",
    "variable": "var(--assets-icons-ram-2-line)"
  },
  "assets.icons.ram-fill": {
    "value": "/icons/remix-icon.svg#ram-fill",
    "variable": "var(--assets-icons-ram-fill)"
  },
  "assets.icons.ram-line": {
    "value": "/icons/remix-icon.svg#ram-line",
    "variable": "var(--assets-icons-ram-line)"
  },
  "assets.icons.receipt-fill": {
    "value": "/icons/remix-icon.svg#receipt-fill",
    "variable": "var(--assets-icons-receipt-fill)"
  },
  "assets.icons.receipt-line": {
    "value": "/icons/remix-icon.svg#receipt-line",
    "variable": "var(--assets-icons-receipt-line)"
  },
  "assets.icons.shadow-fill": {
    "value": "/icons/remix-icon.svg#shadow-fill",
    "variable": "var(--assets-icons-shadow-fill)"
  },
  "assets.icons.shadow-line": {
    "value": "/icons/remix-icon.svg#shadow-line",
    "variable": "var(--assets-icons-shadow-line)"
  },
  "assets.icons.sidebar-fold-fill": {
    "value": "/icons/remix-icon.svg#sidebar-fold-fill",
    "variable": "var(--assets-icons-sidebar-fold-fill)"
  },
  "assets.icons.sidebar-fold-line": {
    "value": "/icons/remix-icon.svg#sidebar-fold-line",
    "variable": "var(--assets-icons-sidebar-fold-line)"
  },
  "assets.icons.sidebar-unfold-fill": {
    "value": "/icons/remix-icon.svg#sidebar-unfold-fill",
    "variable": "var(--assets-icons-sidebar-unfold-fill)"
  },
  "assets.icons.sidebar-unfold-line": {
    "value": "/icons/remix-icon.svg#sidebar-unfold-line",
    "variable": "var(--assets-icons-sidebar-unfold-line)"
  },
  "assets.icons.slideshow-view": {
    "value": "/icons/remix-icon.svg#slideshow-view",
    "variable": "var(--assets-icons-slideshow-view)"
  },
  "assets.icons.sort-alphabet-asc": {
    "value": "/icons/remix-icon.svg#sort-alphabet-asc",
    "variable": "var(--assets-icons-sort-alphabet-asc)"
  },
  "assets.icons.sort-alphabet-desc": {
    "value": "/icons/remix-icon.svg#sort-alphabet-desc",
    "variable": "var(--assets-icons-sort-alphabet-desc)"
  },
  "assets.icons.sort-number-asc": {
    "value": "/icons/remix-icon.svg#sort-number-asc",
    "variable": "var(--assets-icons-sort-number-asc)"
  },
  "assets.icons.sort-number-desc": {
    "value": "/icons/remix-icon.svg#sort-number-desc",
    "variable": "var(--assets-icons-sort-number-desc)"
  },
  "assets.icons.stacked-view": {
    "value": "/icons/remix-icon.svg#stacked-view",
    "variable": "var(--assets-icons-stacked-view)"
  },
  "assets.icons.sticky-note-add-fill": {
    "value": "/icons/remix-icon.svg#sticky-note-add-fill",
    "variable": "var(--assets-icons-sticky-note-add-fill)"
  },
  "assets.icons.sticky-note-add-line": {
    "value": "/icons/remix-icon.svg#sticky-note-add-line",
    "variable": "var(--assets-icons-sticky-note-add-line)"
  },
  "assets.icons.swap-2-fill": {
    "value": "/icons/remix-icon.svg#swap-2-fill",
    "variable": "var(--assets-icons-swap-2-fill)"
  },
  "assets.icons.swap-2-line": {
    "value": "/icons/remix-icon.svg#swap-2-line",
    "variable": "var(--assets-icons-swap-2-line)"
  },
  "assets.icons.swap-3-fill": {
    "value": "/icons/remix-icon.svg#swap-3-fill",
    "variable": "var(--assets-icons-swap-3-fill)"
  },
  "assets.icons.swap-3-line": {
    "value": "/icons/remix-icon.svg#swap-3-line",
    "variable": "var(--assets-icons-swap-3-line)"
  },
  "assets.icons.table-3": {
    "value": "/icons/remix-icon.svg#table-3",
    "variable": "var(--assets-icons-table-3)"
  },
  "assets.icons.table-view": {
    "value": "/icons/remix-icon.svg#table-view",
    "variable": "var(--assets-icons-table-view)"
  },
  "assets.icons.text-block": {
    "value": "/icons/remix-icon.svg#text-block",
    "variable": "var(--assets-icons-text-block)"
  },
  "assets.icons.text-snippet": {
    "value": "/icons/remix-icon.svg#text-snippet",
    "variable": "var(--assets-icons-text-snippet)"
  },
  "assets.icons.timeline-view": {
    "value": "/icons/remix-icon.svg#timeline-view",
    "variable": "var(--assets-icons-timeline-view)"
  },
  "assets.brandLogos.audemars-piguet": {
    "value": "/icons/brand-logos.svg#audemars-piguet",
    "variable": "var(--assets-brand-logos-audemars-piguet)"
  },
  "assets.brandLogos.a-lange-and-sohne": {
    "value": "/icons/brand-logos.svg#a-lange-and-sohne",
    "variable": "var(--assets-brand-logos-a-lange-and-sohne)"
  },
  "assets.brandLogos.seiko": {
    "value": "/icons/brand-logos.svg#seiko",
    "variable": "var(--assets-brand-logos-seiko)"
  },
  "assets.brandLogos.piaget": {
    "value": "/icons/brand-logos.svg#piaget",
    "variable": "var(--assets-brand-logos-piaget)"
  },
  "assets.brandLogos.vacheron-constantin": {
    "value": "/icons/brand-logos.svg#vacheron-constantin",
    "variable": "var(--assets-brand-logos-vacheron-constantin)"
  },
  "assets.brandLogos.rolex": {
    "value": "/icons/brand-logos.svg#rolex",
    "variable": "var(--assets-brand-logos-rolex)"
  },
  "assets.brandLogos.zenith": {
    "value": "/icons/brand-logos.svg#zenith",
    "variable": "var(--assets-brand-logos-zenith)"
  },
  "breakpoints.sm": {
    "value": "640px",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "1280px",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "1536px",
    "variable": "var(--breakpoints-2xl)"
  },
  "fonts.body": {
    "value": "var(--fonts-inter)",
    "variable": "var(--fonts-body)"
  },
  "fonts.heading": {
    "value": "var(--fonts-inter)",
    "variable": "var(--fonts-heading)"
  },
  "fonts.code": {
    "value": "var(--fonts-ibm-plex-mono)",
    "variable": "var(--fonts-code)"
  },
  "sizes.control.xs": {
    "value": "var(--sizes-7)",
    "variable": "var(--sizes-control-xs)"
  },
  "sizes.control.sm": {
    "value": "var(--sizes-8)",
    "variable": "var(--sizes-control-sm)"
  },
  "sizes.control.md": {
    "value": "var(--sizes-10)",
    "variable": "var(--sizes-control-md)"
  },
  "sizes.control.lg": {
    "value": "var(--sizes-12)",
    "variable": "var(--sizes-control-lg)"
  },
  "sizes.icon.xs": {
    "value": "var(--sizes-3)",
    "variable": "var(--sizes-icon-xs)"
  },
  "sizes.icon.sm": {
    "value": "var(--sizes-4)",
    "variable": "var(--sizes-icon-sm)"
  },
  "sizes.icon.md": {
    "value": "var(--sizes-5)",
    "variable": "var(--sizes-icon-md)"
  },
  "sizes.icon.lg": {
    "value": "var(--sizes-6)",
    "variable": "var(--sizes-icon-lg)"
  },
  "sizes.icon.xl": {
    "value": "var(--sizes-12)",
    "variable": "var(--sizes-icon-xl)"
  },
  "radii.control.xs": {
    "value": "6px",
    "variable": "var(--radii-control-xs)"
  },
  "radii.control.sm": {
    "value": "6px",
    "variable": "var(--radii-control-sm)"
  },
  "radii.control.md": {
    "value": "6px",
    "variable": "var(--radii-control-md)"
  },
  "radii.control.lg": {
    "value": "6px",
    "variable": "var(--radii-control-lg)"
  },
  "colors.neutral.background.solid": {
    "value": "var(--colors-neutral-background-solid)",
    "variable": "var(--colors-neutral-background-solid)"
  },
  "colors.neutral.background.solid.hover": {
    "value": "var(--colors-neutral-background-solid-hover)",
    "variable": "var(--colors-neutral-background-solid-hover)"
  },
  "colors.neutral.background.solid.active": {
    "value": "var(--colors-neutral-background-solid-active)",
    "variable": "var(--colors-neutral-background-solid-active)"
  },
  "colors.neutral.background.soft": {
    "value": "var(--colors-neutral-background-soft)",
    "variable": "var(--colors-neutral-background-soft)"
  },
  "colors.neutral.background.soft.hover": {
    "value": "var(--colors-neutral-background-soft-hover)",
    "variable": "var(--colors-neutral-background-soft-hover)"
  },
  "colors.neutral.background.soft.active": {
    "value": "var(--colors-neutral-background-soft-active)",
    "variable": "var(--colors-neutral-background-soft-active)"
  },
  "colors.neutral.foreground": {
    "value": "var(--colors-neutral-foreground)",
    "variable": "var(--colors-neutral-foreground)"
  },
  "colors.neutral.foreground.soft": {
    "value": "var(--colors-neutral-foreground-soft)",
    "variable": "var(--colors-neutral-foreground-soft)"
  },
  "colors.neutral.foreground.softest": {
    "value": "var(--colors-neutral-foreground-softest)",
    "variable": "var(--colors-neutral-foreground-softest)"
  },
  "colors.neutral.foreground.on.solid": {
    "value": "var(--colors-neutral-foreground-on-solid)",
    "variable": "var(--colors-neutral-foreground-on-solid)"
  },
  "colors.neutral.border": {
    "value": "var(--colors-neutral-border)",
    "variable": "var(--colors-neutral-border)"
  },
  "colors.neutral.border.faded": {
    "value": "var(--colors-neutral-border-faded)",
    "variable": "var(--colors-neutral-border-faded)"
  },
  "colors.disabled.background": {
    "value": "var(--colors-disabled-background)",
    "variable": "var(--colors-disabled-background)"
  },
  "colors.disabled.foreground": {
    "value": "var(--colors-disabled-foreground)",
    "variable": "var(--colors-disabled-foreground)"
  },
  "colors.disabled.border": {
    "value": "var(--colors-disabled-border)",
    "variable": "var(--colors-disabled-border)"
  },
  "colors.elevation.page": {
    "value": "var(--colors-elevation-page)",
    "variable": "var(--colors-elevation-page)"
  },
  "colors.elevation.popover": {
    "value": "var(--colors-elevation-popover)",
    "variable": "var(--colors-elevation-popover)"
  },
  "colors.elevation.dialog": {
    "value": "var(--colors-elevation-dialog)",
    "variable": "var(--colors-elevation-dialog)"
  },
  "colors.positive.background.solid": {
    "value": "var(--colors-positive-background-solid)",
    "variable": "var(--colors-positive-background-solid)"
  },
  "colors.positive.background.solid.hover": {
    "value": "var(--colors-positive-background-solid-hover)",
    "variable": "var(--colors-positive-background-solid-hover)"
  },
  "colors.positive.background.solid.active": {
    "value": "var(--colors-positive-background-solid-active)",
    "variable": "var(--colors-positive-background-solid-active)"
  },
  "colors.positive.background.soft": {
    "value": "var(--colors-positive-background-soft)",
    "variable": "var(--colors-positive-background-soft)"
  },
  "colors.positive.background.soft.hover": {
    "value": "var(--colors-positive-background-soft-hover)",
    "variable": "var(--colors-positive-background-soft-hover)"
  },
  "colors.positive.background.soft.active": {
    "value": "var(--colors-positive-background-soft-active)",
    "variable": "var(--colors-positive-background-soft-active)"
  },
  "colors.positive.foreground": {
    "value": "var(--colors-positive-foreground)",
    "variable": "var(--colors-positive-foreground)"
  },
  "colors.positive.foreground.on.solid": {
    "value": "var(--colors-positive-foreground-on-solid)",
    "variable": "var(--colors-positive-foreground-on-solid)"
  },
  "colors.positive.border": {
    "value": "var(--colors-positive-border)",
    "variable": "var(--colors-positive-border)"
  },
  "colors.positive.border.faded": {
    "value": "var(--colors-positive-border-faded)",
    "variable": "var(--colors-positive-border-faded)"
  },
  "colors.critical.background.solid": {
    "value": "var(--colors-critical-background-solid)",
    "variable": "var(--colors-critical-background-solid)"
  },
  "colors.critical.background.solid.hover": {
    "value": "var(--colors-critical-background-solid-hover)",
    "variable": "var(--colors-critical-background-solid-hover)"
  },
  "colors.critical.background.solid.active": {
    "value": "var(--colors-critical-background-solid-active)",
    "variable": "var(--colors-critical-background-solid-active)"
  },
  "colors.critical.background.soft": {
    "value": "var(--colors-critical-background-soft)",
    "variable": "var(--colors-critical-background-soft)"
  },
  "colors.critical.background.soft.hover": {
    "value": "var(--colors-critical-background-soft-hover)",
    "variable": "var(--colors-critical-background-soft-hover)"
  },
  "colors.critical.background.soft.active": {
    "value": "var(--colors-critical-background-soft-active)",
    "variable": "var(--colors-critical-background-soft-active)"
  },
  "colors.critical.foreground": {
    "value": "var(--colors-critical-foreground)",
    "variable": "var(--colors-critical-foreground)"
  },
  "colors.critical.foreground.on.solid": {
    "value": "var(--colors-critical-foreground-on-solid)",
    "variable": "var(--colors-critical-foreground-on-solid)"
  },
  "colors.critical.border": {
    "value": "var(--colors-critical-border)",
    "variable": "var(--colors-critical-border)"
  },
  "colors.critical.border.faded": {
    "value": "var(--colors-critical-border-faded)",
    "variable": "var(--colors-critical-border-faded)"
  },
  "colors.warning.background.solid": {
    "value": "var(--colors-warning-background-solid)",
    "variable": "var(--colors-warning-background-solid)"
  },
  "colors.warning.background.solid.hover": {
    "value": "var(--colors-warning-background-solid-hover)",
    "variable": "var(--colors-warning-background-solid-hover)"
  },
  "colors.warning.background.solid.active": {
    "value": "var(--colors-warning-background-solid-active)",
    "variable": "var(--colors-warning-background-solid-active)"
  },
  "colors.warning.background.soft": {
    "value": "var(--colors-warning-background-soft)",
    "variable": "var(--colors-warning-background-soft)"
  },
  "colors.warning.background.soft.hover": {
    "value": "var(--colors-warning-background-soft-hover)",
    "variable": "var(--colors-warning-background-soft-hover)"
  },
  "colors.warning.background.soft.active": {
    "value": "var(--colors-warning-background-soft-active)",
    "variable": "var(--colors-warning-background-soft-active)"
  },
  "colors.warning.foreground": {
    "value": "var(--colors-warning-foreground)",
    "variable": "var(--colors-warning-foreground)"
  },
  "colors.warning.foreground.on.solid": {
    "value": "var(--colors-warning-foreground-on-solid)",
    "variable": "var(--colors-warning-foreground-on-solid)"
  },
  "colors.warning.border": {
    "value": "var(--colors-warning-border)",
    "variable": "var(--colors-warning-border)"
  },
  "colors.warning.border.faded": {
    "value": "var(--colors-warning-border-faded)",
    "variable": "var(--colors-warning-border-faded)"
  },
  "colors.accent.background.solid": {
    "value": "var(--colors-accent-background-solid)",
    "variable": "var(--colors-accent-background-solid)"
  },
  "colors.accent.background.solid.hover": {
    "value": "var(--colors-accent-background-solid-hover)",
    "variable": "var(--colors-accent-background-solid-hover)"
  },
  "colors.accent.background.solid.active": {
    "value": "var(--colors-accent-background-solid-active)",
    "variable": "var(--colors-accent-background-solid-active)"
  },
  "colors.accent.background.soft": {
    "value": "var(--colors-accent-background-soft)",
    "variable": "var(--colors-accent-background-soft)"
  },
  "colors.accent.background.soft.hover": {
    "value": "var(--colors-accent-background-soft-hover)",
    "variable": "var(--colors-accent-background-soft-hover)"
  },
  "colors.accent.background.soft.active": {
    "value": "var(--colors-accent-background-soft-active)",
    "variable": "var(--colors-accent-background-soft-active)"
  },
  "colors.accent.foreground": {
    "value": "var(--colors-accent-foreground)",
    "variable": "var(--colors-accent-foreground)"
  },
  "colors.accent.foreground.on.solid": {
    "value": "var(--colors-accent-foreground-on-solid)",
    "variable": "var(--colors-accent-foreground-on-solid)"
  },
  "colors.accent.border": {
    "value": "var(--colors-accent-border)",
    "variable": "var(--colors-accent-border)"
  },
  "colors.accent.border.faded": {
    "value": "var(--colors-accent-border-faded)",
    "variable": "var(--colors-accent-border-faded)"
  },
  "colors.amber.1": {
    "value": "var(--colors-amber-1)",
    "variable": "var(--colors-amber-1)"
  },
  "colors.amber.2": {
    "value": "var(--colors-amber-2)",
    "variable": "var(--colors-amber-2)"
  },
  "colors.amber.3": {
    "value": "var(--colors-amber-3)",
    "variable": "var(--colors-amber-3)"
  },
  "colors.amber.4": {
    "value": "var(--colors-amber-4)",
    "variable": "var(--colors-amber-4)"
  },
  "colors.amber.5": {
    "value": "var(--colors-amber-5)",
    "variable": "var(--colors-amber-5)"
  },
  "colors.amber.6": {
    "value": "var(--colors-amber-6)",
    "variable": "var(--colors-amber-6)"
  },
  "colors.amber.7": {
    "value": "var(--colors-amber-7)",
    "variable": "var(--colors-amber-7)"
  },
  "colors.amber.8": {
    "value": "var(--colors-amber-8)",
    "variable": "var(--colors-amber-8)"
  },
  "colors.amber.9": {
    "value": "var(--colors-amber-9)",
    "variable": "var(--colors-amber-9)"
  },
  "colors.amber.10": {
    "value": "var(--colors-amber-10)",
    "variable": "var(--colors-amber-10)"
  },
  "colors.amber.11": {
    "value": "var(--colors-amber-11)",
    "variable": "var(--colors-amber-11)"
  },
  "colors.amber.12": {
    "value": "var(--colors-amber-12)",
    "variable": "var(--colors-amber-12)"
  },
  "colors.amber.light.1": {
    "value": "var(--colors-amber-light-1)",
    "variable": "var(--colors-amber-light-1)"
  },
  "colors.amber.light.2": {
    "value": "var(--colors-amber-light-2)",
    "variable": "var(--colors-amber-light-2)"
  },
  "colors.amber.light.3": {
    "value": "var(--colors-amber-light-3)",
    "variable": "var(--colors-amber-light-3)"
  },
  "colors.amber.light.4": {
    "value": "var(--colors-amber-light-4)",
    "variable": "var(--colors-amber-light-4)"
  },
  "colors.amber.light.5": {
    "value": "var(--colors-amber-light-5)",
    "variable": "var(--colors-amber-light-5)"
  },
  "colors.amber.light.6": {
    "value": "var(--colors-amber-light-6)",
    "variable": "var(--colors-amber-light-6)"
  },
  "colors.amber.light.7": {
    "value": "var(--colors-amber-light-7)",
    "variable": "var(--colors-amber-light-7)"
  },
  "colors.amber.light.8": {
    "value": "var(--colors-amber-light-8)",
    "variable": "var(--colors-amber-light-8)"
  },
  "colors.amber.light.9": {
    "value": "var(--colors-amber-light-9)",
    "variable": "var(--colors-amber-light-9)"
  },
  "colors.amber.light.10": {
    "value": "var(--colors-amber-light-10)",
    "variable": "var(--colors-amber-light-10)"
  },
  "colors.amber.light.11": {
    "value": "var(--colors-amber-light-11)",
    "variable": "var(--colors-amber-light-11)"
  },
  "colors.amber.light.12": {
    "value": "var(--colors-amber-light-12)",
    "variable": "var(--colors-amber-light-12)"
  },
  "colors.amber.light.a.1": {
    "value": "var(--colors-amber-light-a-1)",
    "variable": "var(--colors-amber-light-a-1)"
  },
  "colors.amber.light.a.2": {
    "value": "var(--colors-amber-light-a-2)",
    "variable": "var(--colors-amber-light-a-2)"
  },
  "colors.amber.light.a.3": {
    "value": "var(--colors-amber-light-a-3)",
    "variable": "var(--colors-amber-light-a-3)"
  },
  "colors.amber.light.a.4": {
    "value": "var(--colors-amber-light-a-4)",
    "variable": "var(--colors-amber-light-a-4)"
  },
  "colors.amber.light.a.5": {
    "value": "var(--colors-amber-light-a-5)",
    "variable": "var(--colors-amber-light-a-5)"
  },
  "colors.amber.light.a.6": {
    "value": "var(--colors-amber-light-a-6)",
    "variable": "var(--colors-amber-light-a-6)"
  },
  "colors.amber.light.a.7": {
    "value": "var(--colors-amber-light-a-7)",
    "variable": "var(--colors-amber-light-a-7)"
  },
  "colors.amber.light.a.8": {
    "value": "var(--colors-amber-light-a-8)",
    "variable": "var(--colors-amber-light-a-8)"
  },
  "colors.amber.light.a.9": {
    "value": "var(--colors-amber-light-a-9)",
    "variable": "var(--colors-amber-light-a-9)"
  },
  "colors.amber.light.a.10": {
    "value": "var(--colors-amber-light-a-10)",
    "variable": "var(--colors-amber-light-a-10)"
  },
  "colors.amber.light.a.11": {
    "value": "var(--colors-amber-light-a-11)",
    "variable": "var(--colors-amber-light-a-11)"
  },
  "colors.amber.light.a.12": {
    "value": "var(--colors-amber-light-a-12)",
    "variable": "var(--colors-amber-light-a-12)"
  },
  "colors.amber.light.p3.1": {
    "value": "color(display-p3 0.995 0.992 0.985)",
    "variable": "var(--colors-amber-light-p3-1)"
  },
  "colors.amber.light.p3.2": {
    "value": "color(display-p3 0.994 0.986 0.921)",
    "variable": "var(--colors-amber-light-p3-2)"
  },
  "colors.amber.light.p3.3": {
    "value": "color(display-p3 0.994 0.969 0.782)",
    "variable": "var(--colors-amber-light-p3-3)"
  },
  "colors.amber.light.p3.4": {
    "value": "color(display-p3 0.989 0.937 0.65)",
    "variable": "var(--colors-amber-light-p3-4)"
  },
  "colors.amber.light.p3.5": {
    "value": "color(display-p3 0.97 0.902 0.527)",
    "variable": "var(--colors-amber-light-p3-5)"
  },
  "colors.amber.light.p3.6": {
    "value": "color(display-p3 0.936 0.844 0.506)",
    "variable": "var(--colors-amber-light-p3-6)"
  },
  "colors.amber.light.p3.7": {
    "value": "color(display-p3 0.89 0.762 0.443)",
    "variable": "var(--colors-amber-light-p3-7)"
  },
  "colors.amber.light.p3.8": {
    "value": "color(display-p3 0.85 0.65 0.3)",
    "variable": "var(--colors-amber-light-p3-8)"
  },
  "colors.amber.light.p3.9": {
    "value": "color(display-p3 1 0.77 0.26)",
    "variable": "var(--colors-amber-light-p3-9)"
  },
  "colors.amber.light.p3.10": {
    "value": "color(display-p3 0.959 0.741 0.274)",
    "variable": "var(--colors-amber-light-p3-10)"
  },
  "colors.amber.light.p3.11": {
    "value": "color(display-p3 0.64 0.4 0)",
    "variable": "var(--colors-amber-light-p3-11)"
  },
  "colors.amber.light.p3.12": {
    "value": "color(display-p3 0.294 0.208 0.145)",
    "variable": "var(--colors-amber-light-p3-12)"
  },
  "colors.amber.light.p3.a.1": {
    "value": "color(display-p3 0.757 0.514 0.024 / 0.016)",
    "variable": "var(--colors-amber-light-p3-a-1)"
  },
  "colors.amber.light.p3.a.2": {
    "value": "color(display-p3 0.902 0.804 0.008 / 0.079)",
    "variable": "var(--colors-amber-light-p3-a-2)"
  },
  "colors.amber.light.p3.a.3": {
    "value": "color(display-p3 0.965 0.859 0.004 / 0.22)",
    "variable": "var(--colors-amber-light-p3-a-3)"
  },
  "colors.amber.light.p3.a.4": {
    "value": "color(display-p3 0.969 0.82 0.004 / 0.35)",
    "variable": "var(--colors-amber-light-p3-a-4)"
  },
  "colors.amber.light.p3.a.5": {
    "value": "color(display-p3 0.933 0.796 0.004 / 0.475)",
    "variable": "var(--colors-amber-light-p3-a-5)"
  },
  "colors.amber.light.p3.a.6": {
    "value": "color(display-p3 0.875 0.682 0.004 / 0.495)",
    "variable": "var(--colors-amber-light-p3-a-6)"
  },
  "colors.amber.light.p3.a.7": {
    "value": "color(display-p3 0.804 0.573 0 / 0.557)",
    "variable": "var(--colors-amber-light-p3-a-7)"
  },
  "colors.amber.light.p3.a.8": {
    "value": "color(display-p3 0.788 0.502 0 / 0.699)",
    "variable": "var(--colors-amber-light-p3-a-8)"
  },
  "colors.amber.light.p3.a.9": {
    "value": "color(display-p3 1 0.686 0 / 0.742)",
    "variable": "var(--colors-amber-light-p3-a-9)"
  },
  "colors.amber.light.p3.a.10": {
    "value": "color(display-p3 0.945 0.643 0 / 0.726)",
    "variable": "var(--colors-amber-light-p3-a-10)"
  },
  "colors.amber.light.p3.a.11": {
    "value": "color(display-p3 0.64 0.4 0)",
    "variable": "var(--colors-amber-light-p3-a-11)"
  },
  "colors.amber.light.p3.a.12": {
    "value": "color(display-p3 0.294 0.208 0.145)",
    "variable": "var(--colors-amber-light-p3-a-12)"
  },
  "colors.amber.dark.1": {
    "value": "var(--colors-amber-dark-1)",
    "variable": "var(--colors-amber-dark-1)"
  },
  "colors.amber.dark.2": {
    "value": "var(--colors-amber-dark-2)",
    "variable": "var(--colors-amber-dark-2)"
  },
  "colors.amber.dark.3": {
    "value": "var(--colors-amber-dark-3)",
    "variable": "var(--colors-amber-dark-3)"
  },
  "colors.amber.dark.4": {
    "value": "var(--colors-amber-dark-4)",
    "variable": "var(--colors-amber-dark-4)"
  },
  "colors.amber.dark.5": {
    "value": "var(--colors-amber-dark-5)",
    "variable": "var(--colors-amber-dark-5)"
  },
  "colors.amber.dark.6": {
    "value": "var(--colors-amber-dark-6)",
    "variable": "var(--colors-amber-dark-6)"
  },
  "colors.amber.dark.7": {
    "value": "var(--colors-amber-dark-7)",
    "variable": "var(--colors-amber-dark-7)"
  },
  "colors.amber.dark.8": {
    "value": "var(--colors-amber-dark-8)",
    "variable": "var(--colors-amber-dark-8)"
  },
  "colors.amber.dark.9": {
    "value": "var(--colors-amber-dark-9)",
    "variable": "var(--colors-amber-dark-9)"
  },
  "colors.amber.dark.10": {
    "value": "var(--colors-amber-dark-10)",
    "variable": "var(--colors-amber-dark-10)"
  },
  "colors.amber.dark.11": {
    "value": "var(--colors-amber-dark-11)",
    "variable": "var(--colors-amber-dark-11)"
  },
  "colors.amber.dark.12": {
    "value": "var(--colors-amber-dark-12)",
    "variable": "var(--colors-amber-dark-12)"
  },
  "colors.amber.dark.a.1": {
    "value": "var(--colors-amber-dark-a-1)",
    "variable": "var(--colors-amber-dark-a-1)"
  },
  "colors.amber.dark.a.2": {
    "value": "var(--colors-amber-dark-a-2)",
    "variable": "var(--colors-amber-dark-a-2)"
  },
  "colors.amber.dark.a.3": {
    "value": "var(--colors-amber-dark-a-3)",
    "variable": "var(--colors-amber-dark-a-3)"
  },
  "colors.amber.dark.a.4": {
    "value": "var(--colors-amber-dark-a-4)",
    "variable": "var(--colors-amber-dark-a-4)"
  },
  "colors.amber.dark.a.5": {
    "value": "var(--colors-amber-dark-a-5)",
    "variable": "var(--colors-amber-dark-a-5)"
  },
  "colors.amber.dark.a.6": {
    "value": "var(--colors-amber-dark-a-6)",
    "variable": "var(--colors-amber-dark-a-6)"
  },
  "colors.amber.dark.a.7": {
    "value": "var(--colors-amber-dark-a-7)",
    "variable": "var(--colors-amber-dark-a-7)"
  },
  "colors.amber.dark.a.8": {
    "value": "var(--colors-amber-dark-a-8)",
    "variable": "var(--colors-amber-dark-a-8)"
  },
  "colors.amber.dark.a.9": {
    "value": "var(--colors-amber-dark-a-9)",
    "variable": "var(--colors-amber-dark-a-9)"
  },
  "colors.amber.dark.a.10": {
    "value": "var(--colors-amber-dark-a-10)",
    "variable": "var(--colors-amber-dark-a-10)"
  },
  "colors.amber.dark.a.11": {
    "value": "var(--colors-amber-dark-a-11)",
    "variable": "var(--colors-amber-dark-a-11)"
  },
  "colors.amber.dark.a.12": {
    "value": "var(--colors-amber-dark-a-12)",
    "variable": "var(--colors-amber-dark-a-12)"
  },
  "colors.amber.dark.p3.1": {
    "value": "color(display-p3 0.082 0.07 0.05)",
    "variable": "var(--colors-amber-dark-p3-1)"
  },
  "colors.amber.dark.p3.2": {
    "value": "color(display-p3 0.111 0.094 0.064)",
    "variable": "var(--colors-amber-dark-p3-2)"
  },
  "colors.amber.dark.p3.3": {
    "value": "color(display-p3 0.178 0.128 0.049)",
    "variable": "var(--colors-amber-dark-p3-3)"
  },
  "colors.amber.dark.p3.4": {
    "value": "color(display-p3 0.239 0.156 0)",
    "variable": "var(--colors-amber-dark-p3-4)"
  },
  "colors.amber.dark.p3.5": {
    "value": "color(display-p3 0.29 0.193 0)",
    "variable": "var(--colors-amber-dark-p3-5)"
  },
  "colors.amber.dark.p3.6": {
    "value": "color(display-p3 0.344 0.245 0.076)",
    "variable": "var(--colors-amber-dark-p3-6)"
  },
  "colors.amber.dark.p3.7": {
    "value": "color(display-p3 0.422 0.314 0.141)",
    "variable": "var(--colors-amber-dark-p3-7)"
  },
  "colors.amber.dark.p3.8": {
    "value": "color(display-p3 0.535 0.399 0.189)",
    "variable": "var(--colors-amber-dark-p3-8)"
  },
  "colors.amber.dark.p3.9": {
    "value": "color(display-p3 1 0.77 0.26)",
    "variable": "var(--colors-amber-dark-p3-9)"
  },
  "colors.amber.dark.p3.10": {
    "value": "color(display-p3 1 0.87 0.15)",
    "variable": "var(--colors-amber-dark-p3-10)"
  },
  "colors.amber.dark.p3.11": {
    "value": "color(display-p3 1 0.8 0.29)",
    "variable": "var(--colors-amber-dark-p3-11)"
  },
  "colors.amber.dark.p3.12": {
    "value": "color(display-p3 0.984 0.909 0.726)",
    "variable": "var(--colors-amber-dark-p3-12)"
  },
  "colors.amber.dark.p3.a.1": {
    "value": "color(display-p3 0.992 0.298 0 / 0.017)",
    "variable": "var(--colors-amber-dark-p3-a-1)"
  },
  "colors.amber.dark.p3.a.2": {
    "value": "color(display-p3 0.988 0.651 0 / 0.047)",
    "variable": "var(--colors-amber-dark-p3-a-2)"
  },
  "colors.amber.dark.p3.a.3": {
    "value": "color(display-p3 1 0.6 0 / 0.118)",
    "variable": "var(--colors-amber-dark-p3-a-3)"
  },
  "colors.amber.dark.p3.a.4": {
    "value": "color(display-p3 1 0.557 0 / 0.185)",
    "variable": "var(--colors-amber-dark-p3-a-4)"
  },
  "colors.amber.dark.p3.a.5": {
    "value": "color(display-p3 1 0.592 0 / 0.24)",
    "variable": "var(--colors-amber-dark-p3-a-5)"
  },
  "colors.amber.dark.p3.a.6": {
    "value": "color(display-p3 1 0.659 0.094 / 0.299)",
    "variable": "var(--colors-amber-dark-p3-a-6)"
  },
  "colors.amber.dark.p3.a.7": {
    "value": "color(display-p3 1 0.714 0.263 / 0.383)",
    "variable": "var(--colors-amber-dark-p3-a-7)"
  },
  "colors.amber.dark.p3.a.8": {
    "value": "color(display-p3 0.996 0.729 0.306 / 0.5)",
    "variable": "var(--colors-amber-dark-p3-a-8)"
  },
  "colors.amber.dark.p3.a.9": {
    "value": "color(display-p3 1 0.769 0.259)",
    "variable": "var(--colors-amber-dark-p3-a-9)"
  },
  "colors.amber.dark.p3.a.10": {
    "value": "color(display-p3 1 0.871 0.149)",
    "variable": "var(--colors-amber-dark-p3-a-10)"
  },
  "colors.amber.dark.p3.a.11": {
    "value": "color(display-p3 1 0.8 0.29)",
    "variable": "var(--colors-amber-dark-p3-a-11)"
  },
  "colors.amber.dark.p3.a.12": {
    "value": "color(display-p3 0.984 0.909 0.726)",
    "variable": "var(--colors-amber-dark-p3-a-12)"
  },
  "colors.amber.a.1": {
    "value": "var(--colors-amber-a-1)",
    "variable": "var(--colors-amber-a-1)"
  },
  "colors.amber.a.2": {
    "value": "var(--colors-amber-a-2)",
    "variable": "var(--colors-amber-a-2)"
  },
  "colors.amber.a.3": {
    "value": "var(--colors-amber-a-3)",
    "variable": "var(--colors-amber-a-3)"
  },
  "colors.amber.a.4": {
    "value": "var(--colors-amber-a-4)",
    "variable": "var(--colors-amber-a-4)"
  },
  "colors.amber.a.5": {
    "value": "var(--colors-amber-a-5)",
    "variable": "var(--colors-amber-a-5)"
  },
  "colors.amber.a.6": {
    "value": "var(--colors-amber-a-6)",
    "variable": "var(--colors-amber-a-6)"
  },
  "colors.amber.a.7": {
    "value": "var(--colors-amber-a-7)",
    "variable": "var(--colors-amber-a-7)"
  },
  "colors.amber.a.8": {
    "value": "var(--colors-amber-a-8)",
    "variable": "var(--colors-amber-a-8)"
  },
  "colors.amber.a.9": {
    "value": "var(--colors-amber-a-9)",
    "variable": "var(--colors-amber-a-9)"
  },
  "colors.amber.a.10": {
    "value": "var(--colors-amber-a-10)",
    "variable": "var(--colors-amber-a-10)"
  },
  "colors.amber.a.11": {
    "value": "var(--colors-amber-a-11)",
    "variable": "var(--colors-amber-a-11)"
  },
  "colors.amber.a.12": {
    "value": "var(--colors-amber-a-12)",
    "variable": "var(--colors-amber-a-12)"
  },
  "colors.amber.p3.1": {
    "value": "var(--colors-amber-p3-1)",
    "variable": "var(--colors-amber-p3-1)"
  },
  "colors.amber.p3.2": {
    "value": "var(--colors-amber-p3-2)",
    "variable": "var(--colors-amber-p3-2)"
  },
  "colors.amber.p3.3": {
    "value": "var(--colors-amber-p3-3)",
    "variable": "var(--colors-amber-p3-3)"
  },
  "colors.amber.p3.4": {
    "value": "var(--colors-amber-p3-4)",
    "variable": "var(--colors-amber-p3-4)"
  },
  "colors.amber.p3.5": {
    "value": "var(--colors-amber-p3-5)",
    "variable": "var(--colors-amber-p3-5)"
  },
  "colors.amber.p3.6": {
    "value": "var(--colors-amber-p3-6)",
    "variable": "var(--colors-amber-p3-6)"
  },
  "colors.amber.p3.7": {
    "value": "var(--colors-amber-p3-7)",
    "variable": "var(--colors-amber-p3-7)"
  },
  "colors.amber.p3.8": {
    "value": "var(--colors-amber-p3-8)",
    "variable": "var(--colors-amber-p3-8)"
  },
  "colors.amber.p3.9": {
    "value": "var(--colors-amber-p3-9)",
    "variable": "var(--colors-amber-p3-9)"
  },
  "colors.amber.p3.10": {
    "value": "var(--colors-amber-p3-10)",
    "variable": "var(--colors-amber-p3-10)"
  },
  "colors.amber.p3.11": {
    "value": "var(--colors-amber-p3-11)",
    "variable": "var(--colors-amber-p3-11)"
  },
  "colors.amber.p3.12": {
    "value": "var(--colors-amber-p3-12)",
    "variable": "var(--colors-amber-p3-12)"
  },
  "colors.amber.p3.a.1": {
    "value": "var(--colors-amber-p3-a-1)",
    "variable": "var(--colors-amber-p3-a-1)"
  },
  "colors.amber.p3.a.2": {
    "value": "var(--colors-amber-p3-a-2)",
    "variable": "var(--colors-amber-p3-a-2)"
  },
  "colors.amber.p3.a.3": {
    "value": "var(--colors-amber-p3-a-3)",
    "variable": "var(--colors-amber-p3-a-3)"
  },
  "colors.amber.p3.a.4": {
    "value": "var(--colors-amber-p3-a-4)",
    "variable": "var(--colors-amber-p3-a-4)"
  },
  "colors.amber.p3.a.5": {
    "value": "var(--colors-amber-p3-a-5)",
    "variable": "var(--colors-amber-p3-a-5)"
  },
  "colors.amber.p3.a.6": {
    "value": "var(--colors-amber-p3-a-6)",
    "variable": "var(--colors-amber-p3-a-6)"
  },
  "colors.amber.p3.a.7": {
    "value": "var(--colors-amber-p3-a-7)",
    "variable": "var(--colors-amber-p3-a-7)"
  },
  "colors.amber.p3.a.8": {
    "value": "var(--colors-amber-p3-a-8)",
    "variable": "var(--colors-amber-p3-a-8)"
  },
  "colors.amber.p3.a.9": {
    "value": "var(--colors-amber-p3-a-9)",
    "variable": "var(--colors-amber-p3-a-9)"
  },
  "colors.amber.p3.a.10": {
    "value": "var(--colors-amber-p3-a-10)",
    "variable": "var(--colors-amber-p3-a-10)"
  },
  "colors.amber.p3.a.11": {
    "value": "var(--colors-amber-p3-a-11)",
    "variable": "var(--colors-amber-p3-a-11)"
  },
  "colors.amber.p3.a.12": {
    "value": "var(--colors-amber-p3-a-12)",
    "variable": "var(--colors-amber-p3-a-12)"
  },
  "colors.black.foreground": {
    "value": "var(--colors-black-foreground)",
    "variable": "var(--colors-black-foreground)"
  },
  "colors.black.foreground.soft": {
    "value": "var(--colors-black-foreground-soft)",
    "variable": "var(--colors-black-foreground-soft)"
  },
  "colors.black.foreground.softest": {
    "value": "var(--colors-black-foreground-softest)",
    "variable": "var(--colors-black-foreground-softest)"
  },
  "colors.black.light.a.1": {
    "value": "var(--colors-black-light-a-1)",
    "variable": "var(--colors-black-light-a-1)"
  },
  "colors.black.light.a.2": {
    "value": "var(--colors-black-light-a-2)",
    "variable": "var(--colors-black-light-a-2)"
  },
  "colors.black.light.a.3": {
    "value": "var(--colors-black-light-a-3)",
    "variable": "var(--colors-black-light-a-3)"
  },
  "colors.black.light.a.4": {
    "value": "var(--colors-black-light-a-4)",
    "variable": "var(--colors-black-light-a-4)"
  },
  "colors.black.light.a.5": {
    "value": "var(--colors-black-light-a-5)",
    "variable": "var(--colors-black-light-a-5)"
  },
  "colors.black.light.a.6": {
    "value": "var(--colors-black-light-a-6)",
    "variable": "var(--colors-black-light-a-6)"
  },
  "colors.black.light.a.7": {
    "value": "var(--colors-black-light-a-7)",
    "variable": "var(--colors-black-light-a-7)"
  },
  "colors.black.light.a.8": {
    "value": "var(--colors-black-light-a-8)",
    "variable": "var(--colors-black-light-a-8)"
  },
  "colors.black.light.a.9": {
    "value": "var(--colors-black-light-a-9)",
    "variable": "var(--colors-black-light-a-9)"
  },
  "colors.black.light.a.10": {
    "value": "var(--colors-black-light-a-10)",
    "variable": "var(--colors-black-light-a-10)"
  },
  "colors.black.light.a.11": {
    "value": "var(--colors-black-light-a-11)",
    "variable": "var(--colors-black-light-a-11)"
  },
  "colors.black.light.a.12": {
    "value": "var(--colors-black-light-a-12)",
    "variable": "var(--colors-black-light-a-12)"
  },
  "colors.black.light.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0.05)",
    "variable": "var(--colors-black-light-p3-a-1)"
  },
  "colors.black.light.p3.a.2": {
    "value": "color(display-p3 0 0 0 / 0.1)",
    "variable": "var(--colors-black-light-p3-a-2)"
  },
  "colors.black.light.p3.a.3": {
    "value": "color(display-p3 0 0 0 / 0.15)",
    "variable": "var(--colors-black-light-p3-a-3)"
  },
  "colors.black.light.p3.a.4": {
    "value": "color(display-p3 0 0 0 / 0.2)",
    "variable": "var(--colors-black-light-p3-a-4)"
  },
  "colors.black.light.p3.a.5": {
    "value": "color(display-p3 0 0 0 / 0.3)",
    "variable": "var(--colors-black-light-p3-a-5)"
  },
  "colors.black.light.p3.a.6": {
    "value": "color(display-p3 0 0 0 / 0.4)",
    "variable": "var(--colors-black-light-p3-a-6)"
  },
  "colors.black.light.p3.a.7": {
    "value": "color(display-p3 0 0 0 / 0.5)",
    "variable": "var(--colors-black-light-p3-a-7)"
  },
  "colors.black.light.p3.a.8": {
    "value": "color(display-p3 0 0 0 / 0.6)",
    "variable": "var(--colors-black-light-p3-a-8)"
  },
  "colors.black.light.p3.a.9": {
    "value": "color(display-p3 0 0 0 / 0.7)",
    "variable": "var(--colors-black-light-p3-a-9)"
  },
  "colors.black.light.p3.a.10": {
    "value": "color(display-p3 0 0 0 / 0.8)",
    "variable": "var(--colors-black-light-p3-a-10)"
  },
  "colors.black.light.p3.a.11": {
    "value": "color(display-p3 0 0 0 / 0.9)",
    "variable": "var(--colors-black-light-p3-a-11)"
  },
  "colors.black.light.p3.a.12": {
    "value": "color(display-p3 0 0 0 / 0.95)",
    "variable": "var(--colors-black-light-p3-a-12)"
  },
  "colors.black.a.1": {
    "value": "var(--colors-black-a-1)",
    "variable": "var(--colors-black-a-1)"
  },
  "colors.black.a.2": {
    "value": "var(--colors-black-a-2)",
    "variable": "var(--colors-black-a-2)"
  },
  "colors.black.a.3": {
    "value": "var(--colors-black-a-3)",
    "variable": "var(--colors-black-a-3)"
  },
  "colors.black.a.4": {
    "value": "var(--colors-black-a-4)",
    "variable": "var(--colors-black-a-4)"
  },
  "colors.black.a.5": {
    "value": "var(--colors-black-a-5)",
    "variable": "var(--colors-black-a-5)"
  },
  "colors.black.a.6": {
    "value": "var(--colors-black-a-6)",
    "variable": "var(--colors-black-a-6)"
  },
  "colors.black.a.7": {
    "value": "var(--colors-black-a-7)",
    "variable": "var(--colors-black-a-7)"
  },
  "colors.black.a.8": {
    "value": "var(--colors-black-a-8)",
    "variable": "var(--colors-black-a-8)"
  },
  "colors.black.a.9": {
    "value": "var(--colors-black-a-9)",
    "variable": "var(--colors-black-a-9)"
  },
  "colors.black.a.10": {
    "value": "var(--colors-black-a-10)",
    "variable": "var(--colors-black-a-10)"
  },
  "colors.black.a.11": {
    "value": "var(--colors-black-a-11)",
    "variable": "var(--colors-black-a-11)"
  },
  "colors.black.a.12": {
    "value": "var(--colors-black-a-12)",
    "variable": "var(--colors-black-a-12)"
  },
  "colors.black.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0.05)",
    "variable": "var(--colors-black-p3-a-1)"
  },
  "colors.black.p3.a.2": {
    "value": "color(display-p3 0 0 0 / 0.1)",
    "variable": "var(--colors-black-p3-a-2)"
  },
  "colors.black.p3.a.3": {
    "value": "color(display-p3 0 0 0 / 0.15)",
    "variable": "var(--colors-black-p3-a-3)"
  },
  "colors.black.p3.a.4": {
    "value": "color(display-p3 0 0 0 / 0.2)",
    "variable": "var(--colors-black-p3-a-4)"
  },
  "colors.black.p3.a.5": {
    "value": "color(display-p3 0 0 0 / 0.3)",
    "variable": "var(--colors-black-p3-a-5)"
  },
  "colors.black.p3.a.6": {
    "value": "color(display-p3 0 0 0 / 0.4)",
    "variable": "var(--colors-black-p3-a-6)"
  },
  "colors.black.p3.a.7": {
    "value": "color(display-p3 0 0 0 / 0.5)",
    "variable": "var(--colors-black-p3-a-7)"
  },
  "colors.black.p3.a.8": {
    "value": "color(display-p3 0 0 0 / 0.6)",
    "variable": "var(--colors-black-p3-a-8)"
  },
  "colors.black.p3.a.9": {
    "value": "color(display-p3 0 0 0 / 0.7)",
    "variable": "var(--colors-black-p3-a-9)"
  },
  "colors.black.p3.a.10": {
    "value": "color(display-p3 0 0 0 / 0.8)",
    "variable": "var(--colors-black-p3-a-10)"
  },
  "colors.black.p3.a.11": {
    "value": "color(display-p3 0 0 0 / 0.9)",
    "variable": "var(--colors-black-p3-a-11)"
  },
  "colors.black.p3.a.12": {
    "value": "color(display-p3 0 0 0 / 0.95)",
    "variable": "var(--colors-black-p3-a-12)"
  },
  "colors.blue.1": {
    "value": "var(--colors-blue-1)",
    "variable": "var(--colors-blue-1)"
  },
  "colors.blue.2": {
    "value": "var(--colors-blue-2)",
    "variable": "var(--colors-blue-2)"
  },
  "colors.blue.3": {
    "value": "var(--colors-blue-3)",
    "variable": "var(--colors-blue-3)"
  },
  "colors.blue.4": {
    "value": "var(--colors-blue-4)",
    "variable": "var(--colors-blue-4)"
  },
  "colors.blue.5": {
    "value": "var(--colors-blue-5)",
    "variable": "var(--colors-blue-5)"
  },
  "colors.blue.6": {
    "value": "var(--colors-blue-6)",
    "variable": "var(--colors-blue-6)"
  },
  "colors.blue.7": {
    "value": "var(--colors-blue-7)",
    "variable": "var(--colors-blue-7)"
  },
  "colors.blue.8": {
    "value": "var(--colors-blue-8)",
    "variable": "var(--colors-blue-8)"
  },
  "colors.blue.9": {
    "value": "var(--colors-blue-9)",
    "variable": "var(--colors-blue-9)"
  },
  "colors.blue.10": {
    "value": "var(--colors-blue-10)",
    "variable": "var(--colors-blue-10)"
  },
  "colors.blue.11": {
    "value": "var(--colors-blue-11)",
    "variable": "var(--colors-blue-11)"
  },
  "colors.blue.12": {
    "value": "var(--colors-blue-12)",
    "variable": "var(--colors-blue-12)"
  },
  "colors.blue.light.1": {
    "value": "var(--colors-blue-light-1)",
    "variable": "var(--colors-blue-light-1)"
  },
  "colors.blue.light.2": {
    "value": "var(--colors-blue-light-2)",
    "variable": "var(--colors-blue-light-2)"
  },
  "colors.blue.light.3": {
    "value": "var(--colors-blue-light-3)",
    "variable": "var(--colors-blue-light-3)"
  },
  "colors.blue.light.4": {
    "value": "var(--colors-blue-light-4)",
    "variable": "var(--colors-blue-light-4)"
  },
  "colors.blue.light.5": {
    "value": "var(--colors-blue-light-5)",
    "variable": "var(--colors-blue-light-5)"
  },
  "colors.blue.light.6": {
    "value": "var(--colors-blue-light-6)",
    "variable": "var(--colors-blue-light-6)"
  },
  "colors.blue.light.7": {
    "value": "var(--colors-blue-light-7)",
    "variable": "var(--colors-blue-light-7)"
  },
  "colors.blue.light.8": {
    "value": "var(--colors-blue-light-8)",
    "variable": "var(--colors-blue-light-8)"
  },
  "colors.blue.light.9": {
    "value": "var(--colors-blue-light-9)",
    "variable": "var(--colors-blue-light-9)"
  },
  "colors.blue.light.10": {
    "value": "var(--colors-blue-light-10)",
    "variable": "var(--colors-blue-light-10)"
  },
  "colors.blue.light.11": {
    "value": "var(--colors-blue-light-11)",
    "variable": "var(--colors-blue-light-11)"
  },
  "colors.blue.light.12": {
    "value": "var(--colors-blue-light-12)",
    "variable": "var(--colors-blue-light-12)"
  },
  "colors.blue.light.a.1": {
    "value": "var(--colors-blue-light-a-1)",
    "variable": "var(--colors-blue-light-a-1)"
  },
  "colors.blue.light.a.2": {
    "value": "var(--colors-blue-light-a-2)",
    "variable": "var(--colors-blue-light-a-2)"
  },
  "colors.blue.light.a.3": {
    "value": "var(--colors-blue-light-a-3)",
    "variable": "var(--colors-blue-light-a-3)"
  },
  "colors.blue.light.a.4": {
    "value": "var(--colors-blue-light-a-4)",
    "variable": "var(--colors-blue-light-a-4)"
  },
  "colors.blue.light.a.5": {
    "value": "var(--colors-blue-light-a-5)",
    "variable": "var(--colors-blue-light-a-5)"
  },
  "colors.blue.light.a.6": {
    "value": "var(--colors-blue-light-a-6)",
    "variable": "var(--colors-blue-light-a-6)"
  },
  "colors.blue.light.a.7": {
    "value": "var(--colors-blue-light-a-7)",
    "variable": "var(--colors-blue-light-a-7)"
  },
  "colors.blue.light.a.8": {
    "value": "var(--colors-blue-light-a-8)",
    "variable": "var(--colors-blue-light-a-8)"
  },
  "colors.blue.light.a.9": {
    "value": "var(--colors-blue-light-a-9)",
    "variable": "var(--colors-blue-light-a-9)"
  },
  "colors.blue.light.a.10": {
    "value": "var(--colors-blue-light-a-10)",
    "variable": "var(--colors-blue-light-a-10)"
  },
  "colors.blue.light.a.11": {
    "value": "var(--colors-blue-light-a-11)",
    "variable": "var(--colors-blue-light-a-11)"
  },
  "colors.blue.light.a.12": {
    "value": "var(--colors-blue-light-a-12)",
    "variable": "var(--colors-blue-light-a-12)"
  },
  "colors.blue.light.p3.1": {
    "value": "color(display-p3 0.986 0.992 0.999)",
    "variable": "var(--colors-blue-light-p3-1)"
  },
  "colors.blue.light.p3.2": {
    "value": "color(display-p3 0.96 0.979 0.998)",
    "variable": "var(--colors-blue-light-p3-2)"
  },
  "colors.blue.light.p3.3": {
    "value": "color(display-p3 0.912 0.956 0.991)",
    "variable": "var(--colors-blue-light-p3-3)"
  },
  "colors.blue.light.p3.4": {
    "value": "color(display-p3 0.853 0.932 1)",
    "variable": "var(--colors-blue-light-p3-4)"
  },
  "colors.blue.light.p3.5": {
    "value": "color(display-p3 0.788 0.894 0.998)",
    "variable": "var(--colors-blue-light-p3-5)"
  },
  "colors.blue.light.p3.6": {
    "value": "color(display-p3 0.709 0.843 0.976)",
    "variable": "var(--colors-blue-light-p3-6)"
  },
  "colors.blue.light.p3.7": {
    "value": "color(display-p3 0.606 0.777 0.947)",
    "variable": "var(--colors-blue-light-p3-7)"
  },
  "colors.blue.light.p3.8": {
    "value": "color(display-p3 0.451 0.688 0.917)",
    "variable": "var(--colors-blue-light-p3-8)"
  },
  "colors.blue.light.p3.9": {
    "value": "color(display-p3 0.247 0.556 0.969)",
    "variable": "var(--colors-blue-light-p3-9)"
  },
  "colors.blue.light.p3.10": {
    "value": "color(display-p3 0.234 0.523 0.912)",
    "variable": "var(--colors-blue-light-p3-10)"
  },
  "colors.blue.light.p3.11": {
    "value": "color(display-p3 0.15 0.44 0.84)",
    "variable": "var(--colors-blue-light-p3-11)"
  },
  "colors.blue.light.p3.12": {
    "value": "color(display-p3 0.102 0.193 0.379)",
    "variable": "var(--colors-blue-light-p3-12)"
  },
  "colors.blue.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.514 1 / 0.016)",
    "variable": "var(--colors-blue-light-p3-a-1)"
  },
  "colors.blue.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.514 0.906 / 0.04)",
    "variable": "var(--colors-blue-light-p3-a-2)"
  },
  "colors.blue.light.p3.a.3": {
    "value": "color(display-p3 0.012 0.506 0.914 / 0.087)",
    "variable": "var(--colors-blue-light-p3-a-3)"
  },
  "colors.blue.light.p3.a.4": {
    "value": "color(display-p3 0.008 0.545 1 / 0.146)",
    "variable": "var(--colors-blue-light-p3-a-4)"
  },
  "colors.blue.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.502 0.984 / 0.212)",
    "variable": "var(--colors-blue-light-p3-a-5)"
  },
  "colors.blue.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.463 0.922 / 0.291)",
    "variable": "var(--colors-blue-light-p3-a-6)"
  },
  "colors.blue.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.431 0.863 / 0.393)",
    "variable": "var(--colors-blue-light-p3-a-7)"
  },
  "colors.blue.light.p3.a.8": {
    "value": "color(display-p3 0 0.427 0.851 / 0.55)",
    "variable": "var(--colors-blue-light-p3-a-8)"
  },
  "colors.blue.light.p3.a.9": {
    "value": "color(display-p3 0 0.412 0.961 / 0.753)",
    "variable": "var(--colors-blue-light-p3-a-9)"
  },
  "colors.blue.light.p3.a.10": {
    "value": "color(display-p3 0 0.376 0.886 / 0.765)",
    "variable": "var(--colors-blue-light-p3-a-10)"
  },
  "colors.blue.light.p3.a.11": {
    "value": "color(display-p3 0.15 0.44 0.84)",
    "variable": "var(--colors-blue-light-p3-a-11)"
  },
  "colors.blue.light.p3.a.12": {
    "value": "color(display-p3 0.102 0.193 0.379)",
    "variable": "var(--colors-blue-light-p3-a-12)"
  },
  "colors.blue.dark.1": {
    "value": "var(--colors-blue-dark-1)",
    "variable": "var(--colors-blue-dark-1)"
  },
  "colors.blue.dark.2": {
    "value": "var(--colors-blue-dark-2)",
    "variable": "var(--colors-blue-dark-2)"
  },
  "colors.blue.dark.3": {
    "value": "var(--colors-blue-dark-3)",
    "variable": "var(--colors-blue-dark-3)"
  },
  "colors.blue.dark.4": {
    "value": "var(--colors-blue-dark-4)",
    "variable": "var(--colors-blue-dark-4)"
  },
  "colors.blue.dark.5": {
    "value": "var(--colors-blue-dark-5)",
    "variable": "var(--colors-blue-dark-5)"
  },
  "colors.blue.dark.6": {
    "value": "var(--colors-blue-dark-6)",
    "variable": "var(--colors-blue-dark-6)"
  },
  "colors.blue.dark.7": {
    "value": "var(--colors-blue-dark-7)",
    "variable": "var(--colors-blue-dark-7)"
  },
  "colors.blue.dark.8": {
    "value": "var(--colors-blue-dark-8)",
    "variable": "var(--colors-blue-dark-8)"
  },
  "colors.blue.dark.9": {
    "value": "var(--colors-blue-dark-9)",
    "variable": "var(--colors-blue-dark-9)"
  },
  "colors.blue.dark.10": {
    "value": "var(--colors-blue-dark-10)",
    "variable": "var(--colors-blue-dark-10)"
  },
  "colors.blue.dark.11": {
    "value": "var(--colors-blue-dark-11)",
    "variable": "var(--colors-blue-dark-11)"
  },
  "colors.blue.dark.12": {
    "value": "var(--colors-blue-dark-12)",
    "variable": "var(--colors-blue-dark-12)"
  },
  "colors.blue.dark.a.1": {
    "value": "var(--colors-blue-dark-a-1)",
    "variable": "var(--colors-blue-dark-a-1)"
  },
  "colors.blue.dark.a.2": {
    "value": "var(--colors-blue-dark-a-2)",
    "variable": "var(--colors-blue-dark-a-2)"
  },
  "colors.blue.dark.a.3": {
    "value": "var(--colors-blue-dark-a-3)",
    "variable": "var(--colors-blue-dark-a-3)"
  },
  "colors.blue.dark.a.4": {
    "value": "var(--colors-blue-dark-a-4)",
    "variable": "var(--colors-blue-dark-a-4)"
  },
  "colors.blue.dark.a.5": {
    "value": "var(--colors-blue-dark-a-5)",
    "variable": "var(--colors-blue-dark-a-5)"
  },
  "colors.blue.dark.a.6": {
    "value": "var(--colors-blue-dark-a-6)",
    "variable": "var(--colors-blue-dark-a-6)"
  },
  "colors.blue.dark.a.7": {
    "value": "var(--colors-blue-dark-a-7)",
    "variable": "var(--colors-blue-dark-a-7)"
  },
  "colors.blue.dark.a.8": {
    "value": "var(--colors-blue-dark-a-8)",
    "variable": "var(--colors-blue-dark-a-8)"
  },
  "colors.blue.dark.a.9": {
    "value": "var(--colors-blue-dark-a-9)",
    "variable": "var(--colors-blue-dark-a-9)"
  },
  "colors.blue.dark.a.10": {
    "value": "var(--colors-blue-dark-a-10)",
    "variable": "var(--colors-blue-dark-a-10)"
  },
  "colors.blue.dark.a.11": {
    "value": "var(--colors-blue-dark-a-11)",
    "variable": "var(--colors-blue-dark-a-11)"
  },
  "colors.blue.dark.a.12": {
    "value": "var(--colors-blue-dark-a-12)",
    "variable": "var(--colors-blue-dark-a-12)"
  },
  "colors.blue.dark.p3.1": {
    "value": "color(display-p3 0.057 0.081 0.122)",
    "variable": "var(--colors-blue-dark-p3-1)"
  },
  "colors.blue.dark.p3.2": {
    "value": "color(display-p3 0.072 0.098 0.147)",
    "variable": "var(--colors-blue-dark-p3-2)"
  },
  "colors.blue.dark.p3.3": {
    "value": "color(display-p3 0.078 0.154 0.27)",
    "variable": "var(--colors-blue-dark-p3-3)"
  },
  "colors.blue.dark.p3.4": {
    "value": "color(display-p3 0.033 0.197 0.37)",
    "variable": "var(--colors-blue-dark-p3-4)"
  },
  "colors.blue.dark.p3.5": {
    "value": "color(display-p3 0.08 0.245 0.441)",
    "variable": "var(--colors-blue-dark-p3-5)"
  },
  "colors.blue.dark.p3.6": {
    "value": "color(display-p3 0.14 0.298 0.511)",
    "variable": "var(--colors-blue-dark-p3-6)"
  },
  "colors.blue.dark.p3.7": {
    "value": "color(display-p3 0.195 0.361 0.6)",
    "variable": "var(--colors-blue-dark-p3-7)"
  },
  "colors.blue.dark.p3.8": {
    "value": "color(display-p3 0.239 0.434 0.72)",
    "variable": "var(--colors-blue-dark-p3-8)"
  },
  "colors.blue.dark.p3.9": {
    "value": "color(display-p3 0.247 0.556 0.969)",
    "variable": "var(--colors-blue-dark-p3-9)"
  },
  "colors.blue.dark.p3.10": {
    "value": "color(display-p3 0.344 0.612 0.973)",
    "variable": "var(--colors-blue-dark-p3-10)"
  },
  "colors.blue.dark.p3.11": {
    "value": "color(display-p3 0.49 0.72 1)",
    "variable": "var(--colors-blue-dark-p3-11)"
  },
  "colors.blue.dark.p3.12": {
    "value": "color(display-p3 0.788 0.898 0.99)",
    "variable": "var(--colors-blue-dark-p3-12)"
  },
  "colors.blue.dark.p3.a.1": {
    "value": "color(display-p3 0 0.333 1 / 0.059)",
    "variable": "var(--colors-blue-dark-p3-a-1)"
  },
  "colors.blue.dark.p3.a.2": {
    "value": "color(display-p3 0.114 0.435 0.988 / 0.085)",
    "variable": "var(--colors-blue-dark-p3-a-2)"
  },
  "colors.blue.dark.p3.a.3": {
    "value": "color(display-p3 0.122 0.463 1 / 0.219)",
    "variable": "var(--colors-blue-dark-p3-a-3)"
  },
  "colors.blue.dark.p3.a.4": {
    "value": "color(display-p3 0 0.467 1 / 0.324)",
    "variable": "var(--colors-blue-dark-p3-a-4)"
  },
  "colors.blue.dark.p3.a.5": {
    "value": "color(display-p3 0.098 0.51 1 / 0.4)",
    "variable": "var(--colors-blue-dark-p3-a-5)"
  },
  "colors.blue.dark.p3.a.6": {
    "value": "color(display-p3 0.224 0.557 1 / 0.475)",
    "variable": "var(--colors-blue-dark-p3-a-6)"
  },
  "colors.blue.dark.p3.a.7": {
    "value": "color(display-p3 0.294 0.584 1 / 0.572)",
    "variable": "var(--colors-blue-dark-p3-a-7)"
  },
  "colors.blue.dark.p3.a.8": {
    "value": "color(display-p3 0.314 0.592 1 / 0.702)",
    "variable": "var(--colors-blue-dark-p3-a-8)"
  },
  "colors.blue.dark.p3.a.9": {
    "value": "color(display-p3 0.251 0.573 0.996 / 0.967)",
    "variable": "var(--colors-blue-dark-p3-a-9)"
  },
  "colors.blue.dark.p3.a.10": {
    "value": "color(display-p3 0.357 0.631 1 / 0.971)",
    "variable": "var(--colors-blue-dark-p3-a-10)"
  },
  "colors.blue.dark.p3.a.11": {
    "value": "color(display-p3 0.49 0.72 1)",
    "variable": "var(--colors-blue-dark-p3-a-11)"
  },
  "colors.blue.dark.p3.a.12": {
    "value": "color(display-p3 0.788 0.898 0.99)",
    "variable": "var(--colors-blue-dark-p3-a-12)"
  },
  "colors.blue.a.1": {
    "value": "var(--colors-blue-a-1)",
    "variable": "var(--colors-blue-a-1)"
  },
  "colors.blue.a.2": {
    "value": "var(--colors-blue-a-2)",
    "variable": "var(--colors-blue-a-2)"
  },
  "colors.blue.a.3": {
    "value": "var(--colors-blue-a-3)",
    "variable": "var(--colors-blue-a-3)"
  },
  "colors.blue.a.4": {
    "value": "var(--colors-blue-a-4)",
    "variable": "var(--colors-blue-a-4)"
  },
  "colors.blue.a.5": {
    "value": "var(--colors-blue-a-5)",
    "variable": "var(--colors-blue-a-5)"
  },
  "colors.blue.a.6": {
    "value": "var(--colors-blue-a-6)",
    "variable": "var(--colors-blue-a-6)"
  },
  "colors.blue.a.7": {
    "value": "var(--colors-blue-a-7)",
    "variable": "var(--colors-blue-a-7)"
  },
  "colors.blue.a.8": {
    "value": "var(--colors-blue-a-8)",
    "variable": "var(--colors-blue-a-8)"
  },
  "colors.blue.a.9": {
    "value": "var(--colors-blue-a-9)",
    "variable": "var(--colors-blue-a-9)"
  },
  "colors.blue.a.10": {
    "value": "var(--colors-blue-a-10)",
    "variable": "var(--colors-blue-a-10)"
  },
  "colors.blue.a.11": {
    "value": "var(--colors-blue-a-11)",
    "variable": "var(--colors-blue-a-11)"
  },
  "colors.blue.a.12": {
    "value": "var(--colors-blue-a-12)",
    "variable": "var(--colors-blue-a-12)"
  },
  "colors.blue.p3.1": {
    "value": "var(--colors-blue-p3-1)",
    "variable": "var(--colors-blue-p3-1)"
  },
  "colors.blue.p3.2": {
    "value": "var(--colors-blue-p3-2)",
    "variable": "var(--colors-blue-p3-2)"
  },
  "colors.blue.p3.3": {
    "value": "var(--colors-blue-p3-3)",
    "variable": "var(--colors-blue-p3-3)"
  },
  "colors.blue.p3.4": {
    "value": "var(--colors-blue-p3-4)",
    "variable": "var(--colors-blue-p3-4)"
  },
  "colors.blue.p3.5": {
    "value": "var(--colors-blue-p3-5)",
    "variable": "var(--colors-blue-p3-5)"
  },
  "colors.blue.p3.6": {
    "value": "var(--colors-blue-p3-6)",
    "variable": "var(--colors-blue-p3-6)"
  },
  "colors.blue.p3.7": {
    "value": "var(--colors-blue-p3-7)",
    "variable": "var(--colors-blue-p3-7)"
  },
  "colors.blue.p3.8": {
    "value": "var(--colors-blue-p3-8)",
    "variable": "var(--colors-blue-p3-8)"
  },
  "colors.blue.p3.9": {
    "value": "var(--colors-blue-p3-9)",
    "variable": "var(--colors-blue-p3-9)"
  },
  "colors.blue.p3.10": {
    "value": "var(--colors-blue-p3-10)",
    "variable": "var(--colors-blue-p3-10)"
  },
  "colors.blue.p3.11": {
    "value": "var(--colors-blue-p3-11)",
    "variable": "var(--colors-blue-p3-11)"
  },
  "colors.blue.p3.12": {
    "value": "var(--colors-blue-p3-12)",
    "variable": "var(--colors-blue-p3-12)"
  },
  "colors.blue.p3.a.1": {
    "value": "var(--colors-blue-p3-a-1)",
    "variable": "var(--colors-blue-p3-a-1)"
  },
  "colors.blue.p3.a.2": {
    "value": "var(--colors-blue-p3-a-2)",
    "variable": "var(--colors-blue-p3-a-2)"
  },
  "colors.blue.p3.a.3": {
    "value": "var(--colors-blue-p3-a-3)",
    "variable": "var(--colors-blue-p3-a-3)"
  },
  "colors.blue.p3.a.4": {
    "value": "var(--colors-blue-p3-a-4)",
    "variable": "var(--colors-blue-p3-a-4)"
  },
  "colors.blue.p3.a.5": {
    "value": "var(--colors-blue-p3-a-5)",
    "variable": "var(--colors-blue-p3-a-5)"
  },
  "colors.blue.p3.a.6": {
    "value": "var(--colors-blue-p3-a-6)",
    "variable": "var(--colors-blue-p3-a-6)"
  },
  "colors.blue.p3.a.7": {
    "value": "var(--colors-blue-p3-a-7)",
    "variable": "var(--colors-blue-p3-a-7)"
  },
  "colors.blue.p3.a.8": {
    "value": "var(--colors-blue-p3-a-8)",
    "variable": "var(--colors-blue-p3-a-8)"
  },
  "colors.blue.p3.a.9": {
    "value": "var(--colors-blue-p3-a-9)",
    "variable": "var(--colors-blue-p3-a-9)"
  },
  "colors.blue.p3.a.10": {
    "value": "var(--colors-blue-p3-a-10)",
    "variable": "var(--colors-blue-p3-a-10)"
  },
  "colors.blue.p3.a.11": {
    "value": "var(--colors-blue-p3-a-11)",
    "variable": "var(--colors-blue-p3-a-11)"
  },
  "colors.blue.p3.a.12": {
    "value": "var(--colors-blue-p3-a-12)",
    "variable": "var(--colors-blue-p3-a-12)"
  },
  "colors.bronze.1": {
    "value": "var(--colors-bronze-1)",
    "variable": "var(--colors-bronze-1)"
  },
  "colors.bronze.2": {
    "value": "var(--colors-bronze-2)",
    "variable": "var(--colors-bronze-2)"
  },
  "colors.bronze.3": {
    "value": "var(--colors-bronze-3)",
    "variable": "var(--colors-bronze-3)"
  },
  "colors.bronze.4": {
    "value": "var(--colors-bronze-4)",
    "variable": "var(--colors-bronze-4)"
  },
  "colors.bronze.5": {
    "value": "var(--colors-bronze-5)",
    "variable": "var(--colors-bronze-5)"
  },
  "colors.bronze.6": {
    "value": "var(--colors-bronze-6)",
    "variable": "var(--colors-bronze-6)"
  },
  "colors.bronze.7": {
    "value": "var(--colors-bronze-7)",
    "variable": "var(--colors-bronze-7)"
  },
  "colors.bronze.8": {
    "value": "var(--colors-bronze-8)",
    "variable": "var(--colors-bronze-8)"
  },
  "colors.bronze.9": {
    "value": "var(--colors-bronze-9)",
    "variable": "var(--colors-bronze-9)"
  },
  "colors.bronze.10": {
    "value": "var(--colors-bronze-10)",
    "variable": "var(--colors-bronze-10)"
  },
  "colors.bronze.11": {
    "value": "var(--colors-bronze-11)",
    "variable": "var(--colors-bronze-11)"
  },
  "colors.bronze.12": {
    "value": "var(--colors-bronze-12)",
    "variable": "var(--colors-bronze-12)"
  },
  "colors.bronze.light.1": {
    "value": "var(--colors-bronze-light-1)",
    "variable": "var(--colors-bronze-light-1)"
  },
  "colors.bronze.light.2": {
    "value": "var(--colors-bronze-light-2)",
    "variable": "var(--colors-bronze-light-2)"
  },
  "colors.bronze.light.3": {
    "value": "var(--colors-bronze-light-3)",
    "variable": "var(--colors-bronze-light-3)"
  },
  "colors.bronze.light.4": {
    "value": "var(--colors-bronze-light-4)",
    "variable": "var(--colors-bronze-light-4)"
  },
  "colors.bronze.light.5": {
    "value": "var(--colors-bronze-light-5)",
    "variable": "var(--colors-bronze-light-5)"
  },
  "colors.bronze.light.6": {
    "value": "var(--colors-bronze-light-6)",
    "variable": "var(--colors-bronze-light-6)"
  },
  "colors.bronze.light.7": {
    "value": "var(--colors-bronze-light-7)",
    "variable": "var(--colors-bronze-light-7)"
  },
  "colors.bronze.light.8": {
    "value": "var(--colors-bronze-light-8)",
    "variable": "var(--colors-bronze-light-8)"
  },
  "colors.bronze.light.9": {
    "value": "var(--colors-bronze-light-9)",
    "variable": "var(--colors-bronze-light-9)"
  },
  "colors.bronze.light.10": {
    "value": "var(--colors-bronze-light-10)",
    "variable": "var(--colors-bronze-light-10)"
  },
  "colors.bronze.light.11": {
    "value": "var(--colors-bronze-light-11)",
    "variable": "var(--colors-bronze-light-11)"
  },
  "colors.bronze.light.12": {
    "value": "var(--colors-bronze-light-12)",
    "variable": "var(--colors-bronze-light-12)"
  },
  "colors.bronze.light.a.1": {
    "value": "var(--colors-bronze-light-a-1)",
    "variable": "var(--colors-bronze-light-a-1)"
  },
  "colors.bronze.light.a.2": {
    "value": "var(--colors-bronze-light-a-2)",
    "variable": "var(--colors-bronze-light-a-2)"
  },
  "colors.bronze.light.a.3": {
    "value": "var(--colors-bronze-light-a-3)",
    "variable": "var(--colors-bronze-light-a-3)"
  },
  "colors.bronze.light.a.4": {
    "value": "var(--colors-bronze-light-a-4)",
    "variable": "var(--colors-bronze-light-a-4)"
  },
  "colors.bronze.light.a.5": {
    "value": "var(--colors-bronze-light-a-5)",
    "variable": "var(--colors-bronze-light-a-5)"
  },
  "colors.bronze.light.a.6": {
    "value": "var(--colors-bronze-light-a-6)",
    "variable": "var(--colors-bronze-light-a-6)"
  },
  "colors.bronze.light.a.7": {
    "value": "var(--colors-bronze-light-a-7)",
    "variable": "var(--colors-bronze-light-a-7)"
  },
  "colors.bronze.light.a.8": {
    "value": "var(--colors-bronze-light-a-8)",
    "variable": "var(--colors-bronze-light-a-8)"
  },
  "colors.bronze.light.a.9": {
    "value": "var(--colors-bronze-light-a-9)",
    "variable": "var(--colors-bronze-light-a-9)"
  },
  "colors.bronze.light.a.10": {
    "value": "var(--colors-bronze-light-a-10)",
    "variable": "var(--colors-bronze-light-a-10)"
  },
  "colors.bronze.light.a.11": {
    "value": "var(--colors-bronze-light-a-11)",
    "variable": "var(--colors-bronze-light-a-11)"
  },
  "colors.bronze.light.a.12": {
    "value": "var(--colors-bronze-light-a-12)",
    "variable": "var(--colors-bronze-light-a-12)"
  },
  "colors.bronze.light.p3.1": {
    "value": "color(display-p3 0.991 0.988 0.988)",
    "variable": "var(--colors-bronze-light-p3-1)"
  },
  "colors.bronze.light.p3.2": {
    "value": "color(display-p3 0.989 0.97 0.961)",
    "variable": "var(--colors-bronze-light-p3-2)"
  },
  "colors.bronze.light.p3.3": {
    "value": "color(display-p3 0.958 0.932 0.919)",
    "variable": "var(--colors-bronze-light-p3-3)"
  },
  "colors.bronze.light.p3.4": {
    "value": "color(display-p3 0.929 0.894 0.877)",
    "variable": "var(--colors-bronze-light-p3-4)"
  },
  "colors.bronze.light.p3.5": {
    "value": "color(display-p3 0.898 0.853 0.832)",
    "variable": "var(--colors-bronze-light-p3-5)"
  },
  "colors.bronze.light.p3.6": {
    "value": "color(display-p3 0.861 0.805 0.778)",
    "variable": "var(--colors-bronze-light-p3-6)"
  },
  "colors.bronze.light.p3.7": {
    "value": "color(display-p3 0.812 0.739 0.706)",
    "variable": "var(--colors-bronze-light-p3-7)"
  },
  "colors.bronze.light.p3.8": {
    "value": "color(display-p3 0.741 0.647 0.606)",
    "variable": "var(--colors-bronze-light-p3-8)"
  },
  "colors.bronze.light.p3.9": {
    "value": "color(display-p3 0.611 0.507 0.455)",
    "variable": "var(--colors-bronze-light-p3-9)"
  },
  "colors.bronze.light.p3.10": {
    "value": "color(display-p3 0.563 0.461 0.414)",
    "variable": "var(--colors-bronze-light-p3-10)"
  },
  "colors.bronze.light.p3.11": {
    "value": "color(display-p3 0.471 0.373 0.336)",
    "variable": "var(--colors-bronze-light-p3-11)"
  },
  "colors.bronze.light.p3.12": {
    "value": "color(display-p3 0.251 0.191 0.172)",
    "variable": "var(--colors-bronze-light-p3-12)"
  },
  "colors.bronze.light.p3.a.1": {
    "value": "color(display-p3 0.349 0.024 0.024 / 0.012)",
    "variable": "var(--colors-bronze-light-p3-a-1)"
  },
  "colors.bronze.light.p3.a.2": {
    "value": "color(display-p3 0.71 0.22 0.024 / 0.04)",
    "variable": "var(--colors-bronze-light-p3-a-2)"
  },
  "colors.bronze.light.p3.a.3": {
    "value": "color(display-p3 0.482 0.2 0.008 / 0.083)",
    "variable": "var(--colors-bronze-light-p3-a-3)"
  },
  "colors.bronze.light.p3.a.4": {
    "value": "color(display-p3 0.424 0.133 0.004 / 0.122)",
    "variable": "var(--colors-bronze-light-p3-a-4)"
  },
  "colors.bronze.light.p3.a.5": {
    "value": "color(display-p3 0.4 0.145 0.004 / 0.169)",
    "variable": "var(--colors-bronze-light-p3-a-5)"
  },
  "colors.bronze.light.p3.a.6": {
    "value": "color(display-p3 0.388 0.125 0.004 / 0.224)",
    "variable": "var(--colors-bronze-light-p3-a-6)"
  },
  "colors.bronze.light.p3.a.7": {
    "value": "color(display-p3 0.365 0.11 0.004 / 0.295)",
    "variable": "var(--colors-bronze-light-p3-a-7)"
  },
  "colors.bronze.light.p3.a.8": {
    "value": "color(display-p3 0.341 0.102 0.004 / 0.393)",
    "variable": "var(--colors-bronze-light-p3-a-8)"
  },
  "colors.bronze.light.p3.a.9": {
    "value": "color(display-p3 0.29 0.094 0 / 0.546)",
    "variable": "var(--colors-bronze-light-p3-a-9)"
  },
  "colors.bronze.light.p3.a.10": {
    "value": "color(display-p3 0.255 0.082 0 / 0.585)",
    "variable": "var(--colors-bronze-light-p3-a-10)"
  },
  "colors.bronze.light.p3.a.11": {
    "value": "color(display-p3 0.471 0.373 0.336)",
    "variable": "var(--colors-bronze-light-p3-a-11)"
  },
  "colors.bronze.light.p3.a.12": {
    "value": "color(display-p3 0.251 0.191 0.172)",
    "variable": "var(--colors-bronze-light-p3-a-12)"
  },
  "colors.bronze.dark.1": {
    "value": "var(--colors-bronze-dark-1)",
    "variable": "var(--colors-bronze-dark-1)"
  },
  "colors.bronze.dark.2": {
    "value": "var(--colors-bronze-dark-2)",
    "variable": "var(--colors-bronze-dark-2)"
  },
  "colors.bronze.dark.3": {
    "value": "var(--colors-bronze-dark-3)",
    "variable": "var(--colors-bronze-dark-3)"
  },
  "colors.bronze.dark.4": {
    "value": "var(--colors-bronze-dark-4)",
    "variable": "var(--colors-bronze-dark-4)"
  },
  "colors.bronze.dark.5": {
    "value": "var(--colors-bronze-dark-5)",
    "variable": "var(--colors-bronze-dark-5)"
  },
  "colors.bronze.dark.6": {
    "value": "var(--colors-bronze-dark-6)",
    "variable": "var(--colors-bronze-dark-6)"
  },
  "colors.bronze.dark.7": {
    "value": "var(--colors-bronze-dark-7)",
    "variable": "var(--colors-bronze-dark-7)"
  },
  "colors.bronze.dark.8": {
    "value": "var(--colors-bronze-dark-8)",
    "variable": "var(--colors-bronze-dark-8)"
  },
  "colors.bronze.dark.9": {
    "value": "var(--colors-bronze-dark-9)",
    "variable": "var(--colors-bronze-dark-9)"
  },
  "colors.bronze.dark.10": {
    "value": "var(--colors-bronze-dark-10)",
    "variable": "var(--colors-bronze-dark-10)"
  },
  "colors.bronze.dark.11": {
    "value": "var(--colors-bronze-dark-11)",
    "variable": "var(--colors-bronze-dark-11)"
  },
  "colors.bronze.dark.12": {
    "value": "var(--colors-bronze-dark-12)",
    "variable": "var(--colors-bronze-dark-12)"
  },
  "colors.bronze.dark.a.1": {
    "value": "var(--colors-bronze-dark-a-1)",
    "variable": "var(--colors-bronze-dark-a-1)"
  },
  "colors.bronze.dark.a.2": {
    "value": "var(--colors-bronze-dark-a-2)",
    "variable": "var(--colors-bronze-dark-a-2)"
  },
  "colors.bronze.dark.a.3": {
    "value": "var(--colors-bronze-dark-a-3)",
    "variable": "var(--colors-bronze-dark-a-3)"
  },
  "colors.bronze.dark.a.4": {
    "value": "var(--colors-bronze-dark-a-4)",
    "variable": "var(--colors-bronze-dark-a-4)"
  },
  "colors.bronze.dark.a.5": {
    "value": "var(--colors-bronze-dark-a-5)",
    "variable": "var(--colors-bronze-dark-a-5)"
  },
  "colors.bronze.dark.a.6": {
    "value": "var(--colors-bronze-dark-a-6)",
    "variable": "var(--colors-bronze-dark-a-6)"
  },
  "colors.bronze.dark.a.7": {
    "value": "var(--colors-bronze-dark-a-7)",
    "variable": "var(--colors-bronze-dark-a-7)"
  },
  "colors.bronze.dark.a.8": {
    "value": "var(--colors-bronze-dark-a-8)",
    "variable": "var(--colors-bronze-dark-a-8)"
  },
  "colors.bronze.dark.a.9": {
    "value": "var(--colors-bronze-dark-a-9)",
    "variable": "var(--colors-bronze-dark-a-9)"
  },
  "colors.bronze.dark.a.10": {
    "value": "var(--colors-bronze-dark-a-10)",
    "variable": "var(--colors-bronze-dark-a-10)"
  },
  "colors.bronze.dark.a.11": {
    "value": "var(--colors-bronze-dark-a-11)",
    "variable": "var(--colors-bronze-dark-a-11)"
  },
  "colors.bronze.dark.a.12": {
    "value": "var(--colors-bronze-dark-a-12)",
    "variable": "var(--colors-bronze-dark-a-12)"
  },
  "colors.bronze.dark.p3.1": {
    "value": "color(display-p3 0.076 0.067 0.063)",
    "variable": "var(--colors-bronze-dark-p3-1)"
  },
  "colors.bronze.dark.p3.2": {
    "value": "color(display-p3 0.106 0.097 0.093)",
    "variable": "var(--colors-bronze-dark-p3-2)"
  },
  "colors.bronze.dark.p3.3": {
    "value": "color(display-p3 0.147 0.132 0.125)",
    "variable": "var(--colors-bronze-dark-p3-3)"
  },
  "colors.bronze.dark.p3.4": {
    "value": "color(display-p3 0.185 0.166 0.156)",
    "variable": "var(--colors-bronze-dark-p3-4)"
  },
  "colors.bronze.dark.p3.5": {
    "value": "color(display-p3 0.227 0.202 0.19)",
    "variable": "var(--colors-bronze-dark-p3-5)"
  },
  "colors.bronze.dark.p3.6": {
    "value": "color(display-p3 0.278 0.246 0.23)",
    "variable": "var(--colors-bronze-dark-p3-6)"
  },
  "colors.bronze.dark.p3.7": {
    "value": "color(display-p3 0.343 0.302 0.281)",
    "variable": "var(--colors-bronze-dark-p3-7)"
  },
  "colors.bronze.dark.p3.8": {
    "value": "color(display-p3 0.426 0.374 0.347)",
    "variable": "var(--colors-bronze-dark-p3-8)"
  },
  "colors.bronze.dark.p3.9": {
    "value": "color(display-p3 0.611 0.507 0.455)",
    "variable": "var(--colors-bronze-dark-p3-9)"
  },
  "colors.bronze.dark.p3.10": {
    "value": "color(display-p3 0.66 0.556 0.504)",
    "variable": "var(--colors-bronze-dark-p3-10)"
  },
  "colors.bronze.dark.p3.11": {
    "value": "color(display-p3 0.81 0.707 0.655)",
    "variable": "var(--colors-bronze-dark-p3-11)"
  },
  "colors.bronze.dark.p3.12": {
    "value": "color(display-p3 0.921 0.88 0.854)",
    "variable": "var(--colors-bronze-dark-p3-12)"
  },
  "colors.bronze.dark.p3.a.1": {
    "value": "color(display-p3 0.941 0.067 0 / 0.009)",
    "variable": "var(--colors-bronze-dark-p3-a-1)"
  },
  "colors.bronze.dark.p3.a.2": {
    "value": "color(display-p3 0.98 0.8 0.706 / 0.043)",
    "variable": "var(--colors-bronze-dark-p3-a-2)"
  },
  "colors.bronze.dark.p3.a.3": {
    "value": "color(display-p3 0.988 0.851 0.761 / 0.085)",
    "variable": "var(--colors-bronze-dark-p3-a-3)"
  },
  "colors.bronze.dark.p3.a.4": {
    "value": "color(display-p3 0.996 0.839 0.78 / 0.127)",
    "variable": "var(--colors-bronze-dark-p3-a-4)"
  },
  "colors.bronze.dark.p3.a.5": {
    "value": "color(display-p3 0.996 0.863 0.773 / 0.173)",
    "variable": "var(--colors-bronze-dark-p3-a-5)"
  },
  "colors.bronze.dark.p3.a.6": {
    "value": "color(display-p3 1 0.863 0.796 / 0.227)",
    "variable": "var(--colors-bronze-dark-p3-a-6)"
  },
  "colors.bronze.dark.p3.a.7": {
    "value": "color(display-p3 1 0.867 0.8 / 0.295)",
    "variable": "var(--colors-bronze-dark-p3-a-7)"
  },
  "colors.bronze.dark.p3.a.8": {
    "value": "color(display-p3 1 0.859 0.788 / 0.387)",
    "variable": "var(--colors-bronze-dark-p3-a-8)"
  },
  "colors.bronze.dark.p3.a.9": {
    "value": "color(display-p3 1 0.82 0.733 / 0.585)",
    "variable": "var(--colors-bronze-dark-p3-a-9)"
  },
  "colors.bronze.dark.p3.a.10": {
    "value": "color(display-p3 1 0.839 0.761 / 0.635)",
    "variable": "var(--colors-bronze-dark-p3-a-10)"
  },
  "colors.bronze.dark.p3.a.11": {
    "value": "color(display-p3 0.81 0.707 0.655)",
    "variable": "var(--colors-bronze-dark-p3-a-11)"
  },
  "colors.bronze.dark.p3.a.12": {
    "value": "color(display-p3 0.921 0.88 0.854)",
    "variable": "var(--colors-bronze-dark-p3-a-12)"
  },
  "colors.bronze.a.1": {
    "value": "var(--colors-bronze-a-1)",
    "variable": "var(--colors-bronze-a-1)"
  },
  "colors.bronze.a.2": {
    "value": "var(--colors-bronze-a-2)",
    "variable": "var(--colors-bronze-a-2)"
  },
  "colors.bronze.a.3": {
    "value": "var(--colors-bronze-a-3)",
    "variable": "var(--colors-bronze-a-3)"
  },
  "colors.bronze.a.4": {
    "value": "var(--colors-bronze-a-4)",
    "variable": "var(--colors-bronze-a-4)"
  },
  "colors.bronze.a.5": {
    "value": "var(--colors-bronze-a-5)",
    "variable": "var(--colors-bronze-a-5)"
  },
  "colors.bronze.a.6": {
    "value": "var(--colors-bronze-a-6)",
    "variable": "var(--colors-bronze-a-6)"
  },
  "colors.bronze.a.7": {
    "value": "var(--colors-bronze-a-7)",
    "variable": "var(--colors-bronze-a-7)"
  },
  "colors.bronze.a.8": {
    "value": "var(--colors-bronze-a-8)",
    "variable": "var(--colors-bronze-a-8)"
  },
  "colors.bronze.a.9": {
    "value": "var(--colors-bronze-a-9)",
    "variable": "var(--colors-bronze-a-9)"
  },
  "colors.bronze.a.10": {
    "value": "var(--colors-bronze-a-10)",
    "variable": "var(--colors-bronze-a-10)"
  },
  "colors.bronze.a.11": {
    "value": "var(--colors-bronze-a-11)",
    "variable": "var(--colors-bronze-a-11)"
  },
  "colors.bronze.a.12": {
    "value": "var(--colors-bronze-a-12)",
    "variable": "var(--colors-bronze-a-12)"
  },
  "colors.bronze.p3.1": {
    "value": "var(--colors-bronze-p3-1)",
    "variable": "var(--colors-bronze-p3-1)"
  },
  "colors.bronze.p3.2": {
    "value": "var(--colors-bronze-p3-2)",
    "variable": "var(--colors-bronze-p3-2)"
  },
  "colors.bronze.p3.3": {
    "value": "var(--colors-bronze-p3-3)",
    "variable": "var(--colors-bronze-p3-3)"
  },
  "colors.bronze.p3.4": {
    "value": "var(--colors-bronze-p3-4)",
    "variable": "var(--colors-bronze-p3-4)"
  },
  "colors.bronze.p3.5": {
    "value": "var(--colors-bronze-p3-5)",
    "variable": "var(--colors-bronze-p3-5)"
  },
  "colors.bronze.p3.6": {
    "value": "var(--colors-bronze-p3-6)",
    "variable": "var(--colors-bronze-p3-6)"
  },
  "colors.bronze.p3.7": {
    "value": "var(--colors-bronze-p3-7)",
    "variable": "var(--colors-bronze-p3-7)"
  },
  "colors.bronze.p3.8": {
    "value": "var(--colors-bronze-p3-8)",
    "variable": "var(--colors-bronze-p3-8)"
  },
  "colors.bronze.p3.9": {
    "value": "var(--colors-bronze-p3-9)",
    "variable": "var(--colors-bronze-p3-9)"
  },
  "colors.bronze.p3.10": {
    "value": "var(--colors-bronze-p3-10)",
    "variable": "var(--colors-bronze-p3-10)"
  },
  "colors.bronze.p3.11": {
    "value": "var(--colors-bronze-p3-11)",
    "variable": "var(--colors-bronze-p3-11)"
  },
  "colors.bronze.p3.12": {
    "value": "var(--colors-bronze-p3-12)",
    "variable": "var(--colors-bronze-p3-12)"
  },
  "colors.bronze.p3.a.1": {
    "value": "var(--colors-bronze-p3-a-1)",
    "variable": "var(--colors-bronze-p3-a-1)"
  },
  "colors.bronze.p3.a.2": {
    "value": "var(--colors-bronze-p3-a-2)",
    "variable": "var(--colors-bronze-p3-a-2)"
  },
  "colors.bronze.p3.a.3": {
    "value": "var(--colors-bronze-p3-a-3)",
    "variable": "var(--colors-bronze-p3-a-3)"
  },
  "colors.bronze.p3.a.4": {
    "value": "var(--colors-bronze-p3-a-4)",
    "variable": "var(--colors-bronze-p3-a-4)"
  },
  "colors.bronze.p3.a.5": {
    "value": "var(--colors-bronze-p3-a-5)",
    "variable": "var(--colors-bronze-p3-a-5)"
  },
  "colors.bronze.p3.a.6": {
    "value": "var(--colors-bronze-p3-a-6)",
    "variable": "var(--colors-bronze-p3-a-6)"
  },
  "colors.bronze.p3.a.7": {
    "value": "var(--colors-bronze-p3-a-7)",
    "variable": "var(--colors-bronze-p3-a-7)"
  },
  "colors.bronze.p3.a.8": {
    "value": "var(--colors-bronze-p3-a-8)",
    "variable": "var(--colors-bronze-p3-a-8)"
  },
  "colors.bronze.p3.a.9": {
    "value": "var(--colors-bronze-p3-a-9)",
    "variable": "var(--colors-bronze-p3-a-9)"
  },
  "colors.bronze.p3.a.10": {
    "value": "var(--colors-bronze-p3-a-10)",
    "variable": "var(--colors-bronze-p3-a-10)"
  },
  "colors.bronze.p3.a.11": {
    "value": "var(--colors-bronze-p3-a-11)",
    "variable": "var(--colors-bronze-p3-a-11)"
  },
  "colors.bronze.p3.a.12": {
    "value": "var(--colors-bronze-p3-a-12)",
    "variable": "var(--colors-bronze-p3-a-12)"
  },
  "colors.brown.1": {
    "value": "var(--colors-brown-1)",
    "variable": "var(--colors-brown-1)"
  },
  "colors.brown.2": {
    "value": "var(--colors-brown-2)",
    "variable": "var(--colors-brown-2)"
  },
  "colors.brown.3": {
    "value": "var(--colors-brown-3)",
    "variable": "var(--colors-brown-3)"
  },
  "colors.brown.4": {
    "value": "var(--colors-brown-4)",
    "variable": "var(--colors-brown-4)"
  },
  "colors.brown.5": {
    "value": "var(--colors-brown-5)",
    "variable": "var(--colors-brown-5)"
  },
  "colors.brown.6": {
    "value": "var(--colors-brown-6)",
    "variable": "var(--colors-brown-6)"
  },
  "colors.brown.7": {
    "value": "var(--colors-brown-7)",
    "variable": "var(--colors-brown-7)"
  },
  "colors.brown.8": {
    "value": "var(--colors-brown-8)",
    "variable": "var(--colors-brown-8)"
  },
  "colors.brown.9": {
    "value": "var(--colors-brown-9)",
    "variable": "var(--colors-brown-9)"
  },
  "colors.brown.10": {
    "value": "var(--colors-brown-10)",
    "variable": "var(--colors-brown-10)"
  },
  "colors.brown.11": {
    "value": "var(--colors-brown-11)",
    "variable": "var(--colors-brown-11)"
  },
  "colors.brown.12": {
    "value": "var(--colors-brown-12)",
    "variable": "var(--colors-brown-12)"
  },
  "colors.brown.light.1": {
    "value": "var(--colors-brown-light-1)",
    "variable": "var(--colors-brown-light-1)"
  },
  "colors.brown.light.2": {
    "value": "var(--colors-brown-light-2)",
    "variable": "var(--colors-brown-light-2)"
  },
  "colors.brown.light.3": {
    "value": "var(--colors-brown-light-3)",
    "variable": "var(--colors-brown-light-3)"
  },
  "colors.brown.light.4": {
    "value": "var(--colors-brown-light-4)",
    "variable": "var(--colors-brown-light-4)"
  },
  "colors.brown.light.5": {
    "value": "var(--colors-brown-light-5)",
    "variable": "var(--colors-brown-light-5)"
  },
  "colors.brown.light.6": {
    "value": "var(--colors-brown-light-6)",
    "variable": "var(--colors-brown-light-6)"
  },
  "colors.brown.light.7": {
    "value": "var(--colors-brown-light-7)",
    "variable": "var(--colors-brown-light-7)"
  },
  "colors.brown.light.8": {
    "value": "var(--colors-brown-light-8)",
    "variable": "var(--colors-brown-light-8)"
  },
  "colors.brown.light.9": {
    "value": "var(--colors-brown-light-9)",
    "variable": "var(--colors-brown-light-9)"
  },
  "colors.brown.light.10": {
    "value": "var(--colors-brown-light-10)",
    "variable": "var(--colors-brown-light-10)"
  },
  "colors.brown.light.11": {
    "value": "var(--colors-brown-light-11)",
    "variable": "var(--colors-brown-light-11)"
  },
  "colors.brown.light.12": {
    "value": "var(--colors-brown-light-12)",
    "variable": "var(--colors-brown-light-12)"
  },
  "colors.brown.light.a.1": {
    "value": "var(--colors-brown-light-a-1)",
    "variable": "var(--colors-brown-light-a-1)"
  },
  "colors.brown.light.a.2": {
    "value": "var(--colors-brown-light-a-2)",
    "variable": "var(--colors-brown-light-a-2)"
  },
  "colors.brown.light.a.3": {
    "value": "var(--colors-brown-light-a-3)",
    "variable": "var(--colors-brown-light-a-3)"
  },
  "colors.brown.light.a.4": {
    "value": "var(--colors-brown-light-a-4)",
    "variable": "var(--colors-brown-light-a-4)"
  },
  "colors.brown.light.a.5": {
    "value": "var(--colors-brown-light-a-5)",
    "variable": "var(--colors-brown-light-a-5)"
  },
  "colors.brown.light.a.6": {
    "value": "var(--colors-brown-light-a-6)",
    "variable": "var(--colors-brown-light-a-6)"
  },
  "colors.brown.light.a.7": {
    "value": "var(--colors-brown-light-a-7)",
    "variable": "var(--colors-brown-light-a-7)"
  },
  "colors.brown.light.a.8": {
    "value": "var(--colors-brown-light-a-8)",
    "variable": "var(--colors-brown-light-a-8)"
  },
  "colors.brown.light.a.9": {
    "value": "var(--colors-brown-light-a-9)",
    "variable": "var(--colors-brown-light-a-9)"
  },
  "colors.brown.light.a.10": {
    "value": "var(--colors-brown-light-a-10)",
    "variable": "var(--colors-brown-light-a-10)"
  },
  "colors.brown.light.a.11": {
    "value": "var(--colors-brown-light-a-11)",
    "variable": "var(--colors-brown-light-a-11)"
  },
  "colors.brown.light.a.12": {
    "value": "var(--colors-brown-light-a-12)",
    "variable": "var(--colors-brown-light-a-12)"
  },
  "colors.brown.light.p3.1": {
    "value": "color(display-p3 0.995 0.992 0.989)",
    "variable": "var(--colors-brown-light-p3-1)"
  },
  "colors.brown.light.p3.2": {
    "value": "color(display-p3 0.987 0.976 0.964)",
    "variable": "var(--colors-brown-light-p3-2)"
  },
  "colors.brown.light.p3.3": {
    "value": "color(display-p3 0.959 0.936 0.909)",
    "variable": "var(--colors-brown-light-p3-3)"
  },
  "colors.brown.light.p3.4": {
    "value": "color(display-p3 0.934 0.897 0.855)",
    "variable": "var(--colors-brown-light-p3-4)"
  },
  "colors.brown.light.p3.5": {
    "value": "color(display-p3 0.909 0.856 0.798)",
    "variable": "var(--colors-brown-light-p3-5)"
  },
  "colors.brown.light.p3.6": {
    "value": "color(display-p3 0.88 0.808 0.73)",
    "variable": "var(--colors-brown-light-p3-6)"
  },
  "colors.brown.light.p3.7": {
    "value": "color(display-p3 0.841 0.742 0.639)",
    "variable": "var(--colors-brown-light-p3-7)"
  },
  "colors.brown.light.p3.8": {
    "value": "color(display-p3 0.782 0.647 0.514)",
    "variable": "var(--colors-brown-light-p3-8)"
  },
  "colors.brown.light.p3.9": {
    "value": "color(display-p3 0.651 0.505 0.368)",
    "variable": "var(--colors-brown-light-p3-9)"
  },
  "colors.brown.light.p3.10": {
    "value": "color(display-p3 0.601 0.465 0.344)",
    "variable": "var(--colors-brown-light-p3-10)"
  },
  "colors.brown.light.p3.11": {
    "value": "color(display-p3 0.485 0.374 0.288)",
    "variable": "var(--colors-brown-light-p3-11)"
  },
  "colors.brown.light.p3.12": {
    "value": "color(display-p3 0.236 0.202 0.183)",
    "variable": "var(--colors-brown-light-p3-12)"
  },
  "colors.brown.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.349 0.024 / 0.012)",
    "variable": "var(--colors-brown-light-p3-a-1)"
  },
  "colors.brown.light.p3.a.2": {
    "value": "color(display-p3 0.675 0.349 0.024 / 0.036)",
    "variable": "var(--colors-brown-light-p3-a-2)"
  },
  "colors.brown.light.p3.a.3": {
    "value": "color(display-p3 0.573 0.314 0.012 / 0.091)",
    "variable": "var(--colors-brown-light-p3-a-3)"
  },
  "colors.brown.light.p3.a.4": {
    "value": "color(display-p3 0.545 0.302 0.008 / 0.146)",
    "variable": "var(--colors-brown-light-p3-a-4)"
  },
  "colors.brown.light.p3.a.5": {
    "value": "color(display-p3 0.561 0.29 0.004 / 0.204)",
    "variable": "var(--colors-brown-light-p3-a-5)"
  },
  "colors.brown.light.p3.a.6": {
    "value": "color(display-p3 0.553 0.294 0.004 / 0.271)",
    "variable": "var(--colors-brown-light-p3-a-6)"
  },
  "colors.brown.light.p3.a.7": {
    "value": "color(display-p3 0.557 0.286 0.004 / 0.361)",
    "variable": "var(--colors-brown-light-p3-a-7)"
  },
  "colors.brown.light.p3.a.8": {
    "value": "color(display-p3 0.549 0.275 0.004 / 0.487)",
    "variable": "var(--colors-brown-light-p3-a-8)"
  },
  "colors.brown.light.p3.a.9": {
    "value": "color(display-p3 0.447 0.22 0 / 0.632)",
    "variable": "var(--colors-brown-light-p3-a-9)"
  },
  "colors.brown.light.p3.a.10": {
    "value": "color(display-p3 0.388 0.188 0 / 0.655)",
    "variable": "var(--colors-brown-light-p3-a-10)"
  },
  "colors.brown.light.p3.a.11": {
    "value": "color(display-p3 0.485 0.374 0.288)",
    "variable": "var(--colors-brown-light-p3-a-11)"
  },
  "colors.brown.light.p3.a.12": {
    "value": "color(display-p3 0.236 0.202 0.183)",
    "variable": "var(--colors-brown-light-p3-a-12)"
  },
  "colors.brown.dark.1": {
    "value": "var(--colors-brown-dark-1)",
    "variable": "var(--colors-brown-dark-1)"
  },
  "colors.brown.dark.2": {
    "value": "var(--colors-brown-dark-2)",
    "variable": "var(--colors-brown-dark-2)"
  },
  "colors.brown.dark.3": {
    "value": "var(--colors-brown-dark-3)",
    "variable": "var(--colors-brown-dark-3)"
  },
  "colors.brown.dark.4": {
    "value": "var(--colors-brown-dark-4)",
    "variable": "var(--colors-brown-dark-4)"
  },
  "colors.brown.dark.5": {
    "value": "var(--colors-brown-dark-5)",
    "variable": "var(--colors-brown-dark-5)"
  },
  "colors.brown.dark.6": {
    "value": "var(--colors-brown-dark-6)",
    "variable": "var(--colors-brown-dark-6)"
  },
  "colors.brown.dark.7": {
    "value": "var(--colors-brown-dark-7)",
    "variable": "var(--colors-brown-dark-7)"
  },
  "colors.brown.dark.8": {
    "value": "var(--colors-brown-dark-8)",
    "variable": "var(--colors-brown-dark-8)"
  },
  "colors.brown.dark.9": {
    "value": "var(--colors-brown-dark-9)",
    "variable": "var(--colors-brown-dark-9)"
  },
  "colors.brown.dark.10": {
    "value": "var(--colors-brown-dark-10)",
    "variable": "var(--colors-brown-dark-10)"
  },
  "colors.brown.dark.11": {
    "value": "var(--colors-brown-dark-11)",
    "variable": "var(--colors-brown-dark-11)"
  },
  "colors.brown.dark.12": {
    "value": "var(--colors-brown-dark-12)",
    "variable": "var(--colors-brown-dark-12)"
  },
  "colors.brown.dark.a.1": {
    "value": "var(--colors-brown-dark-a-1)",
    "variable": "var(--colors-brown-dark-a-1)"
  },
  "colors.brown.dark.a.2": {
    "value": "var(--colors-brown-dark-a-2)",
    "variable": "var(--colors-brown-dark-a-2)"
  },
  "colors.brown.dark.a.3": {
    "value": "var(--colors-brown-dark-a-3)",
    "variable": "var(--colors-brown-dark-a-3)"
  },
  "colors.brown.dark.a.4": {
    "value": "var(--colors-brown-dark-a-4)",
    "variable": "var(--colors-brown-dark-a-4)"
  },
  "colors.brown.dark.a.5": {
    "value": "var(--colors-brown-dark-a-5)",
    "variable": "var(--colors-brown-dark-a-5)"
  },
  "colors.brown.dark.a.6": {
    "value": "var(--colors-brown-dark-a-6)",
    "variable": "var(--colors-brown-dark-a-6)"
  },
  "colors.brown.dark.a.7": {
    "value": "var(--colors-brown-dark-a-7)",
    "variable": "var(--colors-brown-dark-a-7)"
  },
  "colors.brown.dark.a.8": {
    "value": "var(--colors-brown-dark-a-8)",
    "variable": "var(--colors-brown-dark-a-8)"
  },
  "colors.brown.dark.a.9": {
    "value": "var(--colors-brown-dark-a-9)",
    "variable": "var(--colors-brown-dark-a-9)"
  },
  "colors.brown.dark.a.10": {
    "value": "var(--colors-brown-dark-a-10)",
    "variable": "var(--colors-brown-dark-a-10)"
  },
  "colors.brown.dark.a.11": {
    "value": "var(--colors-brown-dark-a-11)",
    "variable": "var(--colors-brown-dark-a-11)"
  },
  "colors.brown.dark.a.12": {
    "value": "var(--colors-brown-dark-a-12)",
    "variable": "var(--colors-brown-dark-a-12)"
  },
  "colors.brown.dark.p3.1": {
    "value": "color(display-p3 0.071 0.067 0.059)",
    "variable": "var(--colors-brown-dark-p3-1)"
  },
  "colors.brown.dark.p3.2": {
    "value": "color(display-p3 0.107 0.095 0.087)",
    "variable": "var(--colors-brown-dark-p3-2)"
  },
  "colors.brown.dark.p3.3": {
    "value": "color(display-p3 0.151 0.13 0.115)",
    "variable": "var(--colors-brown-dark-p3-3)"
  },
  "colors.brown.dark.p3.4": {
    "value": "color(display-p3 0.191 0.161 0.138)",
    "variable": "var(--colors-brown-dark-p3-4)"
  },
  "colors.brown.dark.p3.5": {
    "value": "color(display-p3 0.235 0.194 0.162)",
    "variable": "var(--colors-brown-dark-p3-5)"
  },
  "colors.brown.dark.p3.6": {
    "value": "color(display-p3 0.291 0.237 0.192)",
    "variable": "var(--colors-brown-dark-p3-6)"
  },
  "colors.brown.dark.p3.7": {
    "value": "color(display-p3 0.365 0.295 0.232)",
    "variable": "var(--colors-brown-dark-p3-7)"
  },
  "colors.brown.dark.p3.8": {
    "value": "color(display-p3 0.469 0.377 0.287)",
    "variable": "var(--colors-brown-dark-p3-8)"
  },
  "colors.brown.dark.p3.9": {
    "value": "color(display-p3 0.651 0.505 0.368)",
    "variable": "var(--colors-brown-dark-p3-9)"
  },
  "colors.brown.dark.p3.10": {
    "value": "color(display-p3 0.697 0.557 0.423)",
    "variable": "var(--colors-brown-dark-p3-10)"
  },
  "colors.brown.dark.p3.11": {
    "value": "color(display-p3 0.835 0.715 0.597)",
    "variable": "var(--colors-brown-dark-p3-11)"
  },
  "colors.brown.dark.p3.12": {
    "value": "color(display-p3 0.938 0.885 0.802)",
    "variable": "var(--colors-brown-dark-p3-12)"
  },
  "colors.brown.dark.p3.a.1": {
    "value": "color(display-p3 0.855 0.071 0 / 0.005)",
    "variable": "var(--colors-brown-dark-p3-a-1)"
  },
  "colors.brown.dark.p3.a.2": {
    "value": "color(display-p3 0.98 0.706 0.525 / 0.043)",
    "variable": "var(--colors-brown-dark-p3-a-2)"
  },
  "colors.brown.dark.p3.a.3": {
    "value": "color(display-p3 0.996 0.745 0.576 / 0.093)",
    "variable": "var(--colors-brown-dark-p3-a-3)"
  },
  "colors.brown.dark.p3.a.4": {
    "value": "color(display-p3 1 0.765 0.592 / 0.135)",
    "variable": "var(--colors-brown-dark-p3-a-4)"
  },
  "colors.brown.dark.p3.a.5": {
    "value": "color(display-p3 1 0.761 0.588 / 0.181)",
    "variable": "var(--colors-brown-dark-p3-a-5)"
  },
  "colors.brown.dark.p3.a.6": {
    "value": "color(display-p3 1 0.773 0.592 / 0.24)",
    "variable": "var(--colors-brown-dark-p3-a-6)"
  },
  "colors.brown.dark.p3.a.7": {
    "value": "color(display-p3 0.996 0.776 0.58 / 0.32)",
    "variable": "var(--colors-brown-dark-p3-a-7)"
  },
  "colors.brown.dark.p3.a.8": {
    "value": "color(display-p3 1 0.78 0.573 / 0.433)",
    "variable": "var(--colors-brown-dark-p3-a-8)"
  },
  "colors.brown.dark.p3.a.9": {
    "value": "color(display-p3 1 0.769 0.549 / 0.627)",
    "variable": "var(--colors-brown-dark-p3-a-9)"
  },
  "colors.brown.dark.p3.a.10": {
    "value": "color(display-p3 1 0.792 0.596 / 0.677)",
    "variable": "var(--colors-brown-dark-p3-a-10)"
  },
  "colors.brown.dark.p3.a.11": {
    "value": "color(display-p3 0.835 0.715 0.597)",
    "variable": "var(--colors-brown-dark-p3-a-11)"
  },
  "colors.brown.dark.p3.a.12": {
    "value": "color(display-p3 0.938 0.885 0.802)",
    "variable": "var(--colors-brown-dark-p3-a-12)"
  },
  "colors.brown.a.1": {
    "value": "var(--colors-brown-a-1)",
    "variable": "var(--colors-brown-a-1)"
  },
  "colors.brown.a.2": {
    "value": "var(--colors-brown-a-2)",
    "variable": "var(--colors-brown-a-2)"
  },
  "colors.brown.a.3": {
    "value": "var(--colors-brown-a-3)",
    "variable": "var(--colors-brown-a-3)"
  },
  "colors.brown.a.4": {
    "value": "var(--colors-brown-a-4)",
    "variable": "var(--colors-brown-a-4)"
  },
  "colors.brown.a.5": {
    "value": "var(--colors-brown-a-5)",
    "variable": "var(--colors-brown-a-5)"
  },
  "colors.brown.a.6": {
    "value": "var(--colors-brown-a-6)",
    "variable": "var(--colors-brown-a-6)"
  },
  "colors.brown.a.7": {
    "value": "var(--colors-brown-a-7)",
    "variable": "var(--colors-brown-a-7)"
  },
  "colors.brown.a.8": {
    "value": "var(--colors-brown-a-8)",
    "variable": "var(--colors-brown-a-8)"
  },
  "colors.brown.a.9": {
    "value": "var(--colors-brown-a-9)",
    "variable": "var(--colors-brown-a-9)"
  },
  "colors.brown.a.10": {
    "value": "var(--colors-brown-a-10)",
    "variable": "var(--colors-brown-a-10)"
  },
  "colors.brown.a.11": {
    "value": "var(--colors-brown-a-11)",
    "variable": "var(--colors-brown-a-11)"
  },
  "colors.brown.a.12": {
    "value": "var(--colors-brown-a-12)",
    "variable": "var(--colors-brown-a-12)"
  },
  "colors.brown.p3.1": {
    "value": "var(--colors-brown-p3-1)",
    "variable": "var(--colors-brown-p3-1)"
  },
  "colors.brown.p3.2": {
    "value": "var(--colors-brown-p3-2)",
    "variable": "var(--colors-brown-p3-2)"
  },
  "colors.brown.p3.3": {
    "value": "var(--colors-brown-p3-3)",
    "variable": "var(--colors-brown-p3-3)"
  },
  "colors.brown.p3.4": {
    "value": "var(--colors-brown-p3-4)",
    "variable": "var(--colors-brown-p3-4)"
  },
  "colors.brown.p3.5": {
    "value": "var(--colors-brown-p3-5)",
    "variable": "var(--colors-brown-p3-5)"
  },
  "colors.brown.p3.6": {
    "value": "var(--colors-brown-p3-6)",
    "variable": "var(--colors-brown-p3-6)"
  },
  "colors.brown.p3.7": {
    "value": "var(--colors-brown-p3-7)",
    "variable": "var(--colors-brown-p3-7)"
  },
  "colors.brown.p3.8": {
    "value": "var(--colors-brown-p3-8)",
    "variable": "var(--colors-brown-p3-8)"
  },
  "colors.brown.p3.9": {
    "value": "var(--colors-brown-p3-9)",
    "variable": "var(--colors-brown-p3-9)"
  },
  "colors.brown.p3.10": {
    "value": "var(--colors-brown-p3-10)",
    "variable": "var(--colors-brown-p3-10)"
  },
  "colors.brown.p3.11": {
    "value": "var(--colors-brown-p3-11)",
    "variable": "var(--colors-brown-p3-11)"
  },
  "colors.brown.p3.12": {
    "value": "var(--colors-brown-p3-12)",
    "variable": "var(--colors-brown-p3-12)"
  },
  "colors.brown.p3.a.1": {
    "value": "var(--colors-brown-p3-a-1)",
    "variable": "var(--colors-brown-p3-a-1)"
  },
  "colors.brown.p3.a.2": {
    "value": "var(--colors-brown-p3-a-2)",
    "variable": "var(--colors-brown-p3-a-2)"
  },
  "colors.brown.p3.a.3": {
    "value": "var(--colors-brown-p3-a-3)",
    "variable": "var(--colors-brown-p3-a-3)"
  },
  "colors.brown.p3.a.4": {
    "value": "var(--colors-brown-p3-a-4)",
    "variable": "var(--colors-brown-p3-a-4)"
  },
  "colors.brown.p3.a.5": {
    "value": "var(--colors-brown-p3-a-5)",
    "variable": "var(--colors-brown-p3-a-5)"
  },
  "colors.brown.p3.a.6": {
    "value": "var(--colors-brown-p3-a-6)",
    "variable": "var(--colors-brown-p3-a-6)"
  },
  "colors.brown.p3.a.7": {
    "value": "var(--colors-brown-p3-a-7)",
    "variable": "var(--colors-brown-p3-a-7)"
  },
  "colors.brown.p3.a.8": {
    "value": "var(--colors-brown-p3-a-8)",
    "variable": "var(--colors-brown-p3-a-8)"
  },
  "colors.brown.p3.a.9": {
    "value": "var(--colors-brown-p3-a-9)",
    "variable": "var(--colors-brown-p3-a-9)"
  },
  "colors.brown.p3.a.10": {
    "value": "var(--colors-brown-p3-a-10)",
    "variable": "var(--colors-brown-p3-a-10)"
  },
  "colors.brown.p3.a.11": {
    "value": "var(--colors-brown-p3-a-11)",
    "variable": "var(--colors-brown-p3-a-11)"
  },
  "colors.brown.p3.a.12": {
    "value": "var(--colors-brown-p3-a-12)",
    "variable": "var(--colors-brown-p3-a-12)"
  },
  "colors.crimson.1": {
    "value": "var(--colors-crimson-1)",
    "variable": "var(--colors-crimson-1)"
  },
  "colors.crimson.2": {
    "value": "var(--colors-crimson-2)",
    "variable": "var(--colors-crimson-2)"
  },
  "colors.crimson.3": {
    "value": "var(--colors-crimson-3)",
    "variable": "var(--colors-crimson-3)"
  },
  "colors.crimson.4": {
    "value": "var(--colors-crimson-4)",
    "variable": "var(--colors-crimson-4)"
  },
  "colors.crimson.5": {
    "value": "var(--colors-crimson-5)",
    "variable": "var(--colors-crimson-5)"
  },
  "colors.crimson.6": {
    "value": "var(--colors-crimson-6)",
    "variable": "var(--colors-crimson-6)"
  },
  "colors.crimson.7": {
    "value": "var(--colors-crimson-7)",
    "variable": "var(--colors-crimson-7)"
  },
  "colors.crimson.8": {
    "value": "var(--colors-crimson-8)",
    "variable": "var(--colors-crimson-8)"
  },
  "colors.crimson.9": {
    "value": "var(--colors-crimson-9)",
    "variable": "var(--colors-crimson-9)"
  },
  "colors.crimson.10": {
    "value": "var(--colors-crimson-10)",
    "variable": "var(--colors-crimson-10)"
  },
  "colors.crimson.11": {
    "value": "var(--colors-crimson-11)",
    "variable": "var(--colors-crimson-11)"
  },
  "colors.crimson.12": {
    "value": "var(--colors-crimson-12)",
    "variable": "var(--colors-crimson-12)"
  },
  "colors.crimson.light.1": {
    "value": "var(--colors-crimson-light-1)",
    "variable": "var(--colors-crimson-light-1)"
  },
  "colors.crimson.light.2": {
    "value": "var(--colors-crimson-light-2)",
    "variable": "var(--colors-crimson-light-2)"
  },
  "colors.crimson.light.3": {
    "value": "var(--colors-crimson-light-3)",
    "variable": "var(--colors-crimson-light-3)"
  },
  "colors.crimson.light.4": {
    "value": "var(--colors-crimson-light-4)",
    "variable": "var(--colors-crimson-light-4)"
  },
  "colors.crimson.light.5": {
    "value": "var(--colors-crimson-light-5)",
    "variable": "var(--colors-crimson-light-5)"
  },
  "colors.crimson.light.6": {
    "value": "var(--colors-crimson-light-6)",
    "variable": "var(--colors-crimson-light-6)"
  },
  "colors.crimson.light.7": {
    "value": "var(--colors-crimson-light-7)",
    "variable": "var(--colors-crimson-light-7)"
  },
  "colors.crimson.light.8": {
    "value": "var(--colors-crimson-light-8)",
    "variable": "var(--colors-crimson-light-8)"
  },
  "colors.crimson.light.9": {
    "value": "var(--colors-crimson-light-9)",
    "variable": "var(--colors-crimson-light-9)"
  },
  "colors.crimson.light.10": {
    "value": "var(--colors-crimson-light-10)",
    "variable": "var(--colors-crimson-light-10)"
  },
  "colors.crimson.light.11": {
    "value": "var(--colors-crimson-light-11)",
    "variable": "var(--colors-crimson-light-11)"
  },
  "colors.crimson.light.12": {
    "value": "var(--colors-crimson-light-12)",
    "variable": "var(--colors-crimson-light-12)"
  },
  "colors.crimson.light.a.1": {
    "value": "var(--colors-crimson-light-a-1)",
    "variable": "var(--colors-crimson-light-a-1)"
  },
  "colors.crimson.light.a.2": {
    "value": "var(--colors-crimson-light-a-2)",
    "variable": "var(--colors-crimson-light-a-2)"
  },
  "colors.crimson.light.a.3": {
    "value": "var(--colors-crimson-light-a-3)",
    "variable": "var(--colors-crimson-light-a-3)"
  },
  "colors.crimson.light.a.4": {
    "value": "var(--colors-crimson-light-a-4)",
    "variable": "var(--colors-crimson-light-a-4)"
  },
  "colors.crimson.light.a.5": {
    "value": "var(--colors-crimson-light-a-5)",
    "variable": "var(--colors-crimson-light-a-5)"
  },
  "colors.crimson.light.a.6": {
    "value": "var(--colors-crimson-light-a-6)",
    "variable": "var(--colors-crimson-light-a-6)"
  },
  "colors.crimson.light.a.7": {
    "value": "var(--colors-crimson-light-a-7)",
    "variable": "var(--colors-crimson-light-a-7)"
  },
  "colors.crimson.light.a.8": {
    "value": "var(--colors-crimson-light-a-8)",
    "variable": "var(--colors-crimson-light-a-8)"
  },
  "colors.crimson.light.a.9": {
    "value": "var(--colors-crimson-light-a-9)",
    "variable": "var(--colors-crimson-light-a-9)"
  },
  "colors.crimson.light.a.10": {
    "value": "var(--colors-crimson-light-a-10)",
    "variable": "var(--colors-crimson-light-a-10)"
  },
  "colors.crimson.light.a.11": {
    "value": "var(--colors-crimson-light-a-11)",
    "variable": "var(--colors-crimson-light-a-11)"
  },
  "colors.crimson.light.a.12": {
    "value": "var(--colors-crimson-light-a-12)",
    "variable": "var(--colors-crimson-light-a-12)"
  },
  "colors.crimson.light.p3.1": {
    "value": "color(display-p3 0.998 0.989 0.992)",
    "variable": "var(--colors-crimson-light-p3-1)"
  },
  "colors.crimson.light.p3.2": {
    "value": "color(display-p3 0.991 0.969 0.976)",
    "variable": "var(--colors-crimson-light-p3-2)"
  },
  "colors.crimson.light.p3.3": {
    "value": "color(display-p3 0.987 0.917 0.941)",
    "variable": "var(--colors-crimson-light-p3-3)"
  },
  "colors.crimson.light.p3.4": {
    "value": "color(display-p3 0.975 0.866 0.904)",
    "variable": "var(--colors-crimson-light-p3-4)"
  },
  "colors.crimson.light.p3.5": {
    "value": "color(display-p3 0.953 0.813 0.864)",
    "variable": "var(--colors-crimson-light-p3-5)"
  },
  "colors.crimson.light.p3.6": {
    "value": "color(display-p3 0.921 0.755 0.817)",
    "variable": "var(--colors-crimson-light-p3-6)"
  },
  "colors.crimson.light.p3.7": {
    "value": "color(display-p3 0.88 0.683 0.761)",
    "variable": "var(--colors-crimson-light-p3-7)"
  },
  "colors.crimson.light.p3.8": {
    "value": "color(display-p3 0.834 0.592 0.694)",
    "variable": "var(--colors-crimson-light-p3-8)"
  },
  "colors.crimson.light.p3.9": {
    "value": "color(display-p3 0.843 0.298 0.507)",
    "variable": "var(--colors-crimson-light-p3-9)"
  },
  "colors.crimson.light.p3.10": {
    "value": "color(display-p3 0.807 0.266 0.468)",
    "variable": "var(--colors-crimson-light-p3-10)"
  },
  "colors.crimson.light.p3.11": {
    "value": "color(display-p3 0.731 0.195 0.388)",
    "variable": "var(--colors-crimson-light-p3-11)"
  },
  "colors.crimson.light.p3.12": {
    "value": "color(display-p3 0.352 0.111 0.221)",
    "variable": "var(--colors-crimson-light-p3-12)"
  },
  "colors.crimson.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.024 0.349 / 0.012)",
    "variable": "var(--colors-crimson-light-p3-a-1)"
  },
  "colors.crimson.light.p3.a.2": {
    "value": "color(display-p3 0.757 0.02 0.267 / 0.032)",
    "variable": "var(--colors-crimson-light-p3-a-2)"
  },
  "colors.crimson.light.p3.a.3": {
    "value": "color(display-p3 0.859 0.008 0.294 / 0.083)",
    "variable": "var(--colors-crimson-light-p3-a-3)"
  },
  "colors.crimson.light.p3.a.4": {
    "value": "color(display-p3 0.827 0.008 0.298 / 0.134)",
    "variable": "var(--colors-crimson-light-p3-a-4)"
  },
  "colors.crimson.light.p3.a.5": {
    "value": "color(display-p3 0.753 0.008 0.275 / 0.189)",
    "variable": "var(--colors-crimson-light-p3-a-5)"
  },
  "colors.crimson.light.p3.a.6": {
    "value": "color(display-p3 0.682 0.004 0.247 / 0.244)",
    "variable": "var(--colors-crimson-light-p3-a-6)"
  },
  "colors.crimson.light.p3.a.7": {
    "value": "color(display-p3 0.62 0.004 0.251 / 0.318)",
    "variable": "var(--colors-crimson-light-p3-a-7)"
  },
  "colors.crimson.light.p3.a.8": {
    "value": "color(display-p3 0.6 0.004 0.251 / 0.408)",
    "variable": "var(--colors-crimson-light-p3-a-8)"
  },
  "colors.crimson.light.p3.a.9": {
    "value": "color(display-p3 0.776 0 0.298 / 0.702)",
    "variable": "var(--colors-crimson-light-p3-a-9)"
  },
  "colors.crimson.light.p3.a.10": {
    "value": "color(display-p3 0.737 0 0.275 / 0.734)",
    "variable": "var(--colors-crimson-light-p3-a-10)"
  },
  "colors.crimson.light.p3.a.11": {
    "value": "color(display-p3 0.731 0.195 0.388)",
    "variable": "var(--colors-crimson-light-p3-a-11)"
  },
  "colors.crimson.light.p3.a.12": {
    "value": "color(display-p3 0.352 0.111 0.221)",
    "variable": "var(--colors-crimson-light-p3-a-12)"
  },
  "colors.crimson.dark.1": {
    "value": "var(--colors-crimson-dark-1)",
    "variable": "var(--colors-crimson-dark-1)"
  },
  "colors.crimson.dark.2": {
    "value": "var(--colors-crimson-dark-2)",
    "variable": "var(--colors-crimson-dark-2)"
  },
  "colors.crimson.dark.3": {
    "value": "var(--colors-crimson-dark-3)",
    "variable": "var(--colors-crimson-dark-3)"
  },
  "colors.crimson.dark.4": {
    "value": "var(--colors-crimson-dark-4)",
    "variable": "var(--colors-crimson-dark-4)"
  },
  "colors.crimson.dark.5": {
    "value": "var(--colors-crimson-dark-5)",
    "variable": "var(--colors-crimson-dark-5)"
  },
  "colors.crimson.dark.6": {
    "value": "var(--colors-crimson-dark-6)",
    "variable": "var(--colors-crimson-dark-6)"
  },
  "colors.crimson.dark.7": {
    "value": "var(--colors-crimson-dark-7)",
    "variable": "var(--colors-crimson-dark-7)"
  },
  "colors.crimson.dark.8": {
    "value": "var(--colors-crimson-dark-8)",
    "variable": "var(--colors-crimson-dark-8)"
  },
  "colors.crimson.dark.9": {
    "value": "var(--colors-crimson-dark-9)",
    "variable": "var(--colors-crimson-dark-9)"
  },
  "colors.crimson.dark.10": {
    "value": "var(--colors-crimson-dark-10)",
    "variable": "var(--colors-crimson-dark-10)"
  },
  "colors.crimson.dark.11": {
    "value": "var(--colors-crimson-dark-11)",
    "variable": "var(--colors-crimson-dark-11)"
  },
  "colors.crimson.dark.12": {
    "value": "var(--colors-crimson-dark-12)",
    "variable": "var(--colors-crimson-dark-12)"
  },
  "colors.crimson.dark.a.1": {
    "value": "var(--colors-crimson-dark-a-1)",
    "variable": "var(--colors-crimson-dark-a-1)"
  },
  "colors.crimson.dark.a.2": {
    "value": "var(--colors-crimson-dark-a-2)",
    "variable": "var(--colors-crimson-dark-a-2)"
  },
  "colors.crimson.dark.a.3": {
    "value": "var(--colors-crimson-dark-a-3)",
    "variable": "var(--colors-crimson-dark-a-3)"
  },
  "colors.crimson.dark.a.4": {
    "value": "var(--colors-crimson-dark-a-4)",
    "variable": "var(--colors-crimson-dark-a-4)"
  },
  "colors.crimson.dark.a.5": {
    "value": "var(--colors-crimson-dark-a-5)",
    "variable": "var(--colors-crimson-dark-a-5)"
  },
  "colors.crimson.dark.a.6": {
    "value": "var(--colors-crimson-dark-a-6)",
    "variable": "var(--colors-crimson-dark-a-6)"
  },
  "colors.crimson.dark.a.7": {
    "value": "var(--colors-crimson-dark-a-7)",
    "variable": "var(--colors-crimson-dark-a-7)"
  },
  "colors.crimson.dark.a.8": {
    "value": "var(--colors-crimson-dark-a-8)",
    "variable": "var(--colors-crimson-dark-a-8)"
  },
  "colors.crimson.dark.a.9": {
    "value": "var(--colors-crimson-dark-a-9)",
    "variable": "var(--colors-crimson-dark-a-9)"
  },
  "colors.crimson.dark.a.10": {
    "value": "var(--colors-crimson-dark-a-10)",
    "variable": "var(--colors-crimson-dark-a-10)"
  },
  "colors.crimson.dark.a.11": {
    "value": "var(--colors-crimson-dark-a-11)",
    "variable": "var(--colors-crimson-dark-a-11)"
  },
  "colors.crimson.dark.a.12": {
    "value": "var(--colors-crimson-dark-a-12)",
    "variable": "var(--colors-crimson-dark-a-12)"
  },
  "colors.crimson.dark.p3.1": {
    "value": "color(display-p3 0.093 0.068 0.078)",
    "variable": "var(--colors-crimson-dark-p3-1)"
  },
  "colors.crimson.dark.p3.2": {
    "value": "color(display-p3 0.117 0.078 0.095)",
    "variable": "var(--colors-crimson-dark-p3-2)"
  },
  "colors.crimson.dark.p3.3": {
    "value": "color(display-p3 0.203 0.091 0.143)",
    "variable": "var(--colors-crimson-dark-p3-3)"
  },
  "colors.crimson.dark.p3.4": {
    "value": "color(display-p3 0.277 0.087 0.182)",
    "variable": "var(--colors-crimson-dark-p3-4)"
  },
  "colors.crimson.dark.p3.5": {
    "value": "color(display-p3 0.332 0.115 0.22)",
    "variable": "var(--colors-crimson-dark-p3-5)"
  },
  "colors.crimson.dark.p3.6": {
    "value": "color(display-p3 0.394 0.162 0.268)",
    "variable": "var(--colors-crimson-dark-p3-6)"
  },
  "colors.crimson.dark.p3.7": {
    "value": "color(display-p3 0.489 0.222 0.336)",
    "variable": "var(--colors-crimson-dark-p3-7)"
  },
  "colors.crimson.dark.p3.8": {
    "value": "color(display-p3 0.638 0.289 0.429)",
    "variable": "var(--colors-crimson-dark-p3-8)"
  },
  "colors.crimson.dark.p3.9": {
    "value": "color(display-p3 0.843 0.298 0.507)",
    "variable": "var(--colors-crimson-dark-p3-9)"
  },
  "colors.crimson.dark.p3.10": {
    "value": "color(display-p3 0.864 0.364 0.539)",
    "variable": "var(--colors-crimson-dark-p3-10)"
  },
  "colors.crimson.dark.p3.11": {
    "value": "color(display-p3 1 0.56 0.66)",
    "variable": "var(--colors-crimson-dark-p3-11)"
  },
  "colors.crimson.dark.p3.12": {
    "value": "color(display-p3 0.966 0.834 0.906)",
    "variable": "var(--colors-crimson-dark-p3-12)"
  },
  "colors.crimson.dark.p3.a.1": {
    "value": "color(display-p3 0.984 0.071 0.463 / 0.03)",
    "variable": "var(--colors-crimson-dark-p3-a-1)"
  },
  "colors.crimson.dark.p3.a.2": {
    "value": "color(display-p3 0.996 0.282 0.569 / 0.055)",
    "variable": "var(--colors-crimson-dark-p3-a-2)"
  },
  "colors.crimson.dark.p3.a.3": {
    "value": "color(display-p3 0.996 0.227 0.573 / 0.148)",
    "variable": "var(--colors-crimson-dark-p3-a-3)"
  },
  "colors.crimson.dark.p3.a.4": {
    "value": "color(display-p3 1 0.157 0.569 / 0.227)",
    "variable": "var(--colors-crimson-dark-p3-a-4)"
  },
  "colors.crimson.dark.p3.a.5": {
    "value": "color(display-p3 1 0.231 0.604 / 0.286)",
    "variable": "var(--colors-crimson-dark-p3-a-5)"
  },
  "colors.crimson.dark.p3.a.6": {
    "value": "color(display-p3 1 0.337 0.643 / 0.349)",
    "variable": "var(--colors-crimson-dark-p3-a-6)"
  },
  "colors.crimson.dark.p3.a.7": {
    "value": "color(display-p3 1 0.416 0.663 / 0.454)",
    "variable": "var(--colors-crimson-dark-p3-a-7)"
  },
  "colors.crimson.dark.p3.a.8": {
    "value": "color(display-p3 0.996 0.427 0.651 / 0.614)",
    "variable": "var(--colors-crimson-dark-p3-a-8)"
  },
  "colors.crimson.dark.p3.a.9": {
    "value": "color(display-p3 1 0.345 0.596 / 0.832)",
    "variable": "var(--colors-crimson-dark-p3-a-9)"
  },
  "colors.crimson.dark.p3.a.10": {
    "value": "color(display-p3 1 0.42 0.62 / 0.853)",
    "variable": "var(--colors-crimson-dark-p3-a-10)"
  },
  "colors.crimson.dark.p3.a.11": {
    "value": "color(display-p3 1 0.56 0.66)",
    "variable": "var(--colors-crimson-dark-p3-a-11)"
  },
  "colors.crimson.dark.p3.a.12": {
    "value": "color(display-p3 0.966 0.834 0.906)",
    "variable": "var(--colors-crimson-dark-p3-a-12)"
  },
  "colors.crimson.a.1": {
    "value": "var(--colors-crimson-a-1)",
    "variable": "var(--colors-crimson-a-1)"
  },
  "colors.crimson.a.2": {
    "value": "var(--colors-crimson-a-2)",
    "variable": "var(--colors-crimson-a-2)"
  },
  "colors.crimson.a.3": {
    "value": "var(--colors-crimson-a-3)",
    "variable": "var(--colors-crimson-a-3)"
  },
  "colors.crimson.a.4": {
    "value": "var(--colors-crimson-a-4)",
    "variable": "var(--colors-crimson-a-4)"
  },
  "colors.crimson.a.5": {
    "value": "var(--colors-crimson-a-5)",
    "variable": "var(--colors-crimson-a-5)"
  },
  "colors.crimson.a.6": {
    "value": "var(--colors-crimson-a-6)",
    "variable": "var(--colors-crimson-a-6)"
  },
  "colors.crimson.a.7": {
    "value": "var(--colors-crimson-a-7)",
    "variable": "var(--colors-crimson-a-7)"
  },
  "colors.crimson.a.8": {
    "value": "var(--colors-crimson-a-8)",
    "variable": "var(--colors-crimson-a-8)"
  },
  "colors.crimson.a.9": {
    "value": "var(--colors-crimson-a-9)",
    "variable": "var(--colors-crimson-a-9)"
  },
  "colors.crimson.a.10": {
    "value": "var(--colors-crimson-a-10)",
    "variable": "var(--colors-crimson-a-10)"
  },
  "colors.crimson.a.11": {
    "value": "var(--colors-crimson-a-11)",
    "variable": "var(--colors-crimson-a-11)"
  },
  "colors.crimson.a.12": {
    "value": "var(--colors-crimson-a-12)",
    "variable": "var(--colors-crimson-a-12)"
  },
  "colors.crimson.p3.1": {
    "value": "var(--colors-crimson-p3-1)",
    "variable": "var(--colors-crimson-p3-1)"
  },
  "colors.crimson.p3.2": {
    "value": "var(--colors-crimson-p3-2)",
    "variable": "var(--colors-crimson-p3-2)"
  },
  "colors.crimson.p3.3": {
    "value": "var(--colors-crimson-p3-3)",
    "variable": "var(--colors-crimson-p3-3)"
  },
  "colors.crimson.p3.4": {
    "value": "var(--colors-crimson-p3-4)",
    "variable": "var(--colors-crimson-p3-4)"
  },
  "colors.crimson.p3.5": {
    "value": "var(--colors-crimson-p3-5)",
    "variable": "var(--colors-crimson-p3-5)"
  },
  "colors.crimson.p3.6": {
    "value": "var(--colors-crimson-p3-6)",
    "variable": "var(--colors-crimson-p3-6)"
  },
  "colors.crimson.p3.7": {
    "value": "var(--colors-crimson-p3-7)",
    "variable": "var(--colors-crimson-p3-7)"
  },
  "colors.crimson.p3.8": {
    "value": "var(--colors-crimson-p3-8)",
    "variable": "var(--colors-crimson-p3-8)"
  },
  "colors.crimson.p3.9": {
    "value": "var(--colors-crimson-p3-9)",
    "variable": "var(--colors-crimson-p3-9)"
  },
  "colors.crimson.p3.10": {
    "value": "var(--colors-crimson-p3-10)",
    "variable": "var(--colors-crimson-p3-10)"
  },
  "colors.crimson.p3.11": {
    "value": "var(--colors-crimson-p3-11)",
    "variable": "var(--colors-crimson-p3-11)"
  },
  "colors.crimson.p3.12": {
    "value": "var(--colors-crimson-p3-12)",
    "variable": "var(--colors-crimson-p3-12)"
  },
  "colors.crimson.p3.a.1": {
    "value": "var(--colors-crimson-p3-a-1)",
    "variable": "var(--colors-crimson-p3-a-1)"
  },
  "colors.crimson.p3.a.2": {
    "value": "var(--colors-crimson-p3-a-2)",
    "variable": "var(--colors-crimson-p3-a-2)"
  },
  "colors.crimson.p3.a.3": {
    "value": "var(--colors-crimson-p3-a-3)",
    "variable": "var(--colors-crimson-p3-a-3)"
  },
  "colors.crimson.p3.a.4": {
    "value": "var(--colors-crimson-p3-a-4)",
    "variable": "var(--colors-crimson-p3-a-4)"
  },
  "colors.crimson.p3.a.5": {
    "value": "var(--colors-crimson-p3-a-5)",
    "variable": "var(--colors-crimson-p3-a-5)"
  },
  "colors.crimson.p3.a.6": {
    "value": "var(--colors-crimson-p3-a-6)",
    "variable": "var(--colors-crimson-p3-a-6)"
  },
  "colors.crimson.p3.a.7": {
    "value": "var(--colors-crimson-p3-a-7)",
    "variable": "var(--colors-crimson-p3-a-7)"
  },
  "colors.crimson.p3.a.8": {
    "value": "var(--colors-crimson-p3-a-8)",
    "variable": "var(--colors-crimson-p3-a-8)"
  },
  "colors.crimson.p3.a.9": {
    "value": "var(--colors-crimson-p3-a-9)",
    "variable": "var(--colors-crimson-p3-a-9)"
  },
  "colors.crimson.p3.a.10": {
    "value": "var(--colors-crimson-p3-a-10)",
    "variable": "var(--colors-crimson-p3-a-10)"
  },
  "colors.crimson.p3.a.11": {
    "value": "var(--colors-crimson-p3-a-11)",
    "variable": "var(--colors-crimson-p3-a-11)"
  },
  "colors.crimson.p3.a.12": {
    "value": "var(--colors-crimson-p3-a-12)",
    "variable": "var(--colors-crimson-p3-a-12)"
  },
  "colors.cyan.1": {
    "value": "var(--colors-cyan-1)",
    "variable": "var(--colors-cyan-1)"
  },
  "colors.cyan.2": {
    "value": "var(--colors-cyan-2)",
    "variable": "var(--colors-cyan-2)"
  },
  "colors.cyan.3": {
    "value": "var(--colors-cyan-3)",
    "variable": "var(--colors-cyan-3)"
  },
  "colors.cyan.4": {
    "value": "var(--colors-cyan-4)",
    "variable": "var(--colors-cyan-4)"
  },
  "colors.cyan.5": {
    "value": "var(--colors-cyan-5)",
    "variable": "var(--colors-cyan-5)"
  },
  "colors.cyan.6": {
    "value": "var(--colors-cyan-6)",
    "variable": "var(--colors-cyan-6)"
  },
  "colors.cyan.7": {
    "value": "var(--colors-cyan-7)",
    "variable": "var(--colors-cyan-7)"
  },
  "colors.cyan.8": {
    "value": "var(--colors-cyan-8)",
    "variable": "var(--colors-cyan-8)"
  },
  "colors.cyan.9": {
    "value": "var(--colors-cyan-9)",
    "variable": "var(--colors-cyan-9)"
  },
  "colors.cyan.10": {
    "value": "var(--colors-cyan-10)",
    "variable": "var(--colors-cyan-10)"
  },
  "colors.cyan.11": {
    "value": "var(--colors-cyan-11)",
    "variable": "var(--colors-cyan-11)"
  },
  "colors.cyan.12": {
    "value": "var(--colors-cyan-12)",
    "variable": "var(--colors-cyan-12)"
  },
  "colors.cyan.light.1": {
    "value": "var(--colors-cyan-light-1)",
    "variable": "var(--colors-cyan-light-1)"
  },
  "colors.cyan.light.2": {
    "value": "var(--colors-cyan-light-2)",
    "variable": "var(--colors-cyan-light-2)"
  },
  "colors.cyan.light.3": {
    "value": "var(--colors-cyan-light-3)",
    "variable": "var(--colors-cyan-light-3)"
  },
  "colors.cyan.light.4": {
    "value": "var(--colors-cyan-light-4)",
    "variable": "var(--colors-cyan-light-4)"
  },
  "colors.cyan.light.5": {
    "value": "var(--colors-cyan-light-5)",
    "variable": "var(--colors-cyan-light-5)"
  },
  "colors.cyan.light.6": {
    "value": "var(--colors-cyan-light-6)",
    "variable": "var(--colors-cyan-light-6)"
  },
  "colors.cyan.light.7": {
    "value": "var(--colors-cyan-light-7)",
    "variable": "var(--colors-cyan-light-7)"
  },
  "colors.cyan.light.8": {
    "value": "var(--colors-cyan-light-8)",
    "variable": "var(--colors-cyan-light-8)"
  },
  "colors.cyan.light.9": {
    "value": "var(--colors-cyan-light-9)",
    "variable": "var(--colors-cyan-light-9)"
  },
  "colors.cyan.light.10": {
    "value": "var(--colors-cyan-light-10)",
    "variable": "var(--colors-cyan-light-10)"
  },
  "colors.cyan.light.11": {
    "value": "var(--colors-cyan-light-11)",
    "variable": "var(--colors-cyan-light-11)"
  },
  "colors.cyan.light.12": {
    "value": "var(--colors-cyan-light-12)",
    "variable": "var(--colors-cyan-light-12)"
  },
  "colors.cyan.light.a.1": {
    "value": "var(--colors-cyan-light-a-1)",
    "variable": "var(--colors-cyan-light-a-1)"
  },
  "colors.cyan.light.a.2": {
    "value": "var(--colors-cyan-light-a-2)",
    "variable": "var(--colors-cyan-light-a-2)"
  },
  "colors.cyan.light.a.3": {
    "value": "var(--colors-cyan-light-a-3)",
    "variable": "var(--colors-cyan-light-a-3)"
  },
  "colors.cyan.light.a.4": {
    "value": "var(--colors-cyan-light-a-4)",
    "variable": "var(--colors-cyan-light-a-4)"
  },
  "colors.cyan.light.a.5": {
    "value": "var(--colors-cyan-light-a-5)",
    "variable": "var(--colors-cyan-light-a-5)"
  },
  "colors.cyan.light.a.6": {
    "value": "var(--colors-cyan-light-a-6)",
    "variable": "var(--colors-cyan-light-a-6)"
  },
  "colors.cyan.light.a.7": {
    "value": "var(--colors-cyan-light-a-7)",
    "variable": "var(--colors-cyan-light-a-7)"
  },
  "colors.cyan.light.a.8": {
    "value": "var(--colors-cyan-light-a-8)",
    "variable": "var(--colors-cyan-light-a-8)"
  },
  "colors.cyan.light.a.9": {
    "value": "var(--colors-cyan-light-a-9)",
    "variable": "var(--colors-cyan-light-a-9)"
  },
  "colors.cyan.light.a.10": {
    "value": "var(--colors-cyan-light-a-10)",
    "variable": "var(--colors-cyan-light-a-10)"
  },
  "colors.cyan.light.a.11": {
    "value": "var(--colors-cyan-light-a-11)",
    "variable": "var(--colors-cyan-light-a-11)"
  },
  "colors.cyan.light.a.12": {
    "value": "var(--colors-cyan-light-a-12)",
    "variable": "var(--colors-cyan-light-a-12)"
  },
  "colors.cyan.light.p3.1": {
    "value": "color(display-p3 0.982 0.992 0.996)",
    "variable": "var(--colors-cyan-light-p3-1)"
  },
  "colors.cyan.light.p3.2": {
    "value": "color(display-p3 0.955 0.981 0.984)",
    "variable": "var(--colors-cyan-light-p3-2)"
  },
  "colors.cyan.light.p3.3": {
    "value": "color(display-p3 0.888 0.965 0.975)",
    "variable": "var(--colors-cyan-light-p3-3)"
  },
  "colors.cyan.light.p3.4": {
    "value": "color(display-p3 0.821 0.941 0.959)",
    "variable": "var(--colors-cyan-light-p3-4)"
  },
  "colors.cyan.light.p3.5": {
    "value": "color(display-p3 0.751 0.907 0.935)",
    "variable": "var(--colors-cyan-light-p3-5)"
  },
  "colors.cyan.light.p3.6": {
    "value": "color(display-p3 0.671 0.862 0.9)",
    "variable": "var(--colors-cyan-light-p3-6)"
  },
  "colors.cyan.light.p3.7": {
    "value": "color(display-p3 0.564 0.8 0.854)",
    "variable": "var(--colors-cyan-light-p3-7)"
  },
  "colors.cyan.light.p3.8": {
    "value": "color(display-p3 0.388 0.715 0.798)",
    "variable": "var(--colors-cyan-light-p3-8)"
  },
  "colors.cyan.light.p3.9": {
    "value": "color(display-p3 0.282 0.627 0.765)",
    "variable": "var(--colors-cyan-light-p3-9)"
  },
  "colors.cyan.light.p3.10": {
    "value": "color(display-p3 0.264 0.583 0.71)",
    "variable": "var(--colors-cyan-light-p3-10)"
  },
  "colors.cyan.light.p3.11": {
    "value": "color(display-p3 0.08 0.48 0.63)",
    "variable": "var(--colors-cyan-light-p3-11)"
  },
  "colors.cyan.light.p3.12": {
    "value": "color(display-p3 0.108 0.232 0.277)",
    "variable": "var(--colors-cyan-light-p3-12)"
  },
  "colors.cyan.light.p3.a.1": {
    "value": "color(display-p3 0.02 0.608 0.804 / 0.02)",
    "variable": "var(--colors-cyan-light-p3-a-1)"
  },
  "colors.cyan.light.p3.a.2": {
    "value": "color(display-p3 0.02 0.557 0.647 / 0.044)",
    "variable": "var(--colors-cyan-light-p3-a-2)"
  },
  "colors.cyan.light.p3.a.3": {
    "value": "color(display-p3 0.004 0.694 0.796 / 0.114)",
    "variable": "var(--colors-cyan-light-p3-a-3)"
  },
  "colors.cyan.light.p3.a.4": {
    "value": "color(display-p3 0.004 0.678 0.784 / 0.181)",
    "variable": "var(--colors-cyan-light-p3-a-4)"
  },
  "colors.cyan.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.624 0.733 / 0.248)",
    "variable": "var(--colors-cyan-light-p3-a-5)"
  },
  "colors.cyan.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.584 0.706 / 0.33)",
    "variable": "var(--colors-cyan-light-p3-a-6)"
  },
  "colors.cyan.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.541 0.667 / 0.436)",
    "variable": "var(--colors-cyan-light-p3-a-7)"
  },
  "colors.cyan.light.p3.a.8": {
    "value": "color(display-p3 0 0.533 0.667 / 0.612)",
    "variable": "var(--colors-cyan-light-p3-a-8)"
  },
  "colors.cyan.light.p3.a.9": {
    "value": "color(display-p3 0 0.482 0.675 / 0.718)",
    "variable": "var(--colors-cyan-light-p3-a-9)"
  },
  "colors.cyan.light.p3.a.10": {
    "value": "color(display-p3 0 0.435 0.608 / 0.738)",
    "variable": "var(--colors-cyan-light-p3-a-10)"
  },
  "colors.cyan.light.p3.a.11": {
    "value": "color(display-p3 0.08 0.48 0.63)",
    "variable": "var(--colors-cyan-light-p3-a-11)"
  },
  "colors.cyan.light.p3.a.12": {
    "value": "color(display-p3 0.108 0.232 0.277)",
    "variable": "var(--colors-cyan-light-p3-a-12)"
  },
  "colors.cyan.dark.1": {
    "value": "var(--colors-cyan-dark-1)",
    "variable": "var(--colors-cyan-dark-1)"
  },
  "colors.cyan.dark.2": {
    "value": "var(--colors-cyan-dark-2)",
    "variable": "var(--colors-cyan-dark-2)"
  },
  "colors.cyan.dark.3": {
    "value": "var(--colors-cyan-dark-3)",
    "variable": "var(--colors-cyan-dark-3)"
  },
  "colors.cyan.dark.4": {
    "value": "var(--colors-cyan-dark-4)",
    "variable": "var(--colors-cyan-dark-4)"
  },
  "colors.cyan.dark.5": {
    "value": "var(--colors-cyan-dark-5)",
    "variable": "var(--colors-cyan-dark-5)"
  },
  "colors.cyan.dark.6": {
    "value": "var(--colors-cyan-dark-6)",
    "variable": "var(--colors-cyan-dark-6)"
  },
  "colors.cyan.dark.7": {
    "value": "var(--colors-cyan-dark-7)",
    "variable": "var(--colors-cyan-dark-7)"
  },
  "colors.cyan.dark.8": {
    "value": "var(--colors-cyan-dark-8)",
    "variable": "var(--colors-cyan-dark-8)"
  },
  "colors.cyan.dark.9": {
    "value": "var(--colors-cyan-dark-9)",
    "variable": "var(--colors-cyan-dark-9)"
  },
  "colors.cyan.dark.10": {
    "value": "var(--colors-cyan-dark-10)",
    "variable": "var(--colors-cyan-dark-10)"
  },
  "colors.cyan.dark.11": {
    "value": "var(--colors-cyan-dark-11)",
    "variable": "var(--colors-cyan-dark-11)"
  },
  "colors.cyan.dark.12": {
    "value": "var(--colors-cyan-dark-12)",
    "variable": "var(--colors-cyan-dark-12)"
  },
  "colors.cyan.dark.a.1": {
    "value": "var(--colors-cyan-dark-a-1)",
    "variable": "var(--colors-cyan-dark-a-1)"
  },
  "colors.cyan.dark.a.2": {
    "value": "var(--colors-cyan-dark-a-2)",
    "variable": "var(--colors-cyan-dark-a-2)"
  },
  "colors.cyan.dark.a.3": {
    "value": "var(--colors-cyan-dark-a-3)",
    "variable": "var(--colors-cyan-dark-a-3)"
  },
  "colors.cyan.dark.a.4": {
    "value": "var(--colors-cyan-dark-a-4)",
    "variable": "var(--colors-cyan-dark-a-4)"
  },
  "colors.cyan.dark.a.5": {
    "value": "var(--colors-cyan-dark-a-5)",
    "variable": "var(--colors-cyan-dark-a-5)"
  },
  "colors.cyan.dark.a.6": {
    "value": "var(--colors-cyan-dark-a-6)",
    "variable": "var(--colors-cyan-dark-a-6)"
  },
  "colors.cyan.dark.a.7": {
    "value": "var(--colors-cyan-dark-a-7)",
    "variable": "var(--colors-cyan-dark-a-7)"
  },
  "colors.cyan.dark.a.8": {
    "value": "var(--colors-cyan-dark-a-8)",
    "variable": "var(--colors-cyan-dark-a-8)"
  },
  "colors.cyan.dark.a.9": {
    "value": "var(--colors-cyan-dark-a-9)",
    "variable": "var(--colors-cyan-dark-a-9)"
  },
  "colors.cyan.dark.a.10": {
    "value": "var(--colors-cyan-dark-a-10)",
    "variable": "var(--colors-cyan-dark-a-10)"
  },
  "colors.cyan.dark.a.11": {
    "value": "var(--colors-cyan-dark-a-11)",
    "variable": "var(--colors-cyan-dark-a-11)"
  },
  "colors.cyan.dark.a.12": {
    "value": "var(--colors-cyan-dark-a-12)",
    "variable": "var(--colors-cyan-dark-a-12)"
  },
  "colors.cyan.dark.p3.1": {
    "value": "color(display-p3 0.053 0.085 0.098)",
    "variable": "var(--colors-cyan-dark-p3-1)"
  },
  "colors.cyan.dark.p3.2": {
    "value": "color(display-p3 0.072 0.105 0.122)",
    "variable": "var(--colors-cyan-dark-p3-2)"
  },
  "colors.cyan.dark.p3.3": {
    "value": "color(display-p3 0.073 0.168 0.209)",
    "variable": "var(--colors-cyan-dark-p3-3)"
  },
  "colors.cyan.dark.p3.4": {
    "value": "color(display-p3 0.063 0.216 0.277)",
    "variable": "var(--colors-cyan-dark-p3-4)"
  },
  "colors.cyan.dark.p3.5": {
    "value": "color(display-p3 0.091 0.267 0.336)",
    "variable": "var(--colors-cyan-dark-p3-5)"
  },
  "colors.cyan.dark.p3.6": {
    "value": "color(display-p3 0.137 0.324 0.4)",
    "variable": "var(--colors-cyan-dark-p3-6)"
  },
  "colors.cyan.dark.p3.7": {
    "value": "color(display-p3 0.186 0.398 0.484)",
    "variable": "var(--colors-cyan-dark-p3-7)"
  },
  "colors.cyan.dark.p3.8": {
    "value": "color(display-p3 0.23 0.496 0.6)",
    "variable": "var(--colors-cyan-dark-p3-8)"
  },
  "colors.cyan.dark.p3.9": {
    "value": "color(display-p3 0.282 0.627 0.765)",
    "variable": "var(--colors-cyan-dark-p3-9)"
  },
  "colors.cyan.dark.p3.10": {
    "value": "color(display-p3 0.331 0.675 0.801)",
    "variable": "var(--colors-cyan-dark-p3-10)"
  },
  "colors.cyan.dark.p3.11": {
    "value": "color(display-p3 0.446 0.79 0.887)",
    "variable": "var(--colors-cyan-dark-p3-11)"
  },
  "colors.cyan.dark.p3.12": {
    "value": "color(display-p3 0.757 0.919 0.962)",
    "variable": "var(--colors-cyan-dark-p3-12)"
  },
  "colors.cyan.dark.p3.a.1": {
    "value": "color(display-p3 0 0.647 0.992 / 0.034)",
    "variable": "var(--colors-cyan-dark-p3-a-1)"
  },
  "colors.cyan.dark.p3.a.2": {
    "value": "color(display-p3 0.133 0.733 1 / 0.059)",
    "variable": "var(--colors-cyan-dark-p3-a-2)"
  },
  "colors.cyan.dark.p3.a.3": {
    "value": "color(display-p3 0.122 0.741 0.996 / 0.152)",
    "variable": "var(--colors-cyan-dark-p3-a-3)"
  },
  "colors.cyan.dark.p3.a.4": {
    "value": "color(display-p3 0.051 0.725 1 / 0.227)",
    "variable": "var(--colors-cyan-dark-p3-a-4)"
  },
  "colors.cyan.dark.p3.a.5": {
    "value": "color(display-p3 0.149 0.757 1 / 0.29)",
    "variable": "var(--colors-cyan-dark-p3-a-5)"
  },
  "colors.cyan.dark.p3.a.6": {
    "value": "color(display-p3 0.267 0.792 1 / 0.358)",
    "variable": "var(--colors-cyan-dark-p3-a-6)"
  },
  "colors.cyan.dark.p3.a.7": {
    "value": "color(display-p3 0.333 0.808 1 / 0.446)",
    "variable": "var(--colors-cyan-dark-p3-a-7)"
  },
  "colors.cyan.dark.p3.a.8": {
    "value": "color(display-p3 0.357 0.816 1 / 0.572)",
    "variable": "var(--colors-cyan-dark-p3-a-8)"
  },
  "colors.cyan.dark.p3.a.9": {
    "value": "color(display-p3 0.357 0.82 1 / 0.748)",
    "variable": "var(--colors-cyan-dark-p3-a-9)"
  },
  "colors.cyan.dark.p3.a.10": {
    "value": "color(display-p3 0.4 0.839 1 / 0.786)",
    "variable": "var(--colors-cyan-dark-p3-a-10)"
  },
  "colors.cyan.dark.p3.a.11": {
    "value": "color(display-p3 0.446 0.79 0.887)",
    "variable": "var(--colors-cyan-dark-p3-a-11)"
  },
  "colors.cyan.dark.p3.a.12": {
    "value": "color(display-p3 0.757 0.919 0.962)",
    "variable": "var(--colors-cyan-dark-p3-a-12)"
  },
  "colors.cyan.a.1": {
    "value": "var(--colors-cyan-a-1)",
    "variable": "var(--colors-cyan-a-1)"
  },
  "colors.cyan.a.2": {
    "value": "var(--colors-cyan-a-2)",
    "variable": "var(--colors-cyan-a-2)"
  },
  "colors.cyan.a.3": {
    "value": "var(--colors-cyan-a-3)",
    "variable": "var(--colors-cyan-a-3)"
  },
  "colors.cyan.a.4": {
    "value": "var(--colors-cyan-a-4)",
    "variable": "var(--colors-cyan-a-4)"
  },
  "colors.cyan.a.5": {
    "value": "var(--colors-cyan-a-5)",
    "variable": "var(--colors-cyan-a-5)"
  },
  "colors.cyan.a.6": {
    "value": "var(--colors-cyan-a-6)",
    "variable": "var(--colors-cyan-a-6)"
  },
  "colors.cyan.a.7": {
    "value": "var(--colors-cyan-a-7)",
    "variable": "var(--colors-cyan-a-7)"
  },
  "colors.cyan.a.8": {
    "value": "var(--colors-cyan-a-8)",
    "variable": "var(--colors-cyan-a-8)"
  },
  "colors.cyan.a.9": {
    "value": "var(--colors-cyan-a-9)",
    "variable": "var(--colors-cyan-a-9)"
  },
  "colors.cyan.a.10": {
    "value": "var(--colors-cyan-a-10)",
    "variable": "var(--colors-cyan-a-10)"
  },
  "colors.cyan.a.11": {
    "value": "var(--colors-cyan-a-11)",
    "variable": "var(--colors-cyan-a-11)"
  },
  "colors.cyan.a.12": {
    "value": "var(--colors-cyan-a-12)",
    "variable": "var(--colors-cyan-a-12)"
  },
  "colors.cyan.p3.1": {
    "value": "var(--colors-cyan-p3-1)",
    "variable": "var(--colors-cyan-p3-1)"
  },
  "colors.cyan.p3.2": {
    "value": "var(--colors-cyan-p3-2)",
    "variable": "var(--colors-cyan-p3-2)"
  },
  "colors.cyan.p3.3": {
    "value": "var(--colors-cyan-p3-3)",
    "variable": "var(--colors-cyan-p3-3)"
  },
  "colors.cyan.p3.4": {
    "value": "var(--colors-cyan-p3-4)",
    "variable": "var(--colors-cyan-p3-4)"
  },
  "colors.cyan.p3.5": {
    "value": "var(--colors-cyan-p3-5)",
    "variable": "var(--colors-cyan-p3-5)"
  },
  "colors.cyan.p3.6": {
    "value": "var(--colors-cyan-p3-6)",
    "variable": "var(--colors-cyan-p3-6)"
  },
  "colors.cyan.p3.7": {
    "value": "var(--colors-cyan-p3-7)",
    "variable": "var(--colors-cyan-p3-7)"
  },
  "colors.cyan.p3.8": {
    "value": "var(--colors-cyan-p3-8)",
    "variable": "var(--colors-cyan-p3-8)"
  },
  "colors.cyan.p3.9": {
    "value": "var(--colors-cyan-p3-9)",
    "variable": "var(--colors-cyan-p3-9)"
  },
  "colors.cyan.p3.10": {
    "value": "var(--colors-cyan-p3-10)",
    "variable": "var(--colors-cyan-p3-10)"
  },
  "colors.cyan.p3.11": {
    "value": "var(--colors-cyan-p3-11)",
    "variable": "var(--colors-cyan-p3-11)"
  },
  "colors.cyan.p3.12": {
    "value": "var(--colors-cyan-p3-12)",
    "variable": "var(--colors-cyan-p3-12)"
  },
  "colors.cyan.p3.a.1": {
    "value": "var(--colors-cyan-p3-a-1)",
    "variable": "var(--colors-cyan-p3-a-1)"
  },
  "colors.cyan.p3.a.2": {
    "value": "var(--colors-cyan-p3-a-2)",
    "variable": "var(--colors-cyan-p3-a-2)"
  },
  "colors.cyan.p3.a.3": {
    "value": "var(--colors-cyan-p3-a-3)",
    "variable": "var(--colors-cyan-p3-a-3)"
  },
  "colors.cyan.p3.a.4": {
    "value": "var(--colors-cyan-p3-a-4)",
    "variable": "var(--colors-cyan-p3-a-4)"
  },
  "colors.cyan.p3.a.5": {
    "value": "var(--colors-cyan-p3-a-5)",
    "variable": "var(--colors-cyan-p3-a-5)"
  },
  "colors.cyan.p3.a.6": {
    "value": "var(--colors-cyan-p3-a-6)",
    "variable": "var(--colors-cyan-p3-a-6)"
  },
  "colors.cyan.p3.a.7": {
    "value": "var(--colors-cyan-p3-a-7)",
    "variable": "var(--colors-cyan-p3-a-7)"
  },
  "colors.cyan.p3.a.8": {
    "value": "var(--colors-cyan-p3-a-8)",
    "variable": "var(--colors-cyan-p3-a-8)"
  },
  "colors.cyan.p3.a.9": {
    "value": "var(--colors-cyan-p3-a-9)",
    "variable": "var(--colors-cyan-p3-a-9)"
  },
  "colors.cyan.p3.a.10": {
    "value": "var(--colors-cyan-p3-a-10)",
    "variable": "var(--colors-cyan-p3-a-10)"
  },
  "colors.cyan.p3.a.11": {
    "value": "var(--colors-cyan-p3-a-11)",
    "variable": "var(--colors-cyan-p3-a-11)"
  },
  "colors.cyan.p3.a.12": {
    "value": "var(--colors-cyan-p3-a-12)",
    "variable": "var(--colors-cyan-p3-a-12)"
  },
  "colors.gold.1": {
    "value": "var(--colors-gold-1)",
    "variable": "var(--colors-gold-1)"
  },
  "colors.gold.2": {
    "value": "var(--colors-gold-2)",
    "variable": "var(--colors-gold-2)"
  },
  "colors.gold.3": {
    "value": "var(--colors-gold-3)",
    "variable": "var(--colors-gold-3)"
  },
  "colors.gold.4": {
    "value": "var(--colors-gold-4)",
    "variable": "var(--colors-gold-4)"
  },
  "colors.gold.5": {
    "value": "var(--colors-gold-5)",
    "variable": "var(--colors-gold-5)"
  },
  "colors.gold.6": {
    "value": "var(--colors-gold-6)",
    "variable": "var(--colors-gold-6)"
  },
  "colors.gold.7": {
    "value": "var(--colors-gold-7)",
    "variable": "var(--colors-gold-7)"
  },
  "colors.gold.8": {
    "value": "var(--colors-gold-8)",
    "variable": "var(--colors-gold-8)"
  },
  "colors.gold.9": {
    "value": "var(--colors-gold-9)",
    "variable": "var(--colors-gold-9)"
  },
  "colors.gold.10": {
    "value": "var(--colors-gold-10)",
    "variable": "var(--colors-gold-10)"
  },
  "colors.gold.11": {
    "value": "var(--colors-gold-11)",
    "variable": "var(--colors-gold-11)"
  },
  "colors.gold.12": {
    "value": "var(--colors-gold-12)",
    "variable": "var(--colors-gold-12)"
  },
  "colors.gold.light.1": {
    "value": "var(--colors-gold-light-1)",
    "variable": "var(--colors-gold-light-1)"
  },
  "colors.gold.light.2": {
    "value": "var(--colors-gold-light-2)",
    "variable": "var(--colors-gold-light-2)"
  },
  "colors.gold.light.3": {
    "value": "var(--colors-gold-light-3)",
    "variable": "var(--colors-gold-light-3)"
  },
  "colors.gold.light.4": {
    "value": "var(--colors-gold-light-4)",
    "variable": "var(--colors-gold-light-4)"
  },
  "colors.gold.light.5": {
    "value": "var(--colors-gold-light-5)",
    "variable": "var(--colors-gold-light-5)"
  },
  "colors.gold.light.6": {
    "value": "var(--colors-gold-light-6)",
    "variable": "var(--colors-gold-light-6)"
  },
  "colors.gold.light.7": {
    "value": "var(--colors-gold-light-7)",
    "variable": "var(--colors-gold-light-7)"
  },
  "colors.gold.light.8": {
    "value": "var(--colors-gold-light-8)",
    "variable": "var(--colors-gold-light-8)"
  },
  "colors.gold.light.9": {
    "value": "var(--colors-gold-light-9)",
    "variable": "var(--colors-gold-light-9)"
  },
  "colors.gold.light.10": {
    "value": "var(--colors-gold-light-10)",
    "variable": "var(--colors-gold-light-10)"
  },
  "colors.gold.light.11": {
    "value": "var(--colors-gold-light-11)",
    "variable": "var(--colors-gold-light-11)"
  },
  "colors.gold.light.12": {
    "value": "var(--colors-gold-light-12)",
    "variable": "var(--colors-gold-light-12)"
  },
  "colors.gold.light.a.1": {
    "value": "var(--colors-gold-light-a-1)",
    "variable": "var(--colors-gold-light-a-1)"
  },
  "colors.gold.light.a.2": {
    "value": "var(--colors-gold-light-a-2)",
    "variable": "var(--colors-gold-light-a-2)"
  },
  "colors.gold.light.a.3": {
    "value": "var(--colors-gold-light-a-3)",
    "variable": "var(--colors-gold-light-a-3)"
  },
  "colors.gold.light.a.4": {
    "value": "var(--colors-gold-light-a-4)",
    "variable": "var(--colors-gold-light-a-4)"
  },
  "colors.gold.light.a.5": {
    "value": "var(--colors-gold-light-a-5)",
    "variable": "var(--colors-gold-light-a-5)"
  },
  "colors.gold.light.a.6": {
    "value": "var(--colors-gold-light-a-6)",
    "variable": "var(--colors-gold-light-a-6)"
  },
  "colors.gold.light.a.7": {
    "value": "var(--colors-gold-light-a-7)",
    "variable": "var(--colors-gold-light-a-7)"
  },
  "colors.gold.light.a.8": {
    "value": "var(--colors-gold-light-a-8)",
    "variable": "var(--colors-gold-light-a-8)"
  },
  "colors.gold.light.a.9": {
    "value": "var(--colors-gold-light-a-9)",
    "variable": "var(--colors-gold-light-a-9)"
  },
  "colors.gold.light.a.10": {
    "value": "var(--colors-gold-light-a-10)",
    "variable": "var(--colors-gold-light-a-10)"
  },
  "colors.gold.light.a.11": {
    "value": "var(--colors-gold-light-a-11)",
    "variable": "var(--colors-gold-light-a-11)"
  },
  "colors.gold.light.a.12": {
    "value": "var(--colors-gold-light-a-12)",
    "variable": "var(--colors-gold-light-a-12)"
  },
  "colors.gold.light.p3.1": {
    "value": "color(display-p3 0.992 0.992 0.989)",
    "variable": "var(--colors-gold-light-p3-1)"
  },
  "colors.gold.light.p3.2": {
    "value": "color(display-p3 0.98 0.976 0.953)",
    "variable": "var(--colors-gold-light-p3-2)"
  },
  "colors.gold.light.p3.3": {
    "value": "color(display-p3 0.947 0.94 0.909)",
    "variable": "var(--colors-gold-light-p3-3)"
  },
  "colors.gold.light.p3.4": {
    "value": "color(display-p3 0.914 0.904 0.865)",
    "variable": "var(--colors-gold-light-p3-4)"
  },
  "colors.gold.light.p3.5": {
    "value": "color(display-p3 0.88 0.865 0.816)",
    "variable": "var(--colors-gold-light-p3-5)"
  },
  "colors.gold.light.p3.6": {
    "value": "color(display-p3 0.84 0.818 0.756)",
    "variable": "var(--colors-gold-light-p3-6)"
  },
  "colors.gold.light.p3.7": {
    "value": "color(display-p3 0.788 0.753 0.677)",
    "variable": "var(--colors-gold-light-p3-7)"
  },
  "colors.gold.light.p3.8": {
    "value": "color(display-p3 0.715 0.66 0.565)",
    "variable": "var(--colors-gold-light-p3-8)"
  },
  "colors.gold.light.p3.9": {
    "value": "color(display-p3 0.579 0.517 0.41)",
    "variable": "var(--colors-gold-light-p3-9)"
  },
  "colors.gold.light.p3.10": {
    "value": "color(display-p3 0.538 0.479 0.38)",
    "variable": "var(--colors-gold-light-p3-10)"
  },
  "colors.gold.light.p3.11": {
    "value": "color(display-p3 0.433 0.386 0.305)",
    "variable": "var(--colors-gold-light-p3-11)"
  },
  "colors.gold.light.p3.12": {
    "value": "color(display-p3 0.227 0.209 0.173)",
    "variable": "var(--colors-gold-light-p3-12)"
  },
  "colors.gold.light.p3.a.1": {
    "value": "color(display-p3 0.349 0.349 0.024 / 0.012)",
    "variable": "var(--colors-gold-light-p3-a-1)"
  },
  "colors.gold.light.p3.a.2": {
    "value": "color(display-p3 0.592 0.514 0.024 / 0.048)",
    "variable": "var(--colors-gold-light-p3-a-2)"
  },
  "colors.gold.light.p3.a.3": {
    "value": "color(display-p3 0.4 0.357 0.012 / 0.091)",
    "variable": "var(--colors-gold-light-p3-a-3)"
  },
  "colors.gold.light.p3.a.4": {
    "value": "color(display-p3 0.357 0.298 0.008 / 0.134)",
    "variable": "var(--colors-gold-light-p3-a-4)"
  },
  "colors.gold.light.p3.a.5": {
    "value": "color(display-p3 0.345 0.282 0.004 / 0.185)",
    "variable": "var(--colors-gold-light-p3-a-5)"
  },
  "colors.gold.light.p3.a.6": {
    "value": "color(display-p3 0.341 0.263 0.004 / 0.244)",
    "variable": "var(--colors-gold-light-p3-a-6)"
  },
  "colors.gold.light.p3.a.7": {
    "value": "color(display-p3 0.345 0.235 0.004 / 0.322)",
    "variable": "var(--colors-gold-light-p3-a-7)"
  },
  "colors.gold.light.p3.a.8": {
    "value": "color(display-p3 0.345 0.22 0.004 / 0.436)",
    "variable": "var(--colors-gold-light-p3-a-8)"
  },
  "colors.gold.light.p3.a.9": {
    "value": "color(display-p3 0.286 0.18 0 / 0.589)",
    "variable": "var(--colors-gold-light-p3-a-9)"
  },
  "colors.gold.light.p3.a.10": {
    "value": "color(display-p3 0.255 0.161 0 / 0.62)",
    "variable": "var(--colors-gold-light-p3-a-10)"
  },
  "colors.gold.light.p3.a.11": {
    "value": "color(display-p3 0.433 0.386 0.305)",
    "variable": "var(--colors-gold-light-p3-a-11)"
  },
  "colors.gold.light.p3.a.12": {
    "value": "color(display-p3 0.227 0.209 0.173)",
    "variable": "var(--colors-gold-light-p3-a-12)"
  },
  "colors.gold.dark.1": {
    "value": "var(--colors-gold-dark-1)",
    "variable": "var(--colors-gold-dark-1)"
  },
  "colors.gold.dark.2": {
    "value": "var(--colors-gold-dark-2)",
    "variable": "var(--colors-gold-dark-2)"
  },
  "colors.gold.dark.3": {
    "value": "var(--colors-gold-dark-3)",
    "variable": "var(--colors-gold-dark-3)"
  },
  "colors.gold.dark.4": {
    "value": "var(--colors-gold-dark-4)",
    "variable": "var(--colors-gold-dark-4)"
  },
  "colors.gold.dark.5": {
    "value": "var(--colors-gold-dark-5)",
    "variable": "var(--colors-gold-dark-5)"
  },
  "colors.gold.dark.6": {
    "value": "var(--colors-gold-dark-6)",
    "variable": "var(--colors-gold-dark-6)"
  },
  "colors.gold.dark.7": {
    "value": "var(--colors-gold-dark-7)",
    "variable": "var(--colors-gold-dark-7)"
  },
  "colors.gold.dark.8": {
    "value": "var(--colors-gold-dark-8)",
    "variable": "var(--colors-gold-dark-8)"
  },
  "colors.gold.dark.9": {
    "value": "var(--colors-gold-dark-9)",
    "variable": "var(--colors-gold-dark-9)"
  },
  "colors.gold.dark.10": {
    "value": "var(--colors-gold-dark-10)",
    "variable": "var(--colors-gold-dark-10)"
  },
  "colors.gold.dark.11": {
    "value": "var(--colors-gold-dark-11)",
    "variable": "var(--colors-gold-dark-11)"
  },
  "colors.gold.dark.12": {
    "value": "var(--colors-gold-dark-12)",
    "variable": "var(--colors-gold-dark-12)"
  },
  "colors.gold.dark.a.1": {
    "value": "var(--colors-gold-dark-a-1)",
    "variable": "var(--colors-gold-dark-a-1)"
  },
  "colors.gold.dark.a.2": {
    "value": "var(--colors-gold-dark-a-2)",
    "variable": "var(--colors-gold-dark-a-2)"
  },
  "colors.gold.dark.a.3": {
    "value": "var(--colors-gold-dark-a-3)",
    "variable": "var(--colors-gold-dark-a-3)"
  },
  "colors.gold.dark.a.4": {
    "value": "var(--colors-gold-dark-a-4)",
    "variable": "var(--colors-gold-dark-a-4)"
  },
  "colors.gold.dark.a.5": {
    "value": "var(--colors-gold-dark-a-5)",
    "variable": "var(--colors-gold-dark-a-5)"
  },
  "colors.gold.dark.a.6": {
    "value": "var(--colors-gold-dark-a-6)",
    "variable": "var(--colors-gold-dark-a-6)"
  },
  "colors.gold.dark.a.7": {
    "value": "var(--colors-gold-dark-a-7)",
    "variable": "var(--colors-gold-dark-a-7)"
  },
  "colors.gold.dark.a.8": {
    "value": "var(--colors-gold-dark-a-8)",
    "variable": "var(--colors-gold-dark-a-8)"
  },
  "colors.gold.dark.a.9": {
    "value": "var(--colors-gold-dark-a-9)",
    "variable": "var(--colors-gold-dark-a-9)"
  },
  "colors.gold.dark.a.10": {
    "value": "var(--colors-gold-dark-a-10)",
    "variable": "var(--colors-gold-dark-a-10)"
  },
  "colors.gold.dark.a.11": {
    "value": "var(--colors-gold-dark-a-11)",
    "variable": "var(--colors-gold-dark-a-11)"
  },
  "colors.gold.dark.a.12": {
    "value": "var(--colors-gold-dark-a-12)",
    "variable": "var(--colors-gold-dark-a-12)"
  },
  "colors.gold.dark.p3.1": {
    "value": "color(display-p3 0.071 0.071 0.067)",
    "variable": "var(--colors-gold-dark-p3-1)"
  },
  "colors.gold.dark.p3.2": {
    "value": "color(display-p3 0.104 0.101 0.09)",
    "variable": "var(--colors-gold-dark-p3-2)"
  },
  "colors.gold.dark.p3.3": {
    "value": "color(display-p3 0.141 0.136 0.122)",
    "variable": "var(--colors-gold-dark-p3-3)"
  },
  "colors.gold.dark.p3.4": {
    "value": "color(display-p3 0.177 0.17 0.152)",
    "variable": "var(--colors-gold-dark-p3-4)"
  },
  "colors.gold.dark.p3.5": {
    "value": "color(display-p3 0.217 0.207 0.185)",
    "variable": "var(--colors-gold-dark-p3-5)"
  },
  "colors.gold.dark.p3.6": {
    "value": "color(display-p3 0.265 0.252 0.225)",
    "variable": "var(--colors-gold-dark-p3-6)"
  },
  "colors.gold.dark.p3.7": {
    "value": "color(display-p3 0.327 0.31 0.277)",
    "variable": "var(--colors-gold-dark-p3-7)"
  },
  "colors.gold.dark.p3.8": {
    "value": "color(display-p3 0.407 0.384 0.342)",
    "variable": "var(--colors-gold-dark-p3-8)"
  },
  "colors.gold.dark.p3.9": {
    "value": "color(display-p3 0.579 0.517 0.41)",
    "variable": "var(--colors-gold-dark-p3-9)"
  },
  "colors.gold.dark.p3.10": {
    "value": "color(display-p3 0.628 0.566 0.463)",
    "variable": "var(--colors-gold-dark-p3-10)"
  },
  "colors.gold.dark.p3.11": {
    "value": "color(display-p3 0.784 0.728 0.635)",
    "variable": "var(--colors-gold-dark-p3-11)"
  },
  "colors.gold.dark.p3.12": {
    "value": "color(display-p3 0.906 0.887 0.855)",
    "variable": "var(--colors-gold-dark-p3-12)"
  },
  "colors.gold.dark.p3.a.1": {
    "value": "color(display-p3 0.855 0.855 0.071 / 0.005)",
    "variable": "var(--colors-gold-dark-p3-a-1)"
  },
  "colors.gold.dark.p3.a.2": {
    "value": "color(display-p3 0.98 0.89 0.616 / 0.043)",
    "variable": "var(--colors-gold-dark-p3-a-2)"
  },
  "colors.gold.dark.p3.a.3": {
    "value": "color(display-p3 1 0.949 0.753 / 0.08)",
    "variable": "var(--colors-gold-dark-p3-a-3)"
  },
  "colors.gold.dark.p3.a.4": {
    "value": "color(display-p3 1 0.933 0.8 / 0.118)",
    "variable": "var(--colors-gold-dark-p3-a-4)"
  },
  "colors.gold.dark.p3.a.5": {
    "value": "color(display-p3 1 0.949 0.804 / 0.16)",
    "variable": "var(--colors-gold-dark-p3-a-5)"
  },
  "colors.gold.dark.p3.a.6": {
    "value": "color(display-p3 1 0.925 0.8 / 0.215)",
    "variable": "var(--colors-gold-dark-p3-a-6)"
  },
  "colors.gold.dark.p3.a.7": {
    "value": "color(display-p3 1 0.945 0.831 / 0.278)",
    "variable": "var(--colors-gold-dark-p3-a-7)"
  },
  "colors.gold.dark.p3.a.8": {
    "value": "color(display-p3 1 0.937 0.82 / 0.366)",
    "variable": "var(--colors-gold-dark-p3-a-8)"
  },
  "colors.gold.dark.p3.a.9": {
    "value": "color(display-p3 0.996 0.882 0.69 / 0.551)",
    "variable": "var(--colors-gold-dark-p3-a-9)"
  },
  "colors.gold.dark.p3.a.10": {
    "value": "color(display-p3 1 0.894 0.725 / 0.601)",
    "variable": "var(--colors-gold-dark-p3-a-10)"
  },
  "colors.gold.dark.p3.a.11": {
    "value": "color(display-p3 0.784 0.728 0.635)",
    "variable": "var(--colors-gold-dark-p3-a-11)"
  },
  "colors.gold.dark.p3.a.12": {
    "value": "color(display-p3 0.906 0.887 0.855)",
    "variable": "var(--colors-gold-dark-p3-a-12)"
  },
  "colors.gold.a.1": {
    "value": "var(--colors-gold-a-1)",
    "variable": "var(--colors-gold-a-1)"
  },
  "colors.gold.a.2": {
    "value": "var(--colors-gold-a-2)",
    "variable": "var(--colors-gold-a-2)"
  },
  "colors.gold.a.3": {
    "value": "var(--colors-gold-a-3)",
    "variable": "var(--colors-gold-a-3)"
  },
  "colors.gold.a.4": {
    "value": "var(--colors-gold-a-4)",
    "variable": "var(--colors-gold-a-4)"
  },
  "colors.gold.a.5": {
    "value": "var(--colors-gold-a-5)",
    "variable": "var(--colors-gold-a-5)"
  },
  "colors.gold.a.6": {
    "value": "var(--colors-gold-a-6)",
    "variable": "var(--colors-gold-a-6)"
  },
  "colors.gold.a.7": {
    "value": "var(--colors-gold-a-7)",
    "variable": "var(--colors-gold-a-7)"
  },
  "colors.gold.a.8": {
    "value": "var(--colors-gold-a-8)",
    "variable": "var(--colors-gold-a-8)"
  },
  "colors.gold.a.9": {
    "value": "var(--colors-gold-a-9)",
    "variable": "var(--colors-gold-a-9)"
  },
  "colors.gold.a.10": {
    "value": "var(--colors-gold-a-10)",
    "variable": "var(--colors-gold-a-10)"
  },
  "colors.gold.a.11": {
    "value": "var(--colors-gold-a-11)",
    "variable": "var(--colors-gold-a-11)"
  },
  "colors.gold.a.12": {
    "value": "var(--colors-gold-a-12)",
    "variable": "var(--colors-gold-a-12)"
  },
  "colors.gold.p3.1": {
    "value": "var(--colors-gold-p3-1)",
    "variable": "var(--colors-gold-p3-1)"
  },
  "colors.gold.p3.2": {
    "value": "var(--colors-gold-p3-2)",
    "variable": "var(--colors-gold-p3-2)"
  },
  "colors.gold.p3.3": {
    "value": "var(--colors-gold-p3-3)",
    "variable": "var(--colors-gold-p3-3)"
  },
  "colors.gold.p3.4": {
    "value": "var(--colors-gold-p3-4)",
    "variable": "var(--colors-gold-p3-4)"
  },
  "colors.gold.p3.5": {
    "value": "var(--colors-gold-p3-5)",
    "variable": "var(--colors-gold-p3-5)"
  },
  "colors.gold.p3.6": {
    "value": "var(--colors-gold-p3-6)",
    "variable": "var(--colors-gold-p3-6)"
  },
  "colors.gold.p3.7": {
    "value": "var(--colors-gold-p3-7)",
    "variable": "var(--colors-gold-p3-7)"
  },
  "colors.gold.p3.8": {
    "value": "var(--colors-gold-p3-8)",
    "variable": "var(--colors-gold-p3-8)"
  },
  "colors.gold.p3.9": {
    "value": "var(--colors-gold-p3-9)",
    "variable": "var(--colors-gold-p3-9)"
  },
  "colors.gold.p3.10": {
    "value": "var(--colors-gold-p3-10)",
    "variable": "var(--colors-gold-p3-10)"
  },
  "colors.gold.p3.11": {
    "value": "var(--colors-gold-p3-11)",
    "variable": "var(--colors-gold-p3-11)"
  },
  "colors.gold.p3.12": {
    "value": "var(--colors-gold-p3-12)",
    "variable": "var(--colors-gold-p3-12)"
  },
  "colors.gold.p3.a.1": {
    "value": "var(--colors-gold-p3-a-1)",
    "variable": "var(--colors-gold-p3-a-1)"
  },
  "colors.gold.p3.a.2": {
    "value": "var(--colors-gold-p3-a-2)",
    "variable": "var(--colors-gold-p3-a-2)"
  },
  "colors.gold.p3.a.3": {
    "value": "var(--colors-gold-p3-a-3)",
    "variable": "var(--colors-gold-p3-a-3)"
  },
  "colors.gold.p3.a.4": {
    "value": "var(--colors-gold-p3-a-4)",
    "variable": "var(--colors-gold-p3-a-4)"
  },
  "colors.gold.p3.a.5": {
    "value": "var(--colors-gold-p3-a-5)",
    "variable": "var(--colors-gold-p3-a-5)"
  },
  "colors.gold.p3.a.6": {
    "value": "var(--colors-gold-p3-a-6)",
    "variable": "var(--colors-gold-p3-a-6)"
  },
  "colors.gold.p3.a.7": {
    "value": "var(--colors-gold-p3-a-7)",
    "variable": "var(--colors-gold-p3-a-7)"
  },
  "colors.gold.p3.a.8": {
    "value": "var(--colors-gold-p3-a-8)",
    "variable": "var(--colors-gold-p3-a-8)"
  },
  "colors.gold.p3.a.9": {
    "value": "var(--colors-gold-p3-a-9)",
    "variable": "var(--colors-gold-p3-a-9)"
  },
  "colors.gold.p3.a.10": {
    "value": "var(--colors-gold-p3-a-10)",
    "variable": "var(--colors-gold-p3-a-10)"
  },
  "colors.gold.p3.a.11": {
    "value": "var(--colors-gold-p3-a-11)",
    "variable": "var(--colors-gold-p3-a-11)"
  },
  "colors.gold.p3.a.12": {
    "value": "var(--colors-gold-p3-a-12)",
    "variable": "var(--colors-gold-p3-a-12)"
  },
  "colors.grass.1": {
    "value": "var(--colors-grass-1)",
    "variable": "var(--colors-grass-1)"
  },
  "colors.grass.2": {
    "value": "var(--colors-grass-2)",
    "variable": "var(--colors-grass-2)"
  },
  "colors.grass.3": {
    "value": "var(--colors-grass-3)",
    "variable": "var(--colors-grass-3)"
  },
  "colors.grass.4": {
    "value": "var(--colors-grass-4)",
    "variable": "var(--colors-grass-4)"
  },
  "colors.grass.5": {
    "value": "var(--colors-grass-5)",
    "variable": "var(--colors-grass-5)"
  },
  "colors.grass.6": {
    "value": "var(--colors-grass-6)",
    "variable": "var(--colors-grass-6)"
  },
  "colors.grass.7": {
    "value": "var(--colors-grass-7)",
    "variable": "var(--colors-grass-7)"
  },
  "colors.grass.8": {
    "value": "var(--colors-grass-8)",
    "variable": "var(--colors-grass-8)"
  },
  "colors.grass.9": {
    "value": "var(--colors-grass-9)",
    "variable": "var(--colors-grass-9)"
  },
  "colors.grass.10": {
    "value": "var(--colors-grass-10)",
    "variable": "var(--colors-grass-10)"
  },
  "colors.grass.11": {
    "value": "var(--colors-grass-11)",
    "variable": "var(--colors-grass-11)"
  },
  "colors.grass.12": {
    "value": "var(--colors-grass-12)",
    "variable": "var(--colors-grass-12)"
  },
  "colors.grass.light.1": {
    "value": "var(--colors-grass-light-1)",
    "variable": "var(--colors-grass-light-1)"
  },
  "colors.grass.light.2": {
    "value": "var(--colors-grass-light-2)",
    "variable": "var(--colors-grass-light-2)"
  },
  "colors.grass.light.3": {
    "value": "var(--colors-grass-light-3)",
    "variable": "var(--colors-grass-light-3)"
  },
  "colors.grass.light.4": {
    "value": "var(--colors-grass-light-4)",
    "variable": "var(--colors-grass-light-4)"
  },
  "colors.grass.light.5": {
    "value": "var(--colors-grass-light-5)",
    "variable": "var(--colors-grass-light-5)"
  },
  "colors.grass.light.6": {
    "value": "var(--colors-grass-light-6)",
    "variable": "var(--colors-grass-light-6)"
  },
  "colors.grass.light.7": {
    "value": "var(--colors-grass-light-7)",
    "variable": "var(--colors-grass-light-7)"
  },
  "colors.grass.light.8": {
    "value": "var(--colors-grass-light-8)",
    "variable": "var(--colors-grass-light-8)"
  },
  "colors.grass.light.9": {
    "value": "var(--colors-grass-light-9)",
    "variable": "var(--colors-grass-light-9)"
  },
  "colors.grass.light.10": {
    "value": "var(--colors-grass-light-10)",
    "variable": "var(--colors-grass-light-10)"
  },
  "colors.grass.light.11": {
    "value": "var(--colors-grass-light-11)",
    "variable": "var(--colors-grass-light-11)"
  },
  "colors.grass.light.12": {
    "value": "var(--colors-grass-light-12)",
    "variable": "var(--colors-grass-light-12)"
  },
  "colors.grass.light.a.1": {
    "value": "var(--colors-grass-light-a-1)",
    "variable": "var(--colors-grass-light-a-1)"
  },
  "colors.grass.light.a.2": {
    "value": "var(--colors-grass-light-a-2)",
    "variable": "var(--colors-grass-light-a-2)"
  },
  "colors.grass.light.a.3": {
    "value": "var(--colors-grass-light-a-3)",
    "variable": "var(--colors-grass-light-a-3)"
  },
  "colors.grass.light.a.4": {
    "value": "var(--colors-grass-light-a-4)",
    "variable": "var(--colors-grass-light-a-4)"
  },
  "colors.grass.light.a.5": {
    "value": "var(--colors-grass-light-a-5)",
    "variable": "var(--colors-grass-light-a-5)"
  },
  "colors.grass.light.a.6": {
    "value": "var(--colors-grass-light-a-6)",
    "variable": "var(--colors-grass-light-a-6)"
  },
  "colors.grass.light.a.7": {
    "value": "var(--colors-grass-light-a-7)",
    "variable": "var(--colors-grass-light-a-7)"
  },
  "colors.grass.light.a.8": {
    "value": "var(--colors-grass-light-a-8)",
    "variable": "var(--colors-grass-light-a-8)"
  },
  "colors.grass.light.a.9": {
    "value": "var(--colors-grass-light-a-9)",
    "variable": "var(--colors-grass-light-a-9)"
  },
  "colors.grass.light.a.10": {
    "value": "var(--colors-grass-light-a-10)",
    "variable": "var(--colors-grass-light-a-10)"
  },
  "colors.grass.light.a.11": {
    "value": "var(--colors-grass-light-a-11)",
    "variable": "var(--colors-grass-light-a-11)"
  },
  "colors.grass.light.a.12": {
    "value": "var(--colors-grass-light-a-12)",
    "variable": "var(--colors-grass-light-a-12)"
  },
  "colors.grass.light.p3.1": {
    "value": "color(display-p3 0.986 0.996 0.985)",
    "variable": "var(--colors-grass-light-p3-1)"
  },
  "colors.grass.light.p3.2": {
    "value": "color(display-p3 0.966 0.983 0.964)",
    "variable": "var(--colors-grass-light-p3-2)"
  },
  "colors.grass.light.p3.3": {
    "value": "color(display-p3 0.923 0.965 0.917)",
    "variable": "var(--colors-grass-light-p3-3)"
  },
  "colors.grass.light.p3.4": {
    "value": "color(display-p3 0.872 0.94 0.865)",
    "variable": "var(--colors-grass-light-p3-4)"
  },
  "colors.grass.light.p3.5": {
    "value": "color(display-p3 0.811 0.908 0.802)",
    "variable": "var(--colors-grass-light-p3-5)"
  },
  "colors.grass.light.p3.6": {
    "value": "color(display-p3 0.733 0.864 0.724)",
    "variable": "var(--colors-grass-light-p3-6)"
  },
  "colors.grass.light.p3.7": {
    "value": "color(display-p3 0.628 0.803 0.622)",
    "variable": "var(--colors-grass-light-p3-7)"
  },
  "colors.grass.light.p3.8": {
    "value": "color(display-p3 0.477 0.72 0.482)",
    "variable": "var(--colors-grass-light-p3-8)"
  },
  "colors.grass.light.p3.9": {
    "value": "color(display-p3 0.38 0.647 0.378)",
    "variable": "var(--colors-grass-light-p3-9)"
  },
  "colors.grass.light.p3.10": {
    "value": "color(display-p3 0.344 0.598 0.342)",
    "variable": "var(--colors-grass-light-p3-10)"
  },
  "colors.grass.light.p3.11": {
    "value": "color(display-p3 0.263 0.488 0.261)",
    "variable": "var(--colors-grass-light-p3-11)"
  },
  "colors.grass.light.p3.12": {
    "value": "color(display-p3 0.151 0.233 0.153)",
    "variable": "var(--colors-grass-light-p3-12)"
  },
  "colors.grass.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.757 0.024 / 0.016)",
    "variable": "var(--colors-grass-light-p3-a-1)"
  },
  "colors.grass.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.565 0.024 / 0.036)",
    "variable": "var(--colors-grass-light-p3-a-2)"
  },
  "colors.grass.light.p3.a.3": {
    "value": "color(display-p3 0.059 0.576 0.008 / 0.083)",
    "variable": "var(--colors-grass-light-p3-a-3)"
  },
  "colors.grass.light.p3.a.4": {
    "value": "color(display-p3 0.035 0.565 0.008 / 0.134)",
    "variable": "var(--colors-grass-light-p3-a-4)"
  },
  "colors.grass.light.p3.a.5": {
    "value": "color(display-p3 0.047 0.545 0.008 / 0.197)",
    "variable": "var(--colors-grass-light-p3-a-5)"
  },
  "colors.grass.light.p3.a.6": {
    "value": "color(display-p3 0.031 0.502 0.004 / 0.275)",
    "variable": "var(--colors-grass-light-p3-a-6)"
  },
  "colors.grass.light.p3.a.7": {
    "value": "color(display-p3 0.012 0.482 0.004 / 0.377)",
    "variable": "var(--colors-grass-light-p3-a-7)"
  },
  "colors.grass.light.p3.a.8": {
    "value": "color(display-p3 0 0.467 0.008 / 0.522)",
    "variable": "var(--colors-grass-light-p3-a-8)"
  },
  "colors.grass.light.p3.a.9": {
    "value": "color(display-p3 0.008 0.435 0 / 0.624)",
    "variable": "var(--colors-grass-light-p3-a-9)"
  },
  "colors.grass.light.p3.a.10": {
    "value": "color(display-p3 0.008 0.388 0 / 0.659)",
    "variable": "var(--colors-grass-light-p3-a-10)"
  },
  "colors.grass.light.p3.a.11": {
    "value": "color(display-p3 0.263 0.488 0.261)",
    "variable": "var(--colors-grass-light-p3-a-11)"
  },
  "colors.grass.light.p3.a.12": {
    "value": "color(display-p3 0.151 0.233 0.153)",
    "variable": "var(--colors-grass-light-p3-a-12)"
  },
  "colors.grass.dark.1": {
    "value": "var(--colors-grass-dark-1)",
    "variable": "var(--colors-grass-dark-1)"
  },
  "colors.grass.dark.2": {
    "value": "var(--colors-grass-dark-2)",
    "variable": "var(--colors-grass-dark-2)"
  },
  "colors.grass.dark.3": {
    "value": "var(--colors-grass-dark-3)",
    "variable": "var(--colors-grass-dark-3)"
  },
  "colors.grass.dark.4": {
    "value": "var(--colors-grass-dark-4)",
    "variable": "var(--colors-grass-dark-4)"
  },
  "colors.grass.dark.5": {
    "value": "var(--colors-grass-dark-5)",
    "variable": "var(--colors-grass-dark-5)"
  },
  "colors.grass.dark.6": {
    "value": "var(--colors-grass-dark-6)",
    "variable": "var(--colors-grass-dark-6)"
  },
  "colors.grass.dark.7": {
    "value": "var(--colors-grass-dark-7)",
    "variable": "var(--colors-grass-dark-7)"
  },
  "colors.grass.dark.8": {
    "value": "var(--colors-grass-dark-8)",
    "variable": "var(--colors-grass-dark-8)"
  },
  "colors.grass.dark.9": {
    "value": "var(--colors-grass-dark-9)",
    "variable": "var(--colors-grass-dark-9)"
  },
  "colors.grass.dark.10": {
    "value": "var(--colors-grass-dark-10)",
    "variable": "var(--colors-grass-dark-10)"
  },
  "colors.grass.dark.11": {
    "value": "var(--colors-grass-dark-11)",
    "variable": "var(--colors-grass-dark-11)"
  },
  "colors.grass.dark.12": {
    "value": "var(--colors-grass-dark-12)",
    "variable": "var(--colors-grass-dark-12)"
  },
  "colors.grass.dark.a.1": {
    "value": "var(--colors-grass-dark-a-1)",
    "variable": "var(--colors-grass-dark-a-1)"
  },
  "colors.grass.dark.a.2": {
    "value": "var(--colors-grass-dark-a-2)",
    "variable": "var(--colors-grass-dark-a-2)"
  },
  "colors.grass.dark.a.3": {
    "value": "var(--colors-grass-dark-a-3)",
    "variable": "var(--colors-grass-dark-a-3)"
  },
  "colors.grass.dark.a.4": {
    "value": "var(--colors-grass-dark-a-4)",
    "variable": "var(--colors-grass-dark-a-4)"
  },
  "colors.grass.dark.a.5": {
    "value": "var(--colors-grass-dark-a-5)",
    "variable": "var(--colors-grass-dark-a-5)"
  },
  "colors.grass.dark.a.6": {
    "value": "var(--colors-grass-dark-a-6)",
    "variable": "var(--colors-grass-dark-a-6)"
  },
  "colors.grass.dark.a.7": {
    "value": "var(--colors-grass-dark-a-7)",
    "variable": "var(--colors-grass-dark-a-7)"
  },
  "colors.grass.dark.a.8": {
    "value": "var(--colors-grass-dark-a-8)",
    "variable": "var(--colors-grass-dark-a-8)"
  },
  "colors.grass.dark.a.9": {
    "value": "var(--colors-grass-dark-a-9)",
    "variable": "var(--colors-grass-dark-a-9)"
  },
  "colors.grass.dark.a.10": {
    "value": "var(--colors-grass-dark-a-10)",
    "variable": "var(--colors-grass-dark-a-10)"
  },
  "colors.grass.dark.a.11": {
    "value": "var(--colors-grass-dark-a-11)",
    "variable": "var(--colors-grass-dark-a-11)"
  },
  "colors.grass.dark.a.12": {
    "value": "var(--colors-grass-dark-a-12)",
    "variable": "var(--colors-grass-dark-a-12)"
  },
  "colors.grass.dark.p3.1": {
    "value": "color(display-p3 0.062 0.083 0.067)",
    "variable": "var(--colors-grass-dark-p3-1)"
  },
  "colors.grass.dark.p3.2": {
    "value": "color(display-p3 0.083 0.103 0.085)",
    "variable": "var(--colors-grass-dark-p3-2)"
  },
  "colors.grass.dark.p3.3": {
    "value": "color(display-p3 0.118 0.163 0.122)",
    "variable": "var(--colors-grass-dark-p3-3)"
  },
  "colors.grass.dark.p3.4": {
    "value": "color(display-p3 0.142 0.225 0.15)",
    "variable": "var(--colors-grass-dark-p3-4)"
  },
  "colors.grass.dark.p3.5": {
    "value": "color(display-p3 0.178 0.279 0.186)",
    "variable": "var(--colors-grass-dark-p3-5)"
  },
  "colors.grass.dark.p3.6": {
    "value": "color(display-p3 0.217 0.337 0.224)",
    "variable": "var(--colors-grass-dark-p3-6)"
  },
  "colors.grass.dark.p3.7": {
    "value": "color(display-p3 0.258 0.4 0.264)",
    "variable": "var(--colors-grass-dark-p3-7)"
  },
  "colors.grass.dark.p3.8": {
    "value": "color(display-p3 0.302 0.47 0.305)",
    "variable": "var(--colors-grass-dark-p3-8)"
  },
  "colors.grass.dark.p3.9": {
    "value": "color(display-p3 0.38 0.647 0.378)",
    "variable": "var(--colors-grass-dark-p3-9)"
  },
  "colors.grass.dark.p3.10": {
    "value": "color(display-p3 0.426 0.694 0.426)",
    "variable": "var(--colors-grass-dark-p3-10)"
  },
  "colors.grass.dark.p3.11": {
    "value": "color(display-p3 0.535 0.807 0.542)",
    "variable": "var(--colors-grass-dark-p3-11)"
  },
  "colors.grass.dark.p3.12": {
    "value": "color(display-p3 0.797 0.936 0.776)",
    "variable": "var(--colors-grass-dark-p3-12)"
  },
  "colors.grass.dark.p3.a.1": {
    "value": "color(display-p3 0 0.992 0.071 / 0.017)",
    "variable": "var(--colors-grass-dark-p3-a-1)"
  },
  "colors.grass.dark.p3.a.2": {
    "value": "color(display-p3 0.482 0.996 0.584 / 0.038)",
    "variable": "var(--colors-grass-dark-p3-a-2)"
  },
  "colors.grass.dark.p3.a.3": {
    "value": "color(display-p3 0.549 0.992 0.588 / 0.106)",
    "variable": "var(--colors-grass-dark-p3-a-3)"
  },
  "colors.grass.dark.p3.a.4": {
    "value": "color(display-p3 0.51 0.996 0.557 / 0.169)",
    "variable": "var(--colors-grass-dark-p3-a-4)"
  },
  "colors.grass.dark.p3.a.5": {
    "value": "color(display-p3 0.553 1 0.588 / 0.227)",
    "variable": "var(--colors-grass-dark-p3-a-5)"
  },
  "colors.grass.dark.p3.a.6": {
    "value": "color(display-p3 0.584 1 0.608 / 0.29)",
    "variable": "var(--colors-grass-dark-p3-a-6)"
  },
  "colors.grass.dark.p3.a.7": {
    "value": "color(display-p3 0.604 1 0.616 / 0.358)",
    "variable": "var(--colors-grass-dark-p3-a-7)"
  },
  "colors.grass.dark.p3.a.8": {
    "value": "color(display-p3 0.608 1 0.62 / 0.433)",
    "variable": "var(--colors-grass-dark-p3-a-8)"
  },
  "colors.grass.dark.p3.a.9": {
    "value": "color(display-p3 0.573 1 0.569 / 0.622)",
    "variable": "var(--colors-grass-dark-p3-a-9)"
  },
  "colors.grass.dark.p3.a.10": {
    "value": "color(display-p3 0.6 0.996 0.6 / 0.673)",
    "variable": "var(--colors-grass-dark-p3-a-10)"
  },
  "colors.grass.dark.p3.a.11": {
    "value": "color(display-p3 0.535 0.807 0.542)",
    "variable": "var(--colors-grass-dark-p3-a-11)"
  },
  "colors.grass.dark.p3.a.12": {
    "value": "color(display-p3 0.797 0.936 0.776)",
    "variable": "var(--colors-grass-dark-p3-a-12)"
  },
  "colors.grass.a.1": {
    "value": "var(--colors-grass-a-1)",
    "variable": "var(--colors-grass-a-1)"
  },
  "colors.grass.a.2": {
    "value": "var(--colors-grass-a-2)",
    "variable": "var(--colors-grass-a-2)"
  },
  "colors.grass.a.3": {
    "value": "var(--colors-grass-a-3)",
    "variable": "var(--colors-grass-a-3)"
  },
  "colors.grass.a.4": {
    "value": "var(--colors-grass-a-4)",
    "variable": "var(--colors-grass-a-4)"
  },
  "colors.grass.a.5": {
    "value": "var(--colors-grass-a-5)",
    "variable": "var(--colors-grass-a-5)"
  },
  "colors.grass.a.6": {
    "value": "var(--colors-grass-a-6)",
    "variable": "var(--colors-grass-a-6)"
  },
  "colors.grass.a.7": {
    "value": "var(--colors-grass-a-7)",
    "variable": "var(--colors-grass-a-7)"
  },
  "colors.grass.a.8": {
    "value": "var(--colors-grass-a-8)",
    "variable": "var(--colors-grass-a-8)"
  },
  "colors.grass.a.9": {
    "value": "var(--colors-grass-a-9)",
    "variable": "var(--colors-grass-a-9)"
  },
  "colors.grass.a.10": {
    "value": "var(--colors-grass-a-10)",
    "variable": "var(--colors-grass-a-10)"
  },
  "colors.grass.a.11": {
    "value": "var(--colors-grass-a-11)",
    "variable": "var(--colors-grass-a-11)"
  },
  "colors.grass.a.12": {
    "value": "var(--colors-grass-a-12)",
    "variable": "var(--colors-grass-a-12)"
  },
  "colors.grass.p3.1": {
    "value": "var(--colors-grass-p3-1)",
    "variable": "var(--colors-grass-p3-1)"
  },
  "colors.grass.p3.2": {
    "value": "var(--colors-grass-p3-2)",
    "variable": "var(--colors-grass-p3-2)"
  },
  "colors.grass.p3.3": {
    "value": "var(--colors-grass-p3-3)",
    "variable": "var(--colors-grass-p3-3)"
  },
  "colors.grass.p3.4": {
    "value": "var(--colors-grass-p3-4)",
    "variable": "var(--colors-grass-p3-4)"
  },
  "colors.grass.p3.5": {
    "value": "var(--colors-grass-p3-5)",
    "variable": "var(--colors-grass-p3-5)"
  },
  "colors.grass.p3.6": {
    "value": "var(--colors-grass-p3-6)",
    "variable": "var(--colors-grass-p3-6)"
  },
  "colors.grass.p3.7": {
    "value": "var(--colors-grass-p3-7)",
    "variable": "var(--colors-grass-p3-7)"
  },
  "colors.grass.p3.8": {
    "value": "var(--colors-grass-p3-8)",
    "variable": "var(--colors-grass-p3-8)"
  },
  "colors.grass.p3.9": {
    "value": "var(--colors-grass-p3-9)",
    "variable": "var(--colors-grass-p3-9)"
  },
  "colors.grass.p3.10": {
    "value": "var(--colors-grass-p3-10)",
    "variable": "var(--colors-grass-p3-10)"
  },
  "colors.grass.p3.11": {
    "value": "var(--colors-grass-p3-11)",
    "variable": "var(--colors-grass-p3-11)"
  },
  "colors.grass.p3.12": {
    "value": "var(--colors-grass-p3-12)",
    "variable": "var(--colors-grass-p3-12)"
  },
  "colors.grass.p3.a.1": {
    "value": "var(--colors-grass-p3-a-1)",
    "variable": "var(--colors-grass-p3-a-1)"
  },
  "colors.grass.p3.a.2": {
    "value": "var(--colors-grass-p3-a-2)",
    "variable": "var(--colors-grass-p3-a-2)"
  },
  "colors.grass.p3.a.3": {
    "value": "var(--colors-grass-p3-a-3)",
    "variable": "var(--colors-grass-p3-a-3)"
  },
  "colors.grass.p3.a.4": {
    "value": "var(--colors-grass-p3-a-4)",
    "variable": "var(--colors-grass-p3-a-4)"
  },
  "colors.grass.p3.a.5": {
    "value": "var(--colors-grass-p3-a-5)",
    "variable": "var(--colors-grass-p3-a-5)"
  },
  "colors.grass.p3.a.6": {
    "value": "var(--colors-grass-p3-a-6)",
    "variable": "var(--colors-grass-p3-a-6)"
  },
  "colors.grass.p3.a.7": {
    "value": "var(--colors-grass-p3-a-7)",
    "variable": "var(--colors-grass-p3-a-7)"
  },
  "colors.grass.p3.a.8": {
    "value": "var(--colors-grass-p3-a-8)",
    "variable": "var(--colors-grass-p3-a-8)"
  },
  "colors.grass.p3.a.9": {
    "value": "var(--colors-grass-p3-a-9)",
    "variable": "var(--colors-grass-p3-a-9)"
  },
  "colors.grass.p3.a.10": {
    "value": "var(--colors-grass-p3-a-10)",
    "variable": "var(--colors-grass-p3-a-10)"
  },
  "colors.grass.p3.a.11": {
    "value": "var(--colors-grass-p3-a-11)",
    "variable": "var(--colors-grass-p3-a-11)"
  },
  "colors.grass.p3.a.12": {
    "value": "var(--colors-grass-p3-a-12)",
    "variable": "var(--colors-grass-p3-a-12)"
  },
  "colors.gray.1": {
    "value": "var(--colors-gray-1)",
    "variable": "var(--colors-gray-1)"
  },
  "colors.gray.2": {
    "value": "var(--colors-gray-2)",
    "variable": "var(--colors-gray-2)"
  },
  "colors.gray.3": {
    "value": "var(--colors-gray-3)",
    "variable": "var(--colors-gray-3)"
  },
  "colors.gray.4": {
    "value": "var(--colors-gray-4)",
    "variable": "var(--colors-gray-4)"
  },
  "colors.gray.5": {
    "value": "var(--colors-gray-5)",
    "variable": "var(--colors-gray-5)"
  },
  "colors.gray.6": {
    "value": "var(--colors-gray-6)",
    "variable": "var(--colors-gray-6)"
  },
  "colors.gray.7": {
    "value": "var(--colors-gray-7)",
    "variable": "var(--colors-gray-7)"
  },
  "colors.gray.8": {
    "value": "var(--colors-gray-8)",
    "variable": "var(--colors-gray-8)"
  },
  "colors.gray.9": {
    "value": "var(--colors-gray-9)",
    "variable": "var(--colors-gray-9)"
  },
  "colors.gray.10": {
    "value": "var(--colors-gray-10)",
    "variable": "var(--colors-gray-10)"
  },
  "colors.gray.11": {
    "value": "var(--colors-gray-11)",
    "variable": "var(--colors-gray-11)"
  },
  "colors.gray.12": {
    "value": "var(--colors-gray-12)",
    "variable": "var(--colors-gray-12)"
  },
  "colors.gray.light.1": {
    "value": "var(--colors-gray-light-1)",
    "variable": "var(--colors-gray-light-1)"
  },
  "colors.gray.light.2": {
    "value": "var(--colors-gray-light-2)",
    "variable": "var(--colors-gray-light-2)"
  },
  "colors.gray.light.3": {
    "value": "var(--colors-gray-light-3)",
    "variable": "var(--colors-gray-light-3)"
  },
  "colors.gray.light.4": {
    "value": "var(--colors-gray-light-4)",
    "variable": "var(--colors-gray-light-4)"
  },
  "colors.gray.light.5": {
    "value": "var(--colors-gray-light-5)",
    "variable": "var(--colors-gray-light-5)"
  },
  "colors.gray.light.6": {
    "value": "var(--colors-gray-light-6)",
    "variable": "var(--colors-gray-light-6)"
  },
  "colors.gray.light.7": {
    "value": "var(--colors-gray-light-7)",
    "variable": "var(--colors-gray-light-7)"
  },
  "colors.gray.light.8": {
    "value": "var(--colors-gray-light-8)",
    "variable": "var(--colors-gray-light-8)"
  },
  "colors.gray.light.9": {
    "value": "var(--colors-gray-light-9)",
    "variable": "var(--colors-gray-light-9)"
  },
  "colors.gray.light.10": {
    "value": "var(--colors-gray-light-10)",
    "variable": "var(--colors-gray-light-10)"
  },
  "colors.gray.light.11": {
    "value": "var(--colors-gray-light-11)",
    "variable": "var(--colors-gray-light-11)"
  },
  "colors.gray.light.12": {
    "value": "var(--colors-gray-light-12)",
    "variable": "var(--colors-gray-light-12)"
  },
  "colors.gray.light.a.1": {
    "value": "var(--colors-gray-light-a-1)",
    "variable": "var(--colors-gray-light-a-1)"
  },
  "colors.gray.light.a.2": {
    "value": "var(--colors-gray-light-a-2)",
    "variable": "var(--colors-gray-light-a-2)"
  },
  "colors.gray.light.a.3": {
    "value": "var(--colors-gray-light-a-3)",
    "variable": "var(--colors-gray-light-a-3)"
  },
  "colors.gray.light.a.4": {
    "value": "var(--colors-gray-light-a-4)",
    "variable": "var(--colors-gray-light-a-4)"
  },
  "colors.gray.light.a.5": {
    "value": "var(--colors-gray-light-a-5)",
    "variable": "var(--colors-gray-light-a-5)"
  },
  "colors.gray.light.a.6": {
    "value": "var(--colors-gray-light-a-6)",
    "variable": "var(--colors-gray-light-a-6)"
  },
  "colors.gray.light.a.7": {
    "value": "var(--colors-gray-light-a-7)",
    "variable": "var(--colors-gray-light-a-7)"
  },
  "colors.gray.light.a.8": {
    "value": "var(--colors-gray-light-a-8)",
    "variable": "var(--colors-gray-light-a-8)"
  },
  "colors.gray.light.a.9": {
    "value": "var(--colors-gray-light-a-9)",
    "variable": "var(--colors-gray-light-a-9)"
  },
  "colors.gray.light.a.10": {
    "value": "var(--colors-gray-light-a-10)",
    "variable": "var(--colors-gray-light-a-10)"
  },
  "colors.gray.light.a.11": {
    "value": "var(--colors-gray-light-a-11)",
    "variable": "var(--colors-gray-light-a-11)"
  },
  "colors.gray.light.a.12": {
    "value": "var(--colors-gray-light-a-12)",
    "variable": "var(--colors-gray-light-a-12)"
  },
  "colors.gray.light.p3.1": {
    "value": "color(display-p3 0.988 0.988 0.988)",
    "variable": "var(--colors-gray-light-p3-1)"
  },
  "colors.gray.light.p3.2": {
    "value": "color(display-p3 0.975 0.975 0.975)",
    "variable": "var(--colors-gray-light-p3-2)"
  },
  "colors.gray.light.p3.3": {
    "value": "color(display-p3 0.939 0.939 0.939)",
    "variable": "var(--colors-gray-light-p3-3)"
  },
  "colors.gray.light.p3.4": {
    "value": "color(display-p3 0.908 0.908 0.908)",
    "variable": "var(--colors-gray-light-p3-4)"
  },
  "colors.gray.light.p3.5": {
    "value": "color(display-p3 0.88 0.88 0.88)",
    "variable": "var(--colors-gray-light-p3-5)"
  },
  "colors.gray.light.p3.6": {
    "value": "color(display-p3 0.849 0.849 0.849)",
    "variable": "var(--colors-gray-light-p3-6)"
  },
  "colors.gray.light.p3.7": {
    "value": "color(display-p3 0.807 0.807 0.807)",
    "variable": "var(--colors-gray-light-p3-7)"
  },
  "colors.gray.light.p3.8": {
    "value": "color(display-p3 0.732 0.732 0.732)",
    "variable": "var(--colors-gray-light-p3-8)"
  },
  "colors.gray.light.p3.9": {
    "value": "color(display-p3 0.553 0.553 0.553)",
    "variable": "var(--colors-gray-light-p3-9)"
  },
  "colors.gray.light.p3.10": {
    "value": "color(display-p3 0.512 0.512 0.512)",
    "variable": "var(--colors-gray-light-p3-10)"
  },
  "colors.gray.light.p3.11": {
    "value": "color(display-p3 0.392 0.392 0.392)",
    "variable": "var(--colors-gray-light-p3-11)"
  },
  "colors.gray.light.p3.12": {
    "value": "color(display-p3 0.125 0.125 0.125)",
    "variable": "var(--colors-gray-light-p3-12)"
  },
  "colors.gray.light.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0.012)",
    "variable": "var(--colors-gray-light-p3-a-1)"
  },
  "colors.gray.light.p3.a.2": {
    "value": "color(display-p3 0 0 0 / 0.024)",
    "variable": "var(--colors-gray-light-p3-a-2)"
  },
  "colors.gray.light.p3.a.3": {
    "value": "color(display-p3 0 0 0 / 0.063)",
    "variable": "var(--colors-gray-light-p3-a-3)"
  },
  "colors.gray.light.p3.a.4": {
    "value": "color(display-p3 0 0 0 / 0.09)",
    "variable": "var(--colors-gray-light-p3-a-4)"
  },
  "colors.gray.light.p3.a.5": {
    "value": "color(display-p3 0 0 0 / 0.122)",
    "variable": "var(--colors-gray-light-p3-a-5)"
  },
  "colors.gray.light.p3.a.6": {
    "value": "color(display-p3 0 0 0 / 0.153)",
    "variable": "var(--colors-gray-light-p3-a-6)"
  },
  "colors.gray.light.p3.a.7": {
    "value": "color(display-p3 0 0 0 / 0.192)",
    "variable": "var(--colors-gray-light-p3-a-7)"
  },
  "colors.gray.light.p3.a.8": {
    "value": "color(display-p3 0 0 0 / 0.267)",
    "variable": "var(--colors-gray-light-p3-a-8)"
  },
  "colors.gray.light.p3.a.9": {
    "value": "color(display-p3 0 0 0 / 0.447)",
    "variable": "var(--colors-gray-light-p3-a-9)"
  },
  "colors.gray.light.p3.a.10": {
    "value": "color(display-p3 0 0 0 / 0.486)",
    "variable": "var(--colors-gray-light-p3-a-10)"
  },
  "colors.gray.light.p3.a.11": {
    "value": "color(display-p3 0 0 0 / 0.608)",
    "variable": "var(--colors-gray-light-p3-a-11)"
  },
  "colors.gray.light.p3.a.12": {
    "value": "color(display-p3 0 0 0 / 0.875)",
    "variable": "var(--colors-gray-light-p3-a-12)"
  },
  "colors.gray.dark.1": {
    "value": "var(--colors-gray-dark-1)",
    "variable": "var(--colors-gray-dark-1)"
  },
  "colors.gray.dark.2": {
    "value": "var(--colors-gray-dark-2)",
    "variable": "var(--colors-gray-dark-2)"
  },
  "colors.gray.dark.3": {
    "value": "var(--colors-gray-dark-3)",
    "variable": "var(--colors-gray-dark-3)"
  },
  "colors.gray.dark.4": {
    "value": "var(--colors-gray-dark-4)",
    "variable": "var(--colors-gray-dark-4)"
  },
  "colors.gray.dark.5": {
    "value": "var(--colors-gray-dark-5)",
    "variable": "var(--colors-gray-dark-5)"
  },
  "colors.gray.dark.6": {
    "value": "var(--colors-gray-dark-6)",
    "variable": "var(--colors-gray-dark-6)"
  },
  "colors.gray.dark.7": {
    "value": "var(--colors-gray-dark-7)",
    "variable": "var(--colors-gray-dark-7)"
  },
  "colors.gray.dark.8": {
    "value": "var(--colors-gray-dark-8)",
    "variable": "var(--colors-gray-dark-8)"
  },
  "colors.gray.dark.9": {
    "value": "var(--colors-gray-dark-9)",
    "variable": "var(--colors-gray-dark-9)"
  },
  "colors.gray.dark.10": {
    "value": "var(--colors-gray-dark-10)",
    "variable": "var(--colors-gray-dark-10)"
  },
  "colors.gray.dark.11": {
    "value": "var(--colors-gray-dark-11)",
    "variable": "var(--colors-gray-dark-11)"
  },
  "colors.gray.dark.12": {
    "value": "var(--colors-gray-dark-12)",
    "variable": "var(--colors-gray-dark-12)"
  },
  "colors.gray.dark.a.1": {
    "value": "var(--colors-gray-dark-a-1)",
    "variable": "var(--colors-gray-dark-a-1)"
  },
  "colors.gray.dark.a.2": {
    "value": "var(--colors-gray-dark-a-2)",
    "variable": "var(--colors-gray-dark-a-2)"
  },
  "colors.gray.dark.a.3": {
    "value": "var(--colors-gray-dark-a-3)",
    "variable": "var(--colors-gray-dark-a-3)"
  },
  "colors.gray.dark.a.4": {
    "value": "var(--colors-gray-dark-a-4)",
    "variable": "var(--colors-gray-dark-a-4)"
  },
  "colors.gray.dark.a.5": {
    "value": "var(--colors-gray-dark-a-5)",
    "variable": "var(--colors-gray-dark-a-5)"
  },
  "colors.gray.dark.a.6": {
    "value": "var(--colors-gray-dark-a-6)",
    "variable": "var(--colors-gray-dark-a-6)"
  },
  "colors.gray.dark.a.7": {
    "value": "var(--colors-gray-dark-a-7)",
    "variable": "var(--colors-gray-dark-a-7)"
  },
  "colors.gray.dark.a.8": {
    "value": "var(--colors-gray-dark-a-8)",
    "variable": "var(--colors-gray-dark-a-8)"
  },
  "colors.gray.dark.a.9": {
    "value": "var(--colors-gray-dark-a-9)",
    "variable": "var(--colors-gray-dark-a-9)"
  },
  "colors.gray.dark.a.10": {
    "value": "var(--colors-gray-dark-a-10)",
    "variable": "var(--colors-gray-dark-a-10)"
  },
  "colors.gray.dark.a.11": {
    "value": "var(--colors-gray-dark-a-11)",
    "variable": "var(--colors-gray-dark-a-11)"
  },
  "colors.gray.dark.a.12": {
    "value": "var(--colors-gray-dark-a-12)",
    "variable": "var(--colors-gray-dark-a-12)"
  },
  "colors.gray.dark.p3.1": {
    "value": "color(display-p3 0.067 0.067 0.067)",
    "variable": "var(--colors-gray-dark-p3-1)"
  },
  "colors.gray.dark.p3.2": {
    "value": "color(display-p3 0.098 0.098 0.098)",
    "variable": "var(--colors-gray-dark-p3-2)"
  },
  "colors.gray.dark.p3.3": {
    "value": "color(display-p3 0.135 0.135 0.135)",
    "variable": "var(--colors-gray-dark-p3-3)"
  },
  "colors.gray.dark.p3.4": {
    "value": "color(display-p3 0.163 0.163 0.163)",
    "variable": "var(--colors-gray-dark-p3-4)"
  },
  "colors.gray.dark.p3.5": {
    "value": "color(display-p3 0.192 0.192 0.192)",
    "variable": "var(--colors-gray-dark-p3-5)"
  },
  "colors.gray.dark.p3.6": {
    "value": "color(display-p3 0.228 0.228 0.228)",
    "variable": "var(--colors-gray-dark-p3-6)"
  },
  "colors.gray.dark.p3.7": {
    "value": "color(display-p3 0.283 0.283 0.283)",
    "variable": "var(--colors-gray-dark-p3-7)"
  },
  "colors.gray.dark.p3.8": {
    "value": "color(display-p3 0.375 0.375 0.375)",
    "variable": "var(--colors-gray-dark-p3-8)"
  },
  "colors.gray.dark.p3.9": {
    "value": "color(display-p3 0.431 0.431 0.431)",
    "variable": "var(--colors-gray-dark-p3-9)"
  },
  "colors.gray.dark.p3.10": {
    "value": "color(display-p3 0.484 0.484 0.484)",
    "variable": "var(--colors-gray-dark-p3-10)"
  },
  "colors.gray.dark.p3.11": {
    "value": "color(display-p3 0.706 0.706 0.706)",
    "variable": "var(--colors-gray-dark-p3-11)"
  },
  "colors.gray.dark.p3.12": {
    "value": "color(display-p3 0.933 0.933 0.933)",
    "variable": "var(--colors-gray-dark-p3-12)"
  },
  "colors.gray.dark.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0)",
    "variable": "var(--colors-gray-dark-p3-a-1)"
  },
  "colors.gray.dark.p3.a.2": {
    "value": "color(display-p3 1 1 1 / 0.034)",
    "variable": "var(--colors-gray-dark-p3-a-2)"
  },
  "colors.gray.dark.p3.a.3": {
    "value": "color(display-p3 1 1 1 / 0.071)",
    "variable": "var(--colors-gray-dark-p3-a-3)"
  },
  "colors.gray.dark.p3.a.4": {
    "value": "color(display-p3 1 1 1 / 0.105)",
    "variable": "var(--colors-gray-dark-p3-a-4)"
  },
  "colors.gray.dark.p3.a.5": {
    "value": "color(display-p3 1 1 1 / 0.134)",
    "variable": "var(--colors-gray-dark-p3-a-5)"
  },
  "colors.gray.dark.p3.a.6": {
    "value": "color(display-p3 1 1 1 / 0.172)",
    "variable": "var(--colors-gray-dark-p3-a-6)"
  },
  "colors.gray.dark.p3.a.7": {
    "value": "color(display-p3 1 1 1 / 0.231)",
    "variable": "var(--colors-gray-dark-p3-a-7)"
  },
  "colors.gray.dark.p3.a.8": {
    "value": "color(display-p3 1 1 1 / 0.332)",
    "variable": "var(--colors-gray-dark-p3-a-8)"
  },
  "colors.gray.dark.p3.a.9": {
    "value": "color(display-p3 1 1 1 / 0.391)",
    "variable": "var(--colors-gray-dark-p3-a-9)"
  },
  "colors.gray.dark.p3.a.10": {
    "value": "color(display-p3 1 1 1 / 0.445)",
    "variable": "var(--colors-gray-dark-p3-a-10)"
  },
  "colors.gray.dark.p3.a.11": {
    "value": "color(display-p3 1 1 1 / 0.685)",
    "variable": "var(--colors-gray-dark-p3-a-11)"
  },
  "colors.gray.dark.p3.a.12": {
    "value": "color(display-p3 1 1 1 / 0.929)",
    "variable": "var(--colors-gray-dark-p3-a-12)"
  },
  "colors.gray.a.1": {
    "value": "var(--colors-gray-a-1)",
    "variable": "var(--colors-gray-a-1)"
  },
  "colors.gray.a.2": {
    "value": "var(--colors-gray-a-2)",
    "variable": "var(--colors-gray-a-2)"
  },
  "colors.gray.a.3": {
    "value": "var(--colors-gray-a-3)",
    "variable": "var(--colors-gray-a-3)"
  },
  "colors.gray.a.4": {
    "value": "var(--colors-gray-a-4)",
    "variable": "var(--colors-gray-a-4)"
  },
  "colors.gray.a.5": {
    "value": "var(--colors-gray-a-5)",
    "variable": "var(--colors-gray-a-5)"
  },
  "colors.gray.a.6": {
    "value": "var(--colors-gray-a-6)",
    "variable": "var(--colors-gray-a-6)"
  },
  "colors.gray.a.7": {
    "value": "var(--colors-gray-a-7)",
    "variable": "var(--colors-gray-a-7)"
  },
  "colors.gray.a.8": {
    "value": "var(--colors-gray-a-8)",
    "variable": "var(--colors-gray-a-8)"
  },
  "colors.gray.a.9": {
    "value": "var(--colors-gray-a-9)",
    "variable": "var(--colors-gray-a-9)"
  },
  "colors.gray.a.10": {
    "value": "var(--colors-gray-a-10)",
    "variable": "var(--colors-gray-a-10)"
  },
  "colors.gray.a.11": {
    "value": "var(--colors-gray-a-11)",
    "variable": "var(--colors-gray-a-11)"
  },
  "colors.gray.a.12": {
    "value": "var(--colors-gray-a-12)",
    "variable": "var(--colors-gray-a-12)"
  },
  "colors.gray.p3.1": {
    "value": "var(--colors-gray-p3-1)",
    "variable": "var(--colors-gray-p3-1)"
  },
  "colors.gray.p3.2": {
    "value": "var(--colors-gray-p3-2)",
    "variable": "var(--colors-gray-p3-2)"
  },
  "colors.gray.p3.3": {
    "value": "var(--colors-gray-p3-3)",
    "variable": "var(--colors-gray-p3-3)"
  },
  "colors.gray.p3.4": {
    "value": "var(--colors-gray-p3-4)",
    "variable": "var(--colors-gray-p3-4)"
  },
  "colors.gray.p3.5": {
    "value": "var(--colors-gray-p3-5)",
    "variable": "var(--colors-gray-p3-5)"
  },
  "colors.gray.p3.6": {
    "value": "var(--colors-gray-p3-6)",
    "variable": "var(--colors-gray-p3-6)"
  },
  "colors.gray.p3.7": {
    "value": "var(--colors-gray-p3-7)",
    "variable": "var(--colors-gray-p3-7)"
  },
  "colors.gray.p3.8": {
    "value": "var(--colors-gray-p3-8)",
    "variable": "var(--colors-gray-p3-8)"
  },
  "colors.gray.p3.9": {
    "value": "var(--colors-gray-p3-9)",
    "variable": "var(--colors-gray-p3-9)"
  },
  "colors.gray.p3.10": {
    "value": "var(--colors-gray-p3-10)",
    "variable": "var(--colors-gray-p3-10)"
  },
  "colors.gray.p3.11": {
    "value": "var(--colors-gray-p3-11)",
    "variable": "var(--colors-gray-p3-11)"
  },
  "colors.gray.p3.12": {
    "value": "var(--colors-gray-p3-12)",
    "variable": "var(--colors-gray-p3-12)"
  },
  "colors.gray.p3.a.1": {
    "value": "var(--colors-gray-p3-a-1)",
    "variable": "var(--colors-gray-p3-a-1)"
  },
  "colors.gray.p3.a.2": {
    "value": "var(--colors-gray-p3-a-2)",
    "variable": "var(--colors-gray-p3-a-2)"
  },
  "colors.gray.p3.a.3": {
    "value": "var(--colors-gray-p3-a-3)",
    "variable": "var(--colors-gray-p3-a-3)"
  },
  "colors.gray.p3.a.4": {
    "value": "var(--colors-gray-p3-a-4)",
    "variable": "var(--colors-gray-p3-a-4)"
  },
  "colors.gray.p3.a.5": {
    "value": "var(--colors-gray-p3-a-5)",
    "variable": "var(--colors-gray-p3-a-5)"
  },
  "colors.gray.p3.a.6": {
    "value": "var(--colors-gray-p3-a-6)",
    "variable": "var(--colors-gray-p3-a-6)"
  },
  "colors.gray.p3.a.7": {
    "value": "var(--colors-gray-p3-a-7)",
    "variable": "var(--colors-gray-p3-a-7)"
  },
  "colors.gray.p3.a.8": {
    "value": "var(--colors-gray-p3-a-8)",
    "variable": "var(--colors-gray-p3-a-8)"
  },
  "colors.gray.p3.a.9": {
    "value": "var(--colors-gray-p3-a-9)",
    "variable": "var(--colors-gray-p3-a-9)"
  },
  "colors.gray.p3.a.10": {
    "value": "var(--colors-gray-p3-a-10)",
    "variable": "var(--colors-gray-p3-a-10)"
  },
  "colors.gray.p3.a.11": {
    "value": "var(--colors-gray-p3-a-11)",
    "variable": "var(--colors-gray-p3-a-11)"
  },
  "colors.gray.p3.a.12": {
    "value": "var(--colors-gray-p3-a-12)",
    "variable": "var(--colors-gray-p3-a-12)"
  },
  "colors.green.1": {
    "value": "var(--colors-green-1)",
    "variable": "var(--colors-green-1)"
  },
  "colors.green.2": {
    "value": "var(--colors-green-2)",
    "variable": "var(--colors-green-2)"
  },
  "colors.green.3": {
    "value": "var(--colors-green-3)",
    "variable": "var(--colors-green-3)"
  },
  "colors.green.4": {
    "value": "var(--colors-green-4)",
    "variable": "var(--colors-green-4)"
  },
  "colors.green.5": {
    "value": "var(--colors-green-5)",
    "variable": "var(--colors-green-5)"
  },
  "colors.green.6": {
    "value": "var(--colors-green-6)",
    "variable": "var(--colors-green-6)"
  },
  "colors.green.7": {
    "value": "var(--colors-green-7)",
    "variable": "var(--colors-green-7)"
  },
  "colors.green.8": {
    "value": "var(--colors-green-8)",
    "variable": "var(--colors-green-8)"
  },
  "colors.green.9": {
    "value": "var(--colors-green-9)",
    "variable": "var(--colors-green-9)"
  },
  "colors.green.10": {
    "value": "var(--colors-green-10)",
    "variable": "var(--colors-green-10)"
  },
  "colors.green.11": {
    "value": "var(--colors-green-11)",
    "variable": "var(--colors-green-11)"
  },
  "colors.green.12": {
    "value": "var(--colors-green-12)",
    "variable": "var(--colors-green-12)"
  },
  "colors.green.light.1": {
    "value": "var(--colors-green-light-1)",
    "variable": "var(--colors-green-light-1)"
  },
  "colors.green.light.2": {
    "value": "var(--colors-green-light-2)",
    "variable": "var(--colors-green-light-2)"
  },
  "colors.green.light.3": {
    "value": "var(--colors-green-light-3)",
    "variable": "var(--colors-green-light-3)"
  },
  "colors.green.light.4": {
    "value": "var(--colors-green-light-4)",
    "variable": "var(--colors-green-light-4)"
  },
  "colors.green.light.5": {
    "value": "var(--colors-green-light-5)",
    "variable": "var(--colors-green-light-5)"
  },
  "colors.green.light.6": {
    "value": "var(--colors-green-light-6)",
    "variable": "var(--colors-green-light-6)"
  },
  "colors.green.light.7": {
    "value": "var(--colors-green-light-7)",
    "variable": "var(--colors-green-light-7)"
  },
  "colors.green.light.8": {
    "value": "var(--colors-green-light-8)",
    "variable": "var(--colors-green-light-8)"
  },
  "colors.green.light.9": {
    "value": "var(--colors-green-light-9)",
    "variable": "var(--colors-green-light-9)"
  },
  "colors.green.light.10": {
    "value": "var(--colors-green-light-10)",
    "variable": "var(--colors-green-light-10)"
  },
  "colors.green.light.11": {
    "value": "var(--colors-green-light-11)",
    "variable": "var(--colors-green-light-11)"
  },
  "colors.green.light.12": {
    "value": "var(--colors-green-light-12)",
    "variable": "var(--colors-green-light-12)"
  },
  "colors.green.light.a.1": {
    "value": "var(--colors-green-light-a-1)",
    "variable": "var(--colors-green-light-a-1)"
  },
  "colors.green.light.a.2": {
    "value": "var(--colors-green-light-a-2)",
    "variable": "var(--colors-green-light-a-2)"
  },
  "colors.green.light.a.3": {
    "value": "var(--colors-green-light-a-3)",
    "variable": "var(--colors-green-light-a-3)"
  },
  "colors.green.light.a.4": {
    "value": "var(--colors-green-light-a-4)",
    "variable": "var(--colors-green-light-a-4)"
  },
  "colors.green.light.a.5": {
    "value": "var(--colors-green-light-a-5)",
    "variable": "var(--colors-green-light-a-5)"
  },
  "colors.green.light.a.6": {
    "value": "var(--colors-green-light-a-6)",
    "variable": "var(--colors-green-light-a-6)"
  },
  "colors.green.light.a.7": {
    "value": "var(--colors-green-light-a-7)",
    "variable": "var(--colors-green-light-a-7)"
  },
  "colors.green.light.a.8": {
    "value": "var(--colors-green-light-a-8)",
    "variable": "var(--colors-green-light-a-8)"
  },
  "colors.green.light.a.9": {
    "value": "var(--colors-green-light-a-9)",
    "variable": "var(--colors-green-light-a-9)"
  },
  "colors.green.light.a.10": {
    "value": "var(--colors-green-light-a-10)",
    "variable": "var(--colors-green-light-a-10)"
  },
  "colors.green.light.a.11": {
    "value": "var(--colors-green-light-a-11)",
    "variable": "var(--colors-green-light-a-11)"
  },
  "colors.green.light.a.12": {
    "value": "var(--colors-green-light-a-12)",
    "variable": "var(--colors-green-light-a-12)"
  },
  "colors.green.light.p3.1": {
    "value": "color(display-p3 0.986 0.996 0.989)",
    "variable": "var(--colors-green-light-p3-1)"
  },
  "colors.green.light.p3.2": {
    "value": "color(display-p3 0.963 0.983 0.967)",
    "variable": "var(--colors-green-light-p3-2)"
  },
  "colors.green.light.p3.3": {
    "value": "color(display-p3 0.913 0.964 0.925)",
    "variable": "var(--colors-green-light-p3-3)"
  },
  "colors.green.light.p3.4": {
    "value": "color(display-p3 0.859 0.94 0.879)",
    "variable": "var(--colors-green-light-p3-4)"
  },
  "colors.green.light.p3.5": {
    "value": "color(display-p3 0.796 0.907 0.826)",
    "variable": "var(--colors-green-light-p3-5)"
  },
  "colors.green.light.p3.6": {
    "value": "color(display-p3 0.718 0.863 0.761)",
    "variable": "var(--colors-green-light-p3-6)"
  },
  "colors.green.light.p3.7": {
    "value": "color(display-p3 0.61 0.801 0.675)",
    "variable": "var(--colors-green-light-p3-7)"
  },
  "colors.green.light.p3.8": {
    "value": "color(display-p3 0.451 0.715 0.559)",
    "variable": "var(--colors-green-light-p3-8)"
  },
  "colors.green.light.p3.9": {
    "value": "color(display-p3 0.332 0.634 0.442)",
    "variable": "var(--colors-green-light-p3-9)"
  },
  "colors.green.light.p3.10": {
    "value": "color(display-p3 0.308 0.595 0.417)",
    "variable": "var(--colors-green-light-p3-10)"
  },
  "colors.green.light.p3.11": {
    "value": "color(display-p3 0.19 0.5 0.32)",
    "variable": "var(--colors-green-light-p3-11)"
  },
  "colors.green.light.p3.12": {
    "value": "color(display-p3 0.132 0.228 0.18)",
    "variable": "var(--colors-green-light-p3-12)"
  },
  "colors.green.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.757 0.267 / 0.016)",
    "variable": "var(--colors-green-light-p3-a-1)"
  },
  "colors.green.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.565 0.129 / 0.036)",
    "variable": "var(--colors-green-light-p3-a-2)"
  },
  "colors.green.light.p3.a.3": {
    "value": "color(display-p3 0.012 0.596 0.145 / 0.087)",
    "variable": "var(--colors-green-light-p3-a-3)"
  },
  "colors.green.light.p3.a.4": {
    "value": "color(display-p3 0.008 0.588 0.145 / 0.142)",
    "variable": "var(--colors-green-light-p3-a-4)"
  },
  "colors.green.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.541 0.157 / 0.204)",
    "variable": "var(--colors-green-light-p3-a-5)"
  },
  "colors.green.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.518 0.157 / 0.283)",
    "variable": "var(--colors-green-light-p3-a-6)"
  },
  "colors.green.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.486 0.165 / 0.389)",
    "variable": "var(--colors-green-light-p3-a-7)"
  },
  "colors.green.light.p3.a.8": {
    "value": "color(display-p3 0 0.478 0.2 / 0.55)",
    "variable": "var(--colors-green-light-p3-a-8)"
  },
  "colors.green.light.p3.a.9": {
    "value": "color(display-p3 0 0.455 0.165 / 0.667)",
    "variable": "var(--colors-green-light-p3-a-9)"
  },
  "colors.green.light.p3.a.10": {
    "value": "color(display-p3 0 0.416 0.153 / 0.691)",
    "variable": "var(--colors-green-light-p3-a-10)"
  },
  "colors.green.light.p3.a.11": {
    "value": "color(display-p3 0.19 0.5 0.32)",
    "variable": "var(--colors-green-light-p3-a-11)"
  },
  "colors.green.light.p3.a.12": {
    "value": "color(display-p3 0.132 0.228 0.18)",
    "variable": "var(--colors-green-light-p3-a-12)"
  },
  "colors.green.dark.1": {
    "value": "var(--colors-green-dark-1)",
    "variable": "var(--colors-green-dark-1)"
  },
  "colors.green.dark.2": {
    "value": "var(--colors-green-dark-2)",
    "variable": "var(--colors-green-dark-2)"
  },
  "colors.green.dark.3": {
    "value": "var(--colors-green-dark-3)",
    "variable": "var(--colors-green-dark-3)"
  },
  "colors.green.dark.4": {
    "value": "var(--colors-green-dark-4)",
    "variable": "var(--colors-green-dark-4)"
  },
  "colors.green.dark.5": {
    "value": "var(--colors-green-dark-5)",
    "variable": "var(--colors-green-dark-5)"
  },
  "colors.green.dark.6": {
    "value": "var(--colors-green-dark-6)",
    "variable": "var(--colors-green-dark-6)"
  },
  "colors.green.dark.7": {
    "value": "var(--colors-green-dark-7)",
    "variable": "var(--colors-green-dark-7)"
  },
  "colors.green.dark.8": {
    "value": "var(--colors-green-dark-8)",
    "variable": "var(--colors-green-dark-8)"
  },
  "colors.green.dark.9": {
    "value": "var(--colors-green-dark-9)",
    "variable": "var(--colors-green-dark-9)"
  },
  "colors.green.dark.10": {
    "value": "var(--colors-green-dark-10)",
    "variable": "var(--colors-green-dark-10)"
  },
  "colors.green.dark.11": {
    "value": "var(--colors-green-dark-11)",
    "variable": "var(--colors-green-dark-11)"
  },
  "colors.green.dark.12": {
    "value": "var(--colors-green-dark-12)",
    "variable": "var(--colors-green-dark-12)"
  },
  "colors.green.dark.a.1": {
    "value": "var(--colors-green-dark-a-1)",
    "variable": "var(--colors-green-dark-a-1)"
  },
  "colors.green.dark.a.2": {
    "value": "var(--colors-green-dark-a-2)",
    "variable": "var(--colors-green-dark-a-2)"
  },
  "colors.green.dark.a.3": {
    "value": "var(--colors-green-dark-a-3)",
    "variable": "var(--colors-green-dark-a-3)"
  },
  "colors.green.dark.a.4": {
    "value": "var(--colors-green-dark-a-4)",
    "variable": "var(--colors-green-dark-a-4)"
  },
  "colors.green.dark.a.5": {
    "value": "var(--colors-green-dark-a-5)",
    "variable": "var(--colors-green-dark-a-5)"
  },
  "colors.green.dark.a.6": {
    "value": "var(--colors-green-dark-a-6)",
    "variable": "var(--colors-green-dark-a-6)"
  },
  "colors.green.dark.a.7": {
    "value": "var(--colors-green-dark-a-7)",
    "variable": "var(--colors-green-dark-a-7)"
  },
  "colors.green.dark.a.8": {
    "value": "var(--colors-green-dark-a-8)",
    "variable": "var(--colors-green-dark-a-8)"
  },
  "colors.green.dark.a.9": {
    "value": "var(--colors-green-dark-a-9)",
    "variable": "var(--colors-green-dark-a-9)"
  },
  "colors.green.dark.a.10": {
    "value": "var(--colors-green-dark-a-10)",
    "variable": "var(--colors-green-dark-a-10)"
  },
  "colors.green.dark.a.11": {
    "value": "var(--colors-green-dark-a-11)",
    "variable": "var(--colors-green-dark-a-11)"
  },
  "colors.green.dark.a.12": {
    "value": "var(--colors-green-dark-a-12)",
    "variable": "var(--colors-green-dark-a-12)"
  },
  "colors.green.dark.p3.1": {
    "value": "color(display-p3 0.062 0.083 0.071)",
    "variable": "var(--colors-green-dark-p3-1)"
  },
  "colors.green.dark.p3.2": {
    "value": "color(display-p3 0.079 0.106 0.09)",
    "variable": "var(--colors-green-dark-p3-2)"
  },
  "colors.green.dark.p3.3": {
    "value": "color(display-p3 0.1 0.173 0.133)",
    "variable": "var(--colors-green-dark-p3-3)"
  },
  "colors.green.dark.p3.4": {
    "value": "color(display-p3 0.115 0.229 0.166)",
    "variable": "var(--colors-green-dark-p3-4)"
  },
  "colors.green.dark.p3.5": {
    "value": "color(display-p3 0.147 0.282 0.206)",
    "variable": "var(--colors-green-dark-p3-5)"
  },
  "colors.green.dark.p3.6": {
    "value": "color(display-p3 0.185 0.338 0.25)",
    "variable": "var(--colors-green-dark-p3-6)"
  },
  "colors.green.dark.p3.7": {
    "value": "color(display-p3 0.227 0.403 0.298)",
    "variable": "var(--colors-green-dark-p3-7)"
  },
  "colors.green.dark.p3.8": {
    "value": "color(display-p3 0.27 0.479 0.351)",
    "variable": "var(--colors-green-dark-p3-8)"
  },
  "colors.green.dark.p3.9": {
    "value": "color(display-p3 0.332 0.634 0.442)",
    "variable": "var(--colors-green-dark-p3-9)"
  },
  "colors.green.dark.p3.10": {
    "value": "color(display-p3 0.357 0.682 0.474)",
    "variable": "var(--colors-green-dark-p3-10)"
  },
  "colors.green.dark.p3.11": {
    "value": "color(display-p3 0.434 0.828 0.573)",
    "variable": "var(--colors-green-dark-p3-11)"
  },
  "colors.green.dark.p3.12": {
    "value": "color(display-p3 0.747 0.938 0.807)",
    "variable": "var(--colors-green-dark-p3-12)"
  },
  "colors.green.dark.p3.a.1": {
    "value": "color(display-p3 0 0.992 0.298 / 0.017)",
    "variable": "var(--colors-green-dark-p3-a-1)"
  },
  "colors.green.dark.p3.a.2": {
    "value": "color(display-p3 0.341 0.98 0.616 / 0.043)",
    "variable": "var(--colors-green-dark-p3-a-2)"
  },
  "colors.green.dark.p3.a.3": {
    "value": "color(display-p3 0.376 0.996 0.655 / 0.114)",
    "variable": "var(--colors-green-dark-p3-a-3)"
  },
  "colors.green.dark.p3.a.4": {
    "value": "color(display-p3 0.341 0.996 0.635 / 0.173)",
    "variable": "var(--colors-green-dark-p3-a-4)"
  },
  "colors.green.dark.p3.a.5": {
    "value": "color(display-p3 0.408 1 0.678 / 0.232)",
    "variable": "var(--colors-green-dark-p3-a-5)"
  },
  "colors.green.dark.p3.a.6": {
    "value": "color(display-p3 0.475 1 0.706 / 0.29)",
    "variable": "var(--colors-green-dark-p3-a-6)"
  },
  "colors.green.dark.p3.a.7": {
    "value": "color(display-p3 0.514 1 0.706 / 0.362)",
    "variable": "var(--colors-green-dark-p3-a-7)"
  },
  "colors.green.dark.p3.a.8": {
    "value": "color(display-p3 0.529 1 0.718 / 0.442)",
    "variable": "var(--colors-green-dark-p3-a-8)"
  },
  "colors.green.dark.p3.a.9": {
    "value": "color(display-p3 0.502 0.996 0.682 / 0.61)",
    "variable": "var(--colors-green-dark-p3-a-9)"
  },
  "colors.green.dark.p3.a.10": {
    "value": "color(display-p3 0.506 1 0.682 / 0.66)",
    "variable": "var(--colors-green-dark-p3-a-10)"
  },
  "colors.green.dark.p3.a.11": {
    "value": "color(display-p3 0.434 0.828 0.573)",
    "variable": "var(--colors-green-dark-p3-a-11)"
  },
  "colors.green.dark.p3.a.12": {
    "value": "color(display-p3 0.747 0.938 0.807)",
    "variable": "var(--colors-green-dark-p3-a-12)"
  },
  "colors.green.a.1": {
    "value": "var(--colors-green-a-1)",
    "variable": "var(--colors-green-a-1)"
  },
  "colors.green.a.2": {
    "value": "var(--colors-green-a-2)",
    "variable": "var(--colors-green-a-2)"
  },
  "colors.green.a.3": {
    "value": "var(--colors-green-a-3)",
    "variable": "var(--colors-green-a-3)"
  },
  "colors.green.a.4": {
    "value": "var(--colors-green-a-4)",
    "variable": "var(--colors-green-a-4)"
  },
  "colors.green.a.5": {
    "value": "var(--colors-green-a-5)",
    "variable": "var(--colors-green-a-5)"
  },
  "colors.green.a.6": {
    "value": "var(--colors-green-a-6)",
    "variable": "var(--colors-green-a-6)"
  },
  "colors.green.a.7": {
    "value": "var(--colors-green-a-7)",
    "variable": "var(--colors-green-a-7)"
  },
  "colors.green.a.8": {
    "value": "var(--colors-green-a-8)",
    "variable": "var(--colors-green-a-8)"
  },
  "colors.green.a.9": {
    "value": "var(--colors-green-a-9)",
    "variable": "var(--colors-green-a-9)"
  },
  "colors.green.a.10": {
    "value": "var(--colors-green-a-10)",
    "variable": "var(--colors-green-a-10)"
  },
  "colors.green.a.11": {
    "value": "var(--colors-green-a-11)",
    "variable": "var(--colors-green-a-11)"
  },
  "colors.green.a.12": {
    "value": "var(--colors-green-a-12)",
    "variable": "var(--colors-green-a-12)"
  },
  "colors.green.p3.1": {
    "value": "var(--colors-green-p3-1)",
    "variable": "var(--colors-green-p3-1)"
  },
  "colors.green.p3.2": {
    "value": "var(--colors-green-p3-2)",
    "variable": "var(--colors-green-p3-2)"
  },
  "colors.green.p3.3": {
    "value": "var(--colors-green-p3-3)",
    "variable": "var(--colors-green-p3-3)"
  },
  "colors.green.p3.4": {
    "value": "var(--colors-green-p3-4)",
    "variable": "var(--colors-green-p3-4)"
  },
  "colors.green.p3.5": {
    "value": "var(--colors-green-p3-5)",
    "variable": "var(--colors-green-p3-5)"
  },
  "colors.green.p3.6": {
    "value": "var(--colors-green-p3-6)",
    "variable": "var(--colors-green-p3-6)"
  },
  "colors.green.p3.7": {
    "value": "var(--colors-green-p3-7)",
    "variable": "var(--colors-green-p3-7)"
  },
  "colors.green.p3.8": {
    "value": "var(--colors-green-p3-8)",
    "variable": "var(--colors-green-p3-8)"
  },
  "colors.green.p3.9": {
    "value": "var(--colors-green-p3-9)",
    "variable": "var(--colors-green-p3-9)"
  },
  "colors.green.p3.10": {
    "value": "var(--colors-green-p3-10)",
    "variable": "var(--colors-green-p3-10)"
  },
  "colors.green.p3.11": {
    "value": "var(--colors-green-p3-11)",
    "variable": "var(--colors-green-p3-11)"
  },
  "colors.green.p3.12": {
    "value": "var(--colors-green-p3-12)",
    "variable": "var(--colors-green-p3-12)"
  },
  "colors.green.p3.a.1": {
    "value": "var(--colors-green-p3-a-1)",
    "variable": "var(--colors-green-p3-a-1)"
  },
  "colors.green.p3.a.2": {
    "value": "var(--colors-green-p3-a-2)",
    "variable": "var(--colors-green-p3-a-2)"
  },
  "colors.green.p3.a.3": {
    "value": "var(--colors-green-p3-a-3)",
    "variable": "var(--colors-green-p3-a-3)"
  },
  "colors.green.p3.a.4": {
    "value": "var(--colors-green-p3-a-4)",
    "variable": "var(--colors-green-p3-a-4)"
  },
  "colors.green.p3.a.5": {
    "value": "var(--colors-green-p3-a-5)",
    "variable": "var(--colors-green-p3-a-5)"
  },
  "colors.green.p3.a.6": {
    "value": "var(--colors-green-p3-a-6)",
    "variable": "var(--colors-green-p3-a-6)"
  },
  "colors.green.p3.a.7": {
    "value": "var(--colors-green-p3-a-7)",
    "variable": "var(--colors-green-p3-a-7)"
  },
  "colors.green.p3.a.8": {
    "value": "var(--colors-green-p3-a-8)",
    "variable": "var(--colors-green-p3-a-8)"
  },
  "colors.green.p3.a.9": {
    "value": "var(--colors-green-p3-a-9)",
    "variable": "var(--colors-green-p3-a-9)"
  },
  "colors.green.p3.a.10": {
    "value": "var(--colors-green-p3-a-10)",
    "variable": "var(--colors-green-p3-a-10)"
  },
  "colors.green.p3.a.11": {
    "value": "var(--colors-green-p3-a-11)",
    "variable": "var(--colors-green-p3-a-11)"
  },
  "colors.green.p3.a.12": {
    "value": "var(--colors-green-p3-a-12)",
    "variable": "var(--colors-green-p3-a-12)"
  },
  "colors.indigo.1": {
    "value": "var(--colors-indigo-1)",
    "variable": "var(--colors-indigo-1)"
  },
  "colors.indigo.2": {
    "value": "var(--colors-indigo-2)",
    "variable": "var(--colors-indigo-2)"
  },
  "colors.indigo.3": {
    "value": "var(--colors-indigo-3)",
    "variable": "var(--colors-indigo-3)"
  },
  "colors.indigo.4": {
    "value": "var(--colors-indigo-4)",
    "variable": "var(--colors-indigo-4)"
  },
  "colors.indigo.5": {
    "value": "var(--colors-indigo-5)",
    "variable": "var(--colors-indigo-5)"
  },
  "colors.indigo.6": {
    "value": "var(--colors-indigo-6)",
    "variable": "var(--colors-indigo-6)"
  },
  "colors.indigo.7": {
    "value": "var(--colors-indigo-7)",
    "variable": "var(--colors-indigo-7)"
  },
  "colors.indigo.8": {
    "value": "var(--colors-indigo-8)",
    "variable": "var(--colors-indigo-8)"
  },
  "colors.indigo.9": {
    "value": "var(--colors-indigo-9)",
    "variable": "var(--colors-indigo-9)"
  },
  "colors.indigo.10": {
    "value": "var(--colors-indigo-10)",
    "variable": "var(--colors-indigo-10)"
  },
  "colors.indigo.11": {
    "value": "var(--colors-indigo-11)",
    "variable": "var(--colors-indigo-11)"
  },
  "colors.indigo.12": {
    "value": "var(--colors-indigo-12)",
    "variable": "var(--colors-indigo-12)"
  },
  "colors.indigo.light.1": {
    "value": "var(--colors-indigo-light-1)",
    "variable": "var(--colors-indigo-light-1)"
  },
  "colors.indigo.light.2": {
    "value": "var(--colors-indigo-light-2)",
    "variable": "var(--colors-indigo-light-2)"
  },
  "colors.indigo.light.3": {
    "value": "var(--colors-indigo-light-3)",
    "variable": "var(--colors-indigo-light-3)"
  },
  "colors.indigo.light.4": {
    "value": "var(--colors-indigo-light-4)",
    "variable": "var(--colors-indigo-light-4)"
  },
  "colors.indigo.light.5": {
    "value": "var(--colors-indigo-light-5)",
    "variable": "var(--colors-indigo-light-5)"
  },
  "colors.indigo.light.6": {
    "value": "var(--colors-indigo-light-6)",
    "variable": "var(--colors-indigo-light-6)"
  },
  "colors.indigo.light.7": {
    "value": "var(--colors-indigo-light-7)",
    "variable": "var(--colors-indigo-light-7)"
  },
  "colors.indigo.light.8": {
    "value": "var(--colors-indigo-light-8)",
    "variable": "var(--colors-indigo-light-8)"
  },
  "colors.indigo.light.9": {
    "value": "var(--colors-indigo-light-9)",
    "variable": "var(--colors-indigo-light-9)"
  },
  "colors.indigo.light.10": {
    "value": "var(--colors-indigo-light-10)",
    "variable": "var(--colors-indigo-light-10)"
  },
  "colors.indigo.light.11": {
    "value": "var(--colors-indigo-light-11)",
    "variable": "var(--colors-indigo-light-11)"
  },
  "colors.indigo.light.12": {
    "value": "var(--colors-indigo-light-12)",
    "variable": "var(--colors-indigo-light-12)"
  },
  "colors.indigo.light.a.1": {
    "value": "var(--colors-indigo-light-a-1)",
    "variable": "var(--colors-indigo-light-a-1)"
  },
  "colors.indigo.light.a.2": {
    "value": "var(--colors-indigo-light-a-2)",
    "variable": "var(--colors-indigo-light-a-2)"
  },
  "colors.indigo.light.a.3": {
    "value": "var(--colors-indigo-light-a-3)",
    "variable": "var(--colors-indigo-light-a-3)"
  },
  "colors.indigo.light.a.4": {
    "value": "var(--colors-indigo-light-a-4)",
    "variable": "var(--colors-indigo-light-a-4)"
  },
  "colors.indigo.light.a.5": {
    "value": "var(--colors-indigo-light-a-5)",
    "variable": "var(--colors-indigo-light-a-5)"
  },
  "colors.indigo.light.a.6": {
    "value": "var(--colors-indigo-light-a-6)",
    "variable": "var(--colors-indigo-light-a-6)"
  },
  "colors.indigo.light.a.7": {
    "value": "var(--colors-indigo-light-a-7)",
    "variable": "var(--colors-indigo-light-a-7)"
  },
  "colors.indigo.light.a.8": {
    "value": "var(--colors-indigo-light-a-8)",
    "variable": "var(--colors-indigo-light-a-8)"
  },
  "colors.indigo.light.a.9": {
    "value": "var(--colors-indigo-light-a-9)",
    "variable": "var(--colors-indigo-light-a-9)"
  },
  "colors.indigo.light.a.10": {
    "value": "var(--colors-indigo-light-a-10)",
    "variable": "var(--colors-indigo-light-a-10)"
  },
  "colors.indigo.light.a.11": {
    "value": "var(--colors-indigo-light-a-11)",
    "variable": "var(--colors-indigo-light-a-11)"
  },
  "colors.indigo.light.a.12": {
    "value": "var(--colors-indigo-light-a-12)",
    "variable": "var(--colors-indigo-light-a-12)"
  },
  "colors.indigo.light.p3.1": {
    "value": "color(display-p3 0.992 0.992 0.996)",
    "variable": "var(--colors-indigo-light-p3-1)"
  },
  "colors.indigo.light.p3.2": {
    "value": "color(display-p3 0.971 0.977 0.998)",
    "variable": "var(--colors-indigo-light-p3-2)"
  },
  "colors.indigo.light.p3.3": {
    "value": "color(display-p3 0.933 0.948 0.992)",
    "variable": "var(--colors-indigo-light-p3-3)"
  },
  "colors.indigo.light.p3.4": {
    "value": "color(display-p3 0.885 0.914 1)",
    "variable": "var(--colors-indigo-light-p3-4)"
  },
  "colors.indigo.light.p3.5": {
    "value": "color(display-p3 0.831 0.87 1)",
    "variable": "var(--colors-indigo-light-p3-5)"
  },
  "colors.indigo.light.p3.6": {
    "value": "color(display-p3 0.767 0.814 0.995)",
    "variable": "var(--colors-indigo-light-p3-6)"
  },
  "colors.indigo.light.p3.7": {
    "value": "color(display-p3 0.685 0.74 0.957)",
    "variable": "var(--colors-indigo-light-p3-7)"
  },
  "colors.indigo.light.p3.8": {
    "value": "color(display-p3 0.569 0.639 0.916)",
    "variable": "var(--colors-indigo-light-p3-8)"
  },
  "colors.indigo.light.p3.9": {
    "value": "color(display-p3 0.276 0.384 0.837)",
    "variable": "var(--colors-indigo-light-p3-9)"
  },
  "colors.indigo.light.p3.10": {
    "value": "color(display-p3 0.234 0.343 0.801)",
    "variable": "var(--colors-indigo-light-p3-10)"
  },
  "colors.indigo.light.p3.11": {
    "value": "color(display-p3 0.256 0.354 0.755)",
    "variable": "var(--colors-indigo-light-p3-11)"
  },
  "colors.indigo.light.p3.12": {
    "value": "color(display-p3 0.133 0.175 0.348)",
    "variable": "var(--colors-indigo-light-p3-12)"
  },
  "colors.indigo.light.p3.a.1": {
    "value": "color(display-p3 0.02 0.02 0.51 / 0.008)",
    "variable": "var(--colors-indigo-light-p3-a-1)"
  },
  "colors.indigo.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.161 0.863 / 0.028)",
    "variable": "var(--colors-indigo-light-p3-a-2)"
  },
  "colors.indigo.light.p3.a.3": {
    "value": "color(display-p3 0.008 0.239 0.886 / 0.067)",
    "variable": "var(--colors-indigo-light-p3-a-3)"
  },
  "colors.indigo.light.p3.a.4": {
    "value": "color(display-p3 0.004 0.247 1 / 0.114)",
    "variable": "var(--colors-indigo-light-p3-a-4)"
  },
  "colors.indigo.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.235 1 / 0.169)",
    "variable": "var(--colors-indigo-light-p3-a-5)"
  },
  "colors.indigo.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.208 0.984 / 0.232)",
    "variable": "var(--colors-indigo-light-p3-a-6)"
  },
  "colors.indigo.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.176 0.863 / 0.314)",
    "variable": "var(--colors-indigo-light-p3-a-7)"
  },
  "colors.indigo.light.p3.a.8": {
    "value": "color(display-p3 0.004 0.165 0.812 / 0.432)",
    "variable": "var(--colors-indigo-light-p3-a-8)"
  },
  "colors.indigo.light.p3.a.9": {
    "value": "color(display-p3 0 0.153 0.773 / 0.726)",
    "variable": "var(--colors-indigo-light-p3-a-9)"
  },
  "colors.indigo.light.p3.a.10": {
    "value": "color(display-p3 0 0.137 0.737 / 0.765)",
    "variable": "var(--colors-indigo-light-p3-a-10)"
  },
  "colors.indigo.light.p3.a.11": {
    "value": "color(display-p3 0.256 0.354 0.755)",
    "variable": "var(--colors-indigo-light-p3-a-11)"
  },
  "colors.indigo.light.p3.a.12": {
    "value": "color(display-p3 0.133 0.175 0.348)",
    "variable": "var(--colors-indigo-light-p3-a-12)"
  },
  "colors.indigo.dark.1": {
    "value": "var(--colors-indigo-dark-1)",
    "variable": "var(--colors-indigo-dark-1)"
  },
  "colors.indigo.dark.2": {
    "value": "var(--colors-indigo-dark-2)",
    "variable": "var(--colors-indigo-dark-2)"
  },
  "colors.indigo.dark.3": {
    "value": "var(--colors-indigo-dark-3)",
    "variable": "var(--colors-indigo-dark-3)"
  },
  "colors.indigo.dark.4": {
    "value": "var(--colors-indigo-dark-4)",
    "variable": "var(--colors-indigo-dark-4)"
  },
  "colors.indigo.dark.5": {
    "value": "var(--colors-indigo-dark-5)",
    "variable": "var(--colors-indigo-dark-5)"
  },
  "colors.indigo.dark.6": {
    "value": "var(--colors-indigo-dark-6)",
    "variable": "var(--colors-indigo-dark-6)"
  },
  "colors.indigo.dark.7": {
    "value": "var(--colors-indigo-dark-7)",
    "variable": "var(--colors-indigo-dark-7)"
  },
  "colors.indigo.dark.8": {
    "value": "var(--colors-indigo-dark-8)",
    "variable": "var(--colors-indigo-dark-8)"
  },
  "colors.indigo.dark.9": {
    "value": "var(--colors-indigo-dark-9)",
    "variable": "var(--colors-indigo-dark-9)"
  },
  "colors.indigo.dark.10": {
    "value": "var(--colors-indigo-dark-10)",
    "variable": "var(--colors-indigo-dark-10)"
  },
  "colors.indigo.dark.11": {
    "value": "var(--colors-indigo-dark-11)",
    "variable": "var(--colors-indigo-dark-11)"
  },
  "colors.indigo.dark.12": {
    "value": "var(--colors-indigo-dark-12)",
    "variable": "var(--colors-indigo-dark-12)"
  },
  "colors.indigo.dark.a.1": {
    "value": "var(--colors-indigo-dark-a-1)",
    "variable": "var(--colors-indigo-dark-a-1)"
  },
  "colors.indigo.dark.a.2": {
    "value": "var(--colors-indigo-dark-a-2)",
    "variable": "var(--colors-indigo-dark-a-2)"
  },
  "colors.indigo.dark.a.3": {
    "value": "var(--colors-indigo-dark-a-3)",
    "variable": "var(--colors-indigo-dark-a-3)"
  },
  "colors.indigo.dark.a.4": {
    "value": "var(--colors-indigo-dark-a-4)",
    "variable": "var(--colors-indigo-dark-a-4)"
  },
  "colors.indigo.dark.a.5": {
    "value": "var(--colors-indigo-dark-a-5)",
    "variable": "var(--colors-indigo-dark-a-5)"
  },
  "colors.indigo.dark.a.6": {
    "value": "var(--colors-indigo-dark-a-6)",
    "variable": "var(--colors-indigo-dark-a-6)"
  },
  "colors.indigo.dark.a.7": {
    "value": "var(--colors-indigo-dark-a-7)",
    "variable": "var(--colors-indigo-dark-a-7)"
  },
  "colors.indigo.dark.a.8": {
    "value": "var(--colors-indigo-dark-a-8)",
    "variable": "var(--colors-indigo-dark-a-8)"
  },
  "colors.indigo.dark.a.9": {
    "value": "var(--colors-indigo-dark-a-9)",
    "variable": "var(--colors-indigo-dark-a-9)"
  },
  "colors.indigo.dark.a.10": {
    "value": "var(--colors-indigo-dark-a-10)",
    "variable": "var(--colors-indigo-dark-a-10)"
  },
  "colors.indigo.dark.a.11": {
    "value": "var(--colors-indigo-dark-a-11)",
    "variable": "var(--colors-indigo-dark-a-11)"
  },
  "colors.indigo.dark.a.12": {
    "value": "var(--colors-indigo-dark-a-12)",
    "variable": "var(--colors-indigo-dark-a-12)"
  },
  "colors.indigo.dark.p3.1": {
    "value": "color(display-p3 0.068 0.074 0.118)",
    "variable": "var(--colors-indigo-dark-p3-1)"
  },
  "colors.indigo.dark.p3.2": {
    "value": "color(display-p3 0.081 0.089 0.144)",
    "variable": "var(--colors-indigo-dark-p3-2)"
  },
  "colors.indigo.dark.p3.3": {
    "value": "color(display-p3 0.105 0.141 0.275)",
    "variable": "var(--colors-indigo-dark-p3-3)"
  },
  "colors.indigo.dark.p3.4": {
    "value": "color(display-p3 0.129 0.18 0.369)",
    "variable": "var(--colors-indigo-dark-p3-4)"
  },
  "colors.indigo.dark.p3.5": {
    "value": "color(display-p3 0.163 0.22 0.439)",
    "variable": "var(--colors-indigo-dark-p3-5)"
  },
  "colors.indigo.dark.p3.6": {
    "value": "color(display-p3 0.203 0.262 0.5)",
    "variable": "var(--colors-indigo-dark-p3-6)"
  },
  "colors.indigo.dark.p3.7": {
    "value": "color(display-p3 0.245 0.309 0.575)",
    "variable": "var(--colors-indigo-dark-p3-7)"
  },
  "colors.indigo.dark.p3.8": {
    "value": "color(display-p3 0.285 0.362 0.674)",
    "variable": "var(--colors-indigo-dark-p3-8)"
  },
  "colors.indigo.dark.p3.9": {
    "value": "color(display-p3 0.276 0.384 0.837)",
    "variable": "var(--colors-indigo-dark-p3-9)"
  },
  "colors.indigo.dark.p3.10": {
    "value": "color(display-p3 0.354 0.445 0.866)",
    "variable": "var(--colors-indigo-dark-p3-10)"
  },
  "colors.indigo.dark.p3.11": {
    "value": "color(display-p3 0.63 0.69 1)",
    "variable": "var(--colors-indigo-dark-p3-11)"
  },
  "colors.indigo.dark.p3.12": {
    "value": "color(display-p3 0.848 0.881 0.99)",
    "variable": "var(--colors-indigo-dark-p3-12)"
  },
  "colors.indigo.dark.p3.a.1": {
    "value": "color(display-p3 0.071 0.212 0.996 / 0.055)",
    "variable": "var(--colors-indigo-dark-p3-a-1)"
  },
  "colors.indigo.dark.p3.a.2": {
    "value": "color(display-p3 0.251 0.345 0.988 / 0.085)",
    "variable": "var(--colors-indigo-dark-p3-a-2)"
  },
  "colors.indigo.dark.p3.a.3": {
    "value": "color(display-p3 0.243 0.404 1 / 0.223)",
    "variable": "var(--colors-indigo-dark-p3-a-3)"
  },
  "colors.indigo.dark.p3.a.4": {
    "value": "color(display-p3 0.263 0.42 1 / 0.324)",
    "variable": "var(--colors-indigo-dark-p3-a-4)"
  },
  "colors.indigo.dark.p3.a.5": {
    "value": "color(display-p3 0.314 0.451 1 / 0.4)",
    "variable": "var(--colors-indigo-dark-p3-a-5)"
  },
  "colors.indigo.dark.p3.a.6": {
    "value": "color(display-p3 0.361 0.49 1 / 0.467)",
    "variable": "var(--colors-indigo-dark-p3-a-6)"
  },
  "colors.indigo.dark.p3.a.7": {
    "value": "color(display-p3 0.388 0.51 1 / 0.547)",
    "variable": "var(--colors-indigo-dark-p3-a-7)"
  },
  "colors.indigo.dark.p3.a.8": {
    "value": "color(display-p3 0.404 0.518 1 / 0.652)",
    "variable": "var(--colors-indigo-dark-p3-a-8)"
  },
  "colors.indigo.dark.p3.a.9": {
    "value": "color(display-p3 0.318 0.451 1 / 0.824)",
    "variable": "var(--colors-indigo-dark-p3-a-9)"
  },
  "colors.indigo.dark.p3.a.10": {
    "value": "color(display-p3 0.404 0.506 1 / 0.858)",
    "variable": "var(--colors-indigo-dark-p3-a-10)"
  },
  "colors.indigo.dark.p3.a.11": {
    "value": "color(display-p3 0.63 0.69 1)",
    "variable": "var(--colors-indigo-dark-p3-a-11)"
  },
  "colors.indigo.dark.p3.a.12": {
    "value": "color(display-p3 0.848 0.881 0.99)",
    "variable": "var(--colors-indigo-dark-p3-a-12)"
  },
  "colors.indigo.a.1": {
    "value": "var(--colors-indigo-a-1)",
    "variable": "var(--colors-indigo-a-1)"
  },
  "colors.indigo.a.2": {
    "value": "var(--colors-indigo-a-2)",
    "variable": "var(--colors-indigo-a-2)"
  },
  "colors.indigo.a.3": {
    "value": "var(--colors-indigo-a-3)",
    "variable": "var(--colors-indigo-a-3)"
  },
  "colors.indigo.a.4": {
    "value": "var(--colors-indigo-a-4)",
    "variable": "var(--colors-indigo-a-4)"
  },
  "colors.indigo.a.5": {
    "value": "var(--colors-indigo-a-5)",
    "variable": "var(--colors-indigo-a-5)"
  },
  "colors.indigo.a.6": {
    "value": "var(--colors-indigo-a-6)",
    "variable": "var(--colors-indigo-a-6)"
  },
  "colors.indigo.a.7": {
    "value": "var(--colors-indigo-a-7)",
    "variable": "var(--colors-indigo-a-7)"
  },
  "colors.indigo.a.8": {
    "value": "var(--colors-indigo-a-8)",
    "variable": "var(--colors-indigo-a-8)"
  },
  "colors.indigo.a.9": {
    "value": "var(--colors-indigo-a-9)",
    "variable": "var(--colors-indigo-a-9)"
  },
  "colors.indigo.a.10": {
    "value": "var(--colors-indigo-a-10)",
    "variable": "var(--colors-indigo-a-10)"
  },
  "colors.indigo.a.11": {
    "value": "var(--colors-indigo-a-11)",
    "variable": "var(--colors-indigo-a-11)"
  },
  "colors.indigo.a.12": {
    "value": "var(--colors-indigo-a-12)",
    "variable": "var(--colors-indigo-a-12)"
  },
  "colors.indigo.p3.1": {
    "value": "var(--colors-indigo-p3-1)",
    "variable": "var(--colors-indigo-p3-1)"
  },
  "colors.indigo.p3.2": {
    "value": "var(--colors-indigo-p3-2)",
    "variable": "var(--colors-indigo-p3-2)"
  },
  "colors.indigo.p3.3": {
    "value": "var(--colors-indigo-p3-3)",
    "variable": "var(--colors-indigo-p3-3)"
  },
  "colors.indigo.p3.4": {
    "value": "var(--colors-indigo-p3-4)",
    "variable": "var(--colors-indigo-p3-4)"
  },
  "colors.indigo.p3.5": {
    "value": "var(--colors-indigo-p3-5)",
    "variable": "var(--colors-indigo-p3-5)"
  },
  "colors.indigo.p3.6": {
    "value": "var(--colors-indigo-p3-6)",
    "variable": "var(--colors-indigo-p3-6)"
  },
  "colors.indigo.p3.7": {
    "value": "var(--colors-indigo-p3-7)",
    "variable": "var(--colors-indigo-p3-7)"
  },
  "colors.indigo.p3.8": {
    "value": "var(--colors-indigo-p3-8)",
    "variable": "var(--colors-indigo-p3-8)"
  },
  "colors.indigo.p3.9": {
    "value": "var(--colors-indigo-p3-9)",
    "variable": "var(--colors-indigo-p3-9)"
  },
  "colors.indigo.p3.10": {
    "value": "var(--colors-indigo-p3-10)",
    "variable": "var(--colors-indigo-p3-10)"
  },
  "colors.indigo.p3.11": {
    "value": "var(--colors-indigo-p3-11)",
    "variable": "var(--colors-indigo-p3-11)"
  },
  "colors.indigo.p3.12": {
    "value": "var(--colors-indigo-p3-12)",
    "variable": "var(--colors-indigo-p3-12)"
  },
  "colors.indigo.p3.a.1": {
    "value": "var(--colors-indigo-p3-a-1)",
    "variable": "var(--colors-indigo-p3-a-1)"
  },
  "colors.indigo.p3.a.2": {
    "value": "var(--colors-indigo-p3-a-2)",
    "variable": "var(--colors-indigo-p3-a-2)"
  },
  "colors.indigo.p3.a.3": {
    "value": "var(--colors-indigo-p3-a-3)",
    "variable": "var(--colors-indigo-p3-a-3)"
  },
  "colors.indigo.p3.a.4": {
    "value": "var(--colors-indigo-p3-a-4)",
    "variable": "var(--colors-indigo-p3-a-4)"
  },
  "colors.indigo.p3.a.5": {
    "value": "var(--colors-indigo-p3-a-5)",
    "variable": "var(--colors-indigo-p3-a-5)"
  },
  "colors.indigo.p3.a.6": {
    "value": "var(--colors-indigo-p3-a-6)",
    "variable": "var(--colors-indigo-p3-a-6)"
  },
  "colors.indigo.p3.a.7": {
    "value": "var(--colors-indigo-p3-a-7)",
    "variable": "var(--colors-indigo-p3-a-7)"
  },
  "colors.indigo.p3.a.8": {
    "value": "var(--colors-indigo-p3-a-8)",
    "variable": "var(--colors-indigo-p3-a-8)"
  },
  "colors.indigo.p3.a.9": {
    "value": "var(--colors-indigo-p3-a-9)",
    "variable": "var(--colors-indigo-p3-a-9)"
  },
  "colors.indigo.p3.a.10": {
    "value": "var(--colors-indigo-p3-a-10)",
    "variable": "var(--colors-indigo-p3-a-10)"
  },
  "colors.indigo.p3.a.11": {
    "value": "var(--colors-indigo-p3-a-11)",
    "variable": "var(--colors-indigo-p3-a-11)"
  },
  "colors.indigo.p3.a.12": {
    "value": "var(--colors-indigo-p3-a-12)",
    "variable": "var(--colors-indigo-p3-a-12)"
  },
  "colors.iris.1": {
    "value": "var(--colors-iris-1)",
    "variable": "var(--colors-iris-1)"
  },
  "colors.iris.2": {
    "value": "var(--colors-iris-2)",
    "variable": "var(--colors-iris-2)"
  },
  "colors.iris.3": {
    "value": "var(--colors-iris-3)",
    "variable": "var(--colors-iris-3)"
  },
  "colors.iris.4": {
    "value": "var(--colors-iris-4)",
    "variable": "var(--colors-iris-4)"
  },
  "colors.iris.5": {
    "value": "var(--colors-iris-5)",
    "variable": "var(--colors-iris-5)"
  },
  "colors.iris.6": {
    "value": "var(--colors-iris-6)",
    "variable": "var(--colors-iris-6)"
  },
  "colors.iris.7": {
    "value": "var(--colors-iris-7)",
    "variable": "var(--colors-iris-7)"
  },
  "colors.iris.8": {
    "value": "var(--colors-iris-8)",
    "variable": "var(--colors-iris-8)"
  },
  "colors.iris.9": {
    "value": "var(--colors-iris-9)",
    "variable": "var(--colors-iris-9)"
  },
  "colors.iris.10": {
    "value": "var(--colors-iris-10)",
    "variable": "var(--colors-iris-10)"
  },
  "colors.iris.11": {
    "value": "var(--colors-iris-11)",
    "variable": "var(--colors-iris-11)"
  },
  "colors.iris.12": {
    "value": "var(--colors-iris-12)",
    "variable": "var(--colors-iris-12)"
  },
  "colors.iris.light.1": {
    "value": "var(--colors-iris-light-1)",
    "variable": "var(--colors-iris-light-1)"
  },
  "colors.iris.light.2": {
    "value": "var(--colors-iris-light-2)",
    "variable": "var(--colors-iris-light-2)"
  },
  "colors.iris.light.3": {
    "value": "var(--colors-iris-light-3)",
    "variable": "var(--colors-iris-light-3)"
  },
  "colors.iris.light.4": {
    "value": "var(--colors-iris-light-4)",
    "variable": "var(--colors-iris-light-4)"
  },
  "colors.iris.light.5": {
    "value": "var(--colors-iris-light-5)",
    "variable": "var(--colors-iris-light-5)"
  },
  "colors.iris.light.6": {
    "value": "var(--colors-iris-light-6)",
    "variable": "var(--colors-iris-light-6)"
  },
  "colors.iris.light.7": {
    "value": "var(--colors-iris-light-7)",
    "variable": "var(--colors-iris-light-7)"
  },
  "colors.iris.light.8": {
    "value": "var(--colors-iris-light-8)",
    "variable": "var(--colors-iris-light-8)"
  },
  "colors.iris.light.9": {
    "value": "var(--colors-iris-light-9)",
    "variable": "var(--colors-iris-light-9)"
  },
  "colors.iris.light.10": {
    "value": "var(--colors-iris-light-10)",
    "variable": "var(--colors-iris-light-10)"
  },
  "colors.iris.light.11": {
    "value": "var(--colors-iris-light-11)",
    "variable": "var(--colors-iris-light-11)"
  },
  "colors.iris.light.12": {
    "value": "var(--colors-iris-light-12)",
    "variable": "var(--colors-iris-light-12)"
  },
  "colors.iris.light.a.1": {
    "value": "var(--colors-iris-light-a-1)",
    "variable": "var(--colors-iris-light-a-1)"
  },
  "colors.iris.light.a.2": {
    "value": "var(--colors-iris-light-a-2)",
    "variable": "var(--colors-iris-light-a-2)"
  },
  "colors.iris.light.a.3": {
    "value": "var(--colors-iris-light-a-3)",
    "variable": "var(--colors-iris-light-a-3)"
  },
  "colors.iris.light.a.4": {
    "value": "var(--colors-iris-light-a-4)",
    "variable": "var(--colors-iris-light-a-4)"
  },
  "colors.iris.light.a.5": {
    "value": "var(--colors-iris-light-a-5)",
    "variable": "var(--colors-iris-light-a-5)"
  },
  "colors.iris.light.a.6": {
    "value": "var(--colors-iris-light-a-6)",
    "variable": "var(--colors-iris-light-a-6)"
  },
  "colors.iris.light.a.7": {
    "value": "var(--colors-iris-light-a-7)",
    "variable": "var(--colors-iris-light-a-7)"
  },
  "colors.iris.light.a.8": {
    "value": "var(--colors-iris-light-a-8)",
    "variable": "var(--colors-iris-light-a-8)"
  },
  "colors.iris.light.a.9": {
    "value": "var(--colors-iris-light-a-9)",
    "variable": "var(--colors-iris-light-a-9)"
  },
  "colors.iris.light.a.10": {
    "value": "var(--colors-iris-light-a-10)",
    "variable": "var(--colors-iris-light-a-10)"
  },
  "colors.iris.light.a.11": {
    "value": "var(--colors-iris-light-a-11)",
    "variable": "var(--colors-iris-light-a-11)"
  },
  "colors.iris.light.a.12": {
    "value": "var(--colors-iris-light-a-12)",
    "variable": "var(--colors-iris-light-a-12)"
  },
  "colors.iris.light.p3.1": {
    "value": "color(display-p3 0.992 0.992 0.999)",
    "variable": "var(--colors-iris-light-p3-1)"
  },
  "colors.iris.light.p3.2": {
    "value": "color(display-p3 0.972 0.973 0.998)",
    "variable": "var(--colors-iris-light-p3-2)"
  },
  "colors.iris.light.p3.3": {
    "value": "color(display-p3 0.943 0.945 0.992)",
    "variable": "var(--colors-iris-light-p3-3)"
  },
  "colors.iris.light.p3.4": {
    "value": "color(display-p3 0.902 0.906 1)",
    "variable": "var(--colors-iris-light-p3-4)"
  },
  "colors.iris.light.p3.5": {
    "value": "color(display-p3 0.857 0.861 1)",
    "variable": "var(--colors-iris-light-p3-5)"
  },
  "colors.iris.light.p3.6": {
    "value": "color(display-p3 0.799 0.805 0.987)",
    "variable": "var(--colors-iris-light-p3-6)"
  },
  "colors.iris.light.p3.7": {
    "value": "color(display-p3 0.721 0.727 0.955)",
    "variable": "var(--colors-iris-light-p3-7)"
  },
  "colors.iris.light.p3.8": {
    "value": "color(display-p3 0.61 0.619 0.918)",
    "variable": "var(--colors-iris-light-p3-8)"
  },
  "colors.iris.light.p3.9": {
    "value": "color(display-p3 0.357 0.357 0.81)",
    "variable": "var(--colors-iris-light-p3-9)"
  },
  "colors.iris.light.p3.10": {
    "value": "color(display-p3 0.318 0.318 0.774)",
    "variable": "var(--colors-iris-light-p3-10)"
  },
  "colors.iris.light.p3.11": {
    "value": "color(display-p3 0.337 0.326 0.748)",
    "variable": "var(--colors-iris-light-p3-11)"
  },
  "colors.iris.light.p3.12": {
    "value": "color(display-p3 0.154 0.161 0.371)",
    "variable": "var(--colors-iris-light-p3-12)"
  },
  "colors.iris.light.p3.a.1": {
    "value": "color(display-p3 0.02 0.02 1 / 0.008)",
    "variable": "var(--colors-iris-light-p3-a-1)"
  },
  "colors.iris.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.024 0.863 / 0.028)",
    "variable": "var(--colors-iris-light-p3-a-2)"
  },
  "colors.iris.light.p3.a.3": {
    "value": "color(display-p3 0.004 0.071 0.871 / 0.059)",
    "variable": "var(--colors-iris-light-p3-a-3)"
  },
  "colors.iris.light.p3.a.4": {
    "value": "color(display-p3 0.012 0.051 1 / 0.099)",
    "variable": "var(--colors-iris-light-p3-a-4)"
  },
  "colors.iris.light.p3.a.5": {
    "value": "color(display-p3 0.008 0.035 1 / 0.142)",
    "variable": "var(--colors-iris-light-p3-a-5)"
  },
  "colors.iris.light.p3.a.6": {
    "value": "color(display-p3 0 0.02 0.941 / 0.2)",
    "variable": "var(--colors-iris-light-p3-a-6)"
  },
  "colors.iris.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.02 0.847 / 0.279)",
    "variable": "var(--colors-iris-light-p3-a-7)"
  },
  "colors.iris.light.p3.a.8": {
    "value": "color(display-p3 0.004 0.024 0.788 / 0.389)",
    "variable": "var(--colors-iris-light-p3-a-8)"
  },
  "colors.iris.light.p3.a.9": {
    "value": "color(display-p3 0 0 0.706 / 0.644)",
    "variable": "var(--colors-iris-light-p3-a-9)"
  },
  "colors.iris.light.p3.a.10": {
    "value": "color(display-p3 0 0 0.667 / 0.683)",
    "variable": "var(--colors-iris-light-p3-a-10)"
  },
  "colors.iris.light.p3.a.11": {
    "value": "color(display-p3 0.337 0.326 0.748)",
    "variable": "var(--colors-iris-light-p3-a-11)"
  },
  "colors.iris.light.p3.a.12": {
    "value": "color(display-p3 0.154 0.161 0.371)",
    "variable": "var(--colors-iris-light-p3-a-12)"
  },
  "colors.iris.dark.1": {
    "value": "var(--colors-iris-dark-1)",
    "variable": "var(--colors-iris-dark-1)"
  },
  "colors.iris.dark.2": {
    "value": "var(--colors-iris-dark-2)",
    "variable": "var(--colors-iris-dark-2)"
  },
  "colors.iris.dark.3": {
    "value": "var(--colors-iris-dark-3)",
    "variable": "var(--colors-iris-dark-3)"
  },
  "colors.iris.dark.4": {
    "value": "var(--colors-iris-dark-4)",
    "variable": "var(--colors-iris-dark-4)"
  },
  "colors.iris.dark.5": {
    "value": "var(--colors-iris-dark-5)",
    "variable": "var(--colors-iris-dark-5)"
  },
  "colors.iris.dark.6": {
    "value": "var(--colors-iris-dark-6)",
    "variable": "var(--colors-iris-dark-6)"
  },
  "colors.iris.dark.7": {
    "value": "var(--colors-iris-dark-7)",
    "variable": "var(--colors-iris-dark-7)"
  },
  "colors.iris.dark.8": {
    "value": "var(--colors-iris-dark-8)",
    "variable": "var(--colors-iris-dark-8)"
  },
  "colors.iris.dark.9": {
    "value": "var(--colors-iris-dark-9)",
    "variable": "var(--colors-iris-dark-9)"
  },
  "colors.iris.dark.10": {
    "value": "var(--colors-iris-dark-10)",
    "variable": "var(--colors-iris-dark-10)"
  },
  "colors.iris.dark.11": {
    "value": "var(--colors-iris-dark-11)",
    "variable": "var(--colors-iris-dark-11)"
  },
  "colors.iris.dark.12": {
    "value": "var(--colors-iris-dark-12)",
    "variable": "var(--colors-iris-dark-12)"
  },
  "colors.iris.dark.a.1": {
    "value": "var(--colors-iris-dark-a-1)",
    "variable": "var(--colors-iris-dark-a-1)"
  },
  "colors.iris.dark.a.2": {
    "value": "var(--colors-iris-dark-a-2)",
    "variable": "var(--colors-iris-dark-a-2)"
  },
  "colors.iris.dark.a.3": {
    "value": "var(--colors-iris-dark-a-3)",
    "variable": "var(--colors-iris-dark-a-3)"
  },
  "colors.iris.dark.a.4": {
    "value": "var(--colors-iris-dark-a-4)",
    "variable": "var(--colors-iris-dark-a-4)"
  },
  "colors.iris.dark.a.5": {
    "value": "var(--colors-iris-dark-a-5)",
    "variable": "var(--colors-iris-dark-a-5)"
  },
  "colors.iris.dark.a.6": {
    "value": "var(--colors-iris-dark-a-6)",
    "variable": "var(--colors-iris-dark-a-6)"
  },
  "colors.iris.dark.a.7": {
    "value": "var(--colors-iris-dark-a-7)",
    "variable": "var(--colors-iris-dark-a-7)"
  },
  "colors.iris.dark.a.8": {
    "value": "var(--colors-iris-dark-a-8)",
    "variable": "var(--colors-iris-dark-a-8)"
  },
  "colors.iris.dark.a.9": {
    "value": "var(--colors-iris-dark-a-9)",
    "variable": "var(--colors-iris-dark-a-9)"
  },
  "colors.iris.dark.a.10": {
    "value": "var(--colors-iris-dark-a-10)",
    "variable": "var(--colors-iris-dark-a-10)"
  },
  "colors.iris.dark.a.11": {
    "value": "var(--colors-iris-dark-a-11)",
    "variable": "var(--colors-iris-dark-a-11)"
  },
  "colors.iris.dark.a.12": {
    "value": "var(--colors-iris-dark-a-12)",
    "variable": "var(--colors-iris-dark-a-12)"
  },
  "colors.iris.dark.p3.1": {
    "value": "color(display-p3 0.075 0.075 0.114)",
    "variable": "var(--colors-iris-dark-p3-1)"
  },
  "colors.iris.dark.p3.2": {
    "value": "color(display-p3 0.089 0.086 0.14)",
    "variable": "var(--colors-iris-dark-p3-2)"
  },
  "colors.iris.dark.p3.3": {
    "value": "color(display-p3 0.128 0.134 0.272)",
    "variable": "var(--colors-iris-dark-p3-3)"
  },
  "colors.iris.dark.p3.4": {
    "value": "color(display-p3 0.153 0.165 0.382)",
    "variable": "var(--colors-iris-dark-p3-4)"
  },
  "colors.iris.dark.p3.5": {
    "value": "color(display-p3 0.192 0.201 0.44)",
    "variable": "var(--colors-iris-dark-p3-5)"
  },
  "colors.iris.dark.p3.6": {
    "value": "color(display-p3 0.239 0.241 0.491)",
    "variable": "var(--colors-iris-dark-p3-6)"
  },
  "colors.iris.dark.p3.7": {
    "value": "color(display-p3 0.291 0.289 0.565)",
    "variable": "var(--colors-iris-dark-p3-7)"
  },
  "colors.iris.dark.p3.8": {
    "value": "color(display-p3 0.35 0.345 0.673)",
    "variable": "var(--colors-iris-dark-p3-8)"
  },
  "colors.iris.dark.p3.9": {
    "value": "color(display-p3 0.357 0.357 0.81)",
    "variable": "var(--colors-iris-dark-p3-9)"
  },
  "colors.iris.dark.p3.10": {
    "value": "color(display-p3 0.428 0.416 0.843)",
    "variable": "var(--colors-iris-dark-p3-10)"
  },
  "colors.iris.dark.p3.11": {
    "value": "color(display-p3 0.685 0.662 1)",
    "variable": "var(--colors-iris-dark-p3-11)"
  },
  "colors.iris.dark.p3.12": {
    "value": "color(display-p3 0.878 0.875 0.986)",
    "variable": "var(--colors-iris-dark-p3-12)"
  },
  "colors.iris.dark.p3.a.1": {
    "value": "color(display-p3 0.224 0.224 0.992 / 0.051)",
    "variable": "var(--colors-iris-dark-p3-a-1)"
  },
  "colors.iris.dark.p3.a.2": {
    "value": "color(display-p3 0.361 0.314 1 / 0.08)",
    "variable": "var(--colors-iris-dark-p3-a-2)"
  },
  "colors.iris.dark.p3.a.3": {
    "value": "color(display-p3 0.357 0.373 1 / 0.219)",
    "variable": "var(--colors-iris-dark-p3-a-3)"
  },
  "colors.iris.dark.p3.a.4": {
    "value": "color(display-p3 0.325 0.361 1 / 0.337)",
    "variable": "var(--colors-iris-dark-p3-a-4)"
  },
  "colors.iris.dark.p3.a.5": {
    "value": "color(display-p3 0.38 0.4 1 / 0.4)",
    "variable": "var(--colors-iris-dark-p3-a-5)"
  },
  "colors.iris.dark.p3.a.6": {
    "value": "color(display-p3 0.447 0.447 1 / 0.454)",
    "variable": "var(--colors-iris-dark-p3-a-6)"
  },
  "colors.iris.dark.p3.a.7": {
    "value": "color(display-p3 0.486 0.486 1 / 0.534)",
    "variable": "var(--colors-iris-dark-p3-a-7)"
  },
  "colors.iris.dark.p3.a.8": {
    "value": "color(display-p3 0.502 0.494 1 / 0.652)",
    "variable": "var(--colors-iris-dark-p3-a-8)"
  },
  "colors.iris.dark.p3.a.9": {
    "value": "color(display-p3 0.431 0.431 1 / 0.799)",
    "variable": "var(--colors-iris-dark-p3-a-9)"
  },
  "colors.iris.dark.p3.a.10": {
    "value": "color(display-p3 0.502 0.486 1 / 0.832)",
    "variable": "var(--colors-iris-dark-p3-a-10)"
  },
  "colors.iris.dark.p3.a.11": {
    "value": "color(display-p3 0.685 0.662 1)",
    "variable": "var(--colors-iris-dark-p3-a-11)"
  },
  "colors.iris.dark.p3.a.12": {
    "value": "color(display-p3 0.878 0.875 0.986)",
    "variable": "var(--colors-iris-dark-p3-a-12)"
  },
  "colors.iris.a.1": {
    "value": "var(--colors-iris-a-1)",
    "variable": "var(--colors-iris-a-1)"
  },
  "colors.iris.a.2": {
    "value": "var(--colors-iris-a-2)",
    "variable": "var(--colors-iris-a-2)"
  },
  "colors.iris.a.3": {
    "value": "var(--colors-iris-a-3)",
    "variable": "var(--colors-iris-a-3)"
  },
  "colors.iris.a.4": {
    "value": "var(--colors-iris-a-4)",
    "variable": "var(--colors-iris-a-4)"
  },
  "colors.iris.a.5": {
    "value": "var(--colors-iris-a-5)",
    "variable": "var(--colors-iris-a-5)"
  },
  "colors.iris.a.6": {
    "value": "var(--colors-iris-a-6)",
    "variable": "var(--colors-iris-a-6)"
  },
  "colors.iris.a.7": {
    "value": "var(--colors-iris-a-7)",
    "variable": "var(--colors-iris-a-7)"
  },
  "colors.iris.a.8": {
    "value": "var(--colors-iris-a-8)",
    "variable": "var(--colors-iris-a-8)"
  },
  "colors.iris.a.9": {
    "value": "var(--colors-iris-a-9)",
    "variable": "var(--colors-iris-a-9)"
  },
  "colors.iris.a.10": {
    "value": "var(--colors-iris-a-10)",
    "variable": "var(--colors-iris-a-10)"
  },
  "colors.iris.a.11": {
    "value": "var(--colors-iris-a-11)",
    "variable": "var(--colors-iris-a-11)"
  },
  "colors.iris.a.12": {
    "value": "var(--colors-iris-a-12)",
    "variable": "var(--colors-iris-a-12)"
  },
  "colors.iris.p3.1": {
    "value": "var(--colors-iris-p3-1)",
    "variable": "var(--colors-iris-p3-1)"
  },
  "colors.iris.p3.2": {
    "value": "var(--colors-iris-p3-2)",
    "variable": "var(--colors-iris-p3-2)"
  },
  "colors.iris.p3.3": {
    "value": "var(--colors-iris-p3-3)",
    "variable": "var(--colors-iris-p3-3)"
  },
  "colors.iris.p3.4": {
    "value": "var(--colors-iris-p3-4)",
    "variable": "var(--colors-iris-p3-4)"
  },
  "colors.iris.p3.5": {
    "value": "var(--colors-iris-p3-5)",
    "variable": "var(--colors-iris-p3-5)"
  },
  "colors.iris.p3.6": {
    "value": "var(--colors-iris-p3-6)",
    "variable": "var(--colors-iris-p3-6)"
  },
  "colors.iris.p3.7": {
    "value": "var(--colors-iris-p3-7)",
    "variable": "var(--colors-iris-p3-7)"
  },
  "colors.iris.p3.8": {
    "value": "var(--colors-iris-p3-8)",
    "variable": "var(--colors-iris-p3-8)"
  },
  "colors.iris.p3.9": {
    "value": "var(--colors-iris-p3-9)",
    "variable": "var(--colors-iris-p3-9)"
  },
  "colors.iris.p3.10": {
    "value": "var(--colors-iris-p3-10)",
    "variable": "var(--colors-iris-p3-10)"
  },
  "colors.iris.p3.11": {
    "value": "var(--colors-iris-p3-11)",
    "variable": "var(--colors-iris-p3-11)"
  },
  "colors.iris.p3.12": {
    "value": "var(--colors-iris-p3-12)",
    "variable": "var(--colors-iris-p3-12)"
  },
  "colors.iris.p3.a.1": {
    "value": "var(--colors-iris-p3-a-1)",
    "variable": "var(--colors-iris-p3-a-1)"
  },
  "colors.iris.p3.a.2": {
    "value": "var(--colors-iris-p3-a-2)",
    "variable": "var(--colors-iris-p3-a-2)"
  },
  "colors.iris.p3.a.3": {
    "value": "var(--colors-iris-p3-a-3)",
    "variable": "var(--colors-iris-p3-a-3)"
  },
  "colors.iris.p3.a.4": {
    "value": "var(--colors-iris-p3-a-4)",
    "variable": "var(--colors-iris-p3-a-4)"
  },
  "colors.iris.p3.a.5": {
    "value": "var(--colors-iris-p3-a-5)",
    "variable": "var(--colors-iris-p3-a-5)"
  },
  "colors.iris.p3.a.6": {
    "value": "var(--colors-iris-p3-a-6)",
    "variable": "var(--colors-iris-p3-a-6)"
  },
  "colors.iris.p3.a.7": {
    "value": "var(--colors-iris-p3-a-7)",
    "variable": "var(--colors-iris-p3-a-7)"
  },
  "colors.iris.p3.a.8": {
    "value": "var(--colors-iris-p3-a-8)",
    "variable": "var(--colors-iris-p3-a-8)"
  },
  "colors.iris.p3.a.9": {
    "value": "var(--colors-iris-p3-a-9)",
    "variable": "var(--colors-iris-p3-a-9)"
  },
  "colors.iris.p3.a.10": {
    "value": "var(--colors-iris-p3-a-10)",
    "variable": "var(--colors-iris-p3-a-10)"
  },
  "colors.iris.p3.a.11": {
    "value": "var(--colors-iris-p3-a-11)",
    "variable": "var(--colors-iris-p3-a-11)"
  },
  "colors.iris.p3.a.12": {
    "value": "var(--colors-iris-p3-a-12)",
    "variable": "var(--colors-iris-p3-a-12)"
  },
  "colors.jade.1": {
    "value": "var(--colors-jade-1)",
    "variable": "var(--colors-jade-1)"
  },
  "colors.jade.2": {
    "value": "var(--colors-jade-2)",
    "variable": "var(--colors-jade-2)"
  },
  "colors.jade.3": {
    "value": "var(--colors-jade-3)",
    "variable": "var(--colors-jade-3)"
  },
  "colors.jade.4": {
    "value": "var(--colors-jade-4)",
    "variable": "var(--colors-jade-4)"
  },
  "colors.jade.5": {
    "value": "var(--colors-jade-5)",
    "variable": "var(--colors-jade-5)"
  },
  "colors.jade.6": {
    "value": "var(--colors-jade-6)",
    "variable": "var(--colors-jade-6)"
  },
  "colors.jade.7": {
    "value": "var(--colors-jade-7)",
    "variable": "var(--colors-jade-7)"
  },
  "colors.jade.8": {
    "value": "var(--colors-jade-8)",
    "variable": "var(--colors-jade-8)"
  },
  "colors.jade.9": {
    "value": "var(--colors-jade-9)",
    "variable": "var(--colors-jade-9)"
  },
  "colors.jade.10": {
    "value": "var(--colors-jade-10)",
    "variable": "var(--colors-jade-10)"
  },
  "colors.jade.11": {
    "value": "var(--colors-jade-11)",
    "variable": "var(--colors-jade-11)"
  },
  "colors.jade.12": {
    "value": "var(--colors-jade-12)",
    "variable": "var(--colors-jade-12)"
  },
  "colors.jade.light.1": {
    "value": "var(--colors-jade-light-1)",
    "variable": "var(--colors-jade-light-1)"
  },
  "colors.jade.light.2": {
    "value": "var(--colors-jade-light-2)",
    "variable": "var(--colors-jade-light-2)"
  },
  "colors.jade.light.3": {
    "value": "var(--colors-jade-light-3)",
    "variable": "var(--colors-jade-light-3)"
  },
  "colors.jade.light.4": {
    "value": "var(--colors-jade-light-4)",
    "variable": "var(--colors-jade-light-4)"
  },
  "colors.jade.light.5": {
    "value": "var(--colors-jade-light-5)",
    "variable": "var(--colors-jade-light-5)"
  },
  "colors.jade.light.6": {
    "value": "var(--colors-jade-light-6)",
    "variable": "var(--colors-jade-light-6)"
  },
  "colors.jade.light.7": {
    "value": "var(--colors-jade-light-7)",
    "variable": "var(--colors-jade-light-7)"
  },
  "colors.jade.light.8": {
    "value": "var(--colors-jade-light-8)",
    "variable": "var(--colors-jade-light-8)"
  },
  "colors.jade.light.9": {
    "value": "var(--colors-jade-light-9)",
    "variable": "var(--colors-jade-light-9)"
  },
  "colors.jade.light.10": {
    "value": "var(--colors-jade-light-10)",
    "variable": "var(--colors-jade-light-10)"
  },
  "colors.jade.light.11": {
    "value": "var(--colors-jade-light-11)",
    "variable": "var(--colors-jade-light-11)"
  },
  "colors.jade.light.12": {
    "value": "var(--colors-jade-light-12)",
    "variable": "var(--colors-jade-light-12)"
  },
  "colors.jade.light.a.1": {
    "value": "var(--colors-jade-light-a-1)",
    "variable": "var(--colors-jade-light-a-1)"
  },
  "colors.jade.light.a.2": {
    "value": "var(--colors-jade-light-a-2)",
    "variable": "var(--colors-jade-light-a-2)"
  },
  "colors.jade.light.a.3": {
    "value": "var(--colors-jade-light-a-3)",
    "variable": "var(--colors-jade-light-a-3)"
  },
  "colors.jade.light.a.4": {
    "value": "var(--colors-jade-light-a-4)",
    "variable": "var(--colors-jade-light-a-4)"
  },
  "colors.jade.light.a.5": {
    "value": "var(--colors-jade-light-a-5)",
    "variable": "var(--colors-jade-light-a-5)"
  },
  "colors.jade.light.a.6": {
    "value": "var(--colors-jade-light-a-6)",
    "variable": "var(--colors-jade-light-a-6)"
  },
  "colors.jade.light.a.7": {
    "value": "var(--colors-jade-light-a-7)",
    "variable": "var(--colors-jade-light-a-7)"
  },
  "colors.jade.light.a.8": {
    "value": "var(--colors-jade-light-a-8)",
    "variable": "var(--colors-jade-light-a-8)"
  },
  "colors.jade.light.a.9": {
    "value": "var(--colors-jade-light-a-9)",
    "variable": "var(--colors-jade-light-a-9)"
  },
  "colors.jade.light.a.10": {
    "value": "var(--colors-jade-light-a-10)",
    "variable": "var(--colors-jade-light-a-10)"
  },
  "colors.jade.light.a.11": {
    "value": "var(--colors-jade-light-a-11)",
    "variable": "var(--colors-jade-light-a-11)"
  },
  "colors.jade.light.a.12": {
    "value": "var(--colors-jade-light-a-12)",
    "variable": "var(--colors-jade-light-a-12)"
  },
  "colors.jade.light.p3.1": {
    "value": "color(display-p3 0.986 0.996 0.992)",
    "variable": "var(--colors-jade-light-p3-1)"
  },
  "colors.jade.light.p3.2": {
    "value": "color(display-p3 0.962 0.983 0.969)",
    "variable": "var(--colors-jade-light-p3-2)"
  },
  "colors.jade.light.p3.3": {
    "value": "color(display-p3 0.912 0.965 0.932)",
    "variable": "var(--colors-jade-light-p3-3)"
  },
  "colors.jade.light.p3.4": {
    "value": "color(display-p3 0.858 0.941 0.893)",
    "variable": "var(--colors-jade-light-p3-4)"
  },
  "colors.jade.light.p3.5": {
    "value": "color(display-p3 0.795 0.909 0.847)",
    "variable": "var(--colors-jade-light-p3-5)"
  },
  "colors.jade.light.p3.6": {
    "value": "color(display-p3 0.715 0.864 0.791)",
    "variable": "var(--colors-jade-light-p3-6)"
  },
  "colors.jade.light.p3.7": {
    "value": "color(display-p3 0.603 0.802 0.718)",
    "variable": "var(--colors-jade-light-p3-7)"
  },
  "colors.jade.light.p3.8": {
    "value": "color(display-p3 0.44 0.72 0.629)",
    "variable": "var(--colors-jade-light-p3-8)"
  },
  "colors.jade.light.p3.9": {
    "value": "color(display-p3 0.319 0.63 0.521)",
    "variable": "var(--colors-jade-light-p3-9)"
  },
  "colors.jade.light.p3.10": {
    "value": "color(display-p3 0.299 0.592 0.488)",
    "variable": "var(--colors-jade-light-p3-10)"
  },
  "colors.jade.light.p3.11": {
    "value": "color(display-p3 0.15 0.5 0.37)",
    "variable": "var(--colors-jade-light-p3-11)"
  },
  "colors.jade.light.p3.12": {
    "value": "color(display-p3 0.142 0.229 0.194)",
    "variable": "var(--colors-jade-light-p3-12)"
  },
  "colors.jade.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.757 0.514 / 0.016)",
    "variable": "var(--colors-jade-light-p3-a-1)"
  },
  "colors.jade.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.612 0.22 / 0.04)",
    "variable": "var(--colors-jade-light-p3-a-2)"
  },
  "colors.jade.light.p3.a.3": {
    "value": "color(display-p3 0.012 0.596 0.235 / 0.087)",
    "variable": "var(--colors-jade-light-p3-a-3)"
  },
  "colors.jade.light.p3.a.4": {
    "value": "color(display-p3 0.008 0.588 0.255 / 0.142)",
    "variable": "var(--colors-jade-light-p3-a-4)"
  },
  "colors.jade.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.561 0.251 / 0.204)",
    "variable": "var(--colors-jade-light-p3-a-5)"
  },
  "colors.jade.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.525 0.278 / 0.287)",
    "variable": "var(--colors-jade-light-p3-a-6)"
  },
  "colors.jade.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.506 0.29 / 0.397)",
    "variable": "var(--colors-jade-light-p3-a-7)"
  },
  "colors.jade.light.p3.a.8": {
    "value": "color(display-p3 0 0.506 0.337 / 0.561)",
    "variable": "var(--colors-jade-light-p3-a-8)"
  },
  "colors.jade.light.p3.a.9": {
    "value": "color(display-p3 0 0.459 0.298 / 0.683)",
    "variable": "var(--colors-jade-light-p3-a-9)"
  },
  "colors.jade.light.p3.a.10": {
    "value": "color(display-p3 0 0.42 0.271 / 0.702)",
    "variable": "var(--colors-jade-light-p3-a-10)"
  },
  "colors.jade.light.p3.a.11": {
    "value": "color(display-p3 0.15 0.5 0.37)",
    "variable": "var(--colors-jade-light-p3-a-11)"
  },
  "colors.jade.light.p3.a.12": {
    "value": "color(display-p3 0.142 0.229 0.194)",
    "variable": "var(--colors-jade-light-p3-a-12)"
  },
  "colors.jade.dark.1": {
    "value": "var(--colors-jade-dark-1)",
    "variable": "var(--colors-jade-dark-1)"
  },
  "colors.jade.dark.2": {
    "value": "var(--colors-jade-dark-2)",
    "variable": "var(--colors-jade-dark-2)"
  },
  "colors.jade.dark.3": {
    "value": "var(--colors-jade-dark-3)",
    "variable": "var(--colors-jade-dark-3)"
  },
  "colors.jade.dark.4": {
    "value": "var(--colors-jade-dark-4)",
    "variable": "var(--colors-jade-dark-4)"
  },
  "colors.jade.dark.5": {
    "value": "var(--colors-jade-dark-5)",
    "variable": "var(--colors-jade-dark-5)"
  },
  "colors.jade.dark.6": {
    "value": "var(--colors-jade-dark-6)",
    "variable": "var(--colors-jade-dark-6)"
  },
  "colors.jade.dark.7": {
    "value": "var(--colors-jade-dark-7)",
    "variable": "var(--colors-jade-dark-7)"
  },
  "colors.jade.dark.8": {
    "value": "var(--colors-jade-dark-8)",
    "variable": "var(--colors-jade-dark-8)"
  },
  "colors.jade.dark.9": {
    "value": "var(--colors-jade-dark-9)",
    "variable": "var(--colors-jade-dark-9)"
  },
  "colors.jade.dark.10": {
    "value": "var(--colors-jade-dark-10)",
    "variable": "var(--colors-jade-dark-10)"
  },
  "colors.jade.dark.11": {
    "value": "var(--colors-jade-dark-11)",
    "variable": "var(--colors-jade-dark-11)"
  },
  "colors.jade.dark.12": {
    "value": "var(--colors-jade-dark-12)",
    "variable": "var(--colors-jade-dark-12)"
  },
  "colors.jade.dark.a.1": {
    "value": "var(--colors-jade-dark-a-1)",
    "variable": "var(--colors-jade-dark-a-1)"
  },
  "colors.jade.dark.a.2": {
    "value": "var(--colors-jade-dark-a-2)",
    "variable": "var(--colors-jade-dark-a-2)"
  },
  "colors.jade.dark.a.3": {
    "value": "var(--colors-jade-dark-a-3)",
    "variable": "var(--colors-jade-dark-a-3)"
  },
  "colors.jade.dark.a.4": {
    "value": "var(--colors-jade-dark-a-4)",
    "variable": "var(--colors-jade-dark-a-4)"
  },
  "colors.jade.dark.a.5": {
    "value": "var(--colors-jade-dark-a-5)",
    "variable": "var(--colors-jade-dark-a-5)"
  },
  "colors.jade.dark.a.6": {
    "value": "var(--colors-jade-dark-a-6)",
    "variable": "var(--colors-jade-dark-a-6)"
  },
  "colors.jade.dark.a.7": {
    "value": "var(--colors-jade-dark-a-7)",
    "variable": "var(--colors-jade-dark-a-7)"
  },
  "colors.jade.dark.a.8": {
    "value": "var(--colors-jade-dark-a-8)",
    "variable": "var(--colors-jade-dark-a-8)"
  },
  "colors.jade.dark.a.9": {
    "value": "var(--colors-jade-dark-a-9)",
    "variable": "var(--colors-jade-dark-a-9)"
  },
  "colors.jade.dark.a.10": {
    "value": "var(--colors-jade-dark-a-10)",
    "variable": "var(--colors-jade-dark-a-10)"
  },
  "colors.jade.dark.a.11": {
    "value": "var(--colors-jade-dark-a-11)",
    "variable": "var(--colors-jade-dark-a-11)"
  },
  "colors.jade.dark.a.12": {
    "value": "var(--colors-jade-dark-a-12)",
    "variable": "var(--colors-jade-dark-a-12)"
  },
  "colors.jade.dark.p3.1": {
    "value": "color(display-p3 0.059 0.083 0.071)",
    "variable": "var(--colors-jade-dark-p3-1)"
  },
  "colors.jade.dark.p3.2": {
    "value": "color(display-p3 0.078 0.11 0.094)",
    "variable": "var(--colors-jade-dark-p3-2)"
  },
  "colors.jade.dark.p3.3": {
    "value": "color(display-p3 0.091 0.176 0.138)",
    "variable": "var(--colors-jade-dark-p3-3)"
  },
  "colors.jade.dark.p3.4": {
    "value": "color(display-p3 0.102 0.228 0.177)",
    "variable": "var(--colors-jade-dark-p3-4)"
  },
  "colors.jade.dark.p3.5": {
    "value": "color(display-p3 0.133 0.279 0.221)",
    "variable": "var(--colors-jade-dark-p3-5)"
  },
  "colors.jade.dark.p3.6": {
    "value": "color(display-p3 0.174 0.334 0.273)",
    "variable": "var(--colors-jade-dark-p3-6)"
  },
  "colors.jade.dark.p3.7": {
    "value": "color(display-p3 0.219 0.402 0.335)",
    "variable": "var(--colors-jade-dark-p3-7)"
  },
  "colors.jade.dark.p3.8": {
    "value": "color(display-p3 0.263 0.488 0.411)",
    "variable": "var(--colors-jade-dark-p3-8)"
  },
  "colors.jade.dark.p3.9": {
    "value": "color(display-p3 0.319 0.63 0.521)",
    "variable": "var(--colors-jade-dark-p3-9)"
  },
  "colors.jade.dark.p3.10": {
    "value": "color(display-p3 0.338 0.68 0.555)",
    "variable": "var(--colors-jade-dark-p3-10)"
  },
  "colors.jade.dark.p3.11": {
    "value": "color(display-p3 0.4 0.835 0.656)",
    "variable": "var(--colors-jade-dark-p3-11)"
  },
  "colors.jade.dark.p3.12": {
    "value": "color(display-p3 0.734 0.934 0.838)",
    "variable": "var(--colors-jade-dark-p3-12)"
  },
  "colors.jade.dark.p3.a.1": {
    "value": "color(display-p3 0 0.992 0.298 / 0.017)",
    "variable": "var(--colors-jade-dark-p3-a-1)"
  },
  "colors.jade.dark.p3.a.2": {
    "value": "color(display-p3 0.318 0.988 0.651 / 0.047)",
    "variable": "var(--colors-jade-dark-p3-a-2)"
  },
  "colors.jade.dark.p3.a.3": {
    "value": "color(display-p3 0.267 1 0.667 / 0.118)",
    "variable": "var(--colors-jade-dark-p3-a-3)"
  },
  "colors.jade.dark.p3.a.4": {
    "value": "color(display-p3 0.275 0.996 0.702 / 0.173)",
    "variable": "var(--colors-jade-dark-p3-a-4)"
  },
  "colors.jade.dark.p3.a.5": {
    "value": "color(display-p3 0.361 1 0.741 / 0.227)",
    "variable": "var(--colors-jade-dark-p3-a-5)"
  },
  "colors.jade.dark.p3.a.6": {
    "value": "color(display-p3 0.439 1 0.796 / 0.286)",
    "variable": "var(--colors-jade-dark-p3-a-6)"
  },
  "colors.jade.dark.p3.a.7": {
    "value": "color(display-p3 0.49 1 0.804 / 0.362)",
    "variable": "var(--colors-jade-dark-p3-a-7)"
  },
  "colors.jade.dark.p3.a.8": {
    "value": "color(display-p3 0.506 1 0.835 / 0.45)",
    "variable": "var(--colors-jade-dark-p3-a-8)"
  },
  "colors.jade.dark.p3.a.9": {
    "value": "color(display-p3 0.478 0.996 0.816 / 0.606)",
    "variable": "var(--colors-jade-dark-p3-a-9)"
  },
  "colors.jade.dark.p3.a.10": {
    "value": "color(display-p3 0.478 1 0.816 / 0.656)",
    "variable": "var(--colors-jade-dark-p3-a-10)"
  },
  "colors.jade.dark.p3.a.11": {
    "value": "color(display-p3 0.4 0.835 0.656)",
    "variable": "var(--colors-jade-dark-p3-a-11)"
  },
  "colors.jade.dark.p3.a.12": {
    "value": "color(display-p3 0.734 0.934 0.838)",
    "variable": "var(--colors-jade-dark-p3-a-12)"
  },
  "colors.jade.a.1": {
    "value": "var(--colors-jade-a-1)",
    "variable": "var(--colors-jade-a-1)"
  },
  "colors.jade.a.2": {
    "value": "var(--colors-jade-a-2)",
    "variable": "var(--colors-jade-a-2)"
  },
  "colors.jade.a.3": {
    "value": "var(--colors-jade-a-3)",
    "variable": "var(--colors-jade-a-3)"
  },
  "colors.jade.a.4": {
    "value": "var(--colors-jade-a-4)",
    "variable": "var(--colors-jade-a-4)"
  },
  "colors.jade.a.5": {
    "value": "var(--colors-jade-a-5)",
    "variable": "var(--colors-jade-a-5)"
  },
  "colors.jade.a.6": {
    "value": "var(--colors-jade-a-6)",
    "variable": "var(--colors-jade-a-6)"
  },
  "colors.jade.a.7": {
    "value": "var(--colors-jade-a-7)",
    "variable": "var(--colors-jade-a-7)"
  },
  "colors.jade.a.8": {
    "value": "var(--colors-jade-a-8)",
    "variable": "var(--colors-jade-a-8)"
  },
  "colors.jade.a.9": {
    "value": "var(--colors-jade-a-9)",
    "variable": "var(--colors-jade-a-9)"
  },
  "colors.jade.a.10": {
    "value": "var(--colors-jade-a-10)",
    "variable": "var(--colors-jade-a-10)"
  },
  "colors.jade.a.11": {
    "value": "var(--colors-jade-a-11)",
    "variable": "var(--colors-jade-a-11)"
  },
  "colors.jade.a.12": {
    "value": "var(--colors-jade-a-12)",
    "variable": "var(--colors-jade-a-12)"
  },
  "colors.jade.p3.1": {
    "value": "var(--colors-jade-p3-1)",
    "variable": "var(--colors-jade-p3-1)"
  },
  "colors.jade.p3.2": {
    "value": "var(--colors-jade-p3-2)",
    "variable": "var(--colors-jade-p3-2)"
  },
  "colors.jade.p3.3": {
    "value": "var(--colors-jade-p3-3)",
    "variable": "var(--colors-jade-p3-3)"
  },
  "colors.jade.p3.4": {
    "value": "var(--colors-jade-p3-4)",
    "variable": "var(--colors-jade-p3-4)"
  },
  "colors.jade.p3.5": {
    "value": "var(--colors-jade-p3-5)",
    "variable": "var(--colors-jade-p3-5)"
  },
  "colors.jade.p3.6": {
    "value": "var(--colors-jade-p3-6)",
    "variable": "var(--colors-jade-p3-6)"
  },
  "colors.jade.p3.7": {
    "value": "var(--colors-jade-p3-7)",
    "variable": "var(--colors-jade-p3-7)"
  },
  "colors.jade.p3.8": {
    "value": "var(--colors-jade-p3-8)",
    "variable": "var(--colors-jade-p3-8)"
  },
  "colors.jade.p3.9": {
    "value": "var(--colors-jade-p3-9)",
    "variable": "var(--colors-jade-p3-9)"
  },
  "colors.jade.p3.10": {
    "value": "var(--colors-jade-p3-10)",
    "variable": "var(--colors-jade-p3-10)"
  },
  "colors.jade.p3.11": {
    "value": "var(--colors-jade-p3-11)",
    "variable": "var(--colors-jade-p3-11)"
  },
  "colors.jade.p3.12": {
    "value": "var(--colors-jade-p3-12)",
    "variable": "var(--colors-jade-p3-12)"
  },
  "colors.jade.p3.a.1": {
    "value": "var(--colors-jade-p3-a-1)",
    "variable": "var(--colors-jade-p3-a-1)"
  },
  "colors.jade.p3.a.2": {
    "value": "var(--colors-jade-p3-a-2)",
    "variable": "var(--colors-jade-p3-a-2)"
  },
  "colors.jade.p3.a.3": {
    "value": "var(--colors-jade-p3-a-3)",
    "variable": "var(--colors-jade-p3-a-3)"
  },
  "colors.jade.p3.a.4": {
    "value": "var(--colors-jade-p3-a-4)",
    "variable": "var(--colors-jade-p3-a-4)"
  },
  "colors.jade.p3.a.5": {
    "value": "var(--colors-jade-p3-a-5)",
    "variable": "var(--colors-jade-p3-a-5)"
  },
  "colors.jade.p3.a.6": {
    "value": "var(--colors-jade-p3-a-6)",
    "variable": "var(--colors-jade-p3-a-6)"
  },
  "colors.jade.p3.a.7": {
    "value": "var(--colors-jade-p3-a-7)",
    "variable": "var(--colors-jade-p3-a-7)"
  },
  "colors.jade.p3.a.8": {
    "value": "var(--colors-jade-p3-a-8)",
    "variable": "var(--colors-jade-p3-a-8)"
  },
  "colors.jade.p3.a.9": {
    "value": "var(--colors-jade-p3-a-9)",
    "variable": "var(--colors-jade-p3-a-9)"
  },
  "colors.jade.p3.a.10": {
    "value": "var(--colors-jade-p3-a-10)",
    "variable": "var(--colors-jade-p3-a-10)"
  },
  "colors.jade.p3.a.11": {
    "value": "var(--colors-jade-p3-a-11)",
    "variable": "var(--colors-jade-p3-a-11)"
  },
  "colors.jade.p3.a.12": {
    "value": "var(--colors-jade-p3-a-12)",
    "variable": "var(--colors-jade-p3-a-12)"
  },
  "colors.lime.1": {
    "value": "var(--colors-lime-1)",
    "variable": "var(--colors-lime-1)"
  },
  "colors.lime.2": {
    "value": "var(--colors-lime-2)",
    "variable": "var(--colors-lime-2)"
  },
  "colors.lime.3": {
    "value": "var(--colors-lime-3)",
    "variable": "var(--colors-lime-3)"
  },
  "colors.lime.4": {
    "value": "var(--colors-lime-4)",
    "variable": "var(--colors-lime-4)"
  },
  "colors.lime.5": {
    "value": "var(--colors-lime-5)",
    "variable": "var(--colors-lime-5)"
  },
  "colors.lime.6": {
    "value": "var(--colors-lime-6)",
    "variable": "var(--colors-lime-6)"
  },
  "colors.lime.7": {
    "value": "var(--colors-lime-7)",
    "variable": "var(--colors-lime-7)"
  },
  "colors.lime.8": {
    "value": "var(--colors-lime-8)",
    "variable": "var(--colors-lime-8)"
  },
  "colors.lime.9": {
    "value": "var(--colors-lime-9)",
    "variable": "var(--colors-lime-9)"
  },
  "colors.lime.10": {
    "value": "var(--colors-lime-10)",
    "variable": "var(--colors-lime-10)"
  },
  "colors.lime.11": {
    "value": "var(--colors-lime-11)",
    "variable": "var(--colors-lime-11)"
  },
  "colors.lime.12": {
    "value": "var(--colors-lime-12)",
    "variable": "var(--colors-lime-12)"
  },
  "colors.lime.light.1": {
    "value": "var(--colors-lime-light-1)",
    "variable": "var(--colors-lime-light-1)"
  },
  "colors.lime.light.2": {
    "value": "var(--colors-lime-light-2)",
    "variable": "var(--colors-lime-light-2)"
  },
  "colors.lime.light.3": {
    "value": "var(--colors-lime-light-3)",
    "variable": "var(--colors-lime-light-3)"
  },
  "colors.lime.light.4": {
    "value": "var(--colors-lime-light-4)",
    "variable": "var(--colors-lime-light-4)"
  },
  "colors.lime.light.5": {
    "value": "var(--colors-lime-light-5)",
    "variable": "var(--colors-lime-light-5)"
  },
  "colors.lime.light.6": {
    "value": "var(--colors-lime-light-6)",
    "variable": "var(--colors-lime-light-6)"
  },
  "colors.lime.light.7": {
    "value": "var(--colors-lime-light-7)",
    "variable": "var(--colors-lime-light-7)"
  },
  "colors.lime.light.8": {
    "value": "var(--colors-lime-light-8)",
    "variable": "var(--colors-lime-light-8)"
  },
  "colors.lime.light.9": {
    "value": "var(--colors-lime-light-9)",
    "variable": "var(--colors-lime-light-9)"
  },
  "colors.lime.light.10": {
    "value": "var(--colors-lime-light-10)",
    "variable": "var(--colors-lime-light-10)"
  },
  "colors.lime.light.11": {
    "value": "var(--colors-lime-light-11)",
    "variable": "var(--colors-lime-light-11)"
  },
  "colors.lime.light.12": {
    "value": "var(--colors-lime-light-12)",
    "variable": "var(--colors-lime-light-12)"
  },
  "colors.lime.light.a.1": {
    "value": "var(--colors-lime-light-a-1)",
    "variable": "var(--colors-lime-light-a-1)"
  },
  "colors.lime.light.a.2": {
    "value": "var(--colors-lime-light-a-2)",
    "variable": "var(--colors-lime-light-a-2)"
  },
  "colors.lime.light.a.3": {
    "value": "var(--colors-lime-light-a-3)",
    "variable": "var(--colors-lime-light-a-3)"
  },
  "colors.lime.light.a.4": {
    "value": "var(--colors-lime-light-a-4)",
    "variable": "var(--colors-lime-light-a-4)"
  },
  "colors.lime.light.a.5": {
    "value": "var(--colors-lime-light-a-5)",
    "variable": "var(--colors-lime-light-a-5)"
  },
  "colors.lime.light.a.6": {
    "value": "var(--colors-lime-light-a-6)",
    "variable": "var(--colors-lime-light-a-6)"
  },
  "colors.lime.light.a.7": {
    "value": "var(--colors-lime-light-a-7)",
    "variable": "var(--colors-lime-light-a-7)"
  },
  "colors.lime.light.a.8": {
    "value": "var(--colors-lime-light-a-8)",
    "variable": "var(--colors-lime-light-a-8)"
  },
  "colors.lime.light.a.9": {
    "value": "var(--colors-lime-light-a-9)",
    "variable": "var(--colors-lime-light-a-9)"
  },
  "colors.lime.light.a.10": {
    "value": "var(--colors-lime-light-a-10)",
    "variable": "var(--colors-lime-light-a-10)"
  },
  "colors.lime.light.a.11": {
    "value": "var(--colors-lime-light-a-11)",
    "variable": "var(--colors-lime-light-a-11)"
  },
  "colors.lime.light.a.12": {
    "value": "var(--colors-lime-light-a-12)",
    "variable": "var(--colors-lime-light-a-12)"
  },
  "colors.lime.light.p3.1": {
    "value": "color(display-p3 0.989 0.992 0.981)",
    "variable": "var(--colors-lime-light-p3-1)"
  },
  "colors.lime.light.p3.2": {
    "value": "color(display-p3 0.975 0.98 0.954)",
    "variable": "var(--colors-lime-light-p3-2)"
  },
  "colors.lime.light.p3.3": {
    "value": "color(display-p3 0.939 0.965 0.851)",
    "variable": "var(--colors-lime-light-p3-3)"
  },
  "colors.lime.light.p3.4": {
    "value": "color(display-p3 0.896 0.94 0.76)",
    "variable": "var(--colors-lime-light-p3-4)"
  },
  "colors.lime.light.p3.5": {
    "value": "color(display-p3 0.843 0.903 0.678)",
    "variable": "var(--colors-lime-light-p3-5)"
  },
  "colors.lime.light.p3.6": {
    "value": "color(display-p3 0.778 0.852 0.599)",
    "variable": "var(--colors-lime-light-p3-6)"
  },
  "colors.lime.light.p3.7": {
    "value": "color(display-p3 0.694 0.784 0.508)",
    "variable": "var(--colors-lime-light-p3-7)"
  },
  "colors.lime.light.p3.8": {
    "value": "color(display-p3 0.585 0.707 0.378)",
    "variable": "var(--colors-lime-light-p3-8)"
  },
  "colors.lime.light.p3.9": {
    "value": "color(display-p3 0.78 0.928 0.466)",
    "variable": "var(--colors-lime-light-p3-9)"
  },
  "colors.lime.light.p3.10": {
    "value": "color(display-p3 0.734 0.896 0.397)",
    "variable": "var(--colors-lime-light-p3-10)"
  },
  "colors.lime.light.p3.11": {
    "value": "color(display-p3 0.386 0.482 0.227)",
    "variable": "var(--colors-lime-light-p3-11)"
  },
  "colors.lime.light.p3.12": {
    "value": "color(display-p3 0.222 0.25 0.128)",
    "variable": "var(--colors-lime-light-p3-12)"
  },
  "colors.lime.light.p3.a.1": {
    "value": "color(display-p3 0.412 0.608 0.02 / 0.02)",
    "variable": "var(--colors-lime-light-p3-a-1)"
  },
  "colors.lime.light.p3.a.2": {
    "value": "color(display-p3 0.514 0.592 0.024 / 0.048)",
    "variable": "var(--colors-lime-light-p3-a-2)"
  },
  "colors.lime.light.p3.a.3": {
    "value": "color(display-p3 0.584 0.765 0.008 / 0.15)",
    "variable": "var(--colors-lime-light-p3-a-3)"
  },
  "colors.lime.light.p3.a.4": {
    "value": "color(display-p3 0.561 0.757 0.004 / 0.24)",
    "variable": "var(--colors-lime-light-p3-a-4)"
  },
  "colors.lime.light.p3.a.5": {
    "value": "color(display-p3 0.514 0.698 0.004 / 0.322)",
    "variable": "var(--colors-lime-light-p3-a-5)"
  },
  "colors.lime.light.p3.a.6": {
    "value": "color(display-p3 0.443 0.627 0 / 0.4)",
    "variable": "var(--colors-lime-light-p3-a-6)"
  },
  "colors.lime.light.p3.a.7": {
    "value": "color(display-p3 0.376 0.561 0.004 / 0.491)",
    "variable": "var(--colors-lime-light-p3-a-7)"
  },
  "colors.lime.light.p3.a.8": {
    "value": "color(display-p3 0.333 0.529 0 / 0.624)",
    "variable": "var(--colors-lime-light-p3-a-8)"
  },
  "colors.lime.light.p3.a.9": {
    "value": "color(display-p3 0.588 0.867 0 / 0.534)",
    "variable": "var(--colors-lime-light-p3-a-9)"
  },
  "colors.lime.light.p3.a.10": {
    "value": "color(display-p3 0.561 0.827 0 / 0.604)",
    "variable": "var(--colors-lime-light-p3-a-10)"
  },
  "colors.lime.light.p3.a.11": {
    "value": "color(display-p3 0.386 0.482 0.227)",
    "variable": "var(--colors-lime-light-p3-a-11)"
  },
  "colors.lime.light.p3.a.12": {
    "value": "color(display-p3 0.222 0.25 0.128)",
    "variable": "var(--colors-lime-light-p3-a-12)"
  },
  "colors.lime.dark.1": {
    "value": "var(--colors-lime-dark-1)",
    "variable": "var(--colors-lime-dark-1)"
  },
  "colors.lime.dark.2": {
    "value": "var(--colors-lime-dark-2)",
    "variable": "var(--colors-lime-dark-2)"
  },
  "colors.lime.dark.3": {
    "value": "var(--colors-lime-dark-3)",
    "variable": "var(--colors-lime-dark-3)"
  },
  "colors.lime.dark.4": {
    "value": "var(--colors-lime-dark-4)",
    "variable": "var(--colors-lime-dark-4)"
  },
  "colors.lime.dark.5": {
    "value": "var(--colors-lime-dark-5)",
    "variable": "var(--colors-lime-dark-5)"
  },
  "colors.lime.dark.6": {
    "value": "var(--colors-lime-dark-6)",
    "variable": "var(--colors-lime-dark-6)"
  },
  "colors.lime.dark.7": {
    "value": "var(--colors-lime-dark-7)",
    "variable": "var(--colors-lime-dark-7)"
  },
  "colors.lime.dark.8": {
    "value": "var(--colors-lime-dark-8)",
    "variable": "var(--colors-lime-dark-8)"
  },
  "colors.lime.dark.9": {
    "value": "var(--colors-lime-dark-9)",
    "variable": "var(--colors-lime-dark-9)"
  },
  "colors.lime.dark.10": {
    "value": "var(--colors-lime-dark-10)",
    "variable": "var(--colors-lime-dark-10)"
  },
  "colors.lime.dark.11": {
    "value": "var(--colors-lime-dark-11)",
    "variable": "var(--colors-lime-dark-11)"
  },
  "colors.lime.dark.12": {
    "value": "var(--colors-lime-dark-12)",
    "variable": "var(--colors-lime-dark-12)"
  },
  "colors.lime.dark.a.1": {
    "value": "var(--colors-lime-dark-a-1)",
    "variable": "var(--colors-lime-dark-a-1)"
  },
  "colors.lime.dark.a.2": {
    "value": "var(--colors-lime-dark-a-2)",
    "variable": "var(--colors-lime-dark-a-2)"
  },
  "colors.lime.dark.a.3": {
    "value": "var(--colors-lime-dark-a-3)",
    "variable": "var(--colors-lime-dark-a-3)"
  },
  "colors.lime.dark.a.4": {
    "value": "var(--colors-lime-dark-a-4)",
    "variable": "var(--colors-lime-dark-a-4)"
  },
  "colors.lime.dark.a.5": {
    "value": "var(--colors-lime-dark-a-5)",
    "variable": "var(--colors-lime-dark-a-5)"
  },
  "colors.lime.dark.a.6": {
    "value": "var(--colors-lime-dark-a-6)",
    "variable": "var(--colors-lime-dark-a-6)"
  },
  "colors.lime.dark.a.7": {
    "value": "var(--colors-lime-dark-a-7)",
    "variable": "var(--colors-lime-dark-a-7)"
  },
  "colors.lime.dark.a.8": {
    "value": "var(--colors-lime-dark-a-8)",
    "variable": "var(--colors-lime-dark-a-8)"
  },
  "colors.lime.dark.a.9": {
    "value": "var(--colors-lime-dark-a-9)",
    "variable": "var(--colors-lime-dark-a-9)"
  },
  "colors.lime.dark.a.10": {
    "value": "var(--colors-lime-dark-a-10)",
    "variable": "var(--colors-lime-dark-a-10)"
  },
  "colors.lime.dark.a.11": {
    "value": "var(--colors-lime-dark-a-11)",
    "variable": "var(--colors-lime-dark-a-11)"
  },
  "colors.lime.dark.a.12": {
    "value": "var(--colors-lime-dark-a-12)",
    "variable": "var(--colors-lime-dark-a-12)"
  },
  "colors.lime.dark.p3.1": {
    "value": "color(display-p3 0.067 0.073 0.048)",
    "variable": "var(--colors-lime-dark-p3-1)"
  },
  "colors.lime.dark.p3.2": {
    "value": "color(display-p3 0.086 0.1 0.067)",
    "variable": "var(--colors-lime-dark-p3-2)"
  },
  "colors.lime.dark.p3.3": {
    "value": "color(display-p3 0.13 0.16 0.099)",
    "variable": "var(--colors-lime-dark-p3-3)"
  },
  "colors.lime.dark.p3.4": {
    "value": "color(display-p3 0.172 0.214 0.126)",
    "variable": "var(--colors-lime-dark-p3-4)"
  },
  "colors.lime.dark.p3.5": {
    "value": "color(display-p3 0.213 0.266 0.153)",
    "variable": "var(--colors-lime-dark-p3-5)"
  },
  "colors.lime.dark.p3.6": {
    "value": "color(display-p3 0.257 0.321 0.182)",
    "variable": "var(--colors-lime-dark-p3-6)"
  },
  "colors.lime.dark.p3.7": {
    "value": "color(display-p3 0.307 0.383 0.215)",
    "variable": "var(--colors-lime-dark-p3-7)"
  },
  "colors.lime.dark.p3.8": {
    "value": "color(display-p3 0.365 0.456 0.25)",
    "variable": "var(--colors-lime-dark-p3-8)"
  },
  "colors.lime.dark.p3.9": {
    "value": "color(display-p3 0.78 0.928 0.466)",
    "variable": "var(--colors-lime-dark-p3-9)"
  },
  "colors.lime.dark.p3.10": {
    "value": "color(display-p3 0.865 0.995 0.519)",
    "variable": "var(--colors-lime-dark-p3-10)"
  },
  "colors.lime.dark.p3.11": {
    "value": "color(display-p3 0.771 0.893 0.485)",
    "variable": "var(--colors-lime-dark-p3-11)"
  },
  "colors.lime.dark.p3.12": {
    "value": "color(display-p3 0.905 0.966 0.753)",
    "variable": "var(--colors-lime-dark-p3-12)"
  },
  "colors.lime.dark.p3.a.1": {
    "value": "color(display-p3 0.067 0.941 0 / 0.009)",
    "variable": "var(--colors-lime-dark-p3-a-1)"
  },
  "colors.lime.dark.p3.a.2": {
    "value": "color(display-p3 0.584 0.996 0.071 / 0.038)",
    "variable": "var(--colors-lime-dark-p3-a-2)"
  },
  "colors.lime.dark.p3.a.3": {
    "value": "color(display-p3 0.69 1 0.38 / 0.101)",
    "variable": "var(--colors-lime-dark-p3-a-3)"
  },
  "colors.lime.dark.p3.a.4": {
    "value": "color(display-p3 0.729 1 0.435 / 0.16)",
    "variable": "var(--colors-lime-dark-p3-a-4)"
  },
  "colors.lime.dark.p3.a.5": {
    "value": "color(display-p3 0.745 1 0.471 / 0.215)",
    "variable": "var(--colors-lime-dark-p3-a-5)"
  },
  "colors.lime.dark.p3.a.6": {
    "value": "color(display-p3 0.769 1 0.482 / 0.274)",
    "variable": "var(--colors-lime-dark-p3-a-6)"
  },
  "colors.lime.dark.p3.a.7": {
    "value": "color(display-p3 0.769 1 0.506 / 0.341)",
    "variable": "var(--colors-lime-dark-p3-a-7)"
  },
  "colors.lime.dark.p3.a.8": {
    "value": "color(display-p3 0.784 1 0.51 / 0.416)",
    "variable": "var(--colors-lime-dark-p3-a-8)"
  },
  "colors.lime.dark.p3.a.9": {
    "value": "color(display-p3 0.839 1 0.502 / 0.925)",
    "variable": "var(--colors-lime-dark-p3-a-9)"
  },
  "colors.lime.dark.p3.a.10": {
    "value": "color(display-p3 0.871 1 0.522 / 0.996)",
    "variable": "var(--colors-lime-dark-p3-a-10)"
  },
  "colors.lime.dark.p3.a.11": {
    "value": "color(display-p3 0.771 0.893 0.485)",
    "variable": "var(--colors-lime-dark-p3-a-11)"
  },
  "colors.lime.dark.p3.a.12": {
    "value": "color(display-p3 0.905 0.966 0.753)",
    "variable": "var(--colors-lime-dark-p3-a-12)"
  },
  "colors.lime.a.1": {
    "value": "var(--colors-lime-a-1)",
    "variable": "var(--colors-lime-a-1)"
  },
  "colors.lime.a.2": {
    "value": "var(--colors-lime-a-2)",
    "variable": "var(--colors-lime-a-2)"
  },
  "colors.lime.a.3": {
    "value": "var(--colors-lime-a-3)",
    "variable": "var(--colors-lime-a-3)"
  },
  "colors.lime.a.4": {
    "value": "var(--colors-lime-a-4)",
    "variable": "var(--colors-lime-a-4)"
  },
  "colors.lime.a.5": {
    "value": "var(--colors-lime-a-5)",
    "variable": "var(--colors-lime-a-5)"
  },
  "colors.lime.a.6": {
    "value": "var(--colors-lime-a-6)",
    "variable": "var(--colors-lime-a-6)"
  },
  "colors.lime.a.7": {
    "value": "var(--colors-lime-a-7)",
    "variable": "var(--colors-lime-a-7)"
  },
  "colors.lime.a.8": {
    "value": "var(--colors-lime-a-8)",
    "variable": "var(--colors-lime-a-8)"
  },
  "colors.lime.a.9": {
    "value": "var(--colors-lime-a-9)",
    "variable": "var(--colors-lime-a-9)"
  },
  "colors.lime.a.10": {
    "value": "var(--colors-lime-a-10)",
    "variable": "var(--colors-lime-a-10)"
  },
  "colors.lime.a.11": {
    "value": "var(--colors-lime-a-11)",
    "variable": "var(--colors-lime-a-11)"
  },
  "colors.lime.a.12": {
    "value": "var(--colors-lime-a-12)",
    "variable": "var(--colors-lime-a-12)"
  },
  "colors.lime.p3.1": {
    "value": "var(--colors-lime-p3-1)",
    "variable": "var(--colors-lime-p3-1)"
  },
  "colors.lime.p3.2": {
    "value": "var(--colors-lime-p3-2)",
    "variable": "var(--colors-lime-p3-2)"
  },
  "colors.lime.p3.3": {
    "value": "var(--colors-lime-p3-3)",
    "variable": "var(--colors-lime-p3-3)"
  },
  "colors.lime.p3.4": {
    "value": "var(--colors-lime-p3-4)",
    "variable": "var(--colors-lime-p3-4)"
  },
  "colors.lime.p3.5": {
    "value": "var(--colors-lime-p3-5)",
    "variable": "var(--colors-lime-p3-5)"
  },
  "colors.lime.p3.6": {
    "value": "var(--colors-lime-p3-6)",
    "variable": "var(--colors-lime-p3-6)"
  },
  "colors.lime.p3.7": {
    "value": "var(--colors-lime-p3-7)",
    "variable": "var(--colors-lime-p3-7)"
  },
  "colors.lime.p3.8": {
    "value": "var(--colors-lime-p3-8)",
    "variable": "var(--colors-lime-p3-8)"
  },
  "colors.lime.p3.9": {
    "value": "var(--colors-lime-p3-9)",
    "variable": "var(--colors-lime-p3-9)"
  },
  "colors.lime.p3.10": {
    "value": "var(--colors-lime-p3-10)",
    "variable": "var(--colors-lime-p3-10)"
  },
  "colors.lime.p3.11": {
    "value": "var(--colors-lime-p3-11)",
    "variable": "var(--colors-lime-p3-11)"
  },
  "colors.lime.p3.12": {
    "value": "var(--colors-lime-p3-12)",
    "variable": "var(--colors-lime-p3-12)"
  },
  "colors.lime.p3.a.1": {
    "value": "var(--colors-lime-p3-a-1)",
    "variable": "var(--colors-lime-p3-a-1)"
  },
  "colors.lime.p3.a.2": {
    "value": "var(--colors-lime-p3-a-2)",
    "variable": "var(--colors-lime-p3-a-2)"
  },
  "colors.lime.p3.a.3": {
    "value": "var(--colors-lime-p3-a-3)",
    "variable": "var(--colors-lime-p3-a-3)"
  },
  "colors.lime.p3.a.4": {
    "value": "var(--colors-lime-p3-a-4)",
    "variable": "var(--colors-lime-p3-a-4)"
  },
  "colors.lime.p3.a.5": {
    "value": "var(--colors-lime-p3-a-5)",
    "variable": "var(--colors-lime-p3-a-5)"
  },
  "colors.lime.p3.a.6": {
    "value": "var(--colors-lime-p3-a-6)",
    "variable": "var(--colors-lime-p3-a-6)"
  },
  "colors.lime.p3.a.7": {
    "value": "var(--colors-lime-p3-a-7)",
    "variable": "var(--colors-lime-p3-a-7)"
  },
  "colors.lime.p3.a.8": {
    "value": "var(--colors-lime-p3-a-8)",
    "variable": "var(--colors-lime-p3-a-8)"
  },
  "colors.lime.p3.a.9": {
    "value": "var(--colors-lime-p3-a-9)",
    "variable": "var(--colors-lime-p3-a-9)"
  },
  "colors.lime.p3.a.10": {
    "value": "var(--colors-lime-p3-a-10)",
    "variable": "var(--colors-lime-p3-a-10)"
  },
  "colors.lime.p3.a.11": {
    "value": "var(--colors-lime-p3-a-11)",
    "variable": "var(--colors-lime-p3-a-11)"
  },
  "colors.lime.p3.a.12": {
    "value": "var(--colors-lime-p3-a-12)",
    "variable": "var(--colors-lime-p3-a-12)"
  },
  "colors.mauve.1": {
    "value": "var(--colors-mauve-1)",
    "variable": "var(--colors-mauve-1)"
  },
  "colors.mauve.2": {
    "value": "var(--colors-mauve-2)",
    "variable": "var(--colors-mauve-2)"
  },
  "colors.mauve.3": {
    "value": "var(--colors-mauve-3)",
    "variable": "var(--colors-mauve-3)"
  },
  "colors.mauve.4": {
    "value": "var(--colors-mauve-4)",
    "variable": "var(--colors-mauve-4)"
  },
  "colors.mauve.5": {
    "value": "var(--colors-mauve-5)",
    "variable": "var(--colors-mauve-5)"
  },
  "colors.mauve.6": {
    "value": "var(--colors-mauve-6)",
    "variable": "var(--colors-mauve-6)"
  },
  "colors.mauve.7": {
    "value": "var(--colors-mauve-7)",
    "variable": "var(--colors-mauve-7)"
  },
  "colors.mauve.8": {
    "value": "var(--colors-mauve-8)",
    "variable": "var(--colors-mauve-8)"
  },
  "colors.mauve.9": {
    "value": "var(--colors-mauve-9)",
    "variable": "var(--colors-mauve-9)"
  },
  "colors.mauve.10": {
    "value": "var(--colors-mauve-10)",
    "variable": "var(--colors-mauve-10)"
  },
  "colors.mauve.11": {
    "value": "var(--colors-mauve-11)",
    "variable": "var(--colors-mauve-11)"
  },
  "colors.mauve.12": {
    "value": "var(--colors-mauve-12)",
    "variable": "var(--colors-mauve-12)"
  },
  "colors.mauve.light.1": {
    "value": "var(--colors-mauve-light-1)",
    "variable": "var(--colors-mauve-light-1)"
  },
  "colors.mauve.light.2": {
    "value": "var(--colors-mauve-light-2)",
    "variable": "var(--colors-mauve-light-2)"
  },
  "colors.mauve.light.3": {
    "value": "var(--colors-mauve-light-3)",
    "variable": "var(--colors-mauve-light-3)"
  },
  "colors.mauve.light.4": {
    "value": "var(--colors-mauve-light-4)",
    "variable": "var(--colors-mauve-light-4)"
  },
  "colors.mauve.light.5": {
    "value": "var(--colors-mauve-light-5)",
    "variable": "var(--colors-mauve-light-5)"
  },
  "colors.mauve.light.6": {
    "value": "var(--colors-mauve-light-6)",
    "variable": "var(--colors-mauve-light-6)"
  },
  "colors.mauve.light.7": {
    "value": "var(--colors-mauve-light-7)",
    "variable": "var(--colors-mauve-light-7)"
  },
  "colors.mauve.light.8": {
    "value": "var(--colors-mauve-light-8)",
    "variable": "var(--colors-mauve-light-8)"
  },
  "colors.mauve.light.9": {
    "value": "var(--colors-mauve-light-9)",
    "variable": "var(--colors-mauve-light-9)"
  },
  "colors.mauve.light.10": {
    "value": "var(--colors-mauve-light-10)",
    "variable": "var(--colors-mauve-light-10)"
  },
  "colors.mauve.light.11": {
    "value": "var(--colors-mauve-light-11)",
    "variable": "var(--colors-mauve-light-11)"
  },
  "colors.mauve.light.12": {
    "value": "var(--colors-mauve-light-12)",
    "variable": "var(--colors-mauve-light-12)"
  },
  "colors.mauve.light.a.1": {
    "value": "var(--colors-mauve-light-a-1)",
    "variable": "var(--colors-mauve-light-a-1)"
  },
  "colors.mauve.light.a.2": {
    "value": "var(--colors-mauve-light-a-2)",
    "variable": "var(--colors-mauve-light-a-2)"
  },
  "colors.mauve.light.a.3": {
    "value": "var(--colors-mauve-light-a-3)",
    "variable": "var(--colors-mauve-light-a-3)"
  },
  "colors.mauve.light.a.4": {
    "value": "var(--colors-mauve-light-a-4)",
    "variable": "var(--colors-mauve-light-a-4)"
  },
  "colors.mauve.light.a.5": {
    "value": "var(--colors-mauve-light-a-5)",
    "variable": "var(--colors-mauve-light-a-5)"
  },
  "colors.mauve.light.a.6": {
    "value": "var(--colors-mauve-light-a-6)",
    "variable": "var(--colors-mauve-light-a-6)"
  },
  "colors.mauve.light.a.7": {
    "value": "var(--colors-mauve-light-a-7)",
    "variable": "var(--colors-mauve-light-a-7)"
  },
  "colors.mauve.light.a.8": {
    "value": "var(--colors-mauve-light-a-8)",
    "variable": "var(--colors-mauve-light-a-8)"
  },
  "colors.mauve.light.a.9": {
    "value": "var(--colors-mauve-light-a-9)",
    "variable": "var(--colors-mauve-light-a-9)"
  },
  "colors.mauve.light.a.10": {
    "value": "var(--colors-mauve-light-a-10)",
    "variable": "var(--colors-mauve-light-a-10)"
  },
  "colors.mauve.light.a.11": {
    "value": "var(--colors-mauve-light-a-11)",
    "variable": "var(--colors-mauve-light-a-11)"
  },
  "colors.mauve.light.a.12": {
    "value": "var(--colors-mauve-light-a-12)",
    "variable": "var(--colors-mauve-light-a-12)"
  },
  "colors.mauve.light.p3.1": {
    "value": "color(display-p3 0.991 0.988 0.992)",
    "variable": "var(--colors-mauve-light-p3-1)"
  },
  "colors.mauve.light.p3.2": {
    "value": "color(display-p3 0.98 0.976 0.984)",
    "variable": "var(--colors-mauve-light-p3-2)"
  },
  "colors.mauve.light.p3.3": {
    "value": "color(display-p3 0.946 0.938 0.952)",
    "variable": "var(--colors-mauve-light-p3-3)"
  },
  "colors.mauve.light.p3.4": {
    "value": "color(display-p3 0.915 0.906 0.925)",
    "variable": "var(--colors-mauve-light-p3-4)"
  },
  "colors.mauve.light.p3.5": {
    "value": "color(display-p3 0.886 0.876 0.901)",
    "variable": "var(--colors-mauve-light-p3-5)"
  },
  "colors.mauve.light.p3.6": {
    "value": "color(display-p3 0.856 0.846 0.875)",
    "variable": "var(--colors-mauve-light-p3-6)"
  },
  "colors.mauve.light.p3.7": {
    "value": "color(display-p3 0.814 0.804 0.84)",
    "variable": "var(--colors-mauve-light-p3-7)"
  },
  "colors.mauve.light.p3.8": {
    "value": "color(display-p3 0.735 0.728 0.777)",
    "variable": "var(--colors-mauve-light-p3-8)"
  },
  "colors.mauve.light.p3.9": {
    "value": "color(display-p3 0.555 0.549 0.596)",
    "variable": "var(--colors-mauve-light-p3-9)"
  },
  "colors.mauve.light.p3.10": {
    "value": "color(display-p3 0.514 0.508 0.552)",
    "variable": "var(--colors-mauve-light-p3-10)"
  },
  "colors.mauve.light.p3.11": {
    "value": "color(display-p3 0.395 0.388 0.424)",
    "variable": "var(--colors-mauve-light-p3-11)"
  },
  "colors.mauve.light.p3.12": {
    "value": "color(display-p3 0.128 0.122 0.147)",
    "variable": "var(--colors-mauve-light-p3-12)"
  },
  "colors.mauve.light.p3.a.1": {
    "value": "color(display-p3 0.349 0.024 0.349 / 0.012)",
    "variable": "var(--colors-mauve-light-p3-a-1)"
  },
  "colors.mauve.light.p3.a.2": {
    "value": "color(display-p3 0.184 0.024 0.349 / 0.024)",
    "variable": "var(--colors-mauve-light-p3-a-2)"
  },
  "colors.mauve.light.p3.a.3": {
    "value": "color(display-p3 0.129 0.008 0.255 / 0.063)",
    "variable": "var(--colors-mauve-light-p3-a-3)"
  },
  "colors.mauve.light.p3.a.4": {
    "value": "color(display-p3 0.094 0.012 0.216 / 0.095)",
    "variable": "var(--colors-mauve-light-p3-a-4)"
  },
  "colors.mauve.light.p3.a.5": {
    "value": "color(display-p3 0.098 0.008 0.224 / 0.126)",
    "variable": "var(--colors-mauve-light-p3-a-5)"
  },
  "colors.mauve.light.p3.a.6": {
    "value": "color(display-p3 0.055 0.004 0.18 / 0.153)",
    "variable": "var(--colors-mauve-light-p3-a-6)"
  },
  "colors.mauve.light.p3.a.7": {
    "value": "color(display-p3 0.067 0.008 0.184 / 0.197)",
    "variable": "var(--colors-mauve-light-p3-a-7)"
  },
  "colors.mauve.light.p3.a.8": {
    "value": "color(display-p3 0.02 0.004 0.176 / 0.271)",
    "variable": "var(--colors-mauve-light-p3-a-8)"
  },
  "colors.mauve.light.p3.a.9": {
    "value": "color(display-p3 0.02 0.004 0.106 / 0.451)",
    "variable": "var(--colors-mauve-light-p3-a-9)"
  },
  "colors.mauve.light.p3.a.10": {
    "value": "color(display-p3 0.012 0.004 0.09 / 0.491)",
    "variable": "var(--colors-mauve-light-p3-a-10)"
  },
  "colors.mauve.light.p3.a.11": {
    "value": "color(display-p3 0.016 0 0.059 / 0.612)",
    "variable": "var(--colors-mauve-light-p3-a-11)"
  },
  "colors.mauve.light.p3.a.12": {
    "value": "color(display-p3 0.008 0 0.027 / 0.879)",
    "variable": "var(--colors-mauve-light-p3-a-12)"
  },
  "colors.mauve.dark.1": {
    "value": "var(--colors-mauve-dark-1)",
    "variable": "var(--colors-mauve-dark-1)"
  },
  "colors.mauve.dark.2": {
    "value": "var(--colors-mauve-dark-2)",
    "variable": "var(--colors-mauve-dark-2)"
  },
  "colors.mauve.dark.3": {
    "value": "var(--colors-mauve-dark-3)",
    "variable": "var(--colors-mauve-dark-3)"
  },
  "colors.mauve.dark.4": {
    "value": "var(--colors-mauve-dark-4)",
    "variable": "var(--colors-mauve-dark-4)"
  },
  "colors.mauve.dark.5": {
    "value": "var(--colors-mauve-dark-5)",
    "variable": "var(--colors-mauve-dark-5)"
  },
  "colors.mauve.dark.6": {
    "value": "var(--colors-mauve-dark-6)",
    "variable": "var(--colors-mauve-dark-6)"
  },
  "colors.mauve.dark.7": {
    "value": "var(--colors-mauve-dark-7)",
    "variable": "var(--colors-mauve-dark-7)"
  },
  "colors.mauve.dark.8": {
    "value": "var(--colors-mauve-dark-8)",
    "variable": "var(--colors-mauve-dark-8)"
  },
  "colors.mauve.dark.9": {
    "value": "var(--colors-mauve-dark-9)",
    "variable": "var(--colors-mauve-dark-9)"
  },
  "colors.mauve.dark.10": {
    "value": "var(--colors-mauve-dark-10)",
    "variable": "var(--colors-mauve-dark-10)"
  },
  "colors.mauve.dark.11": {
    "value": "var(--colors-mauve-dark-11)",
    "variable": "var(--colors-mauve-dark-11)"
  },
  "colors.mauve.dark.12": {
    "value": "var(--colors-mauve-dark-12)",
    "variable": "var(--colors-mauve-dark-12)"
  },
  "colors.mauve.dark.a.1": {
    "value": "var(--colors-mauve-dark-a-1)",
    "variable": "var(--colors-mauve-dark-a-1)"
  },
  "colors.mauve.dark.a.2": {
    "value": "var(--colors-mauve-dark-a-2)",
    "variable": "var(--colors-mauve-dark-a-2)"
  },
  "colors.mauve.dark.a.3": {
    "value": "var(--colors-mauve-dark-a-3)",
    "variable": "var(--colors-mauve-dark-a-3)"
  },
  "colors.mauve.dark.a.4": {
    "value": "var(--colors-mauve-dark-a-4)",
    "variable": "var(--colors-mauve-dark-a-4)"
  },
  "colors.mauve.dark.a.5": {
    "value": "var(--colors-mauve-dark-a-5)",
    "variable": "var(--colors-mauve-dark-a-5)"
  },
  "colors.mauve.dark.a.6": {
    "value": "var(--colors-mauve-dark-a-6)",
    "variable": "var(--colors-mauve-dark-a-6)"
  },
  "colors.mauve.dark.a.7": {
    "value": "var(--colors-mauve-dark-a-7)",
    "variable": "var(--colors-mauve-dark-a-7)"
  },
  "colors.mauve.dark.a.8": {
    "value": "var(--colors-mauve-dark-a-8)",
    "variable": "var(--colors-mauve-dark-a-8)"
  },
  "colors.mauve.dark.a.9": {
    "value": "var(--colors-mauve-dark-a-9)",
    "variable": "var(--colors-mauve-dark-a-9)"
  },
  "colors.mauve.dark.a.10": {
    "value": "var(--colors-mauve-dark-a-10)",
    "variable": "var(--colors-mauve-dark-a-10)"
  },
  "colors.mauve.dark.a.11": {
    "value": "var(--colors-mauve-dark-a-11)",
    "variable": "var(--colors-mauve-dark-a-11)"
  },
  "colors.mauve.dark.a.12": {
    "value": "var(--colors-mauve-dark-a-12)",
    "variable": "var(--colors-mauve-dark-a-12)"
  },
  "colors.mauve.dark.p3.1": {
    "value": "color(display-p3 0.07 0.067 0.074)",
    "variable": "var(--colors-mauve-dark-p3-1)"
  },
  "colors.mauve.dark.p3.2": {
    "value": "color(display-p3 0.101 0.098 0.105)",
    "variable": "var(--colors-mauve-dark-p3-2)"
  },
  "colors.mauve.dark.p3.3": {
    "value": "color(display-p3 0.138 0.134 0.144)",
    "variable": "var(--colors-mauve-dark-p3-3)"
  },
  "colors.mauve.dark.p3.4": {
    "value": "color(display-p3 0.167 0.161 0.175)",
    "variable": "var(--colors-mauve-dark-p3-4)"
  },
  "colors.mauve.dark.p3.5": {
    "value": "color(display-p3 0.196 0.189 0.206)",
    "variable": "var(--colors-mauve-dark-p3-5)"
  },
  "colors.mauve.dark.p3.6": {
    "value": "color(display-p3 0.232 0.225 0.245)",
    "variable": "var(--colors-mauve-dark-p3-6)"
  },
  "colors.mauve.dark.p3.7": {
    "value": "color(display-p3 0.286 0.277 0.302)",
    "variable": "var(--colors-mauve-dark-p3-7)"
  },
  "colors.mauve.dark.p3.8": {
    "value": "color(display-p3 0.383 0.373 0.408)",
    "variable": "var(--colors-mauve-dark-p3-8)"
  },
  "colors.mauve.dark.p3.9": {
    "value": "color(display-p3 0.434 0.428 0.467)",
    "variable": "var(--colors-mauve-dark-p3-9)"
  },
  "colors.mauve.dark.p3.10": {
    "value": "color(display-p3 0.487 0.48 0.519)",
    "variable": "var(--colors-mauve-dark-p3-10)"
  },
  "colors.mauve.dark.p3.11": {
    "value": "color(display-p3 0.707 0.7 0.735)",
    "variable": "var(--colors-mauve-dark-p3-11)"
  },
  "colors.mauve.dark.p3.12": {
    "value": "color(display-p3 0.933 0.933 0.94)",
    "variable": "var(--colors-mauve-dark-p3-12)"
  },
  "colors.mauve.dark.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0)",
    "variable": "var(--colors-mauve-dark-p3-a-1)"
  },
  "colors.mauve.dark.p3.a.2": {
    "value": "color(display-p3 0.996 0.992 1 / 0.034)",
    "variable": "var(--colors-mauve-dark-p3-a-2)"
  },
  "colors.mauve.dark.p3.a.3": {
    "value": "color(display-p3 0.937 0.933 0.992 / 0.077)",
    "variable": "var(--colors-mauve-dark-p3-a-3)"
  },
  "colors.mauve.dark.p3.a.4": {
    "value": "color(display-p3 0.957 0.918 0.996 / 0.111)",
    "variable": "var(--colors-mauve-dark-p3-a-4)"
  },
  "colors.mauve.dark.p3.a.5": {
    "value": "color(display-p3 0.937 0.906 0.996 / 0.145)",
    "variable": "var(--colors-mauve-dark-p3-a-5)"
  },
  "colors.mauve.dark.p3.a.6": {
    "value": "color(display-p3 0.953 0.925 0.996 / 0.183)",
    "variable": "var(--colors-mauve-dark-p3-a-6)"
  },
  "colors.mauve.dark.p3.a.7": {
    "value": "color(display-p3 0.945 0.929 1 / 0.246)",
    "variable": "var(--colors-mauve-dark-p3-a-7)"
  },
  "colors.mauve.dark.p3.a.8": {
    "value": "color(display-p3 0.937 0.918 1 / 0.361)",
    "variable": "var(--colors-mauve-dark-p3-a-8)"
  },
  "colors.mauve.dark.p3.a.9": {
    "value": "color(display-p3 0.933 0.918 1 / 0.424)",
    "variable": "var(--colors-mauve-dark-p3-a-9)"
  },
  "colors.mauve.dark.p3.a.10": {
    "value": "color(display-p3 0.941 0.925 1 / 0.479)",
    "variable": "var(--colors-mauve-dark-p3-a-10)"
  },
  "colors.mauve.dark.p3.a.11": {
    "value": "color(display-p3 0.965 0.961 1 / 0.712)",
    "variable": "var(--colors-mauve-dark-p3-a-11)"
  },
  "colors.mauve.dark.p3.a.12": {
    "value": "color(display-p3 0.992 0.992 1 / 0.937)",
    "variable": "var(--colors-mauve-dark-p3-a-12)"
  },
  "colors.mauve.a.1": {
    "value": "var(--colors-mauve-a-1)",
    "variable": "var(--colors-mauve-a-1)"
  },
  "colors.mauve.a.2": {
    "value": "var(--colors-mauve-a-2)",
    "variable": "var(--colors-mauve-a-2)"
  },
  "colors.mauve.a.3": {
    "value": "var(--colors-mauve-a-3)",
    "variable": "var(--colors-mauve-a-3)"
  },
  "colors.mauve.a.4": {
    "value": "var(--colors-mauve-a-4)",
    "variable": "var(--colors-mauve-a-4)"
  },
  "colors.mauve.a.5": {
    "value": "var(--colors-mauve-a-5)",
    "variable": "var(--colors-mauve-a-5)"
  },
  "colors.mauve.a.6": {
    "value": "var(--colors-mauve-a-6)",
    "variable": "var(--colors-mauve-a-6)"
  },
  "colors.mauve.a.7": {
    "value": "var(--colors-mauve-a-7)",
    "variable": "var(--colors-mauve-a-7)"
  },
  "colors.mauve.a.8": {
    "value": "var(--colors-mauve-a-8)",
    "variable": "var(--colors-mauve-a-8)"
  },
  "colors.mauve.a.9": {
    "value": "var(--colors-mauve-a-9)",
    "variable": "var(--colors-mauve-a-9)"
  },
  "colors.mauve.a.10": {
    "value": "var(--colors-mauve-a-10)",
    "variable": "var(--colors-mauve-a-10)"
  },
  "colors.mauve.a.11": {
    "value": "var(--colors-mauve-a-11)",
    "variable": "var(--colors-mauve-a-11)"
  },
  "colors.mauve.a.12": {
    "value": "var(--colors-mauve-a-12)",
    "variable": "var(--colors-mauve-a-12)"
  },
  "colors.mauve.p3.1": {
    "value": "var(--colors-mauve-p3-1)",
    "variable": "var(--colors-mauve-p3-1)"
  },
  "colors.mauve.p3.2": {
    "value": "var(--colors-mauve-p3-2)",
    "variable": "var(--colors-mauve-p3-2)"
  },
  "colors.mauve.p3.3": {
    "value": "var(--colors-mauve-p3-3)",
    "variable": "var(--colors-mauve-p3-3)"
  },
  "colors.mauve.p3.4": {
    "value": "var(--colors-mauve-p3-4)",
    "variable": "var(--colors-mauve-p3-4)"
  },
  "colors.mauve.p3.5": {
    "value": "var(--colors-mauve-p3-5)",
    "variable": "var(--colors-mauve-p3-5)"
  },
  "colors.mauve.p3.6": {
    "value": "var(--colors-mauve-p3-6)",
    "variable": "var(--colors-mauve-p3-6)"
  },
  "colors.mauve.p3.7": {
    "value": "var(--colors-mauve-p3-7)",
    "variable": "var(--colors-mauve-p3-7)"
  },
  "colors.mauve.p3.8": {
    "value": "var(--colors-mauve-p3-8)",
    "variable": "var(--colors-mauve-p3-8)"
  },
  "colors.mauve.p3.9": {
    "value": "var(--colors-mauve-p3-9)",
    "variable": "var(--colors-mauve-p3-9)"
  },
  "colors.mauve.p3.10": {
    "value": "var(--colors-mauve-p3-10)",
    "variable": "var(--colors-mauve-p3-10)"
  },
  "colors.mauve.p3.11": {
    "value": "var(--colors-mauve-p3-11)",
    "variable": "var(--colors-mauve-p3-11)"
  },
  "colors.mauve.p3.12": {
    "value": "var(--colors-mauve-p3-12)",
    "variable": "var(--colors-mauve-p3-12)"
  },
  "colors.mauve.p3.a.1": {
    "value": "var(--colors-mauve-p3-a-1)",
    "variable": "var(--colors-mauve-p3-a-1)"
  },
  "colors.mauve.p3.a.2": {
    "value": "var(--colors-mauve-p3-a-2)",
    "variable": "var(--colors-mauve-p3-a-2)"
  },
  "colors.mauve.p3.a.3": {
    "value": "var(--colors-mauve-p3-a-3)",
    "variable": "var(--colors-mauve-p3-a-3)"
  },
  "colors.mauve.p3.a.4": {
    "value": "var(--colors-mauve-p3-a-4)",
    "variable": "var(--colors-mauve-p3-a-4)"
  },
  "colors.mauve.p3.a.5": {
    "value": "var(--colors-mauve-p3-a-5)",
    "variable": "var(--colors-mauve-p3-a-5)"
  },
  "colors.mauve.p3.a.6": {
    "value": "var(--colors-mauve-p3-a-6)",
    "variable": "var(--colors-mauve-p3-a-6)"
  },
  "colors.mauve.p3.a.7": {
    "value": "var(--colors-mauve-p3-a-7)",
    "variable": "var(--colors-mauve-p3-a-7)"
  },
  "colors.mauve.p3.a.8": {
    "value": "var(--colors-mauve-p3-a-8)",
    "variable": "var(--colors-mauve-p3-a-8)"
  },
  "colors.mauve.p3.a.9": {
    "value": "var(--colors-mauve-p3-a-9)",
    "variable": "var(--colors-mauve-p3-a-9)"
  },
  "colors.mauve.p3.a.10": {
    "value": "var(--colors-mauve-p3-a-10)",
    "variable": "var(--colors-mauve-p3-a-10)"
  },
  "colors.mauve.p3.a.11": {
    "value": "var(--colors-mauve-p3-a-11)",
    "variable": "var(--colors-mauve-p3-a-11)"
  },
  "colors.mauve.p3.a.12": {
    "value": "var(--colors-mauve-p3-a-12)",
    "variable": "var(--colors-mauve-p3-a-12)"
  },
  "colors.mint.1": {
    "value": "var(--colors-mint-1)",
    "variable": "var(--colors-mint-1)"
  },
  "colors.mint.2": {
    "value": "var(--colors-mint-2)",
    "variable": "var(--colors-mint-2)"
  },
  "colors.mint.3": {
    "value": "var(--colors-mint-3)",
    "variable": "var(--colors-mint-3)"
  },
  "colors.mint.4": {
    "value": "var(--colors-mint-4)",
    "variable": "var(--colors-mint-4)"
  },
  "colors.mint.5": {
    "value": "var(--colors-mint-5)",
    "variable": "var(--colors-mint-5)"
  },
  "colors.mint.6": {
    "value": "var(--colors-mint-6)",
    "variable": "var(--colors-mint-6)"
  },
  "colors.mint.7": {
    "value": "var(--colors-mint-7)",
    "variable": "var(--colors-mint-7)"
  },
  "colors.mint.8": {
    "value": "var(--colors-mint-8)",
    "variable": "var(--colors-mint-8)"
  },
  "colors.mint.9": {
    "value": "var(--colors-mint-9)",
    "variable": "var(--colors-mint-9)"
  },
  "colors.mint.10": {
    "value": "var(--colors-mint-10)",
    "variable": "var(--colors-mint-10)"
  },
  "colors.mint.11": {
    "value": "var(--colors-mint-11)",
    "variable": "var(--colors-mint-11)"
  },
  "colors.mint.12": {
    "value": "var(--colors-mint-12)",
    "variable": "var(--colors-mint-12)"
  },
  "colors.mint.light.1": {
    "value": "var(--colors-mint-light-1)",
    "variable": "var(--colors-mint-light-1)"
  },
  "colors.mint.light.2": {
    "value": "var(--colors-mint-light-2)",
    "variable": "var(--colors-mint-light-2)"
  },
  "colors.mint.light.3": {
    "value": "var(--colors-mint-light-3)",
    "variable": "var(--colors-mint-light-3)"
  },
  "colors.mint.light.4": {
    "value": "var(--colors-mint-light-4)",
    "variable": "var(--colors-mint-light-4)"
  },
  "colors.mint.light.5": {
    "value": "var(--colors-mint-light-5)",
    "variable": "var(--colors-mint-light-5)"
  },
  "colors.mint.light.6": {
    "value": "var(--colors-mint-light-6)",
    "variable": "var(--colors-mint-light-6)"
  },
  "colors.mint.light.7": {
    "value": "var(--colors-mint-light-7)",
    "variable": "var(--colors-mint-light-7)"
  },
  "colors.mint.light.8": {
    "value": "var(--colors-mint-light-8)",
    "variable": "var(--colors-mint-light-8)"
  },
  "colors.mint.light.9": {
    "value": "var(--colors-mint-light-9)",
    "variable": "var(--colors-mint-light-9)"
  },
  "colors.mint.light.10": {
    "value": "var(--colors-mint-light-10)",
    "variable": "var(--colors-mint-light-10)"
  },
  "colors.mint.light.11": {
    "value": "var(--colors-mint-light-11)",
    "variable": "var(--colors-mint-light-11)"
  },
  "colors.mint.light.12": {
    "value": "var(--colors-mint-light-12)",
    "variable": "var(--colors-mint-light-12)"
  },
  "colors.mint.light.a.1": {
    "value": "var(--colors-mint-light-a-1)",
    "variable": "var(--colors-mint-light-a-1)"
  },
  "colors.mint.light.a.2": {
    "value": "var(--colors-mint-light-a-2)",
    "variable": "var(--colors-mint-light-a-2)"
  },
  "colors.mint.light.a.3": {
    "value": "var(--colors-mint-light-a-3)",
    "variable": "var(--colors-mint-light-a-3)"
  },
  "colors.mint.light.a.4": {
    "value": "var(--colors-mint-light-a-4)",
    "variable": "var(--colors-mint-light-a-4)"
  },
  "colors.mint.light.a.5": {
    "value": "var(--colors-mint-light-a-5)",
    "variable": "var(--colors-mint-light-a-5)"
  },
  "colors.mint.light.a.6": {
    "value": "var(--colors-mint-light-a-6)",
    "variable": "var(--colors-mint-light-a-6)"
  },
  "colors.mint.light.a.7": {
    "value": "var(--colors-mint-light-a-7)",
    "variable": "var(--colors-mint-light-a-7)"
  },
  "colors.mint.light.a.8": {
    "value": "var(--colors-mint-light-a-8)",
    "variable": "var(--colors-mint-light-a-8)"
  },
  "colors.mint.light.a.9": {
    "value": "var(--colors-mint-light-a-9)",
    "variable": "var(--colors-mint-light-a-9)"
  },
  "colors.mint.light.a.10": {
    "value": "var(--colors-mint-light-a-10)",
    "variable": "var(--colors-mint-light-a-10)"
  },
  "colors.mint.light.a.11": {
    "value": "var(--colors-mint-light-a-11)",
    "variable": "var(--colors-mint-light-a-11)"
  },
  "colors.mint.light.a.12": {
    "value": "var(--colors-mint-light-a-12)",
    "variable": "var(--colors-mint-light-a-12)"
  },
  "colors.mint.light.p3.1": {
    "value": "color(display-p3 0.98 0.995 0.992)",
    "variable": "var(--colors-mint-light-p3-1)"
  },
  "colors.mint.light.p3.2": {
    "value": "color(display-p3 0.957 0.985 0.977)",
    "variable": "var(--colors-mint-light-p3-2)"
  },
  "colors.mint.light.p3.3": {
    "value": "color(display-p3 0.888 0.972 0.95)",
    "variable": "var(--colors-mint-light-p3-3)"
  },
  "colors.mint.light.p3.4": {
    "value": "color(display-p3 0.819 0.951 0.916)",
    "variable": "var(--colors-mint-light-p3-4)"
  },
  "colors.mint.light.p3.5": {
    "value": "color(display-p3 0.747 0.918 0.873)",
    "variable": "var(--colors-mint-light-p3-5)"
  },
  "colors.mint.light.p3.6": {
    "value": "color(display-p3 0.668 0.87 0.818)",
    "variable": "var(--colors-mint-light-p3-6)"
  },
  "colors.mint.light.p3.7": {
    "value": "color(display-p3 0.567 0.805 0.744)",
    "variable": "var(--colors-mint-light-p3-7)"
  },
  "colors.mint.light.p3.8": {
    "value": "color(display-p3 0.42 0.724 0.649)",
    "variable": "var(--colors-mint-light-p3-8)"
  },
  "colors.mint.light.p3.9": {
    "value": "color(display-p3 0.62 0.908 0.834)",
    "variable": "var(--colors-mint-light-p3-9)"
  },
  "colors.mint.light.p3.10": {
    "value": "color(display-p3 0.585 0.871 0.797)",
    "variable": "var(--colors-mint-light-p3-10)"
  },
  "colors.mint.light.p3.11": {
    "value": "color(display-p3 0.203 0.463 0.397)",
    "variable": "var(--colors-mint-light-p3-11)"
  },
  "colors.mint.light.p3.12": {
    "value": "color(display-p3 0.136 0.259 0.236)",
    "variable": "var(--colors-mint-light-p3-12)"
  },
  "colors.mint.light.p3.a.1": {
    "value": "color(display-p3 0.02 0.804 0.608 / 0.02)",
    "variable": "var(--colors-mint-light-p3-a-1)"
  },
  "colors.mint.light.p3.a.2": {
    "value": "color(display-p3 0.02 0.647 0.467 / 0.044)",
    "variable": "var(--colors-mint-light-p3-a-2)"
  },
  "colors.mint.light.p3.a.3": {
    "value": "color(display-p3 0.004 0.761 0.553 / 0.114)",
    "variable": "var(--colors-mint-light-p3-a-3)"
  },
  "colors.mint.light.p3.a.4": {
    "value": "color(display-p3 0.004 0.741 0.545 / 0.181)",
    "variable": "var(--colors-mint-light-p3-a-4)"
  },
  "colors.mint.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.678 0.51 / 0.255)",
    "variable": "var(--colors-mint-light-p3-a-5)"
  },
  "colors.mint.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.616 0.463 / 0.334)",
    "variable": "var(--colors-mint-light-p3-a-6)"
  },
  "colors.mint.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.549 0.412 / 0.432)",
    "variable": "var(--colors-mint-light-p3-a-7)"
  },
  "colors.mint.light.p3.a.8": {
    "value": "color(display-p3 0 0.529 0.392 / 0.581)",
    "variable": "var(--colors-mint-light-p3-a-8)"
  },
  "colors.mint.light.p3.a.9": {
    "value": "color(display-p3 0.004 0.765 0.569 / 0.381)",
    "variable": "var(--colors-mint-light-p3-a-9)"
  },
  "colors.mint.light.p3.a.10": {
    "value": "color(display-p3 0.004 0.69 0.51 / 0.416)",
    "variable": "var(--colors-mint-light-p3-a-10)"
  },
  "colors.mint.light.p3.a.11": {
    "value": "color(display-p3 0.203 0.463 0.397)",
    "variable": "var(--colors-mint-light-p3-a-11)"
  },
  "colors.mint.light.p3.a.12": {
    "value": "color(display-p3 0.136 0.259 0.236)",
    "variable": "var(--colors-mint-light-p3-a-12)"
  },
  "colors.mint.dark.1": {
    "value": "var(--colors-mint-dark-1)",
    "variable": "var(--colors-mint-dark-1)"
  },
  "colors.mint.dark.2": {
    "value": "var(--colors-mint-dark-2)",
    "variable": "var(--colors-mint-dark-2)"
  },
  "colors.mint.dark.3": {
    "value": "var(--colors-mint-dark-3)",
    "variable": "var(--colors-mint-dark-3)"
  },
  "colors.mint.dark.4": {
    "value": "var(--colors-mint-dark-4)",
    "variable": "var(--colors-mint-dark-4)"
  },
  "colors.mint.dark.5": {
    "value": "var(--colors-mint-dark-5)",
    "variable": "var(--colors-mint-dark-5)"
  },
  "colors.mint.dark.6": {
    "value": "var(--colors-mint-dark-6)",
    "variable": "var(--colors-mint-dark-6)"
  },
  "colors.mint.dark.7": {
    "value": "var(--colors-mint-dark-7)",
    "variable": "var(--colors-mint-dark-7)"
  },
  "colors.mint.dark.8": {
    "value": "var(--colors-mint-dark-8)",
    "variable": "var(--colors-mint-dark-8)"
  },
  "colors.mint.dark.9": {
    "value": "var(--colors-mint-dark-9)",
    "variable": "var(--colors-mint-dark-9)"
  },
  "colors.mint.dark.10": {
    "value": "var(--colors-mint-dark-10)",
    "variable": "var(--colors-mint-dark-10)"
  },
  "colors.mint.dark.11": {
    "value": "var(--colors-mint-dark-11)",
    "variable": "var(--colors-mint-dark-11)"
  },
  "colors.mint.dark.12": {
    "value": "var(--colors-mint-dark-12)",
    "variable": "var(--colors-mint-dark-12)"
  },
  "colors.mint.dark.a.1": {
    "value": "var(--colors-mint-dark-a-1)",
    "variable": "var(--colors-mint-dark-a-1)"
  },
  "colors.mint.dark.a.2": {
    "value": "var(--colors-mint-dark-a-2)",
    "variable": "var(--colors-mint-dark-a-2)"
  },
  "colors.mint.dark.a.3": {
    "value": "var(--colors-mint-dark-a-3)",
    "variable": "var(--colors-mint-dark-a-3)"
  },
  "colors.mint.dark.a.4": {
    "value": "var(--colors-mint-dark-a-4)",
    "variable": "var(--colors-mint-dark-a-4)"
  },
  "colors.mint.dark.a.5": {
    "value": "var(--colors-mint-dark-a-5)",
    "variable": "var(--colors-mint-dark-a-5)"
  },
  "colors.mint.dark.a.6": {
    "value": "var(--colors-mint-dark-a-6)",
    "variable": "var(--colors-mint-dark-a-6)"
  },
  "colors.mint.dark.a.7": {
    "value": "var(--colors-mint-dark-a-7)",
    "variable": "var(--colors-mint-dark-a-7)"
  },
  "colors.mint.dark.a.8": {
    "value": "var(--colors-mint-dark-a-8)",
    "variable": "var(--colors-mint-dark-a-8)"
  },
  "colors.mint.dark.a.9": {
    "value": "var(--colors-mint-dark-a-9)",
    "variable": "var(--colors-mint-dark-a-9)"
  },
  "colors.mint.dark.a.10": {
    "value": "var(--colors-mint-dark-a-10)",
    "variable": "var(--colors-mint-dark-a-10)"
  },
  "colors.mint.dark.a.11": {
    "value": "var(--colors-mint-dark-a-11)",
    "variable": "var(--colors-mint-dark-a-11)"
  },
  "colors.mint.dark.a.12": {
    "value": "var(--colors-mint-dark-a-12)",
    "variable": "var(--colors-mint-dark-a-12)"
  },
  "colors.mint.dark.p3.1": {
    "value": "color(display-p3 0.059 0.082 0.081)",
    "variable": "var(--colors-mint-dark-p3-1)"
  },
  "colors.mint.dark.p3.2": {
    "value": "color(display-p3 0.068 0.104 0.105)",
    "variable": "var(--colors-mint-dark-p3-2)"
  },
  "colors.mint.dark.p3.3": {
    "value": "color(display-p3 0.077 0.17 0.168)",
    "variable": "var(--colors-mint-dark-p3-3)"
  },
  "colors.mint.dark.p3.4": {
    "value": "color(display-p3 0.068 0.224 0.22)",
    "variable": "var(--colors-mint-dark-p3-4)"
  },
  "colors.mint.dark.p3.5": {
    "value": "color(display-p3 0.104 0.275 0.264)",
    "variable": "var(--colors-mint-dark-p3-5)"
  },
  "colors.mint.dark.p3.6": {
    "value": "color(display-p3 0.154 0.332 0.313)",
    "variable": "var(--colors-mint-dark-p3-6)"
  },
  "colors.mint.dark.p3.7": {
    "value": "color(display-p3 0.207 0.403 0.373)",
    "variable": "var(--colors-mint-dark-p3-7)"
  },
  "colors.mint.dark.p3.8": {
    "value": "color(display-p3 0.258 0.49 0.441)",
    "variable": "var(--colors-mint-dark-p3-8)"
  },
  "colors.mint.dark.p3.9": {
    "value": "color(display-p3 0.62 0.908 0.834)",
    "variable": "var(--colors-mint-dark-p3-9)"
  },
  "colors.mint.dark.p3.10": {
    "value": "color(display-p3 0.725 0.954 0.898)",
    "variable": "var(--colors-mint-dark-p3-10)"
  },
  "colors.mint.dark.p3.11": {
    "value": "color(display-p3 0.482 0.825 0.733)",
    "variable": "var(--colors-mint-dark-p3-11)"
  },
  "colors.mint.dark.p3.12": {
    "value": "color(display-p3 0.807 0.955 0.887)",
    "variable": "var(--colors-mint-dark-p3-12)"
  },
  "colors.mint.dark.p3.a.1": {
    "value": "color(display-p3 0 0.992 0.992 / 0.017)",
    "variable": "var(--colors-mint-dark-p3-a-1)"
  },
  "colors.mint.dark.p3.a.2": {
    "value": "color(display-p3 0.071 0.98 0.98 / 0.043)",
    "variable": "var(--colors-mint-dark-p3-a-2)"
  },
  "colors.mint.dark.p3.a.3": {
    "value": "color(display-p3 0.176 0.996 0.996 / 0.11)",
    "variable": "var(--colors-mint-dark-p3-a-3)"
  },
  "colors.mint.dark.p3.a.4": {
    "value": "color(display-p3 0.071 0.996 0.973 / 0.169)",
    "variable": "var(--colors-mint-dark-p3-a-4)"
  },
  "colors.mint.dark.p3.a.5": {
    "value": "color(display-p3 0.243 1 0.949 / 0.223)",
    "variable": "var(--colors-mint-dark-p3-a-5)"
  },
  "colors.mint.dark.p3.a.6": {
    "value": "color(display-p3 0.369 1 0.933 / 0.286)",
    "variable": "var(--colors-mint-dark-p3-a-6)"
  },
  "colors.mint.dark.p3.a.7": {
    "value": "color(display-p3 0.459 1 0.914 / 0.362)",
    "variable": "var(--colors-mint-dark-p3-a-7)"
  },
  "colors.mint.dark.p3.a.8": {
    "value": "color(display-p3 0.49 1 0.89 / 0.454)",
    "variable": "var(--colors-mint-dark-p3-a-8)"
  },
  "colors.mint.dark.p3.a.9": {
    "value": "color(display-p3 0.678 0.996 0.914 / 0.904)",
    "variable": "var(--colors-mint-dark-p3-a-9)"
  },
  "colors.mint.dark.p3.a.10": {
    "value": "color(display-p3 0.761 1 0.941 / 0.95)",
    "variable": "var(--colors-mint-dark-p3-a-10)"
  },
  "colors.mint.dark.p3.a.11": {
    "value": "color(display-p3 0.482 0.825 0.733)",
    "variable": "var(--colors-mint-dark-p3-a-11)"
  },
  "colors.mint.dark.p3.a.12": {
    "value": "color(display-p3 0.807 0.955 0.887)",
    "variable": "var(--colors-mint-dark-p3-a-12)"
  },
  "colors.mint.a.1": {
    "value": "var(--colors-mint-a-1)",
    "variable": "var(--colors-mint-a-1)"
  },
  "colors.mint.a.2": {
    "value": "var(--colors-mint-a-2)",
    "variable": "var(--colors-mint-a-2)"
  },
  "colors.mint.a.3": {
    "value": "var(--colors-mint-a-3)",
    "variable": "var(--colors-mint-a-3)"
  },
  "colors.mint.a.4": {
    "value": "var(--colors-mint-a-4)",
    "variable": "var(--colors-mint-a-4)"
  },
  "colors.mint.a.5": {
    "value": "var(--colors-mint-a-5)",
    "variable": "var(--colors-mint-a-5)"
  },
  "colors.mint.a.6": {
    "value": "var(--colors-mint-a-6)",
    "variable": "var(--colors-mint-a-6)"
  },
  "colors.mint.a.7": {
    "value": "var(--colors-mint-a-7)",
    "variable": "var(--colors-mint-a-7)"
  },
  "colors.mint.a.8": {
    "value": "var(--colors-mint-a-8)",
    "variable": "var(--colors-mint-a-8)"
  },
  "colors.mint.a.9": {
    "value": "var(--colors-mint-a-9)",
    "variable": "var(--colors-mint-a-9)"
  },
  "colors.mint.a.10": {
    "value": "var(--colors-mint-a-10)",
    "variable": "var(--colors-mint-a-10)"
  },
  "colors.mint.a.11": {
    "value": "var(--colors-mint-a-11)",
    "variable": "var(--colors-mint-a-11)"
  },
  "colors.mint.a.12": {
    "value": "var(--colors-mint-a-12)",
    "variable": "var(--colors-mint-a-12)"
  },
  "colors.mint.p3.1": {
    "value": "var(--colors-mint-p3-1)",
    "variable": "var(--colors-mint-p3-1)"
  },
  "colors.mint.p3.2": {
    "value": "var(--colors-mint-p3-2)",
    "variable": "var(--colors-mint-p3-2)"
  },
  "colors.mint.p3.3": {
    "value": "var(--colors-mint-p3-3)",
    "variable": "var(--colors-mint-p3-3)"
  },
  "colors.mint.p3.4": {
    "value": "var(--colors-mint-p3-4)",
    "variable": "var(--colors-mint-p3-4)"
  },
  "colors.mint.p3.5": {
    "value": "var(--colors-mint-p3-5)",
    "variable": "var(--colors-mint-p3-5)"
  },
  "colors.mint.p3.6": {
    "value": "var(--colors-mint-p3-6)",
    "variable": "var(--colors-mint-p3-6)"
  },
  "colors.mint.p3.7": {
    "value": "var(--colors-mint-p3-7)",
    "variable": "var(--colors-mint-p3-7)"
  },
  "colors.mint.p3.8": {
    "value": "var(--colors-mint-p3-8)",
    "variable": "var(--colors-mint-p3-8)"
  },
  "colors.mint.p3.9": {
    "value": "var(--colors-mint-p3-9)",
    "variable": "var(--colors-mint-p3-9)"
  },
  "colors.mint.p3.10": {
    "value": "var(--colors-mint-p3-10)",
    "variable": "var(--colors-mint-p3-10)"
  },
  "colors.mint.p3.11": {
    "value": "var(--colors-mint-p3-11)",
    "variable": "var(--colors-mint-p3-11)"
  },
  "colors.mint.p3.12": {
    "value": "var(--colors-mint-p3-12)",
    "variable": "var(--colors-mint-p3-12)"
  },
  "colors.mint.p3.a.1": {
    "value": "var(--colors-mint-p3-a-1)",
    "variable": "var(--colors-mint-p3-a-1)"
  },
  "colors.mint.p3.a.2": {
    "value": "var(--colors-mint-p3-a-2)",
    "variable": "var(--colors-mint-p3-a-2)"
  },
  "colors.mint.p3.a.3": {
    "value": "var(--colors-mint-p3-a-3)",
    "variable": "var(--colors-mint-p3-a-3)"
  },
  "colors.mint.p3.a.4": {
    "value": "var(--colors-mint-p3-a-4)",
    "variable": "var(--colors-mint-p3-a-4)"
  },
  "colors.mint.p3.a.5": {
    "value": "var(--colors-mint-p3-a-5)",
    "variable": "var(--colors-mint-p3-a-5)"
  },
  "colors.mint.p3.a.6": {
    "value": "var(--colors-mint-p3-a-6)",
    "variable": "var(--colors-mint-p3-a-6)"
  },
  "colors.mint.p3.a.7": {
    "value": "var(--colors-mint-p3-a-7)",
    "variable": "var(--colors-mint-p3-a-7)"
  },
  "colors.mint.p3.a.8": {
    "value": "var(--colors-mint-p3-a-8)",
    "variable": "var(--colors-mint-p3-a-8)"
  },
  "colors.mint.p3.a.9": {
    "value": "var(--colors-mint-p3-a-9)",
    "variable": "var(--colors-mint-p3-a-9)"
  },
  "colors.mint.p3.a.10": {
    "value": "var(--colors-mint-p3-a-10)",
    "variable": "var(--colors-mint-p3-a-10)"
  },
  "colors.mint.p3.a.11": {
    "value": "var(--colors-mint-p3-a-11)",
    "variable": "var(--colors-mint-p3-a-11)"
  },
  "colors.mint.p3.a.12": {
    "value": "var(--colors-mint-p3-a-12)",
    "variable": "var(--colors-mint-p3-a-12)"
  },
  "colors.olive.1": {
    "value": "var(--colors-olive-1)",
    "variable": "var(--colors-olive-1)"
  },
  "colors.olive.2": {
    "value": "var(--colors-olive-2)",
    "variable": "var(--colors-olive-2)"
  },
  "colors.olive.3": {
    "value": "var(--colors-olive-3)",
    "variable": "var(--colors-olive-3)"
  },
  "colors.olive.4": {
    "value": "var(--colors-olive-4)",
    "variable": "var(--colors-olive-4)"
  },
  "colors.olive.5": {
    "value": "var(--colors-olive-5)",
    "variable": "var(--colors-olive-5)"
  },
  "colors.olive.6": {
    "value": "var(--colors-olive-6)",
    "variable": "var(--colors-olive-6)"
  },
  "colors.olive.7": {
    "value": "var(--colors-olive-7)",
    "variable": "var(--colors-olive-7)"
  },
  "colors.olive.8": {
    "value": "var(--colors-olive-8)",
    "variable": "var(--colors-olive-8)"
  },
  "colors.olive.9": {
    "value": "var(--colors-olive-9)",
    "variable": "var(--colors-olive-9)"
  },
  "colors.olive.10": {
    "value": "var(--colors-olive-10)",
    "variable": "var(--colors-olive-10)"
  },
  "colors.olive.11": {
    "value": "var(--colors-olive-11)",
    "variable": "var(--colors-olive-11)"
  },
  "colors.olive.12": {
    "value": "var(--colors-olive-12)",
    "variable": "var(--colors-olive-12)"
  },
  "colors.olive.light.1": {
    "value": "var(--colors-olive-light-1)",
    "variable": "var(--colors-olive-light-1)"
  },
  "colors.olive.light.2": {
    "value": "var(--colors-olive-light-2)",
    "variable": "var(--colors-olive-light-2)"
  },
  "colors.olive.light.3": {
    "value": "var(--colors-olive-light-3)",
    "variable": "var(--colors-olive-light-3)"
  },
  "colors.olive.light.4": {
    "value": "var(--colors-olive-light-4)",
    "variable": "var(--colors-olive-light-4)"
  },
  "colors.olive.light.5": {
    "value": "var(--colors-olive-light-5)",
    "variable": "var(--colors-olive-light-5)"
  },
  "colors.olive.light.6": {
    "value": "var(--colors-olive-light-6)",
    "variable": "var(--colors-olive-light-6)"
  },
  "colors.olive.light.7": {
    "value": "var(--colors-olive-light-7)",
    "variable": "var(--colors-olive-light-7)"
  },
  "colors.olive.light.8": {
    "value": "var(--colors-olive-light-8)",
    "variable": "var(--colors-olive-light-8)"
  },
  "colors.olive.light.9": {
    "value": "var(--colors-olive-light-9)",
    "variable": "var(--colors-olive-light-9)"
  },
  "colors.olive.light.10": {
    "value": "var(--colors-olive-light-10)",
    "variable": "var(--colors-olive-light-10)"
  },
  "colors.olive.light.11": {
    "value": "var(--colors-olive-light-11)",
    "variable": "var(--colors-olive-light-11)"
  },
  "colors.olive.light.12": {
    "value": "var(--colors-olive-light-12)",
    "variable": "var(--colors-olive-light-12)"
  },
  "colors.olive.light.a.1": {
    "value": "var(--colors-olive-light-a-1)",
    "variable": "var(--colors-olive-light-a-1)"
  },
  "colors.olive.light.a.2": {
    "value": "var(--colors-olive-light-a-2)",
    "variable": "var(--colors-olive-light-a-2)"
  },
  "colors.olive.light.a.3": {
    "value": "var(--colors-olive-light-a-3)",
    "variable": "var(--colors-olive-light-a-3)"
  },
  "colors.olive.light.a.4": {
    "value": "var(--colors-olive-light-a-4)",
    "variable": "var(--colors-olive-light-a-4)"
  },
  "colors.olive.light.a.5": {
    "value": "var(--colors-olive-light-a-5)",
    "variable": "var(--colors-olive-light-a-5)"
  },
  "colors.olive.light.a.6": {
    "value": "var(--colors-olive-light-a-6)",
    "variable": "var(--colors-olive-light-a-6)"
  },
  "colors.olive.light.a.7": {
    "value": "var(--colors-olive-light-a-7)",
    "variable": "var(--colors-olive-light-a-7)"
  },
  "colors.olive.light.a.8": {
    "value": "var(--colors-olive-light-a-8)",
    "variable": "var(--colors-olive-light-a-8)"
  },
  "colors.olive.light.a.9": {
    "value": "var(--colors-olive-light-a-9)",
    "variable": "var(--colors-olive-light-a-9)"
  },
  "colors.olive.light.a.10": {
    "value": "var(--colors-olive-light-a-10)",
    "variable": "var(--colors-olive-light-a-10)"
  },
  "colors.olive.light.a.11": {
    "value": "var(--colors-olive-light-a-11)",
    "variable": "var(--colors-olive-light-a-11)"
  },
  "colors.olive.light.a.12": {
    "value": "var(--colors-olive-light-a-12)",
    "variable": "var(--colors-olive-light-a-12)"
  },
  "colors.olive.light.p3.1": {
    "value": "color(display-p3 0.989 0.992 0.989)",
    "variable": "var(--colors-olive-light-p3-1)"
  },
  "colors.olive.light.p3.2": {
    "value": "color(display-p3 0.974 0.98 0.973)",
    "variable": "var(--colors-olive-light-p3-2)"
  },
  "colors.olive.light.p3.3": {
    "value": "color(display-p3 0.939 0.945 0.937)",
    "variable": "var(--colors-olive-light-p3-3)"
  },
  "colors.olive.light.p3.4": {
    "value": "color(display-p3 0.907 0.914 0.905)",
    "variable": "var(--colors-olive-light-p3-4)"
  },
  "colors.olive.light.p3.5": {
    "value": "color(display-p3 0.878 0.885 0.875)",
    "variable": "var(--colors-olive-light-p3-5)"
  },
  "colors.olive.light.p3.6": {
    "value": "color(display-p3 0.846 0.855 0.843)",
    "variable": "var(--colors-olive-light-p3-6)"
  },
  "colors.olive.light.p3.7": {
    "value": "color(display-p3 0.803 0.812 0.8)",
    "variable": "var(--colors-olive-light-p3-7)"
  },
  "colors.olive.light.p3.8": {
    "value": "color(display-p3 0.727 0.738 0.723)",
    "variable": "var(--colors-olive-light-p3-8)"
  },
  "colors.olive.light.p3.9": {
    "value": "color(display-p3 0.541 0.556 0.532)",
    "variable": "var(--colors-olive-light-p3-9)"
  },
  "colors.olive.light.p3.10": {
    "value": "color(display-p3 0.5 0.515 0.491)",
    "variable": "var(--colors-olive-light-p3-10)"
  },
  "colors.olive.light.p3.11": {
    "value": "color(display-p3 0.38 0.395 0.374)",
    "variable": "var(--colors-olive-light-p3-11)"
  },
  "colors.olive.light.p3.12": {
    "value": "color(display-p3 0.117 0.129 0.111)",
    "variable": "var(--colors-olive-light-p3-12)"
  },
  "colors.olive.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.349 0.024 / 0.012)",
    "variable": "var(--colors-olive-light-p3-a-1)"
  },
  "colors.olive.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.302 0.024 / 0.028)",
    "variable": "var(--colors-olive-light-p3-a-2)"
  },
  "colors.olive.light.p3.a.3": {
    "value": "color(display-p3 0.008 0.129 0.008 / 0.063)",
    "variable": "var(--colors-olive-light-p3-a-3)"
  },
  "colors.olive.light.p3.a.4": {
    "value": "color(display-p3 0.012 0.094 0.012 / 0.095)",
    "variable": "var(--colors-olive-light-p3-a-4)"
  },
  "colors.olive.light.p3.a.5": {
    "value": "color(display-p3 0.035 0.098 0.008 / 0.126)",
    "variable": "var(--colors-olive-light-p3-a-5)"
  },
  "colors.olive.light.p3.a.6": {
    "value": "color(display-p3 0.027 0.078 0.004 / 0.157)",
    "variable": "var(--colors-olive-light-p3-a-6)"
  },
  "colors.olive.light.p3.a.7": {
    "value": "color(display-p3 0.02 0.059 0 / 0.2)",
    "variable": "var(--colors-olive-light-p3-a-7)"
  },
  "colors.olive.light.p3.a.8": {
    "value": "color(display-p3 0.02 0.059 0.004 / 0.279)",
    "variable": "var(--colors-olive-light-p3-a-8)"
  },
  "colors.olive.light.p3.a.9": {
    "value": "color(display-p3 0.02 0.051 0.004 / 0.467)",
    "variable": "var(--colors-olive-light-p3-a-9)"
  },
  "colors.olive.light.p3.a.10": {
    "value": "color(display-p3 0.024 0.047 0 / 0.51)",
    "variable": "var(--colors-olive-light-p3-a-10)"
  },
  "colors.olive.light.p3.a.11": {
    "value": "color(display-p3 0.012 0.039 0 / 0.628)",
    "variable": "var(--colors-olive-light-p3-a-11)"
  },
  "colors.olive.light.p3.a.12": {
    "value": "color(display-p3 0.008 0.024 0 / 0.891)",
    "variable": "var(--colors-olive-light-p3-a-12)"
  },
  "colors.olive.dark.1": {
    "value": "var(--colors-olive-dark-1)",
    "variable": "var(--colors-olive-dark-1)"
  },
  "colors.olive.dark.2": {
    "value": "var(--colors-olive-dark-2)",
    "variable": "var(--colors-olive-dark-2)"
  },
  "colors.olive.dark.3": {
    "value": "var(--colors-olive-dark-3)",
    "variable": "var(--colors-olive-dark-3)"
  },
  "colors.olive.dark.4": {
    "value": "var(--colors-olive-dark-4)",
    "variable": "var(--colors-olive-dark-4)"
  },
  "colors.olive.dark.5": {
    "value": "var(--colors-olive-dark-5)",
    "variable": "var(--colors-olive-dark-5)"
  },
  "colors.olive.dark.6": {
    "value": "var(--colors-olive-dark-6)",
    "variable": "var(--colors-olive-dark-6)"
  },
  "colors.olive.dark.7": {
    "value": "var(--colors-olive-dark-7)",
    "variable": "var(--colors-olive-dark-7)"
  },
  "colors.olive.dark.8": {
    "value": "var(--colors-olive-dark-8)",
    "variable": "var(--colors-olive-dark-8)"
  },
  "colors.olive.dark.9": {
    "value": "var(--colors-olive-dark-9)",
    "variable": "var(--colors-olive-dark-9)"
  },
  "colors.olive.dark.10": {
    "value": "var(--colors-olive-dark-10)",
    "variable": "var(--colors-olive-dark-10)"
  },
  "colors.olive.dark.11": {
    "value": "var(--colors-olive-dark-11)",
    "variable": "var(--colors-olive-dark-11)"
  },
  "colors.olive.dark.12": {
    "value": "var(--colors-olive-dark-12)",
    "variable": "var(--colors-olive-dark-12)"
  },
  "colors.olive.dark.a.1": {
    "value": "var(--colors-olive-dark-a-1)",
    "variable": "var(--colors-olive-dark-a-1)"
  },
  "colors.olive.dark.a.2": {
    "value": "var(--colors-olive-dark-a-2)",
    "variable": "var(--colors-olive-dark-a-2)"
  },
  "colors.olive.dark.a.3": {
    "value": "var(--colors-olive-dark-a-3)",
    "variable": "var(--colors-olive-dark-a-3)"
  },
  "colors.olive.dark.a.4": {
    "value": "var(--colors-olive-dark-a-4)",
    "variable": "var(--colors-olive-dark-a-4)"
  },
  "colors.olive.dark.a.5": {
    "value": "var(--colors-olive-dark-a-5)",
    "variable": "var(--colors-olive-dark-a-5)"
  },
  "colors.olive.dark.a.6": {
    "value": "var(--colors-olive-dark-a-6)",
    "variable": "var(--colors-olive-dark-a-6)"
  },
  "colors.olive.dark.a.7": {
    "value": "var(--colors-olive-dark-a-7)",
    "variable": "var(--colors-olive-dark-a-7)"
  },
  "colors.olive.dark.a.8": {
    "value": "var(--colors-olive-dark-a-8)",
    "variable": "var(--colors-olive-dark-a-8)"
  },
  "colors.olive.dark.a.9": {
    "value": "var(--colors-olive-dark-a-9)",
    "variable": "var(--colors-olive-dark-a-9)"
  },
  "colors.olive.dark.a.10": {
    "value": "var(--colors-olive-dark-a-10)",
    "variable": "var(--colors-olive-dark-a-10)"
  },
  "colors.olive.dark.a.11": {
    "value": "var(--colors-olive-dark-a-11)",
    "variable": "var(--colors-olive-dark-a-11)"
  },
  "colors.olive.dark.a.12": {
    "value": "var(--colors-olive-dark-a-12)",
    "variable": "var(--colors-olive-dark-a-12)"
  },
  "colors.olive.dark.p3.1": {
    "value": "color(display-p3 0.067 0.07 0.063)",
    "variable": "var(--colors-olive-dark-p3-1)"
  },
  "colors.olive.dark.p3.2": {
    "value": "color(display-p3 0.095 0.098 0.091)",
    "variable": "var(--colors-olive-dark-p3-2)"
  },
  "colors.olive.dark.p3.3": {
    "value": "color(display-p3 0.131 0.135 0.126)",
    "variable": "var(--colors-olive-dark-p3-3)"
  },
  "colors.olive.dark.p3.4": {
    "value": "color(display-p3 0.158 0.163 0.153)",
    "variable": "var(--colors-olive-dark-p3-4)"
  },
  "colors.olive.dark.p3.5": {
    "value": "color(display-p3 0.186 0.192 0.18)",
    "variable": "var(--colors-olive-dark-p3-5)"
  },
  "colors.olive.dark.p3.6": {
    "value": "color(display-p3 0.221 0.229 0.215)",
    "variable": "var(--colors-olive-dark-p3-6)"
  },
  "colors.olive.dark.p3.7": {
    "value": "color(display-p3 0.273 0.284 0.266)",
    "variable": "var(--colors-olive-dark-p3-7)"
  },
  "colors.olive.dark.p3.8": {
    "value": "color(display-p3 0.365 0.382 0.359)",
    "variable": "var(--colors-olive-dark-p3-8)"
  },
  "colors.olive.dark.p3.9": {
    "value": "color(display-p3 0.414 0.438 0.404)",
    "variable": "var(--colors-olive-dark-p3-9)"
  },
  "colors.olive.dark.p3.10": {
    "value": "color(display-p3 0.467 0.49 0.458)",
    "variable": "var(--colors-olive-dark-p3-10)"
  },
  "colors.olive.dark.p3.11": {
    "value": "color(display-p3 0.69 0.709 0.682)",
    "variable": "var(--colors-olive-dark-p3-11)"
  },
  "colors.olive.dark.p3.12": {
    "value": "color(display-p3 0.927 0.933 0.926)",
    "variable": "var(--colors-olive-dark-p3-12)"
  },
  "colors.olive.dark.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0)",
    "variable": "var(--colors-olive-dark-p3-a-1)"
  },
  "colors.olive.dark.p3.a.2": {
    "value": "color(display-p3 0.984 0.988 0.976 / 0.03)",
    "variable": "var(--colors-olive-dark-p3-a-2)"
  },
  "colors.olive.dark.p3.a.3": {
    "value": "color(display-p3 0.992 0.996 0.988 / 0.068)",
    "variable": "var(--colors-olive-dark-p3-a-3)"
  },
  "colors.olive.dark.p3.a.4": {
    "value": "color(display-p3 0.953 0.996 0.949 / 0.102)",
    "variable": "var(--colors-olive-dark-p3-a-4)"
  },
  "colors.olive.dark.p3.a.5": {
    "value": "color(display-p3 0.969 1 0.965 / 0.131)",
    "variable": "var(--colors-olive-dark-p3-a-5)"
  },
  "colors.olive.dark.p3.a.6": {
    "value": "color(display-p3 0.973 1 0.969 / 0.169)",
    "variable": "var(--colors-olive-dark-p3-a-6)"
  },
  "colors.olive.dark.p3.a.7": {
    "value": "color(display-p3 0.98 1 0.961 / 0.228)",
    "variable": "var(--colors-olive-dark-p3-a-7)"
  },
  "colors.olive.dark.p3.a.8": {
    "value": "color(display-p3 0.961 1 0.957 / 0.334)",
    "variable": "var(--colors-olive-dark-p3-a-8)"
  },
  "colors.olive.dark.p3.a.9": {
    "value": "color(display-p3 0.949 1 0.922 / 0.397)",
    "variable": "var(--colors-olive-dark-p3-a-9)"
  },
  "colors.olive.dark.p3.a.10": {
    "value": "color(display-p3 0.953 1 0.941 / 0.452)",
    "variable": "var(--colors-olive-dark-p3-a-10)"
  },
  "colors.olive.dark.p3.a.11": {
    "value": "color(display-p3 0.976 1 0.965 / 0.688)",
    "variable": "var(--colors-olive-dark-p3-a-11)"
  },
  "colors.olive.dark.p3.a.12": {
    "value": "color(display-p3 0.992 1 0.992 / 0.929)",
    "variable": "var(--colors-olive-dark-p3-a-12)"
  },
  "colors.olive.a.1": {
    "value": "var(--colors-olive-a-1)",
    "variable": "var(--colors-olive-a-1)"
  },
  "colors.olive.a.2": {
    "value": "var(--colors-olive-a-2)",
    "variable": "var(--colors-olive-a-2)"
  },
  "colors.olive.a.3": {
    "value": "var(--colors-olive-a-3)",
    "variable": "var(--colors-olive-a-3)"
  },
  "colors.olive.a.4": {
    "value": "var(--colors-olive-a-4)",
    "variable": "var(--colors-olive-a-4)"
  },
  "colors.olive.a.5": {
    "value": "var(--colors-olive-a-5)",
    "variable": "var(--colors-olive-a-5)"
  },
  "colors.olive.a.6": {
    "value": "var(--colors-olive-a-6)",
    "variable": "var(--colors-olive-a-6)"
  },
  "colors.olive.a.7": {
    "value": "var(--colors-olive-a-7)",
    "variable": "var(--colors-olive-a-7)"
  },
  "colors.olive.a.8": {
    "value": "var(--colors-olive-a-8)",
    "variable": "var(--colors-olive-a-8)"
  },
  "colors.olive.a.9": {
    "value": "var(--colors-olive-a-9)",
    "variable": "var(--colors-olive-a-9)"
  },
  "colors.olive.a.10": {
    "value": "var(--colors-olive-a-10)",
    "variable": "var(--colors-olive-a-10)"
  },
  "colors.olive.a.11": {
    "value": "var(--colors-olive-a-11)",
    "variable": "var(--colors-olive-a-11)"
  },
  "colors.olive.a.12": {
    "value": "var(--colors-olive-a-12)",
    "variable": "var(--colors-olive-a-12)"
  },
  "colors.olive.p3.1": {
    "value": "var(--colors-olive-p3-1)",
    "variable": "var(--colors-olive-p3-1)"
  },
  "colors.olive.p3.2": {
    "value": "var(--colors-olive-p3-2)",
    "variable": "var(--colors-olive-p3-2)"
  },
  "colors.olive.p3.3": {
    "value": "var(--colors-olive-p3-3)",
    "variable": "var(--colors-olive-p3-3)"
  },
  "colors.olive.p3.4": {
    "value": "var(--colors-olive-p3-4)",
    "variable": "var(--colors-olive-p3-4)"
  },
  "colors.olive.p3.5": {
    "value": "var(--colors-olive-p3-5)",
    "variable": "var(--colors-olive-p3-5)"
  },
  "colors.olive.p3.6": {
    "value": "var(--colors-olive-p3-6)",
    "variable": "var(--colors-olive-p3-6)"
  },
  "colors.olive.p3.7": {
    "value": "var(--colors-olive-p3-7)",
    "variable": "var(--colors-olive-p3-7)"
  },
  "colors.olive.p3.8": {
    "value": "var(--colors-olive-p3-8)",
    "variable": "var(--colors-olive-p3-8)"
  },
  "colors.olive.p3.9": {
    "value": "var(--colors-olive-p3-9)",
    "variable": "var(--colors-olive-p3-9)"
  },
  "colors.olive.p3.10": {
    "value": "var(--colors-olive-p3-10)",
    "variable": "var(--colors-olive-p3-10)"
  },
  "colors.olive.p3.11": {
    "value": "var(--colors-olive-p3-11)",
    "variable": "var(--colors-olive-p3-11)"
  },
  "colors.olive.p3.12": {
    "value": "var(--colors-olive-p3-12)",
    "variable": "var(--colors-olive-p3-12)"
  },
  "colors.olive.p3.a.1": {
    "value": "var(--colors-olive-p3-a-1)",
    "variable": "var(--colors-olive-p3-a-1)"
  },
  "colors.olive.p3.a.2": {
    "value": "var(--colors-olive-p3-a-2)",
    "variable": "var(--colors-olive-p3-a-2)"
  },
  "colors.olive.p3.a.3": {
    "value": "var(--colors-olive-p3-a-3)",
    "variable": "var(--colors-olive-p3-a-3)"
  },
  "colors.olive.p3.a.4": {
    "value": "var(--colors-olive-p3-a-4)",
    "variable": "var(--colors-olive-p3-a-4)"
  },
  "colors.olive.p3.a.5": {
    "value": "var(--colors-olive-p3-a-5)",
    "variable": "var(--colors-olive-p3-a-5)"
  },
  "colors.olive.p3.a.6": {
    "value": "var(--colors-olive-p3-a-6)",
    "variable": "var(--colors-olive-p3-a-6)"
  },
  "colors.olive.p3.a.7": {
    "value": "var(--colors-olive-p3-a-7)",
    "variable": "var(--colors-olive-p3-a-7)"
  },
  "colors.olive.p3.a.8": {
    "value": "var(--colors-olive-p3-a-8)",
    "variable": "var(--colors-olive-p3-a-8)"
  },
  "colors.olive.p3.a.9": {
    "value": "var(--colors-olive-p3-a-9)",
    "variable": "var(--colors-olive-p3-a-9)"
  },
  "colors.olive.p3.a.10": {
    "value": "var(--colors-olive-p3-a-10)",
    "variable": "var(--colors-olive-p3-a-10)"
  },
  "colors.olive.p3.a.11": {
    "value": "var(--colors-olive-p3-a-11)",
    "variable": "var(--colors-olive-p3-a-11)"
  },
  "colors.olive.p3.a.12": {
    "value": "var(--colors-olive-p3-a-12)",
    "variable": "var(--colors-olive-p3-a-12)"
  },
  "colors.orange.1": {
    "value": "var(--colors-orange-1)",
    "variable": "var(--colors-orange-1)"
  },
  "colors.orange.2": {
    "value": "var(--colors-orange-2)",
    "variable": "var(--colors-orange-2)"
  },
  "colors.orange.3": {
    "value": "var(--colors-orange-3)",
    "variable": "var(--colors-orange-3)"
  },
  "colors.orange.4": {
    "value": "var(--colors-orange-4)",
    "variable": "var(--colors-orange-4)"
  },
  "colors.orange.5": {
    "value": "var(--colors-orange-5)",
    "variable": "var(--colors-orange-5)"
  },
  "colors.orange.6": {
    "value": "var(--colors-orange-6)",
    "variable": "var(--colors-orange-6)"
  },
  "colors.orange.7": {
    "value": "var(--colors-orange-7)",
    "variable": "var(--colors-orange-7)"
  },
  "colors.orange.8": {
    "value": "var(--colors-orange-8)",
    "variable": "var(--colors-orange-8)"
  },
  "colors.orange.9": {
    "value": "var(--colors-orange-9)",
    "variable": "var(--colors-orange-9)"
  },
  "colors.orange.10": {
    "value": "var(--colors-orange-10)",
    "variable": "var(--colors-orange-10)"
  },
  "colors.orange.11": {
    "value": "var(--colors-orange-11)",
    "variable": "var(--colors-orange-11)"
  },
  "colors.orange.12": {
    "value": "var(--colors-orange-12)",
    "variable": "var(--colors-orange-12)"
  },
  "colors.orange.light.1": {
    "value": "var(--colors-orange-light-1)",
    "variable": "var(--colors-orange-light-1)"
  },
  "colors.orange.light.2": {
    "value": "var(--colors-orange-light-2)",
    "variable": "var(--colors-orange-light-2)"
  },
  "colors.orange.light.3": {
    "value": "var(--colors-orange-light-3)",
    "variable": "var(--colors-orange-light-3)"
  },
  "colors.orange.light.4": {
    "value": "var(--colors-orange-light-4)",
    "variable": "var(--colors-orange-light-4)"
  },
  "colors.orange.light.5": {
    "value": "var(--colors-orange-light-5)",
    "variable": "var(--colors-orange-light-5)"
  },
  "colors.orange.light.6": {
    "value": "var(--colors-orange-light-6)",
    "variable": "var(--colors-orange-light-6)"
  },
  "colors.orange.light.7": {
    "value": "var(--colors-orange-light-7)",
    "variable": "var(--colors-orange-light-7)"
  },
  "colors.orange.light.8": {
    "value": "var(--colors-orange-light-8)",
    "variable": "var(--colors-orange-light-8)"
  },
  "colors.orange.light.9": {
    "value": "var(--colors-orange-light-9)",
    "variable": "var(--colors-orange-light-9)"
  },
  "colors.orange.light.10": {
    "value": "var(--colors-orange-light-10)",
    "variable": "var(--colors-orange-light-10)"
  },
  "colors.orange.light.11": {
    "value": "var(--colors-orange-light-11)",
    "variable": "var(--colors-orange-light-11)"
  },
  "colors.orange.light.12": {
    "value": "var(--colors-orange-light-12)",
    "variable": "var(--colors-orange-light-12)"
  },
  "colors.orange.light.a.1": {
    "value": "var(--colors-orange-light-a-1)",
    "variable": "var(--colors-orange-light-a-1)"
  },
  "colors.orange.light.a.2": {
    "value": "var(--colors-orange-light-a-2)",
    "variable": "var(--colors-orange-light-a-2)"
  },
  "colors.orange.light.a.3": {
    "value": "var(--colors-orange-light-a-3)",
    "variable": "var(--colors-orange-light-a-3)"
  },
  "colors.orange.light.a.4": {
    "value": "var(--colors-orange-light-a-4)",
    "variable": "var(--colors-orange-light-a-4)"
  },
  "colors.orange.light.a.5": {
    "value": "var(--colors-orange-light-a-5)",
    "variable": "var(--colors-orange-light-a-5)"
  },
  "colors.orange.light.a.6": {
    "value": "var(--colors-orange-light-a-6)",
    "variable": "var(--colors-orange-light-a-6)"
  },
  "colors.orange.light.a.7": {
    "value": "var(--colors-orange-light-a-7)",
    "variable": "var(--colors-orange-light-a-7)"
  },
  "colors.orange.light.a.8": {
    "value": "var(--colors-orange-light-a-8)",
    "variable": "var(--colors-orange-light-a-8)"
  },
  "colors.orange.light.a.9": {
    "value": "var(--colors-orange-light-a-9)",
    "variable": "var(--colors-orange-light-a-9)"
  },
  "colors.orange.light.a.10": {
    "value": "var(--colors-orange-light-a-10)",
    "variable": "var(--colors-orange-light-a-10)"
  },
  "colors.orange.light.a.11": {
    "value": "var(--colors-orange-light-a-11)",
    "variable": "var(--colors-orange-light-a-11)"
  },
  "colors.orange.light.a.12": {
    "value": "var(--colors-orange-light-a-12)",
    "variable": "var(--colors-orange-light-a-12)"
  },
  "colors.orange.light.p3.1": {
    "value": "color(display-p3 0.995 0.988 0.985)",
    "variable": "var(--colors-orange-light-p3-1)"
  },
  "colors.orange.light.p3.2": {
    "value": "color(display-p3 0.994 0.968 0.934)",
    "variable": "var(--colors-orange-light-p3-2)"
  },
  "colors.orange.light.p3.3": {
    "value": "color(display-p3 0.989 0.938 0.85)",
    "variable": "var(--colors-orange-light-p3-3)"
  },
  "colors.orange.light.p3.4": {
    "value": "color(display-p3 1 0.874 0.687)",
    "variable": "var(--colors-orange-light-p3-4)"
  },
  "colors.orange.light.p3.5": {
    "value": "color(display-p3 1 0.821 0.583)",
    "variable": "var(--colors-orange-light-p3-5)"
  },
  "colors.orange.light.p3.6": {
    "value": "color(display-p3 0.975 0.767 0.545)",
    "variable": "var(--colors-orange-light-p3-6)"
  },
  "colors.orange.light.p3.7": {
    "value": "color(display-p3 0.919 0.693 0.486)",
    "variable": "var(--colors-orange-light-p3-7)"
  },
  "colors.orange.light.p3.8": {
    "value": "color(display-p3 0.877 0.597 0.379)",
    "variable": "var(--colors-orange-light-p3-8)"
  },
  "colors.orange.light.p3.9": {
    "value": "color(display-p3 0.9 0.45 0.2)",
    "variable": "var(--colors-orange-light-p3-9)"
  },
  "colors.orange.light.p3.10": {
    "value": "color(display-p3 0.87 0.409 0.164)",
    "variable": "var(--colors-orange-light-p3-10)"
  },
  "colors.orange.light.p3.11": {
    "value": "color(display-p3 0.76 0.34 0)",
    "variable": "var(--colors-orange-light-p3-11)"
  },
  "colors.orange.light.p3.12": {
    "value": "color(display-p3 0.323 0.185 0.127)",
    "variable": "var(--colors-orange-light-p3-12)"
  },
  "colors.orange.light.p3.a.1": {
    "value": "color(display-p3 0.757 0.267 0.024 / 0.016)",
    "variable": "var(--colors-orange-light-p3-a-1)"
  },
  "colors.orange.light.p3.a.2": {
    "value": "color(display-p3 0.886 0.533 0.008 / 0.067)",
    "variable": "var(--colors-orange-light-p3-a-2)"
  },
  "colors.orange.light.p3.a.3": {
    "value": "color(display-p3 0.922 0.584 0.008 / 0.15)",
    "variable": "var(--colors-orange-light-p3-a-3)"
  },
  "colors.orange.light.p3.a.4": {
    "value": "color(display-p3 1 0.604 0.004 / 0.314)",
    "variable": "var(--colors-orange-light-p3-a-4)"
  },
  "colors.orange.light.p3.a.5": {
    "value": "color(display-p3 1 0.569 0.004 / 0.416)",
    "variable": "var(--colors-orange-light-p3-a-5)"
  },
  "colors.orange.light.p3.a.6": {
    "value": "color(display-p3 0.949 0.494 0.004 / 0.455)",
    "variable": "var(--colors-orange-light-p3-a-6)"
  },
  "colors.orange.light.p3.a.7": {
    "value": "color(display-p3 0.839 0.408 0 / 0.514)",
    "variable": "var(--colors-orange-light-p3-a-7)"
  },
  "colors.orange.light.p3.a.8": {
    "value": "color(display-p3 0.804 0.349 0 / 0.62)",
    "variable": "var(--colors-orange-light-p3-a-8)"
  },
  "colors.orange.light.p3.a.9": {
    "value": "color(display-p3 0.878 0.314 0 / 0.8)",
    "variable": "var(--colors-orange-light-p3-a-9)"
  },
  "colors.orange.light.p3.a.10": {
    "value": "color(display-p3 0.843 0.29 0 / 0.836)",
    "variable": "var(--colors-orange-light-p3-a-10)"
  },
  "colors.orange.light.p3.a.11": {
    "value": "color(display-p3 0.76 0.34 0)",
    "variable": "var(--colors-orange-light-p3-a-11)"
  },
  "colors.orange.light.p3.a.12": {
    "value": "color(display-p3 0.323 0.185 0.127)",
    "variable": "var(--colors-orange-light-p3-a-12)"
  },
  "colors.orange.dark.1": {
    "value": "var(--colors-orange-dark-1)",
    "variable": "var(--colors-orange-dark-1)"
  },
  "colors.orange.dark.2": {
    "value": "var(--colors-orange-dark-2)",
    "variable": "var(--colors-orange-dark-2)"
  },
  "colors.orange.dark.3": {
    "value": "var(--colors-orange-dark-3)",
    "variable": "var(--colors-orange-dark-3)"
  },
  "colors.orange.dark.4": {
    "value": "var(--colors-orange-dark-4)",
    "variable": "var(--colors-orange-dark-4)"
  },
  "colors.orange.dark.5": {
    "value": "var(--colors-orange-dark-5)",
    "variable": "var(--colors-orange-dark-5)"
  },
  "colors.orange.dark.6": {
    "value": "var(--colors-orange-dark-6)",
    "variable": "var(--colors-orange-dark-6)"
  },
  "colors.orange.dark.7": {
    "value": "var(--colors-orange-dark-7)",
    "variable": "var(--colors-orange-dark-7)"
  },
  "colors.orange.dark.8": {
    "value": "var(--colors-orange-dark-8)",
    "variable": "var(--colors-orange-dark-8)"
  },
  "colors.orange.dark.9": {
    "value": "var(--colors-orange-dark-9)",
    "variable": "var(--colors-orange-dark-9)"
  },
  "colors.orange.dark.10": {
    "value": "var(--colors-orange-dark-10)",
    "variable": "var(--colors-orange-dark-10)"
  },
  "colors.orange.dark.11": {
    "value": "var(--colors-orange-dark-11)",
    "variable": "var(--colors-orange-dark-11)"
  },
  "colors.orange.dark.12": {
    "value": "var(--colors-orange-dark-12)",
    "variable": "var(--colors-orange-dark-12)"
  },
  "colors.orange.dark.a.1": {
    "value": "var(--colors-orange-dark-a-1)",
    "variable": "var(--colors-orange-dark-a-1)"
  },
  "colors.orange.dark.a.2": {
    "value": "var(--colors-orange-dark-a-2)",
    "variable": "var(--colors-orange-dark-a-2)"
  },
  "colors.orange.dark.a.3": {
    "value": "var(--colors-orange-dark-a-3)",
    "variable": "var(--colors-orange-dark-a-3)"
  },
  "colors.orange.dark.a.4": {
    "value": "var(--colors-orange-dark-a-4)",
    "variable": "var(--colors-orange-dark-a-4)"
  },
  "colors.orange.dark.a.5": {
    "value": "var(--colors-orange-dark-a-5)",
    "variable": "var(--colors-orange-dark-a-5)"
  },
  "colors.orange.dark.a.6": {
    "value": "var(--colors-orange-dark-a-6)",
    "variable": "var(--colors-orange-dark-a-6)"
  },
  "colors.orange.dark.a.7": {
    "value": "var(--colors-orange-dark-a-7)",
    "variable": "var(--colors-orange-dark-a-7)"
  },
  "colors.orange.dark.a.8": {
    "value": "var(--colors-orange-dark-a-8)",
    "variable": "var(--colors-orange-dark-a-8)"
  },
  "colors.orange.dark.a.9": {
    "value": "var(--colors-orange-dark-a-9)",
    "variable": "var(--colors-orange-dark-a-9)"
  },
  "colors.orange.dark.a.10": {
    "value": "var(--colors-orange-dark-a-10)",
    "variable": "var(--colors-orange-dark-a-10)"
  },
  "colors.orange.dark.a.11": {
    "value": "var(--colors-orange-dark-a-11)",
    "variable": "var(--colors-orange-dark-a-11)"
  },
  "colors.orange.dark.a.12": {
    "value": "var(--colors-orange-dark-a-12)",
    "variable": "var(--colors-orange-dark-a-12)"
  },
  "colors.orange.dark.p3.1": {
    "value": "color(display-p3 0.088 0.07 0.057)",
    "variable": "var(--colors-orange-dark-p3-1)"
  },
  "colors.orange.dark.p3.2": {
    "value": "color(display-p3 0.113 0.089 0.061)",
    "variable": "var(--colors-orange-dark-p3-2)"
  },
  "colors.orange.dark.p3.3": {
    "value": "color(display-p3 0.189 0.12 0.056)",
    "variable": "var(--colors-orange-dark-p3-3)"
  },
  "colors.orange.dark.p3.4": {
    "value": "color(display-p3 0.262 0.132 0)",
    "variable": "var(--colors-orange-dark-p3-4)"
  },
  "colors.orange.dark.p3.5": {
    "value": "color(display-p3 0.315 0.168 0.016)",
    "variable": "var(--colors-orange-dark-p3-5)"
  },
  "colors.orange.dark.p3.6": {
    "value": "color(display-p3 0.376 0.219 0.088)",
    "variable": "var(--colors-orange-dark-p3-6)"
  },
  "colors.orange.dark.p3.7": {
    "value": "color(display-p3 0.465 0.283 0.147)",
    "variable": "var(--colors-orange-dark-p3-7)"
  },
  "colors.orange.dark.p3.8": {
    "value": "color(display-p3 0.601 0.359 0.201)",
    "variable": "var(--colors-orange-dark-p3-8)"
  },
  "colors.orange.dark.p3.9": {
    "value": "color(display-p3 0.9 0.45 0.2)",
    "variable": "var(--colors-orange-dark-p3-9)"
  },
  "colors.orange.dark.p3.10": {
    "value": "color(display-p3 0.98 0.51 0.23)",
    "variable": "var(--colors-orange-dark-p3-10)"
  },
  "colors.orange.dark.p3.11": {
    "value": "color(display-p3 1 0.63 0.38)",
    "variable": "var(--colors-orange-dark-p3-11)"
  },
  "colors.orange.dark.p3.12": {
    "value": "color(display-p3 0.98 0.883 0.775)",
    "variable": "var(--colors-orange-dark-p3-12)"
  },
  "colors.orange.dark.p3.a.1": {
    "value": "color(display-p3 0.961 0.247 0 / 0.022)",
    "variable": "var(--colors-orange-dark-p3-a-1)"
  },
  "colors.orange.dark.p3.a.2": {
    "value": "color(display-p3 0.992 0.529 0 / 0.051)",
    "variable": "var(--colors-orange-dark-p3-a-2)"
  },
  "colors.orange.dark.p3.a.3": {
    "value": "color(display-p3 0.996 0.486 0 / 0.131)",
    "variable": "var(--colors-orange-dark-p3-a-3)"
  },
  "colors.orange.dark.p3.a.4": {
    "value": "color(display-p3 0.996 0.384 0 / 0.211)",
    "variable": "var(--colors-orange-dark-p3-a-4)"
  },
  "colors.orange.dark.p3.a.5": {
    "value": "color(display-p3 1 0.455 0 / 0.265)",
    "variable": "var(--colors-orange-dark-p3-a-5)"
  },
  "colors.orange.dark.p3.a.6": {
    "value": "color(display-p3 1 0.529 0.129 / 0.332)",
    "variable": "var(--colors-orange-dark-p3-a-6)"
  },
  "colors.orange.dark.p3.a.7": {
    "value": "color(display-p3 1 0.569 0.251 / 0.429)",
    "variable": "var(--colors-orange-dark-p3-a-7)"
  },
  "colors.orange.dark.p3.a.8": {
    "value": "color(display-p3 1 0.584 0.302 / 0.572)",
    "variable": "var(--colors-orange-dark-p3-a-8)"
  },
  "colors.orange.dark.p3.a.9": {
    "value": "color(display-p3 1 0.494 0.216 / 0.895)",
    "variable": "var(--colors-orange-dark-p3-a-9)"
  },
  "colors.orange.dark.p3.a.10": {
    "value": "color(display-p3 1 0.522 0.235 / 0.979)",
    "variable": "var(--colors-orange-dark-p3-a-10)"
  },
  "colors.orange.dark.p3.a.11": {
    "value": "color(display-p3 1 0.63 0.38)",
    "variable": "var(--colors-orange-dark-p3-a-11)"
  },
  "colors.orange.dark.p3.a.12": {
    "value": "color(display-p3 0.98 0.883 0.775)",
    "variable": "var(--colors-orange-dark-p3-a-12)"
  },
  "colors.orange.a.1": {
    "value": "var(--colors-orange-a-1)",
    "variable": "var(--colors-orange-a-1)"
  },
  "colors.orange.a.2": {
    "value": "var(--colors-orange-a-2)",
    "variable": "var(--colors-orange-a-2)"
  },
  "colors.orange.a.3": {
    "value": "var(--colors-orange-a-3)",
    "variable": "var(--colors-orange-a-3)"
  },
  "colors.orange.a.4": {
    "value": "var(--colors-orange-a-4)",
    "variable": "var(--colors-orange-a-4)"
  },
  "colors.orange.a.5": {
    "value": "var(--colors-orange-a-5)",
    "variable": "var(--colors-orange-a-5)"
  },
  "colors.orange.a.6": {
    "value": "var(--colors-orange-a-6)",
    "variable": "var(--colors-orange-a-6)"
  },
  "colors.orange.a.7": {
    "value": "var(--colors-orange-a-7)",
    "variable": "var(--colors-orange-a-7)"
  },
  "colors.orange.a.8": {
    "value": "var(--colors-orange-a-8)",
    "variable": "var(--colors-orange-a-8)"
  },
  "colors.orange.a.9": {
    "value": "var(--colors-orange-a-9)",
    "variable": "var(--colors-orange-a-9)"
  },
  "colors.orange.a.10": {
    "value": "var(--colors-orange-a-10)",
    "variable": "var(--colors-orange-a-10)"
  },
  "colors.orange.a.11": {
    "value": "var(--colors-orange-a-11)",
    "variable": "var(--colors-orange-a-11)"
  },
  "colors.orange.a.12": {
    "value": "var(--colors-orange-a-12)",
    "variable": "var(--colors-orange-a-12)"
  },
  "colors.orange.p3.1": {
    "value": "var(--colors-orange-p3-1)",
    "variable": "var(--colors-orange-p3-1)"
  },
  "colors.orange.p3.2": {
    "value": "var(--colors-orange-p3-2)",
    "variable": "var(--colors-orange-p3-2)"
  },
  "colors.orange.p3.3": {
    "value": "var(--colors-orange-p3-3)",
    "variable": "var(--colors-orange-p3-3)"
  },
  "colors.orange.p3.4": {
    "value": "var(--colors-orange-p3-4)",
    "variable": "var(--colors-orange-p3-4)"
  },
  "colors.orange.p3.5": {
    "value": "var(--colors-orange-p3-5)",
    "variable": "var(--colors-orange-p3-5)"
  },
  "colors.orange.p3.6": {
    "value": "var(--colors-orange-p3-6)",
    "variable": "var(--colors-orange-p3-6)"
  },
  "colors.orange.p3.7": {
    "value": "var(--colors-orange-p3-7)",
    "variable": "var(--colors-orange-p3-7)"
  },
  "colors.orange.p3.8": {
    "value": "var(--colors-orange-p3-8)",
    "variable": "var(--colors-orange-p3-8)"
  },
  "colors.orange.p3.9": {
    "value": "var(--colors-orange-p3-9)",
    "variable": "var(--colors-orange-p3-9)"
  },
  "colors.orange.p3.10": {
    "value": "var(--colors-orange-p3-10)",
    "variable": "var(--colors-orange-p3-10)"
  },
  "colors.orange.p3.11": {
    "value": "var(--colors-orange-p3-11)",
    "variable": "var(--colors-orange-p3-11)"
  },
  "colors.orange.p3.12": {
    "value": "var(--colors-orange-p3-12)",
    "variable": "var(--colors-orange-p3-12)"
  },
  "colors.orange.p3.a.1": {
    "value": "var(--colors-orange-p3-a-1)",
    "variable": "var(--colors-orange-p3-a-1)"
  },
  "colors.orange.p3.a.2": {
    "value": "var(--colors-orange-p3-a-2)",
    "variable": "var(--colors-orange-p3-a-2)"
  },
  "colors.orange.p3.a.3": {
    "value": "var(--colors-orange-p3-a-3)",
    "variable": "var(--colors-orange-p3-a-3)"
  },
  "colors.orange.p3.a.4": {
    "value": "var(--colors-orange-p3-a-4)",
    "variable": "var(--colors-orange-p3-a-4)"
  },
  "colors.orange.p3.a.5": {
    "value": "var(--colors-orange-p3-a-5)",
    "variable": "var(--colors-orange-p3-a-5)"
  },
  "colors.orange.p3.a.6": {
    "value": "var(--colors-orange-p3-a-6)",
    "variable": "var(--colors-orange-p3-a-6)"
  },
  "colors.orange.p3.a.7": {
    "value": "var(--colors-orange-p3-a-7)",
    "variable": "var(--colors-orange-p3-a-7)"
  },
  "colors.orange.p3.a.8": {
    "value": "var(--colors-orange-p3-a-8)",
    "variable": "var(--colors-orange-p3-a-8)"
  },
  "colors.orange.p3.a.9": {
    "value": "var(--colors-orange-p3-a-9)",
    "variable": "var(--colors-orange-p3-a-9)"
  },
  "colors.orange.p3.a.10": {
    "value": "var(--colors-orange-p3-a-10)",
    "variable": "var(--colors-orange-p3-a-10)"
  },
  "colors.orange.p3.a.11": {
    "value": "var(--colors-orange-p3-a-11)",
    "variable": "var(--colors-orange-p3-a-11)"
  },
  "colors.orange.p3.a.12": {
    "value": "var(--colors-orange-p3-a-12)",
    "variable": "var(--colors-orange-p3-a-12)"
  },
  "colors.pink.1": {
    "value": "var(--colors-pink-1)",
    "variable": "var(--colors-pink-1)"
  },
  "colors.pink.2": {
    "value": "var(--colors-pink-2)",
    "variable": "var(--colors-pink-2)"
  },
  "colors.pink.3": {
    "value": "var(--colors-pink-3)",
    "variable": "var(--colors-pink-3)"
  },
  "colors.pink.4": {
    "value": "var(--colors-pink-4)",
    "variable": "var(--colors-pink-4)"
  },
  "colors.pink.5": {
    "value": "var(--colors-pink-5)",
    "variable": "var(--colors-pink-5)"
  },
  "colors.pink.6": {
    "value": "var(--colors-pink-6)",
    "variable": "var(--colors-pink-6)"
  },
  "colors.pink.7": {
    "value": "var(--colors-pink-7)",
    "variable": "var(--colors-pink-7)"
  },
  "colors.pink.8": {
    "value": "var(--colors-pink-8)",
    "variable": "var(--colors-pink-8)"
  },
  "colors.pink.9": {
    "value": "var(--colors-pink-9)",
    "variable": "var(--colors-pink-9)"
  },
  "colors.pink.10": {
    "value": "var(--colors-pink-10)",
    "variable": "var(--colors-pink-10)"
  },
  "colors.pink.11": {
    "value": "var(--colors-pink-11)",
    "variable": "var(--colors-pink-11)"
  },
  "colors.pink.12": {
    "value": "var(--colors-pink-12)",
    "variable": "var(--colors-pink-12)"
  },
  "colors.pink.light.1": {
    "value": "var(--colors-pink-light-1)",
    "variable": "var(--colors-pink-light-1)"
  },
  "colors.pink.light.2": {
    "value": "var(--colors-pink-light-2)",
    "variable": "var(--colors-pink-light-2)"
  },
  "colors.pink.light.3": {
    "value": "var(--colors-pink-light-3)",
    "variable": "var(--colors-pink-light-3)"
  },
  "colors.pink.light.4": {
    "value": "var(--colors-pink-light-4)",
    "variable": "var(--colors-pink-light-4)"
  },
  "colors.pink.light.5": {
    "value": "var(--colors-pink-light-5)",
    "variable": "var(--colors-pink-light-5)"
  },
  "colors.pink.light.6": {
    "value": "var(--colors-pink-light-6)",
    "variable": "var(--colors-pink-light-6)"
  },
  "colors.pink.light.7": {
    "value": "var(--colors-pink-light-7)",
    "variable": "var(--colors-pink-light-7)"
  },
  "colors.pink.light.8": {
    "value": "var(--colors-pink-light-8)",
    "variable": "var(--colors-pink-light-8)"
  },
  "colors.pink.light.9": {
    "value": "var(--colors-pink-light-9)",
    "variable": "var(--colors-pink-light-9)"
  },
  "colors.pink.light.10": {
    "value": "var(--colors-pink-light-10)",
    "variable": "var(--colors-pink-light-10)"
  },
  "colors.pink.light.11": {
    "value": "var(--colors-pink-light-11)",
    "variable": "var(--colors-pink-light-11)"
  },
  "colors.pink.light.12": {
    "value": "var(--colors-pink-light-12)",
    "variable": "var(--colors-pink-light-12)"
  },
  "colors.pink.light.a.1": {
    "value": "var(--colors-pink-light-a-1)",
    "variable": "var(--colors-pink-light-a-1)"
  },
  "colors.pink.light.a.2": {
    "value": "var(--colors-pink-light-a-2)",
    "variable": "var(--colors-pink-light-a-2)"
  },
  "colors.pink.light.a.3": {
    "value": "var(--colors-pink-light-a-3)",
    "variable": "var(--colors-pink-light-a-3)"
  },
  "colors.pink.light.a.4": {
    "value": "var(--colors-pink-light-a-4)",
    "variable": "var(--colors-pink-light-a-4)"
  },
  "colors.pink.light.a.5": {
    "value": "var(--colors-pink-light-a-5)",
    "variable": "var(--colors-pink-light-a-5)"
  },
  "colors.pink.light.a.6": {
    "value": "var(--colors-pink-light-a-6)",
    "variable": "var(--colors-pink-light-a-6)"
  },
  "colors.pink.light.a.7": {
    "value": "var(--colors-pink-light-a-7)",
    "variable": "var(--colors-pink-light-a-7)"
  },
  "colors.pink.light.a.8": {
    "value": "var(--colors-pink-light-a-8)",
    "variable": "var(--colors-pink-light-a-8)"
  },
  "colors.pink.light.a.9": {
    "value": "var(--colors-pink-light-a-9)",
    "variable": "var(--colors-pink-light-a-9)"
  },
  "colors.pink.light.a.10": {
    "value": "var(--colors-pink-light-a-10)",
    "variable": "var(--colors-pink-light-a-10)"
  },
  "colors.pink.light.a.11": {
    "value": "var(--colors-pink-light-a-11)",
    "variable": "var(--colors-pink-light-a-11)"
  },
  "colors.pink.light.a.12": {
    "value": "var(--colors-pink-light-a-12)",
    "variable": "var(--colors-pink-light-a-12)"
  },
  "colors.pink.light.p3.1": {
    "value": "color(display-p3 0.998 0.989 0.996)",
    "variable": "var(--colors-pink-light-p3-1)"
  },
  "colors.pink.light.p3.2": {
    "value": "color(display-p3 0.992 0.97 0.985)",
    "variable": "var(--colors-pink-light-p3-2)"
  },
  "colors.pink.light.p3.3": {
    "value": "color(display-p3 0.981 0.917 0.96)",
    "variable": "var(--colors-pink-light-p3-3)"
  },
  "colors.pink.light.p3.4": {
    "value": "color(display-p3 0.963 0.867 0.932)",
    "variable": "var(--colors-pink-light-p3-4)"
  },
  "colors.pink.light.p3.5": {
    "value": "color(display-p3 0.939 0.815 0.899)",
    "variable": "var(--colors-pink-light-p3-5)"
  },
  "colors.pink.light.p3.6": {
    "value": "color(display-p3 0.907 0.756 0.859)",
    "variable": "var(--colors-pink-light-p3-6)"
  },
  "colors.pink.light.p3.7": {
    "value": "color(display-p3 0.869 0.683 0.81)",
    "variable": "var(--colors-pink-light-p3-7)"
  },
  "colors.pink.light.p3.8": {
    "value": "color(display-p3 0.825 0.59 0.751)",
    "variable": "var(--colors-pink-light-p3-8)"
  },
  "colors.pink.light.p3.9": {
    "value": "color(display-p3 0.775 0.297 0.61)",
    "variable": "var(--colors-pink-light-p3-9)"
  },
  "colors.pink.light.p3.10": {
    "value": "color(display-p3 0.748 0.27 0.581)",
    "variable": "var(--colors-pink-light-p3-10)"
  },
  "colors.pink.light.p3.11": {
    "value": "color(display-p3 0.698 0.219 0.528)",
    "variable": "var(--colors-pink-light-p3-11)"
  },
  "colors.pink.light.p3.12": {
    "value": "color(display-p3 0.363 0.101 0.279)",
    "variable": "var(--colors-pink-light-p3-12)"
  },
  "colors.pink.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.024 0.675 / 0.012)",
    "variable": "var(--colors-pink-light-p3-a-1)"
  },
  "colors.pink.light.p3.a.2": {
    "value": "color(display-p3 0.757 0.02 0.51 / 0.032)",
    "variable": "var(--colors-pink-light-p3-a-2)"
  },
  "colors.pink.light.p3.a.3": {
    "value": "color(display-p3 0.765 0.008 0.529 / 0.083)",
    "variable": "var(--colors-pink-light-p3-a-3)"
  },
  "colors.pink.light.p3.a.4": {
    "value": "color(display-p3 0.737 0.008 0.506 / 0.134)",
    "variable": "var(--colors-pink-light-p3-a-4)"
  },
  "colors.pink.light.p3.a.5": {
    "value": "color(display-p3 0.663 0.004 0.451 / 0.185)",
    "variable": "var(--colors-pink-light-p3-a-5)"
  },
  "colors.pink.light.p3.a.6": {
    "value": "color(display-p3 0.616 0.004 0.424 / 0.244)",
    "variable": "var(--colors-pink-light-p3-a-6)"
  },
  "colors.pink.light.p3.a.7": {
    "value": "color(display-p3 0.596 0.004 0.412 / 0.318)",
    "variable": "var(--colors-pink-light-p3-a-7)"
  },
  "colors.pink.light.p3.a.8": {
    "value": "color(display-p3 0.573 0.004 0.404 / 0.412)",
    "variable": "var(--colors-pink-light-p3-a-8)"
  },
  "colors.pink.light.p3.a.9": {
    "value": "color(display-p3 0.682 0 0.447 / 0.702)",
    "variable": "var(--colors-pink-light-p3-a-9)"
  },
  "colors.pink.light.p3.a.10": {
    "value": "color(display-p3 0.655 0 0.424 / 0.73)",
    "variable": "var(--colors-pink-light-p3-a-10)"
  },
  "colors.pink.light.p3.a.11": {
    "value": "color(display-p3 0.698 0.219 0.528)",
    "variable": "var(--colors-pink-light-p3-a-11)"
  },
  "colors.pink.light.p3.a.12": {
    "value": "color(display-p3 0.363 0.101 0.279)",
    "variable": "var(--colors-pink-light-p3-a-12)"
  },
  "colors.pink.dark.1": {
    "value": "var(--colors-pink-dark-1)",
    "variable": "var(--colors-pink-dark-1)"
  },
  "colors.pink.dark.2": {
    "value": "var(--colors-pink-dark-2)",
    "variable": "var(--colors-pink-dark-2)"
  },
  "colors.pink.dark.3": {
    "value": "var(--colors-pink-dark-3)",
    "variable": "var(--colors-pink-dark-3)"
  },
  "colors.pink.dark.4": {
    "value": "var(--colors-pink-dark-4)",
    "variable": "var(--colors-pink-dark-4)"
  },
  "colors.pink.dark.5": {
    "value": "var(--colors-pink-dark-5)",
    "variable": "var(--colors-pink-dark-5)"
  },
  "colors.pink.dark.6": {
    "value": "var(--colors-pink-dark-6)",
    "variable": "var(--colors-pink-dark-6)"
  },
  "colors.pink.dark.7": {
    "value": "var(--colors-pink-dark-7)",
    "variable": "var(--colors-pink-dark-7)"
  },
  "colors.pink.dark.8": {
    "value": "var(--colors-pink-dark-8)",
    "variable": "var(--colors-pink-dark-8)"
  },
  "colors.pink.dark.9": {
    "value": "var(--colors-pink-dark-9)",
    "variable": "var(--colors-pink-dark-9)"
  },
  "colors.pink.dark.10": {
    "value": "var(--colors-pink-dark-10)",
    "variable": "var(--colors-pink-dark-10)"
  },
  "colors.pink.dark.11": {
    "value": "var(--colors-pink-dark-11)",
    "variable": "var(--colors-pink-dark-11)"
  },
  "colors.pink.dark.12": {
    "value": "var(--colors-pink-dark-12)",
    "variable": "var(--colors-pink-dark-12)"
  },
  "colors.pink.dark.a.1": {
    "value": "var(--colors-pink-dark-a-1)",
    "variable": "var(--colors-pink-dark-a-1)"
  },
  "colors.pink.dark.a.2": {
    "value": "var(--colors-pink-dark-a-2)",
    "variable": "var(--colors-pink-dark-a-2)"
  },
  "colors.pink.dark.a.3": {
    "value": "var(--colors-pink-dark-a-3)",
    "variable": "var(--colors-pink-dark-a-3)"
  },
  "colors.pink.dark.a.4": {
    "value": "var(--colors-pink-dark-a-4)",
    "variable": "var(--colors-pink-dark-a-4)"
  },
  "colors.pink.dark.a.5": {
    "value": "var(--colors-pink-dark-a-5)",
    "variable": "var(--colors-pink-dark-a-5)"
  },
  "colors.pink.dark.a.6": {
    "value": "var(--colors-pink-dark-a-6)",
    "variable": "var(--colors-pink-dark-a-6)"
  },
  "colors.pink.dark.a.7": {
    "value": "var(--colors-pink-dark-a-7)",
    "variable": "var(--colors-pink-dark-a-7)"
  },
  "colors.pink.dark.a.8": {
    "value": "var(--colors-pink-dark-a-8)",
    "variable": "var(--colors-pink-dark-a-8)"
  },
  "colors.pink.dark.a.9": {
    "value": "var(--colors-pink-dark-a-9)",
    "variable": "var(--colors-pink-dark-a-9)"
  },
  "colors.pink.dark.a.10": {
    "value": "var(--colors-pink-dark-a-10)",
    "variable": "var(--colors-pink-dark-a-10)"
  },
  "colors.pink.dark.a.11": {
    "value": "var(--colors-pink-dark-a-11)",
    "variable": "var(--colors-pink-dark-a-11)"
  },
  "colors.pink.dark.a.12": {
    "value": "var(--colors-pink-dark-a-12)",
    "variable": "var(--colors-pink-dark-a-12)"
  },
  "colors.pink.dark.p3.1": {
    "value": "color(display-p3 0.093 0.068 0.089)",
    "variable": "var(--colors-pink-dark-p3-1)"
  },
  "colors.pink.dark.p3.2": {
    "value": "color(display-p3 0.121 0.073 0.11)",
    "variable": "var(--colors-pink-dark-p3-2)"
  },
  "colors.pink.dark.p3.3": {
    "value": "color(display-p3 0.198 0.098 0.179)",
    "variable": "var(--colors-pink-dark-p3-3)"
  },
  "colors.pink.dark.p3.4": {
    "value": "color(display-p3 0.271 0.095 0.231)",
    "variable": "var(--colors-pink-dark-p3-4)"
  },
  "colors.pink.dark.p3.5": {
    "value": "color(display-p3 0.32 0.127 0.273)",
    "variable": "var(--colors-pink-dark-p3-5)"
  },
  "colors.pink.dark.p3.6": {
    "value": "color(display-p3 0.382 0.177 0.326)",
    "variable": "var(--colors-pink-dark-p3-6)"
  },
  "colors.pink.dark.p3.7": {
    "value": "color(display-p3 0.477 0.238 0.405)",
    "variable": "var(--colors-pink-dark-p3-7)"
  },
  "colors.pink.dark.p3.8": {
    "value": "color(display-p3 0.612 0.304 0.51)",
    "variable": "var(--colors-pink-dark-p3-8)"
  },
  "colors.pink.dark.p3.9": {
    "value": "color(display-p3 0.775 0.297 0.61)",
    "variable": "var(--colors-pink-dark-p3-9)"
  },
  "colors.pink.dark.p3.10": {
    "value": "color(display-p3 0.808 0.356 0.645)",
    "variable": "var(--colors-pink-dark-p3-10)"
  },
  "colors.pink.dark.p3.11": {
    "value": "color(display-p3 1 0.535 0.78)",
    "variable": "var(--colors-pink-dark-p3-11)"
  },
  "colors.pink.dark.p3.12": {
    "value": "color(display-p3 0.964 0.826 0.912)",
    "variable": "var(--colors-pink-dark-p3-12)"
  },
  "colors.pink.dark.p3.a.1": {
    "value": "color(display-p3 0.984 0.071 0.855 / 0.03)",
    "variable": "var(--colors-pink-dark-p3-a-1)"
  },
  "colors.pink.dark.p3.a.2": {
    "value": "color(display-p3 1 0.2 0.8 / 0.059)",
    "variable": "var(--colors-pink-dark-p3-a-2)"
  },
  "colors.pink.dark.p3.a.3": {
    "value": "color(display-p3 1 0.294 0.886 / 0.139)",
    "variable": "var(--colors-pink-dark-p3-a-3)"
  },
  "colors.pink.dark.p3.a.4": {
    "value": "color(display-p3 1 0.192 0.82 / 0.219)",
    "variable": "var(--colors-pink-dark-p3-a-4)"
  },
  "colors.pink.dark.p3.a.5": {
    "value": "color(display-p3 1 0.282 0.827 / 0.274)",
    "variable": "var(--colors-pink-dark-p3-a-5)"
  },
  "colors.pink.dark.p3.a.6": {
    "value": "color(display-p3 1 0.396 0.835 / 0.337)",
    "variable": "var(--colors-pink-dark-p3-a-6)"
  },
  "colors.pink.dark.p3.a.7": {
    "value": "color(display-p3 1 0.459 0.831 / 0.442)",
    "variable": "var(--colors-pink-dark-p3-a-7)"
  },
  "colors.pink.dark.p3.a.8": {
    "value": "color(display-p3 1 0.478 0.827 / 0.585)",
    "variable": "var(--colors-pink-dark-p3-a-8)"
  },
  "colors.pink.dark.p3.a.9": {
    "value": "color(display-p3 1 0.373 0.784 / 0.761)",
    "variable": "var(--colors-pink-dark-p3-a-9)"
  },
  "colors.pink.dark.p3.a.10": {
    "value": "color(display-p3 1 0.435 0.792 / 0.795)",
    "variable": "var(--colors-pink-dark-p3-a-10)"
  },
  "colors.pink.dark.p3.a.11": {
    "value": "color(display-p3 1 0.535 0.78)",
    "variable": "var(--colors-pink-dark-p3-a-11)"
  },
  "colors.pink.dark.p3.a.12": {
    "value": "color(display-p3 0.964 0.826 0.912)",
    "variable": "var(--colors-pink-dark-p3-a-12)"
  },
  "colors.pink.a.1": {
    "value": "var(--colors-pink-a-1)",
    "variable": "var(--colors-pink-a-1)"
  },
  "colors.pink.a.2": {
    "value": "var(--colors-pink-a-2)",
    "variable": "var(--colors-pink-a-2)"
  },
  "colors.pink.a.3": {
    "value": "var(--colors-pink-a-3)",
    "variable": "var(--colors-pink-a-3)"
  },
  "colors.pink.a.4": {
    "value": "var(--colors-pink-a-4)",
    "variable": "var(--colors-pink-a-4)"
  },
  "colors.pink.a.5": {
    "value": "var(--colors-pink-a-5)",
    "variable": "var(--colors-pink-a-5)"
  },
  "colors.pink.a.6": {
    "value": "var(--colors-pink-a-6)",
    "variable": "var(--colors-pink-a-6)"
  },
  "colors.pink.a.7": {
    "value": "var(--colors-pink-a-7)",
    "variable": "var(--colors-pink-a-7)"
  },
  "colors.pink.a.8": {
    "value": "var(--colors-pink-a-8)",
    "variable": "var(--colors-pink-a-8)"
  },
  "colors.pink.a.9": {
    "value": "var(--colors-pink-a-9)",
    "variable": "var(--colors-pink-a-9)"
  },
  "colors.pink.a.10": {
    "value": "var(--colors-pink-a-10)",
    "variable": "var(--colors-pink-a-10)"
  },
  "colors.pink.a.11": {
    "value": "var(--colors-pink-a-11)",
    "variable": "var(--colors-pink-a-11)"
  },
  "colors.pink.a.12": {
    "value": "var(--colors-pink-a-12)",
    "variable": "var(--colors-pink-a-12)"
  },
  "colors.pink.p3.1": {
    "value": "var(--colors-pink-p3-1)",
    "variable": "var(--colors-pink-p3-1)"
  },
  "colors.pink.p3.2": {
    "value": "var(--colors-pink-p3-2)",
    "variable": "var(--colors-pink-p3-2)"
  },
  "colors.pink.p3.3": {
    "value": "var(--colors-pink-p3-3)",
    "variable": "var(--colors-pink-p3-3)"
  },
  "colors.pink.p3.4": {
    "value": "var(--colors-pink-p3-4)",
    "variable": "var(--colors-pink-p3-4)"
  },
  "colors.pink.p3.5": {
    "value": "var(--colors-pink-p3-5)",
    "variable": "var(--colors-pink-p3-5)"
  },
  "colors.pink.p3.6": {
    "value": "var(--colors-pink-p3-6)",
    "variable": "var(--colors-pink-p3-6)"
  },
  "colors.pink.p3.7": {
    "value": "var(--colors-pink-p3-7)",
    "variable": "var(--colors-pink-p3-7)"
  },
  "colors.pink.p3.8": {
    "value": "var(--colors-pink-p3-8)",
    "variable": "var(--colors-pink-p3-8)"
  },
  "colors.pink.p3.9": {
    "value": "var(--colors-pink-p3-9)",
    "variable": "var(--colors-pink-p3-9)"
  },
  "colors.pink.p3.10": {
    "value": "var(--colors-pink-p3-10)",
    "variable": "var(--colors-pink-p3-10)"
  },
  "colors.pink.p3.11": {
    "value": "var(--colors-pink-p3-11)",
    "variable": "var(--colors-pink-p3-11)"
  },
  "colors.pink.p3.12": {
    "value": "var(--colors-pink-p3-12)",
    "variable": "var(--colors-pink-p3-12)"
  },
  "colors.pink.p3.a.1": {
    "value": "var(--colors-pink-p3-a-1)",
    "variable": "var(--colors-pink-p3-a-1)"
  },
  "colors.pink.p3.a.2": {
    "value": "var(--colors-pink-p3-a-2)",
    "variable": "var(--colors-pink-p3-a-2)"
  },
  "colors.pink.p3.a.3": {
    "value": "var(--colors-pink-p3-a-3)",
    "variable": "var(--colors-pink-p3-a-3)"
  },
  "colors.pink.p3.a.4": {
    "value": "var(--colors-pink-p3-a-4)",
    "variable": "var(--colors-pink-p3-a-4)"
  },
  "colors.pink.p3.a.5": {
    "value": "var(--colors-pink-p3-a-5)",
    "variable": "var(--colors-pink-p3-a-5)"
  },
  "colors.pink.p3.a.6": {
    "value": "var(--colors-pink-p3-a-6)",
    "variable": "var(--colors-pink-p3-a-6)"
  },
  "colors.pink.p3.a.7": {
    "value": "var(--colors-pink-p3-a-7)",
    "variable": "var(--colors-pink-p3-a-7)"
  },
  "colors.pink.p3.a.8": {
    "value": "var(--colors-pink-p3-a-8)",
    "variable": "var(--colors-pink-p3-a-8)"
  },
  "colors.pink.p3.a.9": {
    "value": "var(--colors-pink-p3-a-9)",
    "variable": "var(--colors-pink-p3-a-9)"
  },
  "colors.pink.p3.a.10": {
    "value": "var(--colors-pink-p3-a-10)",
    "variable": "var(--colors-pink-p3-a-10)"
  },
  "colors.pink.p3.a.11": {
    "value": "var(--colors-pink-p3-a-11)",
    "variable": "var(--colors-pink-p3-a-11)"
  },
  "colors.pink.p3.a.12": {
    "value": "var(--colors-pink-p3-a-12)",
    "variable": "var(--colors-pink-p3-a-12)"
  },
  "colors.plum.1": {
    "value": "var(--colors-plum-1)",
    "variable": "var(--colors-plum-1)"
  },
  "colors.plum.2": {
    "value": "var(--colors-plum-2)",
    "variable": "var(--colors-plum-2)"
  },
  "colors.plum.3": {
    "value": "var(--colors-plum-3)",
    "variable": "var(--colors-plum-3)"
  },
  "colors.plum.4": {
    "value": "var(--colors-plum-4)",
    "variable": "var(--colors-plum-4)"
  },
  "colors.plum.5": {
    "value": "var(--colors-plum-5)",
    "variable": "var(--colors-plum-5)"
  },
  "colors.plum.6": {
    "value": "var(--colors-plum-6)",
    "variable": "var(--colors-plum-6)"
  },
  "colors.plum.7": {
    "value": "var(--colors-plum-7)",
    "variable": "var(--colors-plum-7)"
  },
  "colors.plum.8": {
    "value": "var(--colors-plum-8)",
    "variable": "var(--colors-plum-8)"
  },
  "colors.plum.9": {
    "value": "var(--colors-plum-9)",
    "variable": "var(--colors-plum-9)"
  },
  "colors.plum.10": {
    "value": "var(--colors-plum-10)",
    "variable": "var(--colors-plum-10)"
  },
  "colors.plum.11": {
    "value": "var(--colors-plum-11)",
    "variable": "var(--colors-plum-11)"
  },
  "colors.plum.12": {
    "value": "var(--colors-plum-12)",
    "variable": "var(--colors-plum-12)"
  },
  "colors.plum.light.1": {
    "value": "var(--colors-plum-light-1)",
    "variable": "var(--colors-plum-light-1)"
  },
  "colors.plum.light.2": {
    "value": "var(--colors-plum-light-2)",
    "variable": "var(--colors-plum-light-2)"
  },
  "colors.plum.light.3": {
    "value": "var(--colors-plum-light-3)",
    "variable": "var(--colors-plum-light-3)"
  },
  "colors.plum.light.4": {
    "value": "var(--colors-plum-light-4)",
    "variable": "var(--colors-plum-light-4)"
  },
  "colors.plum.light.5": {
    "value": "var(--colors-plum-light-5)",
    "variable": "var(--colors-plum-light-5)"
  },
  "colors.plum.light.6": {
    "value": "var(--colors-plum-light-6)",
    "variable": "var(--colors-plum-light-6)"
  },
  "colors.plum.light.7": {
    "value": "var(--colors-plum-light-7)",
    "variable": "var(--colors-plum-light-7)"
  },
  "colors.plum.light.8": {
    "value": "var(--colors-plum-light-8)",
    "variable": "var(--colors-plum-light-8)"
  },
  "colors.plum.light.9": {
    "value": "var(--colors-plum-light-9)",
    "variable": "var(--colors-plum-light-9)"
  },
  "colors.plum.light.10": {
    "value": "var(--colors-plum-light-10)",
    "variable": "var(--colors-plum-light-10)"
  },
  "colors.plum.light.11": {
    "value": "var(--colors-plum-light-11)",
    "variable": "var(--colors-plum-light-11)"
  },
  "colors.plum.light.12": {
    "value": "var(--colors-plum-light-12)",
    "variable": "var(--colors-plum-light-12)"
  },
  "colors.plum.light.a.1": {
    "value": "var(--colors-plum-light-a-1)",
    "variable": "var(--colors-plum-light-a-1)"
  },
  "colors.plum.light.a.2": {
    "value": "var(--colors-plum-light-a-2)",
    "variable": "var(--colors-plum-light-a-2)"
  },
  "colors.plum.light.a.3": {
    "value": "var(--colors-plum-light-a-3)",
    "variable": "var(--colors-plum-light-a-3)"
  },
  "colors.plum.light.a.4": {
    "value": "var(--colors-plum-light-a-4)",
    "variable": "var(--colors-plum-light-a-4)"
  },
  "colors.plum.light.a.5": {
    "value": "var(--colors-plum-light-a-5)",
    "variable": "var(--colors-plum-light-a-5)"
  },
  "colors.plum.light.a.6": {
    "value": "var(--colors-plum-light-a-6)",
    "variable": "var(--colors-plum-light-a-6)"
  },
  "colors.plum.light.a.7": {
    "value": "var(--colors-plum-light-a-7)",
    "variable": "var(--colors-plum-light-a-7)"
  },
  "colors.plum.light.a.8": {
    "value": "var(--colors-plum-light-a-8)",
    "variable": "var(--colors-plum-light-a-8)"
  },
  "colors.plum.light.a.9": {
    "value": "var(--colors-plum-light-a-9)",
    "variable": "var(--colors-plum-light-a-9)"
  },
  "colors.plum.light.a.10": {
    "value": "var(--colors-plum-light-a-10)",
    "variable": "var(--colors-plum-light-a-10)"
  },
  "colors.plum.light.a.11": {
    "value": "var(--colors-plum-light-a-11)",
    "variable": "var(--colors-plum-light-a-11)"
  },
  "colors.plum.light.a.12": {
    "value": "var(--colors-plum-light-a-12)",
    "variable": "var(--colors-plum-light-a-12)"
  },
  "colors.plum.light.p3.1": {
    "value": "color(display-p3 0.995 0.988 0.999)",
    "variable": "var(--colors-plum-light-p3-1)"
  },
  "colors.plum.light.p3.2": {
    "value": "color(display-p3 0.988 0.971 0.99)",
    "variable": "var(--colors-plum-light-p3-2)"
  },
  "colors.plum.light.p3.3": {
    "value": "color(display-p3 0.973 0.923 0.98)",
    "variable": "var(--colors-plum-light-p3-3)"
  },
  "colors.plum.light.p3.4": {
    "value": "color(display-p3 0.953 0.875 0.966)",
    "variable": "var(--colors-plum-light-p3-4)"
  },
  "colors.plum.light.p3.5": {
    "value": "color(display-p3 0.926 0.825 0.945)",
    "variable": "var(--colors-plum-light-p3-5)"
  },
  "colors.plum.light.p3.6": {
    "value": "color(display-p3 0.89 0.765 0.916)",
    "variable": "var(--colors-plum-light-p3-6)"
  },
  "colors.plum.light.p3.7": {
    "value": "color(display-p3 0.84 0.686 0.877)",
    "variable": "var(--colors-plum-light-p3-7)"
  },
  "colors.plum.light.p3.8": {
    "value": "color(display-p3 0.775 0.58 0.832)",
    "variable": "var(--colors-plum-light-p3-8)"
  },
  "colors.plum.light.p3.9": {
    "value": "color(display-p3 0.624 0.313 0.708)",
    "variable": "var(--colors-plum-light-p3-9)"
  },
  "colors.plum.light.p3.10": {
    "value": "color(display-p3 0.587 0.29 0.667)",
    "variable": "var(--colors-plum-light-p3-10)"
  },
  "colors.plum.light.p3.11": {
    "value": "color(display-p3 0.543 0.263 0.619)",
    "variable": "var(--colors-plum-light-p3-11)"
  },
  "colors.plum.light.p3.12": {
    "value": "color(display-p3 0.299 0.114 0.352)",
    "variable": "var(--colors-plum-light-p3-12)"
  },
  "colors.plum.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.024 1 / 0.012)",
    "variable": "var(--colors-plum-light-p3-a-1)"
  },
  "colors.plum.light.p3.a.2": {
    "value": "color(display-p3 0.58 0.024 0.58 / 0.028)",
    "variable": "var(--colors-plum-light-p3-a-2)"
  },
  "colors.plum.light.p3.a.3": {
    "value": "color(display-p3 0.655 0.008 0.753 / 0.079)",
    "variable": "var(--colors-plum-light-p3-a-3)"
  },
  "colors.plum.light.p3.a.4": {
    "value": "color(display-p3 0.627 0.008 0.722 / 0.126)",
    "variable": "var(--colors-plum-light-p3-a-4)"
  },
  "colors.plum.light.p3.a.5": {
    "value": "color(display-p3 0.58 0.004 0.69 / 0.177)",
    "variable": "var(--colors-plum-light-p3-a-5)"
  },
  "colors.plum.light.p3.a.6": {
    "value": "color(display-p3 0.537 0.004 0.655 / 0.236)",
    "variable": "var(--colors-plum-light-p3-a-6)"
  },
  "colors.plum.light.p3.a.7": {
    "value": "color(display-p3 0.49 0.004 0.616 / 0.314)",
    "variable": "var(--colors-plum-light-p3-a-7)"
  },
  "colors.plum.light.p3.a.8": {
    "value": "color(display-p3 0.471 0.004 0.6 / 0.42)",
    "variable": "var(--colors-plum-light-p3-a-8)"
  },
  "colors.plum.light.p3.a.9": {
    "value": "color(display-p3 0.451 0 0.576 / 0.687)",
    "variable": "var(--colors-plum-light-p3-a-9)"
  },
  "colors.plum.light.p3.a.10": {
    "value": "color(display-p3 0.42 0 0.529 / 0.71)",
    "variable": "var(--colors-plum-light-p3-a-10)"
  },
  "colors.plum.light.p3.a.11": {
    "value": "color(display-p3 0.543 0.263 0.619)",
    "variable": "var(--colors-plum-light-p3-a-11)"
  },
  "colors.plum.light.p3.a.12": {
    "value": "color(display-p3 0.299 0.114 0.352)",
    "variable": "var(--colors-plum-light-p3-a-12)"
  },
  "colors.plum.dark.1": {
    "value": "var(--colors-plum-dark-1)",
    "variable": "var(--colors-plum-dark-1)"
  },
  "colors.plum.dark.2": {
    "value": "var(--colors-plum-dark-2)",
    "variable": "var(--colors-plum-dark-2)"
  },
  "colors.plum.dark.3": {
    "value": "var(--colors-plum-dark-3)",
    "variable": "var(--colors-plum-dark-3)"
  },
  "colors.plum.dark.4": {
    "value": "var(--colors-plum-dark-4)",
    "variable": "var(--colors-plum-dark-4)"
  },
  "colors.plum.dark.5": {
    "value": "var(--colors-plum-dark-5)",
    "variable": "var(--colors-plum-dark-5)"
  },
  "colors.plum.dark.6": {
    "value": "var(--colors-plum-dark-6)",
    "variable": "var(--colors-plum-dark-6)"
  },
  "colors.plum.dark.7": {
    "value": "var(--colors-plum-dark-7)",
    "variable": "var(--colors-plum-dark-7)"
  },
  "colors.plum.dark.8": {
    "value": "var(--colors-plum-dark-8)",
    "variable": "var(--colors-plum-dark-8)"
  },
  "colors.plum.dark.9": {
    "value": "var(--colors-plum-dark-9)",
    "variable": "var(--colors-plum-dark-9)"
  },
  "colors.plum.dark.10": {
    "value": "var(--colors-plum-dark-10)",
    "variable": "var(--colors-plum-dark-10)"
  },
  "colors.plum.dark.11": {
    "value": "var(--colors-plum-dark-11)",
    "variable": "var(--colors-plum-dark-11)"
  },
  "colors.plum.dark.12": {
    "value": "var(--colors-plum-dark-12)",
    "variable": "var(--colors-plum-dark-12)"
  },
  "colors.plum.dark.a.1": {
    "value": "var(--colors-plum-dark-a-1)",
    "variable": "var(--colors-plum-dark-a-1)"
  },
  "colors.plum.dark.a.2": {
    "value": "var(--colors-plum-dark-a-2)",
    "variable": "var(--colors-plum-dark-a-2)"
  },
  "colors.plum.dark.a.3": {
    "value": "var(--colors-plum-dark-a-3)",
    "variable": "var(--colors-plum-dark-a-3)"
  },
  "colors.plum.dark.a.4": {
    "value": "var(--colors-plum-dark-a-4)",
    "variable": "var(--colors-plum-dark-a-4)"
  },
  "colors.plum.dark.a.5": {
    "value": "var(--colors-plum-dark-a-5)",
    "variable": "var(--colors-plum-dark-a-5)"
  },
  "colors.plum.dark.a.6": {
    "value": "var(--colors-plum-dark-a-6)",
    "variable": "var(--colors-plum-dark-a-6)"
  },
  "colors.plum.dark.a.7": {
    "value": "var(--colors-plum-dark-a-7)",
    "variable": "var(--colors-plum-dark-a-7)"
  },
  "colors.plum.dark.a.8": {
    "value": "var(--colors-plum-dark-a-8)",
    "variable": "var(--colors-plum-dark-a-8)"
  },
  "colors.plum.dark.a.9": {
    "value": "var(--colors-plum-dark-a-9)",
    "variable": "var(--colors-plum-dark-a-9)"
  },
  "colors.plum.dark.a.10": {
    "value": "var(--colors-plum-dark-a-10)",
    "variable": "var(--colors-plum-dark-a-10)"
  },
  "colors.plum.dark.a.11": {
    "value": "var(--colors-plum-dark-a-11)",
    "variable": "var(--colors-plum-dark-a-11)"
  },
  "colors.plum.dark.a.12": {
    "value": "var(--colors-plum-dark-a-12)",
    "variable": "var(--colors-plum-dark-a-12)"
  },
  "colors.plum.dark.p3.1": {
    "value": "color(display-p3 0.09 0.068 0.092)",
    "variable": "var(--colors-plum-dark-p3-1)"
  },
  "colors.plum.dark.p3.2": {
    "value": "color(display-p3 0.118 0.077 0.121)",
    "variable": "var(--colors-plum-dark-p3-2)"
  },
  "colors.plum.dark.p3.3": {
    "value": "color(display-p3 0.192 0.105 0.202)",
    "variable": "var(--colors-plum-dark-p3-3)"
  },
  "colors.plum.dark.p3.4": {
    "value": "color(display-p3 0.25 0.121 0.271)",
    "variable": "var(--colors-plum-dark-p3-4)"
  },
  "colors.plum.dark.p3.5": {
    "value": "color(display-p3 0.293 0.152 0.319)",
    "variable": "var(--colors-plum-dark-p3-5)"
  },
  "colors.plum.dark.p3.6": {
    "value": "color(display-p3 0.343 0.198 0.372)",
    "variable": "var(--colors-plum-dark-p3-6)"
  },
  "colors.plum.dark.p3.7": {
    "value": "color(display-p3 0.424 0.262 0.461)",
    "variable": "var(--colors-plum-dark-p3-7)"
  },
  "colors.plum.dark.p3.8": {
    "value": "color(display-p3 0.54 0.341 0.595)",
    "variable": "var(--colors-plum-dark-p3-8)"
  },
  "colors.plum.dark.p3.9": {
    "value": "color(display-p3 0.624 0.313 0.708)",
    "variable": "var(--colors-plum-dark-p3-9)"
  },
  "colors.plum.dark.p3.10": {
    "value": "color(display-p3 0.666 0.365 0.748)",
    "variable": "var(--colors-plum-dark-p3-10)"
  },
  "colors.plum.dark.p3.11": {
    "value": "color(display-p3 0.86 0.602 0.933)",
    "variable": "var(--colors-plum-dark-p3-11)"
  },
  "colors.plum.dark.p3.12": {
    "value": "color(display-p3 0.936 0.836 0.949)",
    "variable": "var(--colors-plum-dark-p3-12)"
  },
  "colors.plum.dark.p3.a.1": {
    "value": "color(display-p3 0.973 0.071 0.973 / 0.026)",
    "variable": "var(--colors-plum-dark-p3-a-1)"
  },
  "colors.plum.dark.p3.a.2": {
    "value": "color(display-p3 0.933 0.267 1 / 0.059)",
    "variable": "var(--colors-plum-dark-p3-a-2)"
  },
  "colors.plum.dark.p3.a.3": {
    "value": "color(display-p3 0.918 0.333 0.996 / 0.148)",
    "variable": "var(--colors-plum-dark-p3-a-3)"
  },
  "colors.plum.dark.p3.a.4": {
    "value": "color(display-p3 0.91 0.318 1 / 0.219)",
    "variable": "var(--colors-plum-dark-p3-a-4)"
  },
  "colors.plum.dark.p3.a.5": {
    "value": "color(display-p3 0.914 0.388 1 / 0.269)",
    "variable": "var(--colors-plum-dark-p3-a-5)"
  },
  "colors.plum.dark.p3.a.6": {
    "value": "color(display-p3 0.906 0.463 1 / 0.328)",
    "variable": "var(--colors-plum-dark-p3-a-6)"
  },
  "colors.plum.dark.p3.a.7": {
    "value": "color(display-p3 0.906 0.529 1 / 0.425)",
    "variable": "var(--colors-plum-dark-p3-a-7)"
  },
  "colors.plum.dark.p3.a.8": {
    "value": "color(display-p3 0.906 0.553 1 / 0.568)",
    "variable": "var(--colors-plum-dark-p3-a-8)"
  },
  "colors.plum.dark.p3.a.9": {
    "value": "color(display-p3 0.875 0.427 1 / 0.69)",
    "variable": "var(--colors-plum-dark-p3-a-9)"
  },
  "colors.plum.dark.p3.a.10": {
    "value": "color(display-p3 0.886 0.471 0.996 / 0.732)",
    "variable": "var(--colors-plum-dark-p3-a-10)"
  },
  "colors.plum.dark.p3.a.11": {
    "value": "color(display-p3 0.86 0.602 0.933)",
    "variable": "var(--colors-plum-dark-p3-a-11)"
  },
  "colors.plum.dark.p3.a.12": {
    "value": "color(display-p3 0.936 0.836 0.949)",
    "variable": "var(--colors-plum-dark-p3-a-12)"
  },
  "colors.plum.a.1": {
    "value": "var(--colors-plum-a-1)",
    "variable": "var(--colors-plum-a-1)"
  },
  "colors.plum.a.2": {
    "value": "var(--colors-plum-a-2)",
    "variable": "var(--colors-plum-a-2)"
  },
  "colors.plum.a.3": {
    "value": "var(--colors-plum-a-3)",
    "variable": "var(--colors-plum-a-3)"
  },
  "colors.plum.a.4": {
    "value": "var(--colors-plum-a-4)",
    "variable": "var(--colors-plum-a-4)"
  },
  "colors.plum.a.5": {
    "value": "var(--colors-plum-a-5)",
    "variable": "var(--colors-plum-a-5)"
  },
  "colors.plum.a.6": {
    "value": "var(--colors-plum-a-6)",
    "variable": "var(--colors-plum-a-6)"
  },
  "colors.plum.a.7": {
    "value": "var(--colors-plum-a-7)",
    "variable": "var(--colors-plum-a-7)"
  },
  "colors.plum.a.8": {
    "value": "var(--colors-plum-a-8)",
    "variable": "var(--colors-plum-a-8)"
  },
  "colors.plum.a.9": {
    "value": "var(--colors-plum-a-9)",
    "variable": "var(--colors-plum-a-9)"
  },
  "colors.plum.a.10": {
    "value": "var(--colors-plum-a-10)",
    "variable": "var(--colors-plum-a-10)"
  },
  "colors.plum.a.11": {
    "value": "var(--colors-plum-a-11)",
    "variable": "var(--colors-plum-a-11)"
  },
  "colors.plum.a.12": {
    "value": "var(--colors-plum-a-12)",
    "variable": "var(--colors-plum-a-12)"
  },
  "colors.plum.p3.1": {
    "value": "var(--colors-plum-p3-1)",
    "variable": "var(--colors-plum-p3-1)"
  },
  "colors.plum.p3.2": {
    "value": "var(--colors-plum-p3-2)",
    "variable": "var(--colors-plum-p3-2)"
  },
  "colors.plum.p3.3": {
    "value": "var(--colors-plum-p3-3)",
    "variable": "var(--colors-plum-p3-3)"
  },
  "colors.plum.p3.4": {
    "value": "var(--colors-plum-p3-4)",
    "variable": "var(--colors-plum-p3-4)"
  },
  "colors.plum.p3.5": {
    "value": "var(--colors-plum-p3-5)",
    "variable": "var(--colors-plum-p3-5)"
  },
  "colors.plum.p3.6": {
    "value": "var(--colors-plum-p3-6)",
    "variable": "var(--colors-plum-p3-6)"
  },
  "colors.plum.p3.7": {
    "value": "var(--colors-plum-p3-7)",
    "variable": "var(--colors-plum-p3-7)"
  },
  "colors.plum.p3.8": {
    "value": "var(--colors-plum-p3-8)",
    "variable": "var(--colors-plum-p3-8)"
  },
  "colors.plum.p3.9": {
    "value": "var(--colors-plum-p3-9)",
    "variable": "var(--colors-plum-p3-9)"
  },
  "colors.plum.p3.10": {
    "value": "var(--colors-plum-p3-10)",
    "variable": "var(--colors-plum-p3-10)"
  },
  "colors.plum.p3.11": {
    "value": "var(--colors-plum-p3-11)",
    "variable": "var(--colors-plum-p3-11)"
  },
  "colors.plum.p3.12": {
    "value": "var(--colors-plum-p3-12)",
    "variable": "var(--colors-plum-p3-12)"
  },
  "colors.plum.p3.a.1": {
    "value": "var(--colors-plum-p3-a-1)",
    "variable": "var(--colors-plum-p3-a-1)"
  },
  "colors.plum.p3.a.2": {
    "value": "var(--colors-plum-p3-a-2)",
    "variable": "var(--colors-plum-p3-a-2)"
  },
  "colors.plum.p3.a.3": {
    "value": "var(--colors-plum-p3-a-3)",
    "variable": "var(--colors-plum-p3-a-3)"
  },
  "colors.plum.p3.a.4": {
    "value": "var(--colors-plum-p3-a-4)",
    "variable": "var(--colors-plum-p3-a-4)"
  },
  "colors.plum.p3.a.5": {
    "value": "var(--colors-plum-p3-a-5)",
    "variable": "var(--colors-plum-p3-a-5)"
  },
  "colors.plum.p3.a.6": {
    "value": "var(--colors-plum-p3-a-6)",
    "variable": "var(--colors-plum-p3-a-6)"
  },
  "colors.plum.p3.a.7": {
    "value": "var(--colors-plum-p3-a-7)",
    "variable": "var(--colors-plum-p3-a-7)"
  },
  "colors.plum.p3.a.8": {
    "value": "var(--colors-plum-p3-a-8)",
    "variable": "var(--colors-plum-p3-a-8)"
  },
  "colors.plum.p3.a.9": {
    "value": "var(--colors-plum-p3-a-9)",
    "variable": "var(--colors-plum-p3-a-9)"
  },
  "colors.plum.p3.a.10": {
    "value": "var(--colors-plum-p3-a-10)",
    "variable": "var(--colors-plum-p3-a-10)"
  },
  "colors.plum.p3.a.11": {
    "value": "var(--colors-plum-p3-a-11)",
    "variable": "var(--colors-plum-p3-a-11)"
  },
  "colors.plum.p3.a.12": {
    "value": "var(--colors-plum-p3-a-12)",
    "variable": "var(--colors-plum-p3-a-12)"
  },
  "colors.purple.1": {
    "value": "var(--colors-purple-1)",
    "variable": "var(--colors-purple-1)"
  },
  "colors.purple.2": {
    "value": "var(--colors-purple-2)",
    "variable": "var(--colors-purple-2)"
  },
  "colors.purple.3": {
    "value": "var(--colors-purple-3)",
    "variable": "var(--colors-purple-3)"
  },
  "colors.purple.4": {
    "value": "var(--colors-purple-4)",
    "variable": "var(--colors-purple-4)"
  },
  "colors.purple.5": {
    "value": "var(--colors-purple-5)",
    "variable": "var(--colors-purple-5)"
  },
  "colors.purple.6": {
    "value": "var(--colors-purple-6)",
    "variable": "var(--colors-purple-6)"
  },
  "colors.purple.7": {
    "value": "var(--colors-purple-7)",
    "variable": "var(--colors-purple-7)"
  },
  "colors.purple.8": {
    "value": "var(--colors-purple-8)",
    "variable": "var(--colors-purple-8)"
  },
  "colors.purple.9": {
    "value": "var(--colors-purple-9)",
    "variable": "var(--colors-purple-9)"
  },
  "colors.purple.10": {
    "value": "var(--colors-purple-10)",
    "variable": "var(--colors-purple-10)"
  },
  "colors.purple.11": {
    "value": "var(--colors-purple-11)",
    "variable": "var(--colors-purple-11)"
  },
  "colors.purple.12": {
    "value": "var(--colors-purple-12)",
    "variable": "var(--colors-purple-12)"
  },
  "colors.purple.light.1": {
    "value": "var(--colors-purple-light-1)",
    "variable": "var(--colors-purple-light-1)"
  },
  "colors.purple.light.2": {
    "value": "var(--colors-purple-light-2)",
    "variable": "var(--colors-purple-light-2)"
  },
  "colors.purple.light.3": {
    "value": "var(--colors-purple-light-3)",
    "variable": "var(--colors-purple-light-3)"
  },
  "colors.purple.light.4": {
    "value": "var(--colors-purple-light-4)",
    "variable": "var(--colors-purple-light-4)"
  },
  "colors.purple.light.5": {
    "value": "var(--colors-purple-light-5)",
    "variable": "var(--colors-purple-light-5)"
  },
  "colors.purple.light.6": {
    "value": "var(--colors-purple-light-6)",
    "variable": "var(--colors-purple-light-6)"
  },
  "colors.purple.light.7": {
    "value": "var(--colors-purple-light-7)",
    "variable": "var(--colors-purple-light-7)"
  },
  "colors.purple.light.8": {
    "value": "var(--colors-purple-light-8)",
    "variable": "var(--colors-purple-light-8)"
  },
  "colors.purple.light.9": {
    "value": "var(--colors-purple-light-9)",
    "variable": "var(--colors-purple-light-9)"
  },
  "colors.purple.light.10": {
    "value": "var(--colors-purple-light-10)",
    "variable": "var(--colors-purple-light-10)"
  },
  "colors.purple.light.11": {
    "value": "var(--colors-purple-light-11)",
    "variable": "var(--colors-purple-light-11)"
  },
  "colors.purple.light.12": {
    "value": "var(--colors-purple-light-12)",
    "variable": "var(--colors-purple-light-12)"
  },
  "colors.purple.light.a.1": {
    "value": "var(--colors-purple-light-a-1)",
    "variable": "var(--colors-purple-light-a-1)"
  },
  "colors.purple.light.a.2": {
    "value": "var(--colors-purple-light-a-2)",
    "variable": "var(--colors-purple-light-a-2)"
  },
  "colors.purple.light.a.3": {
    "value": "var(--colors-purple-light-a-3)",
    "variable": "var(--colors-purple-light-a-3)"
  },
  "colors.purple.light.a.4": {
    "value": "var(--colors-purple-light-a-4)",
    "variable": "var(--colors-purple-light-a-4)"
  },
  "colors.purple.light.a.5": {
    "value": "var(--colors-purple-light-a-5)",
    "variable": "var(--colors-purple-light-a-5)"
  },
  "colors.purple.light.a.6": {
    "value": "var(--colors-purple-light-a-6)",
    "variable": "var(--colors-purple-light-a-6)"
  },
  "colors.purple.light.a.7": {
    "value": "var(--colors-purple-light-a-7)",
    "variable": "var(--colors-purple-light-a-7)"
  },
  "colors.purple.light.a.8": {
    "value": "var(--colors-purple-light-a-8)",
    "variable": "var(--colors-purple-light-a-8)"
  },
  "colors.purple.light.a.9": {
    "value": "var(--colors-purple-light-a-9)",
    "variable": "var(--colors-purple-light-a-9)"
  },
  "colors.purple.light.a.10": {
    "value": "var(--colors-purple-light-a-10)",
    "variable": "var(--colors-purple-light-a-10)"
  },
  "colors.purple.light.a.11": {
    "value": "var(--colors-purple-light-a-11)",
    "variable": "var(--colors-purple-light-a-11)"
  },
  "colors.purple.light.a.12": {
    "value": "var(--colors-purple-light-a-12)",
    "variable": "var(--colors-purple-light-a-12)"
  },
  "colors.purple.light.p3.1": {
    "value": "color(display-p3 0.995 0.988 0.996)",
    "variable": "var(--colors-purple-light-p3-1)"
  },
  "colors.purple.light.p3.2": {
    "value": "color(display-p3 0.983 0.971 0.993)",
    "variable": "var(--colors-purple-light-p3-2)"
  },
  "colors.purple.light.p3.3": {
    "value": "color(display-p3 0.963 0.931 0.989)",
    "variable": "var(--colors-purple-light-p3-3)"
  },
  "colors.purple.light.p3.4": {
    "value": "color(display-p3 0.937 0.888 0.981)",
    "variable": "var(--colors-purple-light-p3-4)"
  },
  "colors.purple.light.p3.5": {
    "value": "color(display-p3 0.904 0.837 0.966)",
    "variable": "var(--colors-purple-light-p3-5)"
  },
  "colors.purple.light.p3.6": {
    "value": "color(display-p3 0.86 0.774 0.942)",
    "variable": "var(--colors-purple-light-p3-6)"
  },
  "colors.purple.light.p3.7": {
    "value": "color(display-p3 0.799 0.69 0.91)",
    "variable": "var(--colors-purple-light-p3-7)"
  },
  "colors.purple.light.p3.8": {
    "value": "color(display-p3 0.719 0.583 0.874)",
    "variable": "var(--colors-purple-light-p3-8)"
  },
  "colors.purple.light.p3.9": {
    "value": "color(display-p3 0.523 0.318 0.751)",
    "variable": "var(--colors-purple-light-p3-9)"
  },
  "colors.purple.light.p3.10": {
    "value": "color(display-p3 0.483 0.289 0.7)",
    "variable": "var(--colors-purple-light-p3-10)"
  },
  "colors.purple.light.p3.11": {
    "value": "color(display-p3 0.473 0.281 0.687)",
    "variable": "var(--colors-purple-light-p3-11)"
  },
  "colors.purple.light.p3.12": {
    "value": "color(display-p3 0.234 0.132 0.363)",
    "variable": "var(--colors-purple-light-p3-12)"
  },
  "colors.purple.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.024 0.675 / 0.012)",
    "variable": "var(--colors-purple-light-p3-a-1)"
  },
  "colors.purple.light.p3.a.2": {
    "value": "color(display-p3 0.443 0.024 0.722 / 0.028)",
    "variable": "var(--colors-purple-light-p3-a-2)"
  },
  "colors.purple.light.p3.a.3": {
    "value": "color(display-p3 0.506 0.008 0.835 / 0.071)",
    "variable": "var(--colors-purple-light-p3-a-3)"
  },
  "colors.purple.light.p3.a.4": {
    "value": "color(display-p3 0.451 0.004 0.831 / 0.114)",
    "variable": "var(--colors-purple-light-p3-a-4)"
  },
  "colors.purple.light.p3.a.5": {
    "value": "color(display-p3 0.431 0.004 0.788 / 0.165)",
    "variable": "var(--colors-purple-light-p3-a-5)"
  },
  "colors.purple.light.p3.a.6": {
    "value": "color(display-p3 0.384 0.004 0.745 / 0.228)",
    "variable": "var(--colors-purple-light-p3-a-6)"
  },
  "colors.purple.light.p3.a.7": {
    "value": "color(display-p3 0.357 0.004 0.71 / 0.31)",
    "variable": "var(--colors-purple-light-p3-a-7)"
  },
  "colors.purple.light.p3.a.8": {
    "value": "color(display-p3 0.322 0.004 0.702 / 0.416)",
    "variable": "var(--colors-purple-light-p3-a-8)"
  },
  "colors.purple.light.p3.a.9": {
    "value": "color(display-p3 0.298 0 0.639 / 0.683)",
    "variable": "var(--colors-purple-light-p3-a-9)"
  },
  "colors.purple.light.p3.a.10": {
    "value": "color(display-p3 0.271 0 0.58 / 0.71)",
    "variable": "var(--colors-purple-light-p3-a-10)"
  },
  "colors.purple.light.p3.a.11": {
    "value": "color(display-p3 0.473 0.281 0.687)",
    "variable": "var(--colors-purple-light-p3-a-11)"
  },
  "colors.purple.light.p3.a.12": {
    "value": "color(display-p3 0.234 0.132 0.363)",
    "variable": "var(--colors-purple-light-p3-a-12)"
  },
  "colors.purple.dark.1": {
    "value": "var(--colors-purple-dark-1)",
    "variable": "var(--colors-purple-dark-1)"
  },
  "colors.purple.dark.2": {
    "value": "var(--colors-purple-dark-2)",
    "variable": "var(--colors-purple-dark-2)"
  },
  "colors.purple.dark.3": {
    "value": "var(--colors-purple-dark-3)",
    "variable": "var(--colors-purple-dark-3)"
  },
  "colors.purple.dark.4": {
    "value": "var(--colors-purple-dark-4)",
    "variable": "var(--colors-purple-dark-4)"
  },
  "colors.purple.dark.5": {
    "value": "var(--colors-purple-dark-5)",
    "variable": "var(--colors-purple-dark-5)"
  },
  "colors.purple.dark.6": {
    "value": "var(--colors-purple-dark-6)",
    "variable": "var(--colors-purple-dark-6)"
  },
  "colors.purple.dark.7": {
    "value": "var(--colors-purple-dark-7)",
    "variable": "var(--colors-purple-dark-7)"
  },
  "colors.purple.dark.8": {
    "value": "var(--colors-purple-dark-8)",
    "variable": "var(--colors-purple-dark-8)"
  },
  "colors.purple.dark.9": {
    "value": "var(--colors-purple-dark-9)",
    "variable": "var(--colors-purple-dark-9)"
  },
  "colors.purple.dark.10": {
    "value": "var(--colors-purple-dark-10)",
    "variable": "var(--colors-purple-dark-10)"
  },
  "colors.purple.dark.11": {
    "value": "var(--colors-purple-dark-11)",
    "variable": "var(--colors-purple-dark-11)"
  },
  "colors.purple.dark.12": {
    "value": "var(--colors-purple-dark-12)",
    "variable": "var(--colors-purple-dark-12)"
  },
  "colors.purple.dark.a.1": {
    "value": "var(--colors-purple-dark-a-1)",
    "variable": "var(--colors-purple-dark-a-1)"
  },
  "colors.purple.dark.a.2": {
    "value": "var(--colors-purple-dark-a-2)",
    "variable": "var(--colors-purple-dark-a-2)"
  },
  "colors.purple.dark.a.3": {
    "value": "var(--colors-purple-dark-a-3)",
    "variable": "var(--colors-purple-dark-a-3)"
  },
  "colors.purple.dark.a.4": {
    "value": "var(--colors-purple-dark-a-4)",
    "variable": "var(--colors-purple-dark-a-4)"
  },
  "colors.purple.dark.a.5": {
    "value": "var(--colors-purple-dark-a-5)",
    "variable": "var(--colors-purple-dark-a-5)"
  },
  "colors.purple.dark.a.6": {
    "value": "var(--colors-purple-dark-a-6)",
    "variable": "var(--colors-purple-dark-a-6)"
  },
  "colors.purple.dark.a.7": {
    "value": "var(--colors-purple-dark-a-7)",
    "variable": "var(--colors-purple-dark-a-7)"
  },
  "colors.purple.dark.a.8": {
    "value": "var(--colors-purple-dark-a-8)",
    "variable": "var(--colors-purple-dark-a-8)"
  },
  "colors.purple.dark.a.9": {
    "value": "var(--colors-purple-dark-a-9)",
    "variable": "var(--colors-purple-dark-a-9)"
  },
  "colors.purple.dark.a.10": {
    "value": "var(--colors-purple-dark-a-10)",
    "variable": "var(--colors-purple-dark-a-10)"
  },
  "colors.purple.dark.a.11": {
    "value": "var(--colors-purple-dark-a-11)",
    "variable": "var(--colors-purple-dark-a-11)"
  },
  "colors.purple.dark.a.12": {
    "value": "var(--colors-purple-dark-a-12)",
    "variable": "var(--colors-purple-dark-a-12)"
  },
  "colors.purple.dark.p3.1": {
    "value": "color(display-p3 0.09 0.068 0.103)",
    "variable": "var(--colors-purple-dark-p3-1)"
  },
  "colors.purple.dark.p3.2": {
    "value": "color(display-p3 0.113 0.082 0.134)",
    "variable": "var(--colors-purple-dark-p3-2)"
  },
  "colors.purple.dark.p3.3": {
    "value": "color(display-p3 0.175 0.112 0.224)",
    "variable": "var(--colors-purple-dark-p3-3)"
  },
  "colors.purple.dark.p3.4": {
    "value": "color(display-p3 0.224 0.137 0.297)",
    "variable": "var(--colors-purple-dark-p3-4)"
  },
  "colors.purple.dark.p3.5": {
    "value": "color(display-p3 0.264 0.167 0.349)",
    "variable": "var(--colors-purple-dark-p3-5)"
  },
  "colors.purple.dark.p3.6": {
    "value": "color(display-p3 0.311 0.208 0.406)",
    "variable": "var(--colors-purple-dark-p3-6)"
  },
  "colors.purple.dark.p3.7": {
    "value": "color(display-p3 0.381 0.266 0.496)",
    "variable": "var(--colors-purple-dark-p3-7)"
  },
  "colors.purple.dark.p3.8": {
    "value": "color(display-p3 0.49 0.349 0.649)",
    "variable": "var(--colors-purple-dark-p3-8)"
  },
  "colors.purple.dark.p3.9": {
    "value": "color(display-p3 0.523 0.318 0.751)",
    "variable": "var(--colors-purple-dark-p3-9)"
  },
  "colors.purple.dark.p3.10": {
    "value": "color(display-p3 0.57 0.373 0.791)",
    "variable": "var(--colors-purple-dark-p3-10)"
  },
  "colors.purple.dark.p3.11": {
    "value": "color(display-p3 0.8 0.62 1)",
    "variable": "var(--colors-purple-dark-p3-11)"
  },
  "colors.purple.dark.p3.12": {
    "value": "color(display-p3 0.913 0.854 0.971)",
    "variable": "var(--colors-purple-dark-p3-12)"
  },
  "colors.purple.dark.p3.a.1": {
    "value": "color(display-p3 0.686 0.071 0.996 / 0.038)",
    "variable": "var(--colors-purple-dark-p3-a-1)"
  },
  "colors.purple.dark.p3.a.2": {
    "value": "color(display-p3 0.722 0.286 0.996 / 0.072)",
    "variable": "var(--colors-purple-dark-p3-a-2)"
  },
  "colors.purple.dark.p3.a.3": {
    "value": "color(display-p3 0.718 0.349 0.996 / 0.169)",
    "variable": "var(--colors-purple-dark-p3-a-3)"
  },
  "colors.purple.dark.p3.a.4": {
    "value": "color(display-p3 0.702 0.353 1 / 0.248)",
    "variable": "var(--colors-purple-dark-p3-a-4)"
  },
  "colors.purple.dark.p3.a.5": {
    "value": "color(display-p3 0.718 0.404 1 / 0.303)",
    "variable": "var(--colors-purple-dark-p3-a-5)"
  },
  "colors.purple.dark.p3.a.6": {
    "value": "color(display-p3 0.733 0.455 1 / 0.366)",
    "variable": "var(--colors-purple-dark-p3-a-6)"
  },
  "colors.purple.dark.p3.a.7": {
    "value": "color(display-p3 0.753 0.506 1 / 0.458)",
    "variable": "var(--colors-purple-dark-p3-a-7)"
  },
  "colors.purple.dark.p3.a.8": {
    "value": "color(display-p3 0.749 0.522 1 / 0.622)",
    "variable": "var(--colors-purple-dark-p3-a-8)"
  },
  "colors.purple.dark.p3.a.9": {
    "value": "color(display-p3 0.686 0.408 1 / 0.736)",
    "variable": "var(--colors-purple-dark-p3-a-9)"
  },
  "colors.purple.dark.p3.a.10": {
    "value": "color(display-p3 0.71 0.459 1 / 0.778)",
    "variable": "var(--colors-purple-dark-p3-a-10)"
  },
  "colors.purple.dark.p3.a.11": {
    "value": "color(display-p3 0.8 0.62 1)",
    "variable": "var(--colors-purple-dark-p3-a-11)"
  },
  "colors.purple.dark.p3.a.12": {
    "value": "color(display-p3 0.913 0.854 0.971)",
    "variable": "var(--colors-purple-dark-p3-a-12)"
  },
  "colors.purple.a.1": {
    "value": "var(--colors-purple-a-1)",
    "variable": "var(--colors-purple-a-1)"
  },
  "colors.purple.a.2": {
    "value": "var(--colors-purple-a-2)",
    "variable": "var(--colors-purple-a-2)"
  },
  "colors.purple.a.3": {
    "value": "var(--colors-purple-a-3)",
    "variable": "var(--colors-purple-a-3)"
  },
  "colors.purple.a.4": {
    "value": "var(--colors-purple-a-4)",
    "variable": "var(--colors-purple-a-4)"
  },
  "colors.purple.a.5": {
    "value": "var(--colors-purple-a-5)",
    "variable": "var(--colors-purple-a-5)"
  },
  "colors.purple.a.6": {
    "value": "var(--colors-purple-a-6)",
    "variable": "var(--colors-purple-a-6)"
  },
  "colors.purple.a.7": {
    "value": "var(--colors-purple-a-7)",
    "variable": "var(--colors-purple-a-7)"
  },
  "colors.purple.a.8": {
    "value": "var(--colors-purple-a-8)",
    "variable": "var(--colors-purple-a-8)"
  },
  "colors.purple.a.9": {
    "value": "var(--colors-purple-a-9)",
    "variable": "var(--colors-purple-a-9)"
  },
  "colors.purple.a.10": {
    "value": "var(--colors-purple-a-10)",
    "variable": "var(--colors-purple-a-10)"
  },
  "colors.purple.a.11": {
    "value": "var(--colors-purple-a-11)",
    "variable": "var(--colors-purple-a-11)"
  },
  "colors.purple.a.12": {
    "value": "var(--colors-purple-a-12)",
    "variable": "var(--colors-purple-a-12)"
  },
  "colors.purple.p3.1": {
    "value": "var(--colors-purple-p3-1)",
    "variable": "var(--colors-purple-p3-1)"
  },
  "colors.purple.p3.2": {
    "value": "var(--colors-purple-p3-2)",
    "variable": "var(--colors-purple-p3-2)"
  },
  "colors.purple.p3.3": {
    "value": "var(--colors-purple-p3-3)",
    "variable": "var(--colors-purple-p3-3)"
  },
  "colors.purple.p3.4": {
    "value": "var(--colors-purple-p3-4)",
    "variable": "var(--colors-purple-p3-4)"
  },
  "colors.purple.p3.5": {
    "value": "var(--colors-purple-p3-5)",
    "variable": "var(--colors-purple-p3-5)"
  },
  "colors.purple.p3.6": {
    "value": "var(--colors-purple-p3-6)",
    "variable": "var(--colors-purple-p3-6)"
  },
  "colors.purple.p3.7": {
    "value": "var(--colors-purple-p3-7)",
    "variable": "var(--colors-purple-p3-7)"
  },
  "colors.purple.p3.8": {
    "value": "var(--colors-purple-p3-8)",
    "variable": "var(--colors-purple-p3-8)"
  },
  "colors.purple.p3.9": {
    "value": "var(--colors-purple-p3-9)",
    "variable": "var(--colors-purple-p3-9)"
  },
  "colors.purple.p3.10": {
    "value": "var(--colors-purple-p3-10)",
    "variable": "var(--colors-purple-p3-10)"
  },
  "colors.purple.p3.11": {
    "value": "var(--colors-purple-p3-11)",
    "variable": "var(--colors-purple-p3-11)"
  },
  "colors.purple.p3.12": {
    "value": "var(--colors-purple-p3-12)",
    "variable": "var(--colors-purple-p3-12)"
  },
  "colors.purple.p3.a.1": {
    "value": "var(--colors-purple-p3-a-1)",
    "variable": "var(--colors-purple-p3-a-1)"
  },
  "colors.purple.p3.a.2": {
    "value": "var(--colors-purple-p3-a-2)",
    "variable": "var(--colors-purple-p3-a-2)"
  },
  "colors.purple.p3.a.3": {
    "value": "var(--colors-purple-p3-a-3)",
    "variable": "var(--colors-purple-p3-a-3)"
  },
  "colors.purple.p3.a.4": {
    "value": "var(--colors-purple-p3-a-4)",
    "variable": "var(--colors-purple-p3-a-4)"
  },
  "colors.purple.p3.a.5": {
    "value": "var(--colors-purple-p3-a-5)",
    "variable": "var(--colors-purple-p3-a-5)"
  },
  "colors.purple.p3.a.6": {
    "value": "var(--colors-purple-p3-a-6)",
    "variable": "var(--colors-purple-p3-a-6)"
  },
  "colors.purple.p3.a.7": {
    "value": "var(--colors-purple-p3-a-7)",
    "variable": "var(--colors-purple-p3-a-7)"
  },
  "colors.purple.p3.a.8": {
    "value": "var(--colors-purple-p3-a-8)",
    "variable": "var(--colors-purple-p3-a-8)"
  },
  "colors.purple.p3.a.9": {
    "value": "var(--colors-purple-p3-a-9)",
    "variable": "var(--colors-purple-p3-a-9)"
  },
  "colors.purple.p3.a.10": {
    "value": "var(--colors-purple-p3-a-10)",
    "variable": "var(--colors-purple-p3-a-10)"
  },
  "colors.purple.p3.a.11": {
    "value": "var(--colors-purple-p3-a-11)",
    "variable": "var(--colors-purple-p3-a-11)"
  },
  "colors.purple.p3.a.12": {
    "value": "var(--colors-purple-p3-a-12)",
    "variable": "var(--colors-purple-p3-a-12)"
  },
  "colors.red.1": {
    "value": "var(--colors-red-1)",
    "variable": "var(--colors-red-1)"
  },
  "colors.red.2": {
    "value": "var(--colors-red-2)",
    "variable": "var(--colors-red-2)"
  },
  "colors.red.3": {
    "value": "var(--colors-red-3)",
    "variable": "var(--colors-red-3)"
  },
  "colors.red.4": {
    "value": "var(--colors-red-4)",
    "variable": "var(--colors-red-4)"
  },
  "colors.red.5": {
    "value": "var(--colors-red-5)",
    "variable": "var(--colors-red-5)"
  },
  "colors.red.6": {
    "value": "var(--colors-red-6)",
    "variable": "var(--colors-red-6)"
  },
  "colors.red.7": {
    "value": "var(--colors-red-7)",
    "variable": "var(--colors-red-7)"
  },
  "colors.red.8": {
    "value": "var(--colors-red-8)",
    "variable": "var(--colors-red-8)"
  },
  "colors.red.9": {
    "value": "var(--colors-red-9)",
    "variable": "var(--colors-red-9)"
  },
  "colors.red.10": {
    "value": "var(--colors-red-10)",
    "variable": "var(--colors-red-10)"
  },
  "colors.red.11": {
    "value": "var(--colors-red-11)",
    "variable": "var(--colors-red-11)"
  },
  "colors.red.12": {
    "value": "var(--colors-red-12)",
    "variable": "var(--colors-red-12)"
  },
  "colors.red.light.1": {
    "value": "var(--colors-red-light-1)",
    "variable": "var(--colors-red-light-1)"
  },
  "colors.red.light.2": {
    "value": "var(--colors-red-light-2)",
    "variable": "var(--colors-red-light-2)"
  },
  "colors.red.light.3": {
    "value": "var(--colors-red-light-3)",
    "variable": "var(--colors-red-light-3)"
  },
  "colors.red.light.4": {
    "value": "var(--colors-red-light-4)",
    "variable": "var(--colors-red-light-4)"
  },
  "colors.red.light.5": {
    "value": "var(--colors-red-light-5)",
    "variable": "var(--colors-red-light-5)"
  },
  "colors.red.light.6": {
    "value": "var(--colors-red-light-6)",
    "variable": "var(--colors-red-light-6)"
  },
  "colors.red.light.7": {
    "value": "var(--colors-red-light-7)",
    "variable": "var(--colors-red-light-7)"
  },
  "colors.red.light.8": {
    "value": "var(--colors-red-light-8)",
    "variable": "var(--colors-red-light-8)"
  },
  "colors.red.light.9": {
    "value": "var(--colors-red-light-9)",
    "variable": "var(--colors-red-light-9)"
  },
  "colors.red.light.10": {
    "value": "var(--colors-red-light-10)",
    "variable": "var(--colors-red-light-10)"
  },
  "colors.red.light.11": {
    "value": "var(--colors-red-light-11)",
    "variable": "var(--colors-red-light-11)"
  },
  "colors.red.light.12": {
    "value": "var(--colors-red-light-12)",
    "variable": "var(--colors-red-light-12)"
  },
  "colors.red.light.a.1": {
    "value": "var(--colors-red-light-a-1)",
    "variable": "var(--colors-red-light-a-1)"
  },
  "colors.red.light.a.2": {
    "value": "var(--colors-red-light-a-2)",
    "variable": "var(--colors-red-light-a-2)"
  },
  "colors.red.light.a.3": {
    "value": "var(--colors-red-light-a-3)",
    "variable": "var(--colors-red-light-a-3)"
  },
  "colors.red.light.a.4": {
    "value": "var(--colors-red-light-a-4)",
    "variable": "var(--colors-red-light-a-4)"
  },
  "colors.red.light.a.5": {
    "value": "var(--colors-red-light-a-5)",
    "variable": "var(--colors-red-light-a-5)"
  },
  "colors.red.light.a.6": {
    "value": "var(--colors-red-light-a-6)",
    "variable": "var(--colors-red-light-a-6)"
  },
  "colors.red.light.a.7": {
    "value": "var(--colors-red-light-a-7)",
    "variable": "var(--colors-red-light-a-7)"
  },
  "colors.red.light.a.8": {
    "value": "var(--colors-red-light-a-8)",
    "variable": "var(--colors-red-light-a-8)"
  },
  "colors.red.light.a.9": {
    "value": "var(--colors-red-light-a-9)",
    "variable": "var(--colors-red-light-a-9)"
  },
  "colors.red.light.a.10": {
    "value": "var(--colors-red-light-a-10)",
    "variable": "var(--colors-red-light-a-10)"
  },
  "colors.red.light.a.11": {
    "value": "var(--colors-red-light-a-11)",
    "variable": "var(--colors-red-light-a-11)"
  },
  "colors.red.light.a.12": {
    "value": "var(--colors-red-light-a-12)",
    "variable": "var(--colors-red-light-a-12)"
  },
  "colors.red.light.p3.1": {
    "value": "color(display-p3 0.998 0.989 0.988)",
    "variable": "var(--colors-red-light-p3-1)"
  },
  "colors.red.light.p3.2": {
    "value": "color(display-p3 0.995 0.971 0.971)",
    "variable": "var(--colors-red-light-p3-2)"
  },
  "colors.red.light.p3.3": {
    "value": "color(display-p3 0.985 0.925 0.925)",
    "variable": "var(--colors-red-light-p3-3)"
  },
  "colors.red.light.p3.4": {
    "value": "color(display-p3 0.999 0.866 0.866)",
    "variable": "var(--colors-red-light-p3-4)"
  },
  "colors.red.light.p3.5": {
    "value": "color(display-p3 0.984 0.812 0.811)",
    "variable": "var(--colors-red-light-p3-5)"
  },
  "colors.red.light.p3.6": {
    "value": "color(display-p3 0.955 0.751 0.749)",
    "variable": "var(--colors-red-light-p3-6)"
  },
  "colors.red.light.p3.7": {
    "value": "color(display-p3 0.915 0.675 0.672)",
    "variable": "var(--colors-red-light-p3-7)"
  },
  "colors.red.light.p3.8": {
    "value": "color(display-p3 0.872 0.575 0.572)",
    "variable": "var(--colors-red-light-p3-8)"
  },
  "colors.red.light.p3.9": {
    "value": "color(display-p3 0.83 0.329 0.324)",
    "variable": "var(--colors-red-light-p3-9)"
  },
  "colors.red.light.p3.10": {
    "value": "color(display-p3 0.798 0.294 0.285)",
    "variable": "var(--colors-red-light-p3-10)"
  },
  "colors.red.light.p3.11": {
    "value": "color(display-p3 0.744 0.234 0.222)",
    "variable": "var(--colors-red-light-p3-11)"
  },
  "colors.red.light.p3.12": {
    "value": "color(display-p3 0.36 0.115 0.143)",
    "variable": "var(--colors-red-light-p3-12)"
  },
  "colors.red.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.024 0.024 / 0.012)",
    "variable": "var(--colors-red-light-p3-a-1)"
  },
  "colors.red.light.p3.a.2": {
    "value": "color(display-p3 0.863 0.024 0.024 / 0.028)",
    "variable": "var(--colors-red-light-p3-a-2)"
  },
  "colors.red.light.p3.a.3": {
    "value": "color(display-p3 0.792 0.008 0.008 / 0.075)",
    "variable": "var(--colors-red-light-p3-a-3)"
  },
  "colors.red.light.p3.a.4": {
    "value": "color(display-p3 1 0.008 0.008 / 0.134)",
    "variable": "var(--colors-red-light-p3-a-4)"
  },
  "colors.red.light.p3.a.5": {
    "value": "color(display-p3 0.918 0.008 0.008 / 0.189)",
    "variable": "var(--colors-red-light-p3-a-5)"
  },
  "colors.red.light.p3.a.6": {
    "value": "color(display-p3 0.831 0.02 0.004 / 0.251)",
    "variable": "var(--colors-red-light-p3-a-6)"
  },
  "colors.red.light.p3.a.7": {
    "value": "color(display-p3 0.741 0.016 0.004 / 0.33)",
    "variable": "var(--colors-red-light-p3-a-7)"
  },
  "colors.red.light.p3.a.8": {
    "value": "color(display-p3 0.698 0.012 0.004 / 0.428)",
    "variable": "var(--colors-red-light-p3-a-8)"
  },
  "colors.red.light.p3.a.9": {
    "value": "color(display-p3 0.749 0.008 0 / 0.675)",
    "variable": "var(--colors-red-light-p3-a-9)"
  },
  "colors.red.light.p3.a.10": {
    "value": "color(display-p3 0.714 0.012 0 / 0.714)",
    "variable": "var(--colors-red-light-p3-a-10)"
  },
  "colors.red.light.p3.a.11": {
    "value": "color(display-p3 0.744 0.234 0.222)",
    "variable": "var(--colors-red-light-p3-a-11)"
  },
  "colors.red.light.p3.a.12": {
    "value": "color(display-p3 0.36 0.115 0.143)",
    "variable": "var(--colors-red-light-p3-a-12)"
  },
  "colors.red.dark.1": {
    "value": "var(--colors-red-dark-1)",
    "variable": "var(--colors-red-dark-1)"
  },
  "colors.red.dark.2": {
    "value": "var(--colors-red-dark-2)",
    "variable": "var(--colors-red-dark-2)"
  },
  "colors.red.dark.3": {
    "value": "var(--colors-red-dark-3)",
    "variable": "var(--colors-red-dark-3)"
  },
  "colors.red.dark.4": {
    "value": "var(--colors-red-dark-4)",
    "variable": "var(--colors-red-dark-4)"
  },
  "colors.red.dark.5": {
    "value": "var(--colors-red-dark-5)",
    "variable": "var(--colors-red-dark-5)"
  },
  "colors.red.dark.6": {
    "value": "var(--colors-red-dark-6)",
    "variable": "var(--colors-red-dark-6)"
  },
  "colors.red.dark.7": {
    "value": "var(--colors-red-dark-7)",
    "variable": "var(--colors-red-dark-7)"
  },
  "colors.red.dark.8": {
    "value": "var(--colors-red-dark-8)",
    "variable": "var(--colors-red-dark-8)"
  },
  "colors.red.dark.9": {
    "value": "var(--colors-red-dark-9)",
    "variable": "var(--colors-red-dark-9)"
  },
  "colors.red.dark.10": {
    "value": "var(--colors-red-dark-10)",
    "variable": "var(--colors-red-dark-10)"
  },
  "colors.red.dark.11": {
    "value": "var(--colors-red-dark-11)",
    "variable": "var(--colors-red-dark-11)"
  },
  "colors.red.dark.12": {
    "value": "var(--colors-red-dark-12)",
    "variable": "var(--colors-red-dark-12)"
  },
  "colors.red.dark.a.1": {
    "value": "var(--colors-red-dark-a-1)",
    "variable": "var(--colors-red-dark-a-1)"
  },
  "colors.red.dark.a.2": {
    "value": "var(--colors-red-dark-a-2)",
    "variable": "var(--colors-red-dark-a-2)"
  },
  "colors.red.dark.a.3": {
    "value": "var(--colors-red-dark-a-3)",
    "variable": "var(--colors-red-dark-a-3)"
  },
  "colors.red.dark.a.4": {
    "value": "var(--colors-red-dark-a-4)",
    "variable": "var(--colors-red-dark-a-4)"
  },
  "colors.red.dark.a.5": {
    "value": "var(--colors-red-dark-a-5)",
    "variable": "var(--colors-red-dark-a-5)"
  },
  "colors.red.dark.a.6": {
    "value": "var(--colors-red-dark-a-6)",
    "variable": "var(--colors-red-dark-a-6)"
  },
  "colors.red.dark.a.7": {
    "value": "var(--colors-red-dark-a-7)",
    "variable": "var(--colors-red-dark-a-7)"
  },
  "colors.red.dark.a.8": {
    "value": "var(--colors-red-dark-a-8)",
    "variable": "var(--colors-red-dark-a-8)"
  },
  "colors.red.dark.a.9": {
    "value": "var(--colors-red-dark-a-9)",
    "variable": "var(--colors-red-dark-a-9)"
  },
  "colors.red.dark.a.10": {
    "value": "var(--colors-red-dark-a-10)",
    "variable": "var(--colors-red-dark-a-10)"
  },
  "colors.red.dark.a.11": {
    "value": "var(--colors-red-dark-a-11)",
    "variable": "var(--colors-red-dark-a-11)"
  },
  "colors.red.dark.a.12": {
    "value": "var(--colors-red-dark-a-12)",
    "variable": "var(--colors-red-dark-a-12)"
  },
  "colors.red.dark.p3.1": {
    "value": "color(display-p3 0.093 0.068 0.067)",
    "variable": "var(--colors-red-dark-p3-1)"
  },
  "colors.red.dark.p3.2": {
    "value": "color(display-p3 0.118 0.077 0.079)",
    "variable": "var(--colors-red-dark-p3-2)"
  },
  "colors.red.dark.p3.3": {
    "value": "color(display-p3 0.211 0.081 0.099)",
    "variable": "var(--colors-red-dark-p3-3)"
  },
  "colors.red.dark.p3.4": {
    "value": "color(display-p3 0.287 0.079 0.113)",
    "variable": "var(--colors-red-dark-p3-4)"
  },
  "colors.red.dark.p3.5": {
    "value": "color(display-p3 0.348 0.11 0.142)",
    "variable": "var(--colors-red-dark-p3-5)"
  },
  "colors.red.dark.p3.6": {
    "value": "color(display-p3 0.414 0.16 0.183)",
    "variable": "var(--colors-red-dark-p3-6)"
  },
  "colors.red.dark.p3.7": {
    "value": "color(display-p3 0.508 0.224 0.236)",
    "variable": "var(--colors-red-dark-p3-7)"
  },
  "colors.red.dark.p3.8": {
    "value": "color(display-p3 0.659 0.298 0.297)",
    "variable": "var(--colors-red-dark-p3-8)"
  },
  "colors.red.dark.p3.9": {
    "value": "color(display-p3 0.83 0.329 0.324)",
    "variable": "var(--colors-red-dark-p3-9)"
  },
  "colors.red.dark.p3.10": {
    "value": "color(display-p3 0.861 0.403 0.387)",
    "variable": "var(--colors-red-dark-p3-10)"
  },
  "colors.red.dark.p3.11": {
    "value": "color(display-p3 1 0.57 0.55)",
    "variable": "var(--colors-red-dark-p3-11)"
  },
  "colors.red.dark.p3.12": {
    "value": "color(display-p3 0.971 0.826 0.852)",
    "variable": "var(--colors-red-dark-p3-12)"
  },
  "colors.red.dark.p3.a.1": {
    "value": "color(display-p3 0.984 0.071 0.071 / 0.03)",
    "variable": "var(--colors-red-dark-p3-a-1)"
  },
  "colors.red.dark.p3.a.2": {
    "value": "color(display-p3 0.996 0.282 0.282 / 0.055)",
    "variable": "var(--colors-red-dark-p3-a-2)"
  },
  "colors.red.dark.p3.a.3": {
    "value": "color(display-p3 1 0.169 0.271 / 0.156)",
    "variable": "var(--colors-red-dark-p3-a-3)"
  },
  "colors.red.dark.p3.a.4": {
    "value": "color(display-p3 1 0.118 0.267 / 0.236)",
    "variable": "var(--colors-red-dark-p3-a-4)"
  },
  "colors.red.dark.p3.a.5": {
    "value": "color(display-p3 1 0.212 0.314 / 0.303)",
    "variable": "var(--colors-red-dark-p3-a-5)"
  },
  "colors.red.dark.p3.a.6": {
    "value": "color(display-p3 1 0.318 0.38 / 0.374)",
    "variable": "var(--colors-red-dark-p3-a-6)"
  },
  "colors.red.dark.p3.a.7": {
    "value": "color(display-p3 1 0.4 0.424 / 0.475)",
    "variable": "var(--colors-red-dark-p3-a-7)"
  },
  "colors.red.dark.p3.a.8": {
    "value": "color(display-p3 1 0.431 0.431 / 0.635)",
    "variable": "var(--colors-red-dark-p3-a-8)"
  },
  "colors.red.dark.p3.a.9": {
    "value": "color(display-p3 1 0.388 0.384 / 0.82)",
    "variable": "var(--colors-red-dark-p3-a-9)"
  },
  "colors.red.dark.p3.a.10": {
    "value": "color(display-p3 1 0.463 0.447 / 0.853)",
    "variable": "var(--colors-red-dark-p3-a-10)"
  },
  "colors.red.dark.p3.a.11": {
    "value": "color(display-p3 1 0.57 0.55)",
    "variable": "var(--colors-red-dark-p3-a-11)"
  },
  "colors.red.dark.p3.a.12": {
    "value": "color(display-p3 0.971 0.826 0.852)",
    "variable": "var(--colors-red-dark-p3-a-12)"
  },
  "colors.red.a.1": {
    "value": "var(--colors-red-a-1)",
    "variable": "var(--colors-red-a-1)"
  },
  "colors.red.a.2": {
    "value": "var(--colors-red-a-2)",
    "variable": "var(--colors-red-a-2)"
  },
  "colors.red.a.3": {
    "value": "var(--colors-red-a-3)",
    "variable": "var(--colors-red-a-3)"
  },
  "colors.red.a.4": {
    "value": "var(--colors-red-a-4)",
    "variable": "var(--colors-red-a-4)"
  },
  "colors.red.a.5": {
    "value": "var(--colors-red-a-5)",
    "variable": "var(--colors-red-a-5)"
  },
  "colors.red.a.6": {
    "value": "var(--colors-red-a-6)",
    "variable": "var(--colors-red-a-6)"
  },
  "colors.red.a.7": {
    "value": "var(--colors-red-a-7)",
    "variable": "var(--colors-red-a-7)"
  },
  "colors.red.a.8": {
    "value": "var(--colors-red-a-8)",
    "variable": "var(--colors-red-a-8)"
  },
  "colors.red.a.9": {
    "value": "var(--colors-red-a-9)",
    "variable": "var(--colors-red-a-9)"
  },
  "colors.red.a.10": {
    "value": "var(--colors-red-a-10)",
    "variable": "var(--colors-red-a-10)"
  },
  "colors.red.a.11": {
    "value": "var(--colors-red-a-11)",
    "variable": "var(--colors-red-a-11)"
  },
  "colors.red.a.12": {
    "value": "var(--colors-red-a-12)",
    "variable": "var(--colors-red-a-12)"
  },
  "colors.red.p3.1": {
    "value": "var(--colors-red-p3-1)",
    "variable": "var(--colors-red-p3-1)"
  },
  "colors.red.p3.2": {
    "value": "var(--colors-red-p3-2)",
    "variable": "var(--colors-red-p3-2)"
  },
  "colors.red.p3.3": {
    "value": "var(--colors-red-p3-3)",
    "variable": "var(--colors-red-p3-3)"
  },
  "colors.red.p3.4": {
    "value": "var(--colors-red-p3-4)",
    "variable": "var(--colors-red-p3-4)"
  },
  "colors.red.p3.5": {
    "value": "var(--colors-red-p3-5)",
    "variable": "var(--colors-red-p3-5)"
  },
  "colors.red.p3.6": {
    "value": "var(--colors-red-p3-6)",
    "variable": "var(--colors-red-p3-6)"
  },
  "colors.red.p3.7": {
    "value": "var(--colors-red-p3-7)",
    "variable": "var(--colors-red-p3-7)"
  },
  "colors.red.p3.8": {
    "value": "var(--colors-red-p3-8)",
    "variable": "var(--colors-red-p3-8)"
  },
  "colors.red.p3.9": {
    "value": "var(--colors-red-p3-9)",
    "variable": "var(--colors-red-p3-9)"
  },
  "colors.red.p3.10": {
    "value": "var(--colors-red-p3-10)",
    "variable": "var(--colors-red-p3-10)"
  },
  "colors.red.p3.11": {
    "value": "var(--colors-red-p3-11)",
    "variable": "var(--colors-red-p3-11)"
  },
  "colors.red.p3.12": {
    "value": "var(--colors-red-p3-12)",
    "variable": "var(--colors-red-p3-12)"
  },
  "colors.red.p3.a.1": {
    "value": "var(--colors-red-p3-a-1)",
    "variable": "var(--colors-red-p3-a-1)"
  },
  "colors.red.p3.a.2": {
    "value": "var(--colors-red-p3-a-2)",
    "variable": "var(--colors-red-p3-a-2)"
  },
  "colors.red.p3.a.3": {
    "value": "var(--colors-red-p3-a-3)",
    "variable": "var(--colors-red-p3-a-3)"
  },
  "colors.red.p3.a.4": {
    "value": "var(--colors-red-p3-a-4)",
    "variable": "var(--colors-red-p3-a-4)"
  },
  "colors.red.p3.a.5": {
    "value": "var(--colors-red-p3-a-5)",
    "variable": "var(--colors-red-p3-a-5)"
  },
  "colors.red.p3.a.6": {
    "value": "var(--colors-red-p3-a-6)",
    "variable": "var(--colors-red-p3-a-6)"
  },
  "colors.red.p3.a.7": {
    "value": "var(--colors-red-p3-a-7)",
    "variable": "var(--colors-red-p3-a-7)"
  },
  "colors.red.p3.a.8": {
    "value": "var(--colors-red-p3-a-8)",
    "variable": "var(--colors-red-p3-a-8)"
  },
  "colors.red.p3.a.9": {
    "value": "var(--colors-red-p3-a-9)",
    "variable": "var(--colors-red-p3-a-9)"
  },
  "colors.red.p3.a.10": {
    "value": "var(--colors-red-p3-a-10)",
    "variable": "var(--colors-red-p3-a-10)"
  },
  "colors.red.p3.a.11": {
    "value": "var(--colors-red-p3-a-11)",
    "variable": "var(--colors-red-p3-a-11)"
  },
  "colors.red.p3.a.12": {
    "value": "var(--colors-red-p3-a-12)",
    "variable": "var(--colors-red-p3-a-12)"
  },
  "colors.ruby.1": {
    "value": "var(--colors-ruby-1)",
    "variable": "var(--colors-ruby-1)"
  },
  "colors.ruby.2": {
    "value": "var(--colors-ruby-2)",
    "variable": "var(--colors-ruby-2)"
  },
  "colors.ruby.3": {
    "value": "var(--colors-ruby-3)",
    "variable": "var(--colors-ruby-3)"
  },
  "colors.ruby.4": {
    "value": "var(--colors-ruby-4)",
    "variable": "var(--colors-ruby-4)"
  },
  "colors.ruby.5": {
    "value": "var(--colors-ruby-5)",
    "variable": "var(--colors-ruby-5)"
  },
  "colors.ruby.6": {
    "value": "var(--colors-ruby-6)",
    "variable": "var(--colors-ruby-6)"
  },
  "colors.ruby.7": {
    "value": "var(--colors-ruby-7)",
    "variable": "var(--colors-ruby-7)"
  },
  "colors.ruby.8": {
    "value": "var(--colors-ruby-8)",
    "variable": "var(--colors-ruby-8)"
  },
  "colors.ruby.9": {
    "value": "var(--colors-ruby-9)",
    "variable": "var(--colors-ruby-9)"
  },
  "colors.ruby.10": {
    "value": "var(--colors-ruby-10)",
    "variable": "var(--colors-ruby-10)"
  },
  "colors.ruby.11": {
    "value": "var(--colors-ruby-11)",
    "variable": "var(--colors-ruby-11)"
  },
  "colors.ruby.12": {
    "value": "var(--colors-ruby-12)",
    "variable": "var(--colors-ruby-12)"
  },
  "colors.ruby.light.1": {
    "value": "var(--colors-ruby-light-1)",
    "variable": "var(--colors-ruby-light-1)"
  },
  "colors.ruby.light.2": {
    "value": "var(--colors-ruby-light-2)",
    "variable": "var(--colors-ruby-light-2)"
  },
  "colors.ruby.light.3": {
    "value": "var(--colors-ruby-light-3)",
    "variable": "var(--colors-ruby-light-3)"
  },
  "colors.ruby.light.4": {
    "value": "var(--colors-ruby-light-4)",
    "variable": "var(--colors-ruby-light-4)"
  },
  "colors.ruby.light.5": {
    "value": "var(--colors-ruby-light-5)",
    "variable": "var(--colors-ruby-light-5)"
  },
  "colors.ruby.light.6": {
    "value": "var(--colors-ruby-light-6)",
    "variable": "var(--colors-ruby-light-6)"
  },
  "colors.ruby.light.7": {
    "value": "var(--colors-ruby-light-7)",
    "variable": "var(--colors-ruby-light-7)"
  },
  "colors.ruby.light.8": {
    "value": "var(--colors-ruby-light-8)",
    "variable": "var(--colors-ruby-light-8)"
  },
  "colors.ruby.light.9": {
    "value": "var(--colors-ruby-light-9)",
    "variable": "var(--colors-ruby-light-9)"
  },
  "colors.ruby.light.10": {
    "value": "var(--colors-ruby-light-10)",
    "variable": "var(--colors-ruby-light-10)"
  },
  "colors.ruby.light.11": {
    "value": "var(--colors-ruby-light-11)",
    "variable": "var(--colors-ruby-light-11)"
  },
  "colors.ruby.light.12": {
    "value": "var(--colors-ruby-light-12)",
    "variable": "var(--colors-ruby-light-12)"
  },
  "colors.ruby.light.a.1": {
    "value": "var(--colors-ruby-light-a-1)",
    "variable": "var(--colors-ruby-light-a-1)"
  },
  "colors.ruby.light.a.2": {
    "value": "var(--colors-ruby-light-a-2)",
    "variable": "var(--colors-ruby-light-a-2)"
  },
  "colors.ruby.light.a.3": {
    "value": "var(--colors-ruby-light-a-3)",
    "variable": "var(--colors-ruby-light-a-3)"
  },
  "colors.ruby.light.a.4": {
    "value": "var(--colors-ruby-light-a-4)",
    "variable": "var(--colors-ruby-light-a-4)"
  },
  "colors.ruby.light.a.5": {
    "value": "var(--colors-ruby-light-a-5)",
    "variable": "var(--colors-ruby-light-a-5)"
  },
  "colors.ruby.light.a.6": {
    "value": "var(--colors-ruby-light-a-6)",
    "variable": "var(--colors-ruby-light-a-6)"
  },
  "colors.ruby.light.a.7": {
    "value": "var(--colors-ruby-light-a-7)",
    "variable": "var(--colors-ruby-light-a-7)"
  },
  "colors.ruby.light.a.8": {
    "value": "var(--colors-ruby-light-a-8)",
    "variable": "var(--colors-ruby-light-a-8)"
  },
  "colors.ruby.light.a.9": {
    "value": "var(--colors-ruby-light-a-9)",
    "variable": "var(--colors-ruby-light-a-9)"
  },
  "colors.ruby.light.a.10": {
    "value": "var(--colors-ruby-light-a-10)",
    "variable": "var(--colors-ruby-light-a-10)"
  },
  "colors.ruby.light.a.11": {
    "value": "var(--colors-ruby-light-a-11)",
    "variable": "var(--colors-ruby-light-a-11)"
  },
  "colors.ruby.light.a.12": {
    "value": "var(--colors-ruby-light-a-12)",
    "variable": "var(--colors-ruby-light-a-12)"
  },
  "colors.ruby.light.p3.1": {
    "value": "color(display-p3 0.998 0.989 0.992)",
    "variable": "var(--colors-ruby-light-p3-1)"
  },
  "colors.ruby.light.p3.2": {
    "value": "color(display-p3 0.995 0.971 0.974)",
    "variable": "var(--colors-ruby-light-p3-2)"
  },
  "colors.ruby.light.p3.3": {
    "value": "color(display-p3 0.983 0.92 0.928)",
    "variable": "var(--colors-ruby-light-p3-3)"
  },
  "colors.ruby.light.p3.4": {
    "value": "color(display-p3 0.987 0.869 0.885)",
    "variable": "var(--colors-ruby-light-p3-4)"
  },
  "colors.ruby.light.p3.5": {
    "value": "color(display-p3 0.968 0.817 0.839)",
    "variable": "var(--colors-ruby-light-p3-5)"
  },
  "colors.ruby.light.p3.6": {
    "value": "color(display-p3 0.937 0.758 0.786)",
    "variable": "var(--colors-ruby-light-p3-6)"
  },
  "colors.ruby.light.p3.7": {
    "value": "color(display-p3 0.897 0.685 0.721)",
    "variable": "var(--colors-ruby-light-p3-7)"
  },
  "colors.ruby.light.p3.8": {
    "value": "color(display-p3 0.851 0.588 0.639)",
    "variable": "var(--colors-ruby-light-p3-8)"
  },
  "colors.ruby.light.p3.9": {
    "value": "color(display-p3 0.83 0.323 0.408)",
    "variable": "var(--colors-ruby-light-p3-9)"
  },
  "colors.ruby.light.p3.10": {
    "value": "color(display-p3 0.795 0.286 0.375)",
    "variable": "var(--colors-ruby-light-p3-10)"
  },
  "colors.ruby.light.p3.11": {
    "value": "color(display-p3 0.728 0.211 0.311)",
    "variable": "var(--colors-ruby-light-p3-11)"
  },
  "colors.ruby.light.p3.12": {
    "value": "color(display-p3 0.36 0.115 0.171)",
    "variable": "var(--colors-ruby-light-p3-12)"
  },
  "colors.ruby.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.024 0.349 / 0.012)",
    "variable": "var(--colors-ruby-light-p3-a-1)"
  },
  "colors.ruby.light.p3.a.2": {
    "value": "color(display-p3 0.863 0.024 0.024 / 0.028)",
    "variable": "var(--colors-ruby-light-p3-a-2)"
  },
  "colors.ruby.light.p3.a.3": {
    "value": "color(display-p3 0.804 0.008 0.11 / 0.079)",
    "variable": "var(--colors-ruby-light-p3-a-3)"
  },
  "colors.ruby.light.p3.a.4": {
    "value": "color(display-p3 0.91 0.008 0.125 / 0.13)",
    "variable": "var(--colors-ruby-light-p3-a-4)"
  },
  "colors.ruby.light.p3.a.5": {
    "value": "color(display-p3 0.831 0.004 0.133 / 0.185)",
    "variable": "var(--colors-ruby-light-p3-a-5)"
  },
  "colors.ruby.light.p3.a.6": {
    "value": "color(display-p3 0.745 0.004 0.118 / 0.244)",
    "variable": "var(--colors-ruby-light-p3-a-6)"
  },
  "colors.ruby.light.p3.a.7": {
    "value": "color(display-p3 0.678 0.004 0.114 / 0.314)",
    "variable": "var(--colors-ruby-light-p3-a-7)"
  },
  "colors.ruby.light.p3.a.8": {
    "value": "color(display-p3 0.639 0.004 0.125 / 0.412)",
    "variable": "var(--colors-ruby-light-p3-a-8)"
  },
  "colors.ruby.light.p3.a.9": {
    "value": "color(display-p3 0.753 0 0.129 / 0.679)",
    "variable": "var(--colors-ruby-light-p3-a-9)"
  },
  "colors.ruby.light.p3.a.10": {
    "value": "color(display-p3 0.714 0 0.125 / 0.714)",
    "variable": "var(--colors-ruby-light-p3-a-10)"
  },
  "colors.ruby.light.p3.a.11": {
    "value": "color(display-p3 0.728 0.211 0.311)",
    "variable": "var(--colors-ruby-light-p3-a-11)"
  },
  "colors.ruby.light.p3.a.12": {
    "value": "color(display-p3 0.36 0.115 0.171)",
    "variable": "var(--colors-ruby-light-p3-a-12)"
  },
  "colors.ruby.dark.1": {
    "value": "var(--colors-ruby-dark-1)",
    "variable": "var(--colors-ruby-dark-1)"
  },
  "colors.ruby.dark.2": {
    "value": "var(--colors-ruby-dark-2)",
    "variable": "var(--colors-ruby-dark-2)"
  },
  "colors.ruby.dark.3": {
    "value": "var(--colors-ruby-dark-3)",
    "variable": "var(--colors-ruby-dark-3)"
  },
  "colors.ruby.dark.4": {
    "value": "var(--colors-ruby-dark-4)",
    "variable": "var(--colors-ruby-dark-4)"
  },
  "colors.ruby.dark.5": {
    "value": "var(--colors-ruby-dark-5)",
    "variable": "var(--colors-ruby-dark-5)"
  },
  "colors.ruby.dark.6": {
    "value": "var(--colors-ruby-dark-6)",
    "variable": "var(--colors-ruby-dark-6)"
  },
  "colors.ruby.dark.7": {
    "value": "var(--colors-ruby-dark-7)",
    "variable": "var(--colors-ruby-dark-7)"
  },
  "colors.ruby.dark.8": {
    "value": "var(--colors-ruby-dark-8)",
    "variable": "var(--colors-ruby-dark-8)"
  },
  "colors.ruby.dark.9": {
    "value": "var(--colors-ruby-dark-9)",
    "variable": "var(--colors-ruby-dark-9)"
  },
  "colors.ruby.dark.10": {
    "value": "var(--colors-ruby-dark-10)",
    "variable": "var(--colors-ruby-dark-10)"
  },
  "colors.ruby.dark.11": {
    "value": "var(--colors-ruby-dark-11)",
    "variable": "var(--colors-ruby-dark-11)"
  },
  "colors.ruby.dark.12": {
    "value": "var(--colors-ruby-dark-12)",
    "variable": "var(--colors-ruby-dark-12)"
  },
  "colors.ruby.dark.a.1": {
    "value": "var(--colors-ruby-dark-a-1)",
    "variable": "var(--colors-ruby-dark-a-1)"
  },
  "colors.ruby.dark.a.2": {
    "value": "var(--colors-ruby-dark-a-2)",
    "variable": "var(--colors-ruby-dark-a-2)"
  },
  "colors.ruby.dark.a.3": {
    "value": "var(--colors-ruby-dark-a-3)",
    "variable": "var(--colors-ruby-dark-a-3)"
  },
  "colors.ruby.dark.a.4": {
    "value": "var(--colors-ruby-dark-a-4)",
    "variable": "var(--colors-ruby-dark-a-4)"
  },
  "colors.ruby.dark.a.5": {
    "value": "var(--colors-ruby-dark-a-5)",
    "variable": "var(--colors-ruby-dark-a-5)"
  },
  "colors.ruby.dark.a.6": {
    "value": "var(--colors-ruby-dark-a-6)",
    "variable": "var(--colors-ruby-dark-a-6)"
  },
  "colors.ruby.dark.a.7": {
    "value": "var(--colors-ruby-dark-a-7)",
    "variable": "var(--colors-ruby-dark-a-7)"
  },
  "colors.ruby.dark.a.8": {
    "value": "var(--colors-ruby-dark-a-8)",
    "variable": "var(--colors-ruby-dark-a-8)"
  },
  "colors.ruby.dark.a.9": {
    "value": "var(--colors-ruby-dark-a-9)",
    "variable": "var(--colors-ruby-dark-a-9)"
  },
  "colors.ruby.dark.a.10": {
    "value": "var(--colors-ruby-dark-a-10)",
    "variable": "var(--colors-ruby-dark-a-10)"
  },
  "colors.ruby.dark.a.11": {
    "value": "var(--colors-ruby-dark-a-11)",
    "variable": "var(--colors-ruby-dark-a-11)"
  },
  "colors.ruby.dark.a.12": {
    "value": "var(--colors-ruby-dark-a-12)",
    "variable": "var(--colors-ruby-dark-a-12)"
  },
  "colors.ruby.dark.p3.1": {
    "value": "color(display-p3 0.093 0.068 0.074)",
    "variable": "var(--colors-ruby-dark-p3-1)"
  },
  "colors.ruby.dark.p3.2": {
    "value": "color(display-p3 0.113 0.083 0.089)",
    "variable": "var(--colors-ruby-dark-p3-2)"
  },
  "colors.ruby.dark.p3.3": {
    "value": "color(display-p3 0.208 0.088 0.117)",
    "variable": "var(--colors-ruby-dark-p3-3)"
  },
  "colors.ruby.dark.p3.4": {
    "value": "color(display-p3 0.279 0.092 0.147)",
    "variable": "var(--colors-ruby-dark-p3-4)"
  },
  "colors.ruby.dark.p3.5": {
    "value": "color(display-p3 0.337 0.12 0.18)",
    "variable": "var(--colors-ruby-dark-p3-5)"
  },
  "colors.ruby.dark.p3.6": {
    "value": "color(display-p3 0.401 0.166 0.223)",
    "variable": "var(--colors-ruby-dark-p3-6)"
  },
  "colors.ruby.dark.p3.7": {
    "value": "color(display-p3 0.495 0.224 0.281)",
    "variable": "var(--colors-ruby-dark-p3-7)"
  },
  "colors.ruby.dark.p3.8": {
    "value": "color(display-p3 0.652 0.295 0.359)",
    "variable": "var(--colors-ruby-dark-p3-8)"
  },
  "colors.ruby.dark.p3.9": {
    "value": "color(display-p3 0.83 0.323 0.408)",
    "variable": "var(--colors-ruby-dark-p3-9)"
  },
  "colors.ruby.dark.p3.10": {
    "value": "color(display-p3 0.857 0.392 0.455)",
    "variable": "var(--colors-ruby-dark-p3-10)"
  },
  "colors.ruby.dark.p3.11": {
    "value": "color(display-p3 1 0.57 0.59)",
    "variable": "var(--colors-ruby-dark-p3-11)"
  },
  "colors.ruby.dark.p3.12": {
    "value": "color(display-p3 0.968 0.83 0.88)",
    "variable": "var(--colors-ruby-dark-p3-12)"
  },
  "colors.ruby.dark.p3.a.1": {
    "value": "color(display-p3 0.984 0.071 0.329 / 0.03)",
    "variable": "var(--colors-ruby-dark-p3-a-1)"
  },
  "colors.ruby.dark.p3.a.2": {
    "value": "color(display-p3 0.992 0.376 0.529 / 0.051)",
    "variable": "var(--colors-ruby-dark-p3-a-2)"
  },
  "colors.ruby.dark.p3.a.3": {
    "value": "color(display-p3 0.996 0.196 0.404 / 0.152)",
    "variable": "var(--colors-ruby-dark-p3-a-3)"
  },
  "colors.ruby.dark.p3.a.4": {
    "value": "color(display-p3 1 0.173 0.416 / 0.227)",
    "variable": "var(--colors-ruby-dark-p3-a-4)"
  },
  "colors.ruby.dark.p3.a.5": {
    "value": "color(display-p3 1 0.259 0.459 / 0.29)",
    "variable": "var(--colors-ruby-dark-p3-a-5)"
  },
  "colors.ruby.dark.p3.a.6": {
    "value": "color(display-p3 1 0.341 0.506 / 0.358)",
    "variable": "var(--colors-ruby-dark-p3-a-6)"
  },
  "colors.ruby.dark.p3.a.7": {
    "value": "color(display-p3 1 0.412 0.541 / 0.458)",
    "variable": "var(--colors-ruby-dark-p3-a-7)"
  },
  "colors.ruby.dark.p3.a.8": {
    "value": "color(display-p3 1 0.431 0.537 / 0.627)",
    "variable": "var(--colors-ruby-dark-p3-a-8)"
  },
  "colors.ruby.dark.p3.a.9": {
    "value": "color(display-p3 1 0.376 0.482 / 0.82)",
    "variable": "var(--colors-ruby-dark-p3-a-9)"
  },
  "colors.ruby.dark.p3.a.10": {
    "value": "color(display-p3 1 0.447 0.522 / 0.849)",
    "variable": "var(--colors-ruby-dark-p3-a-10)"
  },
  "colors.ruby.dark.p3.a.11": {
    "value": "color(display-p3 1 0.57 0.59)",
    "variable": "var(--colors-ruby-dark-p3-a-11)"
  },
  "colors.ruby.dark.p3.a.12": {
    "value": "color(display-p3 0.968 0.83 0.88)",
    "variable": "var(--colors-ruby-dark-p3-a-12)"
  },
  "colors.ruby.a.1": {
    "value": "var(--colors-ruby-a-1)",
    "variable": "var(--colors-ruby-a-1)"
  },
  "colors.ruby.a.2": {
    "value": "var(--colors-ruby-a-2)",
    "variable": "var(--colors-ruby-a-2)"
  },
  "colors.ruby.a.3": {
    "value": "var(--colors-ruby-a-3)",
    "variable": "var(--colors-ruby-a-3)"
  },
  "colors.ruby.a.4": {
    "value": "var(--colors-ruby-a-4)",
    "variable": "var(--colors-ruby-a-4)"
  },
  "colors.ruby.a.5": {
    "value": "var(--colors-ruby-a-5)",
    "variable": "var(--colors-ruby-a-5)"
  },
  "colors.ruby.a.6": {
    "value": "var(--colors-ruby-a-6)",
    "variable": "var(--colors-ruby-a-6)"
  },
  "colors.ruby.a.7": {
    "value": "var(--colors-ruby-a-7)",
    "variable": "var(--colors-ruby-a-7)"
  },
  "colors.ruby.a.8": {
    "value": "var(--colors-ruby-a-8)",
    "variable": "var(--colors-ruby-a-8)"
  },
  "colors.ruby.a.9": {
    "value": "var(--colors-ruby-a-9)",
    "variable": "var(--colors-ruby-a-9)"
  },
  "colors.ruby.a.10": {
    "value": "var(--colors-ruby-a-10)",
    "variable": "var(--colors-ruby-a-10)"
  },
  "colors.ruby.a.11": {
    "value": "var(--colors-ruby-a-11)",
    "variable": "var(--colors-ruby-a-11)"
  },
  "colors.ruby.a.12": {
    "value": "var(--colors-ruby-a-12)",
    "variable": "var(--colors-ruby-a-12)"
  },
  "colors.ruby.p3.1": {
    "value": "var(--colors-ruby-p3-1)",
    "variable": "var(--colors-ruby-p3-1)"
  },
  "colors.ruby.p3.2": {
    "value": "var(--colors-ruby-p3-2)",
    "variable": "var(--colors-ruby-p3-2)"
  },
  "colors.ruby.p3.3": {
    "value": "var(--colors-ruby-p3-3)",
    "variable": "var(--colors-ruby-p3-3)"
  },
  "colors.ruby.p3.4": {
    "value": "var(--colors-ruby-p3-4)",
    "variable": "var(--colors-ruby-p3-4)"
  },
  "colors.ruby.p3.5": {
    "value": "var(--colors-ruby-p3-5)",
    "variable": "var(--colors-ruby-p3-5)"
  },
  "colors.ruby.p3.6": {
    "value": "var(--colors-ruby-p3-6)",
    "variable": "var(--colors-ruby-p3-6)"
  },
  "colors.ruby.p3.7": {
    "value": "var(--colors-ruby-p3-7)",
    "variable": "var(--colors-ruby-p3-7)"
  },
  "colors.ruby.p3.8": {
    "value": "var(--colors-ruby-p3-8)",
    "variable": "var(--colors-ruby-p3-8)"
  },
  "colors.ruby.p3.9": {
    "value": "var(--colors-ruby-p3-9)",
    "variable": "var(--colors-ruby-p3-9)"
  },
  "colors.ruby.p3.10": {
    "value": "var(--colors-ruby-p3-10)",
    "variable": "var(--colors-ruby-p3-10)"
  },
  "colors.ruby.p3.11": {
    "value": "var(--colors-ruby-p3-11)",
    "variable": "var(--colors-ruby-p3-11)"
  },
  "colors.ruby.p3.12": {
    "value": "var(--colors-ruby-p3-12)",
    "variable": "var(--colors-ruby-p3-12)"
  },
  "colors.ruby.p3.a.1": {
    "value": "var(--colors-ruby-p3-a-1)",
    "variable": "var(--colors-ruby-p3-a-1)"
  },
  "colors.ruby.p3.a.2": {
    "value": "var(--colors-ruby-p3-a-2)",
    "variable": "var(--colors-ruby-p3-a-2)"
  },
  "colors.ruby.p3.a.3": {
    "value": "var(--colors-ruby-p3-a-3)",
    "variable": "var(--colors-ruby-p3-a-3)"
  },
  "colors.ruby.p3.a.4": {
    "value": "var(--colors-ruby-p3-a-4)",
    "variable": "var(--colors-ruby-p3-a-4)"
  },
  "colors.ruby.p3.a.5": {
    "value": "var(--colors-ruby-p3-a-5)",
    "variable": "var(--colors-ruby-p3-a-5)"
  },
  "colors.ruby.p3.a.6": {
    "value": "var(--colors-ruby-p3-a-6)",
    "variable": "var(--colors-ruby-p3-a-6)"
  },
  "colors.ruby.p3.a.7": {
    "value": "var(--colors-ruby-p3-a-7)",
    "variable": "var(--colors-ruby-p3-a-7)"
  },
  "colors.ruby.p3.a.8": {
    "value": "var(--colors-ruby-p3-a-8)",
    "variable": "var(--colors-ruby-p3-a-8)"
  },
  "colors.ruby.p3.a.9": {
    "value": "var(--colors-ruby-p3-a-9)",
    "variable": "var(--colors-ruby-p3-a-9)"
  },
  "colors.ruby.p3.a.10": {
    "value": "var(--colors-ruby-p3-a-10)",
    "variable": "var(--colors-ruby-p3-a-10)"
  },
  "colors.ruby.p3.a.11": {
    "value": "var(--colors-ruby-p3-a-11)",
    "variable": "var(--colors-ruby-p3-a-11)"
  },
  "colors.ruby.p3.a.12": {
    "value": "var(--colors-ruby-p3-a-12)",
    "variable": "var(--colors-ruby-p3-a-12)"
  },
  "colors.sage.1": {
    "value": "var(--colors-sage-1)",
    "variable": "var(--colors-sage-1)"
  },
  "colors.sage.2": {
    "value": "var(--colors-sage-2)",
    "variable": "var(--colors-sage-2)"
  },
  "colors.sage.3": {
    "value": "var(--colors-sage-3)",
    "variable": "var(--colors-sage-3)"
  },
  "colors.sage.4": {
    "value": "var(--colors-sage-4)",
    "variable": "var(--colors-sage-4)"
  },
  "colors.sage.5": {
    "value": "var(--colors-sage-5)",
    "variable": "var(--colors-sage-5)"
  },
  "colors.sage.6": {
    "value": "var(--colors-sage-6)",
    "variable": "var(--colors-sage-6)"
  },
  "colors.sage.7": {
    "value": "var(--colors-sage-7)",
    "variable": "var(--colors-sage-7)"
  },
  "colors.sage.8": {
    "value": "var(--colors-sage-8)",
    "variable": "var(--colors-sage-8)"
  },
  "colors.sage.9": {
    "value": "var(--colors-sage-9)",
    "variable": "var(--colors-sage-9)"
  },
  "colors.sage.10": {
    "value": "var(--colors-sage-10)",
    "variable": "var(--colors-sage-10)"
  },
  "colors.sage.11": {
    "value": "var(--colors-sage-11)",
    "variable": "var(--colors-sage-11)"
  },
  "colors.sage.12": {
    "value": "var(--colors-sage-12)",
    "variable": "var(--colors-sage-12)"
  },
  "colors.sage.light.1": {
    "value": "var(--colors-sage-light-1)",
    "variable": "var(--colors-sage-light-1)"
  },
  "colors.sage.light.2": {
    "value": "var(--colors-sage-light-2)",
    "variable": "var(--colors-sage-light-2)"
  },
  "colors.sage.light.3": {
    "value": "var(--colors-sage-light-3)",
    "variable": "var(--colors-sage-light-3)"
  },
  "colors.sage.light.4": {
    "value": "var(--colors-sage-light-4)",
    "variable": "var(--colors-sage-light-4)"
  },
  "colors.sage.light.5": {
    "value": "var(--colors-sage-light-5)",
    "variable": "var(--colors-sage-light-5)"
  },
  "colors.sage.light.6": {
    "value": "var(--colors-sage-light-6)",
    "variable": "var(--colors-sage-light-6)"
  },
  "colors.sage.light.7": {
    "value": "var(--colors-sage-light-7)",
    "variable": "var(--colors-sage-light-7)"
  },
  "colors.sage.light.8": {
    "value": "var(--colors-sage-light-8)",
    "variable": "var(--colors-sage-light-8)"
  },
  "colors.sage.light.9": {
    "value": "var(--colors-sage-light-9)",
    "variable": "var(--colors-sage-light-9)"
  },
  "colors.sage.light.10": {
    "value": "var(--colors-sage-light-10)",
    "variable": "var(--colors-sage-light-10)"
  },
  "colors.sage.light.11": {
    "value": "var(--colors-sage-light-11)",
    "variable": "var(--colors-sage-light-11)"
  },
  "colors.sage.light.12": {
    "value": "var(--colors-sage-light-12)",
    "variable": "var(--colors-sage-light-12)"
  },
  "colors.sage.light.a.1": {
    "value": "var(--colors-sage-light-a-1)",
    "variable": "var(--colors-sage-light-a-1)"
  },
  "colors.sage.light.a.2": {
    "value": "var(--colors-sage-light-a-2)",
    "variable": "var(--colors-sage-light-a-2)"
  },
  "colors.sage.light.a.3": {
    "value": "var(--colors-sage-light-a-3)",
    "variable": "var(--colors-sage-light-a-3)"
  },
  "colors.sage.light.a.4": {
    "value": "var(--colors-sage-light-a-4)",
    "variable": "var(--colors-sage-light-a-4)"
  },
  "colors.sage.light.a.5": {
    "value": "var(--colors-sage-light-a-5)",
    "variable": "var(--colors-sage-light-a-5)"
  },
  "colors.sage.light.a.6": {
    "value": "var(--colors-sage-light-a-6)",
    "variable": "var(--colors-sage-light-a-6)"
  },
  "colors.sage.light.a.7": {
    "value": "var(--colors-sage-light-a-7)",
    "variable": "var(--colors-sage-light-a-7)"
  },
  "colors.sage.light.a.8": {
    "value": "var(--colors-sage-light-a-8)",
    "variable": "var(--colors-sage-light-a-8)"
  },
  "colors.sage.light.a.9": {
    "value": "var(--colors-sage-light-a-9)",
    "variable": "var(--colors-sage-light-a-9)"
  },
  "colors.sage.light.a.10": {
    "value": "var(--colors-sage-light-a-10)",
    "variable": "var(--colors-sage-light-a-10)"
  },
  "colors.sage.light.a.11": {
    "value": "var(--colors-sage-light-a-11)",
    "variable": "var(--colors-sage-light-a-11)"
  },
  "colors.sage.light.a.12": {
    "value": "var(--colors-sage-light-a-12)",
    "variable": "var(--colors-sage-light-a-12)"
  },
  "colors.sage.light.p3.1": {
    "value": "color(display-p3 0.986 0.992 0.988)",
    "variable": "var(--colors-sage-light-p3-1)"
  },
  "colors.sage.light.p3.2": {
    "value": "color(display-p3 0.97 0.977 0.974)",
    "variable": "var(--colors-sage-light-p3-2)"
  },
  "colors.sage.light.p3.3": {
    "value": "color(display-p3 0.935 0.944 0.94)",
    "variable": "var(--colors-sage-light-p3-3)"
  },
  "colors.sage.light.p3.4": {
    "value": "color(display-p3 0.904 0.913 0.909)",
    "variable": "var(--colors-sage-light-p3-4)"
  },
  "colors.sage.light.p3.5": {
    "value": "color(display-p3 0.875 0.885 0.88)",
    "variable": "var(--colors-sage-light-p3-5)"
  },
  "colors.sage.light.p3.6": {
    "value": "color(display-p3 0.844 0.854 0.849)",
    "variable": "var(--colors-sage-light-p3-6)"
  },
  "colors.sage.light.p3.7": {
    "value": "color(display-p3 0.8 0.811 0.806)",
    "variable": "var(--colors-sage-light-p3-7)"
  },
  "colors.sage.light.p3.8": {
    "value": "color(display-p3 0.725 0.738 0.732)",
    "variable": "var(--colors-sage-light-p3-8)"
  },
  "colors.sage.light.p3.9": {
    "value": "color(display-p3 0.531 0.556 0.546)",
    "variable": "var(--colors-sage-light-p3-9)"
  },
  "colors.sage.light.p3.10": {
    "value": "color(display-p3 0.492 0.515 0.506)",
    "variable": "var(--colors-sage-light-p3-10)"
  },
  "colors.sage.light.p3.11": {
    "value": "color(display-p3 0.377 0.395 0.389)",
    "variable": "var(--colors-sage-light-p3-11)"
  },
  "colors.sage.light.p3.12": {
    "value": "color(display-p3 0.107 0.129 0.118)",
    "variable": "var(--colors-sage-light-p3-12)"
  },
  "colors.sage.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.514 0.267 / 0.016)",
    "variable": "var(--colors-sage-light-p3-a-1)"
  },
  "colors.sage.light.p3.a.2": {
    "value": "color(display-p3 0.02 0.267 0.145 / 0.032)",
    "variable": "var(--colors-sage-light-p3-a-2)"
  },
  "colors.sage.light.p3.a.3": {
    "value": "color(display-p3 0.008 0.184 0.125 / 0.067)",
    "variable": "var(--colors-sage-light-p3-a-3)"
  },
  "colors.sage.light.p3.a.4": {
    "value": "color(display-p3 0.012 0.094 0.051 / 0.095)",
    "variable": "var(--colors-sage-light-p3-a-4)"
  },
  "colors.sage.light.p3.a.5": {
    "value": "color(display-p3 0.008 0.098 0.035 / 0.126)",
    "variable": "var(--colors-sage-light-p3-a-5)"
  },
  "colors.sage.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.078 0.027 / 0.157)",
    "variable": "var(--colors-sage-light-p3-a-6)"
  },
  "colors.sage.light.p3.a.7": {
    "value": "color(display-p3 0 0.059 0.039 / 0.2)",
    "variable": "var(--colors-sage-light-p3-a-7)"
  },
  "colors.sage.light.p3.a.8": {
    "value": "color(display-p3 0.004 0.047 0.031 / 0.275)",
    "variable": "var(--colors-sage-light-p3-a-8)"
  },
  "colors.sage.light.p3.a.9": {
    "value": "color(display-p3 0.004 0.059 0.035 / 0.471)",
    "variable": "var(--colors-sage-light-p3-a-9)"
  },
  "colors.sage.light.p3.a.10": {
    "value": "color(display-p3 0 0.047 0.031 / 0.51)",
    "variable": "var(--colors-sage-light-p3-a-10)"
  },
  "colors.sage.light.p3.a.11": {
    "value": "color(display-p3 0 0.031 0.02 / 0.624)",
    "variable": "var(--colors-sage-light-p3-a-11)"
  },
  "colors.sage.light.p3.a.12": {
    "value": "color(display-p3 0 0.027 0.012 / 0.895)",
    "variable": "var(--colors-sage-light-p3-a-12)"
  },
  "colors.sage.dark.1": {
    "value": "var(--colors-sage-dark-1)",
    "variable": "var(--colors-sage-dark-1)"
  },
  "colors.sage.dark.2": {
    "value": "var(--colors-sage-dark-2)",
    "variable": "var(--colors-sage-dark-2)"
  },
  "colors.sage.dark.3": {
    "value": "var(--colors-sage-dark-3)",
    "variable": "var(--colors-sage-dark-3)"
  },
  "colors.sage.dark.4": {
    "value": "var(--colors-sage-dark-4)",
    "variable": "var(--colors-sage-dark-4)"
  },
  "colors.sage.dark.5": {
    "value": "var(--colors-sage-dark-5)",
    "variable": "var(--colors-sage-dark-5)"
  },
  "colors.sage.dark.6": {
    "value": "var(--colors-sage-dark-6)",
    "variable": "var(--colors-sage-dark-6)"
  },
  "colors.sage.dark.7": {
    "value": "var(--colors-sage-dark-7)",
    "variable": "var(--colors-sage-dark-7)"
  },
  "colors.sage.dark.8": {
    "value": "var(--colors-sage-dark-8)",
    "variable": "var(--colors-sage-dark-8)"
  },
  "colors.sage.dark.9": {
    "value": "var(--colors-sage-dark-9)",
    "variable": "var(--colors-sage-dark-9)"
  },
  "colors.sage.dark.10": {
    "value": "var(--colors-sage-dark-10)",
    "variable": "var(--colors-sage-dark-10)"
  },
  "colors.sage.dark.11": {
    "value": "var(--colors-sage-dark-11)",
    "variable": "var(--colors-sage-dark-11)"
  },
  "colors.sage.dark.12": {
    "value": "var(--colors-sage-dark-12)",
    "variable": "var(--colors-sage-dark-12)"
  },
  "colors.sage.dark.a.1": {
    "value": "var(--colors-sage-dark-a-1)",
    "variable": "var(--colors-sage-dark-a-1)"
  },
  "colors.sage.dark.a.2": {
    "value": "var(--colors-sage-dark-a-2)",
    "variable": "var(--colors-sage-dark-a-2)"
  },
  "colors.sage.dark.a.3": {
    "value": "var(--colors-sage-dark-a-3)",
    "variable": "var(--colors-sage-dark-a-3)"
  },
  "colors.sage.dark.a.4": {
    "value": "var(--colors-sage-dark-a-4)",
    "variable": "var(--colors-sage-dark-a-4)"
  },
  "colors.sage.dark.a.5": {
    "value": "var(--colors-sage-dark-a-5)",
    "variable": "var(--colors-sage-dark-a-5)"
  },
  "colors.sage.dark.a.6": {
    "value": "var(--colors-sage-dark-a-6)",
    "variable": "var(--colors-sage-dark-a-6)"
  },
  "colors.sage.dark.a.7": {
    "value": "var(--colors-sage-dark-a-7)",
    "variable": "var(--colors-sage-dark-a-7)"
  },
  "colors.sage.dark.a.8": {
    "value": "var(--colors-sage-dark-a-8)",
    "variable": "var(--colors-sage-dark-a-8)"
  },
  "colors.sage.dark.a.9": {
    "value": "var(--colors-sage-dark-a-9)",
    "variable": "var(--colors-sage-dark-a-9)"
  },
  "colors.sage.dark.a.10": {
    "value": "var(--colors-sage-dark-a-10)",
    "variable": "var(--colors-sage-dark-a-10)"
  },
  "colors.sage.dark.a.11": {
    "value": "var(--colors-sage-dark-a-11)",
    "variable": "var(--colors-sage-dark-a-11)"
  },
  "colors.sage.dark.a.12": {
    "value": "var(--colors-sage-dark-a-12)",
    "variable": "var(--colors-sage-dark-a-12)"
  },
  "colors.sage.dark.p3.1": {
    "value": "color(display-p3 0.064 0.07 0.067)",
    "variable": "var(--colors-sage-dark-p3-1)"
  },
  "colors.sage.dark.p3.2": {
    "value": "color(display-p3 0.092 0.098 0.094)",
    "variable": "var(--colors-sage-dark-p3-2)"
  },
  "colors.sage.dark.p3.3": {
    "value": "color(display-p3 0.128 0.135 0.131)",
    "variable": "var(--colors-sage-dark-p3-3)"
  },
  "colors.sage.dark.p3.4": {
    "value": "color(display-p3 0.155 0.164 0.159)",
    "variable": "var(--colors-sage-dark-p3-4)"
  },
  "colors.sage.dark.p3.5": {
    "value": "color(display-p3 0.183 0.193 0.188)",
    "variable": "var(--colors-sage-dark-p3-5)"
  },
  "colors.sage.dark.p3.6": {
    "value": "color(display-p3 0.218 0.23 0.224)",
    "variable": "var(--colors-sage-dark-p3-6)"
  },
  "colors.sage.dark.p3.7": {
    "value": "color(display-p3 0.269 0.285 0.277)",
    "variable": "var(--colors-sage-dark-p3-7)"
  },
  "colors.sage.dark.p3.8": {
    "value": "color(display-p3 0.362 0.382 0.373)",
    "variable": "var(--colors-sage-dark-p3-8)"
  },
  "colors.sage.dark.p3.9": {
    "value": "color(display-p3 0.398 0.438 0.421)",
    "variable": "var(--colors-sage-dark-p3-9)"
  },
  "colors.sage.dark.p3.10": {
    "value": "color(display-p3 0.453 0.49 0.474)",
    "variable": "var(--colors-sage-dark-p3-10)"
  },
  "colors.sage.dark.p3.11": {
    "value": "color(display-p3 0.685 0.709 0.697)",
    "variable": "var(--colors-sage-dark-p3-11)"
  },
  "colors.sage.dark.p3.12": {
    "value": "color(display-p3 0.927 0.933 0.93)",
    "variable": "var(--colors-sage-dark-p3-12)"
  },
  "colors.sage.dark.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0)",
    "variable": "var(--colors-sage-dark-p3-a-1)"
  },
  "colors.sage.dark.p3.a.2": {
    "value": "color(display-p3 0.976 0.988 0.984 / 0.03)",
    "variable": "var(--colors-sage-dark-p3-a-2)"
  },
  "colors.sage.dark.p3.a.3": {
    "value": "color(display-p3 0.992 0.945 0.941 / 0.072)",
    "variable": "var(--colors-sage-dark-p3-a-3)"
  },
  "colors.sage.dark.p3.a.4": {
    "value": "color(display-p3 0.988 0.996 0.992 / 0.102)",
    "variable": "var(--colors-sage-dark-p3-a-4)"
  },
  "colors.sage.dark.p3.a.5": {
    "value": "color(display-p3 0.992 1 0.996 / 0.131)",
    "variable": "var(--colors-sage-dark-p3-a-5)"
  },
  "colors.sage.dark.p3.a.6": {
    "value": "color(display-p3 0.973 1 0.976 / 0.173)",
    "variable": "var(--colors-sage-dark-p3-a-6)"
  },
  "colors.sage.dark.p3.a.7": {
    "value": "color(display-p3 0.957 1 0.976 / 0.233)",
    "variable": "var(--colors-sage-dark-p3-a-7)"
  },
  "colors.sage.dark.p3.a.8": {
    "value": "color(display-p3 0.957 1 0.984 / 0.334)",
    "variable": "var(--colors-sage-dark-p3-a-8)"
  },
  "colors.sage.dark.p3.a.9": {
    "value": "color(display-p3 0.902 1 0.957 / 0.397)",
    "variable": "var(--colors-sage-dark-p3-a-9)"
  },
  "colors.sage.dark.p3.a.10": {
    "value": "color(display-p3 0.929 1 0.973 / 0.452)",
    "variable": "var(--colors-sage-dark-p3-a-10)"
  },
  "colors.sage.dark.p3.a.11": {
    "value": "color(display-p3 0.969 1 0.988 / 0.688)",
    "variable": "var(--colors-sage-dark-p3-a-11)"
  },
  "colors.sage.dark.p3.a.12": {
    "value": "color(display-p3 0.992 1 0.996 / 0.929)",
    "variable": "var(--colors-sage-dark-p3-a-12)"
  },
  "colors.sage.a.1": {
    "value": "var(--colors-sage-a-1)",
    "variable": "var(--colors-sage-a-1)"
  },
  "colors.sage.a.2": {
    "value": "var(--colors-sage-a-2)",
    "variable": "var(--colors-sage-a-2)"
  },
  "colors.sage.a.3": {
    "value": "var(--colors-sage-a-3)",
    "variable": "var(--colors-sage-a-3)"
  },
  "colors.sage.a.4": {
    "value": "var(--colors-sage-a-4)",
    "variable": "var(--colors-sage-a-4)"
  },
  "colors.sage.a.5": {
    "value": "var(--colors-sage-a-5)",
    "variable": "var(--colors-sage-a-5)"
  },
  "colors.sage.a.6": {
    "value": "var(--colors-sage-a-6)",
    "variable": "var(--colors-sage-a-6)"
  },
  "colors.sage.a.7": {
    "value": "var(--colors-sage-a-7)",
    "variable": "var(--colors-sage-a-7)"
  },
  "colors.sage.a.8": {
    "value": "var(--colors-sage-a-8)",
    "variable": "var(--colors-sage-a-8)"
  },
  "colors.sage.a.9": {
    "value": "var(--colors-sage-a-9)",
    "variable": "var(--colors-sage-a-9)"
  },
  "colors.sage.a.10": {
    "value": "var(--colors-sage-a-10)",
    "variable": "var(--colors-sage-a-10)"
  },
  "colors.sage.a.11": {
    "value": "var(--colors-sage-a-11)",
    "variable": "var(--colors-sage-a-11)"
  },
  "colors.sage.a.12": {
    "value": "var(--colors-sage-a-12)",
    "variable": "var(--colors-sage-a-12)"
  },
  "colors.sage.p3.1": {
    "value": "var(--colors-sage-p3-1)",
    "variable": "var(--colors-sage-p3-1)"
  },
  "colors.sage.p3.2": {
    "value": "var(--colors-sage-p3-2)",
    "variable": "var(--colors-sage-p3-2)"
  },
  "colors.sage.p3.3": {
    "value": "var(--colors-sage-p3-3)",
    "variable": "var(--colors-sage-p3-3)"
  },
  "colors.sage.p3.4": {
    "value": "var(--colors-sage-p3-4)",
    "variable": "var(--colors-sage-p3-4)"
  },
  "colors.sage.p3.5": {
    "value": "var(--colors-sage-p3-5)",
    "variable": "var(--colors-sage-p3-5)"
  },
  "colors.sage.p3.6": {
    "value": "var(--colors-sage-p3-6)",
    "variable": "var(--colors-sage-p3-6)"
  },
  "colors.sage.p3.7": {
    "value": "var(--colors-sage-p3-7)",
    "variable": "var(--colors-sage-p3-7)"
  },
  "colors.sage.p3.8": {
    "value": "var(--colors-sage-p3-8)",
    "variable": "var(--colors-sage-p3-8)"
  },
  "colors.sage.p3.9": {
    "value": "var(--colors-sage-p3-9)",
    "variable": "var(--colors-sage-p3-9)"
  },
  "colors.sage.p3.10": {
    "value": "var(--colors-sage-p3-10)",
    "variable": "var(--colors-sage-p3-10)"
  },
  "colors.sage.p3.11": {
    "value": "var(--colors-sage-p3-11)",
    "variable": "var(--colors-sage-p3-11)"
  },
  "colors.sage.p3.12": {
    "value": "var(--colors-sage-p3-12)",
    "variable": "var(--colors-sage-p3-12)"
  },
  "colors.sage.p3.a.1": {
    "value": "var(--colors-sage-p3-a-1)",
    "variable": "var(--colors-sage-p3-a-1)"
  },
  "colors.sage.p3.a.2": {
    "value": "var(--colors-sage-p3-a-2)",
    "variable": "var(--colors-sage-p3-a-2)"
  },
  "colors.sage.p3.a.3": {
    "value": "var(--colors-sage-p3-a-3)",
    "variable": "var(--colors-sage-p3-a-3)"
  },
  "colors.sage.p3.a.4": {
    "value": "var(--colors-sage-p3-a-4)",
    "variable": "var(--colors-sage-p3-a-4)"
  },
  "colors.sage.p3.a.5": {
    "value": "var(--colors-sage-p3-a-5)",
    "variable": "var(--colors-sage-p3-a-5)"
  },
  "colors.sage.p3.a.6": {
    "value": "var(--colors-sage-p3-a-6)",
    "variable": "var(--colors-sage-p3-a-6)"
  },
  "colors.sage.p3.a.7": {
    "value": "var(--colors-sage-p3-a-7)",
    "variable": "var(--colors-sage-p3-a-7)"
  },
  "colors.sage.p3.a.8": {
    "value": "var(--colors-sage-p3-a-8)",
    "variable": "var(--colors-sage-p3-a-8)"
  },
  "colors.sage.p3.a.9": {
    "value": "var(--colors-sage-p3-a-9)",
    "variable": "var(--colors-sage-p3-a-9)"
  },
  "colors.sage.p3.a.10": {
    "value": "var(--colors-sage-p3-a-10)",
    "variable": "var(--colors-sage-p3-a-10)"
  },
  "colors.sage.p3.a.11": {
    "value": "var(--colors-sage-p3-a-11)",
    "variable": "var(--colors-sage-p3-a-11)"
  },
  "colors.sage.p3.a.12": {
    "value": "var(--colors-sage-p3-a-12)",
    "variable": "var(--colors-sage-p3-a-12)"
  },
  "colors.sand.1": {
    "value": "var(--colors-sand-1)",
    "variable": "var(--colors-sand-1)"
  },
  "colors.sand.2": {
    "value": "var(--colors-sand-2)",
    "variable": "var(--colors-sand-2)"
  },
  "colors.sand.3": {
    "value": "var(--colors-sand-3)",
    "variable": "var(--colors-sand-3)"
  },
  "colors.sand.4": {
    "value": "var(--colors-sand-4)",
    "variable": "var(--colors-sand-4)"
  },
  "colors.sand.5": {
    "value": "var(--colors-sand-5)",
    "variable": "var(--colors-sand-5)"
  },
  "colors.sand.6": {
    "value": "var(--colors-sand-6)",
    "variable": "var(--colors-sand-6)"
  },
  "colors.sand.7": {
    "value": "var(--colors-sand-7)",
    "variable": "var(--colors-sand-7)"
  },
  "colors.sand.8": {
    "value": "var(--colors-sand-8)",
    "variable": "var(--colors-sand-8)"
  },
  "colors.sand.9": {
    "value": "var(--colors-sand-9)",
    "variable": "var(--colors-sand-9)"
  },
  "colors.sand.10": {
    "value": "var(--colors-sand-10)",
    "variable": "var(--colors-sand-10)"
  },
  "colors.sand.11": {
    "value": "var(--colors-sand-11)",
    "variable": "var(--colors-sand-11)"
  },
  "colors.sand.12": {
    "value": "var(--colors-sand-12)",
    "variable": "var(--colors-sand-12)"
  },
  "colors.sand.light.1": {
    "value": "var(--colors-sand-light-1)",
    "variable": "var(--colors-sand-light-1)"
  },
  "colors.sand.light.2": {
    "value": "var(--colors-sand-light-2)",
    "variable": "var(--colors-sand-light-2)"
  },
  "colors.sand.light.3": {
    "value": "var(--colors-sand-light-3)",
    "variable": "var(--colors-sand-light-3)"
  },
  "colors.sand.light.4": {
    "value": "var(--colors-sand-light-4)",
    "variable": "var(--colors-sand-light-4)"
  },
  "colors.sand.light.5": {
    "value": "var(--colors-sand-light-5)",
    "variable": "var(--colors-sand-light-5)"
  },
  "colors.sand.light.6": {
    "value": "var(--colors-sand-light-6)",
    "variable": "var(--colors-sand-light-6)"
  },
  "colors.sand.light.7": {
    "value": "var(--colors-sand-light-7)",
    "variable": "var(--colors-sand-light-7)"
  },
  "colors.sand.light.8": {
    "value": "var(--colors-sand-light-8)",
    "variable": "var(--colors-sand-light-8)"
  },
  "colors.sand.light.9": {
    "value": "var(--colors-sand-light-9)",
    "variable": "var(--colors-sand-light-9)"
  },
  "colors.sand.light.10": {
    "value": "var(--colors-sand-light-10)",
    "variable": "var(--colors-sand-light-10)"
  },
  "colors.sand.light.11": {
    "value": "var(--colors-sand-light-11)",
    "variable": "var(--colors-sand-light-11)"
  },
  "colors.sand.light.12": {
    "value": "var(--colors-sand-light-12)",
    "variable": "var(--colors-sand-light-12)"
  },
  "colors.sand.light.a.1": {
    "value": "var(--colors-sand-light-a-1)",
    "variable": "var(--colors-sand-light-a-1)"
  },
  "colors.sand.light.a.2": {
    "value": "var(--colors-sand-light-a-2)",
    "variable": "var(--colors-sand-light-a-2)"
  },
  "colors.sand.light.a.3": {
    "value": "var(--colors-sand-light-a-3)",
    "variable": "var(--colors-sand-light-a-3)"
  },
  "colors.sand.light.a.4": {
    "value": "var(--colors-sand-light-a-4)",
    "variable": "var(--colors-sand-light-a-4)"
  },
  "colors.sand.light.a.5": {
    "value": "var(--colors-sand-light-a-5)",
    "variable": "var(--colors-sand-light-a-5)"
  },
  "colors.sand.light.a.6": {
    "value": "var(--colors-sand-light-a-6)",
    "variable": "var(--colors-sand-light-a-6)"
  },
  "colors.sand.light.a.7": {
    "value": "var(--colors-sand-light-a-7)",
    "variable": "var(--colors-sand-light-a-7)"
  },
  "colors.sand.light.a.8": {
    "value": "var(--colors-sand-light-a-8)",
    "variable": "var(--colors-sand-light-a-8)"
  },
  "colors.sand.light.a.9": {
    "value": "var(--colors-sand-light-a-9)",
    "variable": "var(--colors-sand-light-a-9)"
  },
  "colors.sand.light.a.10": {
    "value": "var(--colors-sand-light-a-10)",
    "variable": "var(--colors-sand-light-a-10)"
  },
  "colors.sand.light.a.11": {
    "value": "var(--colors-sand-light-a-11)",
    "variable": "var(--colors-sand-light-a-11)"
  },
  "colors.sand.light.a.12": {
    "value": "var(--colors-sand-light-a-12)",
    "variable": "var(--colors-sand-light-a-12)"
  },
  "colors.sand.light.p3.1": {
    "value": "color(display-p3 0.992 0.992 0.989)",
    "variable": "var(--colors-sand-light-p3-1)"
  },
  "colors.sand.light.p3.2": {
    "value": "color(display-p3 0.977 0.977 0.973)",
    "variable": "var(--colors-sand-light-p3-2)"
  },
  "colors.sand.light.p3.3": {
    "value": "color(display-p3 0.943 0.942 0.936)",
    "variable": "var(--colors-sand-light-p3-3)"
  },
  "colors.sand.light.p3.4": {
    "value": "color(display-p3 0.913 0.912 0.903)",
    "variable": "var(--colors-sand-light-p3-4)"
  },
  "colors.sand.light.p3.5": {
    "value": "color(display-p3 0.885 0.883 0.873)",
    "variable": "var(--colors-sand-light-p3-5)"
  },
  "colors.sand.light.p3.6": {
    "value": "color(display-p3 0.854 0.852 0.839)",
    "variable": "var(--colors-sand-light-p3-6)"
  },
  "colors.sand.light.p3.7": {
    "value": "color(display-p3 0.813 0.81 0.794)",
    "variable": "var(--colors-sand-light-p3-7)"
  },
  "colors.sand.light.p3.8": {
    "value": "color(display-p3 0.738 0.734 0.713)",
    "variable": "var(--colors-sand-light-p3-8)"
  },
  "colors.sand.light.p3.9": {
    "value": "color(display-p3 0.553 0.553 0.528)",
    "variable": "var(--colors-sand-light-p3-9)"
  },
  "colors.sand.light.p3.10": {
    "value": "color(display-p3 0.511 0.511 0.488)",
    "variable": "var(--colors-sand-light-p3-10)"
  },
  "colors.sand.light.p3.11": {
    "value": "color(display-p3 0.388 0.388 0.37)",
    "variable": "var(--colors-sand-light-p3-11)"
  },
  "colors.sand.light.p3.12": {
    "value": "color(display-p3 0.129 0.126 0.111)",
    "variable": "var(--colors-sand-light-p3-12)"
  },
  "colors.sand.light.p3.a.1": {
    "value": "color(display-p3 0.349 0.349 0.024 / 0.012)",
    "variable": "var(--colors-sand-light-p3-a-1)"
  },
  "colors.sand.light.p3.a.2": {
    "value": "color(display-p3 0.161 0.161 0.024 / 0.028)",
    "variable": "var(--colors-sand-light-p3-a-2)"
  },
  "colors.sand.light.p3.a.3": {
    "value": "color(display-p3 0.067 0.067 0.008 / 0.063)",
    "variable": "var(--colors-sand-light-p3-a-3)"
  },
  "colors.sand.light.p3.a.4": {
    "value": "color(display-p3 0.129 0.129 0.012 / 0.099)",
    "variable": "var(--colors-sand-light-p3-a-4)"
  },
  "colors.sand.light.p3.a.5": {
    "value": "color(display-p3 0.098 0.067 0.008 / 0.126)",
    "variable": "var(--colors-sand-light-p3-a-5)"
  },
  "colors.sand.light.p3.a.6": {
    "value": "color(display-p3 0.102 0.075 0.004 / 0.161)",
    "variable": "var(--colors-sand-light-p3-a-6)"
  },
  "colors.sand.light.p3.a.7": {
    "value": "color(display-p3 0.098 0.098 0.004 / 0.208)",
    "variable": "var(--colors-sand-light-p3-a-7)"
  },
  "colors.sand.light.p3.a.8": {
    "value": "color(display-p3 0.086 0.075 0.004 / 0.287)",
    "variable": "var(--colors-sand-light-p3-a-8)"
  },
  "colors.sand.light.p3.a.9": {
    "value": "color(display-p3 0.051 0.051 0.004 / 0.471)",
    "variable": "var(--colors-sand-light-p3-a-9)"
  },
  "colors.sand.light.p3.a.10": {
    "value": "color(display-p3 0.047 0.047 0 / 0.514)",
    "variable": "var(--colors-sand-light-p3-a-10)"
  },
  "colors.sand.light.p3.a.11": {
    "value": "color(display-p3 0.031 0.031 0 / 0.632)",
    "variable": "var(--colors-sand-light-p3-a-11)"
  },
  "colors.sand.light.p3.a.12": {
    "value": "color(display-p3 0.024 0.02 0 / 0.891)",
    "variable": "var(--colors-sand-light-p3-a-12)"
  },
  "colors.sand.dark.1": {
    "value": "var(--colors-sand-dark-1)",
    "variable": "var(--colors-sand-dark-1)"
  },
  "colors.sand.dark.2": {
    "value": "var(--colors-sand-dark-2)",
    "variable": "var(--colors-sand-dark-2)"
  },
  "colors.sand.dark.3": {
    "value": "var(--colors-sand-dark-3)",
    "variable": "var(--colors-sand-dark-3)"
  },
  "colors.sand.dark.4": {
    "value": "var(--colors-sand-dark-4)",
    "variable": "var(--colors-sand-dark-4)"
  },
  "colors.sand.dark.5": {
    "value": "var(--colors-sand-dark-5)",
    "variable": "var(--colors-sand-dark-5)"
  },
  "colors.sand.dark.6": {
    "value": "var(--colors-sand-dark-6)",
    "variable": "var(--colors-sand-dark-6)"
  },
  "colors.sand.dark.7": {
    "value": "var(--colors-sand-dark-7)",
    "variable": "var(--colors-sand-dark-7)"
  },
  "colors.sand.dark.8": {
    "value": "var(--colors-sand-dark-8)",
    "variable": "var(--colors-sand-dark-8)"
  },
  "colors.sand.dark.9": {
    "value": "var(--colors-sand-dark-9)",
    "variable": "var(--colors-sand-dark-9)"
  },
  "colors.sand.dark.10": {
    "value": "var(--colors-sand-dark-10)",
    "variable": "var(--colors-sand-dark-10)"
  },
  "colors.sand.dark.11": {
    "value": "var(--colors-sand-dark-11)",
    "variable": "var(--colors-sand-dark-11)"
  },
  "colors.sand.dark.12": {
    "value": "var(--colors-sand-dark-12)",
    "variable": "var(--colors-sand-dark-12)"
  },
  "colors.sand.dark.a.1": {
    "value": "var(--colors-sand-dark-a-1)",
    "variable": "var(--colors-sand-dark-a-1)"
  },
  "colors.sand.dark.a.2": {
    "value": "var(--colors-sand-dark-a-2)",
    "variable": "var(--colors-sand-dark-a-2)"
  },
  "colors.sand.dark.a.3": {
    "value": "var(--colors-sand-dark-a-3)",
    "variable": "var(--colors-sand-dark-a-3)"
  },
  "colors.sand.dark.a.4": {
    "value": "var(--colors-sand-dark-a-4)",
    "variable": "var(--colors-sand-dark-a-4)"
  },
  "colors.sand.dark.a.5": {
    "value": "var(--colors-sand-dark-a-5)",
    "variable": "var(--colors-sand-dark-a-5)"
  },
  "colors.sand.dark.a.6": {
    "value": "var(--colors-sand-dark-a-6)",
    "variable": "var(--colors-sand-dark-a-6)"
  },
  "colors.sand.dark.a.7": {
    "value": "var(--colors-sand-dark-a-7)",
    "variable": "var(--colors-sand-dark-a-7)"
  },
  "colors.sand.dark.a.8": {
    "value": "var(--colors-sand-dark-a-8)",
    "variable": "var(--colors-sand-dark-a-8)"
  },
  "colors.sand.dark.a.9": {
    "value": "var(--colors-sand-dark-a-9)",
    "variable": "var(--colors-sand-dark-a-9)"
  },
  "colors.sand.dark.a.10": {
    "value": "var(--colors-sand-dark-a-10)",
    "variable": "var(--colors-sand-dark-a-10)"
  },
  "colors.sand.dark.a.11": {
    "value": "var(--colors-sand-dark-a-11)",
    "variable": "var(--colors-sand-dark-a-11)"
  },
  "colors.sand.dark.a.12": {
    "value": "var(--colors-sand-dark-a-12)",
    "variable": "var(--colors-sand-dark-a-12)"
  },
  "colors.sand.dark.p3.1": {
    "value": "color(display-p3 0.067 0.067 0.063)",
    "variable": "var(--colors-sand-dark-p3-1)"
  },
  "colors.sand.dark.p3.2": {
    "value": "color(display-p3 0.098 0.098 0.094)",
    "variable": "var(--colors-sand-dark-p3-2)"
  },
  "colors.sand.dark.p3.3": {
    "value": "color(display-p3 0.135 0.135 0.129)",
    "variable": "var(--colors-sand-dark-p3-3)"
  },
  "colors.sand.dark.p3.4": {
    "value": "color(display-p3 0.164 0.163 0.156)",
    "variable": "var(--colors-sand-dark-p3-4)"
  },
  "colors.sand.dark.p3.5": {
    "value": "color(display-p3 0.193 0.192 0.183)",
    "variable": "var(--colors-sand-dark-p3-5)"
  },
  "colors.sand.dark.p3.6": {
    "value": "color(display-p3 0.23 0.229 0.217)",
    "variable": "var(--colors-sand-dark-p3-6)"
  },
  "colors.sand.dark.p3.7": {
    "value": "color(display-p3 0.285 0.282 0.267)",
    "variable": "var(--colors-sand-dark-p3-7)"
  },
  "colors.sand.dark.p3.8": {
    "value": "color(display-p3 0.384 0.378 0.357)",
    "variable": "var(--colors-sand-dark-p3-8)"
  },
  "colors.sand.dark.p3.9": {
    "value": "color(display-p3 0.434 0.428 0.403)",
    "variable": "var(--colors-sand-dark-p3-9)"
  },
  "colors.sand.dark.p3.10": {
    "value": "color(display-p3 0.487 0.481 0.456)",
    "variable": "var(--colors-sand-dark-p3-10)"
  },
  "colors.sand.dark.p3.11": {
    "value": "color(display-p3 0.707 0.703 0.68)",
    "variable": "var(--colors-sand-dark-p3-11)"
  },
  "colors.sand.dark.p3.12": {
    "value": "color(display-p3 0.933 0.933 0.926)",
    "variable": "var(--colors-sand-dark-p3-12)"
  },
  "colors.sand.dark.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0)",
    "variable": "var(--colors-sand-dark-p3-a-1)"
  },
  "colors.sand.dark.p3.a.2": {
    "value": "color(display-p3 0.992 0.992 0.988 / 0.034)",
    "variable": "var(--colors-sand-dark-p3-a-2)"
  },
  "colors.sand.dark.p3.a.3": {
    "value": "color(display-p3 0.996 0.996 0.992 / 0.072)",
    "variable": "var(--colors-sand-dark-p3-a-3)"
  },
  "colors.sand.dark.p3.a.4": {
    "value": "color(display-p3 0.992 0.992 0.953 / 0.106)",
    "variable": "var(--colors-sand-dark-p3-a-4)"
  },
  "colors.sand.dark.p3.a.5": {
    "value": "color(display-p3 1 1 0.965 / 0.135)",
    "variable": "var(--colors-sand-dark-p3-a-5)"
  },
  "colors.sand.dark.p3.a.6": {
    "value": "color(display-p3 1 0.976 0.929 / 0.177)",
    "variable": "var(--colors-sand-dark-p3-a-6)"
  },
  "colors.sand.dark.p3.a.7": {
    "value": "color(display-p3 1 0.984 0.929 / 0.236)",
    "variable": "var(--colors-sand-dark-p3-a-7)"
  },
  "colors.sand.dark.p3.a.8": {
    "value": "color(display-p3 1 0.976 0.925 / 0.341)",
    "variable": "var(--colors-sand-dark-p3-a-8)"
  },
  "colors.sand.dark.p3.a.9": {
    "value": "color(display-p3 1 0.98 0.925 / 0.395)",
    "variable": "var(--colors-sand-dark-p3-a-9)"
  },
  "colors.sand.dark.p3.a.10": {
    "value": "color(display-p3 1 0.992 0.933 / 0.45)",
    "variable": "var(--colors-sand-dark-p3-a-10)"
  },
  "colors.sand.dark.p3.a.11": {
    "value": "color(display-p3 1 0.996 0.961 / 0.685)",
    "variable": "var(--colors-sand-dark-p3-a-11)"
  },
  "colors.sand.dark.p3.a.12": {
    "value": "color(display-p3 1 1 0.992 / 0.929)",
    "variable": "var(--colors-sand-dark-p3-a-12)"
  },
  "colors.sand.a.1": {
    "value": "var(--colors-sand-a-1)",
    "variable": "var(--colors-sand-a-1)"
  },
  "colors.sand.a.2": {
    "value": "var(--colors-sand-a-2)",
    "variable": "var(--colors-sand-a-2)"
  },
  "colors.sand.a.3": {
    "value": "var(--colors-sand-a-3)",
    "variable": "var(--colors-sand-a-3)"
  },
  "colors.sand.a.4": {
    "value": "var(--colors-sand-a-4)",
    "variable": "var(--colors-sand-a-4)"
  },
  "colors.sand.a.5": {
    "value": "var(--colors-sand-a-5)",
    "variable": "var(--colors-sand-a-5)"
  },
  "colors.sand.a.6": {
    "value": "var(--colors-sand-a-6)",
    "variable": "var(--colors-sand-a-6)"
  },
  "colors.sand.a.7": {
    "value": "var(--colors-sand-a-7)",
    "variable": "var(--colors-sand-a-7)"
  },
  "colors.sand.a.8": {
    "value": "var(--colors-sand-a-8)",
    "variable": "var(--colors-sand-a-8)"
  },
  "colors.sand.a.9": {
    "value": "var(--colors-sand-a-9)",
    "variable": "var(--colors-sand-a-9)"
  },
  "colors.sand.a.10": {
    "value": "var(--colors-sand-a-10)",
    "variable": "var(--colors-sand-a-10)"
  },
  "colors.sand.a.11": {
    "value": "var(--colors-sand-a-11)",
    "variable": "var(--colors-sand-a-11)"
  },
  "colors.sand.a.12": {
    "value": "var(--colors-sand-a-12)",
    "variable": "var(--colors-sand-a-12)"
  },
  "colors.sand.p3.1": {
    "value": "var(--colors-sand-p3-1)",
    "variable": "var(--colors-sand-p3-1)"
  },
  "colors.sand.p3.2": {
    "value": "var(--colors-sand-p3-2)",
    "variable": "var(--colors-sand-p3-2)"
  },
  "colors.sand.p3.3": {
    "value": "var(--colors-sand-p3-3)",
    "variable": "var(--colors-sand-p3-3)"
  },
  "colors.sand.p3.4": {
    "value": "var(--colors-sand-p3-4)",
    "variable": "var(--colors-sand-p3-4)"
  },
  "colors.sand.p3.5": {
    "value": "var(--colors-sand-p3-5)",
    "variable": "var(--colors-sand-p3-5)"
  },
  "colors.sand.p3.6": {
    "value": "var(--colors-sand-p3-6)",
    "variable": "var(--colors-sand-p3-6)"
  },
  "colors.sand.p3.7": {
    "value": "var(--colors-sand-p3-7)",
    "variable": "var(--colors-sand-p3-7)"
  },
  "colors.sand.p3.8": {
    "value": "var(--colors-sand-p3-8)",
    "variable": "var(--colors-sand-p3-8)"
  },
  "colors.sand.p3.9": {
    "value": "var(--colors-sand-p3-9)",
    "variable": "var(--colors-sand-p3-9)"
  },
  "colors.sand.p3.10": {
    "value": "var(--colors-sand-p3-10)",
    "variable": "var(--colors-sand-p3-10)"
  },
  "colors.sand.p3.11": {
    "value": "var(--colors-sand-p3-11)",
    "variable": "var(--colors-sand-p3-11)"
  },
  "colors.sand.p3.12": {
    "value": "var(--colors-sand-p3-12)",
    "variable": "var(--colors-sand-p3-12)"
  },
  "colors.sand.p3.a.1": {
    "value": "var(--colors-sand-p3-a-1)",
    "variable": "var(--colors-sand-p3-a-1)"
  },
  "colors.sand.p3.a.2": {
    "value": "var(--colors-sand-p3-a-2)",
    "variable": "var(--colors-sand-p3-a-2)"
  },
  "colors.sand.p3.a.3": {
    "value": "var(--colors-sand-p3-a-3)",
    "variable": "var(--colors-sand-p3-a-3)"
  },
  "colors.sand.p3.a.4": {
    "value": "var(--colors-sand-p3-a-4)",
    "variable": "var(--colors-sand-p3-a-4)"
  },
  "colors.sand.p3.a.5": {
    "value": "var(--colors-sand-p3-a-5)",
    "variable": "var(--colors-sand-p3-a-5)"
  },
  "colors.sand.p3.a.6": {
    "value": "var(--colors-sand-p3-a-6)",
    "variable": "var(--colors-sand-p3-a-6)"
  },
  "colors.sand.p3.a.7": {
    "value": "var(--colors-sand-p3-a-7)",
    "variable": "var(--colors-sand-p3-a-7)"
  },
  "colors.sand.p3.a.8": {
    "value": "var(--colors-sand-p3-a-8)",
    "variable": "var(--colors-sand-p3-a-8)"
  },
  "colors.sand.p3.a.9": {
    "value": "var(--colors-sand-p3-a-9)",
    "variable": "var(--colors-sand-p3-a-9)"
  },
  "colors.sand.p3.a.10": {
    "value": "var(--colors-sand-p3-a-10)",
    "variable": "var(--colors-sand-p3-a-10)"
  },
  "colors.sand.p3.a.11": {
    "value": "var(--colors-sand-p3-a-11)",
    "variable": "var(--colors-sand-p3-a-11)"
  },
  "colors.sand.p3.a.12": {
    "value": "var(--colors-sand-p3-a-12)",
    "variable": "var(--colors-sand-p3-a-12)"
  },
  "colors.sky.1": {
    "value": "var(--colors-sky-1)",
    "variable": "var(--colors-sky-1)"
  },
  "colors.sky.2": {
    "value": "var(--colors-sky-2)",
    "variable": "var(--colors-sky-2)"
  },
  "colors.sky.3": {
    "value": "var(--colors-sky-3)",
    "variable": "var(--colors-sky-3)"
  },
  "colors.sky.4": {
    "value": "var(--colors-sky-4)",
    "variable": "var(--colors-sky-4)"
  },
  "colors.sky.5": {
    "value": "var(--colors-sky-5)",
    "variable": "var(--colors-sky-5)"
  },
  "colors.sky.6": {
    "value": "var(--colors-sky-6)",
    "variable": "var(--colors-sky-6)"
  },
  "colors.sky.7": {
    "value": "var(--colors-sky-7)",
    "variable": "var(--colors-sky-7)"
  },
  "colors.sky.8": {
    "value": "var(--colors-sky-8)",
    "variable": "var(--colors-sky-8)"
  },
  "colors.sky.9": {
    "value": "var(--colors-sky-9)",
    "variable": "var(--colors-sky-9)"
  },
  "colors.sky.10": {
    "value": "var(--colors-sky-10)",
    "variable": "var(--colors-sky-10)"
  },
  "colors.sky.11": {
    "value": "var(--colors-sky-11)",
    "variable": "var(--colors-sky-11)"
  },
  "colors.sky.12": {
    "value": "var(--colors-sky-12)",
    "variable": "var(--colors-sky-12)"
  },
  "colors.sky.light.1": {
    "value": "var(--colors-sky-light-1)",
    "variable": "var(--colors-sky-light-1)"
  },
  "colors.sky.light.2": {
    "value": "var(--colors-sky-light-2)",
    "variable": "var(--colors-sky-light-2)"
  },
  "colors.sky.light.3": {
    "value": "var(--colors-sky-light-3)",
    "variable": "var(--colors-sky-light-3)"
  },
  "colors.sky.light.4": {
    "value": "var(--colors-sky-light-4)",
    "variable": "var(--colors-sky-light-4)"
  },
  "colors.sky.light.5": {
    "value": "var(--colors-sky-light-5)",
    "variable": "var(--colors-sky-light-5)"
  },
  "colors.sky.light.6": {
    "value": "var(--colors-sky-light-6)",
    "variable": "var(--colors-sky-light-6)"
  },
  "colors.sky.light.7": {
    "value": "var(--colors-sky-light-7)",
    "variable": "var(--colors-sky-light-7)"
  },
  "colors.sky.light.8": {
    "value": "var(--colors-sky-light-8)",
    "variable": "var(--colors-sky-light-8)"
  },
  "colors.sky.light.9": {
    "value": "var(--colors-sky-light-9)",
    "variable": "var(--colors-sky-light-9)"
  },
  "colors.sky.light.10": {
    "value": "var(--colors-sky-light-10)",
    "variable": "var(--colors-sky-light-10)"
  },
  "colors.sky.light.11": {
    "value": "var(--colors-sky-light-11)",
    "variable": "var(--colors-sky-light-11)"
  },
  "colors.sky.light.12": {
    "value": "var(--colors-sky-light-12)",
    "variable": "var(--colors-sky-light-12)"
  },
  "colors.sky.light.a.1": {
    "value": "var(--colors-sky-light-a-1)",
    "variable": "var(--colors-sky-light-a-1)"
  },
  "colors.sky.light.a.2": {
    "value": "var(--colors-sky-light-a-2)",
    "variable": "var(--colors-sky-light-a-2)"
  },
  "colors.sky.light.a.3": {
    "value": "var(--colors-sky-light-a-3)",
    "variable": "var(--colors-sky-light-a-3)"
  },
  "colors.sky.light.a.4": {
    "value": "var(--colors-sky-light-a-4)",
    "variable": "var(--colors-sky-light-a-4)"
  },
  "colors.sky.light.a.5": {
    "value": "var(--colors-sky-light-a-5)",
    "variable": "var(--colors-sky-light-a-5)"
  },
  "colors.sky.light.a.6": {
    "value": "var(--colors-sky-light-a-6)",
    "variable": "var(--colors-sky-light-a-6)"
  },
  "colors.sky.light.a.7": {
    "value": "var(--colors-sky-light-a-7)",
    "variable": "var(--colors-sky-light-a-7)"
  },
  "colors.sky.light.a.8": {
    "value": "var(--colors-sky-light-a-8)",
    "variable": "var(--colors-sky-light-a-8)"
  },
  "colors.sky.light.a.9": {
    "value": "var(--colors-sky-light-a-9)",
    "variable": "var(--colors-sky-light-a-9)"
  },
  "colors.sky.light.a.10": {
    "value": "var(--colors-sky-light-a-10)",
    "variable": "var(--colors-sky-light-a-10)"
  },
  "colors.sky.light.a.11": {
    "value": "var(--colors-sky-light-a-11)",
    "variable": "var(--colors-sky-light-a-11)"
  },
  "colors.sky.light.a.12": {
    "value": "var(--colors-sky-light-a-12)",
    "variable": "var(--colors-sky-light-a-12)"
  },
  "colors.sky.light.p3.1": {
    "value": "color(display-p3 0.98 0.995 0.999)",
    "variable": "var(--colors-sky-light-p3-1)"
  },
  "colors.sky.light.p3.2": {
    "value": "color(display-p3 0.953 0.98 0.99)",
    "variable": "var(--colors-sky-light-p3-2)"
  },
  "colors.sky.light.p3.3": {
    "value": "color(display-p3 0.899 0.963 0.989)",
    "variable": "var(--colors-sky-light-p3-3)"
  },
  "colors.sky.light.p3.4": {
    "value": "color(display-p3 0.842 0.937 0.977)",
    "variable": "var(--colors-sky-light-p3-4)"
  },
  "colors.sky.light.p3.5": {
    "value": "color(display-p3 0.777 0.9 0.954)",
    "variable": "var(--colors-sky-light-p3-5)"
  },
  "colors.sky.light.p3.6": {
    "value": "color(display-p3 0.701 0.851 0.921)",
    "variable": "var(--colors-sky-light-p3-6)"
  },
  "colors.sky.light.p3.7": {
    "value": "color(display-p3 0.604 0.785 0.879)",
    "variable": "var(--colors-sky-light-p3-7)"
  },
  "colors.sky.light.p3.8": {
    "value": "color(display-p3 0.457 0.696 0.829)",
    "variable": "var(--colors-sky-light-p3-8)"
  },
  "colors.sky.light.p3.9": {
    "value": "color(display-p3 0.585 0.877 0.983)",
    "variable": "var(--colors-sky-light-p3-9)"
  },
  "colors.sky.light.p3.10": {
    "value": "color(display-p3 0.555 0.845 0.959)",
    "variable": "var(--colors-sky-light-p3-10)"
  },
  "colors.sky.light.p3.11": {
    "value": "color(display-p3 0.193 0.448 0.605)",
    "variable": "var(--colors-sky-light-p3-11)"
  },
  "colors.sky.light.p3.12": {
    "value": "color(display-p3 0.145 0.241 0.329)",
    "variable": "var(--colors-sky-light-p3-12)"
  },
  "colors.sky.light.p3.a.1": {
    "value": "color(display-p3 0.02 0.804 1 / 0.02)",
    "variable": "var(--colors-sky-light-p3-a-1)"
  },
  "colors.sky.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.592 0.757 / 0.048)",
    "variable": "var(--colors-sky-light-p3-a-2)"
  },
  "colors.sky.light.p3.a.3": {
    "value": "color(display-p3 0.004 0.655 0.886 / 0.102)",
    "variable": "var(--colors-sky-light-p3-a-3)"
  },
  "colors.sky.light.p3.a.4": {
    "value": "color(display-p3 0.004 0.604 0.851 / 0.157)",
    "variable": "var(--colors-sky-light-p3-a-4)"
  },
  "colors.sky.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.565 0.792 / 0.224)",
    "variable": "var(--colors-sky-light-p3-a-5)"
  },
  "colors.sky.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.502 0.737 / 0.299)",
    "variable": "var(--colors-sky-light-p3-a-6)"
  },
  "colors.sky.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.459 0.694 / 0.397)",
    "variable": "var(--colors-sky-light-p3-a-7)"
  },
  "colors.sky.light.p3.a.8": {
    "value": "color(display-p3 0 0.435 0.682 / 0.542)",
    "variable": "var(--colors-sky-light-p3-a-8)"
  },
  "colors.sky.light.p3.a.9": {
    "value": "color(display-p3 0.004 0.71 0.965 / 0.416)",
    "variable": "var(--colors-sky-light-p3-a-9)"
  },
  "colors.sky.light.p3.a.10": {
    "value": "color(display-p3 0.004 0.647 0.914 / 0.444)",
    "variable": "var(--colors-sky-light-p3-a-10)"
  },
  "colors.sky.light.p3.a.11": {
    "value": "color(display-p3 0.193 0.448 0.605)",
    "variable": "var(--colors-sky-light-p3-a-11)"
  },
  "colors.sky.light.p3.a.12": {
    "value": "color(display-p3 0.145 0.241 0.329)",
    "variable": "var(--colors-sky-light-p3-a-12)"
  },
  "colors.sky.dark.1": {
    "value": "var(--colors-sky-dark-1)",
    "variable": "var(--colors-sky-dark-1)"
  },
  "colors.sky.dark.2": {
    "value": "var(--colors-sky-dark-2)",
    "variable": "var(--colors-sky-dark-2)"
  },
  "colors.sky.dark.3": {
    "value": "var(--colors-sky-dark-3)",
    "variable": "var(--colors-sky-dark-3)"
  },
  "colors.sky.dark.4": {
    "value": "var(--colors-sky-dark-4)",
    "variable": "var(--colors-sky-dark-4)"
  },
  "colors.sky.dark.5": {
    "value": "var(--colors-sky-dark-5)",
    "variable": "var(--colors-sky-dark-5)"
  },
  "colors.sky.dark.6": {
    "value": "var(--colors-sky-dark-6)",
    "variable": "var(--colors-sky-dark-6)"
  },
  "colors.sky.dark.7": {
    "value": "var(--colors-sky-dark-7)",
    "variable": "var(--colors-sky-dark-7)"
  },
  "colors.sky.dark.8": {
    "value": "var(--colors-sky-dark-8)",
    "variable": "var(--colors-sky-dark-8)"
  },
  "colors.sky.dark.9": {
    "value": "var(--colors-sky-dark-9)",
    "variable": "var(--colors-sky-dark-9)"
  },
  "colors.sky.dark.10": {
    "value": "var(--colors-sky-dark-10)",
    "variable": "var(--colors-sky-dark-10)"
  },
  "colors.sky.dark.11": {
    "value": "var(--colors-sky-dark-11)",
    "variable": "var(--colors-sky-dark-11)"
  },
  "colors.sky.dark.12": {
    "value": "var(--colors-sky-dark-12)",
    "variable": "var(--colors-sky-dark-12)"
  },
  "colors.sky.dark.a.1": {
    "value": "var(--colors-sky-dark-a-1)",
    "variable": "var(--colors-sky-dark-a-1)"
  },
  "colors.sky.dark.a.2": {
    "value": "var(--colors-sky-dark-a-2)",
    "variable": "var(--colors-sky-dark-a-2)"
  },
  "colors.sky.dark.a.3": {
    "value": "var(--colors-sky-dark-a-3)",
    "variable": "var(--colors-sky-dark-a-3)"
  },
  "colors.sky.dark.a.4": {
    "value": "var(--colors-sky-dark-a-4)",
    "variable": "var(--colors-sky-dark-a-4)"
  },
  "colors.sky.dark.a.5": {
    "value": "var(--colors-sky-dark-a-5)",
    "variable": "var(--colors-sky-dark-a-5)"
  },
  "colors.sky.dark.a.6": {
    "value": "var(--colors-sky-dark-a-6)",
    "variable": "var(--colors-sky-dark-a-6)"
  },
  "colors.sky.dark.a.7": {
    "value": "var(--colors-sky-dark-a-7)",
    "variable": "var(--colors-sky-dark-a-7)"
  },
  "colors.sky.dark.a.8": {
    "value": "var(--colors-sky-dark-a-8)",
    "variable": "var(--colors-sky-dark-a-8)"
  },
  "colors.sky.dark.a.9": {
    "value": "var(--colors-sky-dark-a-9)",
    "variable": "var(--colors-sky-dark-a-9)"
  },
  "colors.sky.dark.a.10": {
    "value": "var(--colors-sky-dark-a-10)",
    "variable": "var(--colors-sky-dark-a-10)"
  },
  "colors.sky.dark.a.11": {
    "value": "var(--colors-sky-dark-a-11)",
    "variable": "var(--colors-sky-dark-a-11)"
  },
  "colors.sky.dark.a.12": {
    "value": "var(--colors-sky-dark-a-12)",
    "variable": "var(--colors-sky-dark-a-12)"
  },
  "colors.sky.dark.p3.1": {
    "value": "color(display-p3 0.056 0.078 0.116)",
    "variable": "var(--colors-sky-dark-p3-1)"
  },
  "colors.sky.dark.p3.2": {
    "value": "color(display-p3 0.075 0.101 0.149)",
    "variable": "var(--colors-sky-dark-p3-2)"
  },
  "colors.sky.dark.p3.3": {
    "value": "color(display-p3 0.089 0.154 0.244)",
    "variable": "var(--colors-sky-dark-p3-3)"
  },
  "colors.sky.dark.p3.4": {
    "value": "color(display-p3 0.106 0.207 0.323)",
    "variable": "var(--colors-sky-dark-p3-4)"
  },
  "colors.sky.dark.p3.5": {
    "value": "color(display-p3 0.135 0.261 0.394)",
    "variable": "var(--colors-sky-dark-p3-5)"
  },
  "colors.sky.dark.p3.6": {
    "value": "color(display-p3 0.17 0.322 0.469)",
    "variable": "var(--colors-sky-dark-p3-6)"
  },
  "colors.sky.dark.p3.7": {
    "value": "color(display-p3 0.205 0.394 0.557)",
    "variable": "var(--colors-sky-dark-p3-7)"
  },
  "colors.sky.dark.p3.8": {
    "value": "color(display-p3 0.232 0.48 0.665)",
    "variable": "var(--colors-sky-dark-p3-8)"
  },
  "colors.sky.dark.p3.9": {
    "value": "color(display-p3 0.585 0.877 0.983)",
    "variable": "var(--colors-sky-dark-p3-9)"
  },
  "colors.sky.dark.p3.10": {
    "value": "color(display-p3 0.718 0.925 0.991)",
    "variable": "var(--colors-sky-dark-p3-10)"
  },
  "colors.sky.dark.p3.11": {
    "value": "color(display-p3 0.536 0.772 0.924)",
    "variable": "var(--colors-sky-dark-p3-11)"
  },
  "colors.sky.dark.p3.12": {
    "value": "color(display-p3 0.799 0.947 0.993)",
    "variable": "var(--colors-sky-dark-p3-12)"
  },
  "colors.sky.dark.p3.a.1": {
    "value": "color(display-p3 0 0.282 0.996 / 0.055)",
    "variable": "var(--colors-sky-dark-p3-a-1)"
  },
  "colors.sky.dark.p3.a.2": {
    "value": "color(display-p3 0.157 0.467 0.992 / 0.089)",
    "variable": "var(--colors-sky-dark-p3-a-2)"
  },
  "colors.sky.dark.p3.a.3": {
    "value": "color(display-p3 0.192 0.522 0.996 / 0.19)",
    "variable": "var(--colors-sky-dark-p3-a-3)"
  },
  "colors.sky.dark.p3.a.4": {
    "value": "color(display-p3 0.212 0.584 1 / 0.274)",
    "variable": "var(--colors-sky-dark-p3-a-4)"
  },
  "colors.sky.dark.p3.a.5": {
    "value": "color(display-p3 0.259 0.631 1 / 0.349)",
    "variable": "var(--colors-sky-dark-p3-a-5)"
  },
  "colors.sky.dark.p3.a.6": {
    "value": "color(display-p3 0.302 0.655 1 / 0.433)",
    "variable": "var(--colors-sky-dark-p3-a-6)"
  },
  "colors.sky.dark.p3.a.7": {
    "value": "color(display-p3 0.329 0.686 1 / 0.526)",
    "variable": "var(--colors-sky-dark-p3-a-7)"
  },
  "colors.sky.dark.p3.a.8": {
    "value": "color(display-p3 0.325 0.71 1 / 0.643)",
    "variable": "var(--colors-sky-dark-p3-a-8)"
  },
  "colors.sky.dark.p3.a.9": {
    "value": "color(display-p3 0.592 0.894 1 / 0.984)",
    "variable": "var(--colors-sky-dark-p3-a-9)"
  },
  "colors.sky.dark.p3.a.10": {
    "value": "color(display-p3 0.722 0.933 1 / 0.992)",
    "variable": "var(--colors-sky-dark-p3-a-10)"
  },
  "colors.sky.dark.p3.a.11": {
    "value": "color(display-p3 0.536 0.772 0.924)",
    "variable": "var(--colors-sky-dark-p3-a-11)"
  },
  "colors.sky.dark.p3.a.12": {
    "value": "color(display-p3 0.799 0.947 0.993)",
    "variable": "var(--colors-sky-dark-p3-a-12)"
  },
  "colors.sky.a.1": {
    "value": "var(--colors-sky-a-1)",
    "variable": "var(--colors-sky-a-1)"
  },
  "colors.sky.a.2": {
    "value": "var(--colors-sky-a-2)",
    "variable": "var(--colors-sky-a-2)"
  },
  "colors.sky.a.3": {
    "value": "var(--colors-sky-a-3)",
    "variable": "var(--colors-sky-a-3)"
  },
  "colors.sky.a.4": {
    "value": "var(--colors-sky-a-4)",
    "variable": "var(--colors-sky-a-4)"
  },
  "colors.sky.a.5": {
    "value": "var(--colors-sky-a-5)",
    "variable": "var(--colors-sky-a-5)"
  },
  "colors.sky.a.6": {
    "value": "var(--colors-sky-a-6)",
    "variable": "var(--colors-sky-a-6)"
  },
  "colors.sky.a.7": {
    "value": "var(--colors-sky-a-7)",
    "variable": "var(--colors-sky-a-7)"
  },
  "colors.sky.a.8": {
    "value": "var(--colors-sky-a-8)",
    "variable": "var(--colors-sky-a-8)"
  },
  "colors.sky.a.9": {
    "value": "var(--colors-sky-a-9)",
    "variable": "var(--colors-sky-a-9)"
  },
  "colors.sky.a.10": {
    "value": "var(--colors-sky-a-10)",
    "variable": "var(--colors-sky-a-10)"
  },
  "colors.sky.a.11": {
    "value": "var(--colors-sky-a-11)",
    "variable": "var(--colors-sky-a-11)"
  },
  "colors.sky.a.12": {
    "value": "var(--colors-sky-a-12)",
    "variable": "var(--colors-sky-a-12)"
  },
  "colors.sky.p3.1": {
    "value": "var(--colors-sky-p3-1)",
    "variable": "var(--colors-sky-p3-1)"
  },
  "colors.sky.p3.2": {
    "value": "var(--colors-sky-p3-2)",
    "variable": "var(--colors-sky-p3-2)"
  },
  "colors.sky.p3.3": {
    "value": "var(--colors-sky-p3-3)",
    "variable": "var(--colors-sky-p3-3)"
  },
  "colors.sky.p3.4": {
    "value": "var(--colors-sky-p3-4)",
    "variable": "var(--colors-sky-p3-4)"
  },
  "colors.sky.p3.5": {
    "value": "var(--colors-sky-p3-5)",
    "variable": "var(--colors-sky-p3-5)"
  },
  "colors.sky.p3.6": {
    "value": "var(--colors-sky-p3-6)",
    "variable": "var(--colors-sky-p3-6)"
  },
  "colors.sky.p3.7": {
    "value": "var(--colors-sky-p3-7)",
    "variable": "var(--colors-sky-p3-7)"
  },
  "colors.sky.p3.8": {
    "value": "var(--colors-sky-p3-8)",
    "variable": "var(--colors-sky-p3-8)"
  },
  "colors.sky.p3.9": {
    "value": "var(--colors-sky-p3-9)",
    "variable": "var(--colors-sky-p3-9)"
  },
  "colors.sky.p3.10": {
    "value": "var(--colors-sky-p3-10)",
    "variable": "var(--colors-sky-p3-10)"
  },
  "colors.sky.p3.11": {
    "value": "var(--colors-sky-p3-11)",
    "variable": "var(--colors-sky-p3-11)"
  },
  "colors.sky.p3.12": {
    "value": "var(--colors-sky-p3-12)",
    "variable": "var(--colors-sky-p3-12)"
  },
  "colors.sky.p3.a.1": {
    "value": "var(--colors-sky-p3-a-1)",
    "variable": "var(--colors-sky-p3-a-1)"
  },
  "colors.sky.p3.a.2": {
    "value": "var(--colors-sky-p3-a-2)",
    "variable": "var(--colors-sky-p3-a-2)"
  },
  "colors.sky.p3.a.3": {
    "value": "var(--colors-sky-p3-a-3)",
    "variable": "var(--colors-sky-p3-a-3)"
  },
  "colors.sky.p3.a.4": {
    "value": "var(--colors-sky-p3-a-4)",
    "variable": "var(--colors-sky-p3-a-4)"
  },
  "colors.sky.p3.a.5": {
    "value": "var(--colors-sky-p3-a-5)",
    "variable": "var(--colors-sky-p3-a-5)"
  },
  "colors.sky.p3.a.6": {
    "value": "var(--colors-sky-p3-a-6)",
    "variable": "var(--colors-sky-p3-a-6)"
  },
  "colors.sky.p3.a.7": {
    "value": "var(--colors-sky-p3-a-7)",
    "variable": "var(--colors-sky-p3-a-7)"
  },
  "colors.sky.p3.a.8": {
    "value": "var(--colors-sky-p3-a-8)",
    "variable": "var(--colors-sky-p3-a-8)"
  },
  "colors.sky.p3.a.9": {
    "value": "var(--colors-sky-p3-a-9)",
    "variable": "var(--colors-sky-p3-a-9)"
  },
  "colors.sky.p3.a.10": {
    "value": "var(--colors-sky-p3-a-10)",
    "variable": "var(--colors-sky-p3-a-10)"
  },
  "colors.sky.p3.a.11": {
    "value": "var(--colors-sky-p3-a-11)",
    "variable": "var(--colors-sky-p3-a-11)"
  },
  "colors.sky.p3.a.12": {
    "value": "var(--colors-sky-p3-a-12)",
    "variable": "var(--colors-sky-p3-a-12)"
  },
  "colors.slate.1": {
    "value": "var(--colors-slate-1)",
    "variable": "var(--colors-slate-1)"
  },
  "colors.slate.2": {
    "value": "var(--colors-slate-2)",
    "variable": "var(--colors-slate-2)"
  },
  "colors.slate.3": {
    "value": "var(--colors-slate-3)",
    "variable": "var(--colors-slate-3)"
  },
  "colors.slate.4": {
    "value": "var(--colors-slate-4)",
    "variable": "var(--colors-slate-4)"
  },
  "colors.slate.5": {
    "value": "var(--colors-slate-5)",
    "variable": "var(--colors-slate-5)"
  },
  "colors.slate.6": {
    "value": "var(--colors-slate-6)",
    "variable": "var(--colors-slate-6)"
  },
  "colors.slate.7": {
    "value": "var(--colors-slate-7)",
    "variable": "var(--colors-slate-7)"
  },
  "colors.slate.8": {
    "value": "var(--colors-slate-8)",
    "variable": "var(--colors-slate-8)"
  },
  "colors.slate.9": {
    "value": "var(--colors-slate-9)",
    "variable": "var(--colors-slate-9)"
  },
  "colors.slate.10": {
    "value": "var(--colors-slate-10)",
    "variable": "var(--colors-slate-10)"
  },
  "colors.slate.11": {
    "value": "var(--colors-slate-11)",
    "variable": "var(--colors-slate-11)"
  },
  "colors.slate.12": {
    "value": "var(--colors-slate-12)",
    "variable": "var(--colors-slate-12)"
  },
  "colors.slate.light.1": {
    "value": "var(--colors-slate-light-1)",
    "variable": "var(--colors-slate-light-1)"
  },
  "colors.slate.light.2": {
    "value": "var(--colors-slate-light-2)",
    "variable": "var(--colors-slate-light-2)"
  },
  "colors.slate.light.3": {
    "value": "var(--colors-slate-light-3)",
    "variable": "var(--colors-slate-light-3)"
  },
  "colors.slate.light.4": {
    "value": "var(--colors-slate-light-4)",
    "variable": "var(--colors-slate-light-4)"
  },
  "colors.slate.light.5": {
    "value": "var(--colors-slate-light-5)",
    "variable": "var(--colors-slate-light-5)"
  },
  "colors.slate.light.6": {
    "value": "var(--colors-slate-light-6)",
    "variable": "var(--colors-slate-light-6)"
  },
  "colors.slate.light.7": {
    "value": "var(--colors-slate-light-7)",
    "variable": "var(--colors-slate-light-7)"
  },
  "colors.slate.light.8": {
    "value": "var(--colors-slate-light-8)",
    "variable": "var(--colors-slate-light-8)"
  },
  "colors.slate.light.9": {
    "value": "var(--colors-slate-light-9)",
    "variable": "var(--colors-slate-light-9)"
  },
  "colors.slate.light.10": {
    "value": "var(--colors-slate-light-10)",
    "variable": "var(--colors-slate-light-10)"
  },
  "colors.slate.light.11": {
    "value": "var(--colors-slate-light-11)",
    "variable": "var(--colors-slate-light-11)"
  },
  "colors.slate.light.12": {
    "value": "var(--colors-slate-light-12)",
    "variable": "var(--colors-slate-light-12)"
  },
  "colors.slate.light.a.1": {
    "value": "var(--colors-slate-light-a-1)",
    "variable": "var(--colors-slate-light-a-1)"
  },
  "colors.slate.light.a.2": {
    "value": "var(--colors-slate-light-a-2)",
    "variable": "var(--colors-slate-light-a-2)"
  },
  "colors.slate.light.a.3": {
    "value": "var(--colors-slate-light-a-3)",
    "variable": "var(--colors-slate-light-a-3)"
  },
  "colors.slate.light.a.4": {
    "value": "var(--colors-slate-light-a-4)",
    "variable": "var(--colors-slate-light-a-4)"
  },
  "colors.slate.light.a.5": {
    "value": "var(--colors-slate-light-a-5)",
    "variable": "var(--colors-slate-light-a-5)"
  },
  "colors.slate.light.a.6": {
    "value": "var(--colors-slate-light-a-6)",
    "variable": "var(--colors-slate-light-a-6)"
  },
  "colors.slate.light.a.7": {
    "value": "var(--colors-slate-light-a-7)",
    "variable": "var(--colors-slate-light-a-7)"
  },
  "colors.slate.light.a.8": {
    "value": "var(--colors-slate-light-a-8)",
    "variable": "var(--colors-slate-light-a-8)"
  },
  "colors.slate.light.a.9": {
    "value": "var(--colors-slate-light-a-9)",
    "variable": "var(--colors-slate-light-a-9)"
  },
  "colors.slate.light.a.10": {
    "value": "var(--colors-slate-light-a-10)",
    "variable": "var(--colors-slate-light-a-10)"
  },
  "colors.slate.light.a.11": {
    "value": "var(--colors-slate-light-a-11)",
    "variable": "var(--colors-slate-light-a-11)"
  },
  "colors.slate.light.a.12": {
    "value": "var(--colors-slate-light-a-12)",
    "variable": "var(--colors-slate-light-a-12)"
  },
  "colors.slate.light.p3.1": {
    "value": "color(display-p3 0.988 0.988 0.992)",
    "variable": "var(--colors-slate-light-p3-1)"
  },
  "colors.slate.light.p3.2": {
    "value": "color(display-p3 0.976 0.976 0.984)",
    "variable": "var(--colors-slate-light-p3-2)"
  },
  "colors.slate.light.p3.3": {
    "value": "color(display-p3 0.94 0.941 0.953)",
    "variable": "var(--colors-slate-light-p3-3)"
  },
  "colors.slate.light.p3.4": {
    "value": "color(display-p3 0.908 0.909 0.925)",
    "variable": "var(--colors-slate-light-p3-4)"
  },
  "colors.slate.light.p3.5": {
    "value": "color(display-p3 0.88 0.881 0.901)",
    "variable": "var(--colors-slate-light-p3-5)"
  },
  "colors.slate.light.p3.6": {
    "value": "color(display-p3 0.85 0.852 0.876)",
    "variable": "var(--colors-slate-light-p3-6)"
  },
  "colors.slate.light.p3.7": {
    "value": "color(display-p3 0.805 0.808 0.838)",
    "variable": "var(--colors-slate-light-p3-7)"
  },
  "colors.slate.light.p3.8": {
    "value": "color(display-p3 0.727 0.733 0.773)",
    "variable": "var(--colors-slate-light-p3-8)"
  },
  "colors.slate.light.p3.9": {
    "value": "color(display-p3 0.547 0.553 0.592)",
    "variable": "var(--colors-slate-light-p3-9)"
  },
  "colors.slate.light.p3.10": {
    "value": "color(display-p3 0.503 0.512 0.549)",
    "variable": "var(--colors-slate-light-p3-10)"
  },
  "colors.slate.light.p3.11": {
    "value": "color(display-p3 0.379 0.392 0.421)",
    "variable": "var(--colors-slate-light-p3-11)"
  },
  "colors.slate.light.p3.12": {
    "value": "color(display-p3 0.113 0.125 0.14)",
    "variable": "var(--colors-slate-light-p3-12)"
  },
  "colors.slate.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.024 0.349 / 0.012)",
    "variable": "var(--colors-slate-light-p3-a-1)"
  },
  "colors.slate.light.p3.a.2": {
    "value": "color(display-p3 0.024 0.024 0.349 / 0.024)",
    "variable": "var(--colors-slate-light-p3-a-2)"
  },
  "colors.slate.light.p3.a.3": {
    "value": "color(display-p3 0.004 0.004 0.204 / 0.059)",
    "variable": "var(--colors-slate-light-p3-a-3)"
  },
  "colors.slate.light.p3.a.4": {
    "value": "color(display-p3 0.012 0.012 0.184 / 0.091)",
    "variable": "var(--colors-slate-light-p3-a-4)"
  },
  "colors.slate.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.039 0.2 / 0.122)",
    "variable": "var(--colors-slate-light-p3-a-5)"
  },
  "colors.slate.light.p3.a.6": {
    "value": "color(display-p3 0.008 0.008 0.165 / 0.15)",
    "variable": "var(--colors-slate-light-p3-a-6)"
  },
  "colors.slate.light.p3.a.7": {
    "value": "color(display-p3 0.008 0.027 0.184 / 0.197)",
    "variable": "var(--colors-slate-light-p3-a-7)"
  },
  "colors.slate.light.p3.a.8": {
    "value": "color(display-p3 0.004 0.031 0.176 / 0.275)",
    "variable": "var(--colors-slate-light-p3-a-8)"
  },
  "colors.slate.light.p3.a.9": {
    "value": "color(display-p3 0.004 0.02 0.106 / 0.455)",
    "variable": "var(--colors-slate-light-p3-a-9)"
  },
  "colors.slate.light.p3.a.10": {
    "value": "color(display-p3 0.004 0.027 0.098 / 0.499)",
    "variable": "var(--colors-slate-light-p3-a-10)"
  },
  "colors.slate.light.p3.a.11": {
    "value": "color(display-p3 0 0.02 0.063 / 0.62)",
    "variable": "var(--colors-slate-light-p3-a-11)"
  },
  "colors.slate.light.p3.a.12": {
    "value": "color(display-p3 0 0.012 0.031 / 0.887)",
    "variable": "var(--colors-slate-light-p3-a-12)"
  },
  "colors.slate.dark.1": {
    "value": "var(--colors-slate-dark-1)",
    "variable": "var(--colors-slate-dark-1)"
  },
  "colors.slate.dark.2": {
    "value": "var(--colors-slate-dark-2)",
    "variable": "var(--colors-slate-dark-2)"
  },
  "colors.slate.dark.3": {
    "value": "var(--colors-slate-dark-3)",
    "variable": "var(--colors-slate-dark-3)"
  },
  "colors.slate.dark.4": {
    "value": "var(--colors-slate-dark-4)",
    "variable": "var(--colors-slate-dark-4)"
  },
  "colors.slate.dark.5": {
    "value": "var(--colors-slate-dark-5)",
    "variable": "var(--colors-slate-dark-5)"
  },
  "colors.slate.dark.6": {
    "value": "var(--colors-slate-dark-6)",
    "variable": "var(--colors-slate-dark-6)"
  },
  "colors.slate.dark.7": {
    "value": "var(--colors-slate-dark-7)",
    "variable": "var(--colors-slate-dark-7)"
  },
  "colors.slate.dark.8": {
    "value": "var(--colors-slate-dark-8)",
    "variable": "var(--colors-slate-dark-8)"
  },
  "colors.slate.dark.9": {
    "value": "var(--colors-slate-dark-9)",
    "variable": "var(--colors-slate-dark-9)"
  },
  "colors.slate.dark.10": {
    "value": "var(--colors-slate-dark-10)",
    "variable": "var(--colors-slate-dark-10)"
  },
  "colors.slate.dark.11": {
    "value": "var(--colors-slate-dark-11)",
    "variable": "var(--colors-slate-dark-11)"
  },
  "colors.slate.dark.12": {
    "value": "var(--colors-slate-dark-12)",
    "variable": "var(--colors-slate-dark-12)"
  },
  "colors.slate.dark.a.1": {
    "value": "var(--colors-slate-dark-a-1)",
    "variable": "var(--colors-slate-dark-a-1)"
  },
  "colors.slate.dark.a.2": {
    "value": "var(--colors-slate-dark-a-2)",
    "variable": "var(--colors-slate-dark-a-2)"
  },
  "colors.slate.dark.a.3": {
    "value": "var(--colors-slate-dark-a-3)",
    "variable": "var(--colors-slate-dark-a-3)"
  },
  "colors.slate.dark.a.4": {
    "value": "var(--colors-slate-dark-a-4)",
    "variable": "var(--colors-slate-dark-a-4)"
  },
  "colors.slate.dark.a.5": {
    "value": "var(--colors-slate-dark-a-5)",
    "variable": "var(--colors-slate-dark-a-5)"
  },
  "colors.slate.dark.a.6": {
    "value": "var(--colors-slate-dark-a-6)",
    "variable": "var(--colors-slate-dark-a-6)"
  },
  "colors.slate.dark.a.7": {
    "value": "var(--colors-slate-dark-a-7)",
    "variable": "var(--colors-slate-dark-a-7)"
  },
  "colors.slate.dark.a.8": {
    "value": "var(--colors-slate-dark-a-8)",
    "variable": "var(--colors-slate-dark-a-8)"
  },
  "colors.slate.dark.a.9": {
    "value": "var(--colors-slate-dark-a-9)",
    "variable": "var(--colors-slate-dark-a-9)"
  },
  "colors.slate.dark.a.10": {
    "value": "var(--colors-slate-dark-a-10)",
    "variable": "var(--colors-slate-dark-a-10)"
  },
  "colors.slate.dark.a.11": {
    "value": "var(--colors-slate-dark-a-11)",
    "variable": "var(--colors-slate-dark-a-11)"
  },
  "colors.slate.dark.a.12": {
    "value": "var(--colors-slate-dark-a-12)",
    "variable": "var(--colors-slate-dark-a-12)"
  },
  "colors.slate.dark.p3.1": {
    "value": "color(display-p3 0.067 0.067 0.074)",
    "variable": "var(--colors-slate-dark-p3-1)"
  },
  "colors.slate.dark.p3.2": {
    "value": "color(display-p3 0.095 0.098 0.105)",
    "variable": "var(--colors-slate-dark-p3-2)"
  },
  "colors.slate.dark.p3.3": {
    "value": "color(display-p3 0.13 0.135 0.145)",
    "variable": "var(--colors-slate-dark-p3-3)"
  },
  "colors.slate.dark.p3.4": {
    "value": "color(display-p3 0.156 0.163 0.176)",
    "variable": "var(--colors-slate-dark-p3-4)"
  },
  "colors.slate.dark.p3.5": {
    "value": "color(display-p3 0.183 0.191 0.206)",
    "variable": "var(--colors-slate-dark-p3-5)"
  },
  "colors.slate.dark.p3.6": {
    "value": "color(display-p3 0.215 0.226 0.244)",
    "variable": "var(--colors-slate-dark-p3-6)"
  },
  "colors.slate.dark.p3.7": {
    "value": "color(display-p3 0.265 0.28 0.302)",
    "variable": "var(--colors-slate-dark-p3-7)"
  },
  "colors.slate.dark.p3.8": {
    "value": "color(display-p3 0.357 0.381 0.409)",
    "variable": "var(--colors-slate-dark-p3-8)"
  },
  "colors.slate.dark.p3.9": {
    "value": "color(display-p3 0.415 0.431 0.463)",
    "variable": "var(--colors-slate-dark-p3-9)"
  },
  "colors.slate.dark.p3.10": {
    "value": "color(display-p3 0.469 0.483 0.514)",
    "variable": "var(--colors-slate-dark-p3-10)"
  },
  "colors.slate.dark.p3.11": {
    "value": "color(display-p3 0.692 0.704 0.728)",
    "variable": "var(--colors-slate-dark-p3-11)"
  },
  "colors.slate.dark.p3.12": {
    "value": "color(display-p3 0.93 0.933 0.94)",
    "variable": "var(--colors-slate-dark-p3-12)"
  },
  "colors.slate.dark.p3.a.1": {
    "value": "color(display-p3 0 0 0 / 0)",
    "variable": "var(--colors-slate-dark-p3-a-1)"
  },
  "colors.slate.dark.p3.a.2": {
    "value": "color(display-p3 0.875 0.992 1 / 0.034)",
    "variable": "var(--colors-slate-dark-p3-a-2)"
  },
  "colors.slate.dark.p3.a.3": {
    "value": "color(display-p3 0.882 0.933 0.992 / 0.077)",
    "variable": "var(--colors-slate-dark-p3-a-3)"
  },
  "colors.slate.dark.p3.a.4": {
    "value": "color(display-p3 0.882 0.953 0.996 / 0.111)",
    "variable": "var(--colors-slate-dark-p3-a-4)"
  },
  "colors.slate.dark.p3.a.5": {
    "value": "color(display-p3 0.878 0.929 0.996 / 0.145)",
    "variable": "var(--colors-slate-dark-p3-a-5)"
  },
  "colors.slate.dark.p3.a.6": {
    "value": "color(display-p3 0.882 0.949 0.996 / 0.183)",
    "variable": "var(--colors-slate-dark-p3-a-6)"
  },
  "colors.slate.dark.p3.a.7": {
    "value": "color(display-p3 0.882 0.929 1 / 0.246)",
    "variable": "var(--colors-slate-dark-p3-a-7)"
  },
  "colors.slate.dark.p3.a.8": {
    "value": "color(display-p3 0.871 0.937 1 / 0.361)",
    "variable": "var(--colors-slate-dark-p3-a-8)"
  },
  "colors.slate.dark.p3.a.9": {
    "value": "color(display-p3 0.898 0.937 1 / 0.42)",
    "variable": "var(--colors-slate-dark-p3-a-9)"
  },
  "colors.slate.dark.p3.a.10": {
    "value": "color(display-p3 0.918 0.945 1 / 0.475)",
    "variable": "var(--colors-slate-dark-p3-a-10)"
  },
  "colors.slate.dark.p3.a.11": {
    "value": "color(display-p3 0.949 0.969 0.996 / 0.708)",
    "variable": "var(--colors-slate-dark-p3-a-11)"
  },
  "colors.slate.dark.p3.a.12": {
    "value": "color(display-p3 0.988 0.992 1 / 0.937)",
    "variable": "var(--colors-slate-dark-p3-a-12)"
  },
  "colors.slate.a.1": {
    "value": "var(--colors-slate-a-1)",
    "variable": "var(--colors-slate-a-1)"
  },
  "colors.slate.a.2": {
    "value": "var(--colors-slate-a-2)",
    "variable": "var(--colors-slate-a-2)"
  },
  "colors.slate.a.3": {
    "value": "var(--colors-slate-a-3)",
    "variable": "var(--colors-slate-a-3)"
  },
  "colors.slate.a.4": {
    "value": "var(--colors-slate-a-4)",
    "variable": "var(--colors-slate-a-4)"
  },
  "colors.slate.a.5": {
    "value": "var(--colors-slate-a-5)",
    "variable": "var(--colors-slate-a-5)"
  },
  "colors.slate.a.6": {
    "value": "var(--colors-slate-a-6)",
    "variable": "var(--colors-slate-a-6)"
  },
  "colors.slate.a.7": {
    "value": "var(--colors-slate-a-7)",
    "variable": "var(--colors-slate-a-7)"
  },
  "colors.slate.a.8": {
    "value": "var(--colors-slate-a-8)",
    "variable": "var(--colors-slate-a-8)"
  },
  "colors.slate.a.9": {
    "value": "var(--colors-slate-a-9)",
    "variable": "var(--colors-slate-a-9)"
  },
  "colors.slate.a.10": {
    "value": "var(--colors-slate-a-10)",
    "variable": "var(--colors-slate-a-10)"
  },
  "colors.slate.a.11": {
    "value": "var(--colors-slate-a-11)",
    "variable": "var(--colors-slate-a-11)"
  },
  "colors.slate.a.12": {
    "value": "var(--colors-slate-a-12)",
    "variable": "var(--colors-slate-a-12)"
  },
  "colors.slate.p3.1": {
    "value": "var(--colors-slate-p3-1)",
    "variable": "var(--colors-slate-p3-1)"
  },
  "colors.slate.p3.2": {
    "value": "var(--colors-slate-p3-2)",
    "variable": "var(--colors-slate-p3-2)"
  },
  "colors.slate.p3.3": {
    "value": "var(--colors-slate-p3-3)",
    "variable": "var(--colors-slate-p3-3)"
  },
  "colors.slate.p3.4": {
    "value": "var(--colors-slate-p3-4)",
    "variable": "var(--colors-slate-p3-4)"
  },
  "colors.slate.p3.5": {
    "value": "var(--colors-slate-p3-5)",
    "variable": "var(--colors-slate-p3-5)"
  },
  "colors.slate.p3.6": {
    "value": "var(--colors-slate-p3-6)",
    "variable": "var(--colors-slate-p3-6)"
  },
  "colors.slate.p3.7": {
    "value": "var(--colors-slate-p3-7)",
    "variable": "var(--colors-slate-p3-7)"
  },
  "colors.slate.p3.8": {
    "value": "var(--colors-slate-p3-8)",
    "variable": "var(--colors-slate-p3-8)"
  },
  "colors.slate.p3.9": {
    "value": "var(--colors-slate-p3-9)",
    "variable": "var(--colors-slate-p3-9)"
  },
  "colors.slate.p3.10": {
    "value": "var(--colors-slate-p3-10)",
    "variable": "var(--colors-slate-p3-10)"
  },
  "colors.slate.p3.11": {
    "value": "var(--colors-slate-p3-11)",
    "variable": "var(--colors-slate-p3-11)"
  },
  "colors.slate.p3.12": {
    "value": "var(--colors-slate-p3-12)",
    "variable": "var(--colors-slate-p3-12)"
  },
  "colors.slate.p3.a.1": {
    "value": "var(--colors-slate-p3-a-1)",
    "variable": "var(--colors-slate-p3-a-1)"
  },
  "colors.slate.p3.a.2": {
    "value": "var(--colors-slate-p3-a-2)",
    "variable": "var(--colors-slate-p3-a-2)"
  },
  "colors.slate.p3.a.3": {
    "value": "var(--colors-slate-p3-a-3)",
    "variable": "var(--colors-slate-p3-a-3)"
  },
  "colors.slate.p3.a.4": {
    "value": "var(--colors-slate-p3-a-4)",
    "variable": "var(--colors-slate-p3-a-4)"
  },
  "colors.slate.p3.a.5": {
    "value": "var(--colors-slate-p3-a-5)",
    "variable": "var(--colors-slate-p3-a-5)"
  },
  "colors.slate.p3.a.6": {
    "value": "var(--colors-slate-p3-a-6)",
    "variable": "var(--colors-slate-p3-a-6)"
  },
  "colors.slate.p3.a.7": {
    "value": "var(--colors-slate-p3-a-7)",
    "variable": "var(--colors-slate-p3-a-7)"
  },
  "colors.slate.p3.a.8": {
    "value": "var(--colors-slate-p3-a-8)",
    "variable": "var(--colors-slate-p3-a-8)"
  },
  "colors.slate.p3.a.9": {
    "value": "var(--colors-slate-p3-a-9)",
    "variable": "var(--colors-slate-p3-a-9)"
  },
  "colors.slate.p3.a.10": {
    "value": "var(--colors-slate-p3-a-10)",
    "variable": "var(--colors-slate-p3-a-10)"
  },
  "colors.slate.p3.a.11": {
    "value": "var(--colors-slate-p3-a-11)",
    "variable": "var(--colors-slate-p3-a-11)"
  },
  "colors.slate.p3.a.12": {
    "value": "var(--colors-slate-p3-a-12)",
    "variable": "var(--colors-slate-p3-a-12)"
  },
  "colors.teal.1": {
    "value": "var(--colors-teal-1)",
    "variable": "var(--colors-teal-1)"
  },
  "colors.teal.2": {
    "value": "var(--colors-teal-2)",
    "variable": "var(--colors-teal-2)"
  },
  "colors.teal.3": {
    "value": "var(--colors-teal-3)",
    "variable": "var(--colors-teal-3)"
  },
  "colors.teal.4": {
    "value": "var(--colors-teal-4)",
    "variable": "var(--colors-teal-4)"
  },
  "colors.teal.5": {
    "value": "var(--colors-teal-5)",
    "variable": "var(--colors-teal-5)"
  },
  "colors.teal.6": {
    "value": "var(--colors-teal-6)",
    "variable": "var(--colors-teal-6)"
  },
  "colors.teal.7": {
    "value": "var(--colors-teal-7)",
    "variable": "var(--colors-teal-7)"
  },
  "colors.teal.8": {
    "value": "var(--colors-teal-8)",
    "variable": "var(--colors-teal-8)"
  },
  "colors.teal.9": {
    "value": "var(--colors-teal-9)",
    "variable": "var(--colors-teal-9)"
  },
  "colors.teal.10": {
    "value": "var(--colors-teal-10)",
    "variable": "var(--colors-teal-10)"
  },
  "colors.teal.11": {
    "value": "var(--colors-teal-11)",
    "variable": "var(--colors-teal-11)"
  },
  "colors.teal.12": {
    "value": "var(--colors-teal-12)",
    "variable": "var(--colors-teal-12)"
  },
  "colors.teal.light.1": {
    "value": "var(--colors-teal-light-1)",
    "variable": "var(--colors-teal-light-1)"
  },
  "colors.teal.light.2": {
    "value": "var(--colors-teal-light-2)",
    "variable": "var(--colors-teal-light-2)"
  },
  "colors.teal.light.3": {
    "value": "var(--colors-teal-light-3)",
    "variable": "var(--colors-teal-light-3)"
  },
  "colors.teal.light.4": {
    "value": "var(--colors-teal-light-4)",
    "variable": "var(--colors-teal-light-4)"
  },
  "colors.teal.light.5": {
    "value": "var(--colors-teal-light-5)",
    "variable": "var(--colors-teal-light-5)"
  },
  "colors.teal.light.6": {
    "value": "var(--colors-teal-light-6)",
    "variable": "var(--colors-teal-light-6)"
  },
  "colors.teal.light.7": {
    "value": "var(--colors-teal-light-7)",
    "variable": "var(--colors-teal-light-7)"
  },
  "colors.teal.light.8": {
    "value": "var(--colors-teal-light-8)",
    "variable": "var(--colors-teal-light-8)"
  },
  "colors.teal.light.9": {
    "value": "var(--colors-teal-light-9)",
    "variable": "var(--colors-teal-light-9)"
  },
  "colors.teal.light.10": {
    "value": "var(--colors-teal-light-10)",
    "variable": "var(--colors-teal-light-10)"
  },
  "colors.teal.light.11": {
    "value": "var(--colors-teal-light-11)",
    "variable": "var(--colors-teal-light-11)"
  },
  "colors.teal.light.12": {
    "value": "var(--colors-teal-light-12)",
    "variable": "var(--colors-teal-light-12)"
  },
  "colors.teal.light.a.1": {
    "value": "var(--colors-teal-light-a-1)",
    "variable": "var(--colors-teal-light-a-1)"
  },
  "colors.teal.light.a.2": {
    "value": "var(--colors-teal-light-a-2)",
    "variable": "var(--colors-teal-light-a-2)"
  },
  "colors.teal.light.a.3": {
    "value": "var(--colors-teal-light-a-3)",
    "variable": "var(--colors-teal-light-a-3)"
  },
  "colors.teal.light.a.4": {
    "value": "var(--colors-teal-light-a-4)",
    "variable": "var(--colors-teal-light-a-4)"
  },
  "colors.teal.light.a.5": {
    "value": "var(--colors-teal-light-a-5)",
    "variable": "var(--colors-teal-light-a-5)"
  },
  "colors.teal.light.a.6": {
    "value": "var(--colors-teal-light-a-6)",
    "variable": "var(--colors-teal-light-a-6)"
  },
  "colors.teal.light.a.7": {
    "value": "var(--colors-teal-light-a-7)",
    "variable": "var(--colors-teal-light-a-7)"
  },
  "colors.teal.light.a.8": {
    "value": "var(--colors-teal-light-a-8)",
    "variable": "var(--colors-teal-light-a-8)"
  },
  "colors.teal.light.a.9": {
    "value": "var(--colors-teal-light-a-9)",
    "variable": "var(--colors-teal-light-a-9)"
  },
  "colors.teal.light.a.10": {
    "value": "var(--colors-teal-light-a-10)",
    "variable": "var(--colors-teal-light-a-10)"
  },
  "colors.teal.light.a.11": {
    "value": "var(--colors-teal-light-a-11)",
    "variable": "var(--colors-teal-light-a-11)"
  },
  "colors.teal.light.a.12": {
    "value": "var(--colors-teal-light-a-12)",
    "variable": "var(--colors-teal-light-a-12)"
  },
  "colors.teal.light.p3.1": {
    "value": "color(display-p3 0.983 0.996 0.992)",
    "variable": "var(--colors-teal-light-p3-1)"
  },
  "colors.teal.light.p3.2": {
    "value": "color(display-p3 0.958 0.983 0.976)",
    "variable": "var(--colors-teal-light-p3-2)"
  },
  "colors.teal.light.p3.3": {
    "value": "color(display-p3 0.895 0.971 0.952)",
    "variable": "var(--colors-teal-light-p3-3)"
  },
  "colors.teal.light.p3.4": {
    "value": "color(display-p3 0.831 0.949 0.92)",
    "variable": "var(--colors-teal-light-p3-4)"
  },
  "colors.teal.light.p3.5": {
    "value": "color(display-p3 0.761 0.914 0.878)",
    "variable": "var(--colors-teal-light-p3-5)"
  },
  "colors.teal.light.p3.6": {
    "value": "color(display-p3 0.682 0.864 0.825)",
    "variable": "var(--colors-teal-light-p3-6)"
  },
  "colors.teal.light.p3.7": {
    "value": "color(display-p3 0.581 0.798 0.756)",
    "variable": "var(--colors-teal-light-p3-7)"
  },
  "colors.teal.light.p3.8": {
    "value": "color(display-p3 0.433 0.716 0.671)",
    "variable": "var(--colors-teal-light-p3-8)"
  },
  "colors.teal.light.p3.9": {
    "value": "color(display-p3 0.297 0.637 0.581)",
    "variable": "var(--colors-teal-light-p3-9)"
  },
  "colors.teal.light.p3.10": {
    "value": "color(display-p3 0.275 0.599 0.542)",
    "variable": "var(--colors-teal-light-p3-10)"
  },
  "colors.teal.light.p3.11": {
    "value": "color(display-p3 0.08 0.5 0.43)",
    "variable": "var(--colors-teal-light-p3-11)"
  },
  "colors.teal.light.p3.12": {
    "value": "color(display-p3 0.11 0.235 0.219)",
    "variable": "var(--colors-teal-light-p3-12)"
  },
  "colors.teal.light.p3.a.1": {
    "value": "color(display-p3 0.024 0.757 0.514 / 0.016)",
    "variable": "var(--colors-teal-light-p3-a-1)"
  },
  "colors.teal.light.p3.a.2": {
    "value": "color(display-p3 0.02 0.647 0.467 / 0.044)",
    "variable": "var(--colors-teal-light-p3-a-2)"
  },
  "colors.teal.light.p3.a.3": {
    "value": "color(display-p3 0.004 0.741 0.557 / 0.106)",
    "variable": "var(--colors-teal-light-p3-a-3)"
  },
  "colors.teal.light.p3.a.4": {
    "value": "color(display-p3 0.004 0.702 0.537 / 0.169)",
    "variable": "var(--colors-teal-light-p3-a-4)"
  },
  "colors.teal.light.p3.a.5": {
    "value": "color(display-p3 0.004 0.643 0.494 / 0.24)",
    "variable": "var(--colors-teal-light-p3-a-5)"
  },
  "colors.teal.light.p3.a.6": {
    "value": "color(display-p3 0.004 0.569 0.447 / 0.318)",
    "variable": "var(--colors-teal-light-p3-a-6)"
  },
  "colors.teal.light.p3.a.7": {
    "value": "color(display-p3 0.004 0.518 0.424 / 0.42)",
    "variable": "var(--colors-teal-light-p3-a-7)"
  },
  "colors.teal.light.p3.a.8": {
    "value": "color(display-p3 0 0.506 0.424 / 0.569)",
    "variable": "var(--colors-teal-light-p3-a-8)"
  },
  "colors.teal.light.p3.a.9": {
    "value": "color(display-p3 0 0.482 0.404 / 0.702)",
    "variable": "var(--colors-teal-light-p3-a-9)"
  },
  "colors.teal.light.p3.a.10": {
    "value": "color(display-p3 0 0.451 0.369 / 0.726)",
    "variable": "var(--colors-teal-light-p3-a-10)"
  },
  "colors.teal.light.p3.a.11": {
    "value": "color(display-p3 0.08 0.5 0.43)",
    "variable": "var(--colors-teal-light-p3-a-11)"
  },
  "colors.teal.light.p3.a.12": {
    "value": "color(display-p3 0.11 0.235 0.219)",
    "variable": "var(--colors-teal-light-p3-a-12)"
  },
  "colors.teal.dark.1": {
    "value": "var(--colors-teal-dark-1)",
    "variable": "var(--colors-teal-dark-1)"
  },
  "colors.teal.dark.2": {
    "value": "var(--colors-teal-dark-2)",
    "variable": "var(--colors-teal-dark-2)"
  },
  "colors.teal.dark.3": {
    "value": "var(--colors-teal-dark-3)",
    "variable": "var(--colors-teal-dark-3)"
  },
  "colors.teal.dark.4": {
    "value": "var(--colors-teal-dark-4)",
    "variable": "var(--colors-teal-dark-4)"
  },
  "colors.teal.dark.5": {
    "value": "var(--colors-teal-dark-5)",
    "variable": "var(--colors-teal-dark-5)"
  },
  "colors.teal.dark.6": {
    "value": "var(--colors-teal-dark-6)",
    "variable": "var(--colors-teal-dark-6)"
  },
  "colors.teal.dark.7": {
    "value": "var(--colors-teal-dark-7)",
    "variable": "var(--colors-teal-dark-7)"
  },
  "colors.teal.dark.8": {
    "value": "var(--colors-teal-dark-8)",
    "variable": "var(--colors-teal-dark-8)"
  },
  "colors.teal.dark.9": {
    "value": "var(--colors-teal-dark-9)",
    "variable": "var(--colors-teal-dark-9)"
  },
  "colors.teal.dark.10": {
    "value": "var(--colors-teal-dark-10)",
    "variable": "var(--colors-teal-dark-10)"
  },
  "colors.teal.dark.11": {
    "value": "var(--colors-teal-dark-11)",
    "variable": "var(--colors-teal-dark-11)"
  },
  "colors.teal.dark.12": {
    "value": "var(--colors-teal-dark-12)",
    "variable": "var(--colors-teal-dark-12)"
  },
  "colors.teal.dark.a.1": {
    "value": "var(--colors-teal-dark-a-1)",
    "variable": "var(--colors-teal-dark-a-1)"
  },
  "colors.teal.dark.a.2": {
    "value": "var(--colors-teal-dark-a-2)",
    "variable": "var(--colors-teal-dark-a-2)"
  },
  "colors.teal.dark.a.3": {
    "value": "var(--colors-teal-dark-a-3)",
    "variable": "var(--colors-teal-dark-a-3)"
  },
  "colors.teal.dark.a.4": {
    "value": "var(--colors-teal-dark-a-4)",
    "variable": "var(--colors-teal-dark-a-4)"
  },
  "colors.teal.dark.a.5": {
    "value": "var(--colors-teal-dark-a-5)",
    "variable": "var(--colors-teal-dark-a-5)"
  },
  "colors.teal.dark.a.6": {
    "value": "var(--colors-teal-dark-a-6)",
    "variable": "var(--colors-teal-dark-a-6)"
  },
  "colors.teal.dark.a.7": {
    "value": "var(--colors-teal-dark-a-7)",
    "variable": "var(--colors-teal-dark-a-7)"
  },
  "colors.teal.dark.a.8": {
    "value": "var(--colors-teal-dark-a-8)",
    "variable": "var(--colors-teal-dark-a-8)"
  },
  "colors.teal.dark.a.9": {
    "value": "var(--colors-teal-dark-a-9)",
    "variable": "var(--colors-teal-dark-a-9)"
  },
  "colors.teal.dark.a.10": {
    "value": "var(--colors-teal-dark-a-10)",
    "variable": "var(--colors-teal-dark-a-10)"
  },
  "colors.teal.dark.a.11": {
    "value": "var(--colors-teal-dark-a-11)",
    "variable": "var(--colors-teal-dark-a-11)"
  },
  "colors.teal.dark.a.12": {
    "value": "var(--colors-teal-dark-a-12)",
    "variable": "var(--colors-teal-dark-a-12)"
  },
  "colors.teal.dark.p3.1": {
    "value": "color(display-p3 0.059 0.083 0.079)",
    "variable": "var(--colors-teal-dark-p3-1)"
  },
  "colors.teal.dark.p3.2": {
    "value": "color(display-p3 0.075 0.11 0.107)",
    "variable": "var(--colors-teal-dark-p3-2)"
  },
  "colors.teal.dark.p3.3": {
    "value": "color(display-p3 0.087 0.175 0.165)",
    "variable": "var(--colors-teal-dark-p3-3)"
  },
  "colors.teal.dark.p3.4": {
    "value": "color(display-p3 0.087 0.227 0.214)",
    "variable": "var(--colors-teal-dark-p3-4)"
  },
  "colors.teal.dark.p3.5": {
    "value": "color(display-p3 0.12 0.277 0.261)",
    "variable": "var(--colors-teal-dark-p3-5)"
  },
  "colors.teal.dark.p3.6": {
    "value": "color(display-p3 0.162 0.335 0.314)",
    "variable": "var(--colors-teal-dark-p3-6)"
  },
  "colors.teal.dark.p3.7": {
    "value": "color(display-p3 0.205 0.406 0.379)",
    "variable": "var(--colors-teal-dark-p3-7)"
  },
  "colors.teal.dark.p3.8": {
    "value": "color(display-p3 0.245 0.489 0.453)",
    "variable": "var(--colors-teal-dark-p3-8)"
  },
  "colors.teal.dark.p3.9": {
    "value": "color(display-p3 0.297 0.637 0.581)",
    "variable": "var(--colors-teal-dark-p3-9)"
  },
  "colors.teal.dark.p3.10": {
    "value": "color(display-p3 0.319 0.69 0.62)",
    "variable": "var(--colors-teal-dark-p3-10)"
  },
  "colors.teal.dark.p3.11": {
    "value": "color(display-p3 0.388 0.835 0.719)",
    "variable": "var(--colors-teal-dark-p3-11)"
  },
  "colors.teal.dark.p3.12": {
    "value": "color(display-p3 0.734 0.934 0.87)",
    "variable": "var(--colors-teal-dark-p3-12)"
  },
  "colors.teal.dark.p3.a.1": {
    "value": "color(display-p3 0 0.992 0.761 / 0.017)",
    "variable": "var(--colors-teal-dark-p3-a-1)"
  },
  "colors.teal.dark.p3.a.2": {
    "value": "color(display-p3 0.235 0.988 0.902 / 0.047)",
    "variable": "var(--colors-teal-dark-p3-a-2)"
  },
  "colors.teal.dark.p3.a.3": {
    "value": "color(display-p3 0.235 1 0.898 / 0.118)",
    "variable": "var(--colors-teal-dark-p3-a-3)"
  },
  "colors.teal.dark.p3.a.4": {
    "value": "color(display-p3 0.18 0.996 0.929 / 0.173)",
    "variable": "var(--colors-teal-dark-p3-a-4)"
  },
  "colors.teal.dark.p3.a.5": {
    "value": "color(display-p3 0.31 1 0.933 / 0.227)",
    "variable": "var(--colors-teal-dark-p3-a-5)"
  },
  "colors.teal.dark.p3.a.6": {
    "value": "color(display-p3 0.396 1 0.933 / 0.286)",
    "variable": "var(--colors-teal-dark-p3-a-6)"
  },
  "colors.teal.dark.p3.a.7": {
    "value": "color(display-p3 0.443 1 0.925 / 0.366)",
    "variable": "var(--colors-teal-dark-p3-a-7)"
  },
  "colors.teal.dark.p3.a.8": {
    "value": "color(display-p3 0.459 1 0.925 / 0.454)",
    "variable": "var(--colors-teal-dark-p3-a-8)"
  },
  "colors.teal.dark.p3.a.9": {
    "value": "color(display-p3 0.443 0.996 0.906 / 0.61)",
    "variable": "var(--colors-teal-dark-p3-a-9)"
  },
  "colors.teal.dark.p3.a.10": {
    "value": "color(display-p3 0.439 0.996 0.89 / 0.669)",
    "variable": "var(--colors-teal-dark-p3-a-10)"
  },
  "colors.teal.dark.p3.a.11": {
    "value": "color(display-p3 0.388 0.835 0.719)",
    "variable": "var(--colors-teal-dark-p3-a-11)"
  },
  "colors.teal.dark.p3.a.12": {
    "value": "color(display-p3 0.734 0.934 0.87)",
    "variable": "var(--colors-teal-dark-p3-a-12)"
  },
  "colors.teal.a.1": {
    "value": "var(--colors-teal-a-1)",
    "variable": "var(--colors-teal-a-1)"
  },
  "colors.teal.a.2": {
    "value": "var(--colors-teal-a-2)",
    "variable": "var(--colors-teal-a-2)"
  },
  "colors.teal.a.3": {
    "value": "var(--colors-teal-a-3)",
    "variable": "var(--colors-teal-a-3)"
  },
  "colors.teal.a.4": {
    "value": "var(--colors-teal-a-4)",
    "variable": "var(--colors-teal-a-4)"
  },
  "colors.teal.a.5": {
    "value": "var(--colors-teal-a-5)",
    "variable": "var(--colors-teal-a-5)"
  },
  "colors.teal.a.6": {
    "value": "var(--colors-teal-a-6)",
    "variable": "var(--colors-teal-a-6)"
  },
  "colors.teal.a.7": {
    "value": "var(--colors-teal-a-7)",
    "variable": "var(--colors-teal-a-7)"
  },
  "colors.teal.a.8": {
    "value": "var(--colors-teal-a-8)",
    "variable": "var(--colors-teal-a-8)"
  },
  "colors.teal.a.9": {
    "value": "var(--colors-teal-a-9)",
    "variable": "var(--colors-teal-a-9)"
  },
  "colors.teal.a.10": {
    "value": "var(--colors-teal-a-10)",
    "variable": "var(--colors-teal-a-10)"
  },
  "colors.teal.a.11": {
    "value": "var(--colors-teal-a-11)",
    "variable": "var(--colors-teal-a-11)"
  },
  "colors.teal.a.12": {
    "value": "var(--colors-teal-a-12)",
    "variable": "var(--colors-teal-a-12)"
  },
  "colors.teal.p3.1": {
    "value": "var(--colors-teal-p3-1)",
    "variable": "var(--colors-teal-p3-1)"
  },
  "colors.teal.p3.2": {
    "value": "var(--colors-teal-p3-2)",
    "variable": "var(--colors-teal-p3-2)"
  },
  "colors.teal.p3.3": {
    "value": "var(--colors-teal-p3-3)",
    "variable": "var(--colors-teal-p3-3)"
  },
  "colors.teal.p3.4": {
    "value": "var(--colors-teal-p3-4)",
    "variable": "var(--colors-teal-p3-4)"
  },
  "colors.teal.p3.5": {
    "value": "var(--colors-teal-p3-5)",
    "variable": "var(--colors-teal-p3-5)"
  },
  "colors.teal.p3.6": {
    "value": "var(--colors-teal-p3-6)",
    "variable": "var(--colors-teal-p3-6)"
  },
  "colors.teal.p3.7": {
    "value": "var(--colors-teal-p3-7)",
    "variable": "var(--colors-teal-p3-7)"
  },
  "colors.teal.p3.8": {
    "value": "var(--colors-teal-p3-8)",
    "variable": "var(--colors-teal-p3-8)"
  },
  "colors.teal.p3.9": {
    "value": "var(--colors-teal-p3-9)",
    "variable": "var(--colors-teal-p3-9)"
  },
  "colors.teal.p3.10": {
    "value": "var(--colors-teal-p3-10)",
    "variable": "var(--colors-teal-p3-10)"
  },
  "colors.teal.p3.11": {
    "value": "var(--colors-teal-p3-11)",
    "variable": "var(--colors-teal-p3-11)"
  },
  "colors.teal.p3.12": {
    "value": "var(--colors-teal-p3-12)",
    "variable": "var(--colors-teal-p3-12)"
  },
  "colors.teal.p3.a.1": {
    "value": "var(--colors-teal-p3-a-1)",
    "variable": "var(--colors-teal-p3-a-1)"
  },
  "colors.teal.p3.a.2": {
    "value": "var(--colors-teal-p3-a-2)",
    "variable": "var(--colors-teal-p3-a-2)"
  },
  "colors.teal.p3.a.3": {
    "value": "var(--colors-teal-p3-a-3)",
    "variable": "var(--colors-teal-p3-a-3)"
  },
  "colors.teal.p3.a.4": {
    "value": "var(--colors-teal-p3-a-4)",
    "variable": "var(--colors-teal-p3-a-4)"
  },
  "colors.teal.p3.a.5": {
    "value": "var(--colors-teal-p3-a-5)",
    "variable": "var(--colors-teal-p3-a-5)"
  },
  "colors.teal.p3.a.6": {
    "value": "var(--colors-teal-p3-a-6)",
    "variable": "var(--colors-teal-p3-a-6)"
  },
  "colors.teal.p3.a.7": {
    "value": "var(--colors-teal-p3-a-7)",
    "variable": "var(--colors-teal-p3-a-7)"
  },
  "colors.teal.p3.a.8": {
    "value": "var(--colors-teal-p3-a-8)",
    "variable": "var(--colors-teal-p3-a-8)"
  },
  "colors.teal.p3.a.9": {
    "value": "var(--colors-teal-p3-a-9)",
    "variable": "var(--colors-teal-p3-a-9)"
  },
  "colors.teal.p3.a.10": {
    "value": "var(--colors-teal-p3-a-10)",
    "variable": "var(--colors-teal-p3-a-10)"
  },
  "colors.teal.p3.a.11": {
    "value": "var(--colors-teal-p3-a-11)",
    "variable": "var(--colors-teal-p3-a-11)"
  },
  "colors.teal.p3.a.12": {
    "value": "var(--colors-teal-p3-a-12)",
    "variable": "var(--colors-teal-p3-a-12)"
  },
  "colors.tomato.1": {
    "value": "var(--colors-tomato-1)",
    "variable": "var(--colors-tomato-1)"
  },
  "colors.tomato.2": {
    "value": "var(--colors-tomato-2)",
    "variable": "var(--colors-tomato-2)"
  },
  "colors.tomato.3": {
    "value": "var(--colors-tomato-3)",
    "variable": "var(--colors-tomato-3)"
  },
  "colors.tomato.4": {
    "value": "var(--colors-tomato-4)",
    "variable": "var(--colors-tomato-4)"
  },
  "colors.tomato.5": {
    "value": "var(--colors-tomato-5)",
    "variable": "var(--colors-tomato-5)"
  },
  "colors.tomato.6": {
    "value": "var(--colors-tomato-6)",
    "variable": "var(--colors-tomato-6)"
  },
  "colors.tomato.7": {
    "value": "var(--colors-tomato-7)",
    "variable": "var(--colors-tomato-7)"
  },
  "colors.tomato.8": {
    "value": "var(--colors-tomato-8)",
    "variable": "var(--colors-tomato-8)"
  },
  "colors.tomato.9": {
    "value": "var(--colors-tomato-9)",
    "variable": "var(--colors-tomato-9)"
  },
  "colors.tomato.10": {
    "value": "var(--colors-tomato-10)",
    "variable": "var(--colors-tomato-10)"
  },
  "colors.tomato.11": {
    "value": "var(--colors-tomato-11)",
    "variable": "var(--colors-tomato-11)"
  },
  "colors.tomato.12": {
    "value": "var(--colors-tomato-12)",
    "variable": "var(--colors-tomato-12)"
  },
  "colors.tomato.light.1": {
    "value": "var(--colors-tomato-light-1)",
    "variable": "var(--colors-tomato-light-1)"
  },
  "colors.tomato.light.2": {
    "value": "var(--colors-tomato-light-2)",
    "variable": "var(--colors-tomato-light-2)"
  },
  "colors.tomato.light.3": {
    "value": "var(--colors-tomato-light-3)",
    "variable": "var(--colors-tomato-light-3)"
  },
  "colors.tomato.light.4": {
    "value": "var(--colors-tomato-light-4)",
    "variable": "var(--colors-tomato-light-4)"
  },
  "colors.tomato.light.5": {
    "value": "var(--colors-tomato-light-5)",
    "variable": "var(--colors-tomato-light-5)"
  },
  "colors.tomato.light.6": {
    "value": "var(--colors-tomato-light-6)",
    "variable": "var(--colors-tomato-light-6)"
  },
  "colors.tomato.light.7": {
    "value": "var(--colors-tomato-light-7)",
    "variable": "var(--colors-tomato-light-7)"
  },
  "colors.tomato.light.8": {
    "value": "var(--colors-tomato-light-8)",
    "variable": "var(--colors-tomato-light-8)"
  },
  "colors.tomato.light.9": {
    "value": "var(--colors-tomato-light-9)",
    "variable": "var(--colors-tomato-light-9)"
  },
  "colors.tomato.light.10": {
    "value": "var(--colors-tomato-light-10)",
    "variable": "var(--colors-tomato-light-10)"
  },
  "colors.tomato.light.11": {
    "value": "var(--colors-tomato-light-11)",
    "variable": "var(--colors-tomato-light-11)"
  },
  "colors.tomato.light.12": {
    "value": "var(--colors-tomato-light-12)",
    "variable": "var(--colors-tomato-light-12)"
  },
  "colors.tomato.light.a.1": {
    "value": "var(--colors-tomato-light-a-1)",
    "variable": "var(--colors-tomato-light-a-1)"
  },
  "colors.tomato.light.a.2": {
    "value": "var(--colors-tomato-light-a-2)",
    "variable": "var(--colors-tomato-light-a-2)"
  },
  "colors.tomato.light.a.3": {
    "value": "var(--colors-tomato-light-a-3)",
    "variable": "var(--colors-tomato-light-a-3)"
  },
  "colors.tomato.light.a.4": {
    "value": "var(--colors-tomato-light-a-4)",
    "variable": "var(--colors-tomato-light-a-4)"
  },
  "colors.tomato.light.a.5": {
    "value": "var(--colors-tomato-light-a-5)",
    "variable": "var(--colors-tomato-light-a-5)"
  },
  "colors.tomato.light.a.6": {
    "value": "var(--colors-tomato-light-a-6)",
    "variable": "var(--colors-tomato-light-a-6)"
  },
  "colors.tomato.light.a.7": {
    "value": "var(--colors-tomato-light-a-7)",
    "variable": "var(--colors-tomato-light-a-7)"
  },
  "colors.tomato.light.a.8": {
    "value": "var(--colors-tomato-light-a-8)",
    "variable": "var(--colors-tomato-light-a-8)"
  },
  "colors.tomato.light.a.9": {
    "value": "var(--colors-tomato-light-a-9)",
    "variable": "var(--colors-tomato-light-a-9)"
  },
  "colors.tomato.light.a.10": {
    "value": "var(--colors-tomato-light-a-10)",
    "variable": "var(--colors-tomato-light-a-10)"
  },
  "colors.tomato.light.a.11": {
    "value": "var(--colors-tomato-light-a-11)",
    "variable": "var(--colors-tomato-light-a-11)"
  },
  "colors.tomato.light.a.12": {
    "value": "var(--colors-tomato-light-a-12)",
    "variable": "var(--colors-tomato-light-a-12)"
  },
  "colors.tomato.light.p3.1": {
    "value": "color(display-p3 0.998 0.989 0.988)",
    "variable": "var(--colors-tomato-light-p3-1)"
  },
  "colors.tomato.light.p3.2": {
    "value": "color(display-p3 0.994 0.974 0.969)",
    "variable": "var(--colors-tomato-light-p3-2)"
  },
  "colors.tomato.light.p3.3": {
    "value": "color(display-p3 0.985 0.924 0.909)",
    "variable": "var(--colors-tomato-light-p3-3)"
  },
  "colors.tomato.light.p3.4": {
    "value": "color(display-p3 0.996 0.868 0.835)",
    "variable": "var(--colors-tomato-light-p3-4)"
  },
  "colors.tomato.light.p3.5": {
    "value": "color(display-p3 0.98 0.812 0.77)",
    "variable": "var(--colors-tomato-light-p3-5)"
  },
  "colors.tomato.light.p3.6": {
    "value": "color(display-p3 0.953 0.75 0.698)",
    "variable": "var(--colors-tomato-light-p3-6)"
  },
  "colors.tomato.light.p3.7": {
    "value": "color(display-p3 0.917 0.673 0.611)",
    "variable": "var(--colors-tomato-light-p3-7)"
  },
  "colors.tomato.light.p3.8": {
    "value": "color(display-p3 0.875 0.575 0.502)",
    "variable": "var(--colors-tomato-light-p3-8)"
  },
  "colors.tomato.light.p3.9": {
    "value": "color(display-p3 0.831 0.345 0.231)",
    "variable": "var(--colors-tomato-light-p3-9)"
  },
  "colors.tomato.light.p3.10": {
    "value": "color(display-p3 0.802 0.313 0.2)",
    "variable": "var(--colors-tomato-light-p3-10)"
  },
  "colors.tomato.light.p3.11": {
    "value": "color(display-p3 0.755 0.259 0.152)",
    "variable": "var(--colors-tomato-light-p3-11)"
  },
  "colors.tomato.light.p3.12": {
    "value": "color(display-p3 0.335 0.165 0.132)",
    "variable": "var(--colors-tomato-light-p3-12)"
  },
  "colors.tomato.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.024 0.024 / 0.012)",
    "variable": "var(--colors-tomato-light-p3-a-1)"
  },
  "colors.tomato.light.p3.a.2": {
    "value": "color(display-p3 0.757 0.145 0.02 / 0.032)",
    "variable": "var(--colors-tomato-light-p3-a-2)"
  },
  "colors.tomato.light.p3.a.3": {
    "value": "color(display-p3 0.831 0.184 0.012 / 0.091)",
    "variable": "var(--colors-tomato-light-p3-a-3)"
  },
  "colors.tomato.light.p3.a.4": {
    "value": "color(display-p3 0.976 0.192 0.004 / 0.165)",
    "variable": "var(--colors-tomato-light-p3-a-4)"
  },
  "colors.tomato.light.p3.a.5": {
    "value": "color(display-p3 0.918 0.192 0.004 / 0.232)",
    "variable": "var(--colors-tomato-light-p3-a-5)"
  },
  "colors.tomato.light.p3.a.6": {
    "value": "color(display-p3 0.847 0.173 0.004 / 0.302)",
    "variable": "var(--colors-tomato-light-p3-a-6)"
  },
  "colors.tomato.light.p3.a.7": {
    "value": "color(display-p3 0.788 0.165 0.004 / 0.389)",
    "variable": "var(--colors-tomato-light-p3-a-7)"
  },
  "colors.tomato.light.p3.a.8": {
    "value": "color(display-p3 0.749 0.153 0.004 / 0.499)",
    "variable": "var(--colors-tomato-light-p3-a-8)"
  },
  "colors.tomato.light.p3.a.9": {
    "value": "color(display-p3 0.78 0.149 0 / 0.769)",
    "variable": "var(--colors-tomato-light-p3-a-9)"
  },
  "colors.tomato.light.p3.a.10": {
    "value": "color(display-p3 0.757 0.141 0 / 0.8)",
    "variable": "var(--colors-tomato-light-p3-a-10)"
  },
  "colors.tomato.light.p3.a.11": {
    "value": "color(display-p3 0.755 0.259 0.152)",
    "variable": "var(--colors-tomato-light-p3-a-11)"
  },
  "colors.tomato.light.p3.a.12": {
    "value": "color(display-p3 0.335 0.165 0.132)",
    "variable": "var(--colors-tomato-light-p3-a-12)"
  },
  "colors.tomato.dark.1": {
    "value": "var(--colors-tomato-dark-1)",
    "variable": "var(--colors-tomato-dark-1)"
  },
  "colors.tomato.dark.2": {
    "value": "var(--colors-tomato-dark-2)",
    "variable": "var(--colors-tomato-dark-2)"
  },
  "colors.tomato.dark.3": {
    "value": "var(--colors-tomato-dark-3)",
    "variable": "var(--colors-tomato-dark-3)"
  },
  "colors.tomato.dark.4": {
    "value": "var(--colors-tomato-dark-4)",
    "variable": "var(--colors-tomato-dark-4)"
  },
  "colors.tomato.dark.5": {
    "value": "var(--colors-tomato-dark-5)",
    "variable": "var(--colors-tomato-dark-5)"
  },
  "colors.tomato.dark.6": {
    "value": "var(--colors-tomato-dark-6)",
    "variable": "var(--colors-tomato-dark-6)"
  },
  "colors.tomato.dark.7": {
    "value": "var(--colors-tomato-dark-7)",
    "variable": "var(--colors-tomato-dark-7)"
  },
  "colors.tomato.dark.8": {
    "value": "var(--colors-tomato-dark-8)",
    "variable": "var(--colors-tomato-dark-8)"
  },
  "colors.tomato.dark.9": {
    "value": "var(--colors-tomato-dark-9)",
    "variable": "var(--colors-tomato-dark-9)"
  },
  "colors.tomato.dark.10": {
    "value": "var(--colors-tomato-dark-10)",
    "variable": "var(--colors-tomato-dark-10)"
  },
  "colors.tomato.dark.11": {
    "value": "var(--colors-tomato-dark-11)",
    "variable": "var(--colors-tomato-dark-11)"
  },
  "colors.tomato.dark.12": {
    "value": "var(--colors-tomato-dark-12)",
    "variable": "var(--colors-tomato-dark-12)"
  },
  "colors.tomato.dark.a.1": {
    "value": "var(--colors-tomato-dark-a-1)",
    "variable": "var(--colors-tomato-dark-a-1)"
  },
  "colors.tomato.dark.a.2": {
    "value": "var(--colors-tomato-dark-a-2)",
    "variable": "var(--colors-tomato-dark-a-2)"
  },
  "colors.tomato.dark.a.3": {
    "value": "var(--colors-tomato-dark-a-3)",
    "variable": "var(--colors-tomato-dark-a-3)"
  },
  "colors.tomato.dark.a.4": {
    "value": "var(--colors-tomato-dark-a-4)",
    "variable": "var(--colors-tomato-dark-a-4)"
  },
  "colors.tomato.dark.a.5": {
    "value": "var(--colors-tomato-dark-a-5)",
    "variable": "var(--colors-tomato-dark-a-5)"
  },
  "colors.tomato.dark.a.6": {
    "value": "var(--colors-tomato-dark-a-6)",
    "variable": "var(--colors-tomato-dark-a-6)"
  },
  "colors.tomato.dark.a.7": {
    "value": "var(--colors-tomato-dark-a-7)",
    "variable": "var(--colors-tomato-dark-a-7)"
  },
  "colors.tomato.dark.a.8": {
    "value": "var(--colors-tomato-dark-a-8)",
    "variable": "var(--colors-tomato-dark-a-8)"
  },
  "colors.tomato.dark.a.9": {
    "value": "var(--colors-tomato-dark-a-9)",
    "variable": "var(--colors-tomato-dark-a-9)"
  },
  "colors.tomato.dark.a.10": {
    "value": "var(--colors-tomato-dark-a-10)",
    "variable": "var(--colors-tomato-dark-a-10)"
  },
  "colors.tomato.dark.a.11": {
    "value": "var(--colors-tomato-dark-a-11)",
    "variable": "var(--colors-tomato-dark-a-11)"
  },
  "colors.tomato.dark.a.12": {
    "value": "var(--colors-tomato-dark-a-12)",
    "variable": "var(--colors-tomato-dark-a-12)"
  },
  "colors.tomato.dark.p3.1": {
    "value": "color(display-p3 0.09 0.068 0.067)",
    "variable": "var(--colors-tomato-dark-p3-1)"
  },
  "colors.tomato.dark.p3.2": {
    "value": "color(display-p3 0.115 0.084 0.076)",
    "variable": "var(--colors-tomato-dark-p3-2)"
  },
  "colors.tomato.dark.p3.3": {
    "value": "color(display-p3 0.205 0.097 0.083)",
    "variable": "var(--colors-tomato-dark-p3-3)"
  },
  "colors.tomato.dark.p3.4": {
    "value": "color(display-p3 0.282 0.099 0.077)",
    "variable": "var(--colors-tomato-dark-p3-4)"
  },
  "colors.tomato.dark.p3.5": {
    "value": "color(display-p3 0.339 0.129 0.101)",
    "variable": "var(--colors-tomato-dark-p3-5)"
  },
  "colors.tomato.dark.p3.6": {
    "value": "color(display-p3 0.398 0.179 0.141)",
    "variable": "var(--colors-tomato-dark-p3-6)"
  },
  "colors.tomato.dark.p3.7": {
    "value": "color(display-p3 0.487 0.245 0.194)",
    "variable": "var(--colors-tomato-dark-p3-7)"
  },
  "colors.tomato.dark.p3.8": {
    "value": "color(display-p3 0.629 0.322 0.248)",
    "variable": "var(--colors-tomato-dark-p3-8)"
  },
  "colors.tomato.dark.p3.9": {
    "value": "color(display-p3 0.831 0.345 0.231)",
    "variable": "var(--colors-tomato-dark-p3-9)"
  },
  "colors.tomato.dark.p3.10": {
    "value": "color(display-p3 0.862 0.415 0.298)",
    "variable": "var(--colors-tomato-dark-p3-10)"
  },
  "colors.tomato.dark.p3.11": {
    "value": "color(display-p3 1 0.585 0.455)",
    "variable": "var(--colors-tomato-dark-p3-11)"
  },
  "colors.tomato.dark.p3.12": {
    "value": "color(display-p3 0.959 0.833 0.802)",
    "variable": "var(--colors-tomato-dark-p3-12)"
  },
  "colors.tomato.dark.p3.a.1": {
    "value": "color(display-p3 0.973 0.071 0.071 / 0.026)",
    "variable": "var(--colors-tomato-dark-p3-a-1)"
  },
  "colors.tomato.dark.p3.a.2": {
    "value": "color(display-p3 0.992 0.376 0.224 / 0.051)",
    "variable": "var(--colors-tomato-dark-p3-a-2)"
  },
  "colors.tomato.dark.p3.a.3": {
    "value": "color(display-p3 0.996 0.282 0.176 / 0.148)",
    "variable": "var(--colors-tomato-dark-p3-a-3)"
  },
  "colors.tomato.dark.p3.a.4": {
    "value": "color(display-p3 1 0.204 0.118 / 0.232)",
    "variable": "var(--colors-tomato-dark-p3-a-4)"
  },
  "colors.tomato.dark.p3.a.5": {
    "value": "color(display-p3 1 0.286 0.192 / 0.29)",
    "variable": "var(--colors-tomato-dark-p3-a-5)"
  },
  "colors.tomato.dark.p3.a.6": {
    "value": "color(display-p3 1 0.392 0.278 / 0.353)",
    "variable": "var(--colors-tomato-dark-p3-a-6)"
  },
  "colors.tomato.dark.p3.a.7": {
    "value": "color(display-p3 1 0.459 0.349 / 0.45)",
    "variable": "var(--colors-tomato-dark-p3-a-7)"
  },
  "colors.tomato.dark.p3.a.8": {
    "value": "color(display-p3 1 0.49 0.369 / 0.601)",
    "variable": "var(--colors-tomato-dark-p3-a-8)"
  },
  "colors.tomato.dark.p3.a.9": {
    "value": "color(display-p3 1 0.408 0.267 / 0.82)",
    "variable": "var(--colors-tomato-dark-p3-a-9)"
  },
  "colors.tomato.dark.p3.a.10": {
    "value": "color(display-p3 1 0.478 0.341 / 0.853)",
    "variable": "var(--colors-tomato-dark-p3-a-10)"
  },
  "colors.tomato.dark.p3.a.11": {
    "value": "color(display-p3 1 0.585 0.455)",
    "variable": "var(--colors-tomato-dark-p3-a-11)"
  },
  "colors.tomato.dark.p3.a.12": {
    "value": "color(display-p3 0.959 0.833 0.802)",
    "variable": "var(--colors-tomato-dark-p3-a-12)"
  },
  "colors.tomato.a.1": {
    "value": "var(--colors-tomato-a-1)",
    "variable": "var(--colors-tomato-a-1)"
  },
  "colors.tomato.a.2": {
    "value": "var(--colors-tomato-a-2)",
    "variable": "var(--colors-tomato-a-2)"
  },
  "colors.tomato.a.3": {
    "value": "var(--colors-tomato-a-3)",
    "variable": "var(--colors-tomato-a-3)"
  },
  "colors.tomato.a.4": {
    "value": "var(--colors-tomato-a-4)",
    "variable": "var(--colors-tomato-a-4)"
  },
  "colors.tomato.a.5": {
    "value": "var(--colors-tomato-a-5)",
    "variable": "var(--colors-tomato-a-5)"
  },
  "colors.tomato.a.6": {
    "value": "var(--colors-tomato-a-6)",
    "variable": "var(--colors-tomato-a-6)"
  },
  "colors.tomato.a.7": {
    "value": "var(--colors-tomato-a-7)",
    "variable": "var(--colors-tomato-a-7)"
  },
  "colors.tomato.a.8": {
    "value": "var(--colors-tomato-a-8)",
    "variable": "var(--colors-tomato-a-8)"
  },
  "colors.tomato.a.9": {
    "value": "var(--colors-tomato-a-9)",
    "variable": "var(--colors-tomato-a-9)"
  },
  "colors.tomato.a.10": {
    "value": "var(--colors-tomato-a-10)",
    "variable": "var(--colors-tomato-a-10)"
  },
  "colors.tomato.a.11": {
    "value": "var(--colors-tomato-a-11)",
    "variable": "var(--colors-tomato-a-11)"
  },
  "colors.tomato.a.12": {
    "value": "var(--colors-tomato-a-12)",
    "variable": "var(--colors-tomato-a-12)"
  },
  "colors.tomato.p3.1": {
    "value": "var(--colors-tomato-p3-1)",
    "variable": "var(--colors-tomato-p3-1)"
  },
  "colors.tomato.p3.2": {
    "value": "var(--colors-tomato-p3-2)",
    "variable": "var(--colors-tomato-p3-2)"
  },
  "colors.tomato.p3.3": {
    "value": "var(--colors-tomato-p3-3)",
    "variable": "var(--colors-tomato-p3-3)"
  },
  "colors.tomato.p3.4": {
    "value": "var(--colors-tomato-p3-4)",
    "variable": "var(--colors-tomato-p3-4)"
  },
  "colors.tomato.p3.5": {
    "value": "var(--colors-tomato-p3-5)",
    "variable": "var(--colors-tomato-p3-5)"
  },
  "colors.tomato.p3.6": {
    "value": "var(--colors-tomato-p3-6)",
    "variable": "var(--colors-tomato-p3-6)"
  },
  "colors.tomato.p3.7": {
    "value": "var(--colors-tomato-p3-7)",
    "variable": "var(--colors-tomato-p3-7)"
  },
  "colors.tomato.p3.8": {
    "value": "var(--colors-tomato-p3-8)",
    "variable": "var(--colors-tomato-p3-8)"
  },
  "colors.tomato.p3.9": {
    "value": "var(--colors-tomato-p3-9)",
    "variable": "var(--colors-tomato-p3-9)"
  },
  "colors.tomato.p3.10": {
    "value": "var(--colors-tomato-p3-10)",
    "variable": "var(--colors-tomato-p3-10)"
  },
  "colors.tomato.p3.11": {
    "value": "var(--colors-tomato-p3-11)",
    "variable": "var(--colors-tomato-p3-11)"
  },
  "colors.tomato.p3.12": {
    "value": "var(--colors-tomato-p3-12)",
    "variable": "var(--colors-tomato-p3-12)"
  },
  "colors.tomato.p3.a.1": {
    "value": "var(--colors-tomato-p3-a-1)",
    "variable": "var(--colors-tomato-p3-a-1)"
  },
  "colors.tomato.p3.a.2": {
    "value": "var(--colors-tomato-p3-a-2)",
    "variable": "var(--colors-tomato-p3-a-2)"
  },
  "colors.tomato.p3.a.3": {
    "value": "var(--colors-tomato-p3-a-3)",
    "variable": "var(--colors-tomato-p3-a-3)"
  },
  "colors.tomato.p3.a.4": {
    "value": "var(--colors-tomato-p3-a-4)",
    "variable": "var(--colors-tomato-p3-a-4)"
  },
  "colors.tomato.p3.a.5": {
    "value": "var(--colors-tomato-p3-a-5)",
    "variable": "var(--colors-tomato-p3-a-5)"
  },
  "colors.tomato.p3.a.6": {
    "value": "var(--colors-tomato-p3-a-6)",
    "variable": "var(--colors-tomato-p3-a-6)"
  },
  "colors.tomato.p3.a.7": {
    "value": "var(--colors-tomato-p3-a-7)",
    "variable": "var(--colors-tomato-p3-a-7)"
  },
  "colors.tomato.p3.a.8": {
    "value": "var(--colors-tomato-p3-a-8)",
    "variable": "var(--colors-tomato-p3-a-8)"
  },
  "colors.tomato.p3.a.9": {
    "value": "var(--colors-tomato-p3-a-9)",
    "variable": "var(--colors-tomato-p3-a-9)"
  },
  "colors.tomato.p3.a.10": {
    "value": "var(--colors-tomato-p3-a-10)",
    "variable": "var(--colors-tomato-p3-a-10)"
  },
  "colors.tomato.p3.a.11": {
    "value": "var(--colors-tomato-p3-a-11)",
    "variable": "var(--colors-tomato-p3-a-11)"
  },
  "colors.tomato.p3.a.12": {
    "value": "var(--colors-tomato-p3-a-12)",
    "variable": "var(--colors-tomato-p3-a-12)"
  },
  "colors.violet.1": {
    "value": "var(--colors-violet-1)",
    "variable": "var(--colors-violet-1)"
  },
  "colors.violet.2": {
    "value": "var(--colors-violet-2)",
    "variable": "var(--colors-violet-2)"
  },
  "colors.violet.3": {
    "value": "var(--colors-violet-3)",
    "variable": "var(--colors-violet-3)"
  },
  "colors.violet.4": {
    "value": "var(--colors-violet-4)",
    "variable": "var(--colors-violet-4)"
  },
  "colors.violet.5": {
    "value": "var(--colors-violet-5)",
    "variable": "var(--colors-violet-5)"
  },
  "colors.violet.6": {
    "value": "var(--colors-violet-6)",
    "variable": "var(--colors-violet-6)"
  },
  "colors.violet.7": {
    "value": "var(--colors-violet-7)",
    "variable": "var(--colors-violet-7)"
  },
  "colors.violet.8": {
    "value": "var(--colors-violet-8)",
    "variable": "var(--colors-violet-8)"
  },
  "colors.violet.9": {
    "value": "var(--colors-violet-9)",
    "variable": "var(--colors-violet-9)"
  },
  "colors.violet.10": {
    "value": "var(--colors-violet-10)",
    "variable": "var(--colors-violet-10)"
  },
  "colors.violet.11": {
    "value": "var(--colors-violet-11)",
    "variable": "var(--colors-violet-11)"
  },
  "colors.violet.12": {
    "value": "var(--colors-violet-12)",
    "variable": "var(--colors-violet-12)"
  },
  "colors.violet.light.1": {
    "value": "var(--colors-violet-light-1)",
    "variable": "var(--colors-violet-light-1)"
  },
  "colors.violet.light.2": {
    "value": "var(--colors-violet-light-2)",
    "variable": "var(--colors-violet-light-2)"
  },
  "colors.violet.light.3": {
    "value": "var(--colors-violet-light-3)",
    "variable": "var(--colors-violet-light-3)"
  },
  "colors.violet.light.4": {
    "value": "var(--colors-violet-light-4)",
    "variable": "var(--colors-violet-light-4)"
  },
  "colors.violet.light.5": {
    "value": "var(--colors-violet-light-5)",
    "variable": "var(--colors-violet-light-5)"
  },
  "colors.violet.light.6": {
    "value": "var(--colors-violet-light-6)",
    "variable": "var(--colors-violet-light-6)"
  },
  "colors.violet.light.7": {
    "value": "var(--colors-violet-light-7)",
    "variable": "var(--colors-violet-light-7)"
  },
  "colors.violet.light.8": {
    "value": "var(--colors-violet-light-8)",
    "variable": "var(--colors-violet-light-8)"
  },
  "colors.violet.light.9": {
    "value": "var(--colors-violet-light-9)",
    "variable": "var(--colors-violet-light-9)"
  },
  "colors.violet.light.10": {
    "value": "var(--colors-violet-light-10)",
    "variable": "var(--colors-violet-light-10)"
  },
  "colors.violet.light.11": {
    "value": "var(--colors-violet-light-11)",
    "variable": "var(--colors-violet-light-11)"
  },
  "colors.violet.light.12": {
    "value": "var(--colors-violet-light-12)",
    "variable": "var(--colors-violet-light-12)"
  },
  "colors.violet.light.a.1": {
    "value": "var(--colors-violet-light-a-1)",
    "variable": "var(--colors-violet-light-a-1)"
  },
  "colors.violet.light.a.2": {
    "value": "var(--colors-violet-light-a-2)",
    "variable": "var(--colors-violet-light-a-2)"
  },
  "colors.violet.light.a.3": {
    "value": "var(--colors-violet-light-a-3)",
    "variable": "var(--colors-violet-light-a-3)"
  },
  "colors.violet.light.a.4": {
    "value": "var(--colors-violet-light-a-4)",
    "variable": "var(--colors-violet-light-a-4)"
  },
  "colors.violet.light.a.5": {
    "value": "var(--colors-violet-light-a-5)",
    "variable": "var(--colors-violet-light-a-5)"
  },
  "colors.violet.light.a.6": {
    "value": "var(--colors-violet-light-a-6)",
    "variable": "var(--colors-violet-light-a-6)"
  },
  "colors.violet.light.a.7": {
    "value": "var(--colors-violet-light-a-7)",
    "variable": "var(--colors-violet-light-a-7)"
  },
  "colors.violet.light.a.8": {
    "value": "var(--colors-violet-light-a-8)",
    "variable": "var(--colors-violet-light-a-8)"
  },
  "colors.violet.light.a.9": {
    "value": "var(--colors-violet-light-a-9)",
    "variable": "var(--colors-violet-light-a-9)"
  },
  "colors.violet.light.a.10": {
    "value": "var(--colors-violet-light-a-10)",
    "variable": "var(--colors-violet-light-a-10)"
  },
  "colors.violet.light.a.11": {
    "value": "var(--colors-violet-light-a-11)",
    "variable": "var(--colors-violet-light-a-11)"
  },
  "colors.violet.light.a.12": {
    "value": "var(--colors-violet-light-a-12)",
    "variable": "var(--colors-violet-light-a-12)"
  },
  "colors.violet.light.p3.1": {
    "value": "color(display-p3 0.991 0.988 0.995)",
    "variable": "var(--colors-violet-light-p3-1)"
  },
  "colors.violet.light.p3.2": {
    "value": "color(display-p3 0.978 0.974 0.998)",
    "variable": "var(--colors-violet-light-p3-2)"
  },
  "colors.violet.light.p3.3": {
    "value": "color(display-p3 0.953 0.943 0.993)",
    "variable": "var(--colors-violet-light-p3-3)"
  },
  "colors.violet.light.p3.4": {
    "value": "color(display-p3 0.916 0.897 1)",
    "variable": "var(--colors-violet-light-p3-4)"
  },
  "colors.violet.light.p3.5": {
    "value": "color(display-p3 0.876 0.851 1)",
    "variable": "var(--colors-violet-light-p3-5)"
  },
  "colors.violet.light.p3.6": {
    "value": "color(display-p3 0.825 0.793 0.981)",
    "variable": "var(--colors-violet-light-p3-6)"
  },
  "colors.violet.light.p3.7": {
    "value": "color(display-p3 0.752 0.712 0.943)",
    "variable": "var(--colors-violet-light-p3-7)"
  },
  "colors.violet.light.p3.8": {
    "value": "color(display-p3 0.654 0.602 0.902)",
    "variable": "var(--colors-violet-light-p3-8)"
  },
  "colors.violet.light.p3.9": {
    "value": "color(display-p3 0.417 0.341 0.784)",
    "variable": "var(--colors-violet-light-p3-9)"
  },
  "colors.violet.light.p3.10": {
    "value": "color(display-p3 0.381 0.306 0.741)",
    "variable": "var(--colors-violet-light-p3-10)"
  },
  "colors.violet.light.p3.11": {
    "value": "color(display-p3 0.383 0.317 0.702)",
    "variable": "var(--colors-violet-light-p3-11)"
  },
  "colors.violet.light.p3.12": {
    "value": "color(display-p3 0.179 0.15 0.359)",
    "variable": "var(--colors-violet-light-p3-12)"
  },
  "colors.violet.light.p3.a.1": {
    "value": "color(display-p3 0.349 0.024 0.675 / 0.012)",
    "variable": "var(--colors-violet-light-p3-a-1)"
  },
  "colors.violet.light.p3.a.2": {
    "value": "color(display-p3 0.161 0.024 0.863 / 0.028)",
    "variable": "var(--colors-violet-light-p3-a-2)"
  },
  "colors.violet.light.p3.a.3": {
    "value": "color(display-p3 0.204 0.004 0.871 / 0.059)",
    "variable": "var(--colors-violet-light-p3-a-3)"
  },
  "colors.violet.light.p3.a.4": {
    "value": "color(display-p3 0.196 0.004 1 / 0.102)",
    "variable": "var(--colors-violet-light-p3-a-4)"
  },
  "colors.violet.light.p3.a.5": {
    "value": "color(display-p3 0.165 0.008 1 / 0.15)",
    "variable": "var(--colors-violet-light-p3-a-5)"
  },
  "colors.violet.light.p3.a.6": {
    "value": "color(display-p3 0.153 0.004 0.906 / 0.208)",
    "variable": "var(--colors-violet-light-p3-a-6)"
  },
  "colors.violet.light.p3.a.7": {
    "value": "color(display-p3 0.141 0.004 0.796 / 0.287)",
    "variable": "var(--colors-violet-light-p3-a-7)"
  },
  "colors.violet.light.p3.a.8": {
    "value": "color(display-p3 0.133 0.004 0.753 / 0.397)",
    "variable": "var(--colors-violet-light-p3-a-8)"
  },
  "colors.violet.light.p3.a.9": {
    "value": "color(display-p3 0.114 0 0.675 / 0.659)",
    "variable": "var(--colors-violet-light-p3-a-9)"
  },
  "colors.violet.light.p3.a.10": {
    "value": "color(display-p3 0.11 0 0.627 / 0.695)",
    "variable": "var(--colors-violet-light-p3-a-10)"
  },
  "colors.violet.light.p3.a.11": {
    "value": "color(display-p3 0.383 0.317 0.702)",
    "variable": "var(--colors-violet-light-p3-a-11)"
  },
  "colors.violet.light.p3.a.12": {
    "value": "color(display-p3 0.179 0.15 0.359)",
    "variable": "var(--colors-violet-light-p3-a-12)"
  },
  "colors.violet.dark.1": {
    "value": "var(--colors-violet-dark-1)",
    "variable": "var(--colors-violet-dark-1)"
  },
  "colors.violet.dark.2": {
    "value": "var(--colors-violet-dark-2)",
    "variable": "var(--colors-violet-dark-2)"
  },
  "colors.violet.dark.3": {
    "value": "var(--colors-violet-dark-3)",
    "variable": "var(--colors-violet-dark-3)"
  },
  "colors.violet.dark.4": {
    "value": "var(--colors-violet-dark-4)",
    "variable": "var(--colors-violet-dark-4)"
  },
  "colors.violet.dark.5": {
    "value": "var(--colors-violet-dark-5)",
    "variable": "var(--colors-violet-dark-5)"
  },
  "colors.violet.dark.6": {
    "value": "var(--colors-violet-dark-6)",
    "variable": "var(--colors-violet-dark-6)"
  },
  "colors.violet.dark.7": {
    "value": "var(--colors-violet-dark-7)",
    "variable": "var(--colors-violet-dark-7)"
  },
  "colors.violet.dark.8": {
    "value": "var(--colors-violet-dark-8)",
    "variable": "var(--colors-violet-dark-8)"
  },
  "colors.violet.dark.9": {
    "value": "var(--colors-violet-dark-9)",
    "variable": "var(--colors-violet-dark-9)"
  },
  "colors.violet.dark.10": {
    "value": "var(--colors-violet-dark-10)",
    "variable": "var(--colors-violet-dark-10)"
  },
  "colors.violet.dark.11": {
    "value": "var(--colors-violet-dark-11)",
    "variable": "var(--colors-violet-dark-11)"
  },
  "colors.violet.dark.12": {
    "value": "var(--colors-violet-dark-12)",
    "variable": "var(--colors-violet-dark-12)"
  },
  "colors.violet.dark.a.1": {
    "value": "var(--colors-violet-dark-a-1)",
    "variable": "var(--colors-violet-dark-a-1)"
  },
  "colors.violet.dark.a.2": {
    "value": "var(--colors-violet-dark-a-2)",
    "variable": "var(--colors-violet-dark-a-2)"
  },
  "colors.violet.dark.a.3": {
    "value": "var(--colors-violet-dark-a-3)",
    "variable": "var(--colors-violet-dark-a-3)"
  },
  "colors.violet.dark.a.4": {
    "value": "var(--colors-violet-dark-a-4)",
    "variable": "var(--colors-violet-dark-a-4)"
  },
  "colors.violet.dark.a.5": {
    "value": "var(--colors-violet-dark-a-5)",
    "variable": "var(--colors-violet-dark-a-5)"
  },
  "colors.violet.dark.a.6": {
    "value": "var(--colors-violet-dark-a-6)",
    "variable": "var(--colors-violet-dark-a-6)"
  },
  "colors.violet.dark.a.7": {
    "value": "var(--colors-violet-dark-a-7)",
    "variable": "var(--colors-violet-dark-a-7)"
  },
  "colors.violet.dark.a.8": {
    "value": "var(--colors-violet-dark-a-8)",
    "variable": "var(--colors-violet-dark-a-8)"
  },
  "colors.violet.dark.a.9": {
    "value": "var(--colors-violet-dark-a-9)",
    "variable": "var(--colors-violet-dark-a-9)"
  },
  "colors.violet.dark.a.10": {
    "value": "var(--colors-violet-dark-a-10)",
    "variable": "var(--colors-violet-dark-a-10)"
  },
  "colors.violet.dark.a.11": {
    "value": "var(--colors-violet-dark-a-11)",
    "variable": "var(--colors-violet-dark-a-11)"
  },
  "colors.violet.dark.a.12": {
    "value": "var(--colors-violet-dark-a-12)",
    "variable": "var(--colors-violet-dark-a-12)"
  },
  "colors.violet.dark.p3.1": {
    "value": "color(display-p3 0.077 0.071 0.118)",
    "variable": "var(--colors-violet-dark-p3-1)"
  },
  "colors.violet.dark.p3.2": {
    "value": "color(display-p3 0.101 0.084 0.141)",
    "variable": "var(--colors-violet-dark-p3-2)"
  },
  "colors.violet.dark.p3.3": {
    "value": "color(display-p3 0.154 0.123 0.256)",
    "variable": "var(--colors-violet-dark-p3-3)"
  },
  "colors.violet.dark.p3.4": {
    "value": "color(display-p3 0.191 0.148 0.345)",
    "variable": "var(--colors-violet-dark-p3-4)"
  },
  "colors.violet.dark.p3.5": {
    "value": "color(display-p3 0.226 0.182 0.396)",
    "variable": "var(--colors-violet-dark-p3-5)"
  },
  "colors.violet.dark.p3.6": {
    "value": "color(display-p3 0.269 0.223 0.449)",
    "variable": "var(--colors-violet-dark-p3-6)"
  },
  "colors.violet.dark.p3.7": {
    "value": "color(display-p3 0.326 0.277 0.53)",
    "variable": "var(--colors-violet-dark-p3-7)"
  },
  "colors.violet.dark.p3.8": {
    "value": "color(display-p3 0.399 0.346 0.656)",
    "variable": "var(--colors-violet-dark-p3-8)"
  },
  "colors.violet.dark.p3.9": {
    "value": "color(display-p3 0.417 0.341 0.784)",
    "variable": "var(--colors-violet-dark-p3-9)"
  },
  "colors.violet.dark.p3.10": {
    "value": "color(display-p3 0.477 0.402 0.823)",
    "variable": "var(--colors-violet-dark-p3-10)"
  },
  "colors.violet.dark.p3.11": {
    "value": "color(display-p3 0.72 0.65 1)",
    "variable": "var(--colors-violet-dark-p3-11)"
  },
  "colors.violet.dark.p3.12": {
    "value": "color(display-p3 0.883 0.867 0.986)",
    "variable": "var(--colors-violet-dark-p3-12)"
  },
  "colors.violet.dark.p3.a.1": {
    "value": "color(display-p3 0.282 0.141 0.996 / 0.055)",
    "variable": "var(--colors-violet-dark-p3-a-1)"
  },
  "colors.violet.dark.p3.a.2": {
    "value": "color(display-p3 0.51 0.263 1 / 0.08)",
    "variable": "var(--colors-violet-dark-p3-a-2)"
  },
  "colors.violet.dark.p3.a.3": {
    "value": "color(display-p3 0.494 0.337 0.996 / 0.202)",
    "variable": "var(--colors-violet-dark-p3-a-3)"
  },
  "colors.violet.dark.p3.a.4": {
    "value": "color(display-p3 0.49 0.345 1 / 0.299)",
    "variable": "var(--colors-violet-dark-p3-a-4)"
  },
  "colors.violet.dark.p3.a.5": {
    "value": "color(display-p3 0.525 0.392 1 / 0.353)",
    "variable": "var(--colors-violet-dark-p3-a-5)"
  },
  "colors.violet.dark.p3.a.6": {
    "value": "color(display-p3 0.569 0.455 1 / 0.408)",
    "variable": "var(--colors-violet-dark-p3-a-6)"
  },
  "colors.violet.dark.p3.a.7": {
    "value": "color(display-p3 0.588 0.494 1 / 0.496)",
    "variable": "var(--colors-violet-dark-p3-a-7)"
  },
  "colors.violet.dark.p3.a.8": {
    "value": "color(display-p3 0.596 0.51 1 / 0.631)",
    "variable": "var(--colors-violet-dark-p3-a-8)"
  },
  "colors.violet.dark.p3.a.9": {
    "value": "color(display-p3 0.522 0.424 1 / 0.769)",
    "variable": "var(--colors-violet-dark-p3-a-9)"
  },
  "colors.violet.dark.p3.a.10": {
    "value": "color(display-p3 0.576 0.482 1 / 0.811)",
    "variable": "var(--colors-violet-dark-p3-a-10)"
  },
  "colors.violet.dark.p3.a.11": {
    "value": "color(display-p3 0.72 0.65 1)",
    "variable": "var(--colors-violet-dark-p3-a-11)"
  },
  "colors.violet.dark.p3.a.12": {
    "value": "color(display-p3 0.883 0.867 0.986)",
    "variable": "var(--colors-violet-dark-p3-a-12)"
  },
  "colors.violet.a.1": {
    "value": "var(--colors-violet-a-1)",
    "variable": "var(--colors-violet-a-1)"
  },
  "colors.violet.a.2": {
    "value": "var(--colors-violet-a-2)",
    "variable": "var(--colors-violet-a-2)"
  },
  "colors.violet.a.3": {
    "value": "var(--colors-violet-a-3)",
    "variable": "var(--colors-violet-a-3)"
  },
  "colors.violet.a.4": {
    "value": "var(--colors-violet-a-4)",
    "variable": "var(--colors-violet-a-4)"
  },
  "colors.violet.a.5": {
    "value": "var(--colors-violet-a-5)",
    "variable": "var(--colors-violet-a-5)"
  },
  "colors.violet.a.6": {
    "value": "var(--colors-violet-a-6)",
    "variable": "var(--colors-violet-a-6)"
  },
  "colors.violet.a.7": {
    "value": "var(--colors-violet-a-7)",
    "variable": "var(--colors-violet-a-7)"
  },
  "colors.violet.a.8": {
    "value": "var(--colors-violet-a-8)",
    "variable": "var(--colors-violet-a-8)"
  },
  "colors.violet.a.9": {
    "value": "var(--colors-violet-a-9)",
    "variable": "var(--colors-violet-a-9)"
  },
  "colors.violet.a.10": {
    "value": "var(--colors-violet-a-10)",
    "variable": "var(--colors-violet-a-10)"
  },
  "colors.violet.a.11": {
    "value": "var(--colors-violet-a-11)",
    "variable": "var(--colors-violet-a-11)"
  },
  "colors.violet.a.12": {
    "value": "var(--colors-violet-a-12)",
    "variable": "var(--colors-violet-a-12)"
  },
  "colors.violet.p3.1": {
    "value": "var(--colors-violet-p3-1)",
    "variable": "var(--colors-violet-p3-1)"
  },
  "colors.violet.p3.2": {
    "value": "var(--colors-violet-p3-2)",
    "variable": "var(--colors-violet-p3-2)"
  },
  "colors.violet.p3.3": {
    "value": "var(--colors-violet-p3-3)",
    "variable": "var(--colors-violet-p3-3)"
  },
  "colors.violet.p3.4": {
    "value": "var(--colors-violet-p3-4)",
    "variable": "var(--colors-violet-p3-4)"
  },
  "colors.violet.p3.5": {
    "value": "var(--colors-violet-p3-5)",
    "variable": "var(--colors-violet-p3-5)"
  },
  "colors.violet.p3.6": {
    "value": "var(--colors-violet-p3-6)",
    "variable": "var(--colors-violet-p3-6)"
  },
  "colors.violet.p3.7": {
    "value": "var(--colors-violet-p3-7)",
    "variable": "var(--colors-violet-p3-7)"
  },
  "colors.violet.p3.8": {
    "value": "var(--colors-violet-p3-8)",
    "variable": "var(--colors-violet-p3-8)"
  },
  "colors.violet.p3.9": {
    "value": "var(--colors-violet-p3-9)",
    "variable": "var(--colors-violet-p3-9)"
  },
  "colors.violet.p3.10": {
    "value": "var(--colors-violet-p3-10)",
    "variable": "var(--colors-violet-p3-10)"
  },
  "colors.violet.p3.11": {
    "value": "var(--colors-violet-p3-11)",
    "variable": "var(--colors-violet-p3-11)"
  },
  "colors.violet.p3.12": {
    "value": "var(--colors-violet-p3-12)",
    "variable": "var(--colors-violet-p3-12)"
  },
  "colors.violet.p3.a.1": {
    "value": "var(--colors-violet-p3-a-1)",
    "variable": "var(--colors-violet-p3-a-1)"
  },
  "colors.violet.p3.a.2": {
    "value": "var(--colors-violet-p3-a-2)",
    "variable": "var(--colors-violet-p3-a-2)"
  },
  "colors.violet.p3.a.3": {
    "value": "var(--colors-violet-p3-a-3)",
    "variable": "var(--colors-violet-p3-a-3)"
  },
  "colors.violet.p3.a.4": {
    "value": "var(--colors-violet-p3-a-4)",
    "variable": "var(--colors-violet-p3-a-4)"
  },
  "colors.violet.p3.a.5": {
    "value": "var(--colors-violet-p3-a-5)",
    "variable": "var(--colors-violet-p3-a-5)"
  },
  "colors.violet.p3.a.6": {
    "value": "var(--colors-violet-p3-a-6)",
    "variable": "var(--colors-violet-p3-a-6)"
  },
  "colors.violet.p3.a.7": {
    "value": "var(--colors-violet-p3-a-7)",
    "variable": "var(--colors-violet-p3-a-7)"
  },
  "colors.violet.p3.a.8": {
    "value": "var(--colors-violet-p3-a-8)",
    "variable": "var(--colors-violet-p3-a-8)"
  },
  "colors.violet.p3.a.9": {
    "value": "var(--colors-violet-p3-a-9)",
    "variable": "var(--colors-violet-p3-a-9)"
  },
  "colors.violet.p3.a.10": {
    "value": "var(--colors-violet-p3-a-10)",
    "variable": "var(--colors-violet-p3-a-10)"
  },
  "colors.violet.p3.a.11": {
    "value": "var(--colors-violet-p3-a-11)",
    "variable": "var(--colors-violet-p3-a-11)"
  },
  "colors.violet.p3.a.12": {
    "value": "var(--colors-violet-p3-a-12)",
    "variable": "var(--colors-violet-p3-a-12)"
  },
  "colors.white.foreground": {
    "value": "var(--colors-white-foreground)",
    "variable": "var(--colors-white-foreground)"
  },
  "colors.white.foreground.soft": {
    "value": "var(--colors-white-foreground-soft)",
    "variable": "var(--colors-white-foreground-soft)"
  },
  "colors.white.foreground.softest": {
    "value": "var(--colors-white-foreground-softest)",
    "variable": "var(--colors-white-foreground-softest)"
  },
  "colors.white.light.a.1": {
    "value": "var(--colors-white-light-a-1)",
    "variable": "var(--colors-white-light-a-1)"
  },
  "colors.white.light.a.2": {
    "value": "var(--colors-white-light-a-2)",
    "variable": "var(--colors-white-light-a-2)"
  },
  "colors.white.light.a.3": {
    "value": "var(--colors-white-light-a-3)",
    "variable": "var(--colors-white-light-a-3)"
  },
  "colors.white.light.a.4": {
    "value": "var(--colors-white-light-a-4)",
    "variable": "var(--colors-white-light-a-4)"
  },
  "colors.white.light.a.5": {
    "value": "var(--colors-white-light-a-5)",
    "variable": "var(--colors-white-light-a-5)"
  },
  "colors.white.light.a.6": {
    "value": "var(--colors-white-light-a-6)",
    "variable": "var(--colors-white-light-a-6)"
  },
  "colors.white.light.a.7": {
    "value": "var(--colors-white-light-a-7)",
    "variable": "var(--colors-white-light-a-7)"
  },
  "colors.white.light.a.8": {
    "value": "var(--colors-white-light-a-8)",
    "variable": "var(--colors-white-light-a-8)"
  },
  "colors.white.light.a.9": {
    "value": "var(--colors-white-light-a-9)",
    "variable": "var(--colors-white-light-a-9)"
  },
  "colors.white.light.a.10": {
    "value": "var(--colors-white-light-a-10)",
    "variable": "var(--colors-white-light-a-10)"
  },
  "colors.white.light.a.11": {
    "value": "var(--colors-white-light-a-11)",
    "variable": "var(--colors-white-light-a-11)"
  },
  "colors.white.light.a.12": {
    "value": "var(--colors-white-light-a-12)",
    "variable": "var(--colors-white-light-a-12)"
  },
  "colors.white.light.p3.a.1": {
    "value": "color(display-p3 1 1 1 / 0.05)",
    "variable": "var(--colors-white-light-p3-a-1)"
  },
  "colors.white.light.p3.a.2": {
    "value": "color(display-p3 1 1 1 / 0.1)",
    "variable": "var(--colors-white-light-p3-a-2)"
  },
  "colors.white.light.p3.a.3": {
    "value": "color(display-p3 1 1 1 / 0.15)",
    "variable": "var(--colors-white-light-p3-a-3)"
  },
  "colors.white.light.p3.a.4": {
    "value": "color(display-p3 1 1 1 / 0.2)",
    "variable": "var(--colors-white-light-p3-a-4)"
  },
  "colors.white.light.p3.a.5": {
    "value": "color(display-p3 1 1 1 / 0.3)",
    "variable": "var(--colors-white-light-p3-a-5)"
  },
  "colors.white.light.p3.a.6": {
    "value": "color(display-p3 1 1 1 / 0.4)",
    "variable": "var(--colors-white-light-p3-a-6)"
  },
  "colors.white.light.p3.a.7": {
    "value": "color(display-p3 1 1 1 / 0.5)",
    "variable": "var(--colors-white-light-p3-a-7)"
  },
  "colors.white.light.p3.a.8": {
    "value": "color(display-p3 1 1 1 / 0.6)",
    "variable": "var(--colors-white-light-p3-a-8)"
  },
  "colors.white.light.p3.a.9": {
    "value": "color(display-p3 1 1 1 / 0.7)",
    "variable": "var(--colors-white-light-p3-a-9)"
  },
  "colors.white.light.p3.a.10": {
    "value": "color(display-p3 1 1 1 / 0.8)",
    "variable": "var(--colors-white-light-p3-a-10)"
  },
  "colors.white.light.p3.a.11": {
    "value": "color(display-p3 1 1 1 / 0.9)",
    "variable": "var(--colors-white-light-p3-a-11)"
  },
  "colors.white.light.p3.a.12": {
    "value": "color(display-p3 1 1 1 / 0.95)",
    "variable": "var(--colors-white-light-p3-a-12)"
  },
  "colors.white.a.1": {
    "value": "var(--colors-white-a-1)",
    "variable": "var(--colors-white-a-1)"
  },
  "colors.white.a.2": {
    "value": "var(--colors-white-a-2)",
    "variable": "var(--colors-white-a-2)"
  },
  "colors.white.a.3": {
    "value": "var(--colors-white-a-3)",
    "variable": "var(--colors-white-a-3)"
  },
  "colors.white.a.4": {
    "value": "var(--colors-white-a-4)",
    "variable": "var(--colors-white-a-4)"
  },
  "colors.white.a.5": {
    "value": "var(--colors-white-a-5)",
    "variable": "var(--colors-white-a-5)"
  },
  "colors.white.a.6": {
    "value": "var(--colors-white-a-6)",
    "variable": "var(--colors-white-a-6)"
  },
  "colors.white.a.7": {
    "value": "var(--colors-white-a-7)",
    "variable": "var(--colors-white-a-7)"
  },
  "colors.white.a.8": {
    "value": "var(--colors-white-a-8)",
    "variable": "var(--colors-white-a-8)"
  },
  "colors.white.a.9": {
    "value": "var(--colors-white-a-9)",
    "variable": "var(--colors-white-a-9)"
  },
  "colors.white.a.10": {
    "value": "var(--colors-white-a-10)",
    "variable": "var(--colors-white-a-10)"
  },
  "colors.white.a.11": {
    "value": "var(--colors-white-a-11)",
    "variable": "var(--colors-white-a-11)"
  },
  "colors.white.a.12": {
    "value": "var(--colors-white-a-12)",
    "variable": "var(--colors-white-a-12)"
  },
  "colors.white.p3.a.1": {
    "value": "color(display-p3 1 1 1 / 0.05)",
    "variable": "var(--colors-white-p3-a-1)"
  },
  "colors.white.p3.a.2": {
    "value": "color(display-p3 1 1 1 / 0.1)",
    "variable": "var(--colors-white-p3-a-2)"
  },
  "colors.white.p3.a.3": {
    "value": "color(display-p3 1 1 1 / 0.15)",
    "variable": "var(--colors-white-p3-a-3)"
  },
  "colors.white.p3.a.4": {
    "value": "color(display-p3 1 1 1 / 0.2)",
    "variable": "var(--colors-white-p3-a-4)"
  },
  "colors.white.p3.a.5": {
    "value": "color(display-p3 1 1 1 / 0.3)",
    "variable": "var(--colors-white-p3-a-5)"
  },
  "colors.white.p3.a.6": {
    "value": "color(display-p3 1 1 1 / 0.4)",
    "variable": "var(--colors-white-p3-a-6)"
  },
  "colors.white.p3.a.7": {
    "value": "color(display-p3 1 1 1 / 0.5)",
    "variable": "var(--colors-white-p3-a-7)"
  },
  "colors.white.p3.a.8": {
    "value": "color(display-p3 1 1 1 / 0.6)",
    "variable": "var(--colors-white-p3-a-8)"
  },
  "colors.white.p3.a.9": {
    "value": "color(display-p3 1 1 1 / 0.7)",
    "variable": "var(--colors-white-p3-a-9)"
  },
  "colors.white.p3.a.10": {
    "value": "color(display-p3 1 1 1 / 0.8)",
    "variable": "var(--colors-white-p3-a-10)"
  },
  "colors.white.p3.a.11": {
    "value": "color(display-p3 1 1 1 / 0.9)",
    "variable": "var(--colors-white-p3-a-11)"
  },
  "colors.white.p3.a.12": {
    "value": "color(display-p3 1 1 1 / 0.95)",
    "variable": "var(--colors-white-p3-a-12)"
  },
  "colors.yellow.1": {
    "value": "var(--colors-yellow-1)",
    "variable": "var(--colors-yellow-1)"
  },
  "colors.yellow.2": {
    "value": "var(--colors-yellow-2)",
    "variable": "var(--colors-yellow-2)"
  },
  "colors.yellow.3": {
    "value": "var(--colors-yellow-3)",
    "variable": "var(--colors-yellow-3)"
  },
  "colors.yellow.4": {
    "value": "var(--colors-yellow-4)",
    "variable": "var(--colors-yellow-4)"
  },
  "colors.yellow.5": {
    "value": "var(--colors-yellow-5)",
    "variable": "var(--colors-yellow-5)"
  },
  "colors.yellow.6": {
    "value": "var(--colors-yellow-6)",
    "variable": "var(--colors-yellow-6)"
  },
  "colors.yellow.7": {
    "value": "var(--colors-yellow-7)",
    "variable": "var(--colors-yellow-7)"
  },
  "colors.yellow.8": {
    "value": "var(--colors-yellow-8)",
    "variable": "var(--colors-yellow-8)"
  },
  "colors.yellow.9": {
    "value": "var(--colors-yellow-9)",
    "variable": "var(--colors-yellow-9)"
  },
  "colors.yellow.10": {
    "value": "var(--colors-yellow-10)",
    "variable": "var(--colors-yellow-10)"
  },
  "colors.yellow.11": {
    "value": "var(--colors-yellow-11)",
    "variable": "var(--colors-yellow-11)"
  },
  "colors.yellow.12": {
    "value": "var(--colors-yellow-12)",
    "variable": "var(--colors-yellow-12)"
  },
  "colors.yellow.light.1": {
    "value": "var(--colors-yellow-light-1)",
    "variable": "var(--colors-yellow-light-1)"
  },
  "colors.yellow.light.2": {
    "value": "var(--colors-yellow-light-2)",
    "variable": "var(--colors-yellow-light-2)"
  },
  "colors.yellow.light.3": {
    "value": "var(--colors-yellow-light-3)",
    "variable": "var(--colors-yellow-light-3)"
  },
  "colors.yellow.light.4": {
    "value": "var(--colors-yellow-light-4)",
    "variable": "var(--colors-yellow-light-4)"
  },
  "colors.yellow.light.5": {
    "value": "var(--colors-yellow-light-5)",
    "variable": "var(--colors-yellow-light-5)"
  },
  "colors.yellow.light.6": {
    "value": "var(--colors-yellow-light-6)",
    "variable": "var(--colors-yellow-light-6)"
  },
  "colors.yellow.light.7": {
    "value": "var(--colors-yellow-light-7)",
    "variable": "var(--colors-yellow-light-7)"
  },
  "colors.yellow.light.8": {
    "value": "var(--colors-yellow-light-8)",
    "variable": "var(--colors-yellow-light-8)"
  },
  "colors.yellow.light.9": {
    "value": "var(--colors-yellow-light-9)",
    "variable": "var(--colors-yellow-light-9)"
  },
  "colors.yellow.light.10": {
    "value": "var(--colors-yellow-light-10)",
    "variable": "var(--colors-yellow-light-10)"
  },
  "colors.yellow.light.11": {
    "value": "var(--colors-yellow-light-11)",
    "variable": "var(--colors-yellow-light-11)"
  },
  "colors.yellow.light.12": {
    "value": "var(--colors-yellow-light-12)",
    "variable": "var(--colors-yellow-light-12)"
  },
  "colors.yellow.light.a.1": {
    "value": "var(--colors-yellow-light-a-1)",
    "variable": "var(--colors-yellow-light-a-1)"
  },
  "colors.yellow.light.a.2": {
    "value": "var(--colors-yellow-light-a-2)",
    "variable": "var(--colors-yellow-light-a-2)"
  },
  "colors.yellow.light.a.3": {
    "value": "var(--colors-yellow-light-a-3)",
    "variable": "var(--colors-yellow-light-a-3)"
  },
  "colors.yellow.light.a.4": {
    "value": "var(--colors-yellow-light-a-4)",
    "variable": "var(--colors-yellow-light-a-4)"
  },
  "colors.yellow.light.a.5": {
    "value": "var(--colors-yellow-light-a-5)",
    "variable": "var(--colors-yellow-light-a-5)"
  },
  "colors.yellow.light.a.6": {
    "value": "var(--colors-yellow-light-a-6)",
    "variable": "var(--colors-yellow-light-a-6)"
  },
  "colors.yellow.light.a.7": {
    "value": "var(--colors-yellow-light-a-7)",
    "variable": "var(--colors-yellow-light-a-7)"
  },
  "colors.yellow.light.a.8": {
    "value": "var(--colors-yellow-light-a-8)",
    "variable": "var(--colors-yellow-light-a-8)"
  },
  "colors.yellow.light.a.9": {
    "value": "var(--colors-yellow-light-a-9)",
    "variable": "var(--colors-yellow-light-a-9)"
  },
  "colors.yellow.light.a.10": {
    "value": "var(--colors-yellow-light-a-10)",
    "variable": "var(--colors-yellow-light-a-10)"
  },
  "colors.yellow.light.a.11": {
    "value": "var(--colors-yellow-light-a-11)",
    "variable": "var(--colors-yellow-light-a-11)"
  },
  "colors.yellow.light.a.12": {
    "value": "var(--colors-yellow-light-a-12)",
    "variable": "var(--colors-yellow-light-a-12)"
  },
  "colors.yellow.light.p3.1": {
    "value": "color(display-p3 0.992 0.992 0.978)",
    "variable": "var(--colors-yellow-light-p3-1)"
  },
  "colors.yellow.light.p3.2": {
    "value": "color(display-p3 0.995 0.99 0.922)",
    "variable": "var(--colors-yellow-light-p3-2)"
  },
  "colors.yellow.light.p3.3": {
    "value": "color(display-p3 0.997 0.982 0.749)",
    "variable": "var(--colors-yellow-light-p3-3)"
  },
  "colors.yellow.light.p3.4": {
    "value": "color(display-p3 0.992 0.953 0.627)",
    "variable": "var(--colors-yellow-light-p3-4)"
  },
  "colors.yellow.light.p3.5": {
    "value": "color(display-p3 0.984 0.91 0.51)",
    "variable": "var(--colors-yellow-light-p3-5)"
  },
  "colors.yellow.light.p3.6": {
    "value": "color(display-p3 0.934 0.847 0.474)",
    "variable": "var(--colors-yellow-light-p3-6)"
  },
  "colors.yellow.light.p3.7": {
    "value": "color(display-p3 0.876 0.785 0.46)",
    "variable": "var(--colors-yellow-light-p3-7)"
  },
  "colors.yellow.light.p3.8": {
    "value": "color(display-p3 0.811 0.689 0.313)",
    "variable": "var(--colors-yellow-light-p3-8)"
  },
  "colors.yellow.light.p3.9": {
    "value": "color(display-p3 1 0.92 0.22)",
    "variable": "var(--colors-yellow-light-p3-9)"
  },
  "colors.yellow.light.p3.10": {
    "value": "color(display-p3 0.977 0.868 0.291)",
    "variable": "var(--colors-yellow-light-p3-10)"
  },
  "colors.yellow.light.p3.11": {
    "value": "color(display-p3 0.6 0.44 0)",
    "variable": "var(--colors-yellow-light-p3-11)"
  },
  "colors.yellow.light.p3.12": {
    "value": "color(display-p3 0.271 0.233 0.137)",
    "variable": "var(--colors-yellow-light-p3-12)"
  },
  "colors.yellow.light.p3.a.1": {
    "value": "color(display-p3 0.675 0.675 0.024 / 0.024)",
    "variable": "var(--colors-yellow-light-p3-a-1)"
  },
  "colors.yellow.light.p3.a.2": {
    "value": "color(display-p3 0.953 0.855 0.008 / 0.079)",
    "variable": "var(--colors-yellow-light-p3-a-2)"
  },
  "colors.yellow.light.p3.a.3": {
    "value": "color(display-p3 0.988 0.925 0.004 / 0.251)",
    "variable": "var(--colors-yellow-light-p3-a-3)"
  },
  "colors.yellow.light.p3.a.4": {
    "value": "color(display-p3 0.98 0.875 0.004 / 0.373)",
    "variable": "var(--colors-yellow-light-p3-a-4)"
  },
  "colors.yellow.light.p3.a.5": {
    "value": "color(display-p3 0.969 0.816 0.004 / 0.491)",
    "variable": "var(--colors-yellow-light-p3-a-5)"
  },
  "colors.yellow.light.p3.a.6": {
    "value": "color(display-p3 0.875 0.71 0 / 0.526)",
    "variable": "var(--colors-yellow-light-p3-a-6)"
  },
  "colors.yellow.light.p3.a.7": {
    "value": "color(display-p3 0.769 0.604 0 / 0.542)",
    "variable": "var(--colors-yellow-light-p3-a-7)"
  },
  "colors.yellow.light.p3.a.8": {
    "value": "color(display-p3 0.725 0.549 0 / 0.687)",
    "variable": "var(--colors-yellow-light-p3-a-8)"
  },
  "colors.yellow.light.p3.a.9": {
    "value": "color(display-p3 1 0.898 0 / 0.781)",
    "variable": "var(--colors-yellow-light-p3-a-9)"
  },
  "colors.yellow.light.p3.a.10": {
    "value": "color(display-p3 0.969 0.812 0 / 0.71)",
    "variable": "var(--colors-yellow-light-p3-a-10)"
  },
  "colors.yellow.light.p3.a.11": {
    "value": "color(display-p3 0.6 0.44 0)",
    "variable": "var(--colors-yellow-light-p3-a-11)"
  },
  "colors.yellow.light.p3.a.12": {
    "value": "color(display-p3 0.271 0.233 0.137)",
    "variable": "var(--colors-yellow-light-p3-a-12)"
  },
  "colors.yellow.dark.1": {
    "value": "var(--colors-yellow-dark-1)",
    "variable": "var(--colors-yellow-dark-1)"
  },
  "colors.yellow.dark.2": {
    "value": "var(--colors-yellow-dark-2)",
    "variable": "var(--colors-yellow-dark-2)"
  },
  "colors.yellow.dark.3": {
    "value": "var(--colors-yellow-dark-3)",
    "variable": "var(--colors-yellow-dark-3)"
  },
  "colors.yellow.dark.4": {
    "value": "var(--colors-yellow-dark-4)",
    "variable": "var(--colors-yellow-dark-4)"
  },
  "colors.yellow.dark.5": {
    "value": "var(--colors-yellow-dark-5)",
    "variable": "var(--colors-yellow-dark-5)"
  },
  "colors.yellow.dark.6": {
    "value": "var(--colors-yellow-dark-6)",
    "variable": "var(--colors-yellow-dark-6)"
  },
  "colors.yellow.dark.7": {
    "value": "var(--colors-yellow-dark-7)",
    "variable": "var(--colors-yellow-dark-7)"
  },
  "colors.yellow.dark.8": {
    "value": "var(--colors-yellow-dark-8)",
    "variable": "var(--colors-yellow-dark-8)"
  },
  "colors.yellow.dark.9": {
    "value": "var(--colors-yellow-dark-9)",
    "variable": "var(--colors-yellow-dark-9)"
  },
  "colors.yellow.dark.10": {
    "value": "var(--colors-yellow-dark-10)",
    "variable": "var(--colors-yellow-dark-10)"
  },
  "colors.yellow.dark.11": {
    "value": "var(--colors-yellow-dark-11)",
    "variable": "var(--colors-yellow-dark-11)"
  },
  "colors.yellow.dark.12": {
    "value": "var(--colors-yellow-dark-12)",
    "variable": "var(--colors-yellow-dark-12)"
  },
  "colors.yellow.dark.a.1": {
    "value": "var(--colors-yellow-dark-a-1)",
    "variable": "var(--colors-yellow-dark-a-1)"
  },
  "colors.yellow.dark.a.2": {
    "value": "var(--colors-yellow-dark-a-2)",
    "variable": "var(--colors-yellow-dark-a-2)"
  },
  "colors.yellow.dark.a.3": {
    "value": "var(--colors-yellow-dark-a-3)",
    "variable": "var(--colors-yellow-dark-a-3)"
  },
  "colors.yellow.dark.a.4": {
    "value": "var(--colors-yellow-dark-a-4)",
    "variable": "var(--colors-yellow-dark-a-4)"
  },
  "colors.yellow.dark.a.5": {
    "value": "var(--colors-yellow-dark-a-5)",
    "variable": "var(--colors-yellow-dark-a-5)"
  },
  "colors.yellow.dark.a.6": {
    "value": "var(--colors-yellow-dark-a-6)",
    "variable": "var(--colors-yellow-dark-a-6)"
  },
  "colors.yellow.dark.a.7": {
    "value": "var(--colors-yellow-dark-a-7)",
    "variable": "var(--colors-yellow-dark-a-7)"
  },
  "colors.yellow.dark.a.8": {
    "value": "var(--colors-yellow-dark-a-8)",
    "variable": "var(--colors-yellow-dark-a-8)"
  },
  "colors.yellow.dark.a.9": {
    "value": "var(--colors-yellow-dark-a-9)",
    "variable": "var(--colors-yellow-dark-a-9)"
  },
  "colors.yellow.dark.a.10": {
    "value": "var(--colors-yellow-dark-a-10)",
    "variable": "var(--colors-yellow-dark-a-10)"
  },
  "colors.yellow.dark.a.11": {
    "value": "var(--colors-yellow-dark-a-11)",
    "variable": "var(--colors-yellow-dark-a-11)"
  },
  "colors.yellow.dark.a.12": {
    "value": "var(--colors-yellow-dark-a-12)",
    "variable": "var(--colors-yellow-dark-a-12)"
  },
  "colors.yellow.dark.p3.1": {
    "value": "color(display-p3 0.078 0.069 0.047)",
    "variable": "var(--colors-yellow-dark-p3-1)"
  },
  "colors.yellow.dark.p3.2": {
    "value": "color(display-p3 0.103 0.094 0.063)",
    "variable": "var(--colors-yellow-dark-p3-2)"
  },
  "colors.yellow.dark.p3.3": {
    "value": "color(display-p3 0.168 0.137 0.039)",
    "variable": "var(--colors-yellow-dark-p3-3)"
  },
  "colors.yellow.dark.p3.4": {
    "value": "color(display-p3 0.209 0.169 0)",
    "variable": "var(--colors-yellow-dark-p3-4)"
  },
  "colors.yellow.dark.p3.5": {
    "value": "color(display-p3 0.255 0.209 0)",
    "variable": "var(--colors-yellow-dark-p3-5)"
  },
  "colors.yellow.dark.p3.6": {
    "value": "color(display-p3 0.31 0.261 0.07)",
    "variable": "var(--colors-yellow-dark-p3-6)"
  },
  "colors.yellow.dark.p3.7": {
    "value": "color(display-p3 0.389 0.331 0.135)",
    "variable": "var(--colors-yellow-dark-p3-7)"
  },
  "colors.yellow.dark.p3.8": {
    "value": "color(display-p3 0.497 0.42 0.182)",
    "variable": "var(--colors-yellow-dark-p3-8)"
  },
  "colors.yellow.dark.p3.9": {
    "value": "color(display-p3 1 0.92 0.22)",
    "variable": "var(--colors-yellow-dark-p3-9)"
  },
  "colors.yellow.dark.p3.10": {
    "value": "color(display-p3 1 1 0.456)",
    "variable": "var(--colors-yellow-dark-p3-10)"
  },
  "colors.yellow.dark.p3.11": {
    "value": "color(display-p3 0.948 0.885 0.392)",
    "variable": "var(--colors-yellow-dark-p3-11)"
  },
  "colors.yellow.dark.p3.12": {
    "value": "color(display-p3 0.959 0.934 0.731)",
    "variable": "var(--colors-yellow-dark-p3-12)"
  },
  "colors.yellow.dark.p3.a.1": {
    "value": "color(display-p3 0.973 0.369 0 / 0.013)",
    "variable": "var(--colors-yellow-dark-p3-a-1)"
  },
  "colors.yellow.dark.p3.a.2": {
    "value": "color(display-p3 0.996 0.792 0 / 0.038)",
    "variable": "var(--colors-yellow-dark-p3-a-2)"
  },
  "colors.yellow.dark.p3.a.3": {
    "value": "color(display-p3 0.996 0.71 0 / 0.11)",
    "variable": "var(--colors-yellow-dark-p3-a-3)"
  },
  "colors.yellow.dark.p3.a.4": {
    "value": "color(display-p3 0.996 0.741 0 / 0.152)",
    "variable": "var(--colors-yellow-dark-p3-a-4)"
  },
  "colors.yellow.dark.p3.a.5": {
    "value": "color(display-p3 0.996 0.765 0 / 0.202)",
    "variable": "var(--colors-yellow-dark-p3-a-5)"
  },
  "colors.yellow.dark.p3.a.6": {
    "value": "color(display-p3 0.996 0.816 0.082 / 0.261)",
    "variable": "var(--colors-yellow-dark-p3-a-6)"
  },
  "colors.yellow.dark.p3.a.7": {
    "value": "color(display-p3 1 0.831 0.263 / 0.345)",
    "variable": "var(--colors-yellow-dark-p3-a-7)"
  },
  "colors.yellow.dark.p3.a.8": {
    "value": "color(display-p3 1 0.831 0.314 / 0.463)",
    "variable": "var(--colors-yellow-dark-p3-a-8)"
  },
  "colors.yellow.dark.p3.a.9": {
    "value": "color(display-p3 1 0.922 0.22)",
    "variable": "var(--colors-yellow-dark-p3-a-9)"
  },
  "colors.yellow.dark.p3.a.10": {
    "value": "color(display-p3 1 1 0.455)",
    "variable": "var(--colors-yellow-dark-p3-a-10)"
  },
  "colors.yellow.dark.p3.a.11": {
    "value": "color(display-p3 0.948 0.885 0.392)",
    "variable": "var(--colors-yellow-dark-p3-a-11)"
  },
  "colors.yellow.dark.p3.a.12": {
    "value": "color(display-p3 0.959 0.934 0.731)",
    "variable": "var(--colors-yellow-dark-p3-a-12)"
  },
  "colors.yellow.a.1": {
    "value": "var(--colors-yellow-a-1)",
    "variable": "var(--colors-yellow-a-1)"
  },
  "colors.yellow.a.2": {
    "value": "var(--colors-yellow-a-2)",
    "variable": "var(--colors-yellow-a-2)"
  },
  "colors.yellow.a.3": {
    "value": "var(--colors-yellow-a-3)",
    "variable": "var(--colors-yellow-a-3)"
  },
  "colors.yellow.a.4": {
    "value": "var(--colors-yellow-a-4)",
    "variable": "var(--colors-yellow-a-4)"
  },
  "colors.yellow.a.5": {
    "value": "var(--colors-yellow-a-5)",
    "variable": "var(--colors-yellow-a-5)"
  },
  "colors.yellow.a.6": {
    "value": "var(--colors-yellow-a-6)",
    "variable": "var(--colors-yellow-a-6)"
  },
  "colors.yellow.a.7": {
    "value": "var(--colors-yellow-a-7)",
    "variable": "var(--colors-yellow-a-7)"
  },
  "colors.yellow.a.8": {
    "value": "var(--colors-yellow-a-8)",
    "variable": "var(--colors-yellow-a-8)"
  },
  "colors.yellow.a.9": {
    "value": "var(--colors-yellow-a-9)",
    "variable": "var(--colors-yellow-a-9)"
  },
  "colors.yellow.a.10": {
    "value": "var(--colors-yellow-a-10)",
    "variable": "var(--colors-yellow-a-10)"
  },
  "colors.yellow.a.11": {
    "value": "var(--colors-yellow-a-11)",
    "variable": "var(--colors-yellow-a-11)"
  },
  "colors.yellow.a.12": {
    "value": "var(--colors-yellow-a-12)",
    "variable": "var(--colors-yellow-a-12)"
  },
  "colors.yellow.p3.1": {
    "value": "var(--colors-yellow-p3-1)",
    "variable": "var(--colors-yellow-p3-1)"
  },
  "colors.yellow.p3.2": {
    "value": "var(--colors-yellow-p3-2)",
    "variable": "var(--colors-yellow-p3-2)"
  },
  "colors.yellow.p3.3": {
    "value": "var(--colors-yellow-p3-3)",
    "variable": "var(--colors-yellow-p3-3)"
  },
  "colors.yellow.p3.4": {
    "value": "var(--colors-yellow-p3-4)",
    "variable": "var(--colors-yellow-p3-4)"
  },
  "colors.yellow.p3.5": {
    "value": "var(--colors-yellow-p3-5)",
    "variable": "var(--colors-yellow-p3-5)"
  },
  "colors.yellow.p3.6": {
    "value": "var(--colors-yellow-p3-6)",
    "variable": "var(--colors-yellow-p3-6)"
  },
  "colors.yellow.p3.7": {
    "value": "var(--colors-yellow-p3-7)",
    "variable": "var(--colors-yellow-p3-7)"
  },
  "colors.yellow.p3.8": {
    "value": "var(--colors-yellow-p3-8)",
    "variable": "var(--colors-yellow-p3-8)"
  },
  "colors.yellow.p3.9": {
    "value": "var(--colors-yellow-p3-9)",
    "variable": "var(--colors-yellow-p3-9)"
  },
  "colors.yellow.p3.10": {
    "value": "var(--colors-yellow-p3-10)",
    "variable": "var(--colors-yellow-p3-10)"
  },
  "colors.yellow.p3.11": {
    "value": "var(--colors-yellow-p3-11)",
    "variable": "var(--colors-yellow-p3-11)"
  },
  "colors.yellow.p3.12": {
    "value": "var(--colors-yellow-p3-12)",
    "variable": "var(--colors-yellow-p3-12)"
  },
  "colors.yellow.p3.a.1": {
    "value": "var(--colors-yellow-p3-a-1)",
    "variable": "var(--colors-yellow-p3-a-1)"
  },
  "colors.yellow.p3.a.2": {
    "value": "var(--colors-yellow-p3-a-2)",
    "variable": "var(--colors-yellow-p3-a-2)"
  },
  "colors.yellow.p3.a.3": {
    "value": "var(--colors-yellow-p3-a-3)",
    "variable": "var(--colors-yellow-p3-a-3)"
  },
  "colors.yellow.p3.a.4": {
    "value": "var(--colors-yellow-p3-a-4)",
    "variable": "var(--colors-yellow-p3-a-4)"
  },
  "colors.yellow.p3.a.5": {
    "value": "var(--colors-yellow-p3-a-5)",
    "variable": "var(--colors-yellow-p3-a-5)"
  },
  "colors.yellow.p3.a.6": {
    "value": "var(--colors-yellow-p3-a-6)",
    "variable": "var(--colors-yellow-p3-a-6)"
  },
  "colors.yellow.p3.a.7": {
    "value": "var(--colors-yellow-p3-a-7)",
    "variable": "var(--colors-yellow-p3-a-7)"
  },
  "colors.yellow.p3.a.8": {
    "value": "var(--colors-yellow-p3-a-8)",
    "variable": "var(--colors-yellow-p3-a-8)"
  },
  "colors.yellow.p3.a.9": {
    "value": "var(--colors-yellow-p3-a-9)",
    "variable": "var(--colors-yellow-p3-a-9)"
  },
  "colors.yellow.p3.a.10": {
    "value": "var(--colors-yellow-p3-a-10)",
    "variable": "var(--colors-yellow-p3-a-10)"
  },
  "colors.yellow.p3.a.11": {
    "value": "var(--colors-yellow-p3-a-11)",
    "variable": "var(--colors-yellow-p3-a-11)"
  },
  "colors.yellow.p3.a.12": {
    "value": "var(--colors-yellow-p3-a-12)",
    "variable": "var(--colors-yellow-p3-a-12)"
  },
  "spacing.-1": {
    "value": "calc(var(--spacing-1) * -1)",
    "variable": "var(--spacing-1)"
  },
  "spacing.-2": {
    "value": "calc(var(--spacing-2) * -1)",
    "variable": "var(--spacing-2)"
  },
  "spacing.-3": {
    "value": "calc(var(--spacing-3) * -1)",
    "variable": "var(--spacing-3)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-5": {
    "value": "calc(var(--spacing-5) * -1)",
    "variable": "var(--spacing-5)"
  },
  "spacing.-6": {
    "value": "calc(var(--spacing-6) * -1)",
    "variable": "var(--spacing-6)"
  },
  "spacing.-7": {
    "value": "calc(var(--spacing-7) * -1)",
    "variable": "var(--spacing-7)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-9": {
    "value": "calc(var(--spacing-9) * -1)",
    "variable": "var(--spacing-9)"
  },
  "spacing.-10": {
    "value": "calc(var(--spacing-10) * -1)",
    "variable": "var(--spacing-10)"
  },
  "spacing.-11": {
    "value": "calc(var(--spacing-11) * -1)",
    "variable": "var(--spacing-11)"
  },
  "spacing.-12": {
    "value": "calc(var(--spacing-12) * -1)",
    "variable": "var(--spacing-12)"
  },
  "spacing.-14": {
    "value": "calc(var(--spacing-14) * -1)",
    "variable": "var(--spacing-14)"
  },
  "spacing.-16": {
    "value": "calc(var(--spacing-16) * -1)",
    "variable": "var(--spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--spacing-20) * -1)",
    "variable": "var(--spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--spacing-24) * -1)",
    "variable": "var(--spacing-24)"
  },
  "spacing.-28": {
    "value": "calc(var(--spacing-28) * -1)",
    "variable": "var(--spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--spacing-32) * -1)",
    "variable": "var(--spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--spacing-36) * -1)",
    "variable": "var(--spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--spacing-40) * -1)",
    "variable": "var(--spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--spacing-44) * -1)",
    "variable": "var(--spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--spacing-48) * -1)",
    "variable": "var(--spacing-48)"
  },
  "spacing.-52": {
    "value": "calc(var(--spacing-52) * -1)",
    "variable": "var(--spacing-52)"
  },
  "spacing.-56": {
    "value": "calc(var(--spacing-56) * -1)",
    "variable": "var(--spacing-56)"
  },
  "spacing.-60": {
    "value": "calc(var(--spacing-60) * -1)",
    "variable": "var(--spacing-60)"
  },
  "spacing.-64": {
    "value": "calc(var(--spacing-64) * -1)",
    "variable": "var(--spacing-64)"
  },
  "spacing.-72": {
    "value": "calc(var(--spacing-72) * -1)",
    "variable": "var(--spacing-72)"
  },
  "spacing.-80": {
    "value": "calc(var(--spacing-80) * -1)",
    "variable": "var(--spacing-80)"
  },
  "spacing.-96": {
    "value": "calc(var(--spacing-96) * -1)",
    "variable": "var(--spacing-96)"
  },
  "spacing.-0.5": {
    "value": "calc(var(--spacing-0\\.5) * -1)",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.-1.5": {
    "value": "calc(var(--spacing-1\\.5) * -1)",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.-2.5": {
    "value": "calc(var(--spacing-2\\.5) * -1)",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.-3.5": {
    "value": "calc(var(--spacing-3\\.5) * -1)",
    "variable": "var(--spacing-3\\.5)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette.950": {
    "value": "var(--colors-color-palette-950)",
    "variable": "var(--colors-color-palette-950)"
  },
  "colors.colorPalette.background.solid": {
    "value": "var(--colors-color-palette-background-solid)",
    "variable": "var(--colors-color-palette-background-solid)"
  },
  "colors.colorPalette.solid": {
    "value": "var(--colors-color-palette-solid)",
    "variable": "var(--colors-color-palette-solid)"
  },
  "colors.colorPalette.background.solid.hover": {
    "value": "var(--colors-color-palette-background-solid-hover)",
    "variable": "var(--colors-color-palette-background-solid-hover)"
  },
  "colors.colorPalette.solid.hover": {
    "value": "var(--colors-color-palette-solid-hover)",
    "variable": "var(--colors-color-palette-solid-hover)"
  },
  "colors.colorPalette.hover": {
    "value": "var(--colors-color-palette-hover)",
    "variable": "var(--colors-color-palette-hover)"
  },
  "colors.colorPalette.background.solid.active": {
    "value": "var(--colors-color-palette-background-solid-active)",
    "variable": "var(--colors-color-palette-background-solid-active)"
  },
  "colors.colorPalette.solid.active": {
    "value": "var(--colors-color-palette-solid-active)",
    "variable": "var(--colors-color-palette-solid-active)"
  },
  "colors.colorPalette.active": {
    "value": "var(--colors-color-palette-active)",
    "variable": "var(--colors-color-palette-active)"
  },
  "colors.colorPalette.background.soft": {
    "value": "var(--colors-color-palette-background-soft)",
    "variable": "var(--colors-color-palette-background-soft)"
  },
  "colors.colorPalette.soft": {
    "value": "var(--colors-color-palette-soft)",
    "variable": "var(--colors-color-palette-soft)"
  },
  "colors.colorPalette.background.soft.hover": {
    "value": "var(--colors-color-palette-background-soft-hover)",
    "variable": "var(--colors-color-palette-background-soft-hover)"
  },
  "colors.colorPalette.soft.hover": {
    "value": "var(--colors-color-palette-soft-hover)",
    "variable": "var(--colors-color-palette-soft-hover)"
  },
  "colors.colorPalette.background.soft.active": {
    "value": "var(--colors-color-palette-background-soft-active)",
    "variable": "var(--colors-color-palette-background-soft-active)"
  },
  "colors.colorPalette.soft.active": {
    "value": "var(--colors-color-palette-soft-active)",
    "variable": "var(--colors-color-palette-soft-active)"
  },
  "colors.colorPalette.foreground": {
    "value": "var(--colors-color-palette-foreground)",
    "variable": "var(--colors-color-palette-foreground)"
  },
  "colors.colorPalette.foreground.soft": {
    "value": "var(--colors-color-palette-foreground-soft)",
    "variable": "var(--colors-color-palette-foreground-soft)"
  },
  "colors.colorPalette.foreground.softest": {
    "value": "var(--colors-color-palette-foreground-softest)",
    "variable": "var(--colors-color-palette-foreground-softest)"
  },
  "colors.colorPalette.softest": {
    "value": "var(--colors-color-palette-softest)",
    "variable": "var(--colors-color-palette-softest)"
  },
  "colors.colorPalette.foreground.on.solid": {
    "value": "var(--colors-color-palette-foreground-on-solid)",
    "variable": "var(--colors-color-palette-foreground-on-solid)"
  },
  "colors.colorPalette.on.solid": {
    "value": "var(--colors-color-palette-on-solid)",
    "variable": "var(--colors-color-palette-on-solid)"
  },
  "colors.colorPalette.border": {
    "value": "var(--colors-color-palette-border)",
    "variable": "var(--colors-color-palette-border)"
  },
  "colors.colorPalette.border.faded": {
    "value": "var(--colors-color-palette-border-faded)",
    "variable": "var(--colors-color-palette-border-faded)"
  },
  "colors.colorPalette.faded": {
    "value": "var(--colors-color-palette-faded)",
    "variable": "var(--colors-color-palette-faded)"
  },
  "colors.colorPalette.background": {
    "value": "var(--colors-color-palette-background)",
    "variable": "var(--colors-color-palette-background)"
  },
  "colors.colorPalette.page": {
    "value": "var(--colors-color-palette-page)",
    "variable": "var(--colors-color-palette-page)"
  },
  "colors.colorPalette.popover": {
    "value": "var(--colors-color-palette-popover)",
    "variable": "var(--colors-color-palette-popover)"
  },
  "colors.colorPalette.dialog": {
    "value": "var(--colors-color-palette-dialog)",
    "variable": "var(--colors-color-palette-dialog)"
  },
  "colors.colorPalette.1": {
    "value": "var(--colors-color-palette-1)",
    "variable": "var(--colors-color-palette-1)"
  },
  "colors.colorPalette.2": {
    "value": "var(--colors-color-palette-2)",
    "variable": "var(--colors-color-palette-2)"
  },
  "colors.colorPalette.3": {
    "value": "var(--colors-color-palette-3)",
    "variable": "var(--colors-color-palette-3)"
  },
  "colors.colorPalette.4": {
    "value": "var(--colors-color-palette-4)",
    "variable": "var(--colors-color-palette-4)"
  },
  "colors.colorPalette.5": {
    "value": "var(--colors-color-palette-5)",
    "variable": "var(--colors-color-palette-5)"
  },
  "colors.colorPalette.6": {
    "value": "var(--colors-color-palette-6)",
    "variable": "var(--colors-color-palette-6)"
  },
  "colors.colorPalette.7": {
    "value": "var(--colors-color-palette-7)",
    "variable": "var(--colors-color-palette-7)"
  },
  "colors.colorPalette.8": {
    "value": "var(--colors-color-palette-8)",
    "variable": "var(--colors-color-palette-8)"
  },
  "colors.colorPalette.9": {
    "value": "var(--colors-color-palette-9)",
    "variable": "var(--colors-color-palette-9)"
  },
  "colors.colorPalette.10": {
    "value": "var(--colors-color-palette-10)",
    "variable": "var(--colors-color-palette-10)"
  },
  "colors.colorPalette.11": {
    "value": "var(--colors-color-palette-11)",
    "variable": "var(--colors-color-palette-11)"
  },
  "colors.colorPalette.12": {
    "value": "var(--colors-color-palette-12)",
    "variable": "var(--colors-color-palette-12)"
  },
  "colors.colorPalette.light.1": {
    "value": "var(--colors-color-palette-light-1)",
    "variable": "var(--colors-color-palette-light-1)"
  },
  "colors.colorPalette.light.2": {
    "value": "var(--colors-color-palette-light-2)",
    "variable": "var(--colors-color-palette-light-2)"
  },
  "colors.colorPalette.light.3": {
    "value": "var(--colors-color-palette-light-3)",
    "variable": "var(--colors-color-palette-light-3)"
  },
  "colors.colorPalette.light.4": {
    "value": "var(--colors-color-palette-light-4)",
    "variable": "var(--colors-color-palette-light-4)"
  },
  "colors.colorPalette.light.5": {
    "value": "var(--colors-color-palette-light-5)",
    "variable": "var(--colors-color-palette-light-5)"
  },
  "colors.colorPalette.light.6": {
    "value": "var(--colors-color-palette-light-6)",
    "variable": "var(--colors-color-palette-light-6)"
  },
  "colors.colorPalette.light.7": {
    "value": "var(--colors-color-palette-light-7)",
    "variable": "var(--colors-color-palette-light-7)"
  },
  "colors.colorPalette.light.8": {
    "value": "var(--colors-color-palette-light-8)",
    "variable": "var(--colors-color-palette-light-8)"
  },
  "colors.colorPalette.light.9": {
    "value": "var(--colors-color-palette-light-9)",
    "variable": "var(--colors-color-palette-light-9)"
  },
  "colors.colorPalette.light.10": {
    "value": "var(--colors-color-palette-light-10)",
    "variable": "var(--colors-color-palette-light-10)"
  },
  "colors.colorPalette.light.11": {
    "value": "var(--colors-color-palette-light-11)",
    "variable": "var(--colors-color-palette-light-11)"
  },
  "colors.colorPalette.light.12": {
    "value": "var(--colors-color-palette-light-12)",
    "variable": "var(--colors-color-palette-light-12)"
  },
  "colors.colorPalette.light.a.1": {
    "value": "var(--colors-color-palette-light-a-1)",
    "variable": "var(--colors-color-palette-light-a-1)"
  },
  "colors.colorPalette.a.1": {
    "value": "var(--colors-color-palette-a-1)",
    "variable": "var(--colors-color-palette-a-1)"
  },
  "colors.colorPalette.light.a.2": {
    "value": "var(--colors-color-palette-light-a-2)",
    "variable": "var(--colors-color-palette-light-a-2)"
  },
  "colors.colorPalette.a.2": {
    "value": "var(--colors-color-palette-a-2)",
    "variable": "var(--colors-color-palette-a-2)"
  },
  "colors.colorPalette.light.a.3": {
    "value": "var(--colors-color-palette-light-a-3)",
    "variable": "var(--colors-color-palette-light-a-3)"
  },
  "colors.colorPalette.a.3": {
    "value": "var(--colors-color-palette-a-3)",
    "variable": "var(--colors-color-palette-a-3)"
  },
  "colors.colorPalette.light.a.4": {
    "value": "var(--colors-color-palette-light-a-4)",
    "variable": "var(--colors-color-palette-light-a-4)"
  },
  "colors.colorPalette.a.4": {
    "value": "var(--colors-color-palette-a-4)",
    "variable": "var(--colors-color-palette-a-4)"
  },
  "colors.colorPalette.light.a.5": {
    "value": "var(--colors-color-palette-light-a-5)",
    "variable": "var(--colors-color-palette-light-a-5)"
  },
  "colors.colorPalette.a.5": {
    "value": "var(--colors-color-palette-a-5)",
    "variable": "var(--colors-color-palette-a-5)"
  },
  "colors.colorPalette.light.a.6": {
    "value": "var(--colors-color-palette-light-a-6)",
    "variable": "var(--colors-color-palette-light-a-6)"
  },
  "colors.colorPalette.a.6": {
    "value": "var(--colors-color-palette-a-6)",
    "variable": "var(--colors-color-palette-a-6)"
  },
  "colors.colorPalette.light.a.7": {
    "value": "var(--colors-color-palette-light-a-7)",
    "variable": "var(--colors-color-palette-light-a-7)"
  },
  "colors.colorPalette.a.7": {
    "value": "var(--colors-color-palette-a-7)",
    "variable": "var(--colors-color-palette-a-7)"
  },
  "colors.colorPalette.light.a.8": {
    "value": "var(--colors-color-palette-light-a-8)",
    "variable": "var(--colors-color-palette-light-a-8)"
  },
  "colors.colorPalette.a.8": {
    "value": "var(--colors-color-palette-a-8)",
    "variable": "var(--colors-color-palette-a-8)"
  },
  "colors.colorPalette.light.a.9": {
    "value": "var(--colors-color-palette-light-a-9)",
    "variable": "var(--colors-color-palette-light-a-9)"
  },
  "colors.colorPalette.a.9": {
    "value": "var(--colors-color-palette-a-9)",
    "variable": "var(--colors-color-palette-a-9)"
  },
  "colors.colorPalette.light.a.10": {
    "value": "var(--colors-color-palette-light-a-10)",
    "variable": "var(--colors-color-palette-light-a-10)"
  },
  "colors.colorPalette.a.10": {
    "value": "var(--colors-color-palette-a-10)",
    "variable": "var(--colors-color-palette-a-10)"
  },
  "colors.colorPalette.light.a.11": {
    "value": "var(--colors-color-palette-light-a-11)",
    "variable": "var(--colors-color-palette-light-a-11)"
  },
  "colors.colorPalette.a.11": {
    "value": "var(--colors-color-palette-a-11)",
    "variable": "var(--colors-color-palette-a-11)"
  },
  "colors.colorPalette.light.a.12": {
    "value": "var(--colors-color-palette-light-a-12)",
    "variable": "var(--colors-color-palette-light-a-12)"
  },
  "colors.colorPalette.a.12": {
    "value": "var(--colors-color-palette-a-12)",
    "variable": "var(--colors-color-palette-a-12)"
  },
  "colors.colorPalette.light.p3.1": {
    "value": "var(--colors-color-palette-light-p3-1)",
    "variable": "var(--colors-color-palette-light-p3-1)"
  },
  "colors.colorPalette.p3.1": {
    "value": "var(--colors-color-palette-p3-1)",
    "variable": "var(--colors-color-palette-p3-1)"
  },
  "colors.colorPalette.light.p3.2": {
    "value": "var(--colors-color-palette-light-p3-2)",
    "variable": "var(--colors-color-palette-light-p3-2)"
  },
  "colors.colorPalette.p3.2": {
    "value": "var(--colors-color-palette-p3-2)",
    "variable": "var(--colors-color-palette-p3-2)"
  },
  "colors.colorPalette.light.p3.3": {
    "value": "var(--colors-color-palette-light-p3-3)",
    "variable": "var(--colors-color-palette-light-p3-3)"
  },
  "colors.colorPalette.p3.3": {
    "value": "var(--colors-color-palette-p3-3)",
    "variable": "var(--colors-color-palette-p3-3)"
  },
  "colors.colorPalette.light.p3.4": {
    "value": "var(--colors-color-palette-light-p3-4)",
    "variable": "var(--colors-color-palette-light-p3-4)"
  },
  "colors.colorPalette.p3.4": {
    "value": "var(--colors-color-palette-p3-4)",
    "variable": "var(--colors-color-palette-p3-4)"
  },
  "colors.colorPalette.light.p3.5": {
    "value": "var(--colors-color-palette-light-p3-5)",
    "variable": "var(--colors-color-palette-light-p3-5)"
  },
  "colors.colorPalette.p3.5": {
    "value": "var(--colors-color-palette-p3-5)",
    "variable": "var(--colors-color-palette-p3-5)"
  },
  "colors.colorPalette.light.p3.6": {
    "value": "var(--colors-color-palette-light-p3-6)",
    "variable": "var(--colors-color-palette-light-p3-6)"
  },
  "colors.colorPalette.p3.6": {
    "value": "var(--colors-color-palette-p3-6)",
    "variable": "var(--colors-color-palette-p3-6)"
  },
  "colors.colorPalette.light.p3.7": {
    "value": "var(--colors-color-palette-light-p3-7)",
    "variable": "var(--colors-color-palette-light-p3-7)"
  },
  "colors.colorPalette.p3.7": {
    "value": "var(--colors-color-palette-p3-7)",
    "variable": "var(--colors-color-palette-p3-7)"
  },
  "colors.colorPalette.light.p3.8": {
    "value": "var(--colors-color-palette-light-p3-8)",
    "variable": "var(--colors-color-palette-light-p3-8)"
  },
  "colors.colorPalette.p3.8": {
    "value": "var(--colors-color-palette-p3-8)",
    "variable": "var(--colors-color-palette-p3-8)"
  },
  "colors.colorPalette.light.p3.9": {
    "value": "var(--colors-color-palette-light-p3-9)",
    "variable": "var(--colors-color-palette-light-p3-9)"
  },
  "colors.colorPalette.p3.9": {
    "value": "var(--colors-color-palette-p3-9)",
    "variable": "var(--colors-color-palette-p3-9)"
  },
  "colors.colorPalette.light.p3.10": {
    "value": "var(--colors-color-palette-light-p3-10)",
    "variable": "var(--colors-color-palette-light-p3-10)"
  },
  "colors.colorPalette.p3.10": {
    "value": "var(--colors-color-palette-p3-10)",
    "variable": "var(--colors-color-palette-p3-10)"
  },
  "colors.colorPalette.light.p3.11": {
    "value": "var(--colors-color-palette-light-p3-11)",
    "variable": "var(--colors-color-palette-light-p3-11)"
  },
  "colors.colorPalette.p3.11": {
    "value": "var(--colors-color-palette-p3-11)",
    "variable": "var(--colors-color-palette-p3-11)"
  },
  "colors.colorPalette.light.p3.12": {
    "value": "var(--colors-color-palette-light-p3-12)",
    "variable": "var(--colors-color-palette-light-p3-12)"
  },
  "colors.colorPalette.p3.12": {
    "value": "var(--colors-color-palette-p3-12)",
    "variable": "var(--colors-color-palette-p3-12)"
  },
  "colors.colorPalette.light.p3.a.1": {
    "value": "var(--colors-color-palette-light-p3-a-1)",
    "variable": "var(--colors-color-palette-light-p3-a-1)"
  },
  "colors.colorPalette.p3.a.1": {
    "value": "var(--colors-color-palette-p3-a-1)",
    "variable": "var(--colors-color-palette-p3-a-1)"
  },
  "colors.colorPalette.light.p3.a.2": {
    "value": "var(--colors-color-palette-light-p3-a-2)",
    "variable": "var(--colors-color-palette-light-p3-a-2)"
  },
  "colors.colorPalette.p3.a.2": {
    "value": "var(--colors-color-palette-p3-a-2)",
    "variable": "var(--colors-color-palette-p3-a-2)"
  },
  "colors.colorPalette.light.p3.a.3": {
    "value": "var(--colors-color-palette-light-p3-a-3)",
    "variable": "var(--colors-color-palette-light-p3-a-3)"
  },
  "colors.colorPalette.p3.a.3": {
    "value": "var(--colors-color-palette-p3-a-3)",
    "variable": "var(--colors-color-palette-p3-a-3)"
  },
  "colors.colorPalette.light.p3.a.4": {
    "value": "var(--colors-color-palette-light-p3-a-4)",
    "variable": "var(--colors-color-palette-light-p3-a-4)"
  },
  "colors.colorPalette.p3.a.4": {
    "value": "var(--colors-color-palette-p3-a-4)",
    "variable": "var(--colors-color-palette-p3-a-4)"
  },
  "colors.colorPalette.light.p3.a.5": {
    "value": "var(--colors-color-palette-light-p3-a-5)",
    "variable": "var(--colors-color-palette-light-p3-a-5)"
  },
  "colors.colorPalette.p3.a.5": {
    "value": "var(--colors-color-palette-p3-a-5)",
    "variable": "var(--colors-color-palette-p3-a-5)"
  },
  "colors.colorPalette.light.p3.a.6": {
    "value": "var(--colors-color-palette-light-p3-a-6)",
    "variable": "var(--colors-color-palette-light-p3-a-6)"
  },
  "colors.colorPalette.p3.a.6": {
    "value": "var(--colors-color-palette-p3-a-6)",
    "variable": "var(--colors-color-palette-p3-a-6)"
  },
  "colors.colorPalette.light.p3.a.7": {
    "value": "var(--colors-color-palette-light-p3-a-7)",
    "variable": "var(--colors-color-palette-light-p3-a-7)"
  },
  "colors.colorPalette.p3.a.7": {
    "value": "var(--colors-color-palette-p3-a-7)",
    "variable": "var(--colors-color-palette-p3-a-7)"
  },
  "colors.colorPalette.light.p3.a.8": {
    "value": "var(--colors-color-palette-light-p3-a-8)",
    "variable": "var(--colors-color-palette-light-p3-a-8)"
  },
  "colors.colorPalette.p3.a.8": {
    "value": "var(--colors-color-palette-p3-a-8)",
    "variable": "var(--colors-color-palette-p3-a-8)"
  },
  "colors.colorPalette.light.p3.a.9": {
    "value": "var(--colors-color-palette-light-p3-a-9)",
    "variable": "var(--colors-color-palette-light-p3-a-9)"
  },
  "colors.colorPalette.p3.a.9": {
    "value": "var(--colors-color-palette-p3-a-9)",
    "variable": "var(--colors-color-palette-p3-a-9)"
  },
  "colors.colorPalette.light.p3.a.10": {
    "value": "var(--colors-color-palette-light-p3-a-10)",
    "variable": "var(--colors-color-palette-light-p3-a-10)"
  },
  "colors.colorPalette.p3.a.10": {
    "value": "var(--colors-color-palette-p3-a-10)",
    "variable": "var(--colors-color-palette-p3-a-10)"
  },
  "colors.colorPalette.light.p3.a.11": {
    "value": "var(--colors-color-palette-light-p3-a-11)",
    "variable": "var(--colors-color-palette-light-p3-a-11)"
  },
  "colors.colorPalette.p3.a.11": {
    "value": "var(--colors-color-palette-p3-a-11)",
    "variable": "var(--colors-color-palette-p3-a-11)"
  },
  "colors.colorPalette.light.p3.a.12": {
    "value": "var(--colors-color-palette-light-p3-a-12)",
    "variable": "var(--colors-color-palette-light-p3-a-12)"
  },
  "colors.colorPalette.p3.a.12": {
    "value": "var(--colors-color-palette-p3-a-12)",
    "variable": "var(--colors-color-palette-p3-a-12)"
  },
  "colors.colorPalette.dark.1": {
    "value": "var(--colors-color-palette-dark-1)",
    "variable": "var(--colors-color-palette-dark-1)"
  },
  "colors.colorPalette.dark.2": {
    "value": "var(--colors-color-palette-dark-2)",
    "variable": "var(--colors-color-palette-dark-2)"
  },
  "colors.colorPalette.dark.3": {
    "value": "var(--colors-color-palette-dark-3)",
    "variable": "var(--colors-color-palette-dark-3)"
  },
  "colors.colorPalette.dark.4": {
    "value": "var(--colors-color-palette-dark-4)",
    "variable": "var(--colors-color-palette-dark-4)"
  },
  "colors.colorPalette.dark.5": {
    "value": "var(--colors-color-palette-dark-5)",
    "variable": "var(--colors-color-palette-dark-5)"
  },
  "colors.colorPalette.dark.6": {
    "value": "var(--colors-color-palette-dark-6)",
    "variable": "var(--colors-color-palette-dark-6)"
  },
  "colors.colorPalette.dark.7": {
    "value": "var(--colors-color-palette-dark-7)",
    "variable": "var(--colors-color-palette-dark-7)"
  },
  "colors.colorPalette.dark.8": {
    "value": "var(--colors-color-palette-dark-8)",
    "variable": "var(--colors-color-palette-dark-8)"
  },
  "colors.colorPalette.dark.9": {
    "value": "var(--colors-color-palette-dark-9)",
    "variable": "var(--colors-color-palette-dark-9)"
  },
  "colors.colorPalette.dark.10": {
    "value": "var(--colors-color-palette-dark-10)",
    "variable": "var(--colors-color-palette-dark-10)"
  },
  "colors.colorPalette.dark.11": {
    "value": "var(--colors-color-palette-dark-11)",
    "variable": "var(--colors-color-palette-dark-11)"
  },
  "colors.colorPalette.dark.12": {
    "value": "var(--colors-color-palette-dark-12)",
    "variable": "var(--colors-color-palette-dark-12)"
  },
  "colors.colorPalette.dark.a.1": {
    "value": "var(--colors-color-palette-dark-a-1)",
    "variable": "var(--colors-color-palette-dark-a-1)"
  },
  "colors.colorPalette.dark.a.2": {
    "value": "var(--colors-color-palette-dark-a-2)",
    "variable": "var(--colors-color-palette-dark-a-2)"
  },
  "colors.colorPalette.dark.a.3": {
    "value": "var(--colors-color-palette-dark-a-3)",
    "variable": "var(--colors-color-palette-dark-a-3)"
  },
  "colors.colorPalette.dark.a.4": {
    "value": "var(--colors-color-palette-dark-a-4)",
    "variable": "var(--colors-color-palette-dark-a-4)"
  },
  "colors.colorPalette.dark.a.5": {
    "value": "var(--colors-color-palette-dark-a-5)",
    "variable": "var(--colors-color-palette-dark-a-5)"
  },
  "colors.colorPalette.dark.a.6": {
    "value": "var(--colors-color-palette-dark-a-6)",
    "variable": "var(--colors-color-palette-dark-a-6)"
  },
  "colors.colorPalette.dark.a.7": {
    "value": "var(--colors-color-palette-dark-a-7)",
    "variable": "var(--colors-color-palette-dark-a-7)"
  },
  "colors.colorPalette.dark.a.8": {
    "value": "var(--colors-color-palette-dark-a-8)",
    "variable": "var(--colors-color-palette-dark-a-8)"
  },
  "colors.colorPalette.dark.a.9": {
    "value": "var(--colors-color-palette-dark-a-9)",
    "variable": "var(--colors-color-palette-dark-a-9)"
  },
  "colors.colorPalette.dark.a.10": {
    "value": "var(--colors-color-palette-dark-a-10)",
    "variable": "var(--colors-color-palette-dark-a-10)"
  },
  "colors.colorPalette.dark.a.11": {
    "value": "var(--colors-color-palette-dark-a-11)",
    "variable": "var(--colors-color-palette-dark-a-11)"
  },
  "colors.colorPalette.dark.a.12": {
    "value": "var(--colors-color-palette-dark-a-12)",
    "variable": "var(--colors-color-palette-dark-a-12)"
  },
  "colors.colorPalette.dark.p3.1": {
    "value": "var(--colors-color-palette-dark-p3-1)",
    "variable": "var(--colors-color-palette-dark-p3-1)"
  },
  "colors.colorPalette.dark.p3.2": {
    "value": "var(--colors-color-palette-dark-p3-2)",
    "variable": "var(--colors-color-palette-dark-p3-2)"
  },
  "colors.colorPalette.dark.p3.3": {
    "value": "var(--colors-color-palette-dark-p3-3)",
    "variable": "var(--colors-color-palette-dark-p3-3)"
  },
  "colors.colorPalette.dark.p3.4": {
    "value": "var(--colors-color-palette-dark-p3-4)",
    "variable": "var(--colors-color-palette-dark-p3-4)"
  },
  "colors.colorPalette.dark.p3.5": {
    "value": "var(--colors-color-palette-dark-p3-5)",
    "variable": "var(--colors-color-palette-dark-p3-5)"
  },
  "colors.colorPalette.dark.p3.6": {
    "value": "var(--colors-color-palette-dark-p3-6)",
    "variable": "var(--colors-color-palette-dark-p3-6)"
  },
  "colors.colorPalette.dark.p3.7": {
    "value": "var(--colors-color-palette-dark-p3-7)",
    "variable": "var(--colors-color-palette-dark-p3-7)"
  },
  "colors.colorPalette.dark.p3.8": {
    "value": "var(--colors-color-palette-dark-p3-8)",
    "variable": "var(--colors-color-palette-dark-p3-8)"
  },
  "colors.colorPalette.dark.p3.9": {
    "value": "var(--colors-color-palette-dark-p3-9)",
    "variable": "var(--colors-color-palette-dark-p3-9)"
  },
  "colors.colorPalette.dark.p3.10": {
    "value": "var(--colors-color-palette-dark-p3-10)",
    "variable": "var(--colors-color-palette-dark-p3-10)"
  },
  "colors.colorPalette.dark.p3.11": {
    "value": "var(--colors-color-palette-dark-p3-11)",
    "variable": "var(--colors-color-palette-dark-p3-11)"
  },
  "colors.colorPalette.dark.p3.12": {
    "value": "var(--colors-color-palette-dark-p3-12)",
    "variable": "var(--colors-color-palette-dark-p3-12)"
  },
  "colors.colorPalette.dark.p3.a.1": {
    "value": "var(--colors-color-palette-dark-p3-a-1)",
    "variable": "var(--colors-color-palette-dark-p3-a-1)"
  },
  "colors.colorPalette.dark.p3.a.2": {
    "value": "var(--colors-color-palette-dark-p3-a-2)",
    "variable": "var(--colors-color-palette-dark-p3-a-2)"
  },
  "colors.colorPalette.dark.p3.a.3": {
    "value": "var(--colors-color-palette-dark-p3-a-3)",
    "variable": "var(--colors-color-palette-dark-p3-a-3)"
  },
  "colors.colorPalette.dark.p3.a.4": {
    "value": "var(--colors-color-palette-dark-p3-a-4)",
    "variable": "var(--colors-color-palette-dark-p3-a-4)"
  },
  "colors.colorPalette.dark.p3.a.5": {
    "value": "var(--colors-color-palette-dark-p3-a-5)",
    "variable": "var(--colors-color-palette-dark-p3-a-5)"
  },
  "colors.colorPalette.dark.p3.a.6": {
    "value": "var(--colors-color-palette-dark-p3-a-6)",
    "variable": "var(--colors-color-palette-dark-p3-a-6)"
  },
  "colors.colorPalette.dark.p3.a.7": {
    "value": "var(--colors-color-palette-dark-p3-a-7)",
    "variable": "var(--colors-color-palette-dark-p3-a-7)"
  },
  "colors.colorPalette.dark.p3.a.8": {
    "value": "var(--colors-color-palette-dark-p3-a-8)",
    "variable": "var(--colors-color-palette-dark-p3-a-8)"
  },
  "colors.colorPalette.dark.p3.a.9": {
    "value": "var(--colors-color-palette-dark-p3-a-9)",
    "variable": "var(--colors-color-palette-dark-p3-a-9)"
  },
  "colors.colorPalette.dark.p3.a.10": {
    "value": "var(--colors-color-palette-dark-p3-a-10)",
    "variable": "var(--colors-color-palette-dark-p3-a-10)"
  },
  "colors.colorPalette.dark.p3.a.11": {
    "value": "var(--colors-color-palette-dark-p3-a-11)",
    "variable": "var(--colors-color-palette-dark-p3-a-11)"
  },
  "colors.colorPalette.dark.p3.a.12": {
    "value": "var(--colors-color-palette-dark-p3-a-12)",
    "variable": "var(--colors-color-palette-dark-p3-a-12)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar