import type { SVGProps } from "react";
import { cx } from "~/design/generated/css";
import { type IconVariantProps, icon } from "~/design/generated/recipes";
import type { AssetToken } from "~/design/generated/tokens";
import { type Token, token } from "~/design/generated/tokens";

type ExtractIconName<T> = T extends `icons.${infer Substring}` ? Substring : never;

export type IconName = ExtractIconName<AssetToken>;

export type IconProps = SVGProps<SVGSVGElement> &
  IconVariantProps & {
    name: IconName;
    className?: string;
  };

export function Icon({ name, className, style, ...props }: IconProps) {
  return (
    <svg {...props} className={cx(icon(props), className)} style={style}>
      <use href={token(`assets.icons.${name}` as Token)} />
    </svg>
  );
}
